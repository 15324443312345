import { getDefaultDataOrder, getDefaultSearchOrder } from "constants/defaultObjects";
import { orderActionTypes } from "./orderActionTypes";

const initialState = {
    data: getDefaultDataOrder(),
    search: getDefaultSearchOrder(),
    refetchData: false
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case orderActionTypes.UPDATE_ORDER_DATA: {
            const { data } = action.payload;
            return {
                ...state,
                data: data
            };
        }
        case orderActionTypes.UPDATE_ORDER_DATA_PAGINATION: {
            const { data } = action.payload;
            return {
                ...state,
                data: data,
                refetchData: true
            };
        }
        case orderActionTypes.UPDATE_ORDER_SEARCH: {
            const { search } = action.payload;
            return {
                ...state,
                search: search
            };
        }
        case orderActionTypes.UPDATE_ORDER_ALL: {
            const { data, search } = action.payload;
            return {
                ...state,
                data: data,
                search: search
            };
        }
        case orderActionTypes.UPDATE_ORDER_REFETCH_DATA: {
            const { refetchData } = action.payload;
            return {
                ...state,
                refetchData: refetchData
            };
        }
        // Implement additional cases for other properties
        default:
            return state;
    }
};

export default orderReducer;