import { Autocomplete, TextField, Typography } from "@mui/material";
import { useState } from "react";
import CustomChip from "components/Chip/CustomChip";

/**
 * The CustomAutocompleteTimezoneSelect, that display a customized autocomple select for timezone.
 * 
 * @property {string} searchLabel the search autocomplete field label
 * @property {array} instances the array of timezone list
 * @property {function} onSelectChange Callable that handles the change of the value
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomAutocompleteTimezoneSelect({ /*searchLabel, */ instances = [], onSelectChange, defaultValue }) {
    const [value, setValue] = useState(defaultValue !== "" ? defaultValue : instances[63].text);

    return <Autocomplete
        id="timezone-list-select"
        options={instances}
        autoHighlight
        multiple={false}
        getOptionLabel={(option) => option}
        sx={{ width: "97.5%" }}
        onChange={(event, newValue) => {
            setValue(newValue?.text);
            onSelectChange(newValue?.text);
        }}
        value={value}
        renderOption={(props, option, { selected }) => {
            return <>
                <li key={option.id} {...props} style={{ borderBottom: (instances[instances.length - 1].id !== option.id) ? "1px solid darkgrey" : "none", paddingTop: "20px" }}>
                    <CustomChip label={option.text.split(")")[0].replace("(UTC", "UTC ")} />
                    <Typography variant="inherit" sx={{ fontSize: "15px", color: "#205d7b" }}>{option.text.split(")")[1]}</Typography>
                </li>
            </>
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                // label={searchLabel}
                inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                }}
            />
        )}
    />
}

export default CustomAutocompleteTimezoneSelect;