import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import orderApi from "../../api/order";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../components/Typography/CustomTypography";

export default function LackOfProductModal({ open, onClose, order, onSubmit }) {
  const { t } = useTranslation();

  const [cart, setCart] = useState([]);
  useEffect(() => {
    setCart(
      order?.cart?.map((item) => {
        return {
          ...item,
          selected: false,
          availableQuantity: item.quantity - item.unavailableQuantity,
        };
      })
    );
  }, [open]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const submitHandler = () => {
    setLoading(true);
    const body = cart
      .filter((item) => item.selected)
      .map((item) => {
        return {
          itemID: item.id,
          quantity: item.quantity - item.availableQuantity,
        };
      });
    orderApi
      .updateOrderItemUnavailbale(order.id, body)
      .then((r) => {
        onSubmit(order.id, body, r);

        // if (r.data.message) {
        //   setAlertBox({
        //     isOpen: true,
        //     message: r.data.message,
        //     backgroundColor: "#4caf50",
        //   });
        // }

        setLoading(false);
        onClose();
      })
      .catch((e) => {
        // console.log(e);
        setError("Fail to Update order item");
        setLoading(false);
      });
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogContent style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "10px", right: "10px" }}>
          <IconButton
            disableRipple
            onClick={onClose}
            sx={{ p: "2px", background: "#DDDFE2" }}
          >
            <CloseIcon
              sx={{
                fill: "rgba(0, 0, 0, 0.87)",
                border: 0,
                borderRadius: "50%",
                fontSize: "1.2rem",
              }}
            />
          </IconButton>
        </div>
        {loading ? (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            sx={{ minHeight: "40vh" }}
          >
            <CircularProgress sx={{ color: "#527b98" }} />
          </Grid>
        ) : (
          <>
            <CustomTypography
              text={`${t("orders.lackOfProductDialog.heading")} #${
                order?.orderNumber
              }`}
              sx={{
                color: "#000",
                fontWeight: "600",
                textAlign: "left",
                ml: "12px",
                mt: "10px",
              }}
              variant='h5'
            />

            <Grid
              container
              direction='column'
              spacing={2}
              gap='15px'
              sx={{ mt: "5px" }}
            >
              {cart?.map((item, i) => (
                <Grid
                  item
                  key={item.id}
                  sx={{
                    width: "100%",

                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid container justifyContent='space-between' spacing={2}>
                    {/* quantity ,name*/}
                    <Grid item sx={{ minWidth: "72px" }}>
                      <Grid container alignItems='center' gap='15px'>
                        <Checkbox
                          checked={item.selected}
                          onChange={() => {
                            setCart((c) =>
                              c.map((cartItem) => {
                                if (item.id === cartItem.id) {
                                  cartItem.selected = !cartItem.selected;
                                }
                                return cartItem;
                              })
                            );
                          }}
                        />
                        <Box
                          sx={{
                            background: "#DDDFE2",
                            //boxShadow: `0px 0px 15px 0px #dcdcdc`,
                            borderRadius: "5px",
                            padding: "5px 15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <CustomTypography
                            text={`${
                              item.quantity - item.unavailableQuantity
                            } x`}
                            sx={{ fontWeight: "600", color: "#000" }}
                            variant='subtitle2'
                          />
                        </Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            textAlign: "left",
                          }}
                          variant='body1'
                        >
                          {item.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {item.selected && (
                        <Grid container spacing={2}>
                          <Grid item sx={{ minWidth: "200px" }}>
                            <TextField
                              select
                              fullWidth
                              size='small'
                              variant='outlined'
                              label={t(
                                "orders.lackOfProductDialog.availableQuantity"
                              )}
                              value={item.availableQuantity}
                              onChange={(e) => {
                                setCart((c) =>
                                  c.map((cartItem) => {
                                    if (item.id === cartItem.id) {
                                      cartItem.availableQuantity =
                                        e.target.value;
                                    }
                                    return cartItem;
                                  })
                                );
                              }}
                            >
                              {[...Array(item.quantity + 1)].map((ele, i) => (
                                <MenuItem key={i} value={i}>
                                  {i}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {error && (
              <Typography
                variant='body1'
                align='center'
                sx={{ mt: "15px", color: "red" }}
              >
                {error}
              </Typography>
            )}
            {cart?.some((item) => item.selected) && (
              <Grid
                item
                sx={{
                  width: "100%",
                  mt: "15px",
                }}
              >
                <Grid container spacing={2} justifyContent='flex-end'>
                  <Grid item>
                    <Button
                      variant='text'
                      sx={{
                        borderColor: "transparent",
                        fontWeight: 600,
                        color: "#4A4c50",
                      }}
                      onClick={onClose}
                    >
                      {t("orders.lackOfProductDialog.cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      sx={{
                        fontWeight: 600,
                      }}
                      onClick={submitHandler}
                      disabled={loading}
                    >
                      {t("orders.lackOfProductDialog.update")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
