import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to get the menus list from the database.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the menus list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    if (search.name !== null && search.name !== undefined && search.name !== "") {
        data = { name: search.name }
    }
    if (search.organizationID !== null && search.organizationID !== undefined && search.organizationID !== "") {
        data = { ...data, organizationID: search.organizationID }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}menu`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}menu`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to get the menus list from the database.
 * @param {*} orgID The id of the organization to be fetched
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * 
 * @returns an object with an attribute containing the menus list
 */
export async function fetchAllByOrganizationID(orgID, pageable) {
    let config = {
        baseURL: process.env.REACT_APP_AXIOSBASE,
        params: pageable !== undefined ? pageable : {}
    }

    return await request.get(`${API}organization/${orgID}/menu`, config);
}


/**
 * The rest request which return required information to be presented on add new menu page.
 * @param {*} organizationID The id of the organization
 * @returns an object with an attribute containing the requested information to be presented on the menu page
 */
export async function fetchNew(organizationID) {
    let data = {};
    if (organizationID !== null && organizationID !== "" && organizationID !== undefined) {
        data.organizationID = organizationID;
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}menu/add`, data));
    else return await request.get(includeParamsToUrl(`${API}menu/add`));
}

/**
 * The rest request which attempts to get an existing menu information.
 * @param {*} organizationID The id of the organization
 * @param {*} menuID The id of the menu to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the menu
 */
export async function fetchOne(organizationID, menuID) {
    let data = {};
    if (organizationID !== null && organizationID !== "" && organizationID !== undefined) {
        data.organizationID = organizationID;
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}menu/${menuID}`, data));
    else return await request.get(includeParamsToUrl(`${API}menu/${menuID}`));
}

/**
 * The rest request which attempts to get an existing menu information.
 * 
 * @param {*} organizationID The id of the organization
 * @param {*} menuID The id of the menu to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the menu
 */
export async function fetchOneCMS(organizationID, menuID) {
    let data = {};
    if (organizationID !== null && organizationID !== undefined && organizationID !== "") {
        data.organizationID = organizationID;
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}menu/${menuID}/full`, data));
    else return await request.get(includeParamsToUrl(`${API}menu/${menuID}/full`));
}

/**
 * The rest request which attempts to get an existing menu settings.
 * 
 * @param {*} organizationID The id of the outlet's organization
 * @param {*} outletID The id of the outlet of the menu
 * @param {*} menuID The id of the menu settings to be fetched
 * 
 * @returns an object with an attribute containing the current settings of the outlet
 */
export async function fetchOneSettings(organizationID, outletID, menuID) {
    let data = {};
    if (organizationID && organizationID !== "") {
        data = { organizationID: organizationID }
    }
    if (outletID !== undefined) {
        data = { ...data, outletID: outletID }
    }
    if (menuID !== undefined) {
        data = { ...data, menuID: menuID }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}menu/settings`, data));
    else return await request.get(includeParamsToUrl(`${API}menu/settings`));
}

/**
 * The rest request which attempts to get an existing menu information.
 * @param {*} organizationID The id of the organization
 * @param {*} menuID The id of the menu to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the menu
 */
export async function fetchOneCategories(organizationID, menuID) {
    let config = {
        baseURL: process.env.REACT_APP_AXIOSBASE,
        params: { organizationID: organizationID }
    }

    return await request.get(`${API}menu/${menuID}/categories`, config);
}


/**
 * The rest request which attempts to get an existing menu information.
 * @param {*} orgID The id of the organization to be fetched
 * @param {*} menuID The id of the menu to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the menu
 */
export async function fetchOneByOrganizationID(orgID, menuID) {
    return await request.get(`${API}organization/${orgID}/menu/${menuID}`);
}

/**
 * The rest request which attempts to create a new menu.
 * @param {*} data A JSON object which contains new menu infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}menu`, data);
}

/**
 * The rest request which attempts to update an existing menu.
 * @param {*} data A JSON object which contains the updated infromation of the menu
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}menu`, data);
}
/**
 * The rest request which attempts to update an existing menu from CMS page.
 * 
 * @param {*} data A JSON object which contains the updated infromation of the menu
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function updateCMS(data) {
    return await request.put(`${API}menu/full`, data);
}

/**
 * The rest request which attempts to update an existing menu from Categories page.
 * 
 * @param {*} data A JSON object which contains the updated infromation of the menu
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function updateCategories(data) {
    return await request.put(`${API}menu/update/categories`, data);
}

/**
 * The rest request which attempts to update an existing menu settings.
 * @param {*} data A JSON object which contains the updated infromation of the menu settings
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function updateSettings(data) {
    return await request.put(`${API}menu/settings`, data);
}

/**
 * The rest request to delete a specific menu.
 * @param {*} id The id of the menu to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}menu/${id}`);
}

/**
 * The rest request which attempts to activate an existing menu.
 * @param {*} menuId The id of the menu to be activate
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function activate(menuId) {
    return await request.post(`${API}menu/${menuId}/activate`);
}

/**
 * The rest request which attempts to disable an existing menu.
 * @param {*} menuId The id of the menu to be disabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function disable(menuId) {
    return await request.post(`${API}menu/${menuId}/disable`);
}

/**
 * The rest request to clear all menus, parent categories and categories.
 * @param {*} organizationID The id of the organization 
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function clearAll(organizationID) {
    let data = {};
    if (organizationID !== null && organizationID !== "") {
        data.organizationID = organizationID;
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}menu/clear`, data));
    else return await request.get(includeParamsToUrl(`${API}menu/clear`));
}

export async function fetchItemDetails(outletUrl, itemID, lang = "en", loyaltyCatgory = "") {
    let data = {};
    if (outletUrl !== undefined && outletUrl !== "") {
        data = { outletUrl: outletUrl }
    }
    if (lang !== undefined && lang !== "") {
        data = { lang: lang }
    }
    if (loyaltyCatgory !== "") {
        data = { ...data, loyaltyCatgory: loyaltyCatgory }
    }

    return await request.get(`${API}display/menu/${outletUrl}/item/${itemID}`, data);
}

/**
 * The rest request which attempts to get the menu items list to be imported from json.
 * 
 * @returns an object with an attribute containing the menu items list in json format
 */
export async function importCsv(organizationID, items , name) {
    let data = {
        organizationID: organizationID,
        items: items,
        name: name
    };

    return await request.post(`${API}menu/import/csv`, data);
}

const menuApi = {
    fetchAll,
    fetchAllByOrganizationID,
    fetchNew,
    fetchOne,
    fetchOneSettings,
    fetchOneCategories,
    fetchOneByOrganizationID,
    fetchOneCMS,
    fetchItemDetails,
    create,
    update,
    updateCMS,
    updateCategories,
    updateSettings,
    activate,
    disable,
    deleteById,
    clearAll,
    importCsv
}

export default menuApi;