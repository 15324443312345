import { Grid } from '@mui/material';
import orderApi from 'api/order';
import { ArrowBackIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomSinglePastOrderCardEdit from 'components/Card/order/CustomSinglePastOrderCardEdit';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomOrderActionsHistoryTimeline from 'components/Timeline/CustomOrderActionsHistoryTimeline';
import CustomOrderPaymentActionsHistoryTimeline from 'components/Timeline/CustomOrderPaymentActionsHistoryTimeline';
import CustomTitle from 'components/Title/CustomTitle';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { mainArea, mainTag, orderBackBtnStyle } from 'themes/defaultThemes';
import { getLanguageFromURL } from 'utils/language';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The OrderEditPdaComponent, that allow the user to view the organization/outlet - customer spesific order details.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OrderEditPdaComponent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { id } = useParams();

    //change document title
    useDocumentTitle(`Usee ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('order.pageTitle')}`);


    /**
     * @type {object}
     * 
     * @property {object} userAuth the authenticated user infromation
     * @property {object} alertBox holds snackbar infromation and style
     * @property {object} order the order details
     * @property {array} equipments the equipment option list
     * @property {array} statuses the status option list
     * @property {object} outlet tha outlet basic details (id and name, isActive)
     * @property {object} organization tha organization basic details (id and name)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        order: null,
        equipments: [],
        statuses: [],
        currency: "€",
        newEquipmentID: null
    });

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        if (isFirstTime)
            getOrder();
        setIsFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * The rest endpoint to get the order details.
     */
    function getOrder() {
        orderApi.fetchOne(id).then((r) => {
            if (r.data.code === "SUCCESS") {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            order: r.data.returnobject.order,
                            equipments: r.data.returnobject.equipments,
                            statuses: r.data.returnobject.statuses,
                            currency: r.data.returnobject.currency
                        })
                    })
                    .then(() => setIsLoaded(true))
            }
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * 
     * @param {*} event 
     * @param {*} id 
     * @param {*} status 
     */
    function changeTable(event, id) {
        let data = {
            equipmentID: equipment.id
        };
        orderApi.updateTable(id, data).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            if (r.data?.code === "SUCCESS")
                getOrder();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * 
     * @param {*} event 
     * @param {*} id 
     * @param {*} status 
     */
    function changeOrderStatus(event, id, status) {
        orderApi.updateOrderStatus(id, status).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            if (r.data?.code === "SUCCESS")
                getOrder();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    // ========================= EQUIPMENT 
    const [equipmentAnchorEl, setEquipmentAnchorEl] = useState(null);
    const [equipment, setEquipment] = useState('');

    function updateEquipmentOpenCallback(event) {
        setEquipment(equipment?.label)
        setEquipmentAnchorEl(event.currentTarget);
    };

    function updateEquipmentCloseCallback() {
        setEquipmentAnchorEl(null);
    };

    function updateEquipmentValue(newValue) {
        setEquipment(newValue);
    };

    function updateEquipmentCallback(event) {
        // validateNewEquipmentLabel(equipment);
        changeTable(event, data.order?.id)
    };

    const openEquipment = Boolean(equipmentAnchorEl);

    /**
     * The rest endpoint to validate the new inputed equipment label and return its details (id, label).
     */
    // function validateNewEquipmentLabel(eq) {
    //     equipmentApi.validateExistance(data.outlet?.id, eq).then((r) => {
    //         if (r.data?.code === "SUCCESS" && r.data?.returnobject !== null) {
    //             // Optionally close the popover
    //             updateEquipmentCloseCallback();
    //             setEquipmentInvalid(false);
    //         } else {
    //             setEquipmentInvalid(true);
    //         }
    //     }).catch((e) => {
    //         // console.log(e);
    //     });
    // }


    // ========================= ORDER STATUS 
    const [orderStatusAnchorEl, setOrderStatusAnchorEl] = useState(null);
    const [orderStatus, setOrderStatus] = useState('');

    function updateOrderStatusOpenCallback(event) {
        setOrderStatus(orderStatus?.label)
        setOrderStatusAnchorEl(event.currentTarget);
    };

    function updateOrderStatusCloseCallback() {
        setOrderStatusAnchorEl(null);
    };

    function updateOrderStatusValue(newValue) {
        setOrderStatus(newValue);
    };

    function updateOrderStatusCallback(event) {
        changeOrderStatus(event, data.order?.id, orderStatus)
    };

    const openOrderStatus = Boolean(orderStatusAnchorEl);

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <CustomGrid role="main" id="order-edit" sx={mainTag()}>
            <LoadingSkeleton lines={9} />
        </CustomGrid>
    } else {

        return (
            <CustomGrid role="main" id="order-edit" sx={mainTag()}>
                <CustomTitle
                    title={`${t('actions.view')} ${t('order.plain')}`}
                    subtitle={' '}
                />

                <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                    <CustomIconButton
                        icon={<ArrowBackIcon />}
                        sx={orderBackBtnStyle()}
                        onClick={() =>
                            navigate(`/${getLanguageFromURL()}/pda`)}
                        label={t('pda.backButton')}
                    />
                </Grid>

                <CustomGrid sx={mainArea()}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                            <CustomSinglePastOrderCardEdit
                                order={data.order}
                                equipment={data.equipments?.find((eq) => eq.id === data.order?.equipmentID)}
                                equipments={data.equipments}
                                statuses={data.statuses}
                                outlet={data.outlet}
                                equipmentAnchorEl={equipmentAnchorEl}
                                updateEquipmentOpenCallback={updateEquipmentOpenCallback}
                                updateEquipmentCloseCallback={updateEquipmentCloseCallback}
                                updateEquipmentCallback={updateEquipmentCallback}
                                handleInputChange={updateEquipmentValue}
                                openEquipment={openEquipment}
                                changeOrderStatus={changeOrderStatus}
                                orderStatusAnchorEl={orderStatusAnchorEl}
                                orderStatus={orderStatus}
                                updateOrderStatusOpenCallback={updateOrderStatusOpenCallback}
                                updateOrderStatusCloseCallback={updateOrderStatusCloseCallback}
                                updateOrderStatusValue={updateOrderStatusValue}
                                updateOrderStatusCallback={updateOrderStatusCallback}
                                openOrderStatus={openOrderStatus}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                            <CustomOrderActionsHistoryTimeline timelineDetails={data.order?.actionsHistory} />
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                            <CustomOrderPaymentActionsHistoryTimeline timelineDetails={data.order?.paymementActionsHistory} currency={data.currency} />
                        </Grid>
                    </Grid>
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }

}

export default OrderEditPdaComponent; 