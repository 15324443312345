import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { mainAreaBodyDraggableTable } from 'themes/defaultThemes';
import CustomPhotoDraggableTableItem from "./CustomPhotoDraggableTableItem";

/**
 * A Custom Draggable List for photos.
 * 
 * @property {array} item the array of the modifier-lists
 * @property {boolean} showEmptyMessage to specify if the empty list message should be shown
 * @property {string} emptyMessage The message to presented if the `label` is empty
 * @property {*} removePhoto function that removes the modifier-list instance
 * @property {*} save function that updates a modifier-list details
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPhotoDraggableTable({ photos, showEmptyMessage = true, emptyMessage,
    setHidden, deletePhoto, setCover, imagePath, updatePhotoOrder,
    field, fieldId
}) {
    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = async (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }
        const orederedPhotos = reorder(photos, result.source.index, result.destination.index)
        updatePhotoOrder(orederedPhotos, field, fieldId)
    }

    return (
        <>
            {(showEmptyMessage && (photos === null || photos?.length === 0)) &&
                <div className="body small" style={mainAreaBodyDraggableTable()}>
                    {emptyMessage}
                </div>
            }

            {(photos !== null && photos.length > 0) &&
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-photo">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="droppable-photo"
                                style={{
                                    background: snapshot.isDraggingOver ? "lightblue" : '',
                                    borderRadius: "4px",
                                    display: "flex",
                                    flexWrap: "wrap"
                                }} >
                                {photos?.map((photo, index) => (
                                    <CustomPhotoDraggableTableItem
                                        photo={photo}
                                        index={index}
                                        imagePath={imagePath}
                                        setCover={setCover}
                                        setHidden={setHidden}
                                        deleteImage={deletePhoto}
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </>
    );
}

export default CustomPhotoDraggableTable;