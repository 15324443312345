import { Typography } from '@mui/material';
import React from 'react';

/**
 * The Custom Typography, that display customized {@link Typography}.
 * @property {string} text the typography text
 * @property {string} variant Applies the theme typography styles.
 * @property {object} sx defining system overrides as well as additional CSS styles
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTypography({ text = "", variant = "body2", sx }) {
    return (
        <Typography sx={sx} variant={variant}>
            {text}
        </Typography>
    );
}

export default CustomTypography;