import { Breadcrumbs, Link, Typography } from '@mui/material';
import { breadcrumbsLinkStyle, breadcrumbsStyle, breadcrumbsTextStyle } from 'themes/defaultThemes';

/**
 * The CustomBreadcrumbMultiParent, that display a page custom breadcrumb 
 * with multiple parents.
 * @property {array} parents the parents details (url and name)
 * @property {string} instanceName the page instance name/submitted date/
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomBreadcrumbMultiParent({ parents, instanceName }) {
    const breadcrumbs = [];
    parents.map((parent, index) =>
        breadcrumbs.push(
            <Link underline="hover" key={index} color="inherit" href={parent.url} sx={breadcrumbsLinkStyle()}>
                {parent.name}
            </Link>
        )
    )

    breadcrumbs.push(
        <Typography key={parents.length} color="text.primary" sx={breadcrumbsTextStyle()}>
            {instanceName}
        </Typography>
    )
    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={breadcrumbsStyle()}>
            {breadcrumbs}
        </Breadcrumbs>
    );
}

export default CustomBreadcrumbMultiParent;