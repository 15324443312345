import { Autocomplete, Box, Button, Checkbox, Grid, Popper, TextField, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon, EditIcon } from 'assets/mui/MuiIcons';
import { useTranslation } from 'react-i18next';

function CheckoutEquipmentPopover({ anchorEl, open, handleClose, handleInputChange, handleUpdate, equipments }) {

  const { t } = useTranslation();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const searchLabel = `${t('actions.searchFor')} ${t('equipment.title')}`;
  const id = open ? 'simple-popover' : undefined;
  const PopperMy = function (props) {

    const anchorEl = document.getElementById('simple-popover');
    return (<Popper {...props}
      modifiers={[
        {
          name: 'flip',
          enabled: false, // Disable flipping if necessary
        },
        {
          name: 'preventOverflow',
          enabled: false, // Disable overflow prevention if necessary
          options: {
            altAxis: true, // Adjust alternate axis behavior if necessary
            tether: false, // Disable tethering if necessary
          },
        },
      ]}
      anchorEl={anchorEl}
      style={{ width: "100%", margin: "20px", overflowY: 'auto', height: anchorEl.clientHeight, transform: "translate(0px, 0px)" }}
      placement='bottom'
      keepMounted={true}
    />)
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ padding: '20px', width: "200px" }}>
        <Grid container gap={1}>
          <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
            <Autocomplete
              key="equipment"
              id="equipment-select"
              disablePortal={false}
              options={equipments}
              autoHighlight
              disableCloseOnSelect
              multiple={false}
              getOptionLabel={(option) => option?.label}
              noOptionsText={`${t('equipment.emptyEquipmentsSearch')}`}
              onChange={(event, newValue) => {
                handleInputChange(newValue);
              }}
              // PopperComponent={PopperMy}
              renderOption={(props, option, { selected }) => {
                return <>
                  <li key={option?.id} {...props} style={{ borderBottom: (equipments[equipments?.length - 1]?.id !== option.id) ? "1px solid darkgrey" : "none", paddingTop: "20px" }}>
                    <div key={option?.id + "-1"} style={{ display: "contents" }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                    </div>
                    <div key={option?.id + "-2"} style={{ display: "block", width: "90%" }}>
                      <Typography variant="inherit" sx={{ fontWeight: 600, fontSize: "15px" }}>{option?.label}</Typography>
                    </div>
                  </li>
                </>
              }
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "200px" }}
                  label={searchLabel}
                  variant='filled'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4} md={4} sx={{ alignSelf: "center" }}>
            <Button
              onClick={handleUpdate}
              color="primary"
              startIcon={<EditIcon />}
              variant="contained"
              sx={{ borderRadius: "30px" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
}

export default CheckoutEquipmentPopover;