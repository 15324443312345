import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import DehazeIcon from '@mui/icons-material/Dehaze';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import HeightIcon from '@mui/icons-material/Height';
import { createTheme, Divider, FormControlLabel, ListItem, ListItemText, Switch, ThemeProvider, Tooltip, Typography } from "@mui/material";
import React, { useState } from 'react';
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { findAllObjectsMatchInArrayOrdered } from 'utils/functions';
import CustomAddButton from 'components/Button/CustomAddButton';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomTextField from "components/Text/CustomTextField";
import CustomItemModifierDraggableTable from 'components/Table/CustomItemModifierDraggableTable';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

/**
 * A Custom Draggable List Item.
 * @property {string} parentCategoryId the id of the parent category of all categories
 * @property {number} parentCategoryIndex the index of the parent category inside the droppable-draggable
 * @property {object} category a specific parent-category
 * @property {array} categoryOptions the array of the categories
 * @property {array} itemOptions the array of the items
 * @property {int} index modifier-list category index
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuCategoriesDraggableTableItem({ parentCategoryId, parentCategoryIndex, category, index, expandAll, organizationID, handleTranslationsDialogState,
    updateCategoryName, updateCategoryIsActive, removeCategory,// categories
    itemOptions, removeItemFromCategory, handleDialogState, updateItemOrder //items
}) {
    let field = "itemIds";//"itemIds";
    const [show, setShow] = useState(false);
    const [expand, setExpand] = useState(true);
    const { t } = useTranslation();

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiInputAdornment: {
                    styleOverrides: {
                        root: {
                            fontWeight: 600,
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            maxHeight: "50px"
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Draggable draggableId={`draggable-${parentCategoryIndex}-${category.id}`} index={index} key={`${parentCategoryIndex}-${category.id}`}>
                {(provided, snapshot) => (
                    <>
                        <ListItem
                            key={`${parentCategoryIndex}-${category.id}-list-item`}
                            ref={provided.innerRef}
                            style={{
                                background: snapshot.isDragging ? 'rgb(235,235,235)' : '',
                                width: "100%",
                                borderBottom: "1px",
                                userSelect: "none",
                                padding: 16,
                                paddingLeft: "35px",
                                borderRadius: "4px",
                                ...provided.draggableProps.style
                            }}
                        >
                            <>
                                {expand && <ExpandMoreIcon style={{ color: "darkgrey", fontSize: "20px", marginLeft: "5px", marginRight: "10px", visibility: (category.itemIds.length > 0) ? "initial" : "hidden" }} onClick={() => setExpand(false)} />}
                                {!expand && <ChevronRightIcon style={{ color: "darkgrey", fontSize: "20px", marginLeft: "5px", marginRight: "10px", visibility: (category.itemIds.length > 0) ? "initial" : "hidden" }} onClick={() => setExpand(true)} />}

                                <span {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                                    <HeightIcon style={{ fontSize: "19px", color: "lightgrey" }} />
                                    <DehazeIcon style={{ marginRight: 15, marginLeft: "-6px", fontSize: "20px", width: "15px", color: "lightgrey", paddingTop: "1px" }} />
                                </span>

                                <CustomIconButton
                                    onClick={() => handleDialogState(true, { parentCategoryId: parentCategoryId, categoryId: category.id, field: "category" }, "photosDialog")}
                                    icon={<PhotoLibraryIcon style={{ marginRight: "0px" }} />}
                                    iconSx={{ marginRight: "0px!important" }}
                                    tooltipTitle={t("assets.configurePhotos")}
                                    sx={{ backgroundColor: "white", borderRadius: "50px", padding: "4px 0px", minWidth: "20px", marginRight: "10px" }}
                                />

                                <CustomIconButton
                                    onClick={() => handleTranslationsDialogState(true, "category", category)}
                                    icon={<GTranslateIcon style={{ marginRight: "0px" }} />}
                                    iconSx={{ marginRight: "0px!important" }}
                                    tooltipTitle={t("label.configureTranslations")}
                                    sx={{ backgroundColor: "white", borderRadius: "50px", padding: "4px 0px", minWidth: "20px", marginRight: "10px" }}
                                />

                                {!show &&
                                    <div style={{ display: "flex" }}>
                                        <ListItemText primary={category.name.en} />
                                        <Tooltip title={t("actions.edit")}>
                                            <EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px", marginTop: "4px" }}
                                                onClick={() => setShow(true)} />
                                        </Tooltip>
                                    </div>
                                }

                                {show &&
                                    <div style={{ display: "flex" }}>
                                        <CustomTextField
                                            id={`${category.id}-name`}
                                            type="text"
                                            defaultValue={category.name.en}
                                            variant="standard"
                                        />
                                        <Tooltip title={t("actions.save")}>
                                            <CheckIcon
                                                style={{ color: "lightgrey", fontSize: "20px", marginLeft: "20px", marginTop: "15px" }}
                                                onClick={() => { setShow(false); updateCategoryName(category, document.getElementById(`${category.id}-name`).value) }} />
                                        </Tooltip>
                                        <Tooltip title={t("actions.cancel")}>
                                            <ClearIcon
                                                style={{ color: "lightgrey", fontSize: "20px", marginLeft: "20px", marginTop: "15px" }}
                                                onClick={() => setShow(false)} />
                                        </Tooltip>
                                    </div>
                                }

                                <div style={{ display: "flex", right: 0, position: "absolute" }}>
                                    <>
                                        <Typography variant="subtitle2" sx={{ fontWeight: 600, marginLeft: "50px", marginTop: "10px" }}>{t('menuCategories.items') + "( " + category.itemIds.length + " )"}</Typography>
                                    </>
                                    <>
                                        <Tooltip title={t("actions.delete")}><DeleteIcon style={{ color: "#205d7b", fontSize: "20px", marginLeft: "30px", marginRight: "20px", marginTop: "10px" }} onClick={() => removeCategory(parentCategoryId, category)} /></Tooltip>
                                        <CustomAddButton label={t('menuCategories.items')} onClick={() => handleDialogState(true, { parentCategoryId: parentCategoryId, categoryId: category.id, field: "items" }, "attachDialog")} />
                                        <FormControlLabel control={<Switch defaultChecked={category.active} onChange={() => updateCategoryIsActive(category, !category.active)} />} sx={{ marginLeft: "20px" }} />
                                    </>
                                </div>
                            </>


                        </ListItem>

                        {/* list items */}
                        {expand && category.itemIds.length > 0 &&
                            <div style={{ marginLeft: "48px" }} >
                                <CustomItemModifierDraggableTable
                                    showEmptyMessage={false}
                                    item={findAllObjectsMatchInArrayOrdered(category.itemIds, itemOptions)}
                                    removeItemOrModifier={removeItemFromCategory}
                                    // field="modifiers"
                                    organizationID={organizationID}
                                    field={category.id}
                                    enableDraggable={true}
                                    updateModifierOrder={updateItemOrder}
                                    categoryID={category.id}
                                />
                            </div>
                        }

                        {category.itemIds.length > 0 &&
                            <CustomAddButton label={t('menuCategories.items')} sx={{ marginLeft: "163px" }} onClick={() => handleDialogState(true, { parentCategoryId: parentCategoryId, categoryId: category.id, field: "items" }, "attachDialog")} />
                        }

                        <Divider style={{ width: "91%", marginLeft: "70px" }} />
                    </>
                )}
            </Draggable>
        </ThemeProvider>
    );
}

export default CustomMenuCategoriesDraggableTableItem;