import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import DehazeIcon from '@mui/icons-material/Dehaze';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeightIcon from '@mui/icons-material/Height';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { createTheme, Divider, Grid, ListItem, ListItemIcon, ListItemText, ThemeProvider, Tooltip, Typography } from "@mui/material";
import CustomSelectWithLabels from "components/Select/CustomSelectWithLabels";
import CustomTextField from "components/Text/CustomTextField";
import { useEffect, useState } from 'react';
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { openInNewTab } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";

/**
 * A Custom Draggable List Item.
 * @property {object} item a specific modifier-list object
 * @property {int} index modifier-list item index
 * @property {*} removeModifierList function that removes the modifier-list instance
 * @property {*} save function that updates a modifier-list details
 * @param {boolean} enableDraggable Has value true when draggable icon is visible.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomModifierListDraggableTableItem({ item, index, removeModifierList, save, enableDraggable, organizationID }) {
    const [show, setShow] = useState(false);
    const [expand, setExpand] = useState(false);
    const { t } = useTranslation();
    const [windowdWidth, setWindowWidth] = useState("")

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiInputAdornment: {
                    styleOverrides: {
                        root: {
                            fontWeight: 600,
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            maxHeight: "50px"
                        }
                    }
                }
            }
        })
    }

    useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            setWindowWidth(document.querySelector("ul.droppable-modifiers-list").clientWidth);
        }

        window.addEventListener('resize', handleResize)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log(windowdWidth)
    let isSmallScreen = windowdWidth < 800
    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Draggable draggableId={item.id} index={index} key={item.id}>
                {(provided, snapshot) => (
                    <>
                        <ListItem
                            id={`list-item-${index}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            sx={{
                                background: snapshot.isDragging ? 'rgb(235,235,235)' : '',
                                width: "100%",
                                borderBottom: "1px",
                                userSelect: "none",
                                padding: "30px 0px",
                                borderRadius: "4px",
                                ...provided.draggableProps.style,
                                height: "20px"
                            }}
                        >
                            <Grid container spacing={0} gap={1} alignItems="center">
                                <Grid item xs={isSmallScreen ? 1 : "auto"}>
                                    {expand && item.attachedModifiers.length > 0 && <ExpandMoreIcon style={{ color: "darkgrey", fontSize: "20px", marginRight: isSmallScreen ? "2px" : "10px" }} onClick={() => setExpand(false)} />}
                                    {!expand && <ChevronRightIcon style={{ color: "darkgrey", fontSize: "20px", marginRight: isSmallScreen ? "2px" : "10px", visibility: (item.attachedModifiers.length > 0) ? "initial" : "hidden" }} onClick={() => setExpand(true)} />}
                                </Grid>

                                <Grid item sx={"auto"}>
                                    <ListItemIcon sx={{ visibility: (enableDraggable) ? "visible" : "hidden", minWidth: "20px" }}  {...provided.dragHandleProps}>
                                        <HeightIcon style={{ fontSize: "19px", color: "lightgrey" }} />
                                        <DehazeIcon style={{ marginRight: isSmallScreen ? 0 : 15, marginLeft: "-6px", fontSize: "20px", color: "lightgrey", paddingTop: "1px" }} />
                                    </ListItemIcon>
                                </Grid>

                                <Grid item xs="auto">
                                    <ListItemText primary={(isSmallScreen && item.name.length > 10) ? `${item.name.substring(0, 10)}...` : item.name} />
                                </Grid>

                                {
                                    <Grid item xs={isSmallScreen ? 1 : "auto"} sx={{ textAlign: 'right' }}>
                                        <Grid container gap={1} alignItems="right">
                                            {!show &&
                                                <Grid item xs={12}>
                                                    <Link component="button" underline="hover" onClick={() => setShow(true)} variant="subtitle2" style={{ display: "contents" }}>
                                                        <Grid container gap={1} alignItems="right">
                                                            {!isSmallScreen &&
                                                                <>
                                                                    <Grid item xs="auto">
                                                                        <Grid container gap={1}>
                                                                            <Grid item xs="auto">
                                                                                <Typography variant="subtitle2" sx={{ fontWeight: 600, marginLeft: "10px" }}>{t('label.type') + ": "}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs="auto">
                                                                                <Typography variant="subtitle2">{item.allowMultipleSelection ? t('label.multiple') : t('label.single')}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs="auto">
                                                                        <Grid container gap={1}>
                                                                            <Grid item xs="auto">
                                                                                <Typography variant="subtitle2" sx={{ fontWeight: 600, marginLeft: "10px" }}>{t('modifierList.minModifiers') + ": "}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs="auto">
                                                                                <Typography variant="subtitle2" >{item.minModifiers !== null && item.minModifiers !== "" && item.minModifiers !== undefined ? item.minModifiers : 1}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs="auto">
                                                                        <Grid container gap={1}>
                                                                            <Grid item xs="auto">
                                                                                <Typography variant="subtitle2" sx={{ fontWeight: 600, marginLeft: "10px" }}>{t('modifierList.maxModifiers') + ": "}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs="auto">
                                                                                <Typography variant="subtitle2">{(item.allowMultipleSelection === false || (item.maxModifiers !== null && item.maxModifiers !== "" && item.maxModifiers !== undefined)) ? item.maxModifiers : 1}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                </>
                                                            }

                                                            <Grid item xs="auto">
                                                                <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} /></Tooltip>
                                                            </Grid>

                                                            {!isSmallScreen &&
                                                                <Grid item xs="auto">
                                                                    <Typography variant="subtitle2" sx={{ fontWeight: 600, marginLeft: "50px" }}>{t('modifierList.modifiers') + "( " + item.attachedModifiers.length + " )"}</Typography>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Link>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                }

                                <Grid item xs={isSmallScreen ? 1 : "auto"} sx={{ marginLeft: "10px", right: isSmallScreen ? 0 : "70px", position: "absolute" }} alignContent={"right"}>
                                    <div style={{ display: "flex" }}>
                                        <Tooltip arrow title={t("actions.view")}>
                                            <VisibilityIcon style={{ color: "#205d7b", fontSize: "20px" }}
                                                onClick={() => openInNewTab(`/${getLanguageFromURL()}${organizationID !== undefined ? `/organization/${organizationID}/` : '/'}modifier-list/${item.id}`)} />
                                        </Tooltip>
                                        <Tooltip arrow title={t("actions.remove")}>
                                            <DeleteIcon style={{ color: "#205d7b", fontSize: "20px" }}
                                                onClick={((event) => removeModifierList(event, item))} />
                                        </Tooltip>
                                    </div>
                                </Grid>
                            </Grid>
                        </ListItem>

                        {show &&
                            <ListItem style={{ justifyContent: "center" }}>
                                <Grid container gap={1} alignItems="center" justifyContent={"center"}>
                                    <Grid item xs={3}>
                                        <CustomSelectWithLabels id={`${item.id}-allowMultipleSelection`}
                                            defaultValue={item.allowMultipleSelection}
                                            startAdormetText={t('label.type') + ":"}
                                            className="form-control-select"
                                            options={[{ "label": t("label.single"), "value": false }, { "label": t("label.multiple"), "value": true }]}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <CustomTextField
                                            type="number"
                                            step="1"
                                            min={0}
                                            name="min"
                                            id={`${item.id}-minModifiers`}
                                            defaultValue={item.minModifiers !== null && item.minModifiers !== "" && item.minModifiers !== undefined ? item.minModifiers : 1}
                                            inputAdornmentLabel={t('modifierList.minModifiers')}
                                        // InputProps={{
                                        //     startAdornment: (
                                        //         <InputAdornment position="start" >
                                        //             <span>{t('modifierList.minModifiers')}</span>
                                        //         </InputAdornment>
                                        //     ),
                                        // }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <CustomTextField
                                            type="number"
                                            step="1"
                                            min={0}
                                            name="min"
                                            id={`${item.id}-maxModifiers`}
                                            variant="filled"
                                            defaultValue={(item.allowMultipleSelection === false || (item.maxModifiers !== null && item.maxModifiers !== "" && item.maxModifiers !== undefined)) ? item.maxModifiers : 1}
                                            inputAdornmentLabel={t('modifierList.maxModifiers')}
                                        // InputProps={{
                                        //     startAdornment: (
                                        //         <InputAdornment position="start" >
                                        //             <span>{t('modifierList.maxModifiers')}</span>
                                        //         </InputAdornment>
                                        //     ),
                                        // }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{ display: "flex" }}>
                                            <Tooltip title={t("actions.save")}><CheckIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "20px", marginTop: "15px" }} onClick={(event) => { setShow(false); save(event, index, item.id, document.getElementById(`${item.id}-allowMultipleSelection`).value, document.getElementById(`${item.id}-minModifiers`).value, document.getElementById(`${item.id}-maxModifiers`).value) }} /></Tooltip>
                                            <Tooltip title={t("actions.cancel")}><ClearIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "20px", marginTop: "15px" }} onClick={() => setShow(false)} /></Tooltip>
                                        </div>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        }

                        {expand && item.attachedModifiers.length > 0 &&
                            <ListItem style={{ marginLeft: "100px", width: "100%" }}>
                                {item.attachedModifiers.map((parent, index) =>
                                    <ListItemText secondary={parent.name} sx={{ width: "100%" }} />
                                )}
                            </ListItem>
                        }
                        <Divider style={{ width: "89%", marginLeft: "50px" }} />
                    </>
                )
                }
            </Draggable>
        </ThemeProvider>
    );
}

export default CustomModifierListDraggableTableItem;