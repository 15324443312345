import { Dialog, DialogContent, Grid, useMediaQuery } from '@mui/material';
import CustomPDAAddOrderGrid from 'components/Card/order/CustomPDAAddOrderGrid';
import CustomPDAViewOrders from 'components/Card/order/CustomPDAViewOrders';
import CustomPDAViewUnpaidOrders from 'components/Card/order/CustomPDAViewUnpaidOrders';
import CustomPDAFirstPageCard from 'components/Card/pda/CustomPDAFirstPageCard';
import CustomPDARequestGrid from 'components/Card/request/CustomPDARequestGrid';
import CustomSwippableDrawer from 'components/Drawer/CustomSwippableDrawer';
import UnpaidBulkOrdersDetailsCard from 'components/Grid/order/UnpaidBulkOrdersDetailsCard';
import UnpaidOrderDetailsCard from 'components/Grid/order/UnpaidOrderDetailsCard';
import { useState } from 'react';

const CustomEquipmentPdaDialog = ({ isOpen, setIsOpen, onClose, setAlertBox,
    equipmentID, equipmentLabel, organizationID, outletID, unpaidOrderCount, pendingRequestsCount, currency,
    getPDADisplay, userAuth
}) => {
    const isMobile = useMediaQuery('(max-width:468px)');

    const [isFirstPage, setIsFirstPage] = useState(true);
    const [isViewOrdersPage, setIsViewOrdersPage] = useState(false);
    const [isAddOrdersPage, setIsAddOrdersPage] = useState(false);
    const [isViewRequestsPage, setIsViewRequestsPage] = useState(false);
    const [isViewUnpaidOrdersPage, setIsViewUnpaidOrdersPage] = useState(false);
    const [selectedOrderToPay, setSelectedOrderToPay] = useState(null);
    const [showAllToPay, setShowAllTopay] = useState(false);

    //==================================== GENERAL

    function handleClose() {
        handleReset();
        onClose();
    }

    function handleReset() {
        setSelectedOrderToPay(null);
        setIsViewUnpaidOrdersPage(false);
        setIsViewRequestsPage(false);
        setIsAddOrdersPage(false);
        setIsViewOrdersPage(false);
        setShowAllTopay(false);
        setIsFirstPage(true);
    }

    // ==================================== REQUESTS

    function handleViewRequests() {
        setIsFirstPage(false);
        setSelectedOrderToPay(null);
        setIsViewUnpaidOrdersPage(false);
        setShowAllTopay(false);
        setIsViewOrdersPage(false);
        setIsAddOrdersPage(false);
        setIsViewRequestsPage(true);
    }

    // ==================================== ORDERS 

    function handleViewOrders() {
        setIsFirstPage(false);
        setIsViewRequestsPage(false);
        setIsAddOrdersPage(false);
        setIsViewUnpaidOrdersPage(false);
        setShowAllTopay(false);
        setSelectedOrderToPay(null);
        setIsViewOrdersPage(true);
    }

    // ==================================== CREATE ORDER

    function handleCreateOrder() {
        setIsFirstPage(false);
        setIsViewRequestsPage(false);
        setIsViewOrdersPage(false);
        setIsViewUnpaidOrdersPage(false);
        setShowAllTopay(false);
        setSelectedOrderToPay(null);
        setIsAddOrdersPage(true);
    }

    function handleCreateOrderView() {
        setIsFirstPage(false);
        setIsViewRequestsPage(false);
        setIsViewOrdersPage(true);
        setIsViewUnpaidOrdersPage(false);
        setShowAllTopay(false);
        setSelectedOrderToPay(null);
        setIsAddOrdersPage(true);
    }

    // ==================================== UNPAID ORDERS 
    function handlePay() {
        setIsFirstPage(false);
        setIsViewRequestsPage(false);
        setIsViewOrdersPage(false);
        setIsAddOrdersPage(false);
        setIsViewUnpaidOrdersPage(true);
        setShowAllTopay(false);
        setSelectedOrderToPay(null);
    }

    function handleReset() {
        setIsViewOrdersPage(false);
        setIsViewRequestsPage(false);
        setSelectedOrderToPay(null);
        setIsAddOrdersPage(false);
        setIsViewUnpaidOrdersPage(false);
        setShowAllTopay(false);
        setIsFirstPage(true);
        setSelectedOrderToPay(null);
    }

    function handleSelectedOrderToPay(order) {
        setIsFirstPage(false);
        setIsViewRequestsPage(false);
        setShowAllTopay(false);
        setIsAddOrdersPage(false);
        setSelectedOrderToPay(order);
    }

    function handleViewAllToPay() {
        setIsFirstPage(false);
        setIsViewRequestsPage(false);
        setIsAddOrdersPage(false);
        setSelectedOrderToPay(null);
        setShowAllTopay(true);
    }

    function body() {
        return <Grid container spacing={2}
            sx={{
                width: "100%",
                // marginTop: "40px",
                alignSelf: "center",
                marginBottom: isFirstPage ? "initial" : "60px",
                overflowY: "initial",
                alignItems: "center",
                padding: "10px"
            }}>
            {/* FIRST PAGE */}
            {(isFirstPage && !isViewOrdersPage && !isViewUnpaidOrdersPage && !showAllToPay && !isAddOrdersPage && !isViewRequestsPage) &&
                <CustomPDAFirstPageCard
                    handlePay={handlePay}
                    handleCreateOrder={handleCreateOrder}
                    handleViewRequests={handleViewRequests}
                    handleViewOrders={handleViewOrders}
                    pendingRequestsCount={pendingRequestsCount}
                    unpaidOrderCount={unpaidOrderCount}
                />
            }

            {/* VIEW UNPAID ORDERS */}
            {(!isFirstPage && !isViewOrdersPage && isViewUnpaidOrdersPage && !showAllToPay && !isViewRequestsPage && !isAddOrdersPage && selectedOrderToPay === null) &&
                <CustomPDAViewUnpaidOrders
                    handleReset={handleReset}
                    handleSelectedOrderToPay={handleSelectedOrderToPay}
                    outletID={outletID}
                    organizationID={organizationID}
                    equipmentID={equipmentID}
                    isViewUnpaidOrdersPage={isViewUnpaidOrdersPage}
                    userAuth={userAuth}
                    handleViewAllToPay={handleViewAllToPay}
                />
            }

            {/* VIEW ORDERS */}
            {(!isFirstPage && isViewOrdersPage && !isViewUnpaidOrdersPage && !showAllToPay && !isViewRequestsPage && !isAddOrdersPage && selectedOrderToPay === null) &&
                <CustomPDAViewOrders
                    handleReset={handleReset}
                    handleSelectedOrderToPay={handleSelectedOrderToPay}
                    handleCreateOrder={handleCreateOrderView}
                    outletID={outletID}
                    organizationID={organizationID}
                    equipmentID={equipmentID}
                    isViewOrdersPage={isViewOrdersPage}
                    setAlertBox={setAlertBox}
                />
            }

            {/* IS ADD */}
            {(!isFirstPage && !isViewOrdersPage && !isViewUnpaidOrdersPage && !showAllToPay && !isViewRequestsPage && isAddOrdersPage && selectedOrderToPay === null) &&
                <CustomPDAAddOrderGrid
                    handleReset={handleReset}
                    equipmentID={equipmentID}
                    equipmentLabel={equipmentLabel}
                    outletID={outletID}
                    organizationID={organizationID}
                    setAlertBox={setAlertBox}
                    isAddOrdersPage={isAddOrdersPage}
                    getPDADisplay={getPDADisplay}
                />
            }

            {/* FROM VIEW ORDER PAGE -> IS ADD */}
            {(!isFirstPage && isViewOrdersPage && !isViewUnpaidOrdersPage && !showAllToPay && !isViewRequestsPage && isAddOrdersPage && selectedOrderToPay === null) &&
                <CustomPDAAddOrderGrid
                    handleReset={() => setIsAddOrdersPage(false)}
                    equipmentID={equipmentID}
                    equipmentLabel={equipmentLabel}
                    outletID={outletID}
                    organizationID={organizationID}
                    setAlertBox={setAlertBox}
                    isAddOrdersPage={isAddOrdersPage}
                    getPDADisplay={getPDADisplay}
                />
            }

            {/* VIEW REQUESTS */}
            {(!isFirstPage && !isViewOrdersPage && !isViewUnpaidOrdersPage && !showAllToPay && isViewRequestsPage && !isAddOrdersPage && selectedOrderToPay === null) &&
                <CustomPDARequestGrid
                    handleReset={handleReset}
                    equipmentID={equipmentID}
                    outletID={outletID}
                    organizationID={organizationID}
                    setAlertBox={setAlertBox}
                    isViewRequestsPage={isViewRequestsPage}
                    getPDADisplay={getPDADisplay}
                />
            }

            {/* PAY ORDER */}
            {(!isFirstPage && !isViewRequestsPage && !showAllToPay && !isAddOrdersPage && selectedOrderToPay !== null) &&
                <UnpaidOrderDetailsCard
                    order={selectedOrderToPay}
                    currency={currency}
                    setAlertBox={setAlertBox}
                    handleReset={() => setSelectedOrderToPay(null)}
                    getPDADisplay={getPDADisplay}
                    userAuth={userAuth}
                />
            }

            {(showAllToPay && !isFirstPage && !isViewOrdersPage && isViewUnpaidOrdersPage && !isViewRequestsPage && !isAddOrdersPage && selectedOrderToPay === null) &&
                <UnpaidBulkOrdersDetailsCard
                    equipmentID={equipmentID}
                    equipmentLabel={equipmentLabel}
                    currency={currency}
                    setAlertBox={setAlertBox}
                    handleReset={() => setShowAllTopay(null)}
                    getPDADisplay={getPDADisplay}
                    userAuth={userAuth}
                />
            }
        </Grid>
    }

    //============================ RENDER

    return (
        <>
            {!isMobile && isOpen &&
                <Dialog
                    open={isOpen}
                    onClose={() => { handleClose() }}
                    aria-describedby="auth-login-dialog"
                // paperSx={autDrawerPaperStyle}
                >
                    <DialogContent sx={{ textAlign: "-webkit-center", minWidth: "60%" }}>
                        {body()}
                    </DialogContent>
                </Dialog>
            }

            {isMobile && isOpen &&
                <CustomSwippableDrawer
                    open={isOpen}
                    onClose={() => handleClose(false)}
                    onOpen={() => setIsOpen(false)}
                    anchor={isMobile ? "bottom" : "left"}
                >
                    {body()}
                </CustomSwippableDrawer>
            }
        </>
    );
};

export default CustomEquipmentPdaDialog;
