import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to get the offer items list from the database.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the offer items list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    if (search.name.en !== "") {
        data = { name: search.name }
    }
    if (search.organizationID !== undefined && search.organizationID !== "") {
        data = { ...data, organizationID: search.organizationID }
    }
    if (search.enabled !== undefined && search.enabled !== null) {
        data = { ...data, enabled: search.enabled }
    }
    if (search.type !== undefined && search.type !== null) {
        data = { ...data, type: search.type }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}offer`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}offer`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which return required information to be presented on add new offer page.
 * @param {*} organizationID The id of the equipment organization id
 * @returns an object with an attribute containing the requested information to be presented on the offer page
 */
export async function fetchNew(organizationID) {
    let data = {};
    if (organizationID !== null && organizationID !== "" && organizationID !== undefined) {
        data.organizationID = organizationID;
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}offer/add`, data));
    else return await request.get(includeParamsToUrl(`${API}offer/add`));
}

/**
 * The rest request which attempts to get an existing offer information.
 * @param {*} organizationID The id of the equipment organization id
 * @param {*} offerID The id of the offer to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the offer
 */
export async function fetchOne(organizationID, offerID) {
    let data = {};
    if (organizationID !== null && organizationID !== "" && organizationID !== undefined) {
        data.organizationID = organizationID;
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}offer/${offerID}`, data));
    else return await request.get(includeParamsToUrl(`${API}offer/${offerID}`));
}

/**
 * The rest request which attempts to create a new offer.
 * @param {*} data A JSON object which contains new offer infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}offer`, data);
}

/**
 * The rest request which attempts to update an existing offer.
 * @param {*} data A JSON object which contains the updated infromation of the offer
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}offer`, data);
}

/**
 * The rest request which attempts to enable an existing offer.
 * @param {*} offerID The id of the offer to be enabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function enabled(offerID) {
    return await request.post(`${API}offer/${offerID}/enabled`);
}

/**
 * The rest request which attempts to disable an existing offer.
 * @param {*} offerID The id of the offer to be disabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function disable(offerID) {
    return await request.post(`${API}offer/${offerID}/disable`);
}

/**
 * The rest request to delete a specific offer.
 * @param {*} id The id of the offer to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}offer/${id}`);
}

const itemOfferApi = {
    fetchAll,
    fetchNew,
    fetchOne,
    create,
    update,
    enabled,
    disable,
    deleteById
}
export default itemOfferApi;