import {
    Apps,
    Article as ArticleIcon,
    Business as BusinessIcon,
    Email as EmailIcon,
    FeaturedPlayList as FeaturedPlayListIcon,
    Feedback as FeedbackIcon,
    Groups as GroupsIcon,
    Print as PrintIcon,
    Sms as SmsIcon,
    TableRestaurant as TableRestaurantIcon
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import analyticsApi from 'api/analytics';
import { ReceiptIcon, ShoppingCartIcon, WavingHandIcon } from 'assets/mui/MuiIcons';
import CustomCard from 'components/Card/CustomCard';
import { getDefaultAnalyticsGeneralObject, getDefaultAnalyticsOrderObject } from 'constants/defaultObjects';
import { Stack } from 'immutable';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * The AnalyticsGeneral, that display customized {@link Grid}to provide the list of analytics general detais
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AnalyticsGeneral({ search }) {
    const { t } = useTranslation();
    const [orderAnalyticsData, setOrderAnalyticsData] = useState(getDefaultAnalyticsOrderObject());
    const [generalData, setGeneralData] = useState(getDefaultAnalyticsGeneralObject());
    const [isLoaded, setIsLoaded] = useState(false);

    function getData() {
        analyticsApi.fetchGeneral(search).then((r) => {
            setGeneralData({ ...generalData, ...r.data.returnobject });
            setIsLoaded(true)
        }).catch((e) => {
            // console.log(e);
        })
        analyticsApi.fetchOrderAnalytics(search).then((r) => {
            setOrderAnalyticsData({ ...orderAnalyticsData, ...r.data.returnobject });
            setIsLoaded(true)
        }).catch((e) => {
            // console.log(e);
        })
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container gap={2} sx={{ textAlign: "center", placeContent: "center" }}>
            {isLoaded &&
                <>
                    <CustomCard label={t('customer.title')} icon={GroupsIcon} totalNumber={generalData.customersCounter !== undefined ? generalData.customersCounter : 0} />
                    <CustomCard label={t('employee.title')} icon={GroupsIcon} totalNumber={generalData.employeesCounter !== undefined ? generalData.employeesCounter : 0} />
                    <CustomCard label={t('equipment.title')} icon={TableRestaurantIcon} totalNumber={generalData.equipmentsCounter !== undefined ? generalData.equipmentsCounter : 0} />
                    <CustomCard label={t('section.title')} icon={Apps} totalNumber={generalData.sectionsCounter !== undefined ? generalData.sectionsCounter : 0} />
                    <CustomCard label={t('feedback.title')} icon={FeedbackIcon} totalNumber={generalData.equipmentsCounter !== undefined ? generalData.equipmentsCounter : 0} />
                    <CustomCard label={t('itemOrModifier.title')} icon={FeaturedPlayListIcon} totalNumber={generalData.itemsOrModifiersCounter !== undefined ? generalData.itemsOrModifiersCounter : 0} />
                    <CustomCard label={t('menus.title')} icon={ArticleIcon} totalNumber={generalData.menusCounter !== undefined ? generalData.menusCounter : 0} />
                    <CustomCard label={t('modifierList.title')} icon={FeaturedPlayListIcon} totalNumber={generalData.modifierListsCounter !== undefined ? generalData.modifierListsCounter : 0} />
                    <CustomCard label={t('outlet.title')} icon={BusinessIcon} totalNumber={generalData.outletsCounter !== undefined ? generalData.outletsCounter : 0} />
                    <CustomCard label={t('printer.title')} icon={PrintIcon} totalNumber={generalData.printersCounter !== undefined ? generalData.printersCounter : 0} />
                    <CustomCard label={t('request.title')} icon={WavingHandIcon} totalNumber={generalData.requestsCounter !== undefined ? generalData.requestsCounter : 0} />
                    <CustomCard label={t('order.title')} icon={ShoppingCartIcon} totalNumber={orderAnalyticsData.totalOrders !== undefined ? orderAnalyticsData.totalOrders : 0} />
                    <CustomCard label={t('label.smss')} icon={SmsIcon} totalNumber={parseInt((generalData.counters?.sms !== undefined ? generalData.counters.sms : 0))} />
                    <CustomCard label={t('label.emails')} icon={EmailIcon} totalNumber={parseInt(generalData.counters?.email !== undefined ? generalData.counters?.email : 0)} />
                    <CustomCard label={t('label.ticketCounter')} icon={ReceiptIcon} totalNumber={generalData.counters?.ticket !== undefined ? generalData.counters.ticket : 0} />
                    <CustomCard label={t('label.receiptTicketCounter')} icon={ReceiptIcon} totalNumber={generalData.counters?.receiptTicket !== undefined ? generalData.counters.receiptTicket : 0} />
                </>
            }
        </Grid>
    );
}

export default AnalyticsGeneral;