import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

/**
 * The Pie Chart Component. The pie chart accepts a single dimensional series.
 * Pie Charts are instrumental visualization tools useful in expressing data and 
 * information in terms of percentages, ratios. A pie chart is most effective when 
 * dealing with a small collection of data. It is constructed by clustering all the 
 * required data into a circular shaped frame wherein the data are depicted in slices.
 * The pie/donut slices can be crafted by patterns/gradients/images for increasing the 
 * visual appeal.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function PaymentBarChart({ height, series, title, subtitle, currency, isEmployees=false, categories }) {
    const { t } = useTranslation();

    const chartData = {
        options: {
            chart: {
                type: 'bar',
                stacked: true,
                zoom: {
                    enabled: true
                },
                toolbar: {
                    show: false
                },
                expandOnClick: true,
                redrawOnParentResize: true,
                redrawOnWindowResize: true,
                width: '100%'
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                labels: {
                    formatter: function (val) {
                        return `${val} ${currency}`
                    }
                },
                categories: isEmployees ? categories :[]
            },
            yaxis: {
                title: {
                    text: t('analytics.chart.employees.yTitle')
                },
                labels: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            dataLabels: {
                formatter: function (val) {
                    return `${parseFloat(val).toFixed(2)} ${currency}`
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return `${parseFloat(val).toFixed(2)} ${currency}`
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                show: false,
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            },
            title: {
                text: title,
                align: 'center',
                style: {
                    color: "#333333",
                    fontSize: "18px",
                    fill: "#333333",
                    fontFamily: "Lumanosimo",
                    fontWeight: 900,
                    paddingTop: "5px",
                    paddingBottom: "10px",
                    marginBottom: 0
                }
            },
            subtitle: {
                text: subtitle,
                align: 'center',
                style: {
                    color: "#666666",
                    fontSize: "12px",
                    fill: "#666666",
                    fontWeight: 400
                }
            },
        }
    };

    return (
        <div>
            <ReactApexChart
                series={series}
                type="bar"
                height={categories?.length <2 ? 150 :height}
                options={chartData.options}
            />
        </div>
    );
};

export default PaymentBarChart;