
import { Box, Collapse, Grid, SvgIcon } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import React from 'react';
import { fieldsetBox, fieldsetBoxContainer, fieldsetBoxLegendCollapsable } from 'themes/defaultThemes';
import { useState } from 'react';

/**
 * The CustomCollapsableFieldset, that display customized fieldset section with collapse functionality.
 * 
 * @param {*} children The children of the drawer
 * @param {string} label The fieldset label
 * @param {*} collapsable Callback that handles the collapse logic of the fieldset.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCollapsableFieldset({ children, label = "", isOpen }) {
   const [open, setOpen] = useState(isOpen);

   const collapseHandler = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   return (
      <Box component="fieldset" sx={fieldsetBox(open)}>
         <legend onClick={collapseHandler} style={fieldsetBoxLegendCollapsable()}><SvgIcon sx={{ fontSize: "16px", marginBottom: "-2px" }} component={!open ? AddIcon : RemoveIcon} />{label}</legend>
         <Collapse in={open}>
            <Grid container spacing={3} sx={fieldsetBoxContainer()}>
               {children}
            </Grid>
         </Collapse>
      </Box>
   );
}

export default CustomCollapsableFieldset;