import CloseRounded from '@mui/icons-material/CloseRounded';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ar from "assets/images/flags/ar.png";
import bg from "assets/images/flags/bg.png";
import cs from "assets/images/flags/cs.png";
import de from "assets/images/flags/de.png";
import el from "assets/images/flags/el.png";
import en from "assets/images/flags/en.png";
import es from "assets/images/flags/es.png";
import et from "assets/images/flags/et.png";
import fr from "assets/images/flags/fr.png";
import it from "assets/images/flags/it.png";
import lt from "assets/images/flags/lt.png";
import nl from "assets/images/flags/nl.png";
import pl from "assets/images/flags/pl.png";
import ro from "assets/images/flags/ro.png";
import ru from "assets/images/flags/ru.png";
import tr from "assets/images/flags/tr.png";
import uk from "assets/images/flags/uk.png";
import CustomFlagTextArea from 'components/Text/CustomFlagTextArea';

/**
 * The CustomTranslationsAreaDialog, that display a customized dialog to insert translated texts.
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {string} title the dialog title
 * @property {string} cancelLabel the dialog cancel label
 * @property {string} actionLabel the dialog action label
 * @property {function} action the function that handles the dialog action button click
 * @property {function} handleOpen the function that handles the dialog open state * 
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {string} title the dialog title
 * @property {string} message the dialog message
 * @property {string} message the dialog cancel label
 * @property {string} actionLabel the dialog action label
 * @property {function} action the function that handles the dialog action button click
 * @property {function} handleOpen the function that handles the dialog open state
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTranslationsAreaDialog({ isOpen = false, id = "", title, cancelLabel, actionLabel, translations, field, action, handleOpen }) {
    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpen) {
        setOpen(isOpen);
        handleOpen(isOpen, translations, field);
    }

    function handleAction() {
        let translations = {};
        let translationElements = document.querySelectorAll('[name^="translation."]');
        for (let i = 0; i < translationElements.length - 1/*last one is the loading*/; i++) {
            translations[translationElements[i].name.split(".")[1]] = translationElements[i].value
        }
        action(translations, field);
    }

    return (
        <Dialog
            id={id}
            open={open}
            onClose={() => handleDialogState(false)}
            aria-describedby="alert-dialog-slide-description"
            className="dialog-title"
        >
            <DialogTitle className="dialog-headers">
                {title}
                <IconButton onClick={() => handleDialogState(false)} sx={{ float: "right" }}>
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ backgroundColor: "white" }}>
                <CustomFlagTextArea country="el" flag={el} name="translation.el" id="el" defaultValue={translations.el} />
                <CustomFlagTextArea country="en" flag={en} name="translation.en" id="en" defaultValue={translations.en} />
                <CustomFlagTextArea country="de" flag={de} name="translation.de" id="de" defaultValue={translations.de} />
                <CustomFlagTextArea country="fr" flag={fr} name="translation.fr" id="fr" defaultValue={translations.fr} />
                <CustomFlagTextArea country="it" flag={it} name="translation.it" id="it" defaultValue={translations.it} />
                <CustomFlagTextArea country="ru" flag={ru} name="translation.ru" id="ru" defaultValue={translations.ru} />
                <CustomFlagTextArea country="uk" flag={uk} name="translation.uk" id="uk" defaultValue={translations.uk} />
                <CustomFlagTextArea country="es" flag={es} name="translation.es" id="es" defaultValue={translations.es} />
                <CustomFlagTextArea country="nl" flag={nl} name="translation.nl" id="nl" defaultValue={translations.nl} />
                <CustomFlagTextArea country="cs" flag={cs} name="translation.cs" id="cs" defaultValue={translations.cs} />
                <CustomFlagTextArea country="pl" flag={pl} name="translation.pl" id="pl" defaultValue={translations.pl} />
                <CustomFlagTextArea country="bg" flag={bg} name="translation.bg" id="bg" defaultValue={translations.bg} />
                <CustomFlagTextArea country="ar" flag={ar} name="translation.ar" id="ar" defaultValue={translations.ar} />
                <CustomFlagTextArea country="et" flag={et} name="translation.et" id="et" defaultValue={translations.et} />
                <CustomFlagTextArea country="lt" flag={lt} name="translation.lt" id="lt" defaultValue={translations.lt} />
                <CustomFlagTextArea country="ro" flag={ro} name="translation.ro" id="ro" defaultValue={translations.ro} />
                <CustomFlagTextArea country="tr" flag={tr} name="translation.tr" id="tr" defaultValue={translations.tr} />
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button className="dialog-cancel-btn" onClick={() => handleDialogState(false)}>{cancelLabel}</Button>
                <Button className="dialog-action-btn" onClick={handleAction}>{actionLabel.toUpperCase()}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CustomTranslationsAreaDialog;