import { SearchTwoTone as SearchTwoToneIcon } from '@mui/icons-material';
import { Box, Divider, Drawer, Grid, InputAdornment, OutlinedInput, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AddIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import ChatUserAvatar from 'components/Grid/chat/user/ChatUserAvatar';
import ChatChannelList from 'components/List/ChatChannelList';
import { drawerWidth, gridSpacing } from 'constants/theme';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

/**
 * The ChatDrawer, that display floor planner drawer.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ChatDrawer({ handleDrawerOpen, openChatDrawer,
    selectedChatChannel, setSelectedChatChannel, user,
    setOpenChatChannelDialog, chatChannels,
    setTab, filterChatsByName
}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:468px)');
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    // show menu to set current user status
    const [anchorEl, setAnchorEl] = useState();
    const handleClickRightMenu = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleCloseRightMenu = () => {
        setAnchorEl(null);
    };

    const handleRightMenuItemClick = (userStatus) => () => {
        handleCloseRightMenu();
    };


    const [viewMine, setViewMine] = useState(false);

    return (
        <Drawer
            sx={{
                width: (!isMobile) ? drawerWidth : (openChatDrawer ? drawerWidth : 0),
                flexShrink: 0,
                zIndex: { xs: 1100, lg: 0 },
                '& .MuiDrawer-paper': {
                    height: matchDownLG ? '100%' : 'auto',
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    position: 'relative',
                    border: 'none',
                    borderRadius: matchDownLG ? 'none' : `20px`
                }
            }}
            anchor="left"
            variant="persistent"
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
            open={openChatDrawer}
        >
            {openChatDrawer &&
                <>
                    <Box sx={{ p: 3, pb: 2 }}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                                    <Grid item>
                                        <ChatUserAvatar user={user} />
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography align="left" variant="span">
                                            {user?.fullName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <CustomIconButton
                                            icon={<AddIcon />}
                                            variant="outlined"
                                            keyValue="add"
                                            onClick={() => setOpenChatChannelDialog(true)}
                                            tooltipTitle={t('actions.add')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedInput
                                    fullWidth
                                    id="input-search-header"
                                    placeholder={t('actions.search')}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchTwoToneIcon fontSize="small" />
                                        </InputAdornment>
                                    }
                                    onChange={(event) => filterChatsByName(event.target.value)}
                                />
                            </Grid>
                            {/* BUTTONS for ticketing */}
                            <Grid xs={12} sx={{ height: "50px", marginLeft: "20px" }}>
                                <CustomIconButton
                                    isFocused={viewMine}
                                    variant="outlined"
                                    label={t('chat.tabs.mine')}
                                    backgroundColor={viewMine ? "#47a6d2" : "initial"}
                                    sx={{ width: "50%", height: "50px", color: viewMine ? "white" : "#47a6d2", textTransform: "initial", fontSize: "18px", fontFamily: "auto", letterSpacing: "2px" }}
                                    onClick={() => {
                                        setViewMine(true); setTab(true);
                                    }}
                                />
                                <CustomIconButton
                                    isFocused={!viewMine}
                                    variant="outlined"
                                    label={t('chat.tabs.all')}
                                    backgroundColor={!viewMine ? "#47a6d2" : "initial"}
                                    sx={{ width: "50%", height: "50px", color: !viewMine ? "white" : "#47a6d2", textTransform: "initial", fontSize: "18px", fontFamily: "auto", letterSpacing: "2px" }}
                                    onClick={() => {
                                        setViewMine(false); setTab(false);
                                    }}
                                />
                            </Grid>
                            <Divider />
                        </Grid>
                    </Box>
                    <PerfectScrollbar
                        style={{
                            overflowX: 'hidden',
                            height: matchDownLG ? 'calc(100vh - 190px)' : 'calc(100vh - 355px)',
                            minHeight: matchDownLG ? 0 : 520
                        }}
                    >
                        <Box sx={{ p: 3, pt: 0 }}>
                            <ChatChannelList setSelectedChatChannel={setSelectedChatChannel} chatChannels={chatChannels} selectedChatChannel={selectedChatChannel} />
                        </Box>
                    </PerfectScrollbar>
                </>
            }
        </Drawer>
    );
}

export default ChatDrawer;