import { Divider, Grid } from '@mui/material';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { subscriptionHistoryCardGridStyle, subscriptionHistoryDetailsLabelStyle, subscriptionHistoryDetailsTextPriceStyle, subscriptionHistoryDetailsTextStyle } from 'themes/defaultThemes';
import { findCurrency } from 'utils/functions';

/**
 * `SubscriptionHistoryInstanceOutletCharge` Component
 * 
 * Renders detailed information about charges specific to an outlet within a subscription's history.
 * 
 * This component is responsible for displaying various charge-related details such as email, ticket,
 * receipt ticket counters and their corresponding prices, and the total amount for each outlet.
 * 
 * Props:
 * - `outletCharge`: An object containing the charge details for an outlet. This object should
 *    include fields like outletID, emailCounter, ticketCounter, receiptTicketCounter, smsCounter,
 *    their respective prices, and the total amount.
 * - `currency` (default: "EUR"): A string indicating the currency in which the charges are denominated.
 * - `outletBranches`: An array or object containing information about outlet branches, used for
 *    rendering specific details like the name of the outlet.
 * 
 * The component uses a combination of Grid layout and CustomTypography components to display each
 * piece of information in a structured and styled manner. Internationalization is handled via
 * `useTranslation` for text display.
 * 
 * Example of `outletCharge` object structure:
 * ```
 * {
 *   outletID: "outlet_1",
 *   emailCounter: 10,
 *   emailPrice: 1.5,
 *   ticketCounter: 5,
 *   ticketPrice: 2,
 *   receiptTicketCounter: 3,
 *   receiptTicketPrice: 1,
 *   smsCounter: 20,
 *   smsPrice: 0.5,
 *   amount: 50
 * }
 * ```
 * 
 * @param {Object} props Component props
 * @returns {React.Component} SubscriptionHistoryInstanceOutletCharge component
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SubscriptionHistoryInstanceOutletCharge({ outletCharge, currency="EUR", outletBranches}) {
    const { t } = useTranslation();

    return (

        <Grid container sx={subscriptionHistoryCardGridStyle()}>
            {outletCharge?.outletID &&
                <>
                    <Grid item xs={7} md={7}>
                        <CustomTypography variant="body2" text={t('subscription.organization.history.outlet')} sx={subscriptionHistoryDetailsLabelStyle()} />
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <CustomTypography variant="body2" text={outletBranches?.find((outletBranch) => outletBranch?.outlet?.id === outletCharge?.outletID)?.outlet.name} sx={subscriptionHistoryDetailsTextStyle()} />
                    </Grid>
                </>
            }


            {outletCharge?.emailCounter !== undefined && outletCharge?.emailCounter !== null &&
                <>
                    <Grid item xs={7} md={7}>
                        <CustomTypography variant="body2" text={t('subscription.organization.history.emailCounter')} sx={subscriptionHistoryDetailsLabelStyle()} />
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <Grid container sx={{ paddingLeft: "10%", alignSelf: "center" }}>
                            <Grid item xs={5} md={5}>
                                <CustomTypography variant="body2" text={outletCharge.emailCounter} sx={subscriptionHistoryDetailsTextStyle()} />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <CustomTypography variant="body2" text={"*"} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                            <Grid item xs={5} md={5} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={`${outletCharge.emailPrice} ${findCurrency(currency)}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }

            {outletCharge?.ticketCounter !== undefined && outletCharge?.ticketCounter !== null &&
                <>
                    <Grid item xs={7} md={7}>
                        <CustomTypography variant="body2" text={t('subscription.organization.history.ticketCounter')} sx={subscriptionHistoryDetailsLabelStyle()} />
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <Grid container sx={{ paddingLeft: "10%", alignSelf: "center" }}>
                            <Grid item xs={5} md={5}>
                                <CustomTypography variant="body2" text={outletCharge.ticketCounter} sx={subscriptionHistoryDetailsTextStyle()} />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <CustomTypography variant="body2" text={"*"} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                            <Grid item xs={5} md={5} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={`${outletCharge.ticketPrice} ${findCurrency(currency)}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }

            {outletCharge?.receiptTicketCounter !== undefined && outletCharge?.receiptTicketCounter !== null &&
                <>
                    <Grid item xs={7} md={7}>
                        <CustomTypography variant="body2" text={t('subscription.organization.history.receiptTicketCounter')} sx={subscriptionHistoryDetailsLabelStyle()} />
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <Grid container sx={{ paddingLeft: "10%", alignSelf: "center" }}>
                            <Grid item xs={5} md={5}>
                                <CustomTypography variant="body2" text={outletCharge.receiptTicketCounter} sx={subscriptionHistoryDetailsTextStyle()} />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <CustomTypography variant="body2" text={"*"} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                            <Grid item xs={5} md={5} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={`${outletCharge.receiptTicketPrice} ${findCurrency(currency)}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }

            {outletCharge?.smsCounter !== undefined && outletCharge?.smsCounter !== null &&
                [
                    <Grid item xs={7} md={7}>
                        <CustomTypography variant="body2" text={t('subscription.organization.history.smsCounter')} sx={subscriptionHistoryDetailsLabelStyle()} />
                    </Grid>
                    ,
                    <Grid item xs={5} md={5}>
                        <Grid container sx={{ paddingLeft: "10%", alignSelf: "center" }}>
                            <Grid item xs={5} md={5}>
                                <CustomTypography variant="body2" text={outletCharge.smsCounter} sx={subscriptionHistoryDetailsTextStyle()} />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <CustomTypography variant="body2" text={"*"} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                            <Grid item xs={5} md={5} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={`${outletCharge.smsPrice} ${findCurrency(currency)}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                ]
            }


            {outletCharge?.amount !== null &&
                <>
                    <Grid item xs={7} md={7}>
                        {/* <CustomTypography variant="body2" text={t('subscription.organization.history.amount')} sx={subscriptionHistoryDetailsLabelStyle()} /> */}
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <Divider light flexItem sx={{ borderRightWidth: 5, width: "20%", marginBottom: "5px" }} />
                        <CustomTypography variant="body2" text={`${outletCharge.amount}  ${findCurrency(currency)}`} sx={subscriptionHistoryDetailsTextStyle()} />
                    </Grid>
                </>
            }
        </Grid>
    );
}

export default SubscriptionHistoryInstanceOutletCharge;