import { request } from 'constants/alias';
import { API } from "constants/config";

/**
 * The rest request which attempts to get the notifications list from the database.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @returns an object with an attribute containing the notifications list
 */
export async function fetchAll(pageable) {
    let config = {
        baseURL: process.env.REACT_APP_AXIOSBASE,
        params: pageable !== undefined ? pageable : {}
    }
    return await request.get(`${API}notification`, config);
}

/**
 * The rest request which attempts to get the notification settings from the database.
 * @returns an object with an attribute containing the notification settings
 */
export async function fetchSettings() {
    return await request.get(`${API}notification/settings`);
}

/**
 * The rest request which attempts to get the notification settings for administration from the database.
 * @returns an object with an attribute containing the notification settings
 */
export async function fetchSettingsAdmin() {
    return await request.get(`${API}administration/notification/settings`);
}

/**
 * The rest request which attempts to get the previous notifications from the database.
 * @param {*} id  The id of the notification to fetch the previous ones
 * @returns an object with an attribute containing the previous notifications list
 */
export async function fetchPrevious(id) {
    return await request.get(`${API}notification/previous/${id}`);
}

/**
 * The rest request which attempts to mark the notifications as seen.
 * @param {*} data A JSON object which contains the notification IDs list that will be markes as seen
 * @returns an object refering to the success or failure of the request
 */
export async function seen(data) {
    return await request.put(`${API}notification/seen`, data);
}

/**
 * The rest request which attempts to mark the notifications as read.
 * @param {*} data A JSON object which contains the notification IDs list that will be markes as read
 * @returns an object refering to the success or failure of the request
 */
export async function batchRead(data) {
    return await request.put(`${API}notification/batchread`, data);
}

/**
 * The rest request which attempts to dismiss a specific notification based on its ID
 * @param {*} id  The id of the notification to be dismissed
 * @returns an object refering to the success or failure of the request
 */
export async function dismiss(id) {
    return await request.put(`${API}notification/dismiss/${id}`);
}

/**
 * The rest request which attempts to mark a specific notification based on its ID and flag as read or unread.
 * @param {*} id  The id of the notification to be marked as read
 * @param {boolean} flag  The flag of the notification to be marked as read or not. If the value is `true` the notification will be marked as read.
 * @returns an object refering to the success or failure of the request
 */
export async function read(id, flag) {
    return await request.put(`${API}notification/read/${flag}/${id}`);
}

/**
 * The rest request which attempts to live update a notification.
 * @param {*} data A JSON object which contains the infromation of the notification
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}notification/liveupdate`, data);
}

/**
 * The rest request which attempts to update the notification settings.
 * @param {*} data A JSON object which contains the updated infromation of the notification settings
 * @returns an object refering to the success or failure of the request
 */
export async function updateSettings(data) {
    return await request.put(`${API}notification/settings`, data);
}

const notificationApi = {
    fetchAll,
    fetchSettings,
    fetchSettingsAdmin,
    fetchPrevious,
    seen,
    batchRead,
    dismiss,
    read,
    update,
    updateSettings
}

export default notificationApi;