import styled from '@emotion/styled';
import { CopyAll } from '@mui/icons-material';
import { Button, FormControlLabel, Grid, Switch } from '@mui/material';
import CodeMirror from "@uiw/react-codemirror";
import JsonViewer from "@uiw/react-json-view";
import Split from "@uiw/react-split";
import itemApi from 'api/item';
import { CloudUploadIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import CustomTypography from 'components/Typography/CustomTypography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exportItemDialogStyle } from 'themes/defaultThemes';
import { transformToFinalFormat } from 'utils/item';

/**
 * The ItemImportDialog, that display a customized dialog to import items from JSON format.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */

function ItemImportDialog({
    isOpen = false,
    handleClose,
    id = "",
    message,
    handleOpen,
    includeCloseIcon = false,
    counter,
    organizationID = "",
    getItems
}) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [includePhotos, setIncludePhotos] = useState(false);
    const [importedItemsJson, setImportedItemsJson] = useState([]);

    function copyToClipBoard() {
        const importedItemsStr = JSON.stringify(transformToFinalFormat(importedItemsJson, includePhotos, organizationID));
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = importedItemsStr;
        document.body.appendChild(textarea);

        // Select the text inside the textarea
        textarea.select();
        textarea.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text to the clipboard
        document.execCommand('copy');

        // Remove the temporary textarea
        document.body.removeChild(textarea);

        // Optionally, provide feedback to the user
        alert('Text copied to clipboard!');
    }

    function importItems() {
        itemApi.importJson(organizationID, transformToFinalFormat(importedItemsJson, includePhotos, organizationID)).then((r) => {
            if (r.data.code === "SUCCESS") {
                handleClose();
                getItems();
            }
        }).catch((e) => {
            // console.log(e);
        })
    }


    function handleFileUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const content = JSON.parse(e.target.result);
                console.log(content)
                setImportedItemsJson(content);
            } catch (error) {
                console.error('Error parsing JSON file:', error);
            }
        };

        reader.readAsText(file);
    }

    // =========================================== RENDER
    return (
        <CustomDialogBasedOnScreenChildren
            isOpen={open}
            aria-describedby="dialog"
            sx={exportItemDialogStyle()}
            cancelLabel={t('actions.cancel')}
            actionLabel={t('actions.import')}
            title={t('itemOrModifier.import.title')}
            children={
                <>
                    <Grid container gap={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={includePhotos}
                                        onChange={() => setIncludePhotos(!includePhotos)}
                                    />
                                }
                                sx={{ marginLeft: "20px" }}
                                label={t('itemOrModifier.export.includePhotos')}
                            />
                        </Grid>

                        <Grid item xs={5} sx={{ textAlign: "left", borderRight: "1px solid lightgrey" }}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: "center" }}>
                                    <CustomTypography text={t('itemOrModifier.export.editor')} variant="span" sx={{ fontWeight: "bold" }} />

                                    <CustomIconButton
                                        icon={<CopyAll />}
                                        onClick={() => copyToClipBoard()}
                                        tooltipTitle={t('itemOrModifier.export.copy')}
                                    />

                                    <Button
                                        component="label"
                                        role={undefined}
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                        sx={{
                                            backgroundColor: "#fff",
                                            color: "#2596be",
                                            margin: "0px",
                                            fontSize: "8px",
                                            fontWeight: "900",
                                            "&:hover": {
                                                backgroundColor: "#2596be",
                                                color: "white",
                                            },
                                            '.MuiButton-startIcon': {
                                                marginLeft: "0px"
                                            }
                                        }}
                                    >
                                        <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
                                    </Button>
                                </Grid>

                                <Grid item xs={10}>
                                    <Split direction="vertical" gutter>
                                        <CodeMirror
                                            value={JSON.stringify(importedItemsJson, null, 2)}
                                            options={{
                                                mode: 'javascript',
                                                theme: 'default',
                                                lineNumbers: true,
                                            }}
                                        />
                                    </Split>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={5} sx={{ textAlign: "left" }}>
                            <CustomTypography text={t('itemOrModifier.export.viewer')} variant="body2" sx={{ fontWeight: "bold", textAlign: "center" }} />
                            <Split direction="vertical" gutter>
                                <JsonViewer
                                    value={importedItemsJson}
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                    enableClipboard={false}
                                />
                            </Split>
                        </Grid>
                    </Grid>
                </>
            }
            handleClose={handleClose}
            id={id}
            message={message}
            counter={counter}
            includeCloseIcon={includeCloseIcon}
            handleOpen={handleOpen}
            handleAction={() => importItems()}
        />
    );
}

export default ItemImportDialog;