import { CloseRounded } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Button,
    CardMedia,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
    Grid,
    IconButton,
    ThemeProvider, Typography
} from '@mui/material';
import { DeleteIcon } from 'assets/mui/MuiIcons';
import CustomActionImage from 'components/Assets/CustomActionImage';
import CustomImageCropper from 'components/Assets/CustomImageCropper';
import CustomIconButton from 'components/Button/CustomIconButton';
import { getAssetDefaultObject } from 'constants/defaultObjects';
import { MINIO_HOST } from 'constants/hosts';
import { Stack } from 'immutable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cardItemButtonGroupStackButtonIcon, logoStyle } from 'themes/defaultThemes';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * The PhotosDialog, that display a customized dialog to for menu parent categories and categories
 * 
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {string} title the dialog title
 * @property {function} action the function that handles the dialog action button click
 * @property {function} handleOpen the function that handles the dialog open state
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function PhotosDialog({
    id = "", isOpen = false, title, organizationID,
    actionLabel, cancelLabel, handleOpen,
    field, dialogName, instance,
    updatePhoto, handleFileChange
}) {
    const { t } = useTranslation();
    // const [photos, setPhotos] = useState((instance?.photos) ? instance.photos : []);
    const [photos, setPhotos] = useState((instance?.photo) ? instance.photo : null);

    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal  should be open and visible.
     */
    function handleDialogState(open) {
        handleOpen(open, field, dialogName);
    }

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiFilledInput: {
                    styleOverrides: {
                        input: {
                            backgroundColor: "white",
                        },
                        root: {
                            backgroundColor: "white",
                            border: "1px solid grey"
                        }
                    }
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "white",
                            zIndex: 111111111,
                            padding: 0
                        }
                    }
                },
                MuiDialogContent: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "white",
                            paddingBottom: "100px"
                        }
                    }
                },
                MuiDialog: {
                    styleOverrides: {
                        paper: {
                            minWidth: "60%",
                            maxWidth: "90%",
                            minHeight: "70%",
                            maxHeight: "90%",
                            overflowY: "hidden",
                            // top: "10%",
                        },
                        container: {
                            textAlign: "-webkit-center"
                        }
                    }
                },
                MuiListItemText: {
                    styleOverrides: {
                        secondary: {
                            fontWeight: 700,
                            fontSize: "11px"
                        }
                    }
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        secondary: {
                            // height: "900px",
                            overflow: "scroll"
                        },
                        listbox: {
                            marginTop: 0,
                            padding: 0,
                            height: "100%"
                        },
                        popper: {
                            height: "fit-content"
                        },
                        paper: {
                            marginLeft: "25px",
                            marginRight: "25px",
                            // overflowY: "hidden",
                        }
                    }
                },
                MuiChip: {
                    styleOverrides: {
                        label: {
                            whiteSpace: "pre-wrap!important"
                        },
                        root: {
                            padding: "7px!important",
                            marginBottom: "10px",
                            height: "fit-content"
                        }
                    }
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            paddingRight: "50px",
                            zIndex: "1000000000"
                        }
                    }
                }
            }
        });
    }

    // ================================================ PHOTO

    /**
     * Callback that handles file upload change.
     * @param {string} fileBase64 the new base64 image 
     */
    function handleFileChange(fileBase64) {
        let newPhotos = photos;
        // newPhotos.push(getAssetDefaultObject(fileBase64, photos.length));
        newPhotos = getAssetDefaultObject(fileBase64, 0);
        setPhotos(newPhotos);
    }

    function updatePhotoHiddenState(event, index) {
        // const updatedPhotos = photos.map((photo, i) => {
        //     if (i === index) {
        //         return {
        //             ...photo,
        //             hidden: !photo.hidden,
        //         };
        //     }
        //     return photo;
        // });
        if (photos !== null) {
            const updatedPhotos = {
                ...photos,
                hidden: !photos.hidden,
            };
            setPhotos(updatedPhotos);
        }
    }

    function updatePhotoCoverState(event, index) {
        let updatedPhotos = photos.map((photo, i) => {
            return {
                ...photo,
                cover: (i === index) ? true : false
            };
        });
        setPhotos(updatedPhotos);
    }

    function deletePhoto(event, index) {
        // const updatedPhotos = photos;
        // if (index > -1) updatedPhotos.splice(index, 1);
        // setPhotos(updatedPhotos);
        setPhotos(null);
    }

    /**
    * The rest endpoint to update item photos order
    * 
    * @param {array} photos  the photos after reorder
    */
    function updatePhotoOrder(photos) {
        setPhotos(photos);
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Dialog
                id={id}
                open={isOpen}
                onClose={() => handleDialogState(false)}
                aria-describedby="dialog-slide-description"
                className="dialog-title"
            >
                <DialogTitle className="dialog-headers" style={{ textAlignLast: "center" }}>
                    <Typography variant="title" sx={{ fontSize: "18px" }}>{`${title}${t('actions.for')}${t(`menus.cms.${field.field}`)}: `}</Typography>
                    <Typography variant="title" sx={{ color: "#205d7b", fontSize: "19px" }}>{instance?.name?.en}</Typography>
                    <IconButton onClick={() => handleDialogState(false)} sx={{ float: "right" }}>
                        <CloseRounded />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ marginBottom: "80px", marginTop: "20px" }} id="dialog-content">
                    {/* <PhotoComponent
                        photos={photos}
                        showEmptyMessage={true}
                        emptyMessage={t('assets.emptyPhotos')}
                        imagePath={`${MINIO_HOST}/${organizationID}/${field}/${field === "parentCategory" ? field.parentCategoryId : field.categoryId}/`}
                        updatePhotoHiddenState={updatePhotoHiddenState}
                        updatePhotoCoverState={updatePhotoCoverState}
                        deletePhoto={deletePhoto}
                        handleFileChange={handleFileChange}
                        updatePhotoOrder={updatePhotoOrder}
                    /> */}

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Grid sx={{ width: "fit-content" }}>
                                {(photos !== null && photos?.imageSrc !== "") &&
                                    <CustomActionImage
                                        {...photos}
                                        // viewImage={handleDialogState}
                                        imagePath={`${MINIO_HOST}organization/${organizationID}/${field.field}/${field.field === "parentCategory" ? field.parentCategoryId : field.categoryId}/`}
                                        setHidden={(event, index) => updatePhotoHiddenState(event, index)}
                                        deleteImage={(event, imageSrc, index) => deletePhoto(event, index)}
                                        index={0}
                                    />
                                }

                                {(photos === null || photos?.imageSrc === "") &&
                                    <Button>
                                        {/* Choose File */}
                                        <CustomImageCropper storeCroppedImage={handleFileChange} />
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    <Button
                        className="dialog-cancel-btn"
                        sx={{
                            textTransform: "none !important",
                            backgroundColor: "#ebf5f9",
                            ":hover": {
                                backgroundColor: "#ebf5f9"
                            }
                        }}
                        onClick={() => handleDialogState(false)}>{cancelLabel}
                    </Button>

                    <Button
                        className="dialog-action-btn"
                        sx={{
                            textTransform: "none !important",
                            marginLeft: "40px!important",
                            backgroundColor: "#205d7b",
                            color: "white !important",
                            ":hover": {
                                backgroundColor: "#205d7b",
                                color: "white !important"
                            }
                        }}
                        onClick={() => updatePhoto(photos, field, instance)}>
                        {actionLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default PhotosDialog;
