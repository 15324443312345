import { Grid } from "@mui/material";
import vivaPaymentApi from "api/vivaPayment";
import { LottieFail } from "assets/lottie/LottieAnimations";
import { ArrowBackIcon } from "assets/mui/MuiIcons";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTypography from "components/Typography/CustomTypography";
import { lottieDefaultOptions } from "constants/animations/lottieOptions";
import Lottie from "lottie-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { mainTag, orderBackBtnStyle, orderDiscountTextStyle } from 'themes/defaultThemes';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";
import WebFont from "webfontloader";

/**
 * The FailPaymentComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function FailPaymentComponent() {
    const { t } = useTranslation();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const tid = params.get("tid");//transaction id
    const navigate = useNavigate();

    //change document title
    useDocumentTitle(t('subscription.organization.payment.fail.label'));

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Lumanosimo']
            }
        });

        checkForVerifiedPayment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function checkForVerifiedPayment() {
        vivaPaymentApi.checkForVerifiedPaymentSubscriptionRecurrent(tid).then((checked) => {
            if (checked)
                navigate(`/${getLanguageFromURL()}/subscription`)
            else
                console.log("fail")
            // setAlertBox({
            //     isOpen: true,
            //     message: t("subscription.paymentFailure"),
            //     backgroundColor: "#a71313"
            // });
        }).catch((e) => {
            // console.log(e);
        });
    }
    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="failed-payemnt" sx={mainTag()}>

            <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                <CustomIconButton
                    icon={<ArrowBackIcon />}
                    sx={orderBackBtnStyle()}
                    onClick={() => navigate(`/${getLanguageFromURL()}/subscription`)}
                    label={t('subscription.organization.payment.fail.backButton')}
                />
            </Grid>

            <Grid item xs={12} md={12} sx={{ textAlign: "-webkit-center" }}>
                <Lottie
                    {...lottieDefaultOptions}
                    animationData={LottieFail}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <CustomTypography variant="body2" text={t('subscription.organization.payment.fail.infoPayment')} sx={{ ...orderDiscountTextStyle(), textAlign: "-webkit-center" }} />
            </Grid>
        </CustomGrid>
    );
}

export default FailPaymentComponent;