import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to get the printers list from the database.
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the printers list
 */
export async function fetchAll(search) {
    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "" && search.organizationID !== null) {
        data = { organizationID: search.organizationID }
    }
    if (search.outletID !== undefined && search.outletID !== "" && search.outletID !== null) {
        data = { ...data, outletID: search.outletID }
    }
    if (search.printNodeId !== undefined && search.printNodeId !== "") {
        data = { ...data, printNodeId: search.printNodeId }
    }
    if (search.friendlyName !== undefined && search.friendlyName !== "") {
        data = { ...data, friendlyName: search.friendlyName }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}printer`, data));
    else return await request.get(includeParamsToUrl(`${API}printer`));
}

/**
 * The rest request which return required information to be presented on add new printer page.
 * @param {*} organizationID The id of the printer organization id
 * @param {*} outletID The id of the printer outlet id
 * @returns an object with an attribute containing the requested information to be presented on the printer page
 */
export async function fetchNew(organizationID, outletID) {
    let data = {};
    if (outletID !== null && outletID !== "") {
        data.outletID = outletID;
    }
    if (organizationID !== null && organizationID !== "") {
        data.organizationID = organizationID;
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}printer/add`, data));
    else return await request.get(includeParamsToUrl(`${API}printer/add`));
}

/**
 * The rest request which attempts to get an existing printer information.
 * @param {*} organizationID The id of the printer organization id
 * @param {*} outletID The id of the printer outlet id
 * @param {*} id The id of the printer to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the printer
 */
export async function fetchOne(organizationID, outletID, id) {
    let data = {};
    if (outletID !== null && outletID !== "") {
        data.outletID = outletID;
    }
    if (organizationID !== null && organizationID !== "") {
        data.organizationID = organizationID;
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}printer/${id}`, data));
    else return await request.get(includeParamsToUrl(`${API}printer/${id}`));
}

/**
 * The rest request which attempts to create a new printer.
 * @param {*} data A JSON object which contains new printer infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}printer`, data);
}

/**
 * The rest request which attempts to update an existing printer.
 * @param {*} data A JSON object which contains the updated infromation of the printer
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}printer`, data);
}

/**
 * The rest request which attempts to enable an existing printer.
 * @param {*} printerID The id of the printer to be enabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function action(printerID, action) {
    return await request.post(`${API}printer/${printerID}/${action}`);
}

export async function exist(printNodeId) {
    let data = { printNodeId: printNodeId };
    return await request.get(includeParamsToUrl(`${API}printer/exist`, data));
}

/**
 * The rest request to delete a specific printer.
 * @param {*} id The id of the printer to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}printer/${id}`);
}

const printerApi = {
    fetchAll,
    fetchNew,
    fetchOne,
    create,
    update,
    action,
    deleteById,
    exist
}

export default printerApi;