import { Fab, Grid } from "@mui/material";
import menuApi from "api/menu";
import menuCategoryApi from "api/menuCategory";
import menuParentCategoryApi from "api/menuParentCategory";
import { SaveIcon } from "assets/mui/MuiIcons";
import CustomBreadcrumbMultiParent from "components/Breadcrumb/CustomBreadcrumbMultiParent";
import CustomButton from "components/Button/CustomButton";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomCheckbox from "components/Checkbox/CustomCheckbox";
import AttachItemOrModifierDialog from "components/Dialogs/AttachItemOrModifierDialog";
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";
import PhotosDialog from "components/Dialogs/photo/PhotosDialog";
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomMenuParentCategoriesDraggableTable from "components/Table/CustomMenuParentCategoriesDraggableTable";
import CustomTextField from "components/Text/CustomTextField";
import CustomTypography from "components/Typography/CustomTypography";
import { getTranslationDefaultObject } from "constants/defaultObjects";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, lumanosimoTitleStyle, mainArea, mainTag, mainTagBreadcrumb } from "themes/defaultThemes";
import { hasRoleAdminMenu } from 'utils/auth';
import { formValChangeWithParentElement } from "utils/form-validation";
import { findAllObjectsMatchInArrayOrdered } from "utils/functions";
import { generateObjectId } from "utils/functions/mongo";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The Menu Categories List component to view the menu categoriess of the system.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function MenuCategoriesComponent() {
    const navigate = useNavigate();
    const { organizationID } = useParams();
    const { menuID } = useParams();
    const { t } = useTranslation();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    path += `/menu`;

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {array} menuCategories the menu categories (pageable)
     * @property {object} organization the menu's organization details
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     * @property {object} search includes all pageable details (page, size, sort, direction)
     * @property {object} alertBox holds snackbar infromation and style
     */
    const [data, setData] = useState({
        userAuth: null,
        menuCategories: null,
        categoryName: "",
        organization: {
            id: organizationID
        },
        menu: {
            id: menuID,
            name: ""
        },
        parentCategories: [],
        categories: [],
        outlets: [],
        items: [],
        isError: {
            parentCategoryName: ""
        },
        draggable: {
            expandAll: true
        }
    });

    //change document title
    useDocumentTitle(`Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t('org.pageTitle') : ""} | ${t('menus.pageTitle')} | ${t('menuCategories.title')}`);


    // isLoaded to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        message: t("label.configureTranslations"),
        id: "",
        field: "",
        instance: "",
        translations: {}
    });

    // pop dialog for attach
    const [attachDialog, setAttachDialog] = useState({
        isOpen: false,
        field: {}
    });

    // pop dialog for photos
    const [photosDialog, setPhotosDialog] = useState({
        isOpen: false,
        instance: {},
        field: {}
    });

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });
    const [autoSave, setAutoSave] = useState(false);

    const [showAdd, setShowAdd] = useState(false);

    useEffect(() => {
        getMenuCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (autoSave)
            updateMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    /**
     * The rest endpoint to get the menu list.
     */
    function getMenuCategories() {
        menuApi.fetchOneCategories(organizationID, menuID).then((r) => {
            let menu = r.data.returnobject.menu;
            let parentCategories = r.data.returnobject.parentCategories;

            if (!menu.settings.allowParentCategories) {
                if (menu.parentCategoryIds.length === 1 && data.parentCategories.find((parentCategory) => parentCategory.id === menu.parentCategoryIds[0])?.name.en === "default") {
                    let newParentCategory = {
                        id: generateObjectId(),
                        organizationID: data.organization.id,
                        name: {
                            ...getTranslationDefaultObject(),
                            en: "Parent category 1"
                        },
                        active: true,
                        categoryIds: []
                    }
                    parentCategories.push(newParentCategory);
                    menu.parentCategoryIds.push(newParentCategory.id)
                } else if (menu.parentCategoryIds.length === 0) {
                    let newParentCategoryDefault = {
                        id: generateObjectId(),
                        organizationID: data.organization.id,
                        name: {
                            ...getTranslationDefaultObject(),
                            en: "default"
                        },
                        active: true,
                        categoryIds: []
                    }
                    let newParentCategory = {
                        id: generateObjectId(),
                        organizationID: data.organization.id,
                        name: {
                            ...getTranslationDefaultObject(),
                            en: "Parent category 1"
                        },
                        active: true,
                        categoryIds: []
                    }
                    parentCategories.push(newParentCategoryDefault);
                    parentCategories.push(newParentCategory);
                    menu.parentCategoryIds.push(newParentCategoryDefault.id);
                    menu.parentCategoryIds.push(newParentCategory.id)
                }
            }

            setData({
                ...data,
                organization: r.data.returnobject.organization,
                menu: menu,
                outlets: r.data.returnobject.outlets,
                items: r.data.returnobject.items,
                parentCategories: parentCategories,
                categories: r.data.returnobject.categories,
                userAuth: r.data.returnobject.userAuth
            });
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }

    function updateMenu() {
        let requestData = {
            menu: data.menu,
            parentCategories: data.parentCategories,
            categories: data.categories
        }
        menuApi.updateCategories(requestData).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            getMenuCategories();
        })
    }

    /**
     * The rest endpoint to update a menu categories order
     * 
     * @param {array} parentCategories  the new parent categories list after reorder
     */
    function updateMenuParentCategoriesOrder(parentCategories) {
        let newParentCategories = {
            ...data.menu,
            parentCategoryIds: parentCategories.map(parentCategory => parentCategory.id)
        }
        if (autoSave) {
            menuApi.update(newParentCategories).then((r) => {
                setData({
                    ...data,
                    menu: {
                        ...newParentCategories
                    }
                });
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
            }).catch((e) => {
                // console.log(e);
            })
        } else {
            setData({
                ...data,
                menu: {
                    ...newParentCategories
                }
            });
        }
    }

    /**
     * The rest endpoint to update a menu category name
     * 
     * @param {object} parentCategory the parent category instance
     * @param {string} name the new name
     */
    function updateParentCategoryName(parentCategory, name) {
        parentCategory.name.en = name;
        let newParentCategories = data.parentCategories.map(obj => (parentCategory.id === obj.id) ? parentCategory : obj);
        if (name !== "") {
            if (autoSave) {
                menuParentCategoryApi.update(parentCategory).then((r) => {
                    setData({
                        ...data,
                        parentCategories: newParentCategories
                    });
                    setAlertBox({
                        isOpen: true,
                        message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                }).catch((e) => {
                    // console.log(e);
                });
            } else {
                setData({
                    ...data,
                    parentCategories: newParentCategories
                });
            }
        }
    }

    /**
     * The rest endpoint to update a menu category name translations
     * 
     * @param {object} parentCategory the parent category instance
     * @param {object} translations the new name translations 
     */
    function updateParentCategoryNameTranslations(parentCategory, translations) {
        parentCategory.name = translations;
        let newParentCategories = data.parentCategories.map(obj => (parentCategory.id === obj.id) ? parentCategory : obj);
        if (translations.en !== "") {
            if (autoSave) {
                menuParentCategoryApi.update(parentCategory).then((r) => {
                    setData({
                        ...data,
                        parentCategories: newParentCategories
                    });
                    setDialog({
                        ...dialog,
                        translations: {},
                        field: "",
                        instance: {},
                        isOpen: false
                    });
                    setAlertBox({
                        isOpen: true,
                        message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                }).catch((e) => {
                    // console.log(e);
                });
            } else {
                setData({
                    ...data,
                    parentCategories: newParentCategories
                });
            }
        }
    }

    /**
     * The rest endpoint to activate/disable a menu parent category
     * @param {object} parentCategory the parent category instance
     * @param {boolean} isActive the new isActive state
     */
    function updateParentCategoryIsActive(parentCategory, isActive) {
        let functionName = isActive ? "activate" : "disable"
        parentCategory.active = isActive;
        if (autoSave) {
            menuParentCategoryApi[functionName](parentCategory.id).then((r) => {
                let newParentCategories = data.parentCategories.map(obj => (parentCategory.id === obj.id) ? parentCategory : obj);
                setData({
                    ...data,
                    parentCategories: newParentCategories
                });
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
            }).catch((e) => {
                // console.log(e);
            });
        } else {

        }
    }

    /**
     * The rest endpoint to update a menu category name translations
     * 
     * @param {object} parentCategory the parent category instance
     * @param {array} photos the new photos 
     */
    function updateParentCategoryPhotos(parentCategory, photos) {
        parentCategory.photo = photos;
        let newParentCategories = data.parentCategories.map(obj => (parentCategory.id === obj.id) ? parentCategory : obj);

        if (autoSave) {
            menuParentCategoryApi.update(parentCategory).then((r) => {
                setData({
                    ...data,
                    parentCategories: newParentCategories
                });
                setPhotosDialog({
                    ...photosDialog,
                    isOpen: false,
                    field: {},
                    instance: {}
                });
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            setData({
                ...data,
                parentCategories: newParentCategories
            });
            setPhotosDialog({
                ...photosDialog,
                isOpen: false,
                field: {},
                instance: {}
            });
        }
    }


    /**
     * Include a parent Category
     */
    function addParentCategory(name) {
        let parentCategoryName = name ? name : document.getElementById("parentCategoryName").value;
        let parentCategory = {
            organizationID: data.organization.id,
            name: {
                ...getTranslationDefaultObject(),
                en: parentCategoryName
            },
            active: true,
            categoryIds: []
        }
        let newIds = data.menu.parentCategoryIds;
        let newParentCategories = data.parentCategories;

        if (parentCategoryName !== "")
            if (autoSave) {
                menuParentCategoryApi.create(parentCategory).then((r) => {
                    newIds.push(r.data.returnobject.id);
                    newParentCategories.push(r.data.returnobject);
                    if (!name) {
                        document.getElementById("parentCategoryName").value = "";
                        document.getElementById("parentCategoryName").error = false;
                    }
                    setData({
                        ...data,
                        menu: {
                            ...data.menu,
                            parentCategoryIds: newIds
                        },
                        parentCategories: newParentCategories,
                        isError: {
                            ...data.isError,
                            parentCategory: ""
                        }
                    });
                    setAlertBox({
                        isOpen: true,
                        message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                    return parentCategory;
                }).catch((e) => {
                    // console.log(e);
                });
            }
            else {
                let newParentCategory = {
                    id: generateObjectId(),
                    ...parentCategory
                }
                if (!name) {
                    document.getElementById("parentCategoryName").value = "";
                    document.getElementById("parentCategoryName").error = false;
                }
                newIds.push(newParentCategory.id);
                newParentCategories.push(newParentCategory);
                setData({
                    ...data,
                    menu: {
                        ...data.menu,
                        parentCategoryIds: newIds
                    },
                    parentCategories: newParentCategories,
                    isError: {
                        ...data.isError,
                        parentCategoryName: t('form.requiredField')
                    }
                });
                return newParentCategory;
            }
    }

    /**
     * Function that triggers the removal of a menu parent category.
     * 
     * @param {*} parentCategoryToDelete the parent category instance to be deleted
     */
    function removeParentCategory(parentCategoryToDelete) {
        let parentCategoryIds = data.menu.parentCategoryIds;
        const index = parentCategoryIds.indexOf(parentCategoryToDelete.id);
        // only splice array when item is found
        if (index > -1) parentCategoryIds.splice(index, 1); // 2nd parameter means remove one item only
        let newData = findAllObjectsMatchInArrayOrdered(parentCategoryIds, data.parentCategories);

        if (autoSave) {
            menuParentCategoryApi.deleteById(parentCategoryToDelete.id).then((r) => {
                setData({
                    ...data,
                    menu: {
                        ...data.menu,
                        parentCategoryIds: parentCategoryIds
                    },
                    parentCategories: newData
                });
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            setData({
                ...data,
                menu: {
                    ...data.menu,
                    parentCategoryIds: parentCategoryIds
                },
                parentCategories: newData
            });
        }
    }
    // ================================== Categories

    /**
     * Include a category to parent Category
     *
     * @param {object} parentCategory the parent category instance
     */
    function addCategoryToParentCategory(parentCategory) {
        let categoryName = document.getElementById(`${parentCategory.id}-category-name-new`).value;
        let category = {
            organizationID: data.organization.id,
            name: {
                ...getTranslationDefaultObject(),
                en: categoryName
            },
            itemIds: [],
            active: true
        }

        if (categoryName !== "") {
            document.getElementById(`${parentCategory.id}-category-name-new`).error = false;
            let newIds = parentCategory.categoryIds;
            let newCategories = data.categories;

            if (autoSave) {
                // create new category
                menuCategoryApi.create(category).then((r) => {
                    newIds.push(r.data.returnobject.id);

                    newCategories.push(r.data.returnobject);
                    parentCategory.categoryIds = newIds;

                    document.getElementById(`${parentCategory.id}-category-name-new`).value = "";
                    // assign category to Parent category
                    menuParentCategoryApi.update(parentCategory).then((r) => {
                        setData({
                            ...data,
                            parentCategories: data.parentCategories.map(obj => (parentCategory.id === obj.id) ? parentCategory : obj),
                            categories: newCategories
                        });
                        setAlertBox({
                            isOpen: true,
                            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                        });
                    }).catch((e) => {
                        // console.log(e);
                    });
                }).catch((e) => {
                    // console.log(e);
                });
            } else {
                let newCategory = {
                    id: generateObjectId(),
                    ...category
                }
                newIds.push(newCategory.id);
                newCategories.push(newCategory);
                parentCategory.categoryIds = newIds;

                document.getElementById(`${parentCategory.id}-category-name-new`).value = "";
                setData({
                    ...data,
                    parentCategories: data.parentCategories.map(obj => (parentCategory.id === obj.id) ? parentCategory : obj),
                    categories: newCategories
                });
            }
        } else {
            document.getElementById(`${parentCategory.id}-category-name-new`).error = true;
        }
    }

    function addInitCategoryToNewParentCategory() {
        let categoryName = document.getElementById(`init-category-name-new`).value;
        let category = {
            organizationID: data.organization.id,
            name: {
                ...getTranslationDefaultObject(),
                en: categoryName
            },
            itemIds: [],
            active: true
        }

        if (categoryName !== "") {
            let parentCategory = addParentCategory("Category 1")
            document.getElementById(`init-category-name-new`).error = false;
            let newIds = parentCategory.categoryIds;
            let newCategories = data.categories;

            if (autoSave) {
                // create new category
                menuCategoryApi.create(category).then((r) => {
                    newIds.push(r.data.returnobject.id);

                    newCategories.push(r.data.returnobject);
                    parentCategory.categoryIds = newIds;

                    document.getElementById(`init-category-name-new`).value = "";
                    // assign category to Parent category
                    menuParentCategoryApi.update(parentCategory).then((r) => {
                        setData({
                            ...data,
                            parentCategories: data.parentCategories.map(obj => (parentCategory.id === obj.id) ? parentCategory : obj),
                            categories: newCategories
                        });
                        setAlertBox({
                            isOpen: true,
                            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                        });
                    }).catch((e) => {
                        // console.log(e);
                    });
                }).catch((e) => {
                    // console.log(e);
                });
            } else {
                let newCategory = {
                    id: generateObjectId(),
                    ...category
                }
                newIds.push(newCategory.id);
                newCategories.push(newCategory);
                parentCategory.categoryIds = newIds;

                document.getElementById(`init-category-name-new`).value = "";
                setData({
                    ...data,
                    parentCategories: data.parentCategories.map(obj => (parentCategory.id === obj.id) ? parentCategory : obj),
                    categories: newCategories
                });
            }
        } else {
            document.getElementById(`init-category-name-new`).error = true;
        }
    }
    /**
     * The rest endpoint to update a menu parent-category categories order
     * 
     * @param {string} parentCategoryId the parent category id
     * @param {array} categories  the new parent categories list after reorder
     */
    function updateMenuCategoriesOrder(parentCategoryId, categories) {
        let parentCategory = data.parentCategories?.find((parentCategory) => parentCategory.id === parentCategoryId);
        parentCategory.categoryIds = categories.map(obj => obj.id);
        let newParentCategories = data.parentCategories.map(obj => (parentCategory.id === obj.id) ? parentCategory : obj);
        if (autoSave) {
            menuParentCategoryApi.update(parentCategory).then((r) => {
                setData({
                    ...data,
                    parentCategories: newParentCategories
                })
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            setData({
                ...data,
                parentCategories: newParentCategories
            })
        }
    }

    /**
    * The rest endpoint to update a menu parent-category categories items order
    * 
    * @param {string} parentCategoryId the parent category id
    * @param {array} categories  the new parent categories list after reorder
    */
    function updateItemOrder(items, categoryId) {
        let category = data.categories?.find((category) => category.id === categoryId);
        if (!category.hasOwnProperty("itemIds")) category.itemIds = [];
        category.itemIds = items?.map(obj => obj.id);
        let newCategories = data.categories?.map(obj => (category.id === obj.id) ? category : obj);
        if (autoSave) {
            menuCategoryApi.update(category).then((r) => {
                setData({
                    ...data,
                    categories: newCategories
                })
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            setData({
                ...data,
                categories: newCategories
            })
        }
    }

    /**
     * The rest endpoint to update a category's name
     * 
     * @param {object} category the category instance
     * @param {string} name the new name
     */
    function updateCategoryName(category, name) {
        if (name !== "") {
            category.name.en = name;
            let newCategories = data.categories.map(obj => (category.id === obj.id) ? category : obj);
            if (autoSave) {
                menuCategoryApi.update(category).then((r) => {
                    setData({
                        ...data,
                        categories: newCategories,
                    });
                    setAlertBox({
                        isOpen: true,
                        message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                }).catch((e) => {
                    // console.log(e);
                });
            } else {
                setData({
                    ...data,
                    categories: newCategories,
                });
            }
        }
    }

    /**
     * The rest endpoint to update a menu category name translations
     * 
     * @param {object} category the category instance
     * @param {object} translations the new name translations 
     */
    function updateCategoryNameTranslations(category, translations) {
        if (translations.en !== "") {
            category.name = translations;
            let newCategories = data.categories.map(obj => (category.id === obj.id) ? category : obj);
            if (autoSave) {
                menuCategoryApi.update(category).then((r) => {
                    setData({
                        ...data,
                        categories: newCategories
                    });
                    setDialog({
                        ...dialog,
                        translations: {},
                        field: "",
                        instance: {},
                        isOpen: false
                    });
                    setAlertBox({
                        isOpen: true,
                        message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                }).catch((e) => {
                    // console.log(e);
                });
            } else {
                setData({
                    ...data,
                    categories: newCategories
                });
            }
        }
    }

    /**
     * The rest endpoint to activate/disable a menu category
     * @param {object} category the category instance
     * @param {boolean} isActive the new isActive state
     */
    function updateCategoryIsActive(category, isActive) {
        let functionName = isActive ? "activate" : "disable"
        category.active = isActive;
        let newCategories = data.categories.map(obj => (category.id === obj.id) ? category : obj);
        if (autoSave) {
            menuCategoryApi[functionName](category.id).then((r) => {
                setData({
                    ...data,
                    categories: newCategories
                });
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            setData({
                ...data,
                categories: newCategories
            });
        }
    }

    /**
     * Function that triggers the removal of a category.
     * 
     * @param {string} parentCategoryId the parent category id
     * @param {object} categoryToDelete the category instance to be deleted
     */
    function removeCategory(parentCategoryId, categoryToDelete) {
        let newCategories = data.categories;
        const index = newCategories.indexOf(categoryToDelete.id);
        // only splice array when item is found
        if (index > -1) newCategories.splice(index, 1); // 2nd parameter means remove one item only

        let newParentCategories = data.parentCategories.map((obj) => {
            if (parentCategoryId === obj.id) {
                const categoryIndex = obj.categoryIds.indexOf(categoryToDelete.id);
                if (categoryIndex > -1) obj.categoryIds.splice(index, 1)

            }
            return obj;
        });

        if (autoSave) {
            menuCategoryApi.deleteById(categoryToDelete.id).then((r) => {
                setData({
                    ...data,
                    parentCategories: newParentCategories,
                    categories: newCategories
                });
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            setData({
                ...data,
                parentCategories: newParentCategories,
                categories: newCategories
            });
        }
    }

    /**
     * The rest endpoint to update a menu category photos
     * 
     * @param {object} ategory the category instance
     * @param {array} photos the new photos 
     */
    function updateCategoryPhotos(category, photos) {
        category.photo = photos;
        let newCategories = data.categories.map(obj => (category.id === obj.id) ? category : obj);

        if (autoSave) {
            menuCategoryApi.update(category).then((r) => {
                setData({
                    ...data,
                    categories: newCategories
                });
                setPhotosDialog({
                    ...photosDialog,
                    isOpen: false,
                    field: {},
                    instance: {}
                });
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            setData({
                ...data,
                categories: newCategories
            });
            setPhotosDialog({
                ...photosDialog,
                isOpen: false,
                field: {},
                instance: {}
            });
        }
    }


    // =====================================================
    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     * @property {object} instance The instance details to have name field translated
     */
    function handleTranslationsDialogState(isOpen, field = "", instance) {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            field: field,
            instance: instance,
            translations: instance.name
        });
    }

    function addItemToCategory(instances, field) {
        let newCategories = data.categories.map((obj) => {
            if (field.categortId === obj.id) {
                obj.itemIds.push(instances.map(instance => instance.id));
            }
            return obj;
        });
        let category = data.categories.find((category) => category.id === field.categoryId);
        category.itemIds = [...category.itemIds, ...instances.map(instance => instance.id)];

        if (autoSave) {
            menuCategoryApi.update(category).then((r) => {
                setData({
                    ...data,
                    categories: newCategories
                });
                setAttachDialog({
                    ...attachDialog,
                    isOpen: false,
                    field: {}
                });
            })
        } else {
            setData({
                ...data,
                categories: newCategories
            });
            setAttachDialog({
                ...attachDialog,
                isOpen: false,
                field: {}
            });
        }
    }

    /**
     * Function that triggers the removal of a item.
     * 
     * @param {string} categoryId the category id that the item is attached
     * @param {object} itemToDelete the item instance to be deleted
     */
    function removeItemFromCategory(event, itemToDelete, categoryId) {
        let category = data.categories.find((category) => category.id === categoryId);

        let newItems = category.itemIds;
        const index = newItems.indexOf(itemToDelete.id);
        // only splice array when item is found
        if (index > -1) newItems.splice(index, 1); // 2nd parameter means remove one item only

        category.itemIds = newItems;

        let newCategories = data.categories.map((obj) => {
            if (category.id === obj.id) {
                const itemIndex = obj.itemIds.indexOf(itemToDelete.id);
                if (itemIndex > -1) obj.itemIds.splice(index, 1)

            }
            return obj;
        });

        if (autoSave) {
            menuCategoryApi.update(category).then((r) => {
                setData({
                    ...data,
                    categories: newCategories
                });
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            setData({
                ...data,
                categories: newCategories
            });
        }
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     * @property {string} dialogName The dialog name
     */
    function handleDialogState(isOpen, field, dialogName = "dialog") {
        let newData = {
            isOpen: isOpen,
            field: field
        }

        if (dialogName === "dialog")
            setDialog({
                ...dialog,
                ...newData
            });
        else if (dialogName === "photosDialog") {
            setPhotosDialog({
                ...photosDialog,
                ...newData,
                instance: field.field === "parentCategory" ? data.parentCategories.find((parentCategory) => parentCategory.id === field.parentCategoryId) : data.categories.find((category) => category.id === field.categoryId)
            })
        } else
            setAttachDialog({
                ...attachDialog,
                ...newData
            });

    }


    /**
     * This function will trigger the menu instance (parent category/category) name field translations configuration
     * 
     * @param {object} translations  the new translations
     * @param {string} field  the type of menu instance that has been translated. Available values are: 'parentCategory' | 'category'
     */
    function configureMenuInstanceTranslations(translations, field) {
        if (field === "parentCategory") updateParentCategoryNameTranslations(dialog.instance, translations);
        else if (field === "category") updateCategoryNameTranslations(dialog.instance, translations);
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }


    // ================================================ PHOTO

    /**
    * The rest endpoint to update a menu parent-category categories items order
    * 
    * @param {string} parentCategoryId the parent category id
    * @param {array} categories  the new parent categories list after reorder
    */
    function updatePhoto(photos, field, instance) {
        if (field?.field === "parentCategory") {
            updateParentCategoryPhotos(instance, photos);
        } else if (field?.field === "cateogry") {
            updateCategoryPhotos(instance, photos);
        }
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <LoadingSkeleton lines={9} />;
    } else {
        const { isError } = data;

        return (
            <CustomGrid role="main" id="menu-categories-list" sx={mainTag()}>
                {(organizationID !== undefined && !hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('menus.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/menu` },
                                { name: data.menu.name.en, url: `/${getLanguageFromURL()}/organization/${organizationID}/menu/${menuID}` },
                            ]}
                            instanceName={t('menuCategories.title')}
                        />
                    </CustomGrid>
                }

                {(organizationID !== undefined && hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('menus.pageTitle'), url: `/${getLanguageFromURL()}/menu` },
                                { name: data.menu.name.en, url: `/${getLanguageFromURL()}/menu/${menuID}` },
                            ]}
                            instanceName={t('menuCategories.title')}
                        />
                    </CustomGrid>
                }

                {organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('menus.pageTitle'), url: `/${getLanguageFromURL()}/menu` },
                                { name: data.menu.name.en, url: `/${getLanguageFromURL()}/menu/${menuID}` },
                            ]}
                            instanceName={t('menuCategories.title')}
                        />
                    </CustomGrid>
                }

                <Grid container sx={{ marginTop: "20px" }}>
                    <Grid item xs={12} md={6}>
                        <CustomTypography text={t('menuCategories.plain')} variant="h5" sx={{ textAlign: "left", ...lumanosimoTitleStyle() }} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
                        <CustomIconButton
                            sx={{ fontWeight: "900", textTransform: "initial", fontSize: "12px", padding: "10px 15px" }}
                            onClick={() => navigate(`${path}/${menuID}`)}
                            label={t('menuCategories.viewMenuDetails')}
                        />
                    </Grid>
                </Grid>

                <CustomCheckbox
                    name="autoSave"
                    selected={autoSave}
                    onChange={(event) => setAutoSave(event.target.checked)}
                    label={t('menu.autoSave')}
                />

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="menuCategories" onSubmit={(e) => { e.preventDefault(); }}>
                        {/* {!data.draggable.expandAll && <CustomExpandAllButton onClick={() => setData({ ...data, draggable: { expandAll: true } })} />}
                        {data.draggable.expandAll && <CustomCollpaseAllButton onClick={() => setData({ ...data, draggable: { expandAll: false } })} />} */}

                        <div style={{ width: "100%", display: "inline-grid", paddingTop: "0px" }}>
                            <CustomMenuParentCategoriesDraggableTable
                                organizationID={organizationID}
                                // parent categories
                                parentCategories={findAllObjectsMatchInArrayOrdered(data.menu.parentCategoryIds, data.parentCategories)}
                                removeParentCategory={removeParentCategory}
                                updateParentCategoryName={updateParentCategoryName}
                                updateParentCategoryIsActive={updateParentCategoryIsActive}
                                updateMenuParentCategoriesOrder={updateMenuParentCategoriesOrder}
                                // categories
                                categoryOptions={data.categories}
                                addCategoryToParentCategory={addCategoryToParentCategory}
                                updateCategoryName={updateCategoryName}
                                updateCategoryIsActive={updateCategoryIsActive}
                                updateMenuCategoriesOrder={updateMenuCategoriesOrder}
                                removeCategory={removeCategory}
                                //items
                                itemOptions={data.items}
                                handleDialogState={handleDialogState}
                                removeItemFromCategory={removeItemFromCategory}
                                updateItemOrder={updateItemOrder}
                                // other
                                emptyMessage={t('menuCategories.emptyItemList')}
                                handleTranslationsDialogState={handleTranslationsDialogState}
                                expandAll={data.draggable.expandAll}
                                allowParentCategories={data.menu?.settings?.allowParentCategories || false}
                            // photos

                            />

                        </div>


                        {(data.menu?.settings?.allowParentCategories && data.menu?.settings?.showAdvancedCMS) &&
                            <div style={{ display: "flex", marginTop: "30px" }}>
                                <CustomTextField
                                    type="text" name="parentCategoryName" id="parentCategoryName" required={true}
                                    label={t('menuCategories.newParentCategory')}
                                    error={isError.parentCategoryName.length > 0 ? true : false}
                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                    sx={{ width: "87%" }}
                                    onKeyUp={(e) => { if (e.key === 'Enter') { e.preventDefault(); addParentCategory() } }}
                                />
                                <CustomButton
                                    type="button"
                                    id="btn-create-menu-parent-category"
                                    label={t('actions.create')}
                                    sx={{ backgroundColor: "#54b1d2", color: "white", textTransform: "initial", fontSize: "12px" }}
                                    sxHover={{ backgroundColor: "#34809b", color: "white", textTransform: "initial", fontSize: "12px" }}
                                    onClick={() => addParentCategory()}
                                />
                            </div>
                        }

                        {(!data.menu?.settings?.allowParentCategories) &&
                            <div style={{ display: "flex", marginTop: "30px", marginBottom: "20px", marginLeft: "80px" }}>
                                <CustomTextField
                                    variant="standard"
                                    type="text"
                                    id={`init-category-name-new`}
                                    required={true}
                                    label={t('menuCategories.newCategory')}
                                    sx={{ width: "87%" }}
                                    onKeyUp={(e) => { if (e.key === 'Enter') { e.preventDefault(); addInitCategoryToNewParentCategory() } }}
                                />
                                <CustomButton
                                    type="button"
                                    id="btn-create-menu-category"
                                    label={t('actions.create')}
                                    sx={{ backgroundColor: "#54b1d2", color: "white", textTransform: "initial", fontSize: "12px" }}
                                    sxHover={{ backgroundColor: "#34809b", color: "white", textTransform: "initial", fontSize: "12px" }}
                                    onClick={() => addInitCategoryToNewParentCategory()}
                                />
                            </div>
                        }
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={() => updateMenu()}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {attachDialog.isOpen &&
                    <AttachItemOrModifierDialog
                        isOpen={attachDialog.isOpen}
                        id="add-items"
                        title={`${t('actions.add')} ${t('modifierList.items')} ${t('modifierList.to')}`}
                        boldTitle={data.categories?.find(category => attachDialog.field.categoryId === category.id)?.name.en}
                        searchLabel={`${t('actions.searchFor')} ${t('modifierList.items')}`}
                        noOptionsText={`${t('modifierList.emptyItemsSearch')}`}
                        actionLabel={t("actions.add")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.add')} ${t('actions.deleteModal.confirm')}`}
                        action={addItemToCategory}
                        handleOpen={handleDialogState}
                        instances={data.items?.filter(item => !data.categories.find(category => attachDialog.field.categoryId === category.id)?.itemIds.includes(item.id))}
                        dialogName="attachDialog"
                        field={attachDialog.field}
                        category={attachDialog.instance}
                        fieldName={"field"}
                    />
                }
                {photosDialog.isOpen &&
                    <PhotosDialog
                        id="photos"
                        isOpen={photosDialog.isOpen}
                        title={t('assets.configurePhotos')}
                        actionLabel={t("actions.save")}
                        cancelLabel={t("actions.cancel")}
                        dialogName="photosDialog"
                        fieldName={"field"}
                        field={photosDialog.field}
                        showEmptyMessage={true}
                        handleOpen={handleDialogState}
                        updatePhoto={updatePhoto}
                        instance={photosDialog.instance}
                        organizationID={data.organization?.id}
                    />
                }

                {dialog.isOpen &&
                    <CustomTranslationsDialog
                        isOpen={dialog.isOpen}
                        id="translations"
                        title={t('actions.translationModal.title')}
                        actionLabel={t('actions.save')}
                        action={configureMenuInstanceTranslations}
                        handleOpen={handleTranslationsDialogState}
                        translations={dialog.instance.name}
                        field={dialog.field}
                    />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={6000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState}
                    />
                }
            </CustomGrid>
        );
    }
}

export default MenuCategoriesComponent;