import PropTypes from 'prop-types';
import { Badge } from '@mui/material';
import CustomLetterAvatar from 'components/Avatar/CustomLetterAvatar';

/**
 * Chat user avatar.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ChatUserAvatar({ user }) {
    return (
        <Badge
            overlap="circular"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
        >
            <CustomLetterAvatar name={(user?.fullName !== undefined) ? user.fullName : user?.name} onlyLetter={true} />
        </Badge>
    )
}

ChatUserAvatar.propTypes = {
    user: PropTypes.object
};

export default ChatUserAvatar;
