import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from "react-i18next";

/**
 * The CustomAddEditTitle, that display each add/edit page custom title.
 * 
 * @property {boolean} isAdd If the values is `true`, the page is about creating a new instance.
 *                              Otherwise referring to the update of a instance details.
 * @property {string} title the page title
 * @property {string} subtitle the page subtitle
 * @property {string} titleCreateText the page after title text if {@link isAdd} is `true`
 * @property {string} subtitleCreateText the page after title text if {@link isAdd} is `true`
 * @property {string} instanceName the instance name to be editted
 * @property {string} className the text class name
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomAddEditTitle({ isAdd, title = "", titleCreateText, subtitleCreateText, subtitle = "", instanceName, className = "usee-h3" }) {

    const { t } = useTranslation();
    if (!isAdd) {
        titleCreateText = t("actions.edit");
    } else if (titleCreateText === undefined || titleCreateText === "") titleCreateText = t("actions.create");

    if (subtitleCreateText === undefined || subtitleCreateText === "") subtitleCreateText = t("actions.create");

    return (
        <Grid>
            <h1>{title} | {titleCreateText}</h1>

            {isAdd ?
                <h3 className={className}>{subtitleCreateText} a new {subtitle}</h3>
                :
                <h3 className={className}> {t("actions.edit")} {subtitle} <strong>{instanceName}</strong></h3>
            }
        </Grid>
    );
}

export default CustomAddEditTitle;