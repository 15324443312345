import {
  LightMode as BrightnessIcon,
  Contrast as ContrastIcon,
  Crop as CropIcon,
  Flip as FlipIcon,
  InvertColors as HueIcon,
  RotateLeft as RotateLeftIcon,
  RotateRight as RotateRightIcon,
  Opacity as SaturationIcon
} from '@mui/icons-material';
import { Box, Button, } from "@mui/material";
import Slider from '@mui/material-next/Slider';
import CustomIconButton from "components/Button/CustomIconButton";
import CustomDialogChildren from "components/Dialogs/CustomDialogChildren";
import { cropperButtonProperties, cropperModeButtonProperties } from "constants/fieldProperties";
import { useRef, useState } from 'react';
import { Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { useTranslation } from "react-i18next";
import { cropperBasicButtonIcon, cropperButtonsBox } from 'themes/defaultThemes';
import { checkFileIsImage, checkFileSizeLimit } from 'utils/functions';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import AdjustableImage from 'components/Assets/AdjustableImage';


function CustomImageCropper({ storeCroppedImage, width = 600, height = 340 }) {
  const { t } = useTranslation();

  const [selectedImage, setSelectedImage] = useState(null);

  const [mode, setMode] = useState('crop');
  const [adjustments, setAdjustments] = useState({
    brightness: 0,
    hue: 0,
    saturation: 0,
    contrast: 0
  });

  function handleImageUpload(event) {
    const file = event.target.files[0];
    let isValidImageFile = checkFileIsImage(file)
    let isValidImageFileSize = checkFileSizeLimit(file)
    if (isValidImageFile === null && isValidImageFileSize === null) {
      setSelectedImage(URL.createObjectURL(file));
      handleDialogState(true);
    } else {
      handleSnackbarState(true, t(isValidImageFile !== null ? isValidImageFile : isValidImageFileSize));
    }
  };

  function handleCrop() {
    if (cropperRef.current) {
      handleDialogState(false);
      storeCroppedImage(cropperRef.current.getCanvas()?.toDataURL());
    }
  };

  // popup dialog for delete
  const [dialog, setDialog] = useState({
    isOpen: false,
    field: ""
  });

  // snackbar details
  const [alertBox, setAlertBox] = useState({
    isOpen: false,
    message: "",
    backgroundColor: "#a71313"
  });

  /**
   * Function that handles the delete modal open or close state.
   * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
   * @property {string} field The field of the selected text translations to be removed.
   */
  function handleDialogState(isOpen, field = "") {
    setDialog({
      ...dialog,
      isOpen: isOpen,
      field: field,
    });
  }

  const cropperRef = useRef(null);

  function onChangeValue(event) {
    if (mode in adjustments) {
      setAdjustments((previousValue) => ({
        ...previousValue,
        [mode]: event.target.value
      }));
    }
  };

  function handleFlip(horizontal, vertical) {
    if (cropperRef.current) {
      cropperRef.current.flipImage(horizontal, vertical)
    }
  }
  function handleRotate(angle) {
    if (cropperRef.current) {
      cropperRef.current.rotateImage(angle)
    }
  }

  function handleReset() {
    if (cropperRef.current) {
      cropperRef.current.reset();
      setMode("crop");
      setAdjustments({
        brightness: 0,
        hue: 0,
        saturation: 0,
        contrast: 0
      });
    }
  }

  function valuetext(value) {
    return `${value}`;
  }

  /**
   * Function that handles the snackbar open or close state.
   * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
   */
  function handleSnackbarState(isOpen, message) {
    setAlertBox({
      ...alertBox,
      isOpen: isOpen,
      message: message
    });
  }

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageUpload} />

      {dialog.isOpen &&
        <CustomDialogChildren
          isOpen={dialog.isOpen}
          actionLabel={t('assets.crop')}
          cancelLabel={t('actions.cancel')}
          title="test"
          handleAction={handleCrop}
          handleOpen={handleDialogState}
          children={<>
            <Box sx={cropperButtonsBox()}>
              <Button {...cropperButtonProperties} onClick={handleReset}>{t('assets.reset')}</Button>
              <Button {...cropperButtonProperties} onClick={() => handleRotate(-90)}><RotateLeftIcon /></Button>
              <Button {...cropperButtonProperties} onClick={() => handleRotate(90)}><RotateRightIcon /></Button>
              <Button {...cropperButtonProperties} onClick={() => handleFlip(true, false)} ><FlipIcon /></Button>
              <Button {...cropperButtonProperties} onClick={() => handleFlip(false, true)} sx={{ ...cropperBasicButtonIcon(), "& .MuiSvgIcon-root": { rotate: "90deg" } }} ><FlipIcon sx={{ rotate: "90deg" }} /></Button>
            </Box>
            <Box sx={cropperButtonsBox()}>
              <CustomIconButton
                {...cropperModeButtonProperties("crop", setMode, mode)}
                icon={<CropIcon />}
              />
              <CustomIconButton
                {...cropperModeButtonProperties("saturation", setMode, mode)}
                icon={<SaturationIcon />}
                tooltipTitle={t('assets.saturation')}
              />
              <CustomIconButton
                {...cropperModeButtonProperties("brightness", setMode, mode)}
                icon={<BrightnessIcon />}
                tooltipTitle={t('assets.brightness')}
              />
              <CustomIconButton
                {...cropperModeButtonProperties("contrast", setMode, mode)}
                icon={<ContrastIcon />}
                tooltipTitle={t('assets.contrast')}
              />
              <CustomIconButton
                {...cropperModeButtonProperties("hue", setMode, mode)}
                icon={<HueIcon />}
                tooltipTitle={t('assets.hue')}
              />
            </Box>
            <Box sx={{ ...cropperButtonsBox(), paddingTop: "20px" }}>
              {mode !== "crop" && (
                <Slider
                  value={adjustments[mode]}
                  onChange={onChangeValue}
                  min={-100}
                  max={100}
                  step={1}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  sx={{ "& .MuiSlider-track": { backgroundColor: "#26505f" }, "& .MuiSlider-thumb": { backgroundColor: "#1976d2" }, "& .MuiSlider-valueLabel": { backgroundColor: "#1976d2" }, "& .MuiSlider-rail": { backgroundColor: "#26505f" } }}
                  marks={[
                    {
                      value: 0,
                      label: '0',
                    },
                    {
                      value: -100,
                      label: '-100',
                    },
                    {
                      value: 100,
                      label: '100',
                    }
                  ]}
                />
              )}
            </Box>

            <Cropper
              ref={cropperRef}
              src={selectedImage}
              visible={true}
              defaultSize={{
                width: width,
                height: height
              }}
              imageRestriction="stencil"
              resizeImage={true}
              resizable={false}
              movable={true}
              backgroundComponent={AdjustableImage}
              backgroundProps={adjustments}
              stencilProps={{
                handlers: false,
                lines: true,
                movable: true,
                resizable: false,
              }}
              transformImage={{
                adjustStencil: false
              }}
              zoomable
            />
          </>}
        />
      }
      {alertBox.isOpen &&
        <CustomSnackbar
          isOpen={alertBox.isOpen}
          autoHideDuration={6000}
          message={alertBox.message}
          backgroundColor={alertBox.backgroundColor}
          handleSnackbarOpen={handleSnackbarState} />
      }
    </div>
  );
}

export default CustomImageCropper;