import { Card, CardActionArea, CardContent, createTheme, List, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomTypography from 'components/Typography/CustomTypography';

/**
 * The CustomCard, that display a custom help {@link Card}
 * 
 * @property {string} title the instance name
 * @property {string} subtitle the instance name
 * @property {*} imgSrc the icon represented the instance
 * @property {string} link the onclick card navigation link
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomHelpCard({ title, subtitle, imgSrc, link = "", font, instanceHeaderColor = "#205d7b", instanceTitleFontSize = "16px", itemDescColor, itemDescFontSize, itemPriceColor, }) {

    const navigate = useNavigate();

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiCard: {
                    styleOverrides: {
                        root: {
                            borderRadius: "10px",
                            background: "white!important",
                            width: 420,

                        }
                    }
                },
                MuiSvgIcon: {
                    styleOverrides: {
                        root: {
                            fontSize: "60px",
                            color: "#205d7b",
                        }
                    }
                },
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            color: "#205d7b"
                        }
                    }
                },
                MuiCardActionArea: {
                    styleOverrides: {
                        root: {
                            textAlign: "left"
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Card onClick={() => navigate(link)}>
                <CardActionArea>
                    <CardContent sx={{ display: "inline-flex", textAlign: "justify", alignItems: "center", textAlign: "left" }}>
                        <div style={{ height: "78px", width: "100px" }}>
                            {imgSrc}
                            {/* <CustomAsset /> */}
                        </div>
                        <div style={{ width: "70%", paddingRight: "3px" }}>
                            <CustomTypography variant="body2" text={title} sx={{ fontFamily: font, fontSize: instanceTitleFontSize, color: instanceHeaderColor, fontWeight: "bold", padding: 0, paddingBottom: "3px", textAlign: "justify" }} />
                            <CustomTypography variant="body2" text={subtitle} sx={{ fontFamily: font, fontSize: itemDescFontSize, color: itemDescColor, padding: 0, paddingBottom: "7px", textAlign: "justify" }} />
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        </ThemeProvider>
    );
}

export default CustomHelpCard;