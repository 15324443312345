// Action to reset the store or clean it up
export const cleanStoreAction = () => ({
    type: 'CLEAN_STORE',
});


// Middleware to set up the timer
export const cleanupMiddleware = store => next => action => {
    if (action.type === 'SET_CLEANUP_TIMER') {
        const now = new Date();
        let millisTill4AM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 4, 0, 0, 0) - now;
        if (millisTill4AM < 0) {
            millisTill4AM += 86400000; // it's after 4am, set for next day
        }

        setTimeout(() => store.dispatch(cleanStoreAction()), millisTill4AM);
    }

    return next(action);
};