import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Person } from '@mui/icons-material';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { Autocomplete, Badge, Box, Button, Card, CardActionArea, Checkbox, Dialog, DialogContent, Divider, Grid, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ArrowBackIcon, ArrowLeftIcon, CheckBoxIcon, CheckBoxOutlineBlankIcon, EditIcon, MessageIcon, PlaceIcon, ReceiptIcon, SearchTwoToneIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomItemCard from 'components/Card/item/CustomItemCard';
import CustomSingleCardItem from 'components/Card/item/CustomSingleCardItem';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import CustomGrid from 'components/Grid/CustomGrid';
import CheckoutPersonsPopover from 'components/Popover/CheckoutPersonsPopover';
import OrderTypePopover from 'components/Popover/OrderTypePopover';
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTypography from 'components/Typography/CustomTypography';
import { requestTextFieldLimit } from 'constants/textFieldLimit';
import { useTranslation } from 'react-i18next';
import { checkoutCommentTextareaBoxStyle } from 'themes/checkout/checkoutTheme';
import { ItemPaper, cardItemContentActionArea, cartButtonLabelItemStyle, cartButtonStackStyle, checkoutCommentTextareaBoxInfoCharStyle, mainAreaFullScreen, orderAddTextStyle, orderAddTextStyleLabel, orderBackBtnStyle, orderCartTitleTextStyle, orderIconStyle, orderItemFontAwesomeIconStyle, orderItemMuiIconStyle, orderItemTaxPriceTextStyle, orderItemTaxTextStyle, orderItemTotalPriceTextStyle, orderItemTotalTextStyle } from 'themes/defaultThemes';
import { getOrderEquipmentAdd } from 'themes/qrcode/defaultTheme';
import { calculateLoyaltyPointsEarn, calculateTax, calculateTotalAmount } from 'utils/cart-functions';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { calculateCartChipQuantity, priceTextWithCurrency } from 'utils/functions/order';
import { getItems } from 'utils/item';

function AddOrderGrid({
    data, setData,
    selectedEquipment,
    selectedFilterSectionID, setSelectedFilterSectionID,
    selectedMenu,
    selectedParentCategory,
    selectedCategory,
    selectedItem,
    showEquipments, setShowEquipments,
    showCheckout, setShowCheckout,
    sendOrder,
    addOrder,
    handleEquipmentSelect,
    handleMenuSelect,
    handleParentCategorySelect,
    handleCategorySelect, handleItemSelect,
    handleBackFromCheckOut,
    addItemToCartCallback,
    orderTypeAnchorEl, openOrderType,
    updateOrderTypeOpenCallback, updateOrderTypeCloseCallback, updateOrderTypeCallback,
    personsAnchorEl, updatePersonsOpenCallback, updatePersonsCloseCallback, updatePersonsCallback,
    dialog, handleDialogState,
    removeItemFromCart, updateItemInCart,
    openPersons,
    goBack,
    filterItems, setFilterItems

}) {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const { t } = useTranslation();

    // ================================ RENDER
    return (
        <>
            <CustomGrid sx={{ ...mainAreaFullScreen(), marginRight: "10px" }}>
                <Grid container spacing={2} sx={{ margin: "10px" }}>
                    <Grid container gap={1}>
                        <Grid item xs={4} sx={{ display: "flex" }}>
                            <Grid container gap={1}>
                                <Grid item xs={"auto"}>
                                    <PlaceIcon color="black" sx={orderIconStyle()} />
                                </Grid>
                                <Grid item xs={"auto"}>
                                    <Grid container sx={{ marginLeft: "10px" }}>
                                        <Grid item xs={12}>
                                            <CustomTypography variant="body2" text={t('cart.equipmentLabel')} sx={orderAddTextStyleLabel()} />
                                        </Grid>
                                        <Grid item xs={"auto"}>
                                            <CustomTypography variant="body2" text={selectedEquipment?.label} sx={orderAddTextStyle()} />
                                        </Grid>
                                        <Grid item xs={"auto"}>
                                            {!showEquipments &&
                                                <Grid>
                                                    <Tooltip title={t("actions.edit")}><EditIcon id="edit-eq" style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={() => { setShowEquipments(true); setShowCheckout(false) }} /></Tooltip>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box sx={{ flexGrow: 1 }} /> {/* Spacing to push the following items to the right */}

                        <Grid item xs={"auto"} x={{ display: "flex", float: "right" }}>
                            <CustomIconButton
                                onClick={() => setShowCheckout(true)}
                                backgroundColor='white'
                                label={(
                                    <Badge badgeContent={calculateCartChipQuantity(data.cart?.items)} color="primary" style={{ color: 'blue' }}>
                                        <ShoppingCart color="black" sx={orderIconStyle()} onClick={() => setShowCheckout(true)} />
                                    </Badge>
                                )}
                            />

                        </Grid>
                    </Grid>
                </Grid>

                <Grid container gap={1}>
                    {!showCheckout && showEquipments &&
                        <Grid item xs={12} md={12}>
                            <Stack>
                                <Autocomplete
                                    key="sections"
                                    id="section-filter-select"
                                    disablePortal={false}
                                    options={data.sections}
                                    autoHighlight
                                    disableCloseOnSelect={false}
                                    multiple={false}
                                    getOptionLabel={(option) => option?.name}
                                    noOptionsText={`${t('section.emptySection')}`}
                                    onChange={(event, newValue) => {
                                        setSelectedFilterSectionID(newValue?.id);
                                    }}
                                    renderOption={(props, option, { selected }) => {
                                        return <>
                                            <li key={option?.id} {...props} style={{ borderBottom: (data.sections[data.sections?.length - 1]?.id !== option.id) ? "1px solid darkgrey" : "none", paddingTop: "20px" }}>
                                                <div key={option?.id + "-1"} style={{ display: "contents" }}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        checked={selected}
                                                    />
                                                </div>
                                                <div key={option?.id + "-2"} style={{ display: "block", width: "90%" }}>
                                                    <Typography variant="inherit" sx={{ fontWeight: 600, fontSize: "15px" }}>{option?.name}</Typography>
                                                </div>
                                            </li>
                                        </>
                                    }
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('actions.searchFor')} ${t('section.title')}`}
                                            variant='filled'
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </Stack>

                            <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                <Grid container gap={2}>
                                    {(selectedFilterSectionID !== null
                                        ? data.equipments
                                            ?.filter((eq) =>
                                                data.sections
                                                    ?.find((section) => section.id === selectedFilterSectionID)
                                                    ?.equipments?.includes(eq?.id)
                                            )
                                        : data.equipments)?.map((eq) =>
                                            <Grid>
                                                <Card variant="outlined" onClick={() => handleEquipmentSelect(eq)}
                                                    sx={{
                                                        width: "fit-content",
                                                        // margin :"5px",
                                                        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                        // background: menu.settings?.menuBackgroundColor
                                                    }}
                                                >
                                                    <CardActionArea sx={{
                                                        ...cardItemContentActionArea(),
                                                        minWidth: "80px",
                                                        minHeight: "50px",
                                                        padding: "0 5px"
                                                    }}>
                                                        <CustomTypography variant="body2" text={eq.label} />
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        )}
                                    {selectedFilterSectionID === null && data.equipments?.map((eq) =>
                                        <Grid>
                                            <Card variant="outlined" onClick={() => handleEquipmentSelect(eq)}
                                                sx={{
                                                    width: "fit-content",
                                                    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                }}
                                            >
                                                <CardActionArea sx={{
                                                    ...cardItemContentActionArea(),
                                                    minWidth: "80px",
                                                    minHeight: "50px",
                                                    padding: "0 5px"
                                                }}>
                                                    <CustomTypography variant="body2" text={eq.label} />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    )}
                                </Grid>
                            </Stack>
                        </Grid>
                    }

                    {(!showCheckout && !showEquipments) &&
                        <Grid item xs={12} md={12}>
                            <OutlinedInput
                                fullWidth
                                id="input-search-header"
                                placeholder={t('actions.search')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchTwoToneIcon fontSize="small" />
                                    </InputAdornment>
                                }
                                onChange={(event) => setFilterItems(event.target.value)}
                            />
                        </Grid>
                    }

                    {(filterItems !== "" && !showCheckout && !showEquipments) &&
                        <Grid item xs={12} md={12}>
                            <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                <Grid container gap={2}>
                                    {getItems(data.menus, filterItems)?.map((item) =>
                                        <Grid>
                                            <Card variant="outlined" onClick={() => handleItemSelect(item)}
                                                sx={{
                                                    width: "fit-content",
                                                    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                }}
                                            >
                                                <CardActionArea sx={{
                                                    ...cardItemContentActionArea(),
                                                    minWidth: "80px",
                                                    minHeight: "50px",
                                                    padding: "0 5px"
                                                }}>
                                                    <CustomTypography variant="body2" text={item?.name} />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    )}
                                </Grid>
                            </Stack>
                        </Grid>
                    }

                    {(filterItems === "" && !showCheckout && !showEquipments && selectedMenu === null && selectedParentCategory === null && selectedCategory === null && selectedItem === null) &&
                        <Grid item xs={12} md={12}>
                            <CustomTypography variant="body2" text={`${t('cart.menus')}`} />
                            <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                <Grid container gap={2}>
                                    {data.menus?.map((menu) =>
                                        <Grid>
                                            <Card variant="outlined" onClick={() => handleMenuSelect(menu)}
                                                sx={{
                                                    width: "fit-content",
                                                    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                }}
                                            >
                                                <CardActionArea sx={{
                                                    ...cardItemContentActionArea(),
                                                    minWidth: "80px",
                                                    minHeight: "50px",
                                                    padding: "0 5px"
                                                }}>
                                                    <CustomTypography variant="body2" text={menu?.name} />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    )}
                                </Grid>
                            </Stack>
                        </Grid>
                    }

                    {(filterItems === "" && !showCheckout && !showEquipments && selectedMenu !== null && selectedParentCategory === null && selectedCategory === null && selectedItem === null) &&
                        <>
                            <Grid item xs={"auto"}>
                                <CustomIconButton
                                    icon={<ArrowLeftIcon />}
                                    // sx={orderBackBtnStyle()}
                                    onClick={() => goBack("parentCategories")}
                                />
                            </Grid>

                            <Grid item xs={"auto"}>
                                <CustomTypography variant="body2" text={`${t('cart.parentCategories')}`} sx={{ textAlign: "left", paddingLeft: "50px", fontWeight: "bold" }} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                    <Grid container gap={2}>
                                        {data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.map((parentCategory) =>
                                            <Grid>
                                                <Card variant="outlined" onClick={() => handleParentCategorySelect(parentCategory)}
                                                    sx={{
                                                        width: "fit-content",
                                                        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                    }}
                                                >
                                                    <CardActionArea sx={{
                                                        ...cardItemContentActionArea(),
                                                        minWidth: "80px",
                                                        minHeight: "50px",
                                                        padding: "0 5px"
                                                    }}>
                                                        <CustomTypography variant="body2" text={parentCategory?.name} />
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Stack>
                            </Grid>
                        </>
                    }

                    {(filterItems === "" && !showCheckout && !showEquipments && selectedMenu !== null && selectedParentCategory !== null && selectedCategory === null && selectedItem === null) &&
                        <>
                            <Grid item xs={"auto"}>
                                <CustomIconButton
                                    icon={<ArrowLeftIcon />}
                                    // sx={orderBackBtnStyle()}
                                    onClick={() => goBack("categories")}
                                />
                            </Grid>

                            <Grid item xs={9} md={9}>
                                <CustomTypography variant="body2" text={`${t('cart.categories')}`} sx={{ textAlign: "left", paddingLeft: "50px", fontWeight: "bold" }} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                    <Grid container gap={2}>
                                        {data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCategory) => parentCategory.id === selectedParentCategory)[0]?.categories?.map((category) =>
                                            <Grid>
                                                <Card variant="outlined" onClick={() => handleCategorySelect(category)}
                                                    sx={{
                                                        width: "fit-content",
                                                        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                    }}
                                                >
                                                    <CardActionArea sx={{
                                                        ...cardItemContentActionArea(),
                                                        minWidth: "80px",
                                                        minHeight: "50px",
                                                        padding: "0 5px"
                                                    }}>
                                                        <CustomTypography variant="body2" text={category?.name} />
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Stack>
                            </Grid>
                        </>
                    }

                    {(filterItems === "" && !showCheckout && !showEquipments && selectedMenu !== null && selectedParentCategory !== null && selectedCategory !== null && selectedItem === null) &&
                        <>
                            <Grid item xs={"auto"}>
                                <CustomIconButton
                                    icon={<ArrowLeftIcon />}
                                    // sx={orderBackBtnStyle()}
                                    onClick={() => goBack("items")}
                                />
                            </Grid>

                            <Grid item xs={9} md={9}>
                                <CustomTypography variant="body2" text={`${t('cart.items')}`} sx={{ textAlign: "left", paddingLeft: "50px", fontWeight: "bold" }} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                    <Grid container gap={2}>
                                        {data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCategory) => parentCategory.id === selectedParentCategory)[0]?.categories?.filter((category) => category.id === selectedCategory)[0]?.items?.map((item) =>
                                            <Grid>
                                                <Card variant="outlined" onClick={() => handleItemSelect(item)}
                                                    sx={{
                                                        width: "fit-content",
                                                        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                    }}
                                                >
                                                    <CardActionArea sx={{
                                                        ...cardItemContentActionArea(),
                                                        minWidth: "80px",
                                                        minHeight: "50px",
                                                        padding: "0 5px"
                                                    }}>
                                                        <CustomTypography variant="body2" text={item?.name} />
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Stack>
                            </Grid>
                        </>
                    }


                    {(filterItems === "" && !showCheckout && !showEquipments && selectedMenu !== null && selectedParentCategory !== null && selectedCategory !== null && selectedItem !== null) &&
                        <>
                            <Grid item xs={"auto"}>
                                <CustomIconButton
                                    icon={<ArrowLeftIcon />}
                                    // sx={orderBackBtnStyle()}
                                    onClick={() => goBack("item")}
                                />
                            </Grid>

                            <Grid item xs={9} md={9}>
                                <CustomTypography variant="body2" text={`${t('cart.items')}`} sx={{ textAlign: "left", paddingLeft: "50px", fontWeight: "bold" }} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={`${t('cart.items')}`} />
                                <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                    <Grid container gap={2}>
                                        {data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCategory) => parentCategory.id === selectedParentCategory)[0]?.categories?.filter((category) => category.id === selectedCategory)[0]?.items?.map((item) =>
                                            <Grid>
                                                <Card variant="outlined" onClick={() => handleItemSelect(item)}
                                                    sx={{
                                                        width: "fit-content",
                                                        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                    }}
                                                >
                                                    <CardActionArea sx={{
                                                        ...cardItemContentActionArea(),
                                                        minWidth: "80px",
                                                        minHeight: "50px",
                                                        padding: "0 5px"
                                                    }}>
                                                        <CustomTypography variant="body2" text={item?.name} />
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Stack>
                            </Grid>
                        </>
                    }


                    {showCheckout &&
                        <>
                            <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                                <CustomIconButton
                                    icon={<ArrowBackIcon />}
                                    sx={orderBackBtnStyle()}
                                    onClick={() => handleBackFromCheckOut()}
                                    label={t('cart.backButton')}
                                />
                            </Grid>

                            <Grid item xs={5} x={{ display: "flex", float: "right" }}>
                                <Grid container gap={1}>
                                    <Grid item xs={3}>
                                        <PlaceIcon color="black" sx={orderIconStyle()} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container sx={{ marginLeft: "10px" }}>
                                            <Grid item xs={12}>
                                                <CustomTypography variant="body2" text={t('cart.orderType.label')} sx={orderAddTextStyleLabel()} />
                                            </Grid>
                                            <Grid item xs={"auto"}>
                                                <CustomTypography variant="body2" text={t(`cart.orderType.${data.cart?.type}`)} sx={orderAddTextStyle()} />
                                            </Grid>
                                            <Grid item xs={"auto"}>
                                                <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateOrderTypeOpenCallback} /></Tooltip>
                                                <OrderTypePopover
                                                    anchorEl={orderTypeAnchorEl}
                                                    open={openOrderType}
                                                    handleClose={updateOrderTypeCloseCallback}
                                                    inputValue={data.cart?.type}
                                                    handleUpdate={updateOrderTypeCallback}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={5}>
                                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                        <Person sx={orderIconStyle} />
                                    </Grid>

                                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                                        <Grid container sx={{ marginLeft: "30px", textAlign: "left" }}>
                                            <Grid item xs={12} md={12}>
                                                <CustomTypography variant="body2" text={t('cart.persons')} sx={orderAddTextStyleLabel()} />
                                            </Grid>
                                            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                                <CustomTypography variant="body2" text={data.cart?.persons} sx={{ ...orderAddTextStyle(), width: "auto" }} />
                                                <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updatePersonsOpenCallback} /></Tooltip>
                                                <CheckoutPersonsPopover
                                                    anchorEl={personsAnchorEl}
                                                    open={openPersons}
                                                    handleClose={updatePersonsCloseCallback}
                                                    inputValue={data.cart?.persons}
                                                    handleUpdate={updatePersonsCallback}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

                            <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                                <Grid container gap={1}>
                                    <Grid item xs={12} md={12}>
                                        <InputLabel sx={orderCartTitleTextStyle()}>{t('cart.label')}</InputLabel>
                                    </Grid>

                                    {data.cart?.items?.map((cartItem, index) =>
                                        <Grid item xs={12} md={12}>
                                            <CustomSingleCardItem
                                                outlet={data.outlet}
                                                cartItem={cartItem}
                                                currency={data.currency}
                                                cart={data.cart}
                                                removeItemFromCart={removeItemFromCart}
                                                updateItemInCart={updateItemInCart}
                                                indexInCart={index}
                                                handleItemDialogState={() => handleDialogState(true, cartItem, index, cartItem)}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            {data.cart?.pvcRecyclingTax > 0 &&
                                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                            <FontAwesomeIcon key={"icon-env-tax"} icon={faLeaf} style={orderItemFontAwesomeIconStyle()} />
                                        </Grid>

                                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                            <CustomTypography variant="body2" text={t('checkout.pvcRecyclingTax')} sx={orderItemTaxTextStyle()} />
                                        </Grid>

                                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                            <CustomTypography variant="body2" text={priceTextWithCurrency(data.outlet?.currency, data.cart?.pvcRecyclingTax)} sx={orderItemTaxPriceTextStyle()} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            <Divider light flexItem sx={{ borderRightWidth: 5, width: "80%", marginLeft: "11%", marginTop: "20px" }} />

                            {data.cart?.taxPercent > 0 &&
                                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                            {/* <ReceiptIcon sx={orderItemMuiIconStyle} /> */}
                                        </Grid>

                                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                            <CustomTypography variant="body2" text={`${t('checkout.tax')}  (${data.cart?.taxPercent}%)`} sx={orderItemTotalTextStyle()} />
                                        </Grid>

                                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                            <CustomTypography variant="body2" text={priceTextWithCurrency(data.currency, calculateTax(data.cart))} sx={orderItemTotalPriceTextStyle()} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                        <ReceiptIcon sx={orderItemMuiIconStyle} />
                                    </Grid>

                                    <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                        <CustomTypography variant="body2" text={t('checkout.paymentTotal')} sx={orderItemTotalTextStyle()} />
                                    </Grid>

                                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                        <CustomTypography variant="body2" text={priceTextWithCurrency(data.outlet?.currency, calculateTotalAmount(data.cart))} sx={orderItemTotalPriceTextStyle()} />
                                    </Grid>

                                    {calculateLoyaltyPointsEarn(data.cart) > 0 &&
                                        <Grid item xs={12} md={12} sx={{ alignSelf: "center", marginTop: "20px" }}>
                                            <CustomTypography variant="body2" text={`${t('checkout.loyaltyPointsToEarn')} ${calculateLoyaltyPointsEarn(data.cart)} ${t('checkout.points')}`} sx={{ ...orderItemTotalPriceTextStyle(), fontWeight: "200" }} />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ marginTop: "50px" }}>
                                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                    <Grid item xs={"auto"}>
                                        <MessageIcon sx={orderIconStyle} />
                                    </Grid>
                                    <Grid item xs={2} md={2} sx={{ paddingTop: "8px" }}>
                                        <CustomTypography variant="body2" text={t('checkout.comments')} sx={orderItemTotalTextStyle()} />
                                    </Grid>

                                    <Grid item xs={12} md={12} sx={{ alignSelf: "center" }}>
                                        <CustomTextArea
                                            placeholder={t('checkout.requestPlaceholder')}
                                            value={data.cart.comments}
                                            inputProps={{ maxLength: 100 }}
                                            maxLength={100}
                                            onChange={(event) => {
                                                let newValue = event.target.value.slice(0, 100);
                                                formValChangeWithParentElementWithNameAndValue("cart.comments", newValue, data, setData);
                                            }}
                                            variant="outlined"
                                            sx={{ borderColor: "lightgrey", paddingTop: "15px" }}
                                            endDecorator={
                                                <Box sx={checkoutCommentTextareaBoxStyle()}>
                                                    <Divider flexItem={true} />
                                                    <span style={{ color: data.cart?.comments?.length === 100 ? "red" : "initial" }}>
                                                        {`${data.cart?.comments?.length}  `}
                                                    </span>
                                                    <span style={{ ...checkoutCommentTextareaBoxInfoCharStyle(), color: data.cart?.comments?.length === 100 ? "red" : "initial" }}>
                                                        {data.cart?.comments?.length === 1 ? t('checkout.requestCharLimit') : t('checkout.requestCharsLimit')}
                                                    </span>
                                                    <span style={{ color: data.cart?.comments?.length === 100 ? "red" : "initial" }}>
                                                        {` /${requestTextFieldLimit}`}
                                                    </span>

                                                </Box>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

                            <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                                <Button
                                    variant="extended"
                                    onClick={() => addOrder(data.cart)}
                                    disabled={sendOrder}
                                    sx={{
                                        width: "300px",
                                        borderRadius: "10px",
                                        bottom: 16,
                                        height: "40px",
                                        left: "10%",
                                        background: "#4394fb",
                                        color: "darkgrey",
                                        ":hover": { background: `grey` }
                                    }}>
                                    <Stack direction="row" spacing={2} sx={cartButtonStackStyle()}
                                        onClick={() => addOrder(data.cart)}>
                                        <ItemPaper sx={cartButtonLabelItemStyle()}>
                                            {t('checkout.buttons.sendOrder')}
                                        </ItemPaper>
                                    </Stack>
                                </Button>
                            </Grid>
                        </>
                    }
                </Grid>
            </CustomGrid>

            {dialog.isOpen &&
                <CustomDialogBasedOnScreenChildren
                    isOpen={dialog.isOpen}
                    handleOpen={() => { handleDialogState(true) }}
                    handleClose={() => { handleDialogState(false) }}
                    children={
                        <DialogContent sx={{ textAlign: "-webkit-center", minWidth: "300px" }}>
                            <CustomItemCard
                                organizationID={data.organization?.id}
                                outletURL={data.outletURL}
                                itemID={dialog.id !== null ? dialog.id : selectedItem}
                                isOpen={dialog.isOpen}
                                action={dialog.id !== null ? addItemToCartCallback : updateItemInCart}
                                handleOpen={handleDialogState}
                                menuConfiguration={selectedMenu ? data.menus?.find(menu => menu.id === selectedMenu)?.settings : data.menus[0]?.settings}
                                outletAllowComments={false}
                                enableCart={true}
                                currency={data.currency}
                                cartIndex={dialog.cartIndex}
                                cartItemDefault={dialog.cartItemDefault}
                                extraDetails={dialog.id !== null ? undefined : {
                                    menuID: selectedMenu,
                                    menuName: data.menus?.find(menu => menu.id === selectedMenu)?.nameEN,
                                    parentCategoryID: selectedParentCategory,
                                    parentCategoryName: data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.nameEN,
                                    regularItem: true,
                                    categoryID: selectedCategory,
                                    categoryName: data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCategory) => parentCategory.id === selectedParentCategory)[0]?.categories?.filter((category) => category.id === selectedCategory)[0]?.nameEN,
                                }}
                            />
                        </DialogContent>
                    }
                />
            }

        </>
    );
}

export default AddOrderGrid;