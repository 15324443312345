import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { CloseOutlined, Search as SearchIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function FeedbackFormConnectionDialog({
  open,
  connectionsData,
  attachedOutletIDs,
  onClose,
  onSubmit,
}) {
  const { t } = useTranslation();

  const [selectedOutletIds, setSelectedOutletIds] = useState([]);
  const [connections, setConnections] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setConnections(connectionsData);
    setSelectedOutletIds(attachedOutletIDs);
  }, [open]);
  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        <div style={{ position: "absolute", top: 5, right: 5 }}>
          <IconButton sx={{ p: 0 }} onClick={onClose}>
            <CloseOutlined fontSize='small' sx={{ fill: "#000" }} />
          </IconButton>
        </div>
        <Typography variant='h5' sx={{ color: "#34353A", fontWeight: 700 }}>
          {t("feedback.connectionsDialog.heading")}
        </Typography>
        <Box
          sx={{
            mt: "20px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              px: "20px",
              pb: "20px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
            }}
          >
            <TextField
              variant='outlined'
              size='small'
              fullWidth
              placeholder={t("feedback.connectionsDialog.search")}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              sx={{
                mt: "20px",
                "& .MuiOutlinedInput-root": { borderRadius: "50px" },
              }}
            />
          </Box>

          <Box sx={{ px: "20px", pb: "20px" }}>
            <Grid container direction='column' gap={2} sx={{ mt: "20px" }}>
              {connections
                .filter((item) =>
                  item.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((item, i) => (
                  <Grid item key={item.id} sx={{ width: "100%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size='small'
                          checked={selectedOutletIds.includes(item.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedOutletIds((ids) => [...ids, item.id]);
                            } else {
                              setSelectedOutletIds((ids) =>
                                ids.filter((id) => id !== item.id)
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant='body1'
                          sx={{ fontWeight: "bold", color: "#000" }}
                        >
                          {item.name}
                        </Typography>
                      }
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>

        <Grid item sx={{ width: "100%", mt: "20px" }}>
          <Grid container wrap='nowrap' justifyContent='flex-end' spacing={2}>
            <Grid item>
              <Button
                fullWidth
                variant='outlined'
                sx={{
                  border: "1px solid #D0D5DD",
                  background: "transparent",
                  boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                  color: "#344054",

                  textTransform: "none",
                  fontWeight: 600,
                  minWidth: "100px",
                }}
                onClick={onClose}
              >
                {t("feedback.connectionsDialog.cancel")}
              </Button>
            </Grid>

            <Grid item>
              <Button
                fullWidth
                variant='contained'
                onClick={() => onSubmit(selectedOutletIds)}
                sx={{
                  textTransform: "none",
                  fontWeight: 600,
                  minWidth: "100px",
                }}
              //onClick={edit ? editHandler : submitHandler}
              >
                {/* {loading?.active && loading?.action === "submit" && (
                <CircularProgress
                  size='1rem'
                  color='inherit'
                  sx={{ marginRight: "10px" }}
                />
              )} */}
                {t("feedback.connectionsDialog.save")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default FeedbackFormConnectionDialog;