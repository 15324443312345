import { fas } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Divider, Grid } from '@mui/material';
import {
    ActionAnimations,
    SwipeableListItem
} from '@sandstreamdev/react-swipeable-list';
import { FontAwesomeIcon, faMoneyBill } from "assets/fontAwesome/FontAwesomeIcons";
import { InfoIcon, NotesIcon, ShoppingCartIcon } from 'assets/mui/MuiIcons';
import "assets/scss/swipeable-list.scss";
import { CleanTableIcon } from 'assets/svg/SvgIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomChip from 'components/Chip/CustomChip';
import CustomTypography from 'components/Typography/CustomTypography';
import { getRequestButton, getRequestButtonIcon } from 'constants/fieldProperties';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { customerRequestDateTextStyle, customerRequestTextStyle, requestButtonFontAwesomeIcon, requestButtonIcon, requestButtonSvgIcon, requestTableLabelChipStyle } from 'themes/defaultThemes';
import { calculateTimePassed } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';

/**
 * The CustomCustomerRequestCard, that display a custom {@link Card} for customer request
 * 
 * @property {string} label the instance name
 * @property {*} icon the icon represented the instance
 * @property {int} totalNumber the total number of the instances presented
 * @property {string} link the onclick card navigation link
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCustomerRequestCard({ customerRequest, index, handleAction, showDivider = false }) {
    const { t } = useTranslation();
    const language = getLanguageFromURL();
    const navigate = useNavigate();
    const requestCustomLabel = customerRequest?.type === "CUSTOM" ? customerRequest?.label[language] : "";
    let path = `/${getLanguageFromURL()}`;

    // ======================================== NAVIGATE ADD ORDER/EDIT
    function navigateEditOrder(customerDraftOrder) {
        navigate(`${path}/order/add`, { state: { customerDraftOrder: customerDraftOrder } });
    }

    return (
        <>
            <SwipeableListItem
                swipeLeft={{
                    // content:
                    //     <CustomIconButton
                    //         key={`left-swip-btn-${index}`}
                    //         icon={<DoneIcon sx={{ color: "green" }} {...getOrderButtonIcon()} />}
                    //         variant="contained"
                    //         iconSx={mainTagSearchItemOrderStatusIcon()}
                    //         sx={{ background: "transparent", boxShadow: "none", color: "green" }}
                    //         label={t('request.actions.done')}
                    //     />,
                    actionAnimation: ActionAnimations.RETURN,
                    action: (event) => handleAction(event, customerRequest.id, "DONE")
                }}
                swipeRight={{
                    // content:
                    //     <CustomIconButton
                    //         key={`left-swip-btn-${index}`}
                    //         icon={<DoneIcon sx={{ color: "green" }} {...getOrderButtonIcon()} />}
                    //         variant="contained"
                    //         iconSx={mainTagSearchItemOrderStatusIcon()}
                    //         sx={{ background: "transparent", boxShadow: "none", color: "green" }}
                    //         label={t('request.actions.done')}
                    //     />,
                    actionAnimation: ActionAnimations.RETURN,
                    action: (event) => handleAction(event, customerRequest.id, "DONE")
                }}
            >
                <Grid item xs={12} md={12} sx={{ background: "white", borderRadius: "20px" }}>
                    <Grid container sx={{ width: "100%", padding: "15px 25px" }}>
                        <Grid item xs={3} md={3} sx={{ paddingRight: "10px" }}>
                            {customerRequest?.type === "ORDER" &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getRequestButton(customerRequest.type, "order", "ORDER", t('request.actions.type.order'))}
                                        icon={<ShoppingCartIcon {...getRequestButtonIcon()} sx={requestButtonIcon()} />}
                                    />
                                </Grid>
                            }

                            {customerRequest?.type === "PAYMENT" &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getRequestButton(customerRequest.type, "pay", "PAYMENT", t('request.actions.type.payment'))}
                                        icon={<FontAwesomeIcon icon={faMoneyBill} style={requestButtonFontAwesomeIcon()} />}
                                    />
                                </Grid>
                            }

                            {customerRequest?.type === "CLEAN_TABLE" &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getRequestButton(customerRequest.type, "request", "CLEAN_TABLE", t('request.actions.type.clean_table'))}
                                        icon={<CleanTableIcon  {...requestButtonSvgIcon()} />}
                                    />
                                </Grid>
                            }

                            {customerRequest?.type === "OTHER" &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getRequestButton(customerRequest.type, "other", "OTHER", t('request.actions.type.other'))}
                                        icon={<NotesIcon {...getRequestButtonIcon()} sx={requestButtonIcon()} />}
                                    />
                                </Grid>
                            }
                            {customerRequest?.type === "CUSTOM" &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getRequestButton(requestCustomLabel, requestCustomLabel, requestCustomLabel)}
                                        icon={<FontAwesomeIcon icon={fas[customerRequest.icon]} style={requestButtonFontAwesomeIcon()} />}
                                    />
                                </Grid>
                            }

                        </Grid>

                        <Grid item xs={9} md={9}>
                            <Grid container sx={{ marginLeft: "10px" }}>
                                <Grid item xs={12} md={12}>
                                    <Grid container>
                                        <Grid item xs={"auto"}>
                                            <CustomChip label={customerRequest.type !== "CUSTOM" ? t(`request.actions.type.${customerRequest?.type?.toLowerCase()}`) : requestCustomLabel} sx={{ fontSize: "10px", padding: "4px" }} />
                                        </Grid>
                                        {customerRequest.equipment?.label &&
                                            <Grid item xs={"auto"}>
                                                <CustomChip label={customerRequest.equipment.label} sx={requestTableLabelChipStyle()} />
                                            </Grid>
                                        }
                                        {(customerRequest.type === "ORDER" && customerRequest.draftOrder !== null) &&
                                            <Grid item xs={"auto"}>
                                                <Button startIcon={<InfoIcon style={{ color: "grey" }} onClick={() => navigateEditOrder(customerRequest.draftOrder)} />} />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={customerRequest.request} sx={customerRequestTextStyle()} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ textAlign: "right" }}>
                                    <CustomTypography variant="body2" text={calculateTimePassed(customerRequest.dateCreated)} sx={customerRequestDateTextStyle()} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SwipeableListItem>

            {showDivider &&
                <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", margin: "10px 0" }} />
            }
        </>
    );
}

export default CustomCustomerRequestCard;