const formatText = (value) => {
  if (value) {
    const formattedValue = value
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/_(.*?)_/g, "<i>$1</i>");

    // const sanitizedValue = DOMPurify.sanitize(formattedValue);

    return formattedValue;
  }
  return "";
};

export default formatText;
