import {
    CardMedia,
    Divider,
    Grid,
    ListItem,
} from '@mui/material';
import { AddIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomTypography from 'components/Typography/CustomTypography';
import { MINIO_HOST } from 'constants/hosts';
import { getThemeColor } from 'constants/theme';
import { useTranslation } from 'react-i18next';
import { pastOrderAddFeedbackButtonIconStyle, pastOrderAddFeedbackButtonStyle, pastOrderCartTextStyle, pastOrderDateTextStyle, pastOrderLogoStyle, pastOrderOutletTextStyle } from 'themes/defaultThemes';
import { dateTimeFormatter } from 'utils/functions';
import { fieldFormatter } from 'utils/table';
import { getTheme } from 'utils/theme';

/**
 * The CustomCustomerLoyaltyCard, that display each customer loyalty history instance.
 * 
 * @param {object} loyalty the user loyalty instance details
 *
 * @returns {JSX.Element} A React component that renders a past loyalty.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCustomerLoyaltyCard({ loyalty }) {
    const { t } = useTranslation();

    return (
        <>
            <Grid item xs={12} md={12}>
                <ListItem>
                    <Grid container sx={{ width: "90%", paddingLeftt: "10%" }}>
                        <Grid item xs={9} md={9}>
                            <Grid container sx={{ marginLeft: "10px" }}>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={`${dateTimeFormatter(loyalty.dateTime, "DD/MM/yyyy - HH:mm")}`} sx={pastOrderDateTextStyle()} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={t(`customer.view.tabs.activity.referenceType.${loyalty.referenceType}`)} sx={pastOrderOutletTextStyle()} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={3} md={3} sx={{ paddingRight: "10px" }}>
                            {fieldFormatter((loyalty.action === "REDEEM" ? `-${loyalty.points}` : loyalty.action === "ADD" ? `+${loyalty.points}` : "0") + ` ${t('customer.view.tabs.activity.action.points')}`, {
                                background: getThemeColor()[getTheme()]["loyaltyActions"][`${loyalty.action}`] + "4d",
                                color: getThemeColor()[getTheme()]["loyaltyActions"][`${loyalty.action}`],
                            })}
                        </Grid>
                    </Grid>
                </ListItem>
            </Grid>


            <Divider light flexItem sx={{ bloyaltyRightWidth: 5, width: "100%" }} />
        </>
    );
}

export default CustomCustomerLoyaltyCard;