import { request } from "constants/alias";
import { API } from "constants/config";
import { dateTimeFormatter, includeParamsToUrl, objectIsEmpty, parsePageable } from "utils/functions";
import { formatISOWithTimezone } from "utils/functions/date";

/**
 * The rest request which attempts to get the orders list from the database.
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the orders list
 */
export async function fetchAllKitchen(search) {
    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "") {
        data = { organizationID: search.organizationID };
    }
    if (search.outletID !== undefined && search.outletID !== "") {
        data = { ...data, outletID: search.outletID };
    }
    if (search.orderNumber !== undefined && search.orderNumber !== "") {
        data = { ...data, orderNumber: search.orderNumber };
    }
    if (search.equipmentID !== undefined && search.equipmentID !== "") {
        data = { ...data, equipmentID: search.equipmentID };
    }
    if (search.statuses !== undefined && search.statuses.length > 0) {
        data = { ...data, statuses: search.statuses };
    }
    if (search.paymentMethods !== undefined && search.paymentMethods.length > 0) {
        data = { ...data, paymentMethods: search.paymentMethods }
    }
    if (search.dateFrom !== null && search.dateFrom !== "" && search.dateFrom !== undefined) {
        data = { ...data, dateFrom: search.dateFrom };
    }
    if (search.dateTo !== null && search.dateTo !== "" && search.dateTo !== undefined) {
        data = { ...data, dateTo: search.dateTo };
    }
    if (search.isPaid !== undefined && search.isPaid !== null && search.isPaid !== "") {
        data = { ...data, isPaid: search.isPaid }
    }
    if (search.customerName !== undefined && search.customerName !== "") {
        data = { ...data, customerName: search.customerName }
    }
    if (search.customerPhoneNumber !== undefined && search.customerPhoneNumber !== "") {
        data = { ...data, customerPhoneNumber: search.customerPhoneNumber }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}order`, data));
    else return await request.get(includeParamsToUrl(`${API}order`));
}

/**
 * The rest request which attempts to get the orders list from the database.
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the orders list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "") {
        data = { ...data, organizationID: search.organizationID }
    }
    // if (search.outletIDs !== undefined && search.outletIDs !== null && search.outletIDs.length > 0) {
    //   data = { ...data, outletIds: search.outletIDs }
    // }
    if (search.orderNumber !== undefined && search.orderNumber !== "") {
        data = { ...data, orderNumber: search.orderNumber }
    }
    if (search.equipmentID !== undefined && search.equipmentID !== "") {
        data = { ...data, equipmentID: search.equipmentID }
    }
    if (search.employeeID !== undefined && search.employeeID !== "") {
        data = { ...data, employeeID: search.employeeID }
    }

    if (search.statuses !== null && search.statuses?.length > 0) {
        for (const status of search.statuses) {
            data = { ...data, statuses: status }
        }
    }
    if (search.paymentMethods !== null && search.paymentMethods?.length > 0) {
        for (const paymentMethod of search.paymentMethods) {
            data = { ...data, paymentMethods: paymentMethod }
        }
    }
    if (search.dateFrom !== null && search.dateFrom !== undefined && search.dateFrom !== "") {
        data = { ...data, dateFrom: formatISOWithTimezone(new Date(search.dateFrom)) };
    }
    if (search.dateTo !== null && search.dateTo !== undefined && search.dateTo !== "") {
        data = { ...data, dateTo: formatISOWithTimezone(new Date(search.dateTo)) };
    }
    if (search.isPaid !== undefined && search.isPaid !== null && search.isPaid !== "") {
        data = { ...data, isPaid: search.isPaid }
    }
    if (search.customerName !== undefined && search.customerName !== "") {
        data = { ...data, customerName: search.customerName }
    }
    if (search.customerPhoneNumber !== undefined && search.customerPhoneNumber !== "") {
        data = { ...data, customerPhoneNumber: search.customerPhoneNumber }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}order/list`, pageable !== undefined ? { ...parsePageable(pageable), ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}order/list`, parsePageable(pageable) !== undefined ? pageable : {}));
}


/**
 * The rest request which attempts to get the orders list to be payed on
 * the requested equipmentfrom the database.
 * @param string equipmentID The id of the equipment to fetch unpaid orders
 *
 * @returns an object with an attribute containing the orders list
 */
export async function fetchAllOrdersEquipmentToPay(equipmentID, dateFrom) {
    return await request.get(includeParamsToUrl(`${API}order/list/pay/${equipmentID}`, { dateFrom: dateFrom }));
}

export async function updateOrderStatus(orderID, status, time) {
    let url = `${API}order/${orderID}/${status}`;
    if (time) url = url + "?processingTimeInMinutes=" + time;
    return await request.put(url);
}

export async function updateOrderItemStatus(orderID, itemID, done, index) {
    return await request.put(`${API}order/${orderID}/item/${itemID}/${index}/${done}`);
}

export async function updateOrderItemUnavailbale(orderID, data) {
    return await request.put(`${API}order/${orderID}/item/unavailable`, data);
}

export async function create(data) {
    return await request.post(`${API}order`, data);
}

/**
 * The rest request which return required information to be presented on add new order page.
 * @returns an object with an attribute containing the requested information to be presented on the order page
 */
export async function fetchNew() {
    return await request.get(`${API}order/add`);
}

/**
 * The rest request which attempts to get an existing order information.
 * 
 * @param {*} orderID The id of the order form to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the order
 */
export async function fetchOne(orderID) {
    return await request.get(includeParamsToUrl(`${API}order/${orderID}`));
}

export async function updateTable(orderID, data) {
    return await request.put(includeParamsToUrl(`${API}order/${orderID}/table/update`, data));
}

export async function updateType(orderID, data) {
    return await request.put(includeParamsToUrl(`${API}order/${orderID}/type/update`, data));
}

export async function updatePersons(orderID, data) {
    return await request.put(includeParamsToUrl(`${API}order/${orderID}/persons/update`, data));
}

export async function payAction(orderID, data) {
    return await request.put(`${API}order/${orderID}/pay`, data);
}
export async function payActionAndClose(orderID, data) {
    return await request.put(`${API}order/${orderID}/pay/close`, data);
}

export async function payCombineAction(data) {
    return await request.put(`${API}order/pay/combine`, data);
}
export async function payCombineActionAndClose(data) {
    return await request.put(`${API}order/pay/combine/close`, data);
}

const orderApi = {
    fetchAllKitchen,
    fetchAll,
    fetchAllOrdersEquipmentToPay,
    fetchNew,
    fetchOne,
    updateTable,
    updateType,
    updatePersons,
    updateOrderStatus,
    updateOrderItemStatus,
    updateOrderItemUnavailbale,
    create,
    payAction,
    payActionAndClose,
    payCombineAction,
    payCombineActionAndClose
};

export default orderApi;
