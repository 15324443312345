import CloseRounded from "@mui/icons-material/CloseRounded";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import { useTranslation } from "react-i18next";

function DeleteFeedbackFromDialog({
    open,
    onClose,
    title,
    message,
    boldMessage,
    action,
}) {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-describedby='alert-dialog-slide-description'
            className='dialog-title'
        >
            <DialogTitle className='dialog-headers'>
                {title}
                <IconButton onClick={onClose} sx={{ float: "right" }}>
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ background: "white" }}>
                <DialogContentText className='dialog-desciption'>
                    {message}
                    {boldMessage && (
                        <span style={{ fontWeight: "bold" }}>{boldMessage}</span>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions className='dialog-actions'>
                <Button className='dialog-cancel-btn' onClick={onClose}>
                    {t("actions.cancel")}
                </Button>
                <Button
                    className='dialog-action-btn'
                    sx={{
                        color: "#3593D2",
                        ":hover": { backgroundColor: "#3593D2" + "52" },
                    }}
                    onClick={action}
                >
                    {t("actions.deleteModal.message").toUpperCase()}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteFeedbackFromDialog;