import CircleIcon from '@mui/icons-material/Circle';
import { createTheme, InputAdornment, Link, ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

/**
 * The Custom Chip Multi Select Field, that display customized {@link Chip}.
 * @property {string} id the text field id
 * @property {string} labelID the text field label id
 * @property {string} name the text field name
 * @property {string} label Applies the theme typography styles.
 * @property {array} defaultValue the text field default value
 * @property {boolean} required if the value is required at this text field 
 * @property {boolean} error if text field is at an error state
 * @property {boolean} isMultiple if multiple selection is enabled
 * @property {function} onChange the function that handles the select input change
 * @property {function} onDelete the function that handles the removal of a chip
 * @property {string} startAdornmentLabel the label to be presented at the start of the input field
 * @property {string} linkPathPattern the chip link pattern to redirect when clicking a chip
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSelectObjectWithChipAndIcon({ id = "", labelID = "", name = "", label,
    defaultValue = [], required = true, error = false, isMultiple = true, value,
    color = "default", onChange, onDelete, options, startAdornmentLabel, linkPathPattern, field = "name", sx,
    disabled
}) {
    function isActive(item) {
        if (item.isActive !== undefined) return item.isActive;
        if (item.active !== undefined) return item.active;
        if (item.enabled !== undefined) return item.enabled;
    }

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            width: "100%",
                            marginRight: "0px"
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            borderRadius: "30px",
                            ...sx
                        }
                    }
                }
            }
        })
    }

    return (
        <>
            {label !== undefined && <InputLabel id={labelID} >{label}</InputLabel>}
            <ThemeProvider theme={getMuiTheme()}>
                <Select
                    labelId={labelID}
                    multiple={isMultiple}
                    onChange={onChange}
                    input={<OutlinedInput id={id} name={name} startAdornment={
                        <InputAdornment disableTypography position="end" sx={{ fontWeight: "bold" }}>
                            {startAdornmentLabel}</InputAdornment>}
                    />}
                    disabled={disabled}
                    required={required}
                    value={value}
                    defaultValue={defaultValue}
                    renderValue={(selected) => {
                        return (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                                let item = options.find(option => option.id === value);
                                if (item !== undefined)
                                    return <Chip color={color} key={item.id} label={<Link href={linkPathPattern ? `${linkPathPattern}/${item.id}` : undefined}>{item[field]}</Link>}
                                        icon={<CircleIcon sx={{ fontSize: "10px", color: ((isActive(item) ? "green" : "red") + " !important") }} />}
                                        onDelete={(event) => onDelete(event, item.id)} onMouseDown={(event) => { event.stopPropagation(); }} />
                            })}
                        </Box>
                        );
                    }}
                    MenuProps={MenuProps}
                >
                    {options.map((item) => (
                        <MenuItem
                            key={item.id}
                            value={item.id}
                        >
                            {isActive(item) !== undefined && <Chip color={color} key={item.id} icon={<CircleIcon sx={{ fontSize: "10px", paddingLeft: "10px", color: ((isActive(item) ? "green" : "red") + " !important") }} />} sx={{ marginLeft: "10px", marginRight: "10px" }} />}
                            {item[field]}
                        </MenuItem>
                    ))}
                </Select>
            </ThemeProvider>
        </>
    );
}

export default CustomSelectObjectWithChipAndIcon;