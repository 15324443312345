import {
    Grid,
    InputLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * The CustomMenuLayoutButtons, that display menu layout change.
 * 
 * @param {object} item the menu item details
 * @param {string} priceColor the color for price to have
 * @param {string} lang the lang code
 * @param {fucntion} handleItemDialogState Callback that handles the open item details dialog for more details and to fill in fields to add one or more to cart.
 *
 * @returns {JSX.Element} A React component that renders a menu item.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuLayoutButtons({ activeLayout, fieldName, updateLayoutStyle, layoutOptions, isList = false }) {
    const { t } = useTranslation();

    return (
        <Grid item xs={12} sm={6} md={6}>
            <InputLabel required={true} htmlFor="layout">{t('menus.settings.layout.label')}</InputLabel>
            <Grid container gap={3}>
                <Grid item xs={12} md={5} onClick={(event) => updateLayoutStyle(event, layoutOptions[0], fieldName, isList)}>
                    <span style={{ paddingRight: "20px" }}>
                        <svg width="112" height="98" xmlns="http://www.w3.org/2000/svg">
                            <g stroke={`${((isList && activeLayout.includes("LIST")) || (!isList && activeLayout === "LIST")) ? "#47a6d2" : "#A2B8C6"}`} fill="none" fillRule="evenodd">
                                <rect fill="#F4F6F9" x=".5" y=".5" width="110" height="29" rx="3"></rect>
                                <path fill="#A2B8C6" d="M80.5 29.5h1v-29h-1z"></path>
                                <rect fill="#F4F6F9" x=".5" y="34.5" width="110" height="29" rx="3"></rect>
                                <path fill="#A2B8C6" d="M80.5 62.5h1v-29h-1z"></path>
                                <rect fill="#F4F6F9" x=".5" y="68.5" width="110" height="29" rx="3"></rect>
                                <path fill="#A2B8C6" d="M80.5 97.5h1v-29h-1z"></path>
                            </g>
                        </svg>
                    </span>
                    <InputLabel required={false} style={{ fontSize: "10px" }}>{t('menus.settings.layout.LIST')}</InputLabel>
                </Grid>

                <Grid item xs={12} md={5} onClick={(event) => updateLayoutStyle(event, layoutOptions[1], fieldName, isList)}>
                    <span>
                        <svg width="112" height="98" xmlns="http://www.w3.org/2000/svg">
                            <g stroke={`${((isList && activeLayout.includes("GRID")) || (!isList && activeLayout === "GRID")) ? "#47a6d2" : "#A2B8C6"}`} fill="none" fillRule="evenodd">
                                <rect fill="#F4F6F9" x=".5" y=".5" width="47" height="45" rx="3"></rect>
                                <rect fill="#F4F6F9" x="51.5" y=".5" width="47" height="45" rx="3"></rect>
                                <path fill="#A2B8C6" d="M.5 30.5h45v1h-25zm52 0h25v1h-25zm0 0h46v1h-25z"></path>
                                <rect fill="#F4F6F9" x="52.5" y="50.5" width="47" height="45" rx="3"></rect>
                                <rect fill="#F4F6F9" x=".5" y="50.5" width="47" height="45" rx="3"></rect>
                                <path fill="#A2B8C6" d="M.5 80.5h47v1h-25zm52 0h25v1h-25zm0 0h46v1h-25z"></path>
                            </g>
                        </svg>
                    </span>
                    <InputLabel required={false} style={{ fontSize: "10px" }}>{t('menus.settings.layout.GRID')}</InputLabel>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CustomMenuLayoutButtons;