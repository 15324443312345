import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import { Fab, FormControlLabel, FormHelperText, Grid, InputLabel, Switch } from "@mui/material";
import subscriptionPlanApi from "api/subscription-plan";
import { DeleteIcon } from 'assets/mui/MuiIcons';
import CustomAddEditBreadcrumb from 'components/Breadcrumb/CustomAddEditBreadcrumb';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomCollapsableFieldset from 'components/Fieldset/CustomCollapsableFieldset';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSelect from 'components/Select/CustomSelect';
import CustomSelectMultilingual from 'components/Select/CustomSelectMultilingual';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitleBoldSubtitle from "components/Title/CustomTitleBoldSubtitle";
import { getDefaultSubscriptionPlan, getSubscriptionPlanDetailDefaultObject, getSubscriptionPlanPriceDefaultObject, getSubscriptionPlanServiceDefaultObject } from 'constants/defaultObjects';
import { geSubscriptionPlanPrice, geSubscriptionPlanService } from 'constants/fieldProperties';
import { getThemeColor } from 'constants/theme';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { addCardActionAreaButtonStyle, addSubscriptionPlanButtonIconStyle, fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, selectError, subscriptionPlanButtonGroupStackButton, subscriptionPlanButtonGroupStackButtonIcon, textFieldError } from 'themes/defaultThemes';
import { hasRoleAdmin } from "utils/auth";
import { formIsValid, formValChangeWithParentElement, formValChangeWithParentElementWithNameAndValue, onControlChange, onSelectChange } from "utils/form-validation";
import { removeItemAtIndex, validateInputNumber } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { getTheme } from 'utils/theme';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The SubscriptionPlanAddEditComponent that triggers the creation of a 
 * new subscription plan or the modification of an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SubscriptionPlanAddEditComponent() {
   const { t } = useTranslation();
   //change document title

   const { subscriptionPlanID } = useParams();
   const navigate = useNavigate();

   /**
    * @type {object}
    * @property {boolean} isAdd to define if requested an edit or an add of a subscription plan
    * @property {boolean} redirect if a redirect should be performed
    * @property {string} error holdes the error message of a failed rest api call
    * @property {boolean} isLoaded to render DOM based on rest api call status, if true the rest api call completed
    * @property {object} userAuth the authenticated user infromation
    * @property {string} subscriptionPlanID tha subscription plan id to get subscription plan current information for edit subscription plan page
    * @property {object} subscriptionPlan an empty object if add or the selected subscription plan to be editted
    * @property {*} icon the legend fa icon
    * @property {array} typeOptions the different type options
    * @property {array} currencyOptions the currency type options
    * @property {object} alertBox holds snackbar infromation and style
    * @property {object} isError list of indication of empty form required field 
    *                           after submit to present error message to the
    *                           fields/attributes with true value
    */
   const [data, setData] = useState({
      redirect: false,
      error: null,
      isLoaded: false,
      userAuth: null,
      isAdd: subscriptionPlanID === undefined,
      subscriptionPlanID: subscriptionPlanID,
      subscriptionPlan: null,
      item: null,
      icon: AddIcon,
      menuItemProviderOptions: [],
      typeOptions: [],
      currencyOptions: [],
      serviceTypes: [],
      // error messages per field
      isError: {
         name: "",
         type: ""
      },
      dialog: {
         isOpen: false,
         message: t(""),
         id: "",
         field: "",
         translations: {}
      }
   });

   // if true the menu item is clicked and a redirect to page should perform
   const [redirect, setRedirect] = useState(false);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);

   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });

   useDocumentTitle(`Usee | ${t('subscriptionPlan.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`)
   useEffect(() => {
      getSubscriptionPlan();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   /**
    * The rest endpoint to get the subscription plan default (add) or current (edit) information.
    */
   function getSubscriptionPlan() {
      if (data.isAdd) {
         subscriptionPlanApi.fetchNew().then((r) => {
            setData({
               ...data,
               userAuth: r.data.returnobject.userAuth,
               subscriptionPlan: getDefaultSubscriptionPlan(),
               typeOptions: r.data.returnobject.typeOptions,
               currencyOptions: r.data.returnobject.currencyOptions,
               serviceTypes: r.data.returnobject.serviceTypes
            });
            setIsLoaded(true);
         }).catch((e) => {
            // console.log(e);
            setData({
               ...data,
               error: e
            });
            setIsLoaded(true);
         })
      } else {
         subscriptionPlanApi.fetchOne(data.subscriptionPlanID).then((r) => {
            setData({
               ...data,
               userAuth: r.data.returnobject.userAuth,
               subscriptionPlan: r.data.returnobject.subscriptionPlan,
               typeOptions: r.data.returnobject.typeOptions,
               currencyOptions: r.data.returnobject.currencyOptions,
               serviceTypes: r.data.returnobject.serviceTypes
            });
            setIsLoaded(true);
         }).catch((e) => {
            // console.log(e);
            setData({
               ...data,
               error: e
            });
            setIsLoaded(true);
         });
      }
   }

   /**
    * Gets called when the subscription plan clicks on the save button, and triggers 
    * the creation of the new subscription plan.
    */
   function addSubscriptionPlan() {
      if (formValidation()) {
         subscriptionPlanApi.create(data.subscriptionPlan).then((r) => {
            setAlertBox({
               isOpen: true,
               message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
               backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            setRedirect((hasRoleAdmin(data.userAuth.roles) && r.data.code === "SUCCESS" ? true : false));
         }).catch((e) => {
            // console.log(e)
         });
      }
   }

   /**
    * Gets called when the subscription plan clicks on the save button, and triggers 
    * the edit of the selected subscription plan.
    */
   function editSubscriptionPlan() {
      if (formValidation()) {
         subscriptionPlanApi.update(data.subscriptionPlan).then((r) => {
            setAlertBox({
               isOpen: true,
               message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
               backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            setRedirect((hasRoleAdmin(data.userAuth.roles) && r.data.code === "SUCCESS" ? true : false));
         }).catch((e) => {
            // console.log(e)
         });
      }
   }

   // ========================================= Prices
   /**
    * Updates the value of a specific field in a price object at a given index.
    *
    * This function takes an index, field name, and a new value, and updates the
    * corresponding field in the prices array of the subscription plan.
    *
    * @param {number} index - The index of the price object in the prices array to update.
    * @param {string} field - The field within the price object to update.
    * @param {*} value - The new value to assign to the field.
    */
   function handlePriceChange(index, field, value) {
      let newPrices = data.subscriptionPlan.prices;
      newPrices[index][field] = value;
      formValChangeWithParentElementWithNameAndValue("subscriptionPlan.prices", newPrices, data, setData);
   }

   /**
    * Adds a new price object to the subscription plan's prices array.
    *
    * This function adds a default price object to the end of the prices array.
    * The default object is different based on whether the array is initially empty or not.
    */
   function handleAddPrice() {
      let newPrices = data.subscriptionPlan.prices;
      if (newPrices.length === 0)
         newPrices.push(getSubscriptionPlanPriceDefaultObject("NORMAL"));
      else
         newPrices.push(getSubscriptionPlanPriceDefaultObject());
      formValChangeWithParentElementWithNameAndValue("subscriptionPlan.prices", newPrices, data, setData);
   }

   /**
    * Removes a price object from the subscription plan's prices array based on its index.
    *
    * @param {number} index - The index of the price object in the prices array to remove.
    */
   function removePrice(index) {
      let newPrices = removeItemAtIndex(data.subscriptionPlan.prices, index);
      formValChangeWithParentElementWithNameAndValue("subscriptionPlan.prices", newPrices, data, setData);
   }

   // ========================================= Services

   /**
    * Updates the value of a specific field in a service object at a given index.
    *
    * This function takes an index, field name, and a new value, and updates the
    * corresponding field in the services array of the subscription plan.
    *
    * @param {number} index - The index of the price object in the services array to update.
    * @param {string} field - The field within the service object to update.
    * @param {*} value - The new value to assign to the field.
    */
   function handleServiceChange(index, field, value) {
      let newServices = data.subscriptionPlan.services;
      newServices[index][field] = value;
      formValChangeWithParentElementWithNameAndValue("subscriptionPlan.services", newServices, data, setData);
   }

   /**
    * Adds a new service object to the subscription plan's services array.
    *
    * This function adds a default service object to the end of the services array.
    * The default object is different based on whether the array is initially empty or not.
    */
   function handleAddService() {
      let newServices = data.subscriptionPlan.services;
      newServices.push(getSubscriptionPlanServiceDefaultObject());
      formValChangeWithParentElementWithNameAndValue("subscriptionPlan.services", newServices, data, setData);
   }

   /**
    * Removes a service object from the subscription plan's services array based on its index.
    *
    * @param {number} index - The index of the service object in the services array to remove.
    */
   function removeService(index) {
      let newServices = removeItemAtIndex(data.subscriptionPlan.services, index);
      formValChangeWithParentElementWithNameAndValue("subscriptionPlan.services", newServices, data, setData);
   }

   // ========================================= Details

   /**
    * Updates the value of a specific field in a detail object at a given index.
    *
    * This function takes an index, field name, and a new value, and updates the
    * corresponding field in the details array of the subscription plan.
    *
    * @param {number} index - The index of the price object in the details array to update.
    * @param {string} field - The field within the detail object to update.
    * @param {*} value - The new value to assign to the field.
    */
   function handleDetailChange(index, field, value) {
      let newDetails = data.subscriptionPlan.details;
      newDetails[index][field] = value;
      formValChangeWithParentElementWithNameAndValue("subscriptionPlan.details", newDetails, data, setData);
   }

   /**
    * Adds a new detail object to the subscription plan's details array.
    *
    * This function adds a default detail object to the end of the details array.
    * The default object is different based on whether the array is initially empty or not.
    */
   function handleAddDetail() {
      let newDetails = data.subscriptionPlan.details;
      newDetails.push(getSubscriptionPlanDetailDefaultObject());
      formValChangeWithParentElementWithNameAndValue("subscriptionPlan.details", newDetails, data, setData);
   }

   /**
    * Removes a detail object from the subscription plan's details array based on its index.
    *
    * @param {number} index - The index of the detail object in the details array to remove.
    */
   function removeDetail(index) {
      let newDetails = removeItemAtIndex(data.subscriptionPlan.details, index);
      formValChangeWithParentElementWithNameAndValue("subscriptionPlan.details", newDetails, data, setData);
   }

   // =========================================
   /**
    * Function that triggers form validation and print out if the form is valid or not.
    * @returns true if form is Valid
    */
   function formValidation() {
      let isError = createErrorMessages();
      if (formIsValid(isError)) {
         return true;
      } else {
         return false;
      }
   }

   /**
    * Function that create error messages for each required field that are not filled out.
    * @returns object containing the error messages for each form field
    */
   function createErrorMessages() {
      let isError = { ...data.isError };
      isError.name = data.subscriptionPlan.name.length < 1 ? t('table.valueReuired') : "";
      isError.type = data.subscriptionPlan.type.length < 1 ? t('table.valueReuired') : "";
      setData({
         ...data,
         isError
      });
      return isError;
   }

   /**
    * Function that handles the snackbar open or close data.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }

   // ===============================================================================RENDER

   if (redirect) {
      navigate(`/${getLanguageFromURL()}/subscription-plan`)
   } else if ((!isLoaded && !data.isAdd) || data.subscriptionPlan === null) {
      return (
         <LoadingSkeleton lines={9} />
      );
   } else {
      let defaultTypeSelected = "";
      if (data.subscriptionPlan !== null) defaultTypeSelected = data.subscriptionPlan.type;

      return (
         <CustomGrid role="main" id={"subscription-plan-" + data.isAdd ? "add" : "edit"} sx={mainTag()}>
            <CustomGrid sx={mainTagBreadcrumb()}>
               <CustomAddEditBreadcrumb
                  isAdd={data.isAdd}
                  parentName={t('subscriptionPlan.pageTitle')}
                  parentUrl={`/${getLanguageFromURL()}/subscription-plan`}
                  instanceName={(data.subscriptionPlan !== null) ? data.subscriptionPlan.name.en : ""}
               />
            </CustomGrid>

            <CustomGrid sx={mainTagTitle()}>
               <CustomTitleBoldSubtitle
                  title={`${t('subscriptionPlan.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`}
                  subtitleBeforeText={data.isAdd ? t('actions.tableCreateNewLink') + t('subscriptionPlan.pageTitle') : t('subscriptionPlan.pageTitle') + " "}
                  subtitleboldText={!data.isAdd && data.subscriptionPlan !== null && data.subscriptionPlan !== undefined && data.subscriptionPlan.name}
                  subtitleAfterText={!data.isAdd ? " profile page" : ""}
               />
            </CustomGrid>

            <CustomGrid sx={mainArea()} container={false}>
               <form id="subscriptionPlan" onSubmit={(e) => { return false; }}>

                  {data.isAdd &&
                     <input type="hidden" />
                  }

                  {!data.isAdd &&
                     <input type="hidden" name="id" id="id" defaultValue={data.subscriptionPlanID} />
                  }

                  <Grid container spacing={2}>
                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="name">{t('label.name')}</InputLabel>
                        <CustomTextField
                           type="text"
                           name="subscriptionPlan.name"
                           id="name"
                           placeholder={t('label.namePlaceholder')} required={true}
                           disabled={!data.isAdd} defaultValue={!data.isAdd ? data.subscriptionPlan.name : ""}
                           error={data.isError.name.length > 0 ? true : false}
                           helperText={data.isError.name}
                           sx={textFieldError()}
                           onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                        />
                     </Grid>

                     <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="description">{t('label.description')}</InputLabel>
                        <CustomTextArea
                           name="subscriptionPlan.description"
                           id="description"
                           onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                           defaultValue={null !== data.subscriptionPlan ? data.subscriptionPlan.description : ""}
                        />
                     </Grid>

                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="type">{t('label.duration')}</InputLabel>
                        <CustomSelectMultilingual
                           translationPath="subscriptionPlan.type"
                           required={true}
                           isMultiple={false}
                           width="100%"
                           field="field"
                           id="subscriptionPlan.type"
                           name="subscriptionPlan.type"
                           defaultValue={defaultTypeSelected}
                           options={data.typeOptions}
                           onChange={(event) => onSelectChange(event, data, setData)}
                           error={data.isError.type.length > 0}

                        />
                        {data.isError.type.length > 0 &&
                           <FormHelperText sx={selectError()}>
                              {data.isError.type}
                           </FormHelperText>
                        }
                     </Grid>

                     <Grid item xs={12} md={6}></Grid>

                     <Grid item xs={12} md={6}>
                        <FormControlLabel control={<Switch defaultChecked={data.subscriptionPlan.available} onChange={(event) => onControlChange(event, data, setData, "subscriptionPlan", "available")} />} label={t('label.isAvailable')} />
                     </Grid>

                     <Grid item xs={12} md={6}>
                        <FormControlLabel control={<Switch defaultChecked={data.subscriptionPlan.available} onChange={(event) => onControlChange(event, data, setData, "subscriptionPlan", "visibleOnlyToAdmin")} />} label={t('subscriptionPlan.visibleOnlyToAdmin')} />
                     </Grid>

                     <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                        <CustomCollapsableFieldset
                           isOpen={true}
                           label={`${t('actions.add')} ${t('label.price')}`}
                           children={<>
                              <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "10px" }}>
                                 <Grid item xs={12} md={12}>
                                    {data.subscriptionPlan?.prices.map((price, index) => (
                                       <Grid container spacing={2} key={index} sx={{ marginTop: 0, alignItems: "center" }}>

                                          <Grid item xs={12} md={4}>
                                             <InputLabel required={true} htmlFor="reason">{t('label.reason')}</InputLabel>

                                             {index > 0 &&
                                                <CustomTextField
                                                   type="text"
                                                   id="reason"
                                                   name="reason"
                                                   required={true}
                                                   value={price.reason}
                                                   onChange={(event) => handlePriceChange(index, "reason", event.target.value)}
                                                   sx={{ maxHeight: "50px" }}
                                                />
                                             }

                                             {index === 0 &&
                                                <CustomTextField
                                                   type="text"
                                                   name="reason"
                                                   id="reason"
                                                   value={"NORMAL"}
                                                   disabled={true}
                                                />
                                             }
                                          </Grid>

                                          <Grid item xs={12} md={2}>
                                             <InputLabel required={true} htmlFor="price">{t('label.price')}</InputLabel>
                                             <CustomTextField
                                                type="number"
                                                step={geSubscriptionPlanPrice().step}
                                                min={geSubscriptionPlanPrice().min}
                                                max={geSubscriptionPlanPrice().max}
                                                value={price.price}
                                                required={true}
                                                onChange={(event) => {
                                                   let value = validateInputNumber(event.target.value, geSubscriptionPlanPrice().length, geSubscriptionPlanPrice().min, geSubscriptionPlanPrice().max, geSubscriptionPlanPrice().isFloat);
                                                   if (value === undefined) value = price.price;
                                                   handlePriceChange(index, "price", parseFloat(value))
                                                }}
                                             />
                                          </Grid>

                                          <Grid item xs={12} md={2}>
                                             <InputLabel required={true} htmlFor="currencyString">{t('label.currency')}</InputLabel>
                                             <CustomSelect
                                                id="currencyString"
                                                name="currencyString"
                                                required={true}
                                                value={price?.currencyString}
                                                options={data.currencyOptions}
                                                onChange={(event) => handlePriceChange(index, "currencyString", event.target.value)}
                                             />
                                          </Grid>

                                          {index > 0 &&
                                             <Grid item xs={12} md={1}>
                                                <CustomIconButton
                                                   keyValue={`btn-delete-${index}`}
                                                   onClick={() => removePrice(index)}
                                                   icon={<DeleteIcon style={subscriptionPlanButtonGroupStackButtonIcon()} />}
                                                   iconSx={{ marginRight: "0px!important" }}
                                                   sx={{
                                                      backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                      ":hover": {
                                                         backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                      },
                                                      ...subscriptionPlanButtonGroupStackButton(),
                                                      marginTop: "20px"
                                                   }}
                                                >
                                                   {t('actions.delete')}
                                                </CustomIconButton>
                                             </Grid>
                                          }
                                       </Grid>
                                    ))}
                                 </Grid>

                                 <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                    <CustomIconButton
                                       sx={addCardActionAreaButtonStyle()}
                                       backgroundColor="#dcf1f7"
                                       icon={<AddIcon sx={addSubscriptionPlanButtonIconStyle()} />}
                                       onClick={handleAddPrice}>
                                       {t('actions.add')}
                                    </CustomIconButton>
                                 </Grid>
                              </Grid>
                           </>
                           }
                        />
                     </Grid>

                     <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                        <CustomCollapsableFieldset
                           isOpen={false}
                           label={`${t('actions.add')} ${t('label.service')}`}
                           children={<>
                              <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "10px" }}>
                                 <Grid item xs={12} md={12}>
                                    {data.subscriptionPlan?.services.map((service, index) => (
                                       <Grid container spacing={2} key={index} sx={{ marginTop: 0, alignItems: "center" }}>
                                          <Grid item xs={12} md={4}>
                                             <InputLabel required={true} htmlFor="key">{t('label.key')}</InputLabel>
                                             <CustomSelectMultilingual
                                                translationPath="subscriptionPlan.serviceType"
                                                required={true}
                                                isMultiple={false}
                                                width="100%"
                                                field="field"
                                                id="key"
                                                name="key"
                                                value={service.key}
                                                onChange={(event) => handleServiceChange(index, "key", event.target.value)}
                                                options={data.serviceTypes}
                                             // options={data.subscriptionPlan?.services.size <= index - 1 ? data.serviceTypes : data.serviceTypes?.filter((serviceType) => !data.subscriptionPlan?.services?.some(service => service.key === serviceType))}
                                             />
                                          </Grid>

                                          <Grid item xs={12} md={2}>
                                             <InputLabel required={true} htmlFor="value">{t('label.value')}</InputLabel>
                                             <CustomTextField
                                                name="value"
                                                id="value"
                                                type="number"
                                                step={geSubscriptionPlanService().step}
                                                min={geSubscriptionPlanService().min}
                                                max={geSubscriptionPlanService().max}
                                                value={service.value}
                                                onChange={(event) => {
                                                   let value = validateInputNumber(event.target.value, geSubscriptionPlanService().length, geSubscriptionPlanService().min, geSubscriptionPlanService().max, geSubscriptionPlanService().isFloat, false, "", true, 4);
                                                   if (value === undefined) value = service.value;
                                                   handleServiceChange(index, "value", parseFloat(value))
                                                }}
                                             />
                                          </Grid>

                                          <Grid item xs={"auto"}>
                                             <FormControlLabel control={
                                                <Switch
                                                   defaultChecked={service.oneTimePayment}
                                                   onChange={(event) => handleServiceChange(index, "oneTimePayment", event.target.checked)} />
                                             }
                                                label={t('subscriptionPlan.service.oneTimePayment')}
                                             />
                                          </Grid>

                                          <Grid item xs={12} md={1}>
                                             <CustomIconButton
                                                keyValue={`btn-delete-${index}`}
                                                onClick={() => removeService(index)}
                                                icon={<DeleteIcon style={subscriptionPlanButtonGroupStackButtonIcon()} />}
                                                iconSx={{ marginRight: "0px!important" }}
                                                sx={{
                                                   backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                   ":hover": {
                                                      backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                   },
                                                   ...subscriptionPlanButtonGroupStackButton(),
                                                   marginTop: "20px"
                                                }}
                                             >
                                                {t('actions.delete')}
                                             </CustomIconButton>
                                          </Grid>
                                       </Grid>
                                    ))}
                                 </Grid>

                                 <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                    <CustomIconButton
                                       sx={addCardActionAreaButtonStyle()}
                                       backgroundColor="#dcf1f7"
                                       icon={<AddIcon sx={addSubscriptionPlanButtonIconStyle()} />}
                                       onClick={handleAddService}>
                                       {t('actions.add')}
                                    </CustomIconButton>
                                 </Grid>
                              </Grid>
                           </>
                           }
                        />
                     </Grid>

                     <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                        <CustomCollapsableFieldset
                           isOpen={false}
                           label={`${t('actions.add')} ${t('label.detail')}`}
                           children={<><Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "10px" }}>
                              <Grid item xs={12} md={12}>
                                 {data.subscriptionPlan?.details.map((detail, index) => (
                                    <Grid container spacing={2} key={index} sx={{ marginTop: 0, alignItems: "center" }}>

                                       <Grid item xs={12} md={4}>
                                          <InputLabel required={true} htmlFor="key">{t('label.key')}</InputLabel>
                                          <CustomTextField
                                             type="text"
                                             name="key"
                                             required={true}
                                             value={detail.key}
                                             onChange={(event) => handleDetailChange(index, "key", event.target.value)}
                                             sx={{ maxHeight: "50px" }}
                                          />
                                       </Grid>

                                       <Grid item xs={12} md={4}>
                                          <InputLabel required={true} htmlFor="value">{t('label.value')}</InputLabel>
                                          <CustomTextField
                                             type="text"
                                             name="value"
                                             required={true}
                                             value={detail.value}
                                             onChange={(event) => handleDetailChange(index, "value", event.target.value)}
                                             sx={{ maxHeight: "50px" }}
                                          />
                                       </Grid>

                                       <Grid item xs={12} md={1}>
                                          <CustomIconButton
                                             keyValue={`btn-delete-${index}`}
                                             onClick={() => removeDetail(index)}
                                             icon={<DeleteIcon style={subscriptionPlanButtonGroupStackButtonIcon()} />}
                                             iconSx={{ marginRight: "0px!important" }}
                                             sx={{
                                                backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                ":hover": {
                                                   backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                },
                                                ...subscriptionPlanButtonGroupStackButton(),
                                                marginTop: "20px"
                                             }}
                                          >
                                             {t('actions.delete')}
                                          </CustomIconButton>
                                       </Grid>
                                    </Grid>
                                 ))}
                              </Grid>

                              <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                 <CustomIconButton
                                    sx={addCardActionAreaButtonStyle()}
                                    backgroundColor="#dcf1f7"
                                    icon={<AddIcon sx={addSubscriptionPlanButtonIconStyle()} />}
                                    onClick={handleAddDetail}>
                                    Add
                                 </CustomIconButton>
                              </Grid>
                           </Grid>
                           </>
                           }
                        />
                     </Grid>
                  </Grid>

                  {/* <CustomIconButton id="btn-new-subscription-plan" variant="outlined" keyValue="save" onClick={data.isAdd ? addSubscriptionPlan : editSubscriptionPlan} label={t('actions.save')} sx={formSaveButton()} /> */}
               </form>

               <Fab color="primary"
                  aria-label="add"
                  sx={fabStyle()}
                  onClick={data.isAdd ? addSubscriptionPlan : editSubscriptionPlan}
               >
                  <SaveIcon />
               </Fab>
            </CustomGrid>

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }
         </CustomGrid>
      );
   }
}


export default SubscriptionPlanAddEditComponent;