import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { findCurrency } from 'utils/functions/order';

function RevenueMixChart({ data, currency, isHour }) {
    const { t } = useTranslation();
    const options = {
        chart: {
            stacked: false,
            zoom: {
                enabled: true,
                show: true
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            curve: 'smooth',
            width: [0, 2]
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        fill: {
            opacity: [1, 0.5]
        },
        labels: data.map(entry => entry.id + isHour ? ":00" : ""),
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1],
            // formatter: function (val, opts) {
            //     return opts.w.globals.labels[opts.dataPointIndex] + ": " + val.toFixed(2);
            // },
            style: {
                colors: ['#008ffb73', '#e3810099'],
                borderRadius: "10px",
                padding: "10px"
            }
        },
        colors: ['#008ffb73', '#e3810099'],
        xaxis: {
            type: 'category',
        },
        yaxis: [
            {
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                labels: {
                    style: {
                        colors: '#008FFB'
                    },
                    formatter: function (val) {
                        return `${val}`
                    }
                },
                title: {
                    text: t('analytics.chart.revenue.totalOrders'),
                    style: {
                        color: '#008FFB'
                    }
                }
            },
            {
                opposite: true,
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: '#e38100'
                },
                labels: {
                    style: {
                        colors: '#e38100'
                    },
                    formatter: function (val) {
                        return `${val.toFixed(2)} ${findCurrency(currency)}`
                    }
                },
                title: {
                    text: t('analytics.chart.revenue.totalRevenue'),
                    style: {
                        color: '#e38100'
                    }
                }
            }
        ],
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (val, opts) {
                    if (opts.seriesIndex === 0) {
                        return val.toFixed(0);
                    } else {
                        return `${val.toFixed(2)} ${findCurrency(currency)}`;
                    }
                }
            }
        }
    };

    const series = [
        {
            name: t('analytics.chart.revenue.totalOrders'),
            type: 'column',
            data: data.map(entry => entry.totalOrders)
        },
        {
            name: t('analytics.chart.revenue.totalRevenue'),
            type: 'line',
            data: data.map(entry => entry.totalRevenue)
        }
    ];

    return (
        <div>
            <Chart options={options} series={series} type="line" height={700} />
        </div>
    );
};

export default RevenueMixChart;
