import ArrowBack from '@mui/icons-material/ArrowBack';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Grid,
    ListItem
} from '@mui/material';
import { ExpandMoreIcon, PlaceIcon } from 'assets/mui/MuiIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { getThemeColor } from 'constants/theme';
import { useTranslation } from 'react-i18next';
import { orderHeaderTextStyle, orderIconStyleSmall, orderTextStyle, pastOrderCartTextStyle, pastOrderDateTextBoldStyle } from 'themes/defaultThemes';
import { dateTimeFormatter, findCurrency } from 'utils/functions';
import { fieldFormatter } from 'utils/table';
import { getTheme } from 'utils/theme';
import CustomSinglePastOrderCard from '../order/CustomSinglePastOrderCard';

/**
 * The CustomPastOrderCard, that display each customer past order.
 * 
 * @param {object} order the past order details
 * @param {string} lang the lang code
 *
 * @returns {JSX.Element} A React component that renders a past order.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPastOrderCard({ order, outletCurrency }) {
    const { t } = useTranslation();

    function cartToText() {
        let text = "";
        order.cart.map((cartItem) =>
            text += `${cartItem.quantity}x ${cartItem.name}, `
        );

        return text;
    }

    return (
        <>
            <Grid item xs={12} md={12}>
                <ListItem>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid container sx={{ paddingLeftt: "10%" }}>
                                <Grid item xs={6} md={2} sx={{ alignSelf: "center" }}>
                                    {fieldFormatter(order.status, {
                                        background: getThemeColor()[getTheme()]["actions"][`${order.status}`] + "4d",
                                        color: getThemeColor()[getTheme()]["actions"][`${order.status}`],
                                        fontSize: "9px",
                                        height: "18px"
                                    })}
                                </Grid>

                                <Grid item xs={6} md={1.5} sx={{ alignSelf: "center" }}>
                                    <Grid container sx={{ paddingLeft: "10%", alignSelf: "center" }}>
                                        <Grid item xs={5} md={5} >
                                            <PlaceIcon sx={orderIconStyleSmall()} />
                                        </Grid>

                                        <Grid item xs={7} md={7} sx={{ alignSelf: "center" }}>
                                            <CustomTypography variant="body2" text={order?.equipment?.label} sx={orderTextStyle()} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={6.5}>
                                    <Grid container sx={{ marginLeft: "10px" }}>
                                        <Grid item xs={12} md={12}>
                                            <CustomTypography variant="body2" text={`${dateTimeFormatter(order.dateCreated, "DD/MM/yyyy - HH:mm")} - ID: ${order.id}`} sx={pastOrderDateTextBoldStyle()} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <CustomTypography variant="body2" text={cartToText()} sx={pastOrderCartTextStyle()} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={10} md={2} sx={{ paddingLeft: "20px", alignSelf: "center" }}>
                                    <CustomTypography variant="body2" text={`${findCurrency(outletCurrency)} ${parseFloat(order.totalAmmount).toFixed(2)}`} sx={orderHeaderTextStyle()} />
                                </Grid>
                            </Grid>
                        </AccordionSummary>

                        <AccordionDetails>
                            <CustomSinglePastOrderCard
                                order={order}
                                equipment={order.equipment}
                            />
                        </AccordionDetails>
                    </Accordion>
                </ListItem>
            </Grid>
        </>
    );
}

export default CustomPastOrderCard;