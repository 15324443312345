import {
    Add as AddIcon,
    Save as SaveIcon
} from '@mui/icons-material';
import { Box, Fab, Stack, Tab } from "@mui/material";
import menuApi from 'api/menu';
import itemImage from 'assets/images/item.jpg';
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomPreviewMenuItems from 'components/Menu/CustomPreviewMenuItems';
import CustomSelect from 'components/Select/CustomSelect';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTextField from 'components/Text/CustomTextField';
import { getFontFamilyOptions } from 'constants/fontOptions';
import { MuiColorInput } from 'mui-color-input';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CustomPreviewMenuMobileTabsWithChip from 'components/Menu/CustomPreviewMenuMobileTabsWithChip';
import { Grid, InputLabel } from '@mui/material';
import CustomPreviewItemDialog from 'components/Dialogs/CustomPreviewItemDialog';
import CustomGrid from 'components/Grid/CustomGrid';
import { fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb } from 'themes/defaultThemes';
import { hasRoleAdminMenu } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';

/**
 * The MenuSettingsComponent that allow the ADMIN to create a 
 * new outlet or edit an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function MenuSettingsComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { menuID } = useParams();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    path += `/menu/settings`;

    /**
     * @type {object}
     * @property {boolean} isLoaded to render DOM based on rest api call status, if true the rest api call completed
     * @property {*} icon the legend fa icon
     * @property {object} userAuth the authenticated user infromation
     * @property {boolean} isAdd to define if requested an edit or an add of the knowledge base category
     * @property {object} organization the organization basic details (id and name)
     * @property {object} outlet tha outlet basic details (id and name)
     * @property {array} outlets the organization's outlet basic details (id, name)
     * @property {object} alertBox holds snackbar infromation and style
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        icon: AddIcon,
        userAuth: null,
        isAdd: outletID === undefined,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        menu: {
            id: menuID,
            name: ""
        },
        itemPhotoStyles: [],
        menuChipStyles: [],
        // error messages per field
        isError: {
            name: "",
            url: ""
        }
    });

    const [tabActiveVlue, setTabActiveVlue] = React.useState('1');

    // isLoaded to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        id: "",
        field: "",
        translations: {}
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getMenuSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //change document title
    useDocumentTitle(`Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t('org.pageTitle') : ""} | ${t('menus.pageTitle')} | ${t('menus.settings.label')}`);

    /**
     * The rest endpoint to get the outlet settings.
     */
    function getMenuSettings() {
        menuApi.fetchOneSettings(organizationID, outletID, menuID).then((r) => {
            setData({
                ...data,
                userAuth: r.data.returnobject.userAuth,
                organization: r.data.returnobject.organization,
                outlet: r.data.returnobject.outlet,
                menu: r.data.returnobject.menu,
                layoutOptions: r.data.returnobject.layoutOptions,
                itemPhotoStyles: r.data.returnobject.itemPhotoStyles,
                menuChipStyles: r.data.returnobject.menuChipStyles
            });
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        });
    }

    /**
     * Function that triggers the modification of the outlet
     */
    function editMenuSettings() {
        menuApi.updateSettings(data.menu).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that triggers the control input value change.
     * @param {*} event 
     */
    function onControlChange(event, field) {
        formValChangeWithParentElementWithNameAndValue(`menu.settings.${field}`, event.target.checked, data, setData)
    }

    /**
     * Function that triggers the modification of the layout style
     * @param {*} tagToDelete 
     */
    function updateLayoutStyle(event, layoutOption) {
        let layouts = data.menu.settings.layout;
        if (layouts.includes(layoutOption)) {
            const index = layouts.indexOf(layoutOption);
            // only splice array when item is found
            if (index > -1) layouts.splice(index, 1); // 2nd parameter means remove one item only
        } else {
            layouts.push(layoutOption);
        }
        formValChangeWithParentElementWithNameAndValue(`menu.settings.layout`, layouts, data, setData)
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    function handleTabChange(event, newValue) {
        setTabActiveVlue(newValue);
    };

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <LoadingSkeleton lines={9} />;
    } else {

        return (
            // MENU Settings
            <CustomGrid role="main" id={"menu-settings"} sx={mainTag()}>
                {organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: menuID, url: `/${getLanguageFromURL()}/menu/${menuID}` },
                                { name: t('menus.pageTitle'), url: `/${getLanguageFromURL()}/menu` },
                            ]}
                            instanceName={t('outlet.settings.label')}
                        />
                    </CustomGrid>
                }
                {organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('menus.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/menu` },
                            ]}
                            instanceName={t('menus.settings.label')}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="outlet" onSubmit={(e) => { return false; }}>
                        {data.isAdd &&
                            <input type="hidden" />
                        }

                        <input defaultValue={organizationID} name="organizationID" type="hidden" id="organizationID" />
                        {!data.isAdd && data.outlet !== null &&
                            <input defaultValue={outletID} name="id" type="hidden" id="id" />
                        }
                        {!data.isAdd && data.menu !== null &&
                            <input defaultValue={menuID} name="id" type="hidden" id="id" />
                        }

                        <TabContext value={tabActiveVlue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "30px" }}>
                                <TabList onChange={handleTabChange} TabIndicatorProps={{ style: { backgroundColor: "#47a6d2", color: "#47a6d2" } }}>
                                    <Tab label={t('menuCategories.items')} value="1" sx={{ color: "#000", ":hover": { color: "#47a6d2" }, ":focus": { fontWeight: "bold", color: "#47a6d2" }, "&.Mui-selected": { color: "#47a6d2" } }} />
                                    <Tab label={t('itemOrModifier.pageTitleItem')} value="2" sx={{ color: "#000", ":hover": { color: "#47a6d2" }, ":focus": { fontWeight: "bold", color: "#47a6d2" }, "&.Mui-selected": { color: "#47a6d2" } }} />
                                    <Tab label={t('menus.settings.navigationMenu')} value="3" sx={{ color: "#000", ":hover": { color: "#47a6d2" }, ":focus": { fontWeight: "bold", color: "#47a6d2" }, "&.Mui-selected": { color: "#47a6d2" } }} />
                                </TabList>
                            </Box>

                            {/* ITEMS */}
                            <TabPanel value="1">
                                {/* TODO: add photos */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <CustomPreviewMenuItems {...data.menu.settings} layoutOptions={data.layoutOptions} />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="itemBackgroundColor">{t('menus.settings.itemBackgroundColor')}</InputLabel>
                                        <MuiColorInput id="itemBackgroundColor"
                                            name="menu.settings.itemBackgroundColor"
                                            format="hex8"
                                            sx={{ width: "100%" }}
                                            value={data.menu.settings.itemBackgroundColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemBackgroundColor", value, data, setData)}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="itemHeaderColor">{t('menus.settings.itemHeaderColor')}</InputLabel>
                                        <MuiColorInput id="itemHeaderColor"
                                            name="menu.settings.itemHeaderColor"
                                            format="hex8"
                                            sx={{ width: "100%" }}
                                            value={data.menu.settings.itemHeaderColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemHeaderColor", value, data, setData)}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="itemHeaderFontSize">{t('menus.settings.itemHeaderFontSize')}</InputLabel>
                                        <CustomTextField
                                            name="menu.settings.itemHeaderFontSize"
                                            defaultValue={data.menu.settings.itemHeaderFontSize}
                                            required={true}
                                            sx={{ border: "none" }}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemHeaderFontSize", event.target.value, data, setData)}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="itemDescColor">{t('menus.settings.itemDescColor')}</InputLabel>
                                        <MuiColorInput id="itemDescColor"
                                            name="menu.settings.itemDescColor"
                                            format="hex8"
                                            sx={{ width: "100%" }}
                                            value={data.menu.settings.itemDescColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemDescColor", value, data, setData)}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="itemDescFontSize">{t('menus.settings.itemDescFontSize')}</InputLabel>
                                        <CustomTextField
                                            name="menu.settings.itemDescFontSize"
                                            defaultValue={data.menu.settings.itemDescFontSize}
                                            required={true}
                                            sx={{ border: "none" }}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemDescFontSize", event.target.value, data, setData)}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <Stack direction="column" sx={{ paddingBottom: "20px" }}>
                                            <InputLabel required={false} htmlFor="itemPriceColor">{t('menus.settings.itemPriceColor')}</InputLabel>
                                            <MuiColorInput id="itemPriceColor"
                                                name="menu.settings.itemPriceColor"
                                                format="hex8"
                                            sx={{ width: "100%" }}
                                                value={data.menu.settings.itemPriceColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemPriceColor", value, data, setData)}
                                            />
                                        </Stack>
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="itemPriceFontSize">{t('menus.settings.itemPriceFontSize')}</InputLabel>
                                        <CustomTextField
                                            name="menu.settings.itemPriceFontSize"
                                            defaultValue={data.menu.settings.itemPriceFontSize}
                                            required={true}
                                            sx={{ border: "none" }}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemPriceFontSize", event.target.value, data, setData)}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="layout">{t('menus.settings.layout.label')}</InputLabel>

                                        <Stack direction="row">
                                            <Stack direction="column" sx={{ alignItems: "center" }} onClick={(event) => updateLayoutStyle(event, data.layoutOptions[0])}>
                                                <span style={{ paddingRight: "20px" }}>
                                                    <svg width="112" height="98" xmlns="http://www.w3.org/2000/svg">
                                                        <g stroke={`${data.menu.settings.layout.includes(data.layoutOptions[0]) ? "#47a6d2" : "#A2B8C6"}`} fill="none" fillRule="evenodd">
                                                            <rect fill="#F4F6F9" x=".5" y=".5" width="110" height="29" rx="3"></rect>
                                                            <path fill="#A2B8C6" d="M80.5 29.5h1v-29h-1z"></path>
                                                            <rect fill="#F4F6F9" x=".5" y="34.5" width="110" height="29" rx="3"></rect>
                                                            <path fill="#A2B8C6" d="M80.5 62.5h1v-29h-1z"></path>
                                                            <rect fill="#F4F6F9" x=".5" y="68.5" width="110" height="29" rx="3"></rect>
                                                            <path fill="#A2B8C6" d="M80.5 97.5h1v-29h-1z"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <InputLabel required={false} style={{ fontSize: "10px" }}>{t('menus.settings.layout.LIST')}</InputLabel>
                                            </Stack>

                                            <Stack direction="column" sx={{ alignItems: "center" }} onClick={(event) => updateLayoutStyle(event, data.layoutOptions[1])} >
                                                <span>
                                                    <svg width="112" height="98" xmlns="http://www.w3.org/2000/svg">
                                                        <g stroke={`${data.menu.settings.layout.includes(data.layoutOptions[1]) ? "#47a6d2" : "#A2B8C6"}`} fill="none" fillRule="evenodd">
                                                            <rect fill="#F4F6F9" x=".5" y=".5" width="47" height="45" rx="3"></rect>
                                                            <rect fill="#F4F6F9" x="51.5" y=".5" width="47" height="45" rx="3"></rect>
                                                            <path fill="#A2B8C6" d="M.5 30.5h45v1h-25zm52 0h25v1h-25zm0 0h46v1h-25z"></path>
                                                            <rect fill="#F4F6F9" x="52.5" y="50.5" width="47" height="45" rx="3"></rect>
                                                            <rect fill="#F4F6F9" x=".5" y="50.5" width="47" height="45" rx="3"></rect>
                                                            <path fill="#A2B8C6" d="M.5 80.5h47v1h-25zm52 0h25v1h-25zm0 0h46v1h-25z"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <InputLabel required={false} style={{ fontSize: "10px" }}>{t('menus.settings.layout.GRID')}</InputLabel>
                                            </Stack>
                                        </Stack>
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="itemPhotoStyle">{t('menus.settings.itemPhotoStyle.label')}</InputLabel>

                                        <Stack direction="row">
                                            <Stack direction="column" gap={1} sx={{ alignItems: "center" }}>
                                                <img src={itemImage} width={112} height={98}
                                                    alt={t('menus.settings.itemPhotoStyle.SQUARE')}
                                                    onClick={() => formValChangeWithParentElementWithNameAndValue("menu.settings.itemPhotoStyle", "SQUARE", data, setData)}
                                                    style={{ borderRadius: "10px", marginRight: "20px", opacity: data.menu.settings.itemPhotoStyle === "SQUARE" ? 1 : 0.5, boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" }}
                                                />
                                                <InputLabel required={false} style={{ width: "40%", fontSize: "11px" }}>{t('menus.settings.itemPhotoStyle.SQUARE')}</InputLabel>
                                            </Stack>
                                            <Stack direction="column" gap={1} sx={{ alignItems: "center" }}>
                                                <img src={itemImage} width={112} height={98}
                                                    alt={t('menus.settings.itemPhotoStyle.ROUND')}
                                                    onClick={() => formValChangeWithParentElementWithNameAndValue("menu.settings.itemPhotoStyle", "ROUND", data, setData)}
                                                    style={{ borderRadius: "50%", opacity: data.menu.settings.itemPhotoStyle === "ROUND" ? 1 : 0.5, boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" }}
                                                />
                                                <InputLabel required={false} style={{ width: "40%", fontSize: "11px" }}>{t('menus.settings.itemPhotoStyle.ROUND')}</InputLabel>
                                            </Stack>
                                        </Stack>
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <CustomCheckbox
                                            name="menu.settings.showCurrency"
                                            selected={data.menu.settings.showCurrency}
                                            onChange={(event) => onControlChange(event, "showCurrency")}
                                            label={t('menus.settings.showCurrency')}
                                        />
                                        <CustomCheckbox
                                            name="menu.settings.showDescription"
                                            selected={data.menu.settings.showDescription}
                                            onChange={(event) => onControlChange(event, "showDescription")}
                                            label={t('menus.settings.showDescription')}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            {/* Item popup */}
                            <TabPanel value="2">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <CustomPreviewItemDialog {...data.menu.settings} />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="baseColor">{t('menus.settings.baseColor')}</InputLabel>
                                        <MuiColorInput id="baseColor"
                                            name="menu.settings.baseColor"
                                            format="hex8"
                                            sx={{ width: "100%" }}
                                            value={data.menu.settings.baseColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.baseColor", value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="font">{t('menus.settings.font')}</InputLabel>
                                        <CustomSelect name="menu.settings.font" required={true}
                                            defaultValue={data.menu.settings.font}
                                            options={getFontFamilyOptions()}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.font", event.target.value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="buttonFont">{t('menus.settings.buttonFont')}</InputLabel>
                                        <CustomSelect name="menu.settings.buttonFont" required={true}
                                            defaultValue={data.menu.settings.buttonFont}
                                            options={getFontFamilyOptions()}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.buttonFont", event.target.value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="buttonColor">{t('menus.settings.buttonColor')}</InputLabel>
                                        <MuiColorInput id="buttonColor"
                                            name="menu.settings.buttonColor"
                                            format="hex8"
                                            sx={{ width: "100%" }}
                                            value={data.menu.settings.buttonColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.buttonFont", value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="buttonColor">{t('menus.settings.buttonBackgroundColor')}</InputLabel>
                                        <MuiColorInput id="buttonBackgroundColor"
                                            name="menu.settings.buttonBackgroundColor"
                                            format="hex8"
                                            sx={{ width: "100%" }}
                                            value={data.menu.settings.buttonBackgroundColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.buttonBackgroundColor", value, data, setData)}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="buttonFontSize">{t('menus.settings.buttonFontSize')}</InputLabel>
                                        <CustomTextField
                                            name="menu.settings.buttonFontSize"
                                            defaultValue={data.menu.settings.buttonFontSize}
                                            required={true}
                                            sx={{ border: "none" }}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.buttonFontSize", event.target.value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <CustomCheckbox
                                            name="menu.settings.showPointsPerItem"
                                            selected={data.menu.settings.showPointsPerItem}
                                            onChange={(event) => onControlChange(event, "showPointsPerItem")}
                                            label={t('menus.settings.showPointsPerItem')}
                                        />
                                        <CustomCheckbox
                                            name="menu.settings.allowCommentsOnItem"
                                            selected={data.menu.settings.allowCommentsOnItem}
                                            onChange={(event) => onControlChange(event, "allowCommentsOnItem")}
                                            label={t('menus.settings.allowCommentsOnItem')}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            {/* Navigation Menu */}
                            <TabPanel value="3">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <CustomPreviewMenuMobileTabsWithChip {...data.menu.settings} />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="navigationMenuFont">{t('menus.settings.navigationMenuFont')}</InputLabel>
                                        <CustomSelect name="menu.settings.navigationMenuFont" required={true}
                                            defaultValue={data.menu.settings.navigationMenuFont}
                                            options={getFontFamilyOptions()}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.navigationMenuFont", event.target.value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="navigationMenuFontSize">{t('menus.settings.navigationMenuFontSize')}</InputLabel>
                                        <CustomTextField
                                            name="menu.settings.navigationMenuFontSize"
                                            defaultValue={data.menu.settings.navigationMenuFontSize}
                                            required={true}
                                            sx={{ border: "none" }}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.navigationMenuFontSize", event.target.value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="navigationMenuBackgroundColor">{t('menus.settings.navigationMenuBackgroundColor')}</InputLabel>
                                        <MuiColorInput id="navigationMenuBackgroundColor"
                                            name="menu.settings.navigationMenuBackgroundColor"
                                            format="hex8"
                                            sx={{ width: "100%" }}
                                            value={data.menu.settings.navigationMenuBackgroundColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.navigationMenuBackgroundColor", value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="navigationMenuSelectedOptionFontColor">{t('menus.settings.navigationMenuSelectedOptionFontColor')}</InputLabel>
                                        <MuiColorInput id="navigationMenuSelectedOptionFontColor"
                                            name="menu.settings.navigationMenuSelectedOptionFontColor"
                                            format="hex8"
                                            sx={{ width: "100%" }}
                                            value={data.menu.settings.navigationMenuSelectedOptionFontColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.navigationMenuSelectedOptionFontColor", value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="navigationMenuUnSelectedOptionFontColor">{t('menus.settings.navigationMenuUnSelectedOptionFontColor')}</InputLabel>
                                        <MuiColorInput id="navigationMenuUnSelectedOptionFontColor"
                                            name="menu.settings.navigationMenuUnSelectedOptionFontColor"
                                            format="hex8"
                                            sx={{ width: "100%" }}
                                            value={data.menu.settings.navigationMenuUnSelectedOptionFontColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.navigationMenuUnSelectedOptionFontColor", value, data, setData)}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="navigationMenuStyle">{t('menus.settings.navigationMenuStyle')}</InputLabel>
                                        <CustomSelect name="menu.settings.navigationMenuStyle" required={true}
                                            defaultValue={data.menu.settings.navigationMenuStyle}
                                            options={data.menuChipStyles}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.navigationMenuStyle", event.target.value, data, setData)}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>

                        <Grid container spacing={2}>
                            <Grid item>
                                <CustomIconButton
                                    variant="outlined"
                                    keyValue="save"
                                    onClick={editMenuSettings}
                                    label={t('actions.save')}
                                    sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                />
                            </Grid>

                            {data.isAdd &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        link={`/${getLanguageFromURL()}/menu`}
                                        label={t('actions.back')}
                                        sx={formSaveButton()}
                                    />
                                </Grid>
                            }

                            {!data.isAdd &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        onClick={getMenuSettings}
                                        label={t('actions.cancel')}
                                        sx={formSaveButton()}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={editMenuSettings}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={6000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default MenuSettingsComponent;