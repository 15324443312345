import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { request } from 'constants/alias';
import { API, CRUD } from 'constants/config';
import { AuthProvider } from 'context/AuthContext';
import cookiesService from "services/cookies.service";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

/**
 * The Auth Provider.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function Authenticate({ children }) {
  const [state, setState] = useState({
    isAuthenticated: false,
    getAuthenticatedUserCompleted: false,
    user: {
      roles: ['anonymous'],
    },
    preferences: {},
    sidenav: {
      expanded: true,
      activeKey: '1'
    }
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  if (token !== null && token !== undefined) {
    localStorage.setItem("token", token);
    Cookies.set('auth_token', token);
  }

  useEffect(() => {
    getAuthenticatedUser()
      .then(response => console.log(response))
      .catch(error => console.log(error));
  }, []);

  async function getAuthenticatedUser() {
    try {
      const token = localStorage.getItem("token");

      let data = jwt_decode(token);
      if (data.exp < Math.floor(Date.now() / 1000)) {
        handleTokenExpiration();
        return `Token for user ${data.sub} Expired!`
      }
      // localStorage.setItem('user', localStorage.getItem("rememberMe") === "true" ? data.sub : '');

      setState({
        ...state,
        isAuthenticated: true,
        getAuthenticatedUserCompleted: true,
        sidenav: {
          expanded: true,
          activeKey: '1'
        },
        user: {
          username: data.sub,
          roles: data.role.split(',').map(str => str.startsWith("ROLE_") ? str.slice("ROLE_".length) : str),
          // id: data.userid,
          // fullname: data.userfullname,
          // email: data.email,
          // phone: data.phone,
        },
        preferences: {},
      });

      return `User ${data.sub} is authenticated`;

    } catch (ex) {
      // console.log(ex);

      const user = {
        roles: ['anonymous'],
      };

      setState({
        ...state,
        user: user,
        getAuthenticatedUserCompleted: true,
      });

      handleTokenExpiration();
      // throw new Error(ex);
    }
  }

  /**
   * Function that triggers the re-authentication of user 
   * after token expires.
   */
  function handleTokenExpiration() {
    return new Promise((resolve, reject) => {
      // Clear request interceptor
      if ('requestInterceptor' in state.preferences) {
        // requestInterceptor is used to monitor unauthorized access to the platform
        request.interceptors.response.eject(state.preferences.requestInterceptor);
      }

      setTimeout(() => {

        setState({
          ...state,
          isAuthenticated: false,
          user: {
            roles: ['anonymous'],
          },
          preferences: {},
          getAuthenticatedUserCompleted: true
        });

      }, CRUD.delay);

      localStorage.removeItem("token");
      cookiesService.logOut();
      // TODO: revert it
      // navigate("/" + getLanguageFromURL());
    });

  }

  /**
   * Login REST call
   *
   * @param username
   * @param password
   * @param webAuthnBody - if U2F enabled else undefined
   * @returns {Promise<any>}
   */
  function handleLogin(username, password, webAuthnBody) {
    return new Promise((resolve, reject) => {
      const requestData = {
        username: username,
        password: password,
      };

      if (webAuthnBody) {
        requestData.webAuthnBody = JSON.stringify(webAuthnBody);
      }

      request
        .post(`${API}auth/login`, requestData)
        .then((response) => {
          let token = JSON.stringify(response.headers.authorization)
          token = token.substring(8, token.length - 1);
          localStorage.setItem("token", token);
          localStorage.setItem("theme", "light");
          // localStorage.setItem("refresh_token", response.data.refresh_token);
          setTimeout(() => {
            getAuthenticatedUser()
              .then(response => console.log(response))
              .catch(error => console.error(error));
          }, CRUD.delay);
        })
        .catch((error) => {
          const { response: { status } } = error;

          if (status === 401 || status === 403) {
            reject('Wrong username or password');
          } else {
            reject('Something went wrong. Please contact the system administrator');
          }
        });
    });
  }

  function handleChangeUser(user) {
    setState({
      ...state,
      user: user,
    });
  }

  function handleChangeUserPreferences(preferences) {
    setState({
      ...state,
      preferences: preferences
    });
  }

  function handleLogoutNoRequest() {
    // Clear request interceptor
    if ('requestInterceptor' in state.preferences) {
      // requestInterceptor is used to monitor unauthorized access to the platform
      request.interceptors.response.eject(state.preferences.requestInterceptor);
    }
  }

  function handleLogout() {
    return new Promise((resolve, reject) => {
      // Clear request interceptor
      if ('requestInterceptor' in state.preferences) {
        // requestInterceptor is used to monitor unauthorized access to the platform
        request.interceptors.response.eject(state.preferences.requestInterceptor);
      }

      setTimeout(() => {

        setState({
          ...state,
          isAuthenticated: false,
          user: {
            roles: ['anonymous'],
          },
          preferences: {},
          getAuthenticatedUserCompleted: true,
        });

      }, CRUD.delay);

      localStorage.clear()

    });
  }

  /**
   * Function that handles the sidenav open/close status.
   */
  function handleSidenavStateChange(expanded) {
    setState({ ...state, sidenav: { ...state.sidenav, expanded: expanded !== undefined ? expanded : !state.sidenav.expanded } });
  }

  /**
   * Function that handles the sidenav active key value.
   */
  function handleSidenavActiveKeyChange(activeKey) {
    setState({ ...state, sidenav: { ...state.sidenav, activeKey: activeKey } });
  }



  // RENDER
  if (!state.getAuthenticatedUserCompleted) return '';

  const authProviderValue = {
    ...state,
    handleLogin: handleLogin,
    handleChangeUser: handleChangeUser,
    handleChangeUserPreferences: handleChangeUserPreferences,
    handleLogoutNoRequest: handleLogoutNoRequest,
    handleLogout: handleLogout,
    handleSidenavStateChange: handleSidenavStateChange,
    handleSidenavActiveKeyChange: handleSidenavActiveKeyChange,
  };

  return (
    <AuthProvider value={authProviderValue}>
      {children}
    </AuthProvider>
  )
}

export default Authenticate;