import { Fullscreen as FullscreenIcon } from '@mui/icons-material';
import orderApi from 'api/order';
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import FullScreenDialog from 'components/Dialogs/FullScreenDialog';
import CustomGrid from 'components/Grid/CustomGrid';
import AddOrderGrid from 'components/Grid/order/AddOrderGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomAddEditTitle from 'components/Title/CustomAddEditTitle';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { mainTag, mainTagBreadcrumb, mainTagTitle, pickupDisplayIconButtons } from 'themes/defaultThemes';
import { getLanguageFromURL } from 'utils/language';
import { reloadPage } from "utils/page";
import useDocumentTitle from "utils/useDocumentTitle";
import { calculateLoyaltyPointsEarn, calculateQuantitiesSum, calculateTotalAmount } from 'utils/cart-functions';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';

/**
 * The OrderAddComponent, that create a new order
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OrderAddComponent() {
    const location = useLocation();
    const requestData = (location && location?.state && location.state !== null) ? location.state : undefined;
    const defaultCustomerOrder = (requestData && requestData !== null) ? requestData.customerDraftOrder : undefined;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organizationID } = useParams();
    const { outletID } = useParams();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/order`;

    //change document title
    useDocumentTitle(`Usee ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('order.pageTitle')}`);

    const [showFullScreen, setShowFullScreen] = useState(false);
    const [filterItems, setFilterItems] = useState("");

    /**
     * @type {object}
     * 
     * @property {object} userAuth the authenticated user infromation
     * @property {object} alertBox holds snackbar infromation and style
     * @property {array} orders the orders list
     * @property {object} outlet tha outlet basic details (id and name, isActive)
     * @property {object} organization tha organization basic details (id and name)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        cart: defaultCustomerOrder ? defaultCustomerOrder : {
            organizationID: "",
            outletID: "",
            allergies: [],
            pvcRecyclingTax: 0.0,
            comments: "",
            equipment: {
                id: "",
                label: ""
            },
            items: [],
            type: "DINE_IN",
            persons: 1,
            paymentMethod: "card",
            isPaid: false,
            loyaltyPointsEarned: 0,
            quantitiesSum: 0,
            totalAmmount: 0,
            discount: {
                loyalty: null,
                coupon: null
            },
            taxPercent: 0
            // Initialize other properties as needed
        },
        sections: [],
        outlets: [],
        equipments: [],
        menus: [],
        types: [],
        currency: "€",
        outletURL: ""
    });

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        if (isFirstTime)
            getOrder();
        setIsFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /**
     * The rest endpoint to get the orders list.
     * @property {number} page the list page number
     */
    function getOrder(page) {
        orderApi.fetchNew().then((r) => {
            if (r.data.code !== "EXCEPTION" && r.data.code !== "INVALID") {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            sections: r.data.returnobject.sections,
                            outlet: r.data.returnobject.outlet,
                            equipments: r.data.returnobject.equipments,
                            menus: r.data.returnobject.menus,
                            types: r.data.returnobject.types,
                            currency: r.data.returnobject.currency,
                            outletURL: r.data.returnobject.outletURL
                        })
                    })
                    .then(() => setSelectedEquipment((requestData && requestData !== null && requestData?.customerDraftOrder?.equipmentID !== null) ? r.data.returnobject.equipments?.find((eq) => eq.id === requestData.customerDraftOrder?.equipmentID) : null))
                    .then(() => setShowEquipments((requestData && requestData !== null) ? false : true))
                    .then(() => setIsLoaded(true))
            }
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    const [selectedFilterSectionID, setSelectedFilterSectionID] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [selectedEquipment, setSelectedEquipment] = useState(null);
    const [selectedParentCategory, setSelectedParentCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [showEquipments, setShowEquipments] = useState(true);
    const [showCheckout, setShowCheckout] = useState(false);
    const [sendOrder, setSendOrder] = useState(false);

    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        id: "",
        cartIndex: "",
        cartItemDefault: null
    });


    function addOrder(cart) {
        if (cart?.id === null || cart?.id === undefined || cart?.id === "") {
            setSendOrder(true);
            let order = {
                // organizationID: data.organizationID,
                // outletID: data.outlet?.id,
                equipmentID: selectedEquipment?.id,
                customer: null, //fill in from backend
                cart: cart?.items.map(item => ({
                    customer: null,
                    id: item.id,
                    name: item.name,
                    quantity: item.quantity,
                    price: item.price,
                    pvcRecyclingTax: item.pvcRecyclingTax,
                    notes: item.notes,
                    done: false,
                    modifiers: item.modifierListIds.flatMap(modifierList =>
                        modifierList?.modifiers.map(modifier => ({
                            id: modifier.id,
                            name: modifier.name, // This needs a lookup or mapping
                            price: 0
                        }))
                    ),
                    unavailable: false,
                    unavailableQuantity: 0,
                    regularItem: item.regularItem,
                    happyHourItem: item.happyHourItem,
                    comboItem: item.comboItem,
                    offerItem: item.offerItem,
                    parentCategoryID: item.parentCategoryID,
                    parentCategoryName: item.parentCategoryName,
                    categoryID: item.categoryID,
                    categoryName: item.categoryName,
                    menuID: item.menuID,
                    menuName: item.menuName
                })),
                allergies: cart?.allergies,
                pvcRecyclingTax: cart?.pvcRecyclingTax,
                comments: cart?.comments,
                discount: cart?.discount,
                loyaltyPointsEarned: calculateLoyaltyPointsEarn(cart),
                quantitiesSum: calculateQuantitiesSum(cart),
                totalAmmount: calculateTotalAmount(cart),
                status: "PENDING",
                type: cart?.type,
                paymentMethod: "cash",
                persons: cart?.persons,
                isPaid: false,
                currencyCode: data.outlet?.settings?.currency,
                vivaPaymentsDetails: {
                    token: "",
                    transactionId: "",
                    email: "",
                    amount: calculateTotalAmount(cart),
                    orderCode: "",
                    statusId: "",
                    fullName: "",
                    insDate: "",
                    cardNumber: "",
                    currencyCode: "",
                    customerTrns: "",
                    merchantTrns: "",
                    transactionTypeId: null,
                    recurringSupport: false,
                    totalInstallments: 0,
                    cardCountryCode: "",
                    cardIssuingBank: "",
                    currentInstallment: 0,
                    cardUniqueReference: "",
                    cardTypeId: 0,
                    digitalWalletId: 0,
                    orderHasBeenSentByScheduledJob: false,
                }
            }

            orderApi.create(order).then((r) => {
                if (r.data.code === "SUCCESS") {
                    navigate(path);
                }
            }).catch((e) => {
                setAlertBox({
                    isOpen: true,
                    message: "Fail to create order",
                    backgroundColor: "#ef5350",
                });
                setSendOrder(false);
            });

        }

    }


    const handleEquipmentSelect = (equipment) => {
        setSelectedEquipment(equipment);
        setShowEquipments(false);
        // Reset other selections
        if (data.menus.length === 1)
            setSelectedMenu(data.menus[0].id);
        else
            setSelectedMenu(null);
        setSelectedParentCategory(null);
        setSelectedCategory(null);
        setSelectedItem(null);
    };

    const handleMenuSelect = (menu) => {
        setSelectedMenu(menu.id);
        if (data.menus?.filter((men) => men.id === menu.id)[0]?.parentCategories?.length === 1)
            handleParentCategorySelect(data.menus?.filter((men) => men.id === menu.id)[0]?.parentCategories[0]);
        else
            setSelectedParentCategory(null);
        setSelectedCategory(null);
        setSelectedItem(null);
    };

    const handleParentCategorySelect = (parentCategory) => {
        setSelectedParentCategory(parentCategory.id);
        if (data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCat) => parentCat.id === parentCategory.id)[0]?.categories?.length === 1)
            handleCategorySelect(data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCat) => parentCat.id === parentCategory.id)[0]?.categories[0]);
        else
            setSelectedCategory(null);
        setSelectedItem(null);
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category.id);
        // Reset other selections
        if (data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCategory) => parentCategory.id === selectedParentCategory)[0]?.categories?.filter((cat) => cat.id === category.id)[0]?.items?.length === 1)
            handleItemSelect(data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCategory) => parentCategory.id === selectedParentCategory)[0]?.categories?.filter((cat) => cat.id[0] === category.id)?.items[0]);
        else
            setSelectedItem(null);
    };

    const handleItemSelect = (item) => {
        setSelectedItem(item.id);
        setDialog({
            ...dialog,
            isOpen: true,
            id: item.id,
            cartIndex: -1,
            cartItemDefault: null
        });
    };

    const handleBackFromCheckOut = () => {
        setShowEquipments(false);
        setSelectedMenu(null);
        setSelectedParentCategory(null);
        setSelectedCategory(null);
        setSelectedItem(null);
        setShowCheckout(false);
    };


    // ========================= ITEM
    function addItemToCartCallback(item, modifiersPrice, cartIndex = -1) {
        // outletId, organizationId, item, modifiersPrice, pvcRecyclingTax)
        // dispatch(addItemToCart(data.outlet.id, data.organization.id, cartItem, modifiersPrice));
        let newCart = data.cart;
        let isAddAction = cartIndex === -1;

        if (isAddAction) {
            // Check if the item already exists in the cart
            const existingItemIndex = newCart?.items.findIndex(cartItem =>
                cartItem.id === item?.id &&
                cartItem.name === item?.name &&
                cartItem.nameEn === item?.nameEn &&
                cartItem.notes === item?.notes &&
                cartItem.price === item?.price &&
                cartItem.pvcRecyclingTax === item?.pvcRecyclingTax &&
                cartItem?.modifierListIds.length === item?.modifierListIds.length && // Check lengths first

                // Compare each modifierListId separately
                cartItem?.modifierListIds.every((modifier, index) => {
                    return modifier.modifierListId === item?.modifierListIds[index].modifierListId &&
                        // Check if modifiers array lengths match
                        cartItem.modifierListIds[index].modifiers.length === item.modifierListIds[index]?.modifiers?.length &&
                        // Compare each modifier object in the modifiers array
                        cartItem.modifierListIds[index].modifiers.every((cartModifier, modifierIndex) =>
                            cartModifier.id === item.modifierListIds[index].modifiers[modifierIndex].id &&
                            cartModifier.price === item.modifierListIds[index].modifiers[modifierIndex].price
                        )
                })
            );

            if (existingItemIndex !== -1) {
                // Item exists, increase the quantity
                newCart.items[existingItemIndex].quantity += item.quantity;
            } else {
                // Item does not exist, add as new item
                newCart.items.push(item);
            }
            // update analytics fields
            newCart.totalAmmount += calculateTotalAmount(newCart);
            newCart.quantitiesSum += + calculateQuantitiesSum(newCart);
            newCart.loyaltyPointsEarned += calculateLoyaltyPointsEarn(newCart);

        } else {
            let existingItems = newCart?.items;

            if (existingItems[cartIndex]?.id === item.id)
                existingItems[cartIndex] = item;

            newCart.items = existingItems;
        }
        setData({
            ...data,
            cart: newCart
        });
    }

    // ========================= Order Type
    const [orderTypeAnchorEl, setOrderTypeAnchorEl] = useState(null);

    function updateOrderTypeOpenCallback(event) {
        setOrderTypeAnchorEl(event.currentTarget);
    };

    function updateOrderTypeCloseCallback() {
        setOrderTypeAnchorEl(null);
    };

    function updateOrderTypeCallback(newValue) {
        formValChangeWithParentElementWithNameAndValue("cart.type", newValue, data, setData);
        // dispatch(updateCartPaymentMethod(data.outlet.id, [newValue]));
        // Optionally close the popover
        updateOrderTypeCloseCallback();
    };

    const openOrderType = Boolean(orderTypeAnchorEl);

    // ========================= PERSONS 
    const [personsAnchorEl, setPersonsAnchorEl] = useState(null);

    function updatePersonsOpenCallback(event) {
        setPersonsAnchorEl(event.currentTarget);
    }

    function updatePersonsCloseCallback() {
        setPersonsAnchorEl(null);
    }

    function updatePersonsCallback(newValue) {
        formValChangeWithParentElementWithNameAndValue("cart.persons", newValue, data, setData);
        // Optionally close the popover
        // updatePersonsCloseCallback();
    }


    /**
     * Function that handles the visualize modal open or close state.
     * @param {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpen, item, cartIndex = -1, cartItemDefault) {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            id: item ? item.id : null,
            cartIndex: cartIndex,
            cartItemDefault: cartItemDefault
        });
    }



    function removeItemFromCart(itemID, indexInCart) {
        let newCart = data.cart;
        if (indexInCart > -1 && newCart.items[indexInCart].id === itemID) newCart?.items.splice(indexInCart, 1); // 2nd parameter means remove one item only

        setData({
            ...data,
            cart: newCart
        })
    }

    function updateItemInCart(itemID, newItem, indexInCart) {
        let newCart = data.cart;
        let existingItems = newCart?.items;
        if (existingItems[indexInCart].id === itemID)
            existingItems[indexInCart] = newItem;

        newCart.items = existingItems;
        setData({
            ...data,
            cart: newCart
        })
    }

    const openPersons = Boolean(personsAnchorEl);
    // ===================================== BACK

    function goBack(from) {
        if (from === "item") {
            setSelectedItem(null);
            if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories?.find((parentCategory) => parentCategory.id === selectedParentCategory)?.categories?.find((category) => category.id === selectedCategory)?.length === 1) {
                setSelectedCategory(null);
                if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories?.find((parentCategory) => parentCategory.id === selectedParentCategory)?.categories?.length === 1) {
                    setSelectedParentCategory(null);
                    if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories.length === 1)
                        setSelectedMenu(null);
                }
            }
        }
        if (from === "items") {
            setSelectedCategory(null);
            if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories?.find((parentCategory) => parentCategory.id === selectedParentCategory)?.categories?.length === 1) {
                setSelectedParentCategory(null);
                if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories.length === 1)
                    setSelectedMenu(null);
            }
        } else if (from === "categories") {
            setSelectedParentCategory(null);
            if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories.length === 1)
                setSelectedMenu(null);
        } else if (from === "parentCategories")
            setSelectedMenu(null);
    }
    // ===============================================================================RENDER

    if (redirect) {
        reloadPage();
    } else if (!isLoaded) {
        return <CustomGrid role="main" id="order-add" sx={mainTag()}>
            <LoadingSkeleton lines={9} />
        </CustomGrid>
    } else {

        return (
            <CustomGrid role="main" id="order-add" sx={mainTag()}>
                {outletID !== undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                                { name: t('order.title'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}/order` }
                            ]}
                            instanceName={t('actions.add')}
                        />
                    </CustomGrid>
                }
                {outletID === undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isEdit={true}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('order.title'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}/order` }
                            ]}
                            instanceName={t('actions.add')}
                        />
                    </CustomGrid>
                }

                {outletID === undefined && organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={false}
                            parents={[
                                { name: t('order.title'), url: `/${getLanguageFromURL()}/order` }
                            ]}
                            instanceName={t('actions.add')}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    <CustomIconButton
                        onClick={() => setShowFullScreen(true)}
                        icon={<FullscreenIcon />}
                        sx={pickupDisplayIconButtons()}
                        // iconSx={{ margin: 0 }}
                        tooltipTitle={t('display.pickup.fullscreen')}
                    />
                    <CustomAddEditTitle
                        isAdd={true}
                        title={t('order.pageTitle')}
                        subtitle={t('order.subtitle')}
                        instanceName={t('order.title')}
                    />
                </CustomGrid>


                <AddOrderGrid
                    data={data}
                    setData={setData}
                    selectedEquipment={selectedEquipment}
                    selectedFilterSectionID={selectedFilterSectionID}
                    setSelectedFilterSectionID={setSelectedFilterSectionID}
                    selectedMenu={selectedMenu}
                    selectedParentCategory={selectedParentCategory}
                    selectedCategory={selectedCategory}
                    selectedItem={selectedItem}
                    showEquipments={showEquipments}
                    setShowEquipments={setShowEquipments}
                    showCheckout={showCheckout}
                    setShowCheckout={setShowCheckout}
                    sendOrder={sendOrder}
                    dialog={dialog}
                    addOrder={addOrder}
                    handleEquipmentSelect={handleEquipmentSelect}
                    handleMenuSelect={handleMenuSelect}
                    handleParentCategorySelect={handleParentCategorySelect}
                    handleCategorySelect={handleCategorySelect}
                    handleItemSelect={handleItemSelect}
                    handleBackFromCheckOut={handleBackFromCheckOut}
                    addItemToCartCallback={addItemToCartCallback}
                    orderTypeAnchorEl={orderTypeAnchorEl}
                    openOrderType={openOrderType}
                    updateOrderTypeOpenCallback={updateOrderTypeOpenCallback}
                    updateOrderTypeCloseCallback={updateOrderTypeCloseCallback}
                    updateOrderTypeCallback={updateOrderTypeCallback}
                    personsAnchorEl={personsAnchorEl}
                    updatePersonsOpenCallback={updatePersonsOpenCallback}
                    updatePersonsCloseCallback={updatePersonsCloseCallback}
                    updatePersonsCallback={updatePersonsCallback}
                    handleDialogState={handleDialogState}
                    removeItemFromCart={removeItemFromCart}
                    updateItemInCart={updateItemInCart}
                    openPersons={openPersons}
                    goBack={goBack}
                    filterItems={filterItems}
                    setFilterItems={setFilterItems}
                />

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }

                {showFullScreen &&
                    <FullScreenDialog
                        isOpen={showFullScreen}
                        handleClose={setShowFullScreen}
                        children={
                            <AddOrderGrid
                                data={data}
                                setData={setData}
                                selectedEquipment={selectedEquipment}
                                selectedFilterSectionID={selectedFilterSectionID}
                                setSelectedFilterSectionID={setSelectedFilterSectionID}
                                selectedMenu={selectedMenu}
                                selectedParentCategory={selectedParentCategory}
                                selectedCategory={selectedCategory}
                                selectedItem={selectedItem}
                                showEquipments={showEquipments}
                                setShowEquipments={setShowEquipments}
                                showCheckout={showCheckout}
                                setShowCheckout={setShowCheckout}
                                sendOrder={sendOrder}
                                dialog={dialog}
                                addOrder={addOrder}
                                handleEquipmentSelect={handleEquipmentSelect}
                                handleMenuSelect={handleMenuSelect}
                                handleParentCategorySelect={handleParentCategorySelect}
                                handleCategorySelect={handleCategorySelect}
                                handleItemSelect={handleItemSelect}
                                handleBackFromCheckOut={handleBackFromCheckOut}
                                addItemToCartCallback={addItemToCartCallback}
                                orderTypeAnchorEl={orderTypeAnchorEl}
                                openOrderType={openOrderType}
                                updateOrderTypeOpenCallback={updateOrderTypeOpenCallback}
                                updateOrderTypeCloseCallback={updateOrderTypeCloseCallback}
                                updateOrderTypeCallback={updateOrderTypeCallback}
                                personsAnchorEl={personsAnchorEl}
                                updatePersonsOpenCallback={updatePersonsOpenCallback}
                                updatePersonsCloseCallback={updatePersonsCloseCallback}
                                updatePersonsCallback={updatePersonsCallback}
                                handleDialogState={handleDialogState}
                                removeItemFromCart={removeItemFromCart}
                                updateItemInCart={updateItemInCart}
                                openPersons={openPersons}
                                goBack={goBack}
                                filterItems={filterItems}
                                setFilterItems={setFilterItems}
                            />
                        }
                    />
                }

            </CustomGrid>
        );
    }

}

export default OrderAddComponent; 