import { getEyeRadius } from 'constants/eyeRadiusOptions';
import { getQrcodeFrameSelectedProperties, getQrcodeFrameSelectedSxProperties } from 'constants/fieldProperties';
import { QRCode } from 'react-qrcode-logo';

import {
    BalloonBottom,
    BalloonTop,
    Border10LabelFlat,
    Bottom,
    Cinema,
    CircleLabel,
    CircleThinFlat,
    Corners,
    CornersLabelFlat,
    D1Label,
    DonitDisturb,
    Hexagon10Label,
    LockScreen,
    LockscreenCircle,
    LockscreenRounded,
    Phone,
    RibbonBottom,
    RibbonTop,
    RoundBorder,
    RoundedBorder10LabelFlat,
    RoundedCorners,
    RoundedCornersFlat,
    SimpleBottom,
    SimpleTop,
    Top,
} from 'assets/svg/SvgIcons';


/**
 * Custom QRcode
 * 
 * @param {string} value  The value encoded in the QR Code. When the QR Code is decoded, this value will be returned
 * @param {*} ecLevel ?  L | M | Q | H The error correction level of the QR Code. @default M
 * @param {boolean} enableCORS Enable crossorigin attribute. @default false
 * @param {number} size The size of the QR Code. @default 150
 * @param {number} quietZone The size of the quiet zone around the QR Code. This will have the same color as QR Code bgColor. @default 10
 * @param {string} bgColor Background color. @default #FFFFFF
 * @param {string} fgColor Foreground  color. @default #000000
 * @param {string} logoImage The logo image. It can be a url/path or a base64 value 
 * @param {number} logoWidth Logo image width. @default size * 0.2
 * @param {number} logoHeight Logo image height. @default logoWidth
 * @param {number} logoOpacity Logo opacity. This allows you to modify the transparency of your logo, so that it won't compromise the readability of the QR Code. @default 1
 * @param {boolean} removeQrCodeBehindLogo Removes points behind the logo. If no logoPadding is specified, the removed part will have the same size as the logo. @default false
 * @param {number} logoPadding Adds a border with no points around the logo. When > 0, the padding will be visible even if the prop removeQrCodeBehindLogo is not set to true
 * @param {square | circle} logoPaddingStyle Sets the shape of the padding area around the logo. Works if logoPadding or removeQrCodeBehindLogo are set.  @default square
 * @param {squares | dots} qrStyle Style of the QR Code modules  @default squares
 * @param {CornerRadii | CornerRadii[]} eyeRadius The corner radius for the positional patterns (the three "eyes" around the QR code).
 * @param {EyeColor | EyeColor[]} eyeColor The color for the positional patterns (the three "eyes" around the QR code). 
 * @param {string} id Optional custom id for the QRCode canvas. You can use this prop if you have multiple QRCodes and need to differentiate them. @default "react-qrcode-logo"
 * 
 * @returns 
 */
function CustomQRCode({ value = "https://usee.gr", ecLevel, enableCORS = false, size = 200,
    quietZone = 10, bgColor = "#FFFFFF", fgColor = "#000000", logoImage, logoWidth = 40, logoHeight = 40, logoOpacity = 1, logoOnLoad,
    removeQrCodeBehindLogo = false, logoPadding, logoPaddingStyle = "square", qrStyle = "squares", eyeColor, id = "react-qrcode-logo",
    eyeRadius = [{ // top/left eye
        outer: 'square',
        inner: 'square',
    },
    { // top/right eye
        outer: 'square',
        inner: 'square',
    },
    { // bottom/left eye
        outer: 'square',
        inner: 'square',
    },
    ],
    frameColor = "#FFFFFF", frameStyle = "none", frameText = "", frameTextColor = "#000000",
    frameTextFontSize = "12", frameTextFontFamily = "",
    index,
    scaleFactor = 1
}) {
    console.log(eyeRadius)
    // Evaluate the variable to get the component to render
    let QrCodeFrameComponent;

    switch (frameStyle) {
        case 'SimpleBottom':
            QrCodeFrameComponent = SimpleBottom;
            break;
        case 'SimpleTop':
            QrCodeFrameComponent = SimpleTop;
            break;
        case 'D1Label':
            QrCodeFrameComponent = D1Label;
            break;
        case 'BalloonTop':
            QrCodeFrameComponent = BalloonTop;
            break;
        case 'BalloonBottom':
            QrCodeFrameComponent = BalloonBottom;
            break;
        case 'RibbonTop':
            QrCodeFrameComponent = RibbonTop;
            break;
        case 'RibbonBottom':
            QrCodeFrameComponent = RibbonBottom;
            break;
        case 'Top':
            QrCodeFrameComponent = Top;
            break;
        case 'Bottom':
            QrCodeFrameComponent = Bottom;
            break;
        case 'Border10LabelFlat':
            QrCodeFrameComponent = Border10LabelFlat;
            break;
        case 'Hexagon10Label':
            QrCodeFrameComponent = Hexagon10Label;
            break;
        case 'Cinema':
            QrCodeFrameComponent = Cinema;
            break;
        case 'Phone':
            QrCodeFrameComponent = Phone;
            break;
        case 'RoundBorder':
            QrCodeFrameComponent = RoundBorder;
            break;
        case 'CircleLabel':
            QrCodeFrameComponent = CircleLabel;
            break;
        case 'CircleThinFlat':
            QrCodeFrameComponent = CircleThinFlat;
            break;
        case 'CornersLabelFlat':
            QrCodeFrameComponent = CornersLabelFlat;
            break;
        case 'Corners':
            QrCodeFrameComponent = Corners;
            break;
        case 'DonitDisturb':
            QrCodeFrameComponent = DonitDisturb;
            break;
        case 'LockScreen':
            QrCodeFrameComponent = LockScreen;
            break;
        case 'LockscreenCircle':
            QrCodeFrameComponent = LockscreenCircle;
            break;
        case 'LockscreenRounded':
            QrCodeFrameComponent = LockscreenRounded;
            break;
        case 'RoundedBorder10LabelFlat':
            QrCodeFrameComponent = RoundedBorder10LabelFlat;
            break;
        case 'RoundedCorners':
            QrCodeFrameComponent = RoundedCorners;
            break;
        case 'RoundedCornersFlat':
            QrCodeFrameComponent = RoundedCornersFlat;
            break;
        // Add more cases as needed for other component types
        default:
            QrCodeFrameComponent = null;
    }

    let style = getQrcodeFrameSelectedSxProperties(frameStyle, size + quietZone, scaleFactor);

    return (
        <>
            {QrCodeFrameComponent &&
                <div id={index || index === 0 ? `qr-template-${index}` : undefined} style={{ position: 'relative', ...style.frame }}>
                    <QrCodeFrameComponent {...getQrcodeFrameSelectedProperties(frameColor)} {...style} fill={frameColor} />

                    <div
                        style={{
                            position: 'absolute',
                            bottom: '50px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            ...style.qr
                        }}
                    >
                        <QRCode
                            id={id}
                            value={value}
                            ecLevel={ecLevel}
                            enableCORS={enableCORS}
                            size={size * scaleFactor}
                            quietZone={quietZone * scaleFactor}
                            bgColor={bgColor}
                            fgColor={fgColor}
                            logoImage={logoImage}
                            logoWidth={logoWidth * scaleFactor}
                            logoHeight={logoHeight * scaleFactor}
                            logoOpacity={logoOpacity * scaleFactor}
                            logoOnLoad={logoOnLoad}
                            removeQrCodeBehindLogo={removeQrCodeBehindLogo}
                            logoPadding={logoPadding * scaleFactor}
                            logoPaddingStyle={logoPaddingStyle}
                            qrStyle={qrStyle}
                            eyeRadius={[
                                { // top/left eye
                                    outer: getEyeRadius(eyeRadius[0].outer, scaleFactor),
                                    inner: getEyeRadius(eyeRadius[0].inner, scaleFactor),
                                },
                                { // top/right eye
                                    outer: getEyeRadius(eyeRadius[1].outer, scaleFactor),
                                    inner: getEyeRadius(eyeRadius[1].inner, scaleFactor),
                                },
                                { // bottom/left
                                    outer: getEyeRadius(eyeRadius[2].outer, scaleFactor),
                                    inner: getEyeRadius(eyeRadius[2].inner, scaleFactor),
                                }
                            ]}
                            eyeColor={eyeColor}
                        />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            // bottom: '10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            padding: 5 * scaleFactor + 'px',
                            color: frameTextColor,
                            fontWeight: "bold",
                            fontSize: `${frameTextFontSize * scaleFactor}px`,
                            ...style.text
                        }}
                    >
                        {frameText}
                    </div>
                </div>
            }

            {!QrCodeFrameComponent &&
                <div id={`qr-template-${index}`} style={{ position: 'relative', ...style.frame }}>
                    <div id={index || index === 0 ? `qr-template-${index}` : undefined} >
                        <QRCode
                            id={id}
                            value={value}
                            ecLevel={ecLevel}
                            enableCORS={enableCORS}
                            size={size * scaleFactor}
                            quietZone={quietZone * scaleFactor}
                            bgColor={bgColor}
                            fgColor={fgColor}
                            logoImage={logoImage}
                            logoWidth={logoWidth * scaleFactor}
                            logoHeight={logoHeight * scaleFactor}
                            logoOpacity={logoOpacity * scaleFactor}
                            logoOnLoad={logoOnLoad}
                            removeQrCodeBehindLogo={removeQrCodeBehindLogo}
                            logoPadding={logoPadding * scaleFactor}
                            logoPaddingStyle={logoPaddingStyle}
                            qrStyle={qrStyle}
                            eyeRadius={[
                                { // top/left eye
                                    outer: getEyeRadius(eyeRadius[0].outer, scaleFactor),
                                    inner: getEyeRadius(eyeRadius[0].inner, scaleFactor),
                                },
                                { // top/right eye
                                    outer: getEyeRadius(eyeRadius[1].outer, scaleFactor),
                                    inner: getEyeRadius(eyeRadius[1].inner, scaleFactor),
                                },
                                { // bottom/left
                                    outer: getEyeRadius(eyeRadius[2].outer, scaleFactor),
                                    inner: getEyeRadius(eyeRadius[2].inner, scaleFactor),
                                }
                            ]}
                            eyeColor={eyeColor}
                        />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            // bottom: '10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            padding: 5 * scaleFactor + 'px',
                            color: frameTextColor,
                            fontWeight: "bold",
                            fontSize: `${frameTextFontSize * scaleFactor}px`,
                            ...style.text
                        }}
                    >
                        {frameText}
                    </div>
                </div>
            }

        </>

    );
}
export default CustomQRCode;
