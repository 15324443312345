import { Card, CardActionArea, CardContent, createTheme, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * The CustomCard, that display a custom {@link Card}
 * @property {string} label the instance name
 * @property {*} icon the icon represented the instance
 * @property {int} totalNumber the total number of the instances presented
 * @property {string} link the onclick card navigation link
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCard({ label, icon, totalNumber = 0, link = "", hide = false }) {

    const navigate = useNavigate();

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiCard: {
                    styleOverrides: {
                        root: {
                            borderRadius: "10px",
                            background: "white!important"
                        }
                    }
                },
                MuiSvgIcon: {
                    styleOverrides: {
                        root: {
                            fontSize: "60px",
                            color: "#205d7b",
                        }
                    }
                },
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            color: "#205d7b"
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Card onClick={() => navigate(link)}>
                <CardActionArea>
                    <CardContent>
                        <SvgIcon component={icon} />
                        <Typography gutterBottom variant="h5" component="div">
                            {label}: {totalNumber}
                        </Typography>
                        {hide &&
                            <Typography gutterBottom variant="h5" component="div">
                                {label}: {totalNumber}
                            </Typography>
                        }
                    </CardContent>
                </CardActionArea>
            </Card>
        </ThemeProvider>
    );
}

export default CustomCard;