import { faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShoppingCart } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import {
    Badge,
    Checkbox,
    CircularProgress,
    Divider,
    Fab,
    Grid,
    IconButton,
    useMediaQuery
} from '@mui/material';
import orderApi from 'api/order';
import { faCreditCard, faLeaf } from 'assets/fontAwesome/FontAwesomeIcons';
import { ArrowBackIcon, InfoIcon, PercentIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomPDAViewOrderGrid from 'components/Card/order/CustomPDAViewOrderGrid';
import CustomSinglePastOrderCardItem from 'components/Card/order/CustomSinglePastOrderCardItem';
import CustomSinglePastOrderCardItemPay from 'components/Card/order/CustomSinglePastOrderCardItemPay';
import CustomTabs from 'components/Tab/CustomTabs';
import CustomOrderPaymentActionsHistoryTimeline from 'components/Timeline/CustomOrderPaymentActionsHistoryTimeline';
import CustomTypography from 'components/Typography/CustomTypography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentMethodButtonIconPDA, paymentMethodButtonPDA } from 'themes/checkout/checkoutTheme';
import { orderBackBtnStyle, orderDiscountTextStyle, orderDiscountTextsStyle, orderDiscountValueTextStyle, orderHeaderTextStyle, orderItemFontAwesomeIconStyle, orderItemMuiIconStyle, orderItemTaxPriceTextStyle, orderItemTaxTextStyle, orderItemTotalPriceTextStyle, pastOrderDateTextStyle, requestFabButtonStyle, requestPdaButtonStyle } from 'themes/defaultThemes';
import { hasRoleDirector } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { dateTimeFormatter } from 'utils/functions';
import { calculateChipQuantity, calculateTotalAmountFromSelectedItems, createItemsRequirePayment, generateTabs, prepareItemsForPaymentActionHistory, prepareSelectedItemsForPaymentActionHistory, previewSelectedItemsToPay, priceTextWithCurrency } from 'utils/functions/order';

function UnpaidOrderDetailsCard({ order, currency, handleReset, setAlertBox, getPDADisplay, userAuth }) {
    const { t } = useTranslation();
    const [selectedItemsToPay, setSelectedItemsToPay] = useState([]);
    const [payAll, setPayAll] = useState(false);
    const [couponId, setCouponId] = useState('');
    const [value, setValue] = useState("1");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const isMobile = useMediaQuery('(max-width:468px)');

    const itemsRequirePayment = createItemsRequirePayment(order);

    const handleCheckboxAllChange = () => {
        if (payAll) {
            setSelectedItemsToPay([]);
            formValChangeWithParentElementWithNameAndValue("orderPaymentAction.items", [], data, setData);
        }
        setPayAll(!payAll);
    };

    function onPayUpdate(updatedItems) {
        let newSelectedItems = prepareSelectedItemsForPaymentActionHistory(selectedItemsToPay, itemsRequirePayment, updatedItems);
        setSelectedItemsToPay(newSelectedItems);
        formValChangeWithParentElementWithNameAndValue("orderPaymentAction.items", newSelectedItems, data, setData);
    }

    const [showCheckout, setShowCheckout] = useState(false);
    const [showOrderInfo, setShowOrderInfo] = useState(false);

    function moveToCheckout() {
        setShowCheckout(true);
    }
    function backFromCheckout() {
        setShowCheckout(false);
    }

    /**
    * @type {object}
    * 
    * @property {object} userAuth the authenticated user infromation
    * @property {object} alertBox holds snackbar infromation and style
    * @property {array} orders the orders list
    * @property {object} outlet tha outlet basic details (id and name, isActive)
    * @property {object} organization tha organization basic details (id and name)
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
    */
    const [data, setData] = useState({
        userAuth: null,
        orderPaymentAction: {
            paymentMethod: "cash",
            status: "PAYMENT",
            items: [],
            couponID: null,
            treat: false,
        }
    });

    function pay() {
        if (((!hasRoleDirector(userAuth?.roles) && !data.treat) || (hasRoleDirector(userAuth?.roles) && !data.treat)) && !showCheckout) {
            moveToCheckout();
        } else {
            setLoadingBtn(true);
            // Handle the payment submission logic here
            let paymentActionsHistory = {
                ...data.orderPaymentAction,
                items: payAll ? prepareItemsForPaymentActionHistory(itemsRequirePayment) : selectedItemsToPay
            }
            // console.log(`Selected item for payment: ${JSON.stringify(paymentActionsHistory)}`);
            orderApi.payAction(order.id, paymentActionsHistory).then((r) => {
                if (r.data.code === "SUCCESS") {
                    setAlertBox({
                        isOpen: true,
                        message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                    handleReset();
                    getPDADisplay();
                }
                setLoadingBtn(false);
            }).catch((e) => {
                // console.log(e);
                setLoadingBtn(false);
            });
        }
    }

    function payAndClose() {
        if (((!hasRoleDirector(userAuth?.roles) && !data.treat) || (hasRoleDirector(userAuth?.roles) && !data.treat)) && !showCheckout) {
            moveToCheckout();
        } else {
            setLoadingBtn(true);
            // Handle the payment submission logic here
            let paymentActionsHistory = {
                ...data.orderPaymentAction,
                items: payAll ? prepareItemsForPaymentActionHistory(itemsRequirePayment) : selectedItemsToPay
            }
            // console.log(`Selected item for payment: ${JSON.stringify(paymentActionsHistory)}`);
            orderApi.payAction(order.id, paymentActionsHistory).then((r) => {
                if (r.data.code === "SUCCESS") {
                    setAlertBox({
                        isOpen: true,
                        message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                    handleReset();
                    getPDADisplay();
                    setLoadingBtn(false);
                }
            }).catch((e) => {
                // console.log(e);
                setLoadingBtn(false);
            });
        }
    }


    // ========================= Payment Method 
    function updatePaymmentMethodCallback(newValue) {
        formValChangeWithParentElementWithNameAndValue("orderPaymentAction.paymentMethod", newValue, data, setData);
    };

    // ================================ RENDER
    return (
        <>
            {showOrderInfo &&
                <Grid container>
                    <CustomPDAViewOrderGrid
                        orderID={order.id}
                        setAlertBox={setAlertBox}
                        handleReset={() => { setShowOrderInfo(false); setShowCheckout(false) }}
                    />
                </Grid>
            }

            {!showOrderInfo && !showCheckout &&
                <>
                    <Grid item xs={3} md={3}>
                        <CustomIconButton
                            icon={<ArrowBackIcon />}
                            sx={orderBackBtnStyle()}
                            onClick={() => handleReset()}
                            label={t('pda.backToUnpaidOrders')}
                        />
                    </Grid>

                    <Grid item xs={7} sm={7}></Grid>
                    <Grid item xs={1} sm={1}>
                        <InfoIcon sx={{ fontSize: "15px", paddingTop: "0" }} onClick={() => setShowOrderInfo(true)} />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <CustomTypography variant="body2" text={`${dateTimeFormatter(order.dateCreated, "DD/MM/yyyy - HH:mm")} - ID: ${order.id}`} sx={pastOrderDateTextStyle()} />
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
                        <TabContext value={value}>
                            <CustomTabs
                                initValue={value}
                                instances={generateTabs(itemsRequirePayment, order, t)}
                                setValue={setValue}
                            />

                            <TabPanel value={"0"} sx={{ paddingTop: "0px" }}>
                                {/* <Grid item xs={12} md={12}>
                                        <InputLabel sx={orderCartTitleTextStyle()}>{t('customer.view.tabs.orders.order.cart')}</InputLabel>
                                    </Grid> */}

                                <Grid container>
                                    {order?.cart?.map((item) =>
                                        <CustomSinglePastOrderCardItem cartItem={item} currency={currency} />
                                    )}
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"1"} sx={{ paddingTop: "0px" }}>
                                <Grid container>
                                    {/* <Grid item xs={12} md={12}>
                                        <InputLabel sx={orderCartTitleTextStyle()}>{t('pda.remainToPay')}</InputLabel>
                                    </Grid> */}

                                    {/* Checkbox for paying all unpaid items */}
                                    <Grid item xs={12} md={12} sx={{ marginTop: '20px' }}>
                                        <Checkbox
                                            checked={payAll}
                                            onChange={handleCheckboxAllChange}
                                        />
                                        {t('pda.payAll')}
                                    </Grid>

                                    {itemsRequirePayment?.map((cartItem, index) =>
                                        <CustomSinglePastOrderCardItemPay
                                            cartItem={cartItem}
                                            currency={currency}
                                            onPay={onPayUpdate}
                                            payAll={payAll}
                                            selectedItemsToPay={selectedItemsToPay}
                                            itemsRequirePayment={itemsRequirePayment}
                                            index={index}
                                        />
                                    )}
                                </Grid>

                            </TabPanel>

                            <TabPanel value={"2"} sx={{ paddingTop: "0px" }}>
                                <CustomOrderPaymentActionsHistoryTimeline
                                    currency={currency}
                                    timelineDetails={order?.paymentActionsHistory}
                                    items={order?.cart}
                                />
                            </TabPanel>
                        </TabContext>
                    </Grid>

                    {order?.pvcRecyclingTax > 0 &&
                        <Grid item xs={12} md={12}>
                            <Grid container>
                                <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                    <FontAwesomeIcon key={"icon-env-tax"} icon={faLeaf} style={orderItemFontAwesomeIconStyle()} />
                                </Grid>

                                <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                    <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.pvcRecyclingTax')} sx={orderItemTaxTextStyle()} />
                                </Grid>

                                <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                    <CustomTypography variant="body2" text={priceTextWithCurrency(order?.outlet?.currency, order?.pvcRecyclingTax)} sx={orderItemTaxPriceTextStyle()} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {(order?.discount?.loyalty || order?.discount?.coupon) &&
                        <Grid item xs={12} md={12}>
                            <Grid container>
                                <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                    <PercentIcon sx={orderItemMuiIconStyle} />
                                </Grid>

                                <Grid item xs={10} md={10}>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.discount')} sx={orderDiscountTextStyle()} />
                                        </Grid>

                                        {order?.discount?.loyalty?.discountPercentage > 0 &&
                                            <Grid item xs={12} md={12}>
                                                <Grid container>
                                                    <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                                        <CustomTypography variant="body2" text={`${t('customer.view.tabs.orders.order.loyalty')} (${order?.discount?.loyalty?.name}):`} sx={orderDiscountTextsStyle()} />
                                                    </Grid>

                                                    <Grid item xs={2} md={2}>
                                                        <CustomTypography variant="body2" text={`${order?.discount?.loyalty?.discountPercentage} %`} sx={orderDiscountValueTextStyle()} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }

                                        {order?.discount?.coupon?.value > 0 &&
                                            <Grid item xs={12} md={12}>
                                                <Grid container>
                                                    <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                                        <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.coupon')} sx={orderDiscountTextsStyle()} />
                                                    </Grid>
                                                    <Grid item xs={2} md={2}>
                                                        <CustomTypography variant="body2" text={priceTextWithCurrency(order?.outlet?.currency, order?.discount?.coupon?.value)} sx={orderItemTotalPriceTextStyle()} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {calculateChipQuantity(itemsRequirePayment, selectedItemsToPay, payAll) > 0 &&
                        <>
                            <Divider light flexItem sx={{ borderRightWidth: 5, width: "80%", marginLeft: "11%", marginTop: "20px" }} />

                            <Fab
                                aria-label="add"
                                variant="extended"
                                sx={requestFabButtonStyle(false, "absolute")}
                                onClick={moveToCheckout}
                            >
                                <Badge
                                    badgeContent={calculateChipQuantity(itemsRequirePayment, selectedItemsToPay, payAll)}
                                    color="primary"
                                    style={{ color: 'blue', marginRight: "30px" }}
                                >
                                    <ShoppingCart sx={{ color: "white" }} onClick={() => setShowCheckout(true)} />
                                </Badge>
                                {payAll ? t('pda.payAll') : t('pda.paySelectedItems')}
                            </Fab>

                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="span" text={`${t('order.totalAmount')}:  `} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="span" text={`${calculateTotalAmountFromSelectedItems(payAll ? itemsRequirePayment : selectedItemsToPay, payAll)} ${currency}`} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }

                    {hasRoleDirector(userAuth?.roles) &&
                        <Grid item xs={12} md={12} sx={{ marginTop: '20px', marginBottom: "50px" }}>
                            <Checkbox
                                checked={data.orderPaymentAction?.treat}
                                onChange={(event) => formValChangeWithParentElementWithNameAndValue("orderPaymentAction.treat", event.target.checked, data, setData)}
                            />
                            {t('pda.payment.treat')}
                        </Grid>
                    }
                </>
            }

            {(!showOrderInfo && showCheckout) &&
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                            <CustomIconButton
                                icon={<ArrowBackIcon />}
                                sx={orderBackBtnStyle()}
                                onClick={() => backFromCheckout()}
                                label={t('pda.back')}
                            />
                        </Grid>

                        <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

                        {payAll &&
                            <Grid item xs={12}>
                                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                    {itemsRequirePayment?.map((cartItem) =>
                                        <CustomSinglePastOrderCardItem
                                            cartItem={cartItem}
                                            currency={currency}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        }

                        {!payAll &&
                            <Grid item xs={12}>
                                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                    {previewSelectedItemsToPay(selectedItemsToPay, itemsRequirePayment)?.map((cartItem) =>
                                        cartItem.quantity > 0 &&
                                        <CustomSinglePastOrderCardItem
                                            cartItem={cartItem}
                                            currency={currency}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        }

                        <Grid item xs={12} sx={{ mt: "20px" }}>
                            <Grid container>
                                <Grid item xs={"auto"}>
                                    <CustomTypography variant="span" text={`${t('order.totalAmount')}:  `} sx={orderHeaderTextStyle()} />
                                </Grid>
                                <Grid item xs={"auto"}>
                                    <CustomTypography variant="span" text={`${calculateTotalAmountFromSelectedItems(payAll ? itemsRequirePayment : selectedItemsToPay, payAll)} ${currency}`} sx={orderHeaderTextStyle()} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container sx={{ paddingLeft: "20px", paddingTop: "20px" }} gap={1}>
                                <Grid item xs={12} md={12} sx={{ alignSelf: "center" }}>
                                    <Grid container sx={{ textAlign: "left" }}>
                                        <Grid item xs={12} md={12}>
                                            <CustomTypography variant="body2" text={t('pda.payment.method')} sx={orderHeaderTextStyle()} />
                                        </Grid>
                                        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                            <Grid container gap={1}>
                                                <Grid item xs={5.3}>
                                                    <CustomIconButton
                                                        keyValue={`btn-search-cash`}
                                                        onClick={() => updatePaymmentMethodCallback("cash")}
                                                        icon={<FontAwesomeIcon key={"icon-cash-search"} icon={faMoneyBill1Wave} style={paymentMethodButtonIconPDA()} />}
                                                        iconSx={paymentMethodButtonIconPDA()}
                                                        label={t('checkout.payment.cash')}
                                                        sx={paymentMethodButtonPDA()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={data.orderPaymentAction.paymentMethod === "cash"}
                                                    />
                                                </Grid>

                                                <Grid item xs={5.3}>
                                                    <CustomIconButton
                                                        keyValue={`btn-search-card`}
                                                        onClick={() => updatePaymmentMethodCallback("card")}
                                                        icon={<FontAwesomeIcon key={"icon-card-search"} icon={faCreditCard} style={paymentMethodButtonIconPDA()} />}
                                                        iconSx={paymentMethodButtonIconPDA()}
                                                        label={t('checkout.payment.card')}
                                                        sx={paymentMethodButtonPDA()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={data.orderPaymentAction.paymentMethod === "card"}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={5.5} sx={{ mt: "40px" }}>
                                    <IconButton
                                        aria-label="add"
                                        variant="extended"
                                        sx={requestPdaButtonStyle(loadingBtn)}
                                        onClick={pay}
                                        disabled={loadingBtn}
                                    >
                                        {loadingBtn && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "47a6d2",
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                        <Badge
                                            badgeContent={calculateChipQuantity(itemsRequirePayment, selectedItemsToPay, payAll)}
                                            color="primary"
                                            style={{ color: 'blue', marginRight: "30px" }}
                                        >
                                            <ShoppingCart sx={{ color: "white" }} onClick={() => setShowCheckout(true)} />
                                        </Badge>
                                        {t('pda.pay')}
                                    </IconButton>
                                </Grid>
                                <Grid item xs={5.5} sx={{ mt: "40px" }}>
                                    <IconButton
                                        aria-label="add"
                                        variant="extended"
                                        sx={requestPdaButtonStyle(loadingBtn)}
                                        onClick={payAndClose}
                                        disabled={loadingBtn}
                                    >
                                        {loadingBtn && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "47a6d2",
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                        <Badge
                                            badgeContent={calculateChipQuantity(itemsRequirePayment, selectedItemsToPay, payAll)}
                                            color="primary"
                                            style={{ color: 'blue', marginRight: "30px" }}
                                        >
                                            <ShoppingCart sx={{ color: "white" }} onClick={() => setShowCheckout(true)} />
                                        </Badge>
                                        {t('pda.payAndClose')}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default UnpaidOrderDetailsCard;