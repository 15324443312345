import { Card, Checkbox, FormControlLabel, Grid, InputLabel, SvgIcon, Switch } from '@mui/material';
import en from "assets/images/flags/en.png";
import { VisibilityIcon, VisibilityOffIcon } from 'assets/mui/MuiIcons';
import CustomTranslationsDialog from 'components/Dialogs/CustomTranslationsDialog';
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTextFieldWithIcons from 'components/Text/CustomTextFieldWithIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDefaultWifiConfigurationObject } from 'constants/defaultObjects';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { textFieldError } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';

/**
 * The Custom Add Wifi instance, that display customized {@link Card} with an 
 * add button for triggering the add new wifi details instance.
 * 
 * @property {function} onClick Callback that triggered when card button is clicked
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomWifiConfigCard({ wifiDetails = getDefaultWifiConfigurationObject(), setWifiDetails }) {
    const { t } = useTranslation();
    const [shownPasswordIcon, setShowPasswordIcon] = useState(VisibilityOffIcon);
    const [passwordShown, setPasswordShown] = useState(false);
    console.log(wifiDetails)
    /**
     * Password toggle handler
     */
    function togglePassword() {
        // When the handler is invoked
        // inverse the boolean state of passwordShown and icon to be shown
        setShowPasswordIcon(!passwordShown ? VisibilityIcon : VisibilityOffIcon);
        setPasswordShown(!passwordShown);
    }

    const [isError, setIsError] = useState({
        name: ""
    });

    // ============= TRANSLATIONS
    // popup dialog 
    const [dialog, setDialog] = useState({
        isOpen: false,
        message: t("label.configureTranslations"),
        id: "",
        field: "",
        translations: {}
    });

    /**
     * Function to trigger the remove of the item.
     * @property {*} event
     * @property {string} field The field of the selected item field.
     */
    function configureTranslations(event, field) {
        handleDialogState(true, field);
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleDialogState(isOpen, field = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            field: field,
            translations: wifiDetails.locationName
        });
    }

    /**
     * This function will trigger the item field translations configuration
     */
    function configureItemTranslations(translations, field) {
        formValChangeWithParentElementWithNameAndValue(`locationName`, translations, wifiDetails, setWifiDetails)
        setDialog({
            ...dialog,
            isOpen: false
        });
    }

    // ===============================================================================RENDER
    return (
        <Card
            key="card-new-wifi"
            variant="outlined"
            sx={{ border: "none" }}
        >
            <Grid container gap={2}>
                <Grid item xs={12} md={12}>
                    <InputLabel required={false} htmlFor="locationName">{t('outlet.settings.wifi.locationName')}</InputLabel>
                    <CustomFlagTextField
                        country="en"
                        flag={en}
                        placeholder=""
                        name="locationName.en"
                        id="locationName"
                        value={wifiDetails.locationName.en}
                        onTranslationSelect={configureTranslations}
                        field="locationName"
                        onChange={(event) => {
                            let value = event.target.value;
                            if (value === "")
                                setIsError({ ...isError, name: t('error.valueRequired') });
                            formValChangeWithParentElementWithNameAndValue("locationName.en", value, wifiDetails, setWifiDetails)
                        }}
                        helperText={isError.name}
                        error={isError.name.length > 0}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel required={true} htmlFor="ssid">{t('outlet.settings.wifi.ssid')}</InputLabel>
                    <CustomTextField
                        type="text"
                        name="ssid"
                        id="ssid"
                        value={wifiDetails.ssid}
                        onChange={(event) => formValChangeWithParentElementWithNameAndValue("ssid", event.target.value, wifiDetails, setWifiDetails)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel required={false} htmlFor="password">{t('label.password')}</InputLabel>
                    <CustomTextFieldWithIcons
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        id="password"
                        value={wifiDetails.password}
                        autoComplete="password"
                        onChange={(event) => formValChangeWithParentElementWithNameAndValue("password", event.target.value, wifiDetails, setWifiDetails)}
                        endIcon={<SvgIcon component={shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                        sx={textFieldError()}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel required={false} htmlFor="encryption">{t('outlet.settings.wifi.encryption.label')}</InputLabel>

                    <Grid container>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={wifiDetails.encryption === "nopass"}
                                        onChange={() => formValChangeWithParentElementWithNameAndValue("encryption", "nopass", wifiDetails, setWifiDetails)}
                                    />}
                                label={
                                    <CustomTypography
                                        variant="span"
                                        text={t('outlet.settings.wifi.encryption.nopass')}
                                    />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={wifiDetails.encryption === "WPA"}
                                        onChange={() => formValChangeWithParentElementWithNameAndValue("encryption", "WPA", wifiDetails, setWifiDetails)}
                                    />}
                                label={
                                    <CustomTypography
                                        variant="span"
                                        text={t('outlet.settings.wifi.encryption.WPA')}
                                    />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={wifiDetails.encryption === "WEP"}
                                        onChange={() => formValChangeWithParentElementWithNameAndValue("encryption", "WEP", wifiDetails, setWifiDetails)}
                                    />}
                                label={
                                    <CustomTypography
                                        variant="span"
                                        text={t('outlet.settings.wifi.encryption.WEP')}
                                    />}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel control={
                        <Switch
                            checked={wifiDetails.enableQRcode}
                            onChange={() => formValChangeWithParentElementWithNameAndValue("enableQRcode", !wifiDetails.enableQRcode, wifiDetails, setWifiDetails)} />}
                        sx={{ marginLeft: "20px" }}
                        label={t('outlet.settings.wifi.enableQRcode')}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel control={
                        <Switch
                            checked={wifiDetails.enabled}
                            onChange={() => formValChangeWithParentElementWithNameAndValue("enabled", !wifiDetails.enabled, wifiDetails, setWifiDetails)} />}
                        sx={{ marginLeft: "20px" }}
                        label={t('outlet.settings.wifi.enabled')}
                    />
                </Grid>
            </Grid>

            {dialog.isOpen &&
                <CustomTranslationsDialog
                    isOpen={dialog.isOpen}
                    id="translations"
                    title={t('actions.translationModal.title')}
                    action={configureItemTranslations}
                    handleOpen={handleDialogState}
                    translations={wifiDetails[dialog.field]}
                    field={dialog.field} />
            }

        </Card>
    );
}

export default CustomWifiConfigCard;