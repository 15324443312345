import GTranslateIcon from '@mui/icons-material/GTranslate';
import Textarea from '@mui/joy/Textarea';
import { FormHelperText } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomIconButton from 'components/Button/CustomIconButton';

/**
 * The Custom Flag Text Field, that display customized {@link Textarea}.
 * @property {string} id the text field id
 * @property {string} name the text field name
 * @property {string} type the text field type
 * @property {string} className the text field class
 * @property {string} defaultValue the text field default value
 * @property {string} placeholder the text field placeholder
 * @property {string} helperText the text field helper text
 * @property {string} country the text field language code
 * @property {string} flag the text field language flag
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomFlagTextArea({ id = "", name = "", error, className, defaultValue, placeholder, helperText = "", country = "en", flag, onTranslationSelect, onChange, field, width = "100%" }) {

    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: "10px" }}>
            <Textarea
                id={id}
                minRows={2}
                name={name}
                className={className}
                error={error}
                defaultValue={defaultValue ? defaultValue : ""}
                placeholder={placeholder ? placeholder : placeholder === "" ? "" : t(`languages.${country}`)}
                startDecorator={
                    <Box sx={{ display: 'initial', gap: 0.5, position: "absolute", left: 10, paddingTop: "10px" }}>
                        <img src={flag} width="30" alt={country} style={{ placeSelf: "center", marginRight: "10px" }} />
                    </Box>
                }
                endDecorator={
                    onTranslationSelect !== undefined ?
                        <CustomIconButton onClick={((event) => onTranslationSelect(event, field))} icon={<GTranslateIcon style={{ marginRight: "0px" }} />} className="icon-wrapper tr link" iconSx={{ marginRight: "0px!important" }} sx={{ backgroundColor: "white", borderRadius: "50px", padding: "8px 0px", minWidth: "40px", marginRight: "10px", marginTop: "-30px", position: "absolute", right: 0 }}></CustomIconButton>
                        : <></>
                }
                style={{
                    padding: "11px",
                    marginBottom: 0,
                    height: "auto",
                    paddingLeft: "50px",
                    paddingRight: "40px",
                    paddingTop: 0,
                    width: width
                }}
                onChange={onChange}
            />
            <FormHelperText>{helperText}</FormHelperText>
        </Box>
    );
}

export default CustomFlagTextArea;