import { Dialog, Grid } from "@mui/material";
import itemApi from "api/item";
import menuApi from "api/menu";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { mainTag } from "themes/defaultThemes";
import useDocumentTitle from "utils/useDocumentTitle";

import Header from "components/Menu/cms/header";
import Main from "components/Menu/cms/main";
import RightSider from "components/Menu/cms/rightSider";
import AddItemPopup from "./AddorUpdateDialog";

import "components/Menu/cms/scroll.css";
import { hasRoleAdminMenu } from 'utils/auth';

/**
 * The Menu-CMS, that allow the user to view the users list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function MenuCMSComponent() {
  const { t } = useTranslation();
  const { organizationID } = useParams();
  const { outletID } = useParams();
  const { menuID } = useParams();

  /**
   * @type {object}
   *
   * @property {object} userAuth the authenticated user infromation
   * @property {object} alertBox holds snackbar infromation and style
   * @property {object} menu the menu details
   * @property {array} items the different items
   * @property {object} outlet tha outlet basic details (id and name, isActive)
   * @property {object} organization tha organization basic details (id and name)
   */
  const [data, setData] = useState({
    userAuth: null,
    menu: null,
    showLabel: false,
    organization: {
      id: organizationID,
      name: "",
    },
    outlet: {
      id: outletID,
      name: "",
    },
    organizations: [],
    outlets: [],
    items: [],
  });

  //change document title
  useDocumentTitle(
    `Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t("org.pageTitle") : ""} ${outletID !== undefined ? " | " + t("outlet.pageTitle") : ""
    } | ${t("menus.title")} | ${t("menuCategories.title")}`
  );

  // if true the menu item is clicked and a redirect to page should perform
  const [isLoaded, setIsLoaded] = useState(false);

  const [showLeftSider, setShowLeftSider] = useState(true);
  const [showRightSider, setShowRightSider] = useState(true);
  const [showItemsImages, setShowItemsImages] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const leftSiderWidth = 256;
  const rightSiderWidth = 256;
  const cardWidth = 220;
  const mainHeight = showFullScreen
    ? "calc(100vh - 74px)"
    : "calc(100vh - 164px)";

  const [Items, setItems] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [ParentCategories, setParentCategories] = useState([]);
  const [showParent, setShowParent] = useState(false);
  const [addCategoryDialog, setCategoryDialog] = useState(false);

  const [loading, setLoading] = useState({
    active: false,
    action: "",
  });

  // snackbar details
  const [alertBox, setAlertBox] = useState({
    isOpen: false,
    message: "",
    backgroundColor: "#a71313",
  });

  /**
   * The rest endpoint to get the menu details.
   */
  function getMenu() {
    menuApi
      .fetchOneCMS(organizationID, menuID)
      .then((r) => {
        setIsLoaded(true);
        setData({
          ...data,
          userAuth: r.data.returnobject.userAuth,
          organization: r.data.returnobject.organization,
          menu: r.data.returnobject.menu,
          items: r.data.returnobject.items,
        });

        const menus = r.data.returnobject.menu;

        let p = [];
        let c = [];
        menus.parentCategories.map((x) => {
          let activeUI = true;
          //this functionality is to just maintain eye icon after save.
          if (ParentCategories.length > 0) {
            let ParentItem = ParentCategories.find((pi) => pi.id === x.id);
            if (ParentItem) activeUI = ParentItem.activeUI;
          }
          x.itemIds = x.categories.map((cat) => {
            if (x.name.en === "default") {
              let activeUICat = true;
              if (Categories.length > 0) {
                let CategoryItem = Categories.find((ci) => ci.id === cat.id);
                if (CategoryItem) activeUICat = CategoryItem.activeUI;
              }
              c.push({
                ...cat,
                itemIds: cat.items.map((catItem) => catItem.id),
                activeUI: activeUICat,
              });
            }
            // if (!c.some((ele) => ele.id === cat.id)) {
            //   let activeUICat = true;

            //   if (Categories.length > 0) {
            //     let CategoryItem = Categories.find((ci) => ci.id === cat.id);
            //     if (CategoryItem) activeUICat = CategoryItem.activeUI;
            //   }
            //   c.push({
            //     ...cat,
            //     itemIds: cat.items.map((catItem) => catItem.id),
            //     activeUI: activeUICat,
            //   });
            // }
            return cat.id;
          });
          x.activeUI = activeUI;
          p.push(x);
        });
        setParentCategories(p);
        setCategories(c);
        setItems(r.data.returnobject.items);
      })
      .catch((e) => {
        // console.log(e);
        setAlertBox({
          isOpen: true,
          message: t("errorPages.somethingWentWrong"),
          backgroundColor: "#ef5350",
        });
      });
  }
  /**
   * The rest endpoint to get the item options.
   */
  function getItems() {
    itemApi
      .fetchAllCMS(organizationID)
      .then((r) => {
        setIsLoaded(true);
        setItems(r.data.returnobject);
      })
      .catch((e) => {
        // console.log(e);
        setAlertBox({
          isOpen: true,
          message: t("errorPages.somethingWentWrong"),
          backgroundColor: "#ef5350",
        });
      });
  }

  /**
   * Gets called when the user clicks on the save button, and triggers
   * the udate of the selected menu.
   */
  const fetchData = async () => {
    // await getItems();
    await getMenu();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const saveClickHandler = () => {
    setLoading({
      active: true,
      action: "save",
    });

    const parentCopy = JSON.parse(JSON.stringify(ParentCategories));

    // const usedIds = parentCopy
    //   .filter((x) => x.name.en === "default")
    //   .flatMap((pd) => pd.itemIds.map((parentSubItem) => parentSubItem.id));

    const mergedData = {
      ...data.menu,
      parentCategories: parentCopy.map((p) => {
        p.image = undefined;

        let categoriesCopy = [];
        if (p.name.en === "default") {
          // categoriesCopy = [
          //   ...p.itemIds.filter(
          //     (subCategory) => !usedIds.includes(subCategory)
          //   ),
          // ];
          categoriesCopy = Categories.map((x) => x.id);
        } else {
          categoriesCopy = [...p.itemIds];
        }
        p.categories = categoriesCopy.map((c) => {
          let categoryObj = Categories.find((ca) => ca.id == c);
          let categoryObjCopy = { ...categoryObj };

          categoryObjCopy.items = categoryObjCopy.itemIds?.map((i) => {
            let itemObj = Items.find((ci) => ci.id == i);
            let ItemObjCopy = { ...itemObj };
            return ItemObjCopy;
          });
          if (categoryObjCopy.new) {
            categoryObjCopy.id = undefined;
            categoryObjCopy.new = undefined;
          }
          categoryObjCopy.image = undefined;
          categoryObjCopy.itemIds = undefined;
          categoryObjCopy.activeUI = undefined;
          if (categoryObjCopy.duplicate) {
            categoryObjCopy.id = undefined;
            categoryObjCopy.duplicate = undefined;
          }
          return categoryObjCopy;
        });

        if (p.duplicate) {
          p.id = undefined;
          p.duplicate = undefined;
        }
        p.itemIds = undefined;
        p.activeUI = undefined;
        return p;
      }),
    };

    menuApi
      .updateCMS(mergedData)
      .then(async (r) => {
        if (r.data.message) {
          setAlertBox({
            isOpen: true,
            message:
              "message" in r.data
                ? r.data.message
                : t("errorPages.somethingWentWrong"),
            backgroundColor: r.data.code === "SUCCESS" ? "#177910" : "#a71313",
          });
        }
        setLoading({
          active: false,
          action: "",
        });
        await getMenu();
      })
      .catch((e) => {
        // console.log(e);
        setLoading({
          active: false,
          action: "",
        });
        setAlertBox({
          isOpen: true,
          message: "Fail to fetch Data",
          backgroundColor: "#ef5350",
        });
      });
  };
  /**
   * Function that handles the snackbar open or close state.
   * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
   */
  function handleSnackbarState(isOpen) {
    setAlertBox({
      ...alertBox,
      isOpen: isOpen,
    });
  }

  // ===============================================================================RENDER

  if (!isLoaded) {
    return <LoadingSkeleton lines={9} />;
  } else {
    const ItemsProps = showParent
      ? ParentCategories.map((c) => {
        console.log(c)
        return {
          ...c,
          itemIds: c.itemIds.map((itemId) =>
            Categories.find((x) => x.id === itemId)
          ),
        };
      })
      : Categories.map((c) => {
        console.log(c)
        return {
          ...c,
          itemIds: c.itemIds.map((itemId) =>
            Items.find((x) => x.id === itemId)
          ),
        };
      });

    const UI = (
      <Grid
        container
        direction='column'
        id='main'
        role='main'
        //id={"menu-cms"}
        sx={{
          ...mainTag(),
          padding: showFullScreen ? 0 : "20px 0px 50px 5px",
        }}
      >
        {alertBox.isOpen && (
          <CustomSnackbar
            isOpen={alertBox.isOpen}
            autoHideDuration={3000}
            message={alertBox.message}
            backgroundColor={alertBox.backgroundColor}
            handleSnackbarOpen={handleSnackbarState}
          />
        )}

        <AddItemPopup
          showParent={showParent}
          open={addCategoryDialog}
          setOpen={(state) => setCategoryDialog(state)}
          title={showParent ? "Parent Category" : "Category"}
          onSubmit={(data) => {
            setCategoryDialog(false);
            if (showParent) {
              setParentCategories((c) => [data, ...c]);
            } else {
              setCategories((c) => [
                {
                  ...data,
                  new: true,
                },
                ...c,
              ]);
              let defaultParent = ParentCategories.find(
                (x) => x.name?.en === "default"
              );

              if (defaultParent) {
                setParentCategories((c) =>
                  c.map((pc) => {
                    if (pc.id === defaultParent.id) {
                      pc.itemIds = [...pc.itemIds, data.id];
                    }
                    return pc;
                  })
                );
              }
            }
          }}
          organizationID={data.organization.id}
          Items={ItemsProps}
        />
        <Grid item style={{ width: "100%" }}>
          <Header
            loading={loading}
            showParent={showParent}
            setShowParent={setShowParent}
            onAddClick={() => setCategoryDialog(true)}
            saveClickHandler={() => saveClickHandler()}
            showLeftSider={showLeftSider}
            showRightSider={showRightSider}
            toggleLeftSideBar={() => setShowLeftSider((s) => !s)}
            toggleRightSideBar={() => setShowRightSider((s) => !s)}
            showItemsImages={showItemsImages}
            toggleItemImages={() => setShowItemsImages((s) => !s)}
            showFullScreen={showFullScreen}
            setShowFullScreen={setShowFullScreen}
            menuNames={
              data.menu !== null && data.menu.name !== null
                ? data.menu.name
                : null
            }
          />
        </Grid>
        <Grid
          item
          style={{
            width: "100%",
            height: mainHeight,
          }}
        >
          <Grid
            container
            wrap='nowrap'
            style={{ height: "100%", position: "relative" }}
          >
            {showLeftSider && (
              <Grid
                item
                style={{
                  display: "flex",
                  //width: leftSiderWidth,
                  //position: "absolute",
                }}
              >
                {/*    <LeftSider
              drawerWidth={leftSiderWidth}
              open={showLeftSider}
              Items={Items}
            />*/}
              </Grid>
            )}
            {/* for center*/}
            <Grid
              item
              sx={{
                display: "flex",
                flex: 1,
                overflowX: "hidden",
                height: "100%",
                overflowY: "hidden",
                maxHeight: mainHeight,
              }}
            >
              <Main
                showParent={showParent}
                cardWidth={cardWidth}
                leftSiderWidth={leftSiderWidth}
                showLeftSider={showLeftSider}
                setShowLeftSider={setShowLeftSider}
                leftItems={showParent ? Categories : Items}
                Items={ItemsProps}
                setItems={showParent ? setParentCategories : setCategories}
                setParentCategories={setParentCategories}
                ParentCategories={ParentCategories}
                organizationID={data.organization.id}
                showItemsImages={showItemsImages}
              />
            </Grid>
            {showRightSider && (
              <Grid
                item
                sx={{
                  display: "flex",
                  width: rightSiderWidth,
                  position: "relative",
                }}
              >
                <RightSider
                  cardWidth={cardWidth}
                  drawerWidth={rightSiderWidth}
                  open={showRightSider}
                  Items={showParent ? ParentCategories : Categories}
                  setItems={showParent ? setParentCategories : setCategories}
                  organizationID={data.organization.id}
                  showParent={showParent}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );

    const fullscreenDialog = (
      <Dialog
        open={showFullScreen}
        onClose={() => showFullScreen(false)}
        fullWidth
        fullScreen
        PaperProps={{
          sx: {
            overflowX: "hidden",
          },
        }}
      >
        {UI}
      </Dialog>
    );
    return (
      <>
        {" "}
        {fullscreenDialog} {UI}{" "}
      </>
    );
  }
}

export default MenuCMSComponent;
