import {
    Divider,
    Grid,
    ListItem
} from '@mui/material';
import { NotesIcon, PlaceIcon } from 'assets/mui/MuiIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { getThemeColor } from 'constants/theme';
import { useTranslation } from 'react-i18next';
import { customerDetailsGridStyle, customerNoteDateStyle, orderHeaderTextStyle, orderIconStyle, orderTextStyle, pastOrderCartTextStyle, pastOrderDateTextBoldStyle } from 'themes/defaultThemes';
import { calculateTimePassed, dateTimeFormatter, findCurrency } from 'utils/functions';
import { fieldFormatter } from 'utils/table';
import { getTheme } from 'utils/theme';

/**
 * The CustomCustomerNote, that display each customer note.
 * 
 * @param {object} order the past order details
 * @param {string} lang the lang code
 *
 * @returns {JSX.Element} A React component that renders a past order.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCustomerNote({ note }) {
    const { t } = useTranslation();


    return (
        <>
            <Grid item xs={12} md={12} sx={customerDetailsGridStyle}>
                <ListItem>
                    <Grid container sx={{ width: "90%", paddingLeftt: "10%" }}>
                        <Grid item xs={2} md={2} sx={{ paddingRight: "10px", alignSelf: "center" }}>
                            <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                <Grid item xs={12} md={12}>
                                    <NotesIcon sx={orderIconStyle} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={10} md={10} sx={{ paddingRight: "10px" }}>
                            <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={note?.employee?.name} sx={orderHeaderTextStyle()} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={note?.note} sx={orderTextStyle()} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={customerNoteDateStyle}>
                                    <CustomTypography variant="body2" text={calculateTimePassed(note?.dateCreated)} sx={orderTextStyle()} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ListItem>
            </Grid>
            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />
        </>
    );
}

export default CustomCustomerNote;