import { Avatar, Stack } from '@mui/material';
import React from 'react';

/**
 * The Custom Letter Avatar, that display a letter avatar on the sidebar.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomLetterAvatar({ name = "", onlyLetter = false }) {
    if (name?.length === 0) onlyLetter = false;
    return (
        <Stack direction="row" spacing={2}>
            <Avatar alt={name} sx={{ bgcolor: "#54b1d2" }}>{onlyLetter ? `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}` : name}</Avatar>
        </Stack>
    );
}

export default CustomLetterAvatar;