
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Card, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import React from 'react';
import { getThemeColor } from 'constants/theme';
import { cardItemButtonGroupStackButton } from 'themes/defaultThemes';
import { getTheme } from 'utils/theme';
import CustomIconButton from 'components/Button/CustomIconButton';
import { getCardProperties, getStackButtonsProperties, getStackProperties } from 'themes/qrcode/defaultTheme';
import CustomQRCode from 'components/QRCode/CustomQRCode';

/**
 * The CustomQrcodeTemplate, that display customized {@link Card}.
 * 
 * @property {string} name the Checkbox name value
 * @property {string} selected If `true`, the component is checked.
 * @property {string} label the Checkbox text value
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {function} onChange Callback that triggered when value is changed
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomQrcodeTemplate({
    qrcodeTemplate,
    handleDialogState,
    removeQRTemplate,
    logoImgSrc,
    index
}) {
    return (
        <Grid>
            <Stack direction="row" sx={getStackButtonsProperties()} key={`stack-${qrcodeTemplate.id}`}>
                <CustomIconButton
                    keyValue={`btn-edit-${qrcodeTemplate.id}`}
                    onClick={() => handleDialogState(true, qrcodeTemplate.id, false, index)}
                    icon={<EditIcon style={{ marginRight: "0px" }} />}
                    iconSx={{ marginRight: "0px!important" }}
                    sx={{
                        ...cardItemButtonGroupStackButton(),
                        backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColor"]
                    }}
                >
                    {t('actions.edit')}
                </CustomIconButton>

                <CustomIconButton
                    keyValue={`btn-delete-${qrcodeTemplate.id}`}
                    onClick={(event) => removeQRTemplate(qrcodeTemplate.id, index)}
                    icon={<DeleteIcon style={{ marginRight: "0px" }} />}
                    iconSx={{ marginRight: "0px!important" }}
                    sx={{
                        ...cardItemButtonGroupStackButton(),
                        backgroundColor: "red"
                    }}
                >
                    {t('actions.delete')}
                </CustomIconButton>
            </Stack>
            <Stack direction="row" sx={{ ...getCardProperties(), ...getStackProperties() }} key={`stack-card-${qrcodeTemplate.id}`}>
                <CustomQRCode {...qrcodeTemplate} logoImage={logoImgSrc}/>
            </Stack>
        </Grid>
    );
}

export default CustomQrcodeTemplate;