import Page401 from 'components/Error/Page401';
import Page403 from 'components/Error/Page403';
import Page404 from 'components/Error/Page404';
import { getLangOptions } from 'constants/langOptions';
import Cookies from 'js-cookie';
import AnonymousHomeComponent from 'pages/home/anonymous-home.component';
import Login from "pages/login/";
import TestComponent from 'pages/test/test.component';
import Test2Component from 'pages/test/test2.component';
import SuccessAccountDeleteComponent from 'pages/user/success-account-delete.component';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { Routes } from 'react-router-dom/dist';

/**
 * The Anonymous Routes.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function Anonymous({ }) {
  const location = useLocation();

  let mylang = localStorage.getItem("i18nextLng").substring(0, 2);
  if (mylang === null || mylang === undefined) {
    mylang = "en";
    localStorage.setItem("i18nextLng", "en");
  }
  let theme = localStorage.getItem("theme");
  if (theme !== undefined && theme !== "light" && theme !== "dark" && theme !== "") {
    localStorage.setItem("theme", "light");
  }

  // Check if the path starts with /en/ or /gr/
  const path = location.pathname;
  if (!path.startsWith("/en") && !path.startsWith("/el")) {
    // If the path doesn't start with /en/ or /gr/, redirect to the same path with /en/ in front
    return <Navigate to={`${mylang}${path}${location.search}`} replace />;
  }

  return (
    <Routes>
      <Route key="test" caseSensitive path={"/test"} element={<TestComponent />} />
      <Route key="test2" caseSensitive path={"/test2"} element={<Test2Component />} />
      {getLangOptions().map((lang, i) =>
        <>
          <Route key="401" caseSensitive path={lang + "/page-401"} element={<Page401 />} />
          <Route key="403" caseSensitive path={lang + "/page-403"} element={<Page403 />} />
          <Route key="404" caseSensitive path={lang + "/page-404"} element={<Page404 />} />

          <Route key="test-lang" caseSensitive path={lang + "/test"} element={<TestComponent />} />
          <Route key="test2-lang" caseSensitive path={lang + "/test2"} element={<Test2Component />} />
          <Route key="auth-login" caseSensitive path={lang + "/auth/login"} element={<Navigate to={"/" + lang} replace />} />
          <Route key="login" caseSensitive path={lang + "/login"} element={<Navigate to={"/" + lang} replace />} />

          <Route key='login' caseSensitive path={lang + "/login2"} element={<Login />} />
          <Route key="account-delete-success" caseSensitive path={`/${lang}/success/account/delete`} element={<SuccessAccountDeleteComponent />} />

          <Route key="anonymous-home" caseSensitive path={lang} element={<AnonymousHomeComponent />} />
        </>
      )}
      {/* <Route key="empty" caseSensitive path="" element={<Navigate to={"/" + mylang} replace />} /> */}
    </Routes>
  )
}

export default Anonymous;