import {
    CloseRounded as CloseRounded,
    Delete as DeleteIcon,
    Gradient as GradientIcon,
    Image as ImageIcon,
    Palette as PaletteIcon
} from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    Stack,
    SvgIcon,
    Tooltip
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import en from "assets/images/flags/en.png";
import CustomImage from 'components/Assets/CustomImage';
import CustomSelect from 'components/Select/CustomSelect';
import CustomTabs from 'components/Tab/CustomTabs';
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTextFieldWithIcons from 'components/Text/CustomTextFieldWithIcons';
import CustomTranslationsDialog from 'components/Dialogs/CustomTranslationsDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { getLoyaltyCardTestValue } from 'constants/loyaltyCardTestValue';
import CustomFontStyle from 'components/Text/CustomFontStyle';
import CustomMarginField from 'components/Text/CustomMarginField';
import CustomLoyaltyCardBackSide from 'components/Card/loyalty/CustomLoyaltyCardBackSide';
import CustomLoyaltyCardFrontSide from 'components/Card/loyalty/CustomLoyaltyCardFrontSide';
import { validateInputNumber } from 'utils/functions';
import { cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, loyaltyBackgroundButtonActiveStyle } from 'themes/defaultThemes';
import { MINIO_HOST } from 'constants/hosts';
import { getLoyaltyCardTargetPoints, getLoyaltyCardCouponValue, getLoyaltyCardDiscountPercentage, getLoyaltyCardBarcodeHeight, getLoyaltyCardBarcodeFontSize } from 'constants/fieldProperties';
import CustomIconButton from 'components/Button/CustomIconButton';
import { getThemeColor } from 'constants/theme';
import { getTheme } from 'utils/theme';
import CustomImageCropper from 'components/Assets/CustomImageCropper';
import { getLoyaltyCategoryPhoto } from 'utils/functions/assets';

/**
 * The AddEditLoyaltyCategory, that display a customized dialog to add/edit a loyalty category.
 *
 * @property {object} category the object containing details for loyalty category
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible. Default value is false.
 * @property {boolean} isAdd If the values is `true`, the modal refering to creation of a new loyalty category.
 * @property {function} handleOpen the function that handles the dialog open state
 * @property {function} addLoyaltyCategory the function that handles the creation of a  loyalty category
 * @property {function} updateLoyaltyCategory the function that handles the modification of a  loyalty category
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AddEditLoyaltyCategory({ category, isOpen = false, isAdd, cardProperties, backgroundTypeOptions = [],
    handleOpen, addLoyaltyCategory, updateLoyaltyCategory, sxCard,
    fontOptions, formatOptions, textAlignOptions, textPositionOptions,
    organizationID, logoImgSrc
}) {
    const { t } = useTranslation();
    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);
    const [loyaltyCategory, setLoyaltyCategory] = useState(category);
    const fontFamilyOptions = [
        // '-apple-system',
        // 'BlinkMacSystemFont',
        // 'Segoe UI',
        // 'Roboto',
        // 'Helvetica Neue',
        // 'Arial',
        // 'sans-serif',
        // 'Apple Color Emoji',
        // 'Segoe UI Emoji',
        // 'Segoe UI Symbol',
        "serif",
        "sans-serif",
        "monospace",
        "cursive",
        "fantasy"
    ];
    const [value, setValue] = React.useState("configuration");

    const [dialog, setDialog] = useState({
        isOpen: false,
        id: "",
        field: "",
        translations: {}
    });

    const [isError, setIsError] = useState({
        name: ""
    });

    /**
     * Loyalty number visibility toggle handler.
     *
     * @param {string} tagName the tag name to be updated
     * @param {string} value the new value
     */
    function toggleLoyaltyNumber(tagName, value) {
        formValChangeWithParentElementWithNameAndValue(tagName, value, loyaltyCategory, setLoyaltyCategory);
    };

    function removeImage() {
        setLoyaltyCategory({
            ...loyaltyCategory,
            backgroundImage: ""
        })
    }

    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpened If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpened) {
        setOpen(isOpened);
        handleOpen(isOpened);
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpened If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleTranslationsDialogState(isOpened, field = "name") {
        setDialog({
            ...dialog,
            isOpen: isOpened,
            field: field,
            translations: loyaltyCategory[field]
        });
    }

    /**
     * This function will trigger the outlet field translations configuration
     */
    function configureCategoryTranslations(translations, field) {
        document.querySelectorAll(`[name="loyaltyCategory.name.en"]`)[0].value = translations.en;
        setLoyaltyCategory({
            ...loyaltyCategory,
            [field]: translations
        });
        setDialog({
            ...dialog,
            isOpen: false,
            field: "",
            translations: {}
        });
    }

    /**
     * Function to trigger the remove of the admin broker.
     * @property {*} event
     * @property {string} field The field of the selected outlet field.
     */
    function configureTranslations(event, field) {
        handleTranslationsDialogState(true, field);
    }

    function handleAction() {
        if (loyaltyCategory.name.en !== "") {
            setOpen(false);
            handleOpen(false);
            isAdd ? addLoyaltyCategory(loyaltyCategory) : updateLoyaltyCategory(loyaltyCategory)
        } else {
            setIsError({
                ...isError,
                name: t('error.valueRequired')
            });
            setValue("configuration");
        }
    }

    const [previewUrl, setPreviewUrl] = useState(null);
    
    /**
     * Callback that handles file upload change.
     * @param {string} fileBase64 the new base64 image 
     */
    function handleFileChange(fileBase64) {
        setLoyaltyCategory({
            ...loyaltyCategory,
            backgroundImage: fileBase64
        });
        setPreviewUrl(fileBase64);
    };


    let style = {};
    if (loyaltyCategory.backgroundType === "IMAGE") {
        let loyaltyCategoryBackgroundImage = !loyaltyCategory.backgroundImage.includes("https://") ?
            (loyaltyCategory.backgroundImage.includes("base64")) ?
                loyaltyCategory.backgroundImage :
                getLoyaltyCategoryPhoto(organizationID, loyaltyCategory.id, loyaltyCategory.backgroundImage)
            : loyaltyCategory.backgroundImage;
        style = {
            backgroundImage: `url(${loyaltyCategoryBackgroundImage})`,
            backgroundSize: "cover"
        }
    } else {
        if (!loyaltyCategory.gradientBackground)
            style = {
                backgroundColor: `${loyaltyCategory.backgroundColor1}!important`,
            }
        else
            style = {
                background: `linear-gradient(to right bottom, ${loyaltyCategory.backgroundColor1}, ${loyaltyCategory.backgroundColor2})!important`,
            }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleDialogState(false) }}
                aria-describedby="alert-dialog-slide-description"
                className="dialog-title"
            >
                <DialogTitle className="dialog-headers">
                    {isAdd ? `${t('actions.add')} ${t('loyalty.category.labelSingle')}` : `${t('actions.edit')} ${t('loyalty.category.labelSingle')} '${loyaltyCategory.name.en}'`}
                    <IconButton onClick={() => { handleDialogState(false) }} sx={{ float: "right" }}>
                        <CloseRounded />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ background: "#fff" }}>
                    <TabContext value={value}>
                        <CustomTabs
                            initValue={value}
                            instances={[
                                { name: t('loyalty.settings.configuration').toUpperCase(), value: "configuration" },
                                { name: t('loyalty.settings.front').toUpperCase(), value: "front" },
                                { name: t('loyalty.settings.back').toUpperCase(), value: "back" }
                            ]}
                            setValue={setValue}
                        />

                        <TabPanel value={"configuration"} sx={{ paddingTop: "0px" }}>
                            <Grid container spacing={2} sx={{ marginTop: '5px', justifyContent: "center" }}>
                                <Grid item xs={12} md={12}>
                                    <InputLabel required={false} htmlFor="name">{t('label.name')}</InputLabel>
                                    <CustomFlagTextField
                                        country="en"
                                        flag={en}
                                        placeholder=""
                                        name="loyaltyCategory.name.en"
                                        id="name"
                                        defaultValue={loyaltyCategory !== null && loyaltyCategory.name !== null ? loyaltyCategory.name.en : ""}
                                        onTranslationSelect={configureTranslations}
                                        field="name"
                                        onChange={(event) => { let value = event.target.value; if (value === "") setIsError({ ...isError, name: t('error.valueRequired') }); formValChangeWithParentElementWithNameAndValue("name.en", value, loyaltyCategory, setLoyaltyCategory) }}
                                        helperText={isError.name}
                                        error={isError.name.length > 0}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <CustomTextField
                                        type="number"
                                        step={getLoyaltyCardTargetPoints().step}
                                        min={getLoyaltyCardTargetPoints().min}
                                        max={getLoyaltyCardTargetPoints().max}
                                        name="min"
                                        value={loyaltyCategory.targetPoints}
                                        inputAdornmentLabel={'loyalty.category.targetPoints'}
                                        inputAdornmentAfterLabel={'loyalty.settings.points'}
                                        required={true}
                                        onChange={(event) => {
                                            let value = validateInputNumber(event.target.value, getLoyaltyCardTargetPoints().length, getLoyaltyCardTargetPoints().min, getLoyaltyCardTargetPoints().max, getLoyaltyCardTargetPoints().isFloat);
                                            if (value === undefined) value = loyaltyCategory.targetPoints;
                                            console.log(value); formValChangeWithParentElementWithNameAndValue("targetPoints", parseInt(value), loyaltyCategory, setLoyaltyCategory)
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <CustomTextField
                                        type="number"
                                        step={getLoyaltyCardDiscountPercentage().step}
                                        min={getLoyaltyCardDiscountPercentage().min}
                                        max={getLoyaltyCardDiscountPercentage().max}
                                        name="min"
                                        value={loyaltyCategory.discountPercentage}
                                        inputAdornmentLabel={'loyalty.category.discountPercentage'}
                                        inputAdornmentAfterLabel={'loyalty.settings.%'}
                                        required={true}
                                        onChange={(event) => {
                                            let value = validateInputNumber(event.target.value, getLoyaltyCardDiscountPercentage().length, getLoyaltyCardDiscountPercentage().min, getLoyaltyCardDiscountPercentage().max, getLoyaltyCardDiscountPercentage().isFloat);
                                            if (value === undefined) value = loyaltyCategory.discountPercentage;
                                            formValChangeWithParentElementWithNameAndValue("discountPercentage", parseFloat(value), loyaltyCategory, setLoyaltyCategory)
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <CustomTextField
                                        type="number"
                                        step={getLoyaltyCardCouponValue().step}
                                        min={getLoyaltyCardCouponValue().min}
                                        max={getLoyaltyCardCouponValue().max}
                                        name="min"
                                        defaultValue={loyaltyCategory.couponValue}
                                        inputAdornmentLabel={'loyalty.category.couponValue'}
                                        required={true}
                                        onChange={(event) => {
                                            let value = validateInputNumber(event.target.value, getLoyaltyCardCouponValue().length, getLoyaltyCardCouponValue().min, getLoyaltyCardCouponValue().max, getLoyaltyCardCouponValue().isFloat);
                                            if (value === undefined) value = loyaltyCategory.discountPercentage;
                                            formValChangeWithParentElementWithNameAndValue("couponValue", parseFloat(value), loyaltyCategory, setLoyaltyCategory)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={"front"} sx={{ paddingTop: "0px" }}>
                            <Stack direction="row" sx={{ justifyContent: "center" }}>
                                <CustomLoyaltyCardFrontSide
                                    category={loyaltyCategory}
                                    cardProperties={cardProperties}
                                    sx={{ ...sxCard, ...style }}
                                    organizationID={organizationID}
                                    logoImgSrc={logoImgSrc}
                                />
                            </Stack>

                            <Grid container spacing={2} sx={{ marginTop: '5px', justifyContent: "center" }}>
                                <Grid item xs={12} md={12}>
                                    <Stack direction="row" sx={{ paddingBottom: "10px" }}>
                                        <InputLabel required={false} style={{ fontWeight: "bold", paddingTop: "5px", paddingRight: "62%" }} >{t('loyalty.category.background')}</InputLabel>
                                        <Tooltip arrow title={t("loyalty.category.backgroundColor1")}>
                                            <PaletteIcon
                                                sx={{ padding: "3px", ...((loyaltyCategory.backgroundType === "COLOR" && loyaltyCategory.gradientBackground === false) ? loyaltyBackgroundButtonActiveStyle() : {}) }}
                                                onClick={() => setLoyaltyCategory({ ...loyaltyCategory, backgroundType: "COLOR", gradientBackground: false })} />
                                        </Tooltip>
                                        <Tooltip arrow title={t("loyalty.category.gradientBackground")}>
                                            <GradientIcon
                                                sx={{ padding: "3px", ...((loyaltyCategory.backgroundType === "COLOR" && loyaltyCategory.gradientBackground === true) ? loyaltyBackgroundButtonActiveStyle() : {}) }}
                                                onClick={() => setLoyaltyCategory({ ...loyaltyCategory, backgroundType: "COLOR", gradientBackground: true })} />
                                        </Tooltip>
                                        <Tooltip arrow title={t("loyalty.category.backgroundImage")}>
                                            <ImageIcon
                                                sx={{ padding: "3px", ...((loyaltyCategory.backgroundType === "IMAGE") ? loyaltyBackgroundButtonActiveStyle() : {}) }}
                                                onClick={() => setLoyaltyCategory({ ...loyaltyCategory, backgroundType: "IMAGE" })} />
                                        </Tooltip>
                                    </Stack>
                                </Grid>

                                {loyaltyCategory.backgroundType === "COLOR" &&
                                    <Grid item xs={12} md={12}>
                                        <Stack direction="row" spacing={1} sx={{ paddingBottom: "10px", width: "100%" }}>
                                            <Stack direction="column" sx={{ width: "50%" }}>
                                                {/* <InputLabel required={false} htmlFor="backgroundColor1">{t('loyalty.category.backgroundColor1')}</InputLabel> */}
                                                <MuiColorInput id="backgroundColor1"
                                                    label={t('loyalty.category.backgroundColor1')}
                                                    name="loyaltyCategory.backgroundColor1"
                                                    format="hex8"
                                                    value={loyaltyCategory.backgroundColor1}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("backgroundColor1", value, loyaltyCategory, setLoyaltyCategory)}
                                                />
                                            </Stack>

                                            <>
                                                {loyaltyCategory.gradientBackground &&
                                                    <Stack direction="column" sx={{ width: "50%" }}>
                                                        {/* <InputLabel required={false} htmlFor="backgroundColor2">{t('loyalty.category.backgroundColor2')}</InputLabel> */}
                                                        <MuiColorInput id="backgroundColor2"
                                                            label={t('loyalty.category.backgroundColor2')}
                                                            name="loyaltyCategory.backgroundColor2"
                                                            format="hex8"
                                                            value={loyaltyCategory.backgroundColor2}
                                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("backgroundColor2", value, loyaltyCategory, setLoyaltyCategory)}
                                                        />
                                                    </Stack>
                                                }
                                            </>
                                        </Stack>
                                    </Grid>
                                }

                                <Grid item xs={12} md={12}>
                                    {loyaltyCategory.backgroundType === "IMAGE" &&
                                        <>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    <InputLabel required={false} htmlFor="url">{t('loyalty.category.backgroundImage')}</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    {(previewUrl === null && (loyaltyCategory.backgroundImage !== null && loyaltyCategory.backgroundImage !== "")) &&
                                                        <Grid container direction="row" spacing={2}>
                                                            <Grid item xs={10} md={10}>
                                                                <CustomImage
                                                                    alt="complex"
                                                                    src={loyaltyCategory.backgroundImage}
                                                                    sx={{ height: "220px" }} />

                                                            </Grid>
                                                            <Grid item xs={0} md={2}>
                                                                <CustomIconButton
                                                                    keyValue={`btn-delete-${loyaltyCategory.id}`}
                                                                    onClick={removeImage}
                                                                    icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                    className="icon-wrapper tr link"
                                                                    iconSx={{ marginRight: "0px!important" }}
                                                                    sx={{
                                                                        backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                        ":hover": {
                                                                            backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                        },
                                                                        ...cardItemButtonGroupStackButton()
                                                                    }}
                                                                >
                                                                    {t('actions.delete')}
                                                                </CustomIconButton>
                                                            </Grid>
                                                        </Grid>
                                                    }

                                                    {/* <CustomTextField
                                                        type="text"
                                                        name="loyaltyCategory.backgroundImage"
                                                        id="backgroundImage"
                                                        required={true}
                                                        defaultValue={loyaltyCategory.backgroundImage}
                                                        onChange={(event) => formValChangeWithParentElementWithNameAndValue("backgroundImage", event.target.value, loyaltyCategory, setLoyaltyCategory)}
                                                    /> */}

                                                    {previewUrl &&
                                                        <Grid container direction="row" spacing={2}>
                                                            <Grid item xs={10} md={10}>
                                                                <img src={previewUrl} alt="Preview" height="220px" />
                                                            </Grid>
                                                            <Grid item xs={0} md={2}>
                                                                <CustomIconButton
                                                                    keyValue={`btn-delete-${loyaltyCategory.id}`}
                                                                    onClick={removeImage}
                                                                    icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                    className="icon-wrapper tr link"
                                                                    iconSx={{ marginRight: "0px!important" }}
                                                                    sx={{
                                                                        backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                        ":hover": {
                                                                            backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                        },
                                                                        ...cardItemButtonGroupStackButton()
                                                                    }}
                                                                >
                                                                    {t('actions.delete')}
                                                                </CustomIconButton>
                                                            </Grid>
                                                        </Grid>
                                                    }

                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <Button>
                                                        {/* Choose File */}
                                                        <CustomImageCropper storeCroppedImage={handleFileChange} />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <InputLabel required={false} style={{ fontWeight: "bold" }} >{t('loyalty.category.font')}</InputLabel>
                                    <Grid container spacing={2}>
                                        {/* <Grid item xs={12} md={6}>
                                            <InputLabel required={false} htmlFor="font">{t('loyalty.category.family')}</InputLabel>
                                            <CustomSelect
                                                name="loyaltyCategory.font"
                                                required={true}
                                                defaultValue={loyaltyCategory.font}
                                                options={fontFamilyOptions}
                                                sx={{ maxHeight: "62px" }}
                                                onClick={(event) => formValChangeWithParentElementWithNameAndValue("font", event.target.value, loyaltyCategory, setLoyaltyCategory)}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} md={6}>
                                            <MuiColorInput id="baseColor"
                                                label={t('loyalty.category.baseColor')}
                                                name="loyaltyCategory.baseColor"
                                                format="hex8"
                                                sx={{ marginTop: "16px" }}
                                                value={loyaltyCategory.baseColor}
                                                // TODO: include matchIsValidColor(value) to check input color is valid?
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("baseColor", value, loyaltyCategory, setLoyaltyCategory)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={"back"} sx={{ paddingTop: "0px" }} >
                            <Stack direction="row" sx={{ justifyContent: "center" }}>
                                <CustomLoyaltyCardBackSide
                                    cardProperties={cardProperties}
                                    category={loyaltyCategory}
                                    sx={{ ...sxCard, ...style }}
                                />
                            </Stack>
                            <Stack direction="row" spacing={1} sx={{ paddingBottom: "10px", width: "100%" }} alignItems="flex-start">
                                <Grid container spacing={2} sx={{ marginTop: '5px', justifyContent: "center" }}>
                                    <Grid item xs={12} md={7}>
                                        <InputLabel required={false} htmlFor="font">{t('loyalty.settings.barcode.format')}</InputLabel>
                                        <CustomSelect
                                            name="loyaltyCategory.barcodeConfiguration.format"
                                            required={true}
                                            value={loyaltyCategory.barcodeConfiguration.format}
                                            options={formatOptions}
                                            sx={{ maxHeight: "62px" }}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.format", event.target.value, loyaltyCategory, setLoyaltyCategory)}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} md={8}>
                                        <CustomTextField
                                            type="number" step="1.00" min={0}
                                            name="loyaltyCategory.barcodeConfiguration.width"
                                            inputAdornmentLabel="loyalty.settings.barcode.width"
                                            defaultValue={loyaltyCategory.barcodeConfiguration.width}
                                            required={true}
                                            disabled={true}
                                            startAdormentWidth="150px"
                                            inputWidth={"80%"}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.width", parseInt(event.target.value), loyaltyCategory, setLoyaltyCategory)}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} md={5}>
                                        <CustomTextField
                                            type="number"
                                            step={getLoyaltyCardBarcodeHeight().step}
                                            min={getLoyaltyCardBarcodeHeight().min}
                                            max={getLoyaltyCardBarcodeHeight().max}
                                            name="loyaltyCategory.barcodeConfiguration.height"
                                            inputAdornmentLabel="loyalty.settings.barcode.height"
                                            value={loyaltyCategory.barcodeConfiguration.height}
                                            required={true}
                                            sx={{ marginTop: "16px" }}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, getLoyaltyCardBarcodeHeight().length, getLoyaltyCardBarcodeHeight().min, getLoyaltyCardBarcodeHeight().max, getLoyaltyCardBarcodeHeight().isFloat);
                                                if (value === undefined) value = loyaltyCategory.barcodeConfiguration.height;
                                                formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.height", parseFloat(value), loyaltyCategory, setLoyaltyCategory)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <CustomTextFieldWithIcons
                                            name="loyaltyCategory.barcodeConfiguration.displayValue"
                                            type="text"
                                            defaultValue={getLoyaltyCardTestValue()}
                                            inputAdornmentLabel="loyalty.settings.barcode.displayValue"
                                            endIcon={<SvgIcon component={loyaltyCategory.barcodeConfiguration.displayValue ? VisibilityIcon : VisibilityOffIcon}
                                                id="visibility-icon"
                                                onClick={() => toggleLoyaltyNumber("barcodeConfiguration.displayValue", !loyaltyCategory.barcodeConfiguration.displayValue)} style={{ cursor: "pointer" }} />
                                            }
                                            disabled={true}
                                        />
                                    </Grid>
                                    {loyaltyCategory.barcodeConfiguration.displayValue &&
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Stack spacing={4} direction="row" sx={{ paddingBottom: "5px", width: "100%", placeContent: "center" }}>
                                                    <CustomFontStyle
                                                        // data
                                                        data={loyaltyCategory}
                                                        setData={setLoyaltyCategory}
                                                        objectnName="barcodeConfiguration."
                                                        // option lists
                                                        fontOptions={fontOptions}
                                                        textAlignOptions={textAlignOptions}
                                                        textPositionOptions={textPositionOptions}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <InputLabel required={false} style={{ fontWeight: "bold" }} >{t('loyalty.category.font')}</InputLabel>
                                                <Grid container spacing={2} >
                                                    {/* sx={{ justifyContent: "center" }}> */}
                                                    {/* <Grid item xs={12} md={8}>
                                                        <InputLabel required={false} htmlFor="font">{t('loyalty.category.family')}</InputLabel>
                                                        <CustomSelect
                                                            name="loyaltyCategory.barcodeConfiguration.font"
                                                            required={true}
                                                            defaultValue={loyaltyCategory.barcodeConfiguration.font}
                                                            options={fontFamilyOptions}
                                                            sx={{ maxHeight: "60px" }}
                                                            onClick={(event) => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.font", event.target.value, loyaltyCategory, setLoyaltyCategory)}
                                                        />
                                                    </Grid> */}

                                                    <Grid item xs={12} md={4}>
                                                        <CustomTextField
                                                            type="number"
                                                            step={getLoyaltyCardBarcodeFontSize().step}
                                                            min={getLoyaltyCardBarcodeFontSize().min}
                                                            max={getLoyaltyCardBarcodeFontSize().max}
                                                            name="loyaltyCategory.barcodeConfiguration.fontSize"
                                                            label={t('loyalty.settings.barcode.fontSize')}
                                                            sx={{ marginTop: "16px" }}
                                                            value={loyaltyCategory.barcodeConfiguration.fontSize}
                                                            onChange={(event) => {
                                                                let value = validateInputNumber(event.target.value, getLoyaltyCardBarcodeFontSize().length, getLoyaltyCardBarcodeFontSize().min, getLoyaltyCardBarcodeFontSize().max, getLoyaltyCardBarcodeFontSize().isFloat);
                                                                if (value === undefined) value = loyaltyCategory.barcodeConfiguration.fontSize;
                                                                formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.fontSize", parseInt(value), loyaltyCategory, setLoyaltyCategory)
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12} md={6}>
                                        {/* <InputLabel required={false} htmlFor="background">{t('loyalty.settings.barcode.background')}</InputLabel> */}
                                        <MuiColorInput id="background"
                                            label={t('loyalty.settings.barcode.background')}
                                            name="loyaltyCategory.barcodeConfiguration.background"
                                            format="hex8"
                                            value={loyaltyCategory.barcodeConfiguration.background}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.background", value, loyaltyCategory, setLoyaltyCategory)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {/* <InputLabel required={false} htmlFor="lineColor">{t('loyalty.settings.barcode.lineColor')}</InputLabel> */}
                                        <MuiColorInput id="lineColor"
                                            label={t('loyalty.settings.barcode.lineColor')}
                                            name="loyaltyCategory.barcodeConfiguration.lineColor"
                                            format="hex8"
                                            value={loyaltyCategory.barcodeConfiguration.lineColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.lineColor", value, loyaltyCategory, setLoyaltyCategory)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <InputLabel required={false} htmlFor="margin" style={{ fontWeight: "bold" }}>{t('loyalty.settings.barcode.margin')}</InputLabel>
                                        <Grid container spacing={2} sx={{ justifyContent: "center", marginTop: "5px" }}>
                                            <Grid item xs={12} md={12}>
                                                <CustomMarginField
                                                    // data
                                                    data={loyaltyCategory}
                                                    displayValue={loyaltyCategory.barcodeConfiguration.displayValue}
                                                    setData={setLoyaltyCategory}
                                                    objectnName="barcodeConfiguration."
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </TabPanel>
                    </TabContext>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button className="dialog-cancel-btn" onClick={() => { handleDialogState(false) }}>{t("actions.cancel")}</Button>
                    <Button className="dialog-action-btn" onClick={() => { handleAction() }}>{isAdd ? t('actions.create') : t('actions.save')}</Button>
                </DialogActions>
            </Dialog>

            {dialog.isOpen &&
                <CustomTranslationsDialog
                    isOpen={dialog.isOpen}
                    id="translations"
                    title={t('actions.translationModal.title')}
                    action={configureCategoryTranslations}
                    handleOpen={handleTranslationsDialogState}
                    translations={loyaltyCategory[dialog.field]}
                    field={dialog.field}
                />
            }
        </>
    );
}

export default AddEditLoyaltyCategory;