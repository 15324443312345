import { Grid } from '@mui/material';
import Popover from '@mui/material/Popover';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';

function GoogleReviewPopover({ anchorEl, open, handleClose, anchorOrigin }) {
    const { t } = useTranslation();
    return (
        <Popover
            id={"google-location-popover"}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={anchorOrigin}
        >
            <Grid container sx={{ padding: "40px", width: "fit-content" }}>
                <Grid item xs={12}>
                    <CustomTypography text={t('outlet.googleReview.locationIDInfo.step1')} variant="body2" sx={{ fontWeight: "bold" }} />
                    <CustomTypography text={t('outlet.googleReview.locationIDInfo.step1Details')} variant="body2" />
                </Grid>
                <Grid item xs={12}>
                    <CustomTypography text={t('outlet.googleReview.locationIDInfo.step2')} variant="body2" sx={{ fontWeight: "bold" }} />
                    <CustomTypography text={t('outlet.googleReview.locationIDInfo.step2Details')} variant="body2" />
                </Grid>
                <Grid item xs={12}>
                    <CustomTypography text={t('outlet.googleReview.locationIDInfo.step3')} variant="body2" sx={{ fontWeight: "bold" }} />
                    <CustomTypography text={t('outlet.googleReview.locationIDInfo.step3Details')} variant="body2" />
                </Grid>
                <Grid item xs={12}>
                    <CustomTypography text={t('outlet.googleReview.locationIDInfo.step4')} variant="body2" sx={{ fontWeight: "bold" }} />
                    <CustomTypography text={t('outlet.googleReview.locationIDInfo.step4Details')} variant="body2" />
                </Grid>
                <Grid item xs={12}>
                    <CustomTypography text={t('outlet.googleReview.locationIDInfo.step5')} variant="body2" sx={{ fontWeight: "bold" }} />
                    <CustomTypography text={t('outlet.googleReview.locationIDInfo.step5Details')} variant="body2" />
                </Grid>
            </Grid>
        </Popover>
    );
}

export default GoogleReviewPopover;