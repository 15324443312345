import { faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShoppingCart } from '@mui/icons-material';
import {
    Badge,
    Checkbox,
    CircularProgress,
    Divider,
    Fab,
    Grid,
    IconButton,
} from '@mui/material';
import orderApi from 'api/order';
import { faCreditCard } from 'assets/fontAwesome/FontAwesomeIcons';
import { ArrowBackIcon, InfoIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomPDAViewOrderGrid from 'components/Card/order/CustomPDAViewOrderGrid';
import CustomSinglePastOrderCardItem from 'components/Card/order/CustomSinglePastOrderCardItem';
import CustomSinglePastOrderCardItemPay from 'components/Card/order/CustomSinglePastOrderCardItemPay';
import CustomTypography from 'components/Typography/CustomTypography';
import { getOrderBulkPaymentActionHistoryItem } from 'constants/defaultObjects';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentMethodButtonIconPDA, paymentMethodButtonPDA } from 'themes/checkout/checkoutTheme';
import { orderBackBtnStyle, orderHeaderTextStyle, pastOrderDateTextStyle, requestFabButtonStyle, requestPdaButtonStyle } from 'themes/defaultThemes';
import { hasRoleDirector } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { dateTimeFormatter, dateTimeFormatterFilter } from 'utils/functions';
import { calculateChipQuantityBulkOrdersPayment, createItemsRequirePayment, calculateBulkOrdersTotalAmountFromSelectedItems, prepareSelectedItemsForPaymentActionHistory, previewSelectedItemsToPayBulkPayAll, calculateTotalAmountFromSelectedItems, prepareItemsForPaymentActionHistory } from 'utils/functions/order';

function UnpaidBulkOrdersDetailsCard({ equipmentID, equipmentLabel, currency, handleReset, setAlertBox, getPDADisplay, userAuth }) {
    const { t } = useTranslation();
    const [selectedItemsToPayPerOrder, setSelectedItemsToPayPerOrder] = useState([]);
    const [payAll, setPayAll] = useState(false);
    const [payAllOrders, setPayAllOrders] = useState([]);
    const [couponId, setCouponId] = useState(null);
    const [itemsRequirePaymentForEachOrder, setItemsRequirePaymentForEachOrder] = useState(null);

    const [data, setData] = useState({
        userAuth: null,
        orders: [],
        paymentMethods: [],
        paymentMethod: "cash",
        treat: false,
        equipment: null,
        currency: "€"
    });

    function getUnapaidBulkOrders() {
        orderApi.fetchAllOrdersEquipmentToPay(equipmentID, dateTimeFormatterFilter()).then((r) => {
            if (r.data.code === "SUCCESS") {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            orders: r.data.returnobject.orders,
                            paymentMethods: r.data.returnobject.paymentMethods,
                            currency: r.data.returnobject.currency,
                            equipment: r.data.returnobject.equipment,
                        });
                    })
                    .then(() => {
                        setSelectedItemsToPayPerOrder(r.data.returnobject.orders?.map((order) => { return { orderID: order.id, selectedItemsToPay: [] } }));
                    })
                    .then(() => {
                        setItemsRequirePaymentForEachOrder(r.data.returnobject?.orders?.map((order) => { return { orderID: order.id, itemsRequirePayment: createItemsRequirePayment(order) } }));
                    })
                    .then(() => {
                        setPayAllOrders(r.data.returnobject.orders?.map((order) => { return { orderID: order.id, payAll: false } }));
                    })

            }
        }).catch((e) => {
            // console.log(e);
        })
    }
    useEffect(() => {
        getUnapaidBulkOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleCheckboxAllChange() {
        if (payAll) {
            setSelectedItemsToPayPerOrder(selectedItemsToPayPerOrder?.map((order) => { return { orderID: order.orderID, selectedItemsToPay: [] } }));
        }
        setPayAll(!payAll);
        setPayAllOrders(payAllOrders.map((order) => {
            return {
                ...order,
                payAll: !order.payAll // Toggle the payAll property
            };
        }))
    };

    function handleSingleOrderCheckboxAllChange(orderID) {
        let payAllorder = payAllOrders?.find((order) => order.orderID === orderID)?.payAll;
        if (payAllorder) {
            setSelectedItemsToPayPerOrder(selectedItemsToPayPerOrder?.map((order) => { return order.orderID === orderID ? { orderID: order.orderID, selectedItemsToPay: [] } : order }));
        } else {
            setSelectedItemsToPayPerOrder(selectedItemsToPayPerOrder?.map((order) => {
                return order.orderID === orderID ? {
                    orderID: orderID,
                    selectedItemsToPay: prepareSelectedItemsForPaymentActionHistory(
                        selectedItemsToPayPerOrder?.find((orderItemsToPay) => orderItemsToPay.orderID === orderID)?.selectedItemsToPay,
                        itemsRequirePaymentForEachOrder?.find((orderItemsToPay) => orderItemsToPay.orderID === orderID)?.itemsRequirePayment,
                        itemsRequirePaymentForEachOrder?.find((orderItemsToPay) => orderItemsToPay.orderID === orderID)?.itemsRequirePayment.map((item, index) => {
                            return { newQuantityTaPay: item.quantity, index: index, cartItem: item }
                        }))
                } : order
            }));
        }

        setPayAllOrders(payAllOrders.map((order) => {
            if (order.orderID === orderID) {
                return {
                    ...order,
                    payAll: !payAllorder // Toggle the payAll property
                };
            } else {
                return order; // Return the order unmodified if the orderID does not match
            }
        }))
    };
    function onPayUpdate(updatedItems, orderID) {
        let newSelectedItems = prepareSelectedItemsForPaymentActionHistory(
            selectedItemsToPayPerOrder?.find((orderItemsToPay) => orderItemsToPay.orderID === orderID)?.selectedItemsToPay,
            itemsRequirePaymentForEachOrder?.find((orderItemsToPay) => orderItemsToPay.orderID === orderID)?.itemsRequirePayment,
            updatedItems);

        if (newSelectedItems !== null)
            setSelectedItemsToPayPerOrder(prevOrders =>
                prevOrders.map(order =>
                    order.orderID === orderID
                        ? { ...order, selectedItemsToPay: newSelectedItems }
                        : order
                )
            );
    }

    const [showCheckout, setShowCheckout] = useState(false);
    const [showOrderInfo, setShowOrderInfo] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);

    function moveToCheckout() {
        setShowCheckout(true);
    }
    function backFromCheckout() {
        setShowCheckout(false);
    }

    function pay() {
        if (((!hasRoleDirector(userAuth?.roles) && !data.treat) || (hasRoleDirector(userAuth?.roles) && !data.treat)) && !showCheckout) {
            moveToCheckout();
        } else {
            setLoadingBtn(true);

            let paymentActionsHistory = payAll ?
                itemsRequirePaymentForEachOrder?.map((itemsRequirePayment) => { return getOrderBulkPaymentActionHistoryItem(itemsRequirePayment.orderID, prepareItemsForPaymentActionHistory(itemsRequirePayment.itemsRequirePayment), couponId, data.paymentMethod, data.treat) })
                :
                selectedItemsToPayPerOrder?.map((selectedItemsToPay) => { return getOrderBulkPaymentActionHistoryItem(selectedItemsToPay.orderID, selectedItemsToPay.selectedItemsToPay, couponId, data.paymentMethod, data.treat) });


            orderApi.payCombineAction(paymentActionsHistory).then((r) => {
                if (r.data.code === "SUCCESS") {
                    setAlertBox({
                        isOpen: true,
                        message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                    handleReset();
                    getPDADisplay();
                }
                setLoadingBtn(false);
            }).catch((e) => {
                // console.log(e);
                setLoadingBtn(false);
            });
        }
    }

    function payAndClose() {
        if (((!hasRoleDirector(userAuth?.roles) && !data.treat) || (hasRoleDirector(userAuth?.roles) && !data.treat)) && !showCheckout) {
            moveToCheckout();
        } else {
            setLoadingBtn(true);

            let paymentActionsHistory = payAll ?
                itemsRequirePaymentForEachOrder?.map((itemsRequirePayment) => { return getOrderBulkPaymentActionHistoryItem(itemsRequirePayment.orderID, prepareItemsForPaymentActionHistory(itemsRequirePayment.itemsRequirePayment), couponId, data.paymentMethod, data.treat) })
                :
                selectedItemsToPayPerOrder?.map((selectedItemsToPay) => { return getOrderBulkPaymentActionHistoryItem(selectedItemsToPay.orderID, selectedItemsToPay.selectedItemsToPay, couponId, data.paymentMethod, data.treat) });

            orderApi.payAction(paymentActionsHistory).then((r) => {
                if (r.data.code === "SUCCESS") {
                    setAlertBox({
                        isOpen: true,
                        message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                    handleReset();
                    getPDADisplay();
                    setLoadingBtn(false);
                }
            }).catch((e) => {
                // console.log(e);
                setLoadingBtn(false);
            });
        }
    }

    // ========================= Payment Method 
    function updatePaymmentMethodCallback(newValue) {
        formValChangeWithParentElementWithNameAndValue("paymentMethod", newValue, data, setData);
    }

    // ========================= Payment Method 
    function updateTreatCallback(newValue) {
        formValChangeWithParentElementWithNameAndValue("treat", newValue, data, setData);
    }
    // ================================ RENDER
    return (
        <>
            {showOrderInfo !== "" &&
                <Grid container>
                    <CustomPDAViewOrderGrid
                        orderID={showOrderInfo}
                        setAlertBox={setAlertBox}
                        handleReset={() => { setShowOrderInfo(""); setShowCheckout(false) }}
                    />
                </Grid>
            }

            {!showOrderInfo && !showCheckout &&
                <>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6}>
                                <CustomIconButton
                                    icon={<ArrowBackIcon />}
                                    sx={orderBackBtnStyle()}
                                    onClick={() => handleReset()}
                                    label={t('pda.backToUnpaidOrders')}
                                />
                            </Grid>

                            {/* Checkbox for paying all unpaid items of all orders */}
                            <Grid item xs={6} sx={{ mb: "20px", textAlign: "right" }}>
                                <Checkbox
                                    checked={payAll}
                                    onChange={handleCheckboxAllChange}
                                />
                                {t('pda.payAll')}
                            </Grid>

                            {itemsRequirePaymentForEachOrder?.map((order, oIndex) =>
                                <Grid container gap={1}>
                                    <Grid item xs={1} >
                                        <InfoIcon sx={{ fontSize: "15px", paddingTop: "0" }} onClick={() => setShowOrderInfo(order.orderID)} />
                                    </Grid>

                                    <Grid item xs={10}>
                                        <CustomTypography variant="body2" text={`${dateTimeFormatter(data.orders?.find((or) => or.id === order.orderID)?.dateCreated, "DD/MM/yyyy - HH:mm")} - ID: ${order.orderID} - #${data.orders?.find((or) => or.id === order.orderID)?.orderNumber}`} sx={pastOrderDateTextStyle()} />
                                    </Grid>

                                    {/* Checkbox for paying all unpaid items of single order */}
                                    <Grid item xs={12} md={12}>
                                        <Checkbox
                                            checked={payAllOrders?.find((or) => or.orderID === order.orderID).payAll || payAll}
                                            onChange={() => handleSingleOrderCheckboxAllChange(order.orderID)}
                                        />
                                        {t('pda.payAll')}
                                    </Grid>

                                    {order?.itemsRequirePayment?.map((cartItem, index) =>
                                        <CustomSinglePastOrderCardItemPay
                                            cartItem={cartItem}
                                            currency={data.currency}
                                            onPay={(updatedItems) => onPayUpdate(updatedItems, order.orderID)}
                                            payAll={payAll || payAllOrders?.find((or) => or.orderID === order.orderID)?.payAll}
                                            selectedItemsToPay={selectedItemsToPayPerOrder?.find((or) => or?.orderID === order?.orderID)?.selectedItemsToPay}
                                            index={index}
                                            initGridItemMarginTop="5px"
                                        />
                                    )}

                                    <Divider sx={{ width: "100%", mb: "20px" }} />
                                </Grid>
                            )}
                        </Grid>


                        {calculateChipQuantityBulkOrdersPayment(itemsRequirePaymentForEachOrder, selectedItemsToPayPerOrder, payAll) > 0 &&
                            <>
                                <Fab
                                    aria-label="add"
                                    variant="extended"
                                    sx={requestFabButtonStyle(false, "absolute")}
                                    onClick={moveToCheckout}
                                >
                                    <Badge
                                        badgeContent={calculateChipQuantityBulkOrdersPayment(itemsRequirePaymentForEachOrder, selectedItemsToPayPerOrder, payAll)}
                                        color="primary"
                                        style={{ color: 'blue', marginRight: "30px" }}
                                    >
                                        <ShoppingCart sx={{ color: "white" }} onClick={() => setShowCheckout(true)} />
                                    </Badge>
                                    {payAll ? t('pda.payAll') : t('pda.paySelectedItems')}
                                </Fab>

                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={"auto"}>
                                            <CustomTypography variant="span" text={`${t('order.totalAmount')}:  `} sx={orderHeaderTextStyle()} />
                                        </Grid>
                                        <Grid item xs={"auto"}>
                                            <CustomTypography variant="span" text={`${calculateBulkOrdersTotalAmountFromSelectedItems(payAll ? itemsRequirePaymentForEachOrder : selectedItemsToPayPerOrder, payAll)} ${currency}`} sx={orderHeaderTextStyle()} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }

                        {hasRoleDirector(userAuth?.roles) &&
                            <Grid item xs={12} md={12} sx={{ marginTop: '20px', marginBottom: "50px" }}>
                                <Checkbox
                                    checked={data.treat}
                                    onChange={(event) => updateTreatCallback(event.target.checked)}
                                />
                                {t('pda.payment.treat')}
                            </Grid>
                        }
                    </Grid>
                </>
            }

            {(!showOrderInfo && showCheckout) &&
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                            <CustomIconButton
                                icon={<ArrowBackIcon />}
                                sx={orderBackBtnStyle()}
                                onClick={() => backFromCheckout()}
                                label={t('pda.back')}
                            />
                        </Grid>

                        <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", mb: "20px" }} />

                        {payAll &&
                            <Grid item xs={12} md={12}>
                                {itemsRequirePaymentForEachOrder?.map((order) =>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <InfoIcon sx={{ fontSize: "15px", paddingTop: "0" }} onClick={() => setShowOrderInfo(order.orderID)} />
                                        </Grid>

                                        <Grid item xs={10}>
                                            <CustomTypography variant="body2" text={`${dateTimeFormatter(data.orders?.find((or) => or.id === order.orderID)?.dateCreated, "DD/MM/yyyy - HH:mm")} - ID: ${order.orderID} - #${data.orders?.find((or) => or.id === order.orderID)?.orderNumber}`} sx={pastOrderDateTextStyle()} />
                                        </Grid>

                                        {order.itemsRequirePayment?.map((cartItem) =>
                                            <CustomSinglePastOrderCardItem
                                                cartItem={cartItem}
                                                currency={currency}
                                            />
                                        )}

                                        <Divider sx={{ width: "100%", mb: "20px", mt: "20px" }} />
                                    </Grid>
                                )}
                            </Grid>
                        }

                        {!payAll &&
                            <Grid item xs={12} md={12}>
                                {selectedItemsToPayPerOrder?.map((order) =>
                                    calculateTotalAmountFromSelectedItems(order.selectedItemsToPay, payAll) > 0 &&
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <InfoIcon sx={{ fontSize: "15px", paddingTop: "0" }} onClick={() => setShowOrderInfo(order.orderID)} />
                                        </Grid>

                                        <Grid item xs={10}>
                                            <CustomTypography variant="body2" text={`${dateTimeFormatter(data.orders?.find((or) => or.id === order.orderID)?.dateCreated, "DD/MM/yyyy - HH:mm")} - ID: ${order.orderID} - #${data.orders?.find((or) => or.id === order.orderID)?.orderNumber}`} sx={pastOrderDateTextStyle()} />
                                        </Grid>

                                        {previewSelectedItemsToPayBulkPayAll(order.selectedItemsToPay, itemsRequirePaymentForEachOrder.find((or) => or.orderID === order.orderID)?.itemsRequirePayment)?.map((cartItem) =>
                                            cartItem.quantity > 0 &&
                                            <CustomSinglePastOrderCardItem
                                                cartItem={cartItem}
                                                currency={currency}
                                            />
                                        )}

                                        <Divider sx={{ width: "100%", mb: "20px", mt: "20px" }} />
                                    </Grid>
                                )}
                            </Grid>
                        }

                        {(payAll || selectedItemsToPayPerOrder?.length > 0) &&
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="span" text={`${t('order.totalAmount')}:  `} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="span" text={`${calculateBulkOrdersTotalAmountFromSelectedItems(payAll ? itemsRequirePaymentForEachOrder : selectedItemsToPayPerOrder, payAll)} ${currency}`} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }


                        <Grid container sx={{ paddingLeft: "20px", paddingTop: "20px" }} gap={1}>
                            <Grid item xs={12} md={12} sx={{ alignSelf: "center" }}>
                                <Grid container sx={{ textAlign: "left" }}>
                                    <Grid item xs={12} md={12}>
                                        <CustomTypography variant="body2" text={t('pda.payment.method')} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                        <Grid container gap={1}>
                                            <Grid item xs={5.5}>
                                                <CustomIconButton
                                                    keyValue={`btn-search-cash`}
                                                    onClick={() => updatePaymmentMethodCallback("cash")}
                                                    icon={<FontAwesomeIcon key={"icon-cash-search"} icon={faMoneyBill1Wave} style={paymentMethodButtonIconPDA()} />}
                                                    iconSx={paymentMethodButtonIconPDA()}
                                                    label={t('checkout.payment.cash')}
                                                    sx={paymentMethodButtonPDA()}
                                                    variant='outlined'
                                                    backgroundColor="#dcf1f7"
                                                    isFocused={data.paymentMethod === "cash"}
                                                />
                                            </Grid>

                                            <Grid item xs={5.5}>
                                                <CustomIconButton
                                                    keyValue={`btn-search-card`}
                                                    onClick={() => updatePaymmentMethodCallback("card")}
                                                    icon={<FontAwesomeIcon key={"icon-card-search"} icon={faCreditCard} style={paymentMethodButtonIconPDA()} />}
                                                    iconSx={paymentMethodButtonIconPDA()}
                                                    label={t('checkout.payment.card')}
                                                    sx={paymentMethodButtonPDA()}
                                                    variant='outlined'
                                                    backgroundColor="#dcf1f7"
                                                    isFocused={data.paymentMethod === "card"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={5.5} sx={{ mt: "40px" }}>
                                <IconButton
                                    aria-label="add"
                                    variant="extended"
                                    sx={requestPdaButtonStyle(loadingBtn)}
                                    onClick={pay}
                                    disabled={loadingBtn}
                                >
                                    {loadingBtn && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "47a6d2",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                    <Badge
                                        badgeContent={calculateChipQuantityBulkOrdersPayment(itemsRequirePaymentForEachOrder, selectedItemsToPayPerOrder, payAll)}
                                        color="primary"
                                        style={{ color: 'blue', marginRight: "30px" }}
                                    >
                                        <ShoppingCart sx={{ color: "white" }} onClick={() => setShowCheckout(true)} />
                                    </Badge>
                                    {t('pda.pay')}
                                </IconButton>
                            </Grid>

                            <Grid item xs={5.5} sx={{ mt: "40px" }}>
                                <IconButton
                                    aria-label="add"
                                    variant="extended"
                                    sx={requestPdaButtonStyle(loadingBtn)}
                                    onClick={payAndClose}
                                    disabled={loadingBtn}
                                >
                                    {loadingBtn && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "47a6d2",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                    <Badge
                                        // TODO: fix logic
                                        badgeContent={calculateChipQuantityBulkOrdersPayment(itemsRequirePaymentForEachOrder, selectedItemsToPayPerOrder, payAll)}
                                        color="primary"
                                        style={{ color: 'blue', marginRight: "30px" }}
                                    >
                                        <ShoppingCart sx={{ color: "white" }} onClick={() => setShowCheckout(true)} />
                                    </Badge>
                                    {t('pda.payAndClose')}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default UnpaidBulkOrdersDetailsCard;