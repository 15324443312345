import { Fragment } from 'react';
import { AvatarGroup, Divider, Grid, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import ChatUserAvatar from 'components/Grid/chat/user/ChatUserAvatar';
import { dateTimeFormatter } from 'utils/functions';

/**
 * The ChatChannelList, that display chat user list.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ChatChannelList({ selectedChatChannel, setSelectedChatChannel, chatChannels = {} }) {
    return (
        <List component="nav">
            {chatChannels?.content?.map((chatChannel) => (
                <Fragment key={chatChannel.id}>
                    <ListItemButton
                        sx={{ padding: "10px 0", background: selectedChatChannel === chatChannel.id ? "#47a6d242" : "transparent" }}
                        onClick={() => {
                            setSelectedChatChannel(chatChannel.id);
                        }}
                    >
                        <ListItemAvatar sx={{ marginRight: "10px" }}>
                            <AvatarGroup total={chatChannel?.employees?.length}>
                                {chatChannel?.employees?.slice(0, 2)?.map((employee) => (
                                    <ChatUserAvatar user={employee} />
                                ))}
                            </AvatarGroup>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Grid container alignItems="center" spacing={1} component="span">
                                    <Grid item xs={12} zeroMinWidth>
                                        <Typography
                                            variant="h5"
                                            color="inherit"
                                            component="span"
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'block'
                                            }}
                                        >
                                            {chatChannel?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="span" variant="subtitle2" sx={{ fontSize: "px" }}>
                                            {dateTimeFormatter(chatChannel?.lastMessageDate)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItemButton>
                    <Divider />
                </Fragment>
            ))}
        </List>
    );
};

export default ChatChannelList;
