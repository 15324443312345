import DehazeIcon from '@mui/icons-material/Dehaze';
import DeleteIcon from '@mui/icons-material/Delete';
import HeightIcon from '@mui/icons-material/Height';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { createTheme, Divider, Grid, ListItem, ListItemIcon, ListItemText, ThemeProvider, Tooltip } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { openInNewTab } from 'utils/functions';
import { getLanguageFromURL } from "../../utils/language";

/**
 * A Custom Draggable List Item.
 * 
 * @param {object} item a specific modifier-list object
 * @param {int} index modifier-list item index
 * @param {*} remove function that removes the item/modifier instance
 * @param {string} organizationID The id of the organization
 * @param {boolean} enableDraggable Has value true when draggable icon is visible.
 *
 * @returns {JSX.Element} A React component that renders a custom draggable list item for modifiers/items.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomItemModifierDraggableTableItem({ item, index, remove, field, organizationID, enableDraggable, isMenu = false }) {
    const { t } = useTranslation();

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiInputAdornment: {
                    styleOverrides: {
                        root: {
                            fontWeight: 600
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            maxHeight: "50px"
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Draggable draggableId={item.id} index={index} key={item.id}>
                {(provided, snapshot) => (
                    <>
                        {index !== 0 &&
                            <Divider style={{ width: "80%", marginLeft: "70px" }} />
                        }
                        <ListItem
                            sx={{
                                background: snapshot.isDragging ? 'rgb(235,235,235)' : '',
                                width: "100%",
                                borderBottom: "1px",
                                userSelect: "none",
                                padding: isMenu ? "20px 10px" : "20px",
                                borderRadius: "4px",
                                ...provided.draggableProps.style,
                                height: "20px"
                            }}
                            {...provided.draggableProps} ref={provided.innerRef}
                        >
                            <Grid container spacing={0} alignItems="center" sx={{ marginLeft: "-18px" }}>
                                <Grid item xs={3} sm={2} md={1} >
                                    <ListItemIcon sx={{ visibility: (enableDraggable) ? "visible" : "hidden" }}  {...provided.dragHandleProps}>
                                        <HeightIcon style={{ fontSize: "19px", color: "lightgrey" }} />
                                        <DehazeIcon style={{ marginRight: 15, marginLeft: "-6px", fontSize: "20px", width: "15px", color: "lightgrey", paddingTop: "1px" }} />
                                    </ListItemIcon>
                                </Grid>

                                <Grid item xs={6} sm={8}>
                                    <ListItemText primary={item.name} />
                                </Grid>

                                <Grid item xs={3} sm={2} sx={{ textAlign: 'right' }}>
                                    <Grid container spacing={1} alignItems="right">
                                        <Grid item xs={6}>
                                            {/* {item.prices !== undefined && <CustomTypography text={`€${parseFloat(item.prices.find(i => i.priceListType === 'STANDARD').price).toFixed(2)}`} variant="body2" sx={{ display: "contents", bottom: 0 }} />} */}
                                            <Tooltip arrow title={t("actions.view")}><VisibilityIcon style={{ color: "#205d7b", fontSize: "20px", marginLeft: "40px" }} onClick={() => openInNewTab(`/${getLanguageFromURL()}/organization/${organizationID}/item-or-modifier/${item.id}`)} /></Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip arrow title={t("actions.remove")}><DeleteIcon style={{ color: "#205d7b", fontSize: "20px", marginLeft: "30px", marginRight: "20px" }} onClick={((event) => remove(event, item, field))} /></Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </>
                )}
            </Draggable>
        </ThemeProvider>
    );
}

export default CustomItemModifierDraggableTableItem;