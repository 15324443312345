import { Divider, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { PopupButton } from "react-calendly";
import { useTranslation } from "react-i18next";
import userApi from "../../api/user";
import CustomHelpCard from "../../components/Card/CustomHelpCard";
import LoadingSkeleton from "../../components/Loading/LoadingSkeleton";
import CustomTypography from "../../components/Typography/CustomTypography";
import { getHelpMenuItems } from "../../constants/helpMenuItems";
import useDocumentTitle from "../../utils/useDocumentTitle";

/**
 * The HelpListComponent, that allow the user to view the help page.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function HelpListComponent() {
    const { t } = useTranslation();

    //change document title
    useDocumentTitle(`Usee| | ${t('org.pageTitle')}`);

    /**
     * @type {object}
     * 
     * @property {boolean} isLoaded to render DOM based on rest api call status, if true the rest api call completed
     */
    const [data, setData] = useState({
        isLoaded: false,
        userAuth: null
    });

    useEffect(() => {
        getSubscriptions();
    }, []);

    /**
     * The rest endpoint to get the subscriptions list.
     * @property {number} page the list page number
     */
    function getSubscriptions(page) {
        userApi.fetchAuth().then((r) => {
            setData({
                ...data,
                isLoaded: true,
                userAuth: r.data.returnobject,
            });
        }).catch((e) => {
            // console.log(e);
        })
    }

    // ===============================================================================RENDER

    if (!data.isLoaded) {
        return <LoadingSkeleton lines={9} />;
    } else {
        return (
            // HELP List
            <main className="tr" role="main" id="help-list" style={{ textAlign: "center" }}>
                <CustomTypography variant="body2" text={t('help.header')} sx={{ fontSize: "20px", color: "#000", fontWeight: "bold", padding: 0, paddingBottom: "20px" }} />

                <Stack direction="row" sx={{ padding: "10px", marginRight: "50px", alignItems: "center" }}>
                    <Grid container gap={4}>
                        {getHelpMenuItems().map((category) => (
                            <CustomHelpCard
                                title={t(`help.${category.label}.title`)}
                                subtitle={t(`help.${category.label}.subtitle`)}
                                link={category.link}
                                imgSrc={category.imgSrc}
                            />
                        ))}
                    </Grid>
                </Stack>

                <Divider style={{ marginTop: "30px" }} />

                <CustomTypography variant="body2" text={t('help.bookMeetingMessage')} sx={{ fontSize: "20px", color: "#000", fontWeight: "bold", padding: 0, marginTop: "30px", paddingBottom: "20px" }} />

                <PopupButton
                    url="https://calendly.com/usee"
                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                    rootElement={document.getElementById("root")}
                    text={t('help.bookMeeetingBtnLabel')}
                    styles={{
                        borderRadius: "5px",
                        background: "#205d7b",
                        color: "#fff",
                        padding: "10px 20px",
                        fontSize: "25px",
                        border: "none",
                        marginTop: "10px",
                        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
                    }}
                    pageSettings={{
                        backgroundColor: 'ffffff',//TODO: include darkmode background
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        primaryColor: '205d7b',
                        textColor: '205d7b'
                    }}
                    prefill={{
                        email: 'test@test.com',
                        firstName: data.userAuth.firstName,
                        lastName: data.userAuth.lastName,
                        name: `${data.userAuth.firstName} ${data.userAuth.lastName}`,
                        date: new Date(Date.now() + 86400000),
                        // TODO: include manager email
                        // guests: [
                        //     'janedoe@example.com',
                        //     'johndoe@example.com'
                        // ],
                    }}
                />
            </main>
        );
    }
}

export default HelpListComponent;