import { Collapse, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { hasAnyOfRoles } from 'utils/auth';
import { useEffect } from 'react';

/**
 * The CustomListItem, that display each drawer list items.
 * @property {string} sidenav the drawer details
 * @property {*} icon the drawer list item icon
 * @property {string} label the drawer list item label
 * @property {string} link the drawer list item reference link
 * @property {boolean} open the custom list item collapsable menu state
 * @property {function} onClick the function that handles the menu item click
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomListItem({ sidenav, icon, label = "", link = null, open = false, children = undefined, keyValue, onClick, roles }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [openV, setOpenV] = useState(open);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    const isActive = location.pathname === link || children?.some(child => location.pathname === child.link); // Check if the current item or any child is active

    /**
     * Fucntion that handles the collapsable menu click.
     */
    const handleClick = () => {
        if (children) {
            setOpenV(!openV);
        } else if (link) {
            navigate(link);
        }
    }

    // active menu item on page load
    useEffect(() => {
        // Automatically open the parent item if a child item is active
        if (isActive && children) {
            setOpenV(true);
        }
    }, [isActive, children]);


    if (redirect) {
        setRedirect(false)
        // window.location.href = `${window.location.origin}${link}`;
        navigate(link)
    } else {
        return (
            <>
                <Divider />
                <ListItem button key={label} disablePadding sx={{ display: 'block' }} onClick={onClick || handleClick}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: sidenav.expanded ? 'initial' : 'center',
                            px: 2.5,
                            color: isActive ? "rgba(0, 0, 0, 0.54)" : "#0000008a!important",
                            backgroundColor: isActive ? "#47a6d2" : "white",
                            borderRadius: 4,
                            margin: "2px 5x"
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: sidenav.expanded ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            {icon}
                        </ListItemIcon>

                        <ListItemText
                            primary={label}
                            sx={{
                                opacity: sidenav.expanded ? 1 : 0,
                                // color: isActive ? "white" : "rgba(0, 0, 0, 0.54)",
                                // backgroundColor: isActive ? "rgba(0, 0, 0, 0.54)" : "white",
                            }} />
                    </ListItemButton>
                </ListItem>
                {children &&
                    <Collapse
                        key={"collapse-" + keyValue}
                        in={openV}
                        timeout='auto'
                        unmountOnExit
                        orientation="vertical"
                        collapsedSize="50px"
                        style={{ paddingLeft: "25px" }}
                    >
                        {children.map((menuItem) => (
                            hasAnyOfRoles(roles, menuItem.roles) && (
                                <CustomListItem key={"collapse-" + menuItem.label} sidenav={sidenav} label={t(`menu.${menuItem.label}`)} icon={menuItem.icon} link={menuItem.link} children={menuItem.children} />
                            )
                        ))}
                    </Collapse>
                }
            </>
        );
    }
}

export default CustomListItem;