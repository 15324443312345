import {
    Add as AddIcon,
    Apps as AppsIcon,
    Delete as DeleteIcon,
    Print as PrintIcon,
    Save as SaveIcon,
    WavingHand as WavingHandIcon
} from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import SmsIcon from '@mui/icons-material/Sms';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { TabContext, TabPanel } from '@mui/lab';
import { Divider, Fab, FormControlLabel, Grid, InputLabel, Stack, Switch } from "@mui/material";
import outletApi from 'api/outlet';
import vatApi from 'api/vat';
import en from "assets/images/flags/en.png";
import { InfoIcon, ReceiptIcon } from 'assets/mui/MuiIcons';
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomButton from 'components/Button/CustomButton';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomCard from 'components/Card/CustomCard';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import CustomChipEmailInput from 'components/Chip/CustomChipEmailInput';
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";
import CustomFieldset from 'components/Fieldset/CustomFieldset';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import GoogleReviewPopover from 'components/Popover/GoogleReviewPopover';
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTabs from 'components/Tab/CustomTabs';
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTextFieldWithIcons from 'components/Text/CustomTextFieldWithIcons';
import CustomAddEditTitle from "components/Title/CustomAddEditTitle";
import { getDefaultOutlet, getDefaultOutletTicketDetailObject, getDefaultOutletTicketObject } from 'constants/defaultObjects';
import { MuiChipsInput } from "mui-chips-input";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { addCardActionAreaButtonStyle, addWorkingHoursButtonIconStyle, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, deleteButtonTicketStyle, fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, textFieldError, ticketGridStyle } from 'themes/defaultThemes';
import { formIsValid, formValChangeWithParentElement, formValChangeWithParentElementObjectValues, formValChangeWithParentElementWithNameAndValue, formValChangeWithParents, onControlChangeWithParent, onSelectChange } from "utils/form-validation";
import { isValidEmail, validateVatNumber } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { getSocialFontAwesomeIcon } from 'utils/social';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The OutletAddEditComponent that allow the ADMIN to create a 
 * new outlet or edit an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OutletAddEditComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const navigate = useNavigate();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    path += `/outlet`;

    //info for google
    const [open, setOpen] = useState(false);
    const anchorEl = document.getElementById('google-location-popover');

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {boolean} isAdd to define if requested an edit or an add of the knowledge base category
     * @property {object} organization the organization basic details (id and name)
     * @property {object} outlet tha outlet basic details (id and name)
     * @property {array} socialProfileTypeOptions the different social profile type options
     * @property {array} accountTicketFieldOptions the different account ticket options
     * @property {array} orderTicketFieldOptions the different order ticket options
     * @property {array} notificationActionOptions the different notification action options (plain or email ones)
     * @property {array} tagOptions the different tag options
     * @property {array} outlets the organization's outlet basic details (id, name)
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        userAuth: null,
        isAdd: outletID === undefined || outletID === "add",
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        socialProfileTypeOptions: [],
        accountTicketFieldOptions: [],
        orderTicketFieldOptions: [],
        notificationActionOptions: [],
        emailNotificationActionOptions: [],
        feedbackTicketFieldOptions: [],
        tagOptions: [],
        equipmentOptions: [],
        employeeOptions: [],
        actionsValue: [],//for notification action value field
        printNodeId: "",
        section: "",
        outletId: "",
        employeeIds: "",
        equipmentIds: "",
        employeesCounter: 0,
        outletsCounter: 0,
        menusCounter: 0,
        itemsOrModifiersCounter: 0,
        sectionsCounter: 0,
        equipmentsCounter: 0,
        printersCounter: 0,
        requestsCounter: 0,
        organizationSocialProfiles: [],
        countries: [],
        organizationVatNumber: "",
        organizationAdress: {},
        counters: {},
        // error messages per field
        isError: {
            name: "",
            url: "",
            vatNumber: ""
        }
    });

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} | ${t('outlet.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`);

    const [tabValue, setTabValue] = useState("details");
    const [ticketTabValue, setTicketTabValue] = useState(null);

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    // isLoaded to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        id: "",
        field: "",
        translations: {}
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getOutlet();
    }, []);

    /**
     * The rest endpoint to get the outlet.
     */
    function getOutlet() {
        if (data.isAdd) {
            outletApi.fetchNew(organizationID).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            organization: r.data.returnobject.organization,
                            outlet: {
                                organizationID: organizationID,
                                ...getDefaultOutlet(),
                            },
                            socialProfileTypeOptions: r.data.returnobject.socialProfileTypeOptions,
                            emailNotificationActionOptions: r.data.returnobject.emailNotificationActionOptions,
                            notificationActionOptions: r.data.returnobject.notificationActionOptions,
                            accountTicketFieldOptions: r.data.returnobject.accountTicketFieldOptions,
                            orderTicketFieldOptions: r.data.returnobject.orderTicketFieldOptions,
                            feedbackTicketFieldOptions: r.data.returnobject.feedbackTicketFieldOptions,
                            inventoryTicketFieldOptions: r.data.returnobject.inventoryTicketFieldOptions,
                            tagOptions: r.data.returnobject.outletTagOptions,
                            outlets: r.data.returnobject.outlets,
                            requestsCounter: r.data.returnobject.requestsCounter,
                            equipmentOptions: r.data.returnobject.equipmentOptions,
                            employeeOptions: r.data.returnobject.employeeOptions,
                            organizationSocialProfiles: r.data.returnobject.organizationSocialProfiles,
                            countries: r.data.returnobject.countryList,
                            organizationAdress: r.data.returnobject.organizationAdress,
                            organizationVatNumber: r.data.returnobject.organizationVatNumber,
                            counters: r.data.returnobject.counters
                        });
                    })
                    .then(() => {
                        setTicketTabValue(r.data.returnobject.emailNotificationActionOptions.length > 0 ? r.data.returnobject.emailNotificationActionOptions[0] : null);
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            outletApi.fetchOne(organizationID, outletID).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            organization: r.data.returnobject.organization,
                            outlet: r.data.returnobject.outlet,
                            userAuth: r.data.returnobject.userAuth,
                            socialProfileTypeOptions: r.data.returnobject.socialProfileTypeOptions,
                            emailNotificationActionOptions: r.data.returnobject.emailNotificationActionOptions,
                            notificationActionOptions: r.data.returnobject.notificationActionOptions,
                            inventoryTicketFieldOptions: r.data.returnobject.inventoryTicketFieldOptions,
                            employeesCounter: r.data.returnobject.employeesCounter,
                            outletsCounter: r.data.returnobject.outletsCounter,
                            menusCounter: r.data.returnobject.menusCounter,
                            sectionsCounter: r.data.returnobject.sectionsCounter,
                            itemsOrModifiersCounter: r.data.returnobject.itemsOrModifiersCounter,
                            equipmentsCounter: r.data.returnobject.equipmentsCounter,
                            requestsCounter: r.data.returnobject.requestsCounter,
                            accountTicketFieldOptions: r.data.returnobject.accountTicketFieldOptions,
                            orderTicketFieldOptions: r.data.returnobject.orderTicketFieldOptions,
                            feedbackTicketFieldOptions: r.data.returnobject.feedbackTicketFieldOptions,
                            tagOptions: r.data.returnobject.outletTagOptions,
                            equipmentOptions: r.data.returnobject.equipmentOptions,
                            employeeOptions: r.data.returnobject.employeeOptions,
                            printersCounter: r.data.returnobject.printersCounter,
                            organizationSocialProfiles: r.data.returnobject.organizationSocialProfiles,
                            countries: r.data.returnobject.countryList,
                            organizationAdress: r.data.returnobject.organizationAdress,
                            organizationVatNumber: r.data.returnobject.organizationVatNumber,
                            counters: r.data.returnobject.counters
                        });
                    })
                    .then(() => {
                        setTicketTabValue(r.data.returnobject.emailNotificationActionOptions.length > 0 ? r.data.returnobject.emailNotificationActionOptions[0] : null);
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
            }).catch((e) => {
                // console.log(e);
            });
        }
    }

    /**
     * Gets called when the user clicks on the save button, and triggers 
     * the creating of the outlet.
     */
    function addOutlet() {
        if (formValidation()) {
            outletApi.create(data.outlet).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setAlertBox({
                            isOpen: true,
                            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                        });
                    })
                    .then(() => {
                        if (r.data.code === "SUCCESS")
                            navigate(`${path}/${r.data.returnobject.id}`);
                        // setRedirect((r.data.code === "SUCCESS" ? true : false));
                    })
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the modification of the outlet
     */
    function editOutlet() {
        if (formValidation()) {
            outletApi.update(data.outlet).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the removal of a tag.
     * @param {*} tagToDelete 
     */
    function removeTag(event, tagToDelete) {
        let tags = data.outlet.tags;
        const index = tags.indexOf(tagToDelete);
        // only splice array when item is found
        if (index > -1) tags.splice(index, 1); // 2nd parameter means remove one item only
        setData({
            ...data,
            outlet: {
                ...data.outlet,
                tags: tags
            }
        });
    }

    /**
     * Function that triggers form validation.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns the object with the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.name = data.outlet.name.en.length < 1 ? t('form.requiredField') : "";
        isError.url = data.outlet.url.length < 1 ? t('form.requiredField') : "";
        if (!data.outlet.vatDetails.inheritFromOrganization && data.outlet.vatDetails.vatNumber.length < 1)
            isError.vatNumber = t('form.requiredField');
        else if (!data.outlet.vatDetails.inheritFromOrganization && validateVatNumber(data.outlet.vatDetails.vatNumber) === null)
            isError.vatNumber = t('error.vatNumberFormatError');
        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Function to trigger the remove of the admin broker.
     * @property {*} event
     * @property {string} field The field of the selected outlet field.
     */
    function configureTranslations(event, field) {
        handleDialogState(true, field);
    }

    /**
     * Function that triggers the form value change.
     *
     * @param {type} event 
     */
    function formValChange(event) {
        event.preventDefault();
        // get input tag name and value
        let { name, value } = event.target;
        let isError = { ...data.isError };
        const objectName = name.split(".")[0];
        const fieldNested = name.split(".")[2] !== undefined ? name.split(".")[2] : "";
        name = name.split(".")[1];
        let oldFieldData = null;

        if (name in isError) {
            isError[name] = value.length < 1 ? "The value is required." : "";
        }

        if (fieldNested === "") {
            setData({
                ...data,
                [objectName]: {
                    ...data[objectName],
                    [name]: value
                },
                isError,
            })
        } else {
            let arrayIndex = 0;
            if (name.includes("[")) {
                arrayIndex = name.split("[")[1].split("]")[0];
                name = name.split("[")[0];
                oldFieldData = data[objectName][name];
                oldFieldData[arrayIndex][fieldNested] = value;
                setData({
                    ...data,
                    [objectName]: {
                        ...data[objectName],
                        [name]: oldFieldData
                    }
                })
            } else {
                setData({
                    ...data,
                    [objectName]: {
                        ...data[objectName],
                        [name]: {
                            ...data[objectName][name],
                            [fieldNested]: value
                        }
                    },
                    isError,
                })
            }
        }
    };

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
      * Function that handles the delete modal open or close state.
      * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
      * @property {string} field The field of the selected text translations to be removed.
      */
    function handleDialogState(isOpen, field = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            field: field,
            translations: data.item[field]
        });
    }

    /**
     * This function will trigger the outlet field translations configuration
     */
    function configureOutletTranslations(translations, field) {
        document.querySelectorAll(`[name="outlet.${field}.en"]`)[0].value = translations.en;
        setData({
            ...data,
            outlet: {
                ...data.outlet,
                [field]: translations
            }
        });
        setDialog({
            ...dialog,
            isOpen: false,
            field: "",
            translations: {}
        });
    }

    // ===================================== SOCIAL PROFILES
    function checkVatNumber(event) {
        let { name, value } = event.target;
        if (validateVatNumber(value) !== null) {
            let { countryCode, number } = validateVatNumber(value);

            vatApi.fetch(countryCode, number).then((r) => {
                if (r.data.code === "INVALID")
                    setData({
                        ...data,
                        isError: {
                            ...data.isError,
                            vatNumber: r.data.message
                        }
                    })
                else {
                    let addressDetails = (({ address, city, postalCode, country, name }) => ({ address, city, postalCode, country, officialVatName: name }))(r.data.returnobject.vatAddress);

                    // let addressDetails = (({ address, city, postalCode, country: { id } }) => ({ address, city, postalCode, id }))(r.data.returnobject.vatAddress);

                    setData({
                        ...data,
                        isError: {
                            ...data.isError,
                            vatNumber: ""
                        },
                        outlet: {
                            ...data.outlet,
                            addressDetails: addressDetails,
                            vatDetails: {
                                ...data.outlet.vatDetails,
                                vatNumber: value.toUpperCase()
                            }
                        }
                    })
                }
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        } else {
            setData({
                ...data,
                isError: {
                    ...data.isError,
                    vatNumber: t("error.vatNumberFormatError")
                }
            })
        }
    }

    // =================== ticket config
    /**
     * Update a specific ticket configuration details field for a given action.
     * 
     * @param {string} action - The action to update the ticket configuration.
     * @param {number} index - The index of the ticket configuration to be update.
     * @param {string} field - The ticket configuration field to be updated.
     * @param {string|number} value - iThe ticket configuration field value.
     */
    function handleTicketDetailFieldChange(action, index, field, value) {
        const emailConfigurations = data.outlet.emailConfigurations.map((ticket) => {
            if (field === "to" || field === "cc" || field === "bcc") {

                let newDetails = ticket.details;
                newDetails[index] = {
                    ...newDetails[index],
                    [field]: value.map(email => ({ email, isEnabled: true }))
                }

                return ticket.action === action
                    ? {
                        ...ticket,
                        details: newDetails
                    }
                    : ticket
            } else {
                let newDetails = ticket.details;
                newDetails[index] = {
                    ...newDetails[index],
                    [field]: value
                }

                return ticket.action === action
                    ? {
                        ...ticket,
                        details: newDetails
                    }
                    : ticket
            }
        });

        formValChangeWithParentElementWithNameAndValue("outlet.emailConfigurations", emailConfigurations, data, setData);
    }

    /**
     * Update a specific ticket configuration for a given action.
     * Note: for checkboxes only
     * 
     * @param {string} action - The action to update the ticket configuration.
     * @param {number} index - The index of the ticket configuration to be update.
     * @param {string} field - The ticket configuration field to be shown.
     * @param {boolean} value - if true include the value to field.
     */
    function handleTicketCheckboxFieldChange(action, actionInstanceIndex, field, value) {
        const tickets = data.outlet.emailConfigurations.map((ticket) =>
            ticket.action === action
                ? {
                    ...ticket,
                    // Use map to update the specific field
                    // we use a ternary operator to check whether the value is truthy (i.e., true). If it is, we include it in the array using the spread operator ([...showField[field], value]). Otherwise, we remove it from the array using the filter() method (showField[field].filter(val => val !== value)).
                    details: ticket.details.map((showField, i) =>
                        i === actionInstanceIndex
                            ? {
                                ...showField,
                                showFields: value ? [...showField["showFields"], field] : showField["showFields"].filter(val => val !== field),
                            }
                            : showField
                    ),
                }
                : ticket
        );

        formValChangeWithParentElementWithNameAndValue("outlet.emailConfigurations", tickets, data, setData);
    };

    /**
     * Adds a new ticket configuration for a given action.
     * 
     * @param {string} action - The action to add the new ticket configuration.
     */
    function handleAddTicketConfigurationForAction(action) {
        const matchingTicket = data.outlet.emailConfigurations.find((ticket) => ticket.action === action);
        if (matchingTicket) {
            const updatedTickets = data.outlet.emailConfigurations.map((ticket) =>
                ticket.action === action
                    ? {
                        ...ticket,
                        // Use map to update the specific field
                        details: [...ticket.details, getDefaultOutletTicketDetailObject(showFieldsBasedOnAction(action))]
                    }
                    : ticket
            );
            formValChangeWithParentElementWithNameAndValue("outlet.emailConfigurations", updatedTickets, data, setData);
        } else {
            formValChangeWithParentElementWithNameAndValue("outlet.emailConfigurations", [...data.outlet.emailConfigurations, getDefaultOutletTicketObject(action, showFieldsBasedOnAction(action))], data, setData);
        }
    }

    /**
     * Removes a specific ticket configuration for a given action.
     * 
     * @param {string} action - The action to remove the ticket configuration from.
     * @param {number} index - The index of the ticket configuration to remove.
     */
    function handleRemovalTicketConfigurationForAction(action, showFieldsIndex) {
        const tickets = data.outlet.emailConfigurations.filter((ticket) => {
            if (ticket.action === action) {
                //if action contained only the ticket to be removed -> remove ticket for action
                if (ticket.details.length === 1 && showFieldsIndex === 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }).map((ticket) => {
            if (ticket.action === action) {
                return {
                    ...ticket,
                    // Filter out the specific details index
                    details: ticket.details.filter((_, index) => index !== showFieldsIndex)
                };
            } else {
                return ticket;
            }
        });
        formValChangeWithParentElementWithNameAndValue("outlet.emailConfigurations", tickets, data, setData);
    }

    function showFieldsBasedOnAction(action) {
        switch (action) {
            case "ORDER":
                return data.orderTicketFieldOptions;
            case "ACCOUNT":
                return data.accountTicketFieldOptions;
            case "ANALYTIC":
                return [];
            case "INVENTORY":
                return data.inventoryTicketFieldOptions;
            case "FEEDBACK":
                return data.feedbackTicketFieldOptions;
            case "REPORT":
                return [];
        }
    }
    // ===================================== SOCIAL PROFILES

    function handleSocialProfileChange(index, field, value) {
        let newSocialProfiles = data.outlet.socialProfiles;
        newSocialProfiles.socialProfiles[index].url = value;
        formValChangeWithParentElementObjectValues("outlet.socialProfiles", newSocialProfiles, data, setData);
    }

    // ===============================================================================RENDER

    if (redirect) {
        navigate(path);
    } else if (!isLoaded) {
        return <LoadingSkeleton lines={9} />;
    } else {
        const { isError } = data;

        let defaultTagsSelected = [];
        if (data.outlet !== null) {
            defaultTagsSelected = data.outlet.tags;
        }

        return (
            // OUTLET ADD - EDIT
            <CustomGrid role="main" id={"outlet-" + data.isAdd ? "add" : "edit"} sx={mainTag()}>
                {organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` }
                            ]}
                            instanceName={(data.outlet !== null) ? data.outlet.name.en : ""}
                        />
                    </CustomGrid>
                }

                {organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('outlet.title'), url: `/${getLanguageFromURL()}/outlet` },
                            ]}
                            instanceName={(data.outlet !== null) ? data.outlet.name.en : ""}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    {!data.isAdd &&
                        <CustomIconButton onClick={() => navigate(`${path}/${data.outlet.id}/settings`)}
                            marginTop="-1%"
                            icon={<SettingsIcon />}
                            sx={{ borderRadius: "20px", minWidth: "40px" }}
                            iconSx={{ margin: 0 }}
                            tooltipTitle={t('menu.settings')}
                        />
                    }
                    <CustomAddEditTitle
                        isAdd={data.isAdd}
                        title={t('outlet.pageTitle')}
                        subtitle={t('outlet.pageTitle')}
                        instanceName={(data.outlet !== null) ? data.outlet.name.en : ""}
                    />
                </CustomGrid>

                {!data.isAdd &&
                    <CustomGrid >
                        <Stack direction="row" spacing={0} style={{ marginTop: "20px", placeContent: "center" }}>
                            <Grid container gap={2} sx={{ textAlign: "center", placeContent: "center" }}>
                                <CustomCard label={t('employee.title')} icon={GroupsIcon} totalNumber={data.employeesCounter !== undefined ? data.employeesCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/outlet/${outletID}/employee`} />
                                <CustomCard label={t('menus.title')} icon={ArticleIcon} totalNumber={data.menusCounter !== undefined ? data.menusCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/outlet/${outletID}/menu`} />
                                {/* <CustomCard label={t('itemOrModifier.title')} icon={FeaturedPlayListIcon} totalNumber={data.itemsOrModifiersCounter !== undefined ? data.itemsOrModifiersCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/outlet/${outletID}/item-or-modiffier`} /> */}
                                <CustomCard label={t('section.title')} icon={AppsIcon} totalNumber={data.sectionsCounter !== undefined ? data.sectionsCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/outlet/${outletID}/section`} />
                                <CustomCard label={t('equipment.title')} icon={TableRestaurantIcon} totalNumber={data.equipmentsCounter !== undefined ? data.equipmentsCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/outlet/${outletID}/equipment`} />
                                <CustomCard label={t('printer.title')} icon={PrintIcon} totalNumber={data.printersCounter !== undefined ? data.printersCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/outlet/${outletID}/printer`} />
                                <CustomCard label={t('request.title')} icon={WavingHandIcon} totalNumber={data.requestsCounter !== undefined ? data.requestsCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/outlet/${outletID}/request`} />
                                <CustomCard label={t('label.smss')} icon={SmsIcon} totalNumber={parseInt((data.counters?.sms ? data.counters.sms : 0))} />
                                <CustomCard label={t('label.emails')} icon={EmailIcon} totalNumber={parseInt(data.counters?.email ? data.counters?.email : 0)} />
                                <CustomCard label={t('label.ticketCounter')} icon={ReceiptIcon} totalNumber={parseInt(data.counters?.ticket ? data.counters.ticket : 0)} />
                                <CustomCard label={t('label.receiptTicketCounter')} icon={ReceiptIcon} totalNumber={parseInt(data.counters?.receiptTicket ? data.counters.receiptTicket : 0)} />
                            </Grid>
                        </Stack>
                    </CustomGrid>
                }

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="outlet" onSubmit={(e) => { return false; }}>
                        {data.isAdd &&
                            <input type="hidden" />
                        }

                        <input defaultValue={organizationID} name="organizationID" type="hidden" id="organizationID" />

                        {!data.isAdd && data.outlet !== null &&
                            <input defaultValue={outletID} name="id" type="hidden" id="id" />
                        }

                        <TabContext value={tabValue}>
                            <CustomTabs
                                initValue={tabValue}
                                instances={[
                                    { name: t('outlet.tabs.details'), value: "details" },
                                    { name: t('label.address'), value: "address" },
                                    { name: t('outlet.tabs.socialProfiles.label'), value: "socialProfiles" },
                                    { name: t('outlet.tabs.integrations.label'), value: "integrations" },
                                    { name: t('outlet.tabs.ticketConfiguration.label'), value: "ticketConfiguration" }
                                ]}
                                setValue={setTabValue}
                                sx={{ marginBottom: "50px", padding: 0 }}
                                scrollButtons="auto"
                            />
                            <TabPanel value={"details"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="name">{t('label.name')}</InputLabel>
                                        <CustomFlagTextField
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="outlet.name.en"
                                            id="name"
                                            onChange={formValChange}
                                            defaultValue={data.outlet.name.en}
                                            onTranslationSelect={configureTranslations}
                                            field="name"
                                            error={isError.name.length > 0 ? true : false}
                                            helperText={isError.name}
                                            sx={textFieldError()}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="description">{t('label.description')}</InputLabel>
                                        <CustomFlagTextField
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="outlet.description.en"
                                            id="description"
                                            onChange={formValChange}
                                            defaultValue={data.outlet.description.en}
                                            onTranslationSelect={configureTranslations}
                                            field="description"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="extraNote">{t('outlet.extraNote')}</InputLabel>
                                        <CustomFlagTextField country="en" flag={en}
                                            placeholder=""
                                            name="outlet.extraNote.en"
                                            id="extraNote"
                                            onChange={formValChange}
                                            defaultValue={data.outlet.extraNote.en}
                                            onTranslationSelect={configureTranslations}
                                            field="extraNote"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="vatNumber">{t('label.vatNumber')}</InputLabel>
                                        <FormControlLabel
                                            control={
                                                <Switch defaultChecked={data.outlet.vatDetails.inheritFromOrganization}
                                                    onChange={(event) => onControlChangeWithParent(event, data, setData, "outlet.vatDetails.inheritFromOrganization")} />
                                                // onChange={(event) => {onControlChangeWithParent(event, data, setData, "outlet.vatDetails.inheritFromOrganization"); if(event.target.checked) formValChangeWithParentElementWithNameAndValue("outlet.vatDetails.vatNumber", data.organizationVatNumber, data, setData);}} />
                                            }
                                            label={t('outlet.inheritFromOrganization')}
                                        />
                                        {!data.outlet.vatDetails.inheritFromOrganization &&
                                            <CustomTextField
                                                type="text"
                                                name="outlet.vatDetails.vatNumber"
                                                id="vatDetails.vatNumber"
                                                defaultValue={data.outlet.vatDetails.vatNumber}
                                                onChange={checkVatNumber}
                                                error={isError.vatNumber.length > 0 ? true : false}
                                                helperText={isError.vatNumber}
                                                sx={textFieldError()}
                                            />
                                        }
                                        {data.outlet.vatDetails.inheritFromOrganization &&
                                            <CustomTextField
                                                type="text"
                                                name="outlet.vatDetails.vatNumber"
                                                id="vatDetails.vatNumber"
                                                disabled={data.outlet.vatDetails.inheritFromOrganization}
                                                defaultValue={data.organizationVatNumber}
                                            />
                                        }
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="email">{t('outlet.email')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="outlet.email"
                                            id="email"
                                            required={true}
                                            defaultValue={data.outlet.email}
                                            onChange={formValChange}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="url">{t('outlet.url')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="outlet.url"
                                            id="url"
                                            required={true}
                                            defaultValue={data.outlet.url}
                                            onChange={formValChange}
                                            error={isError.url.length > 0 ? true : false}
                                            helperText={isError.url}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="thirdPartyPosId">{t('outlet.thirdPartyPosId')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="outlet.thirdPartyPosId"
                                            id="thirdPartyPosId"
                                            defaultValue={data.outlet.thirdPartyPosId}
                                            onChange={formValChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="telephones">{t('label.telephone')}</InputLabel>
                                        <MuiChipsInput
                                            id="telephones"
                                            value={data.outlet.telephones}
                                            required={true}
                                            allowDuplicates={false}
                                            style={{ width: "100%" }}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.telephones", event, data, setData, "")}
                                        />
                                    </Grid>

                                    {/* 
                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="tags">{t('label.tags')}</InputLabel>
                                        <CustomSelectWithChipMultilingual
                                            id="tags"
                                            name="outlet.tags"
                                            translationPath="outlet.tags"
                                            defaultValue={defaultTagsSelected}
                                            options={data.tagOptions}
                                            onChange={(event) => onSelectChange(event, data, setData)}
                                            onDelete={removeTag}
                                        />
                                    </Grid> */}

                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    defaultChecked={data.outlet.isActive}
                                                    onChange={(event) => onControlChangeWithParent(event, data, setData, "outlet.isActive")} />
                                            }
                                            label={t('label.isActive')} />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"address"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={3}>
                                        <InputLabel required={false} htmlFor="address">{t('label.address')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="outlet.addressDetails.address"
                                            placeholder="Ermou 5"
                                            id="address"
                                            defaultValue={data.outlet.addressDetails.address}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))} />
                                    </Grid>

                                    <Grid item xs={12} sm={2.5}>
                                        <InputLabel required={false} htmlFor="city">{t('label.city')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="outlet.addressDetails.city"
                                            id="city"
                                            defaultValue={data.outlet.addressDetails.city}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))} />
                                    </Grid>

                                    <Grid item xs={12} sm={1.5}>
                                        <InputLabel required={false} htmlFor="postalCode">{t('label.postalCode')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="outlet.addressDetails.postalCode"
                                            id="postalCode"
                                            defaultValue={data.outlet.addressDetails.postalCode}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <InputLabel required={false} htmlFor="state">{t('label.state')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="outlet.addressDetails.state"
                                            id="state"
                                            defaultValue={data.outlet.addressDetails.state}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))} />
                                    </Grid>

                                    <Grid item xs={12} sm={2}>
                                        <InputLabel required={false} htmlFor="country">{t('label.country')}</InputLabel>
                                        <CustomObjectSelect
                                            name="outlet.addressDetails.country.id"
                                            value={data.outlet.addressDetails.country !== null ? data.outlet.addressDetails.country.id : data.countries.find((country) => country.alpha2 === "GR").id}
                                            options={data.countries}
                                            onChange={(event) => onSelectChange(event, data, setData)}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>


                            <TabPanel value={"integrations"} sx={{ paddingTop: "0px" }}>
                                <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                                    <CustomFieldset
                                        label={t('outlet.tabs.integrations.vivaWalletConfiguration.label')}
                                        children={<>
                                            <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "5px" }}>
                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="appSourceCode">{t('outlet.tabs.integrations.vivaWalletConfiguration.appSourceCode')}</InputLabel>
                                                    <CustomTextField
                                                        type="text"
                                                        name="outlet.vivaWalletConfiguration.appSourceCode"
                                                        id="appSourceCode"
                                                        defaultValue={data.outlet?.vivaWalletConfiguration.appSourceCode}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                                {/* <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="clientId">{t('outlet.tabs.integrations.vivaWalletConfiguration.clientId')}</InputLabel>
                                                    <CustomTextField
                                                        type="text"
                                                        name="outlet.vivaWalletConfiguration.clientId"
                                                        id="clientId"
                                                        defaultValue={data.outlet?.vivaWalletConfiguration.clientId}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="clientSecret">{t('outlet.tabs.integrations.vivaWalletConfiguration.clientSecret')}</InputLabel>
                                                    <CustomTextFieldWithIcons
                                                        type={data.secretShown ? "text" : "password"}
                                                        name="outlet.vivaWalletConfiguration.clientSecret"
                                                        id="clientSecret"
                                                        autoComplete="off"
                                                        defaultValue={data.outlet?.vivaWalletConfiguration.clientSecret}
                                                        endIcon={<SvgIcon component={data.shownSecretIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="merchantId">{t('outlet.tabs.integrations.vivaWalletConfiguration.merchantId')}</InputLabel>
                                                    <CustomTextField
                                                        type="text"
                                                        name="outlet.vivaWalletConfiguration.merchantId"
                                                        id="merchantId"
                                                        defaultValue={data.outlet?.vivaWalletConfiguration.merchantId}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="apiKey">{t('outlet.tabs.integrations.vivaWalletConfiguration.apiKey')}</InputLabel>
                                                    <CustomTextField
                                                        type="text"
                                                        name="outlet.vivaWalletConfiguration.apiKey"
                                                        id="apiKey"
                                                        defaultValue={data.outlet?.vivaWalletConfiguration.apiKey}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </>}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                                    <CustomFieldset
                                        label={t('outlet.tabs.integrations.googleReview.label')}
                                        children={<>
                                            <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "5px" }}>
                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="appSourceCode">{t('outlet.tabs.integrations.googleReview.locationID')}</InputLabel>
                                                    <CustomTextFieldWithIcons
                                                        type="text"
                                                        name="outlet.googleConfiguration.locationID"
                                                        id="locationID"
                                                        defaultValue={data.outlet?.googleConfiguration?.locationID}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData)}
                                                        endIcon={
                                                            <>
                                                                <InfoIcon onClick={() => setOpen(!open)} />

                                                                <GoogleReviewPopover
                                                                    open={open}
                                                                    anchorEl={anchorEl}
                                                                    handleClose={() => setOpen(false)}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right',
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>}
                                    />
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"ticketConfiguration"} sx={{ paddingTop: "0px" }}>
                                <TabContext value={ticketTabValue}>
                                    <CustomTabs
                                        initValue={ticketTabValue}
                                        instances={data.emailNotificationActionOptions.map((action) => ({ name: t(`outlet.tabs.ticketConfiguration.${action}`), value: action }))}
                                        setValue={setTicketTabValue}
                                        scrollButtons={true}
                                        sx={{ marginBottom: "20px", margin: 0 }}
                                        centered={false}
                                    />

                                    {data.emailNotificationActionOptions.map((action) =>
                                        <TabPanel value={action} sx={{ paddingTop: "0px" }}>
                                            <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                                <Grid item xs={12} md={12}>
                                                    {data.outlet.emailConfigurations.find((ticket) => ticket.action === action) !== undefined ? data.outlet.emailConfigurations.find((ticket) => ticket.action === action).details.map((ticketConfigOfAction, actionInstanceIndex) => (
                                                        <>
                                                            <Grid container spacing={2} sx={ticketGridStyle()}>
                                                                <Grid item xs={12} md={12} sx={deleteButtonTicketStyle()}>
                                                                    <CustomIconButton
                                                                        keyValue={`btn-delete-${action}-${actionInstanceIndex}`}
                                                                        onClick={(event) => handleRemovalTicketConfigurationForAction(action, actionInstanceIndex)}
                                                                        icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                        iconSx={{ marginRight: "0px!important" }}
                                                                        sx={{
                                                                            backgroundColor: "red",
                                                                            ":hover": {
                                                                                backgroundColor: "darkred",
                                                                            },
                                                                            ...cardItemButtonGroupStackButton(),
                                                                            marginTop: "-30px"
                                                                        }}
                                                                    >
                                                                        {t('actions.delete')}
                                                                    </CustomIconButton>
                                                                </Grid>

                                                                <Grid item xs={12} md={12}>
                                                                    <CustomTextField
                                                                        type="text"
                                                                        name="subject"
                                                                        InputProps={{
                                                                            startAdornment: <span> {`${t('email.subject')}:`}</span>,
                                                                        }}
                                                                        defaultValue={ticketConfigOfAction.subject}
                                                                        onChange={(event) => handleTicketDetailFieldChange(action, actionInstanceIndex, "subject", event.target.value)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={12}>
                                                                    <CustomChipEmailInput
                                                                        id="to"
                                                                        name="ticketConfigOfAction.to"
                                                                        label={t("email.to")}
                                                                        allowDuplicates={false}
                                                                        value={ticketConfigOfAction.to}
                                                                        validateChip={isValidEmail}
                                                                        onChange={(event) => handleTicketDetailFieldChange(action, actionInstanceIndex, "to", event)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={12}>
                                                                    <CustomChipEmailInput
                                                                        id="cc"
                                                                        name="ticketConfigOfAction.cc"
                                                                        label={t("email.cc")}
                                                                        allowDuplicates={false}
                                                                        value={ticketConfigOfAction.cc}
                                                                        validateChip={isValidEmail}
                                                                        onChange={(event) => handleTicketDetailFieldChange(action, actionInstanceIndex, "cc", event)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={12}>
                                                                    <CustomChipEmailInput
                                                                        id="bcc"
                                                                        name="ticketConfigOfAction.bcc"
                                                                        label={t("email.bcc")}
                                                                        allowDuplicates={false}
                                                                        value={ticketConfigOfAction.bcc}
                                                                        validateChip={isValidEmail}
                                                                        onChange={(event) => handleTicketDetailFieldChange(action, actionInstanceIndex, "bcc", event)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={12}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                defaultChecked={ticketConfigOfAction.isEnabled}
                                                                                onChange={(event) => handleTicketDetailFieldChange(action, actionInstanceIndex, "isEnabled", event.target.checked)} />
                                                                        }
                                                                        label={t('label.isEnabled')} />
                                                                </Grid>

                                                                <Grid item xs={12} md={12} sx={{ marginTop: "5px" }}>
                                                                    <CustomFieldset
                                                                        label={t('outlet.tabs.ticketConfiguration.field.labels')}
                                                                        children={<>
                                                                            {showFieldsBasedOnAction(action).map((field, index) =>
                                                                                <Grid item xs={12} md={6} sx={{ paddingTop: "0 !important" }}>
                                                                                    <CustomCheckbox
                                                                                        name={`ticket[${index}].details.showFields[${field}]`}
                                                                                        onChange={(event) => handleTicketCheckboxFieldChange(action, actionInstanceIndex, field, event.target.checked)}
                                                                                        selected={ticketConfigOfAction.showFields.includes(field)}
                                                                                        label={t(`outlet.tabs.ticketConfiguration.field.${field}`)}
                                                                                    />
                                                                                </Grid>
                                                                            )}
                                                                        </>
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ))
                                                        : <></>
                                                    }
                                                </Grid>

                                                <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                    <CustomIconButton
                                                        sx={addCardActionAreaButtonStyle()}
                                                        backgroundColor="transparent"
                                                        icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                                        onClick={() => handleAddTicketConfigurationForAction(action)}>
                                                        Add
                                                    </CustomIconButton>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </TabPanel>
                                    )}
                                </TabContext>

                            </TabPanel>

                            <TabPanel value={"socialProfiles"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={
                                                <Switch defaultChecked={data.outlet.socialProfiles.inheritFromOrganization}
                                                    onChange={(event) => onControlChangeWithParent(event, data, setData, "outlet.socialProfiles.inheritFromOrganization")} />
                                            }
                                            label={t('outlet.inheritFromOrganization')} />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        {!data.outlet.socialProfiles.inheritFromOrganization &&
                                            <CustomButton
                                                variant='filled'
                                                sx={{ backgroundColor: "#54b1d2", color: "white", textTransform: "initial", fontSize: "12px" }}
                                                sxHover={{ backgroundColor: "#34809b", color: "white", textTransform: "initial", fontSize: "12px" }}
                                                label={t("outlet.tabs.socialProfiles.copyFromOrg")}
                                                onClick={() => formValChangeWithParentElementObjectValues("outlet.socialProfiles", { ...data.outlet.socialProfiles, socialProfiles: data.organizationSocialProfiles }, data, setData)}
                                            />
                                        }
                                    </Grid>

                                    {data.outlet.socialProfiles?.inheritFromOrganization && data.organizationSocialProfiles?.map((socialProfileType, index) =>
                                        <Grid item xs={12} md={6}>
                                            <InputLabel required={false} htmlFor={socialProfileType.name}>{t(`socialProfile.${socialProfileType.name}`)}</InputLabel>
                                            <CustomTextFieldWithIcons
                                                type="text"
                                                defaultValue={socialProfileType.url}
                                                disabled={true}
                                                endIcon={getSocialFontAwesomeIcon(t(`socialProfile.${socialProfileType.name}`), socialProfileType.url)}
                                            />
                                        </Grid>
                                    )}

                                    {(!data.outlet.socialProfiles?.inheritFromOrganization) &&
                                        data.outlet.socialProfiles.socialProfiles?.map((socialProfileType, index) =>
                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor={socialProfileType.name}>{t(`socialProfile.${socialProfileType.name}`)}</InputLabel>
                                                <CustomTextFieldWithIcons
                                                    type="text"
                                                    value={socialProfileType.url}
                                                    placeholder={t(`outlet.tabs.socialProfiles.info`)}
                                                    endIcon={getSocialFontAwesomeIcon(t(`socialProfile.${socialProfileType.name}`), socialProfileType.url)}
                                                    onChange={(event) => handleSocialProfileChange(index, socialProfileType.name, event.target.value)}
                                                />
                                            </Grid>
                                        )}
                                </Grid>
                            </TabPanel>
                        </TabContext>

                        <Grid container spacing={2}>
                            <Grid item>
                                <CustomIconButton
                                    variant="outlined"
                                    keyValue="save"
                                    onClick={data.isAdd ? addOutlet : editOutlet}
                                    label={t('actions.save')}
                                    sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                />
                            </Grid>

                            {data.isAdd &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        link={path}
                                        label={t('actions.back')}
                                        sx={formSaveButton()}
                                    />
                                </Grid>
                            }

                            {!data.isAdd &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        onClick={getOutlet}
                                        label={t('actions.cancel')}
                                        sx={formSaveButton()}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={data.isAdd ? addOutlet : editOutlet}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {dialog.isOpen &&
                    <CustomTranslationsDialog
                        isOpen={dialog.isOpen}
                        id="translations"
                        title={t('actions.translationModal.title')}
                        action={configureOutletTranslations}
                        handleOpen={handleDialogState}
                        translations={data.item[dialog.field]}
                        field={dialog.field} />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default OutletAddEditComponent;