import { Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, Typography } from "@mui/material";
import { DeleteIcon } from "assets/mui/MuiIcons";
import CustomTypography from "components/Typography/CustomTypography";
import { getDefaultChatChannel } from "constants/defaultObjects";
import { MuiColorInput } from "mui-color-input";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formValChangeWithParentElementWithNameAndValue } from "utils/form-validation";
import formatBytes from "utils/formatBytes";

/**
 * Add Chat-Channel Dialog Content.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AddChatChannelDialogContent({ addChatChannel }) {
    const { t } = useTranslation();

    const [addChannelDialogData, setAddChannelDialogData] = useState(getDefaultChatChannel());

    return (
        <Grid container sx={{ padding: "5px 20px" }}>
            <Grid item xs={12}>
                <CustomTypography variant='body1' text={t('chat.channel.name')} />
                <div style={{ height: "4px" }} />
                <TextField
                    name='Name'
                    id='Name'
                    placeholder={t('chat.channel.placeHolderName')}
                    required
                    fullWidth
                    //size="small"
                    variant='outlined'
                    value={addChannelDialogData.name}
                    onChange={(e) => {
                        setAddChannelDialogData((d) => {
                            return {
                                ...d,
                                name: e.target.value,
                            };
                        });
                    }}
                />
            </Grid>

            <Grid item xs={12} sx={{ mt: "20px" }}>
                <CustomTypography variant='body1' text={t('chat.channel.color')} />
                <MuiColorInput
                    id="color"
                    name="color"
                    format="hex8"
                    value={addChannelDialogData.color}
                    sx={{ width: "100%" }}
                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("color", value, addChannelDialogData, setAddChannelDialogData)}
                />
            </Grid>

            <Grid item xs={12} sx={{ mt: "20px" }}>
                <CustomTypography variant='body1' text={t('chat.channel.image')} />
                <div style={{ height: "8px" }} />
                {addChannelDialogData.image ? (
                    <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={1}
                    >
                        <Grid item>
                            <Grid container alignItems='center' gap={"10px"}>
                                <Grid item>
                                    <img
                                        src={
                                            typeof addChannelDialogData.image === "object"
                                                ? `${URL.createObjectURL(
                                                    addChannelDialogData.image
                                                )}`
                                                : `${addChannelDialogData.image}`
                                        }
                                        style={{
                                            width: "56px",
                                            height: "56px",
                                            padding: "1px",
                                            border: "3px solid rgba(223,239,248)",
                                            borderRadius: "4px",
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant='small'
                                        sx={{
                                            color: "#4a4c50",
                                            fontSize: "12px",
                                            display: "block",
                                        }}
                                    >
                                        {typeof addChannelDialogData.image === "object"
                                            ? addChannelDialogData.image.name
                                            : addChannelDialogData.image}
                                    </Typography>
                                    <Typography
                                        variant='small'
                                        sx={{
                                            color: "#D8D8D8",
                                            fontSize: "12px",
                                            display: "block",
                                        }}
                                    >
                                        {formatBytes(addChannelDialogData.imageSize)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <IconButton
                                sx={{ p: 0 }}
                                onClick={() => {
                                    setAddChannelDialogData((r) => {
                                        return {
                                            ...r,
                                            image: null,
                                            imageSize: "",
                                        };
                                    });
                                }}
                            >
                                <DeleteIcon sx={{ fill: "red" }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <input
                            accept='image/*'
                            style={{ display: "none" }}
                            id='image'
                            type='file'
                            onChange={(e) => {
                                if (!e.target.files && !e.target.files[0]) {
                                    return;
                                }
                                setAddChannelDialogData((r) => {
                                    return {
                                        ...r,
                                        image: e.target.files[0],
                                        imageSize: e.target.files[0].size,
                                    };
                                });
                            }}
                        />
                        <label htmlFor='image' style={{ width: "100%" }}>
                            <Button
                                size='small'
                                component='span'
                                variant='contained'
                                fullWidth
                                sx={{
                                    fontSize: "14px",
                                    background: "rgb(18,136,73)",
                                    color: "#fff",
                                    borderRadius: "0.35rem",
                                    fontWeight: 600,
                                    p: "10px 16px",
                                }}
                            >
                                {t('chat.channel.uploadImage')}
                            </Button>
                        </label>
                    </Grid>
                )}
            </Grid>

            <Grid item xs={12} sx={{ mt: "20px" }}>
                <FormControlLabel
                    label={t('chat.channel.urgent')}
                    control={
                        <Checkbox
                            size='small'
                            checked={addChannelDialogData.urgent}
                            onChange={(e) =>
                                setAddChannelDialogData((d) => {
                                    return {
                                        ...d,
                                        urgent: e.target.checked,
                                    };
                                })
                            }
                        />
                    }
                />
            </Grid>

            <Grid item xs={12} sx={{ mt: "20px" }}>
                <Grid container justifyContent='center' spacing={2}>
                    <Grid item>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                setAddChannelDialogData({
                                    name: "",
                                    image: null,
                                    imageSize: "",
                                    color: "",
                                    urgent: false,
                                });
                            }}
                        >
                            {t('actions.cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' onClick={() => addChatChannel(addChannelDialogData)}>
                            {t('actions.add')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddChatChannelDialogContent;
