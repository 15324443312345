import { TextField, Typography } from "@mui/material";
import React from "react";

function RatingFreeText({ question, onChange }) {
  const onUpdate = (newValue) => {
    onChange({
      ...question,
      answer: newValue,
    });
  };

  const getValue = (val) => {
    if (typeof val === "string") {
      return val;
    }
    return "";
  };

  const inputSx = {
    mt: "4px",
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.13)",
    },
  };

  return (
    <>
      <TextField
        multiline
        minRows={4}
        placeholder={question.promptText}
        fullWidth
        variant='outlined'
        sx={inputSx}
        inputProps={
          !isNaN(question.maxAllowedCharacters) &&
            question.maxAllowedCharacters !== ""
            ? { maxLength: question.maxAllowedCharacters }
            : {}
        }
        value={getValue(question.answer)}
        onChange={(e) => onUpdate(e.target.value)}
      />
      {!isNaN(question.maxAllowedCharacters) &&
        question.maxAllowedCharacters !== "" && (
          <Typography variant='body2' align='right' sx={{ marginTop: "4px" }}>
            {getValue(question.answer).length} / {question.maxAllowedCharacters}
          </Typography>
        )}
    </>
  );
}

export default RatingFreeText;