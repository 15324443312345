import DehazeIcon from '@mui/icons-material/Dehaze';
import DeleteIcon from '@mui/icons-material/Delete';
import HeightIcon from '@mui/icons-material/Height';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { createTheme, Divider, Grid, ListItem, ListItemIcon, ListItemText, ThemeProvider, Tooltip } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { openInNewTab } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";

/**
 * A Custom Draggable List Item.
 * @property {object} item a specific modifier-list object
 * @property {int} index modifier-list item index
 * @property {*} removeEquipment function that removes the modifier-list instance
 * @property {*} save function that updates a modifier-list details
 * @param {boolean} enableDraggable Has value true when draggable icon is visible.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomEquipmentDraggableTableItem({ item, index, removeEquipment, save, enableDraggable, organizationID }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiInputAdornment: {
                    styleOverrides: {
                        root: {
                            fontWeight: 600,
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            maxHeight: "50px"
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Draggable draggableId={item.id} index={index} key={item.id}>
                {(provided, snapshot) => (
                    <>
                        {index !== 0 &&
                            <Divider style={{ width: "85%", marginLeft: "100px" }} />
                        }
                        <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            sx={{
                                background: snapshot.isDragging ? 'rgb(235,235,235)' : '',
                                width: "95.5%",
                                borderBottom: "1px",
                                userSelect: "none",
                                padding: "20px",
                                borderRadius: "4px",
                                ...provided.draggableProps.style,
                                height: "20px"
                            }}
                        >
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1}>
                                    <ListItemIcon sx={{ visibility: (enableDraggable) ? "visible" : "hidden" }}  {...provided.dragHandleProps}>
                                        <HeightIcon style={{ fontSize: "19px", color: "lightgrey" }} />
                                        <DehazeIcon style={{ marginRight: 15, marginLeft: "-6px", fontSize: "20px", width: "15px", color: "lightgrey", paddingTop: "1px" }} />
                                    </ListItemIcon>
                                </Grid>

                                <Grid item xs={7}>
                                    <ListItemText primary={item.label} />
                                </Grid>

                                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                    <Grid container spacing={1} alignItems="right" sx={{justifyContent: "right"}}>
                                        <Grid item>
                                            {/* {item.prices !== undefined && <CustomTypography text={`€${parseFloat(item.prices.find(i => i.priceListType === 'STANDARD').price).toFixed(2)}`} variant="body2" sx={{ display: "contents", bottom: 0 }} />} */}
                                            <Tooltip arrow title={t("actions.view")}><VisibilityIcon style={{ color: "#205d7b", fontSize: "20px", marginLeft: "40px" }} onClick={() => openInNewTab(`/${getLanguageFromURL()}/equipment/${item.id}`)} /></Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip arrow title={t("actions.remove")}><DeleteIcon style={{ color: "#205d7b", fontSize: "20px", marginLeft: "30px", marginRight: "20px" }} onClick={((event) => removeEquipment(event, item))} /></Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </>
                )}
            </Draggable>
        </ThemeProvider>
    );
}

export default CustomEquipmentDraggableTableItem;