import CloseRounded from '@mui/icons-material/CloseRounded';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from '@mui/material';
import React, { useState } from 'react';

/**
 * The CustomDialog, that display a customized dialog
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {string} title the dialog title
 * @property {string} message the dialog message
 * @property {string} cancelLabel the dialog cancel label
 * @property {string} actionLabel the dialog action label
 * @property {function} action the function that handles the dialog action button click
 * @property {function} handleOpen the function that handles the dialog open state
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomDialog({ isOpen = false, id = "", title, message, cancelLabel, actionLabel, action, handleOpen, boldMessage, actionColor = "#3593D2" }) {
    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);

    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpen) {
        setOpen(isOpen);
        handleOpen(isOpen);
    }

    return (
        <Dialog
            id={id}
            open={open}
            onClose={() => { handleDialogState(false) }}
            aria-describedby="alert-dialog-slide-description"
            className="dialog-title"
        >
            <DialogTitle className="dialog-headers">
                {title}
                <IconButton onClick={() => { handleDialogState(false) }} sx={{ float: "right" }}>
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ background: "white" }}>
                <DialogContentText id="alert-dialog-slide-description" className="dialog-desciption">
                    {message}
                    {boldMessage !== undefined &&
                        boldMessage
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button className="dialog-cancel-btn" onClick={() => { handleDialogState(false) }}>{cancelLabel}</Button>
                <Button className="dialog-action-btn" sx={{ color: actionColor, ":hover": { backgroundColor: actionColor + "52" } }} onClick={() => { action() }}>{actionLabel.toUpperCase()}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CustomDialog;