import { Fab, Grid, Tooltip } from '@mui/material';
import sectionApi from 'api/section';
import { AddIcon } from 'assets/mui/MuiIcons';
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from "components/Dialogs/CustomDialog";
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from 'components/Title/CustomTitle';
import { getDefaultSubscriptionPlanSortDirection } from 'constants/sortDirection';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagTitle } from 'themes/defaultThemes';
import { hasRoleAdmin } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';
import { actionFormatter, dateFormatter, fieldFormatter, iconFormatter, idFormatterLinkWithParent, nameFormatterLinkWithParentMultilingual } from 'utils/table';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The SectionListComponent, that allow the section to view the organization/outlet sections list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SectionListComponent() {
   const { t } = useTranslation();
   const { organizationID } = useParams();
   const { outletID } = useParams();

   const navigate = useNavigate();
   let path = `/${getLanguageFromURL()}`
   if (organizationID !== undefined) path += `/organization/${organizationID}`;
   if (outletID !== undefined) path += `/outlet/${outletID}`;
   path += `/section`;

   //change document title
   useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('section.pageTitle')}`);

   /**
    * @type {object}
    * @property {object} userAuth the authenticated section infromation
    * @property {array} sections the sections (pageable)
    * @property {object} organization tha organization basic details (id and name)
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
    */
   const [data, setData] = useState({
      userAuth: null,
      organization: {
         id: organizationID,
         name: ""
      },
      outlet: {
         id: outletID,
         name: ""
      },
      organizations: [],
      sections: [],
      pagination: {
         count: -1,
         size: 10,
         page: 0,
         ...getDefaultSubscriptionPlanSortDirection
      }
   });

   // to perform initial request
   const [isFirstTime, setIsFirstTime] = useState(true);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);
   // for search
   const [search, setSearch] = useState({
      organizationID: organizationID,
      outletID: outletID ? [outletID] : undefined,
      name: ""
   });

   // pop dialog for delete
   const [deleteDialog, setDeleteDialog] = useState({
      isOpen: false,
      id: "",
      name: ""
   });

   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });

   useEffect(() => {
      getSections();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      getSections();
      setIsFirstTime(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const identifier = setTimeout(() => {
         if (!isFirstTime) getSections();
      }, 700);

      return () => {
         clearTimeout(identifier);
      }
   }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])


   /**
    * The rest endpoint to get the sections list.
    */
   function getSections() {
      sectionApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
         Promise.resolve()
            .then(() => {
               setData({
                  ...data,
                  userAuth: r.data.returnobject.userAuth,
                  sections: r.data.returnobject.sections,
                  organization: r.data.returnobject.organization,
                  organizations: r.data.returnobject.organizations,
                  outlet: r.data.returnobject.outlet,
                  outlets: r.data.returnobject.outlets,
                  pagination: {
                     ...data.pagination,
                     count: r.data.returnobject.sections.totalPages,
                     size: r.data.returnobject.sections.size,
                     page: r.data.returnobject.sections.number
                  }
               });
            })
            .then(() => {
               setIsLoaded(true);
            })
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Function that triggers the removal of the selected section form.
    * 
    * @property {*} event
    * @property {string} sectionID The id of the selected section to be removed.
    */
   function deleteSection(event, sectionID, name) {
      handleDialogState(true, sectionID, name);
   }

   /**
    * Gets called to remove the selected section
    */
   function deleteS() {
      sectionApi.deleteById(deleteDialog.id).then((r) => {
         handleDialogState(false);
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         getSections();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Callback that handles the delete dialog isOpen state.
    * 
    * @property {boolean} isOpen The state of the dialog open status.
    * @property {string} sectionID The id of the selected section form to be removed.
    * @property {string} name The name of the selected section form to be removed.
    */
   function handleDialogState(isOpen, sectionID = "", name = "") {
      setDeleteDialog({
         ...deleteDialog,
         isOpen: isOpen,
         name: name,
         id: sectionID
      });
   }

   /**
    * Function that handles the snackbar open or close data.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }

   /**
    * Function that handles the pagination information.
    * @property {int} page the page to be redirected
    */
   function handlePaginationChange(page) {
      formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
   }

   /**
    * Function that handles the pagination information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleRowsPerPageChange(size) {
      let pagination = data.pagination;
      pagination.size = size;
      pagination.page = 0;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }
   /**
    * Function that handles the sort information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleColumnSortChange(changedColumn, direction) {
      let pagination = data.pagination;
      pagination.sort = changedColumn;
      pagination.direction = direction;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }
   /**
      * Gets called to activate the selected subscription plan
      * 
      * @param {*} event
      * @param {string} id the id of the subscription plan
      */
   function enableSection(event, id) {
      console.log(id)
      sectionApi.activate(id).then((r) => {
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getSections();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected subscription plan
    * @param {*} event
    * @param {string} id the id of the subscription plan 
    */
   function disableSection(event, id) {
      sectionApi.disable(id).then((r) => {
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getSections();
      }).catch((e) => {
         // console.log(e);
      })
   }

   // ===============================================================================RENDER
   if (!isLoaded) {
      return <CustomGrid role="main" id="section-list" sx={mainTag()}>
         <LoadingSkeleton lines={9} />
      </CustomGrid>
   } else {
      let breadcrumbs = [];
      if (outletID !== undefined && data.outlet !== null)
         breadcrumbs.push(
            { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
            { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
            { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
            { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
         );
      else if (organizationID !== undefined && data.organization !== null)
         breadcrumbs.push(
            { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
            { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
         );

      /**
       * datatable columns.
       */
      const table_columns = [

         {
            name: "active",
            label: t('label.isActive'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return iconFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     {
                        enable: (event, id) => enableSection(event, id),
                        disable: (event, id) => disableSection(event, id),
                        positionOfId: 1
                     });
               }
            }
         },
         {
            name: "id",
            label: t('label.id'),
            options: {
               viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
               filter: false,
               sort: false,
               empty: false,
               display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
               customBodyRender: (value, tableMeta) => {
                  return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
               }
            }
         },
         {
            name: "name",
            label: t('section.name'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('label.name')}: ${v}`,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return nameFormatterLinkWithParentMultilingual(value, tableMeta.tableData[tableMeta.rowIndex], path, "", "en", 1);
               }
            }
         },
         {
            name: "equipments",
            label: t('section.attachEquipments'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return fieldFormatter(value !== undefined ? value.length : 0);
               }
            }
         },
         {
            name: "dateCreated",
            label: t('label.dateCreated'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, "DD/MM/yyyy");
               }
            }
         },
         {
            name: "lastActivity",
            label: t('label.lastActivity'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, "DD/MM/yyyy");
               }
            }
         },
         {
            name: "",
            label: t('actions.label'),
            options: {
               filter: false,
               sort: false,
               empty: true,
               customBodyRender: (value, tableMeta) => {
                  return actionFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     1,
                     null,
                     null,
                     null,
                     2,
                     path,
                     data.showLabel ? t('actions.edit') : undefined,
                     (event, rowIndex, fullName) => deleteSection(event, rowIndex, fullName),
                     data.showLabel ? t('actions.delete') : undefined,
                     path
                  )
               },
               setCellProps: () => ({ className: "click action" })
            }
         }
      ];

      return (
         <CustomGrid role="main" id="section-list" sx={mainTag()}>
            {breadcrumbs.length > 0 &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomBreadcrumbMultiParent
                     parents={breadcrumbs}
                     instanceName={t('section.pageTitle')}
                  />
               </CustomGrid>
            }
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
               <Grid item xs={"auto"}>
                  <CustomRefreshButton onClick={getSections} marginTop="-1%" />
               </Grid>

               <Grid item xs={11} sm={3}>
                  <CustomGrid sx={mainTagTitle()}>
                     <CustomTitle
                        title={t('section.title')}
                        subtitle={t('section.subtitle')}
                     />
                  </CustomGrid>
               </Grid>

               <Grid item xs={12} sm={8}>
                  <CustomGrid sx={mainTagSearch()} direction="column" container={false} spacing={2}>

                     {/* <CustomTextField
                        type="search"
                        name="name"
                        id="name"
                        variant="filled"
                        label={t('actions.search') + " by " + t('label.name')}
                        defaultValue={search.name}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end" >
                                 <CustomSearchButton onClick={getSections} />
                              </InputAdornment>
                           ),
                        }}
                        required={false}
                        sx={mainTagSearchItem()}
                        onChange={(event) => formValChangeWithParentElementSelect(event, search, setSearch, t('table.valueReuired'))}
                     /> */}
                  </CustomGrid>
               </Grid>
            </Grid>

            <CustomGrid sx={mainAreaTable()} container={false}>
               <CustomPageableTable
                  instances={data.sections}
                  emptyMessage={t('table.noData') + t('actions.tableCreateNewLink') + t('section.pageTitle')}
                  table_columns={table_columns}
                  keyField="dateCreated"
                  defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                  pagination={data.pagination}
                  handlePaginationChange={handlePaginationChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handleColumnSortChange={handleColumnSortChange}
                  filter={true}
                  onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                     ...search,
                     name: ""
                  })}
               />

               <Fab
                  color="primary"
                  aria-label="add"
                  sx={fabStyle()}
                  onClick={() => navigate(`/${getLanguageFromURL()}/section/add`)}
               >
                  <Tooltip arrow title={t('actions.add') + " " + t('section.pageTitle')} sx={fabTooltipStyle()}>
                     <AddIcon {...fabStyleIcon()} />
                  </Tooltip>
               </Fab>
            </CustomGrid>

            {deleteDialog.isOpen &&
               <CustomDialog
                  isOpen={deleteDialog.isOpen}
                  id="delete"
                  title={t('actions.deleteModal.title')}
                  actionLabel={t("actions.deleteModal.message")}
                  cancelLabel={t("actions.cancel")}
                  message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('section.title')}: `}
                  boldMessage={<span style={{ fontWeight: "bold" }}>{deleteDialog.name}</span>}
                  action={deleteS}
                  handleOpen={handleDialogState}
                  actionColor="#e91e1e"
               />
            }

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }
         </CustomGrid>
      );
   }
}

export default SectionListComponent;