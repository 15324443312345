import {
    useMediaQuery
} from '@mui/material';
import CustomSwippableDrawerChildren from 'components/Drawer/CustomSwippableDrawerChildren';
import CustomDialogChildren from './CustomDialogChildren';

/**
 * The CustomDialogBasedOnScreenChildren, that display a customized dialog
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {*} body the body of the dialog
 * @property {function} handleClose the function that handles the dialog close state
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomDialogBasedOnScreenChildren({
    isOpen = false,
    children,
    handleClose,
    cancelLabel,
    actionLabel,
    id = "",
    title,
    message,
    handleOpen,
    handleAction,
    includeCloseIcon = false,
    counter,
    sx,
    includeActions = false
}) {
    const isMobile = useMediaQuery('(max-width:468px)');

    return (
        <>
            {(!isMobile && isOpen) &&
                <CustomDialogChildren
                    isOpen={isOpen}
                    aria-describedby="dialog"
                    children={children}
                    actionLabel={actionLabel}
                    cancelLabel={cancelLabel}
                    id={id}
                    title={title}
                    message={message}
                    counter={counter}
                    includeCloseIcon={includeCloseIcon}
                    handleOpen={handleOpen}
                    handleAction={handleAction}
                    sx={sx}
                />
            }

            {(isMobile && isOpen) &&
                <CustomSwippableDrawerChildren
                    open={isOpen}
                    onClose={() => handleClose(false)}
                    anchor={isMobile ? "bottom" : "left"}
                    children={children}
                    actionLabel={includeActions ? actionLabel : undefined}
                    cancelLabel={includeActions ? cancelLabel : undefined}
                    id={id}
                    title={title}
                    message={message}
                    counter={counter}
                    onOpen={handleOpen}
                    handleAction={includeActions ? handleAction : undefined}
                />
            }
        </>
    );
}

export default CustomDialogBasedOnScreenChildren;