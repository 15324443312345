import { Check as CheckIcon, Clear as ClearIcon, Delete as DeleteIcon, Settings as SettingsIcon } from "@mui/icons-material";
import {
    Alert, Box, Button, Dialog,
    DialogContent, Grid, IconButton, Slider, Snackbar, TextField, Typography
} from '@mui/material';
import { AddIcon } from 'assets/mui/MuiIcons';
import CustomGrid from 'components/Grid/CustomGrid';
import { useEffect, useRef, useState } from 'react';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useTranslation } from 'react-i18next';
import { mainTag } from 'themes/defaultThemes';
import useImage from "use-image";
import useDocumentTitle from "utils/useDocumentTitle";
import { v4 as uuidv4 } from "uuid";
import dataURLtoFile from "utils/asset/dataURLtoFile";
import RenderFloorShape from "components/Grid/floor/RenderFloorShape";
import FloorPlannerDrawer from "components/Drawer/floor/FloorPlannerDrawer";
import { Image, Stage } from "react-konva";
import { Layer } from "recharts";
import { shapeOptions, tablesOptions } from "constants/floor/floorOptions";
import DropFloorItem from "components/Table/draggable/floor/DropFloorItem";


/**
 * The FloorEditorComponent, that presents a floor editor component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function FloorEditorComponent() {
    const { t } = useTranslation();
    const [showFullScreen, setShowFullScreen] = useState(false);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    //change document title
    useDocumentTitle(`Usee | Floor Editor`);

    const shapeRef = useRef();
    const trRef = useRef();
    const nameRef = useRef(null);
    const nameRefShape = useRef(null);
    const [nameTextWidth, setNameTextWidth] = useState(0);

    const minMaxCover = useRef(null);
    const minMaxRefShape = useRef(null);

    const defaultRoom = {
        id: "",//uuidv4(),
        index: 0,
        name: "Main Room",
        background: "",
        backgroundImageSize: "",
        objects: [
            // {
            //   id: uuidv4(),
            //   type: "TABLE",
            //    shape: "square",//circle
            //   index: 1,
            //   name: "1",
            //   borderColor: "#4a4c50",
            //   minCover: 1,
            //   maxCover: 20,
            //   availableOnline: true,
            //   canvas:{
            //     rotation: "",
            //     size: {
            //width:0,
            //height:0,
            // },
            //     position: {
            //   x: 0,
            //   y: 0
            // }
            //   }
            // },
        ],
    };





    const sidebarWidth = "30%";

    const cropperRef = useRef();
    const layerRef = useRef(null);
    const containerRef = useRef(null);

    const [showToast, setShowToast] = useState({
        active: false,
        message: "",
        severity: "success",
    });
    const [showError, setShowError] = useState({
        active: false,
        message: "",
        action: "",
    });
    const [stage, setStage] = useState({
        scale: 1,
        x: 0,
        y: 0,
    });
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
    });

    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [rooms, setRooms] = useState([defaultRoom]);
    const [selectedRoom, setSelectedRoom] = useState(defaultRoom);
    const [selectedObject, setSelectedObject] = useState(null);
    const backgroundCanvas = useImage(selectedRoom.background);

    const [zoom, setZoom] = useState(1);
    const [cropper, setCropper] = useState();
    const [showCropper, setShowCropper] = useState({
        active: false,
        file: null,
        fileName: "",
        type: "",
        orignalFile: null,
    });
    const [deleteDialog, setDeleteDialog] = useState({
        active: false,
        object: null,
    });
    const [deleteRoomDialog, setDeleteRoomDialog] = useState({
        active: false,
        room: null,
    });
    const [editRoomNameChip, setEditRoomNameChip] = useState({
        active: false,
        name: "",
    });


    useEffect(() => {
        if (
            containerRef.current?.offsetHeight &&
            containerRef.current?.offsetWidth
        ) {
            setDimensions({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            });
        }
    }, []);
    useEffect(() => {
        function handleResize() {
            setDimensions({
                width: containerRef.current?.offsetWidth,
                height: containerRef.current.offsetHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const saveClickHandler = () => {
        console.log(rooms);
    };

    const addRoomHandler = () => {
        const newRoom = {
            id: uuidv4(),
            index:
                rooms[rooms.length - 1].index > 0
                    ? rooms[rooms.length - 1].index + 1
                    : 1,
            name: `New Room ${rooms[rooms.length - 1].index > 0
                ? rooms[rooms.length - 1].index + 1
                : 1
                }`,
            background: "",
            backgroundImageSize: "",
            objects: [],
        };

        setRooms((r) => [
            ...r.map((ro) => (ro.id === selectedRoom.id ? selectedRoom : ro)),
            newRoom,
        ]);
        setSelectedRoom(newRoom);
        setSelectedObject(null);
    };

    const addTableHandler = (type, shape, offset) => {
        // event.preventDefault();
        // const shape = event.dataTransfer.getData("shape");
        // const type = event.dataTransfer.getData("type");

        //  if (!data) return;
        if (!shape) return;
        if (!type) return;
        const draggedElement = document.getElementById(shape);
        const dropContainer = containerRef.current;
        const dropContainerRect = dropContainer.getBoundingClientRect();
        const droppedElementRect = draggedElement.getBoundingClientRect();

        const maxLeft = 0;
        const maxRight = dropContainerRect.width;
        const maxtop = 0;
        const maxBottom = dropContainerRect.height;

        //const offsetX = event.clientX - dropContainerRect.left;
        //const offsetY = event.clientY - dropContainerRect.top;
        const offsetX = offset.x - dropContainerRect.left;
        const offsetY = offset.y - dropContainerRect.top;

        //console.log(offsetX, droppedElementRect.width / 2);

        let droppedElementPosition = {
            x: offsetX - droppedElementRect.width / 2,
            y: offsetY - droppedElementRect.height / 2,
        };

        if (shape && shape.includes("circle")) {
            droppedElementPosition.x =
                droppedElementPosition.x + droppedElementRect.width / 2;
            droppedElementPosition.y =
                droppedElementPosition.y + droppedElementRect.height / 2;
        }
        if (
            droppedElementPosition.x > maxRight ||
            droppedElementPosition.x < maxLeft ||
            droppedElementPosition.y > maxBottom ||
            droppedElementPosition.y < maxtop
        ) {
            return;
        }

        let roomId = uuidv4();
        setSelectedRoom((s) => {
            return {
                ...s,
                objects: [
                    ...s.objects,
                    {
                        id: roomId,
                        type: type,
                        shape: shape,
                        index:
                            selectedRoom.objects.length > 0
                                ? selectedRoom.objects[selectedRoom.objects.length - 1].index +
                                1
                                : 1,
                        name: `${selectedRoom.objects.length > 0
                            ? selectedRoom.objects[selectedRoom.objects.length - 1].index +
                            1
                            : 1
                            }`,
                        borderColor: "#4a4c50",
                        fill: type === "SHAPE" ? "#afc1ec" : "#fff",
                        minCover: 1,
                        maxCover: 20,
                        availableOnline: true,
                        canvas: {
                            rotation: 0,
                            size: {
                                width: draggedElement.offsetWidth,
                                height: draggedElement.offsetHeight,
                            },
                            position: {
                                left: droppedElementPosition.x,
                                top: droppedElementPosition.y,
                            },
                        },
                    },
                ],
            };
        });

        setSelectedObject(roomId);
    };

    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setSelectedObject(null);
        }
    };

    const handleWheel = (e) => {
        e.evt.preventDefault();

        const scaleBy = 1.02;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
        };

        const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

        setStage({
            scale: newScale,
            x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
            y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
        });
    };

    const nameChangeHandler = (name) => {
        setSelectedRoom((r) => {
            return { ...r, name: name };
        });
        setRooms((rooms) =>
            rooms.map((r) => {
                if (selectedRoom.id === r.id) {
                    return { ...r, name: name };
                }
                return r;
            })
        );
    };
    const fileChangeHandler = (e) => {
        const maximumSize = 5 * 1024 * 1024;
        if (!e.target.files && !e.target.files[0]) {
            return;
        }
        let file = e.target.files[0];
        const fn = e.target.files[0].name;
        const type = e.target.files[0].type;

        if (file.size > maximumSize) {
            setShowToast({
                active: true,
                message: "File size cannot be greater than 5mb",
                severity: "error",
            });
            return;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setShowCropper({
                active: true,
                file: reader.result,
                fileName: fn,
                type: type,
                orignalFile: file,
            });
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            let obj = dataURLtoFile(
                cropper.getCroppedCanvas().toDataURL(showCropper.type),
                showCropper.fileName
            );
            // compressing file
            // if (obj.size > 300000) {
            //   let compressObjBlob = await compressAccurately(obj, 300);
            //   let compressObjFile = new File([compressObjBlob], showCropper.fileName, {
            //     type: showCropper.type,
            //   });
            //   obj = compressObjFile;
            // }

            setSelectedRoom((d) => {
                return {
                    ...d,
                    background: obj,
                    backgroundImageSize: obj.size,
                    backgroundCanvas: cropper.getCroppedCanvas(),
                };
            });
            setShowCropper({
                active: false,
                file: null,
                fileName: "",
                type: "",
                orignalFile: null,
            });
            setZoom(1);
            setCropper();
        }
    };

    const coverCroper = (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={showCropper.active}
            onClose={() => {
                setShowCropper({
                    active: false,
                    file: null,
                    fileName: "",
                    orignalFile: null,
                });
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                {/* <Cropper
                    style={{ height: 390, width: "100%", color: "rgb(18,136,73)" }}
                    aspectRatio={3 / 2} //means 39% height as compare to width
                    guides={false}
                    src={showCropper.file}
                    //   ref={(cropper) => {
                    //     this.cropper = cropper;
                    //   }}
                    ref={cropperRef}
                    viewMode={1}
                    dragMode='move'
                    cropBoxMovable={false}
                    zoomTo={zoom}
                    responsive={true}
                    //this is for reposition stuff
                    // ready={(cro) => {
                    //   if (showCropper.dimensions) {
                    //     cropperRef.current.cropper.setCropBoxData({
                    //       width: showCropper.dimensions.width,
                    //       height: showCropper.dimensions.height,
                    //       top: showCropper.dimensions.cropBoxTop,
                    //       left: showCropper.dimensions.cropBoxLeft,
                    //     });
                    //     cropperRef.current.cropper.setCanvasData({
                    //       top: showCropper.dimensions.top,
                    //       left: showCropper.dimensions.left,
                    //     });
                    //   }
                    // }}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                /> */}

                <div style={{ marginTop: "1em" }}>
                    <Slider
                        value={zoom}
                        //min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby='Zoom'
                        onChange={(e, zoom) => setZoom(zoom)}
                        sx={{ color: "rgb(18,136,73)" }}
                    />
                </div>

                <div style={{ marginTop: "1em" }}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item>
                            <Button
                                variant='outlined'
                                sx={{
                                    borderColor: "rgb(18,136,73)",
                                    color: "rgb(18,136,73)",
                                    textTransform: "none",
                                }}
                                onClick={() =>
                                    setShowCropper({
                                        active: false,
                                        file: null,
                                        fileName: "",
                                        orignalFile: null,
                                    })
                                }
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                sx={{
                                    background: "rgb(18,136,73)",
                                    borderColor: "rgb(18,136,73)",
                                    color: "#fff",
                                    textTransform: "none",
                                }}
                                onClick={getCropData}
                            // disabled={
                            //   loading.active && loading.action === "cover"
                            // }
                            >
                                Upload
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
        </Dialog>
    );

    const renderDeleteObjectDialog = (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={deleteDialog.active}
            onClose={() => {
                setDeleteDialog({
                    active: false,
                    object: null,
                });
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <Typography variant='h5' sx={{ color: "#34353A", fontWeight: 700 }}>
                    Delete {deleteDialog.object?.type?.toLowerCase()}{" "}
                    {deleteDialog?.object?.name}
                </Typography>
                <Typography variant='body1' sx={{ mt: "20px", color: "#4a4c50" }}>
                    Are you sure you want to delete this{" "}
                    {deleteDialog.object?.type?.toLowerCase()} ?
                </Typography>
                <Grid
                    container
                    spacing={2}
                    justifyContent='flex-end'
                    sx={{ mt: "28px" }}
                >
                    <Grid item>
                        <Button
                            variant='text'
                            sx={{
                                borderColor: "transparent",
                                fontWeight: 600,
                                color: "#4A4c50",
                            }}
                            onClick={() =>
                                setDeleteDialog({
                                    active: false,
                                    object: null,
                                })
                            }
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='outlined'
                            sx={{
                                borderColor: "red",
                                color: "red",
                                fontWeight: 600,
                            }}
                            onClick={() => {
                                setSelectedObject(null);
                                setSelectedRoom((r) => {
                                    return {
                                        ...r,
                                        objects: r.objects.filter(
                                            (o) => o.id !== deleteDialog.object?.id
                                        ),
                                    };
                                });

                                setDeleteDialog({
                                    active: false,
                                    object: null,
                                });
                            }}
                        // disabled={
                        //   loading.active && loading.action === "cover"
                        // }
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
    const renderDeleteRoomDialog = (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={deleteRoomDialog.active}
            onClose={() => {
                setDeleteRoomDialog({
                    active: false,
                    room: null,
                });
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <Typography variant='h5' sx={{ color: "#34353A", fontWeight: 700 }}>
                    Delete "{deleteRoomDialog.room?.name}"?{" "}
                </Typography>
                <Typography variant='body1' sx={{ mt: "20px", color: "#4a4c50" }}>
                    Are you sure you want to delete the selected room?
                </Typography>
                <Grid
                    container
                    spacing={2}
                    justifyContent='flex-end'
                    sx={{ mt: "28px" }}
                >
                    <Grid item>
                        <Button
                            variant='text'
                            sx={{
                                borderColor: "transparent",
                                fontWeight: 600,
                                color: "#4A4c50",
                            }}
                            onClick={() =>
                                setDeleteRoomDialog({
                                    active: false,
                                    room: null,
                                })
                            }
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='outlined'
                            sx={{
                                borderColor: "red",
                                color: "red",
                                fontWeight: 600,
                            }}
                            onClick={() => {
                                setSelectedObject(null);
                                let newRooms = rooms.filter(
                                    (ro) => ro.id !== deleteRoomDialog.room?.id
                                );
                                setRooms(newRooms[0] ? newRooms : [defaultRoom]);
                                setSelectedRoom(newRooms[0] ? newRooms[0] : defaultRoom);
                                setDeleteRoomDialog({
                                    active: false,
                                    room: null,
                                });
                            }}
                        // disabled={
                        //   loading.active && loading.action === "cover"
                        // }
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );

    const handleToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShowToast({
            active: false,
            message: "",
            severity: "success",
        });
    };

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="test" sx={mainTag()}>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                <Grid
                    container sx={{ minHeight: "100vh", background: "#F4F4F6" }}>
                    {coverCroper}
                    {renderDeleteObjectDialog}
                    {renderDeleteRoomDialog}
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={showToast.active}
                        autoHideDuration={6000}
                        onClose={handleToastClose}
                    >
                        <Alert
                            onClose={handleToastClose}
                            severity={showToast.severity}
                            sx={{ width: "100%" }}
                        >
                            {showToast.active}
                        </Alert>
                    </Snackbar>
                    <Grid item>
                        <FloorPlannerDrawer
                            sidebarWidth={sidebarWidth}
                            selectedRoom={selectedRoom}
                            setSelectedRoom={setSelectedRoom}
                            selectedObject={selectedObject}
                            setSelectedObject={setSelectedObject}
                            sidebarOpen={sidebarOpen}
                            nameChangeHandler={nameChangeHandler}
                            fileChangeHandler={fileChangeHandler}
                            setDeleteDialog={setDeleteDialog}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            flex: 1,
                            ml: `calc(${sidebarWidth} - 4px)`,
                        }}
                    >
                        <Grid container direction='column' sx={{ height: "100%" }}>
                            {/* for rooms  */}
                            <Grid item>
                                <Grid
                                    container
                                    justifyContent='space-between'
                                    alignItems='center'
                                    sx={{
                                        p: "1rem",
                                        border: "1px solid #d8d8d8",
                                        background: "#fff",
                                    }}
                                >
                                    <Grid item sx={{ flex: 1 }}>
                                        <Grid container alignItems='center' wrap='nowrap'>
                                            {rooms.map((item) => (
                                                <Grid
                                                    item
                                                    key={item.id}
                                                    sx={{
                                                        px: "0.8em",
                                                        backgroundColor:
                                                            selectedRoom?.id === item.id ? "#f4f4f6" : "#fff",
                                                        borderRadius: "50px",
                                                        display: "flex",

                                                        gap: "10px",
                                                    }}
                                                >
                                                    {selectedRoom?.id === item.id &&
                                                        editRoomNameChip.active ? (
                                                        <TextField
                                                            variant='standard'
                                                            size='small'
                                                            sx={{
                                                                py: "0.3em",
                                                                ".MuiInput-input": {
                                                                    fontSize: "10px",
                                                                    pb: "1px",
                                                                },
                                                            }}
                                                            value={editRoomNameChip.name}
                                                            onChange={(e) =>
                                                                setEditRoomNameChip((n) => {
                                                                    return {
                                                                        ...n,
                                                                        name: e.target.value,
                                                                    };
                                                                })
                                                            }
                                                        />
                                                    ) : (
                                                        <Typography
                                                            variant='body2'
                                                            sx={{
                                                                py: "0.6em",
                                                                fontSize: "11px",
                                                                color: "#4a4c50",
                                                                cursor:
                                                                    item.id === selectedRoom?.id
                                                                        ? "default"
                                                                        : "pointer",
                                                            }}
                                                            onClick={() => {
                                                                if (item.id === selectedRoom?.id) return;
                                                                setRooms((r) =>
                                                                    r.map((ro) =>
                                                                        ro.id === selectedRoom.id ? selectedRoom : ro
                                                                    )
                                                                );
                                                                setSelectedObject(null);
                                                                setSelectedRoom(item);
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    )}
                                                    {selectedRoom?.id === item.id && (
                                                        <div
                                                            style={{
                                                                paddingLeft: "10px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <IconButton
                                                                sx={{ p: 0, background: "transparent" }}
                                                                disableRipple
                                                                onClick={() => {
                                                                    if (editRoomNameChip.active) {
                                                                        setEditRoomNameChip({
                                                                            active: false,
                                                                            name: "",
                                                                        });
                                                                    } else {
                                                                        setEditRoomNameChip({
                                                                            active: true,
                                                                            name: selectedRoom.name,
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                {editRoomNameChip.active ? (
                                                                    <ClearIcon
                                                                        style={{
                                                                            py: "0.6em",
                                                                            paddingRight: "10px",
                                                                            fontSize: "0.8rem",
                                                                            fill: "rgb(153, 153, 153)",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <SettingsIcon
                                                                        style={{
                                                                            py: "0.6em",
                                                                            paddingRight: "10px",
                                                                            fontSize: "0.8rem",
                                                                            fill: "rgb(153, 153, 153)",
                                                                        }}
                                                                    />
                                                                )}
                                                            </IconButton>
                                                            <div
                                                                style={{
                                                                    borderLeft: "1px solid #d8d8d8",

                                                                    height: "100%",
                                                                }}
                                                            />
                                                            <IconButton
                                                                sx={{ p: 0, background: "transparent" }}
                                                                disableRipple
                                                                onClick={() => {
                                                                    if (editRoomNameChip.active) {
                                                                        nameChangeHandler(editRoomNameChip.name);
                                                                        setEditRoomNameChip({
                                                                            active: false,
                                                                            name: "",
                                                                        });
                                                                    } else {
                                                                        setDeleteRoomDialog({
                                                                            active: true,
                                                                            room: item,
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                {editRoomNameChip.active ? (
                                                                    <CheckIcon
                                                                        style={{
                                                                            py: "0.6em",
                                                                            paddingLeft: "10px",
                                                                            fontSize: "0.8rem",
                                                                            fill: "rgb(153, 153, 153)",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <DeleteIcon
                                                                        style={{
                                                                            py: "0.6em",
                                                                            paddingLeft: "10px",
                                                                            fontSize: "0.8rem",
                                                                            fill: "rgb(153, 153, 153)",
                                                                        }}
                                                                    />
                                                                )}
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    {/* add, save room */}
                                    <Grid item>
                                        <Grid container sx={{ gap: "20px" }}>
                                            <Grid item>
                                                <Button
                                                    variant='text'
                                                    size='small'
                                                    sx={{
                                                        fontSize: "12px",
                                                        px: "10px",
                                                        color: "#128849",
                                                        textTransform: "none",
                                                    }}
                                                    startIcon={<AddIcon />}
                                                    onClick={addRoomHandler}
                                                >
                                                    {t('display.floorPlanning.addSection')}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant='contained'
                                                    size='small'
                                                    sx={{
                                                        fontSize: "12px",
                                                        px: "15px",
                                                        background: "#128849",
                                                        color: "#fff",
                                                        textTransform: "none",
                                                        borderRadius: "2px",
                                                        ":hover": {
                                                            background: "#128849",
                                                        },
                                                    }}
                                                    onClick={saveClickHandler}
                                                >
                                                    {t('display.floorPlanning.saveSection')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* for floor area  */}
                            <Grid
                                item
                                sx={{
                                    mt: "0.5em",

                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    elevation={0}
                                    sx={{
                                        background: "transparent",
                                        width: { md: "70%", sm: "90%", xs: "98%" },
                                        height: "100%",
                                    }}
                                >
                                    <DropFloorItem
                                        onDrop={(type, shape, offset) =>
                                            addTableHandler(type, shape, offset)
                                        }
                                        children={
                                            <div
                                                id='new-container'
                                                ref={containerRef}
                                                // onDragOver={(event) => event.preventDefault()}
                                                // onDrop={(event) => addTableHandler(event)}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    position: "relative",
                                                }}
                                            >
                                                {/* <div
                                                    style={{
                                                        position: "absolute",
                                                        right: 10,
                                                        top: 10,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "10px",
                                                        zIndex: 25,
                                                    }}
                                                    >
                                                    <button
                                                        style={{ fontWeight: "bold", cursor: "pointer" }}
                                                        onClick={() =>
                                                        setStage((x) => {
                                                            return {
                                                            ...x,
                                                            scale: x.scale + 0.02,
                                                            };
                                                        })
                                                        }
                                                    >
                                                        +
                                                    </button>
                                                    <button
                                                        style={{ fontWeight: "bold", cursor: "pointer" }}
                                                        onClick={() =>
                                                        setStage((x) => {
                                                            return {
                                                            ...x,
                                                            scale: x.scale - 0.02,
                                                            };
                                                        })
                                                        }
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        style={{ fontWeight: "bold", cursor: "pointer" }}
                                                        onClick={() =>
                                                        setStage((x) => {
                                                            return {
                                                            ...x,
                                                            scale: 1,
                                                            };
                                                        })
                                                        }
                                                    >
                                                        x
                                                    </button>
                                                    </div> */}

                                                <Stage
                                                    // onWheel={handleWheel}
                                                    // scaleX={stage.scale}
                                                    // scaleY={stage.scale}
                                                    // x={stage.x}
                                                    // y={stage.y}
                                                    width={dimensions.width}
                                                    height={dimensions.height}
                                                    //height={(2 / 3) * dimensions.width}
                                                    onMouseDown={checkDeselect}
                                                    onTouchStart={checkDeselect}
                                                    // style={{
                                                    //   backgroundImage: selectedRoom.background
                                                    //     ? typeof selectedRoom.background === "object"
                                                    //       ? `url(${URL.createObjectURL(selectedRoom.background)})`
                                                    //       : `url(${selectedRoom.background})`
                                                    //     : "unset",
                                                    //   backgroundSize: "cover",
                                                    //   backgroundRepeat: "no-repeat",
                                                    // }}
                                                    style={{
                                                        background:
                                                            "linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7))",
                                                    }}
                                                >
                                                    <Layer ref={layerRef}>
                                                        {selectedRoom?.background && (
                                                            <Image
                                                                listening={false}
                                                                image={
                                                                    typeof selectedRoom.background === "object"
                                                                        ? selectedRoom.backgroundCanvas
                                                                        : backgroundCanvas
                                                                }
                                                                backgroundSize='cover'
                                                                width={dimensions.width}
                                                                //height={(2 / 3) * dimensions.width}
                                                                height={dimensions.height}
                                                            />
                                                        )}

                                                        {selectedRoom.objects.map((item, i) => {
                                                            return (
                                                                <RenderFloorShape
                                                                    key={i}
                                                                    renderComponent={(props) => {
                                                                        let shape = shapeOptions.find(
                                                                            (to) => to.shape === item.shape
                                                                        );
                                                                        if (shape) {
                                                                            return shape.renderComponent(props);
                                                                        }

                                                                        return tablesOptions
                                                                            .find((to) => to.shape === item.shape)
                                                                            .renderComponent(props);
                                                                    }}
                                                                    layerRef={layerRef}
                                                                    rotation={item.canvas.rotation}
                                                                    shapeWidth={{
                                                                        width: item.canvas.size.width,
                                                                        height: item.canvas.size.height,
                                                                    }}
                                                                    shapePosition={{
                                                                        x: item.canvas.position.left,
                                                                        y: item.canvas.position.top,
                                                                    }}
                                                                    type={item.type}
                                                                    shape={item.shape}
                                                                    name={item.name}
                                                                    minCover={item.minCover}
                                                                    maxCover={item.maxCover}
                                                                    fill={item.fill}
                                                                    borderColor={item.borderColor}
                                                                    availableOnline={item.availableOnline}
                                                                    id={item.id}
                                                                    isSelected={item.id === selectedObject}
                                                                    onSelect={() => {
                                                                        setSelectedObject(item.id);
                                                                    }}
                                                                    onChange={(newAttrs) => {
                                                                        setSelectedRoom((r) => {
                                                                            return {
                                                                                ...r,
                                                                                objects: r.objects.map((o) => {
                                                                                    if (o.id === item.id) {
                                                                                        o.canvas = {
                                                                                            ...o.canvas,
                                                                                            ...newAttrs,
                                                                                        };
                                                                                    }
                                                                                    return o;
                                                                                }),
                                                                            };
                                                                        });
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </Layer>
                                                </Stage>
                                            </div>
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DndProvider>
        </CustomGrid>
    );
}

export default FloorEditorComponent;