export const getRoleOptions = () => {
    return [
        "ADMIN",
        "ADMIN_MENU",
        "ADMIN_ORG",
        "ADMIN_CHAT",
        "DIRECTOR",
        "BARISTA",
        "BARTENDER",
        "BUSSER",
        "KITCHEN",
        "FOOD_N_BEVERAGE_MANAGER",
        "MANAGER",
        "RUNNER",
        "SERVER",
        "BOOKING",
        "FLOOR_SUPERVISOR",
        "MESSAGING",
        "RATING_VIEWER"
    ];
}