import { Fab, Grid, Tooltip } from '@mui/material';
import userApi from "api/user";
import { AddIcon } from 'assets/svg/SvgIcons';
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from "components/Dialogs/CustomDialog";
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from 'components/Title/CustomTitle';
import { getDateFormatter } from 'constants/calendar';
import { getDefaultUserSortDirection } from 'constants/sortDirection';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagTitle } from 'themes/defaultThemes';
import { hasRoleAdmin } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';
import { reloadPage } from "utils/page";
import { actionFormatter, dateFormatter, iconFormatter, idFormatterLinkWithParent, nanFormatter, rolesFormatter } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The EmployeeListComponent, that allow the user to view the organization/outlet employees list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function EmployeeListComponent() {

   const { t } = useTranslation();
   const { organizationID } = useParams();
   const { outletID } = useParams();
   const navigate = useNavigate();
   let path = `/${getLanguageFromURL()}`
   if (organizationID !== undefined) path += `/organization/${organizationID}`;
   if (outletID !== undefined) path += `/outlet/${outletID}`;
   path += `/employee`;

   //change document title
   useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('page.employees')}`);

   /**
    * @type {object}
    * 
    * @property {object} userAuth the authenticated user infromation
    * @property {array} employees the employees list
    * @property {object} outlet tha outlet basic details (id and name, isActive)
    * @property {object} organization tha organization basic details (id and name)
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
    */
   const [data, setData] = useState({
      userAuth: null,
      users: [],
      showLabel: false,
      organization: {
         id: organizationID,
         name: ""
      },
      outlet: {
         id: outletID,
         name: ""
      },
      pagination: {
         count: -1,
         size: 15,
         page: 0,
         ...getDefaultUserSortDirection
      }
   });

   // to perform initial request
   const [isFirstTime, setIsFirstTime] = useState(true);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);
   // if true the menu item is clicked and a redirect to page should perform
   const [redirect, setRedirect] = useState(false);
   // for search
   const [search, setSearch] = useState({
      organizationID: organizationID,
      outletID: outletID !== undefined ? outletID : undefined,
      field: "username",
      username: "",
      email: "",
      phone: ""
   });
   // pop dialog for delete
   const [dialog, setDialog] = useState({
      isOpen: false,
      userID: "",
      fullName: ""
   });
   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });

   const [searchValue, setSearchValue] = useState('');


   useEffect(() => {
      getUsers();
      setIsFirstTime(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const identifier = setTimeout(() => {
         if (!isFirstTime) getUsers();
      }, 700);

      return () => {
         clearTimeout(identifier);
      }
   }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])

   /**
    * The rest endpoint to get the users list.
    */
   function getUsers() {
      userApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
         setData({
            ...data,
            users: r.data.returnobject.users,
            userAuth: r.data.returnobject.userAuth,
            organization: r.data.returnobject.organization,
            outlet: r.data.returnobject.outlet,
            pagination: {
               ...data.pagination,
               count: r.data.returnobject.users.totalPages,
               size: r.data.returnobject.users.size,
               page: r.data.returnobject.users.number
            }
         });
         setIsLoaded(true);
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * 
    * Function that triggers the removal of the selected user.
    * @property {*} event
    * @property {string} userID The id of the selected user to be removed.
    */
   function deleteUser(event, userID, fullName) {
      handleDialogState(true, userID, fullName);
   }

   /**
    * Function that handles the delete modal open or close state.
    * @property {boolean} isOpen The state of the dialog open status.
    * @property {string} userID The id of the selected user to be removed.
    */
   function handleDialogState(isOpen, userID = "", fullName = "") {
      setDialog({
         ...dialog,
         isOpen: isOpen,
         userID: userID,
         fullName: fullName
      });
   }

   /**
    * Gets called to remove the selected user
    */
   function deleteU() {
      userApi.deleteById(dialog.userID).then((r) => {
         handleDialogState(false);
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getUsers();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected subscription plan
    * @param {*} event
    * @param {string} id the id of the subscription plan
    */
   function enableUser(event, id) {
      userApi.enable(id).then((r) => {
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getUsers();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected subscription plan
    * @param {*} event
    * @param {string} id the id of the subscription plan 
    */
   function disableUser(event, id) {
      userApi.disable(id).then((r) => {
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getUsers();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Function that triggers the multi select input value change.
    * @param {*} event 
    */
   function onSelectSearchChange(event) {
      let field = event.target.value;
      setSearchValue("");
      setSearch({
         ...search,
         field: field,
         username: "",
         email: "",
         phone: "",
         [field]: ""
      });
   }

   /**
    * Function that handles the snackbar open or close state.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }

   /**
    * Function that handles the pagination information.
    * @property {int} page the page to be redirected
    */
   function handlePaginationChange(page) {
      formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
   }

   /**
    * Function that handles the pagination information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleRowsPerPageChange(size) {
      let pagination = data.pagination;
      pagination.size = size;
      pagination.page = 0;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }
   /**
    * Function that handles the sort information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleColumnSortChange(changedColumn, direction) {
      let pagination = data.pagination;
      pagination.sort = changedColumn;
      pagination.direction = direction;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }

   // ===============================================================================RENDER

   if (redirect) {
      reloadPage();
   } else if (!isLoaded) {
      return <CustomGrid role="main" id="employee-list" sx={mainTag()}>
         <LoadingSkeleton lines={9} />
      </CustomGrid>
   } else {
      /**
       * datatable columns.
       */
      const table_columns = [
         {
            name: "enabled",
            label: t('label.isEnabled'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return iconFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     {
                        enable: (event, id) => enableUser(event, id),
                        disable: (event, id) => disableUser(event, id),
                        positionOfId: 1
                     });
               }
            }
         },
         {
            name: "id",
            label: t('label.id'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
               customBodyRender: (value, tableMeta) => {
                  return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
               }
            }
         },
         {
            name: "username",
            label: t('label.username'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('label.username')}: ${v}`,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return value;
               }
            }
         },
         {
            name: "firstName",
            label: t('label.firstName'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: t("label.na") });
               }
            }
         },
         // {
         //     name: "fatherName",
         //     label: t('label.fatherName'),
         //     options: {
         //         filter: false,
         //         sort: true,
         //         empty: false,
         //         customBodyRender: (value, tableMeta) => {
         //             return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: t("label.na") });
         //         }
         //     }
         // },
         {
            name: "lastName",
            label: t('label.lastName'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return value;
               }
            }
         },
         {
            name: "phone",
            label: t('label.phone'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('label.phone')}: ${v}`,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return value;
               }
            }
         },
         {
            name: "email",
            label: t('label.email'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('label.email')}: ${v}`,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return value;
               }
            }
         },
         {
            name: "roles",
            label: t('label.roles'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return <div style={{ float: "left" }}>
                     {rolesFormatter(value, tableMeta.rowIndex, { emptyMessage: t("label.na") })}
                  </div>
               }
            }
         },
         {
            name: "dateCreated",
            label: t('label.dateCreated'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateFormatter());
               }
            }
         },
         {
            name: "",
            label: t('actions.label'),
            options: {
               filter: false,
               sort: false,
               empty: true,
               customBodyRender: (value, tableMeta) => {
                  return actionFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     1,
                     null,
                     3,
                     4,
                     null,
                     path,
                     data.showLabel ? t('actions.edit') : undefined,
                     (event, id, fullName) => deleteUser(event, id, fullName),
                     data.showLabel ? t('actions.delete') : undefined,
                  )
               },
            }
         }
      ];

      return (
         <CustomGrid role="main" id="employee-list" sx={mainTag()}>
            {outletID !== undefined && organizationID !== undefined &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomBreadcrumbMultiParent
                     parents={[
                        { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                        { name: data.organization?.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                        { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                        { name: data.outlet?.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` }
                     ]}
                     instanceName={t('employee.title')}
                  />
               </CustomGrid>
            }
            {outletID === undefined && organizationID !== undefined &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomBreadcrumbMultiParent
                     isAdd={data.isAdd}
                     parents={[
                        { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                        { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` }
                     ]}
                     instanceName={t('employee.title')}
                  />
               </CustomGrid>
            }

            <Grid container spacing={2} sx={{ alignItems: "center" }}>
               <Grid item xs={"auto"}>
                  <CustomRefreshButton onClick={getUsers} marginTop="-1%" />
               </Grid>

               <Grid item xs={11} sm={3}>
                  <CustomGrid sx={mainTagTitle()}>
                     <CustomTitle
                        title={t('employee.title')}
                        subtitle={t('employee.subtitle')}
                     />
                  </CustomGrid>
               </Grid>
            </Grid>

            <CustomGrid sx={mainAreaTable()} container={false}>
               <CustomPageableTable
                  instances={data.users}
                  emptyMessage={t('table.noData') + t('actions.tableCreateNewLink') + t('employee.pageTitle')}
                  table_columns={table_columns}
                  addPageUrl={`${path}/add`}
                  keyField="username"
                  pagination={data.pagination}
                  defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                  handlePaginationChange={handlePaginationChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handleColumnSortChange={handleColumnSortChange}
                  filter={true}
                  filterType={"textField"}
                  onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                     ...search,
                     username: "",
                     email: "",
                     phone: ""
                  })}
               />
            </CustomGrid>

            <Fab color="primary"
               aria-label="add"
               sx={fabStyle()}
               onClick={() => navigate(`${path}/add`)}
            >
               <Tooltip arrow title={t('actions.add') + " " + t('employee.pageTitle')} sx={fabTooltipStyle()}>
                  <AddIcon {...fabStyleIcon()} />
               </Tooltip>
            </Fab>

            {dialog.isOpen &&
               <CustomDialog
                  isOpen={dialog.isOpen}
                  id="delete"
                  title={t('actions.deleteModal.title')}
                  actionLabel={t("actions.deleteModal.message")}
                  cancelLabel={t("actions.cancel")}
                  message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('user.pageTitle')}: `}
                  boldMessage={<span style={{ fontWeight: "bold" }}>{dialog.fullName}</span>}
                  action={deleteU}
                  handleOpen={handleDialogState}
                  actionColor="#e91e1e"
               />
            }

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }
         </CustomGrid>
      );
   }
}

export default EmployeeListComponent;