import AddIcon from '@mui/icons-material/Add';
import { Card, CardActionArea } from '@mui/material';
import React from 'react';
import CustomIconButton from 'components/Button/CustomIconButton';
import { getButtonProperties, getCardActionAreaProperties, getCardProperties } from 'themes/qrcode/defaultTheme';

/**
 * The Custom Add QRcode template, that display customized {@link Card} with an add button for triggering the add new qrcode template.
 * 
 * @property {function} onClick Callback that triggered when card button is clicked
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomAddQrcodeTemplateCard({ onClick }) {
    return (
        <Card
            key="card-new-qrcode-template"
            variant="outlined"
            sx={getCardProperties()}
        >
            <CardActionArea sx={getCardActionAreaProperties()}>
                <CustomIconButton
                    sx={{ textAlign: "center" }}
                    backgroundColor="transparent"
                    icon={<AddIcon sx={{ ...getButtonProperties() }} />}
                    onClick={onClick}
                    isFocused={true}
                />
            </CardActionArea>
        </Card>
    );
}

export default CustomAddQrcodeTemplateCard;