import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Save as SaveIcon
} from '@mui/icons-material';
import { Button, Fab, FormControlLabel, Grid, InputLabel, Switch, Tooltip } from "@mui/material";
import itemApi from "api/item";
import modifierListApi from 'api/modifierList';
import en from "assets/images/flags/en.png";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
// import CustomImageCropper from 'components/Assets/CustomImageCropper';
import { TabContext, TabPanel } from '@mui/lab';
import {
    CeleryIcon,
    EggsIcon,
    FishIcon,
    LupineIcon,
    MilkIcon,
    MusselIcon,
    MustardIcon,
    NewIcon,
    PeanutIcon,
    PepperIcon,
    SesameIcon,
    ShrimpIcon,
    SoyIcon,
    VeganIcon,
    VegetarianIcon,
    WheatIcon
} from 'assets/svg/SvgIcons';
import CustomAddEditBreadcrumbMultiParent from "components/Breadcrumb/CustomAddEditBreadcrumbMultiParent";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomChip from 'components/Chip/CustomChip';
import AddModifierListDialog from 'components/Dialogs/AddModifierListDialog';
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";
import CustomCollapsableFieldset from 'components/Fieldset/CustomCollapsableFieldset';
import CustomGrid from 'components/Grid/CustomGrid';
import PhotoComponent from 'components/Grid/photo/PhotoComponent';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSelectMultilingual from 'components/Select/CustomSelectMultilingual';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTabs from 'components/Tab/CustomTabs';
import CustomModifierListDraggableTableItem from 'components/Table/CustomModifierListDraggableTable';
import CustomFlagTextArea from 'components/Text/CustomFlagTextArea';
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTextField from 'components/Text/CustomTextField';
import CustomAddEditTitle from "components/Title/CustomAddEditTitle";
import { getAssetDefaultObject, getDefaultItemModifier, getItemNutrientsDefaultObject, getItemPriceDefaultObject } from 'constants/defaultObjects';
import { geItemDiscountPercentage, getItemButtonIcon, getItemLoyaltyPoints, getItemNutrient, getItemNutrientDetails, getItemPrice, getItemPvcRecyclingTax } from 'constants/fieldProperties';
import { getThemeColor } from 'constants/theme';
import { addCardActionAreaButtonStyle, addWorkingHoursButtonIconStyle, allergyButton, allergyButtonIcon, attachDialogAddButton, attachDialogAddButtonAddIcon, attachDialogAddButtonIcon, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, fabSecondStyle, fabStyle, fabTooltipStyle, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, textFieldError } from 'themes/defaultThemes';
import { hasRoleAdminMenu } from 'utils/auth';
import { formIsValid, formValChangeWithParentElementWithNameAndValue, formValChangeWithParents, onControlChange } from "utils/form-validation";
import { findAllObjectsMatchInArrayOrdered, validateInputNumber } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { getTheme } from "utils/theme";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The ItemAddEditComponent that allow the ADMIN to create a 
 * new item or edit an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ItemAddEditComponent() {
    const { t } = useTranslation();

    const { organizationID } = useParams();
    const { itemID } = useParams();
    const navigate = useNavigate();
    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    // if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/item-or-modifier`;

    /**
     * @type {object}
     * @property {boolean} redirect if a redirect should be performed
     * @property {boolean} isLoaded to render DOM based on rest api call status, if true the rest api call completed
     * @property {object} userAuth the authenticated user infromation
     * @property {object} organization the organization basic details (id and name) 
     * @property {object} outlet tha outlet basic details (id and name)
     * @property {string} itemId the item id to get the current information for edit item page
     * @property {object} item the item details
     * @property {*} icon the legend fa icon
     * @property {object} alertBox holds snackbar infromation and style
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        userAuth: null,
        isAdd: itemID === undefined,
        organization: {
            id: organizationID,
            name: ""
        },
        item: null,
        icon: AddIcon,
        menuItemProviderOptions: [],
        loyaltyCategories: [],
        modifierLists: [],
        modifierListSelected: [],
        itemCategories: [],
        itemVatTypes: [],
        // error messages per field
        isError: {
            name: "",
            description: ""
        }
    });

    const [value, setValue] = React.useState("details");

    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // popup dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        message: t("label.configureTranslations"),
        id: "",
        field: "",
        translations: {}
    });
    // popup dialog for delete
    const [attachDialog, setAttachDialog] = useState({
        isOpen: false,
        field: ""
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        action: undefined,
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getItem();
    }, []);

    //change document title
    useDocumentTitle(`Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t('org.pageTitle') : ""} | ${t('itemOrModifier.title')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`);

    /**
     * The rest endpoint to get the item.
     */
    function getItem() {
        if (data.isAdd) {
            itemApi.fetchNew(organizationID).then((r) => {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    item: getDefaultItemModifier(),
                    menuItemProviderOptions: r.data.returnobject.menuItemProviderOptions,
                    loyaltyCategories: r.data.returnobject.loyaltyCategories,
                    modifierLists: r.data.returnobject.modifierLists,
                    organization: r.data.returnobject.organization,
                    itemCategories: r.data.returnobject.itemCategories,
                    itemVatTypes: r.data.returnobject.itemVatTypes
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            itemApi.fetchOne(organizationID, itemID).then((r) => {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    item: r.data.returnobject.item,
                    organization: r.data.returnobject.organization,
                    menuItemProviderOptions: r.data.returnobject.menuItemProviderOptions,
                    loyaltyCategories: r.data.returnobject.loyaltyCategories,
                    modifierLists: r.data.returnobject.modifierLists,
                    modifierListSelected: findAllObjectsMatchInArrayOrdered(r.data.returnobject.item.modifierListIds, r.data.returnobject.modifierLists),
                    itemCategories: r.data.returnobject.itemCategories,
                    itemVatTypes: r.data.returnobject.itemVatTypes
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            });
        }
    }

    /**
     * Gets called when the user clicks on the save button, and triggers 
     * the creating of the item.
     */
    function addItem(redirectAfter = false) {
        if (formValidation()) {
            let item = data.item;
            item.organizationID = data.organization.id;
            itemApi.create(item).then((r) => {
                let message = "message" in r.data ?
                    (r.data.code === "SUCCESS") ?
                        `${t(`itemOrModifier.${!r.data.returnobject.isModifier ? "pageTitleItem" : "pageTitleModifier"}`)} "${r.data.returnobject.name.en}" ${t('actions.createSuccessfully')}"`
                        :
                        r.data.message
                    : t("errorPages.somethingWentWrong")
                Promise.resolve()
                    .then(() => {
                        setAlertBox({
                            isOpen: true,
                            message: message,
                            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313",
                            action: (r.data.code === "SUCCESS") ? <Button color="secondary" sx={{ color: "white" }} size="small" onClick={() => window.location.href = `${path}/${r.data.returnobject.id}`}>{t('actions.view')}</Button> : undefined
                        });
                    })
                    .then(() => {
                        if (!redirectAfter)
                            window.location.reload();
                        else
                            setData({
                                ...data,
                                isAdd: false,
                                item: r.data.returnobject
                            })
                        navigate(`${path}/${r.data.returnobject.id}`)
                        // formValChangeWithParentElementWithNameAndValue("item", getDefaultItemModifier(), data, setData, "");
                    })
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the modification of the item
     */
    function editItem() {
        if (formValidation()) {
            itemApi.update(data.item).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313",
                    action: undefined
                });
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the removal of a modifier-list.
     * @param {*} modifierListindexToDelete 
     */
    function removeModifierList(event, modifierListToDelete) {
        let modifierListIds = data.item.modifierListIds;
        const index = modifierListIds.indexOf(modifierListToDelete.id);
        // only splice array when item is found
        if (index > -1) modifierListIds.splice(index, 1); // 2nd parameter means remove one item only
        setData({
            ...data,
            item: {
                ...data.item,
                modifierListIds: modifierListIds
            },
            modifierListSelected: data.modifierLists?.filter((modifierList) => modifierListIds?.includes(modifierList.id))
        });
    }

    function updateModifierListProperties(event, index, modifierListId, allowMultipleSelection, min, max) {
        let dataSent = { id: modifierListId, allowMultipleSelection: allowMultipleSelection, minModifiers: allowMultipleSelection ? min : 1, maxModifiers: allowMultipleSelection ? max : 1 };
        modifierListApi.updateDetails(dataSent).then((r) => {
            let modifierLists = data.modifierLists.map(modifierList => {
                if (modifierList.id === modifierListId) return { ...modifierList, ...dataSent }
                return modifierList
            });

            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            setData({
                ...data,
                modifierLists: modifierLists,
                modifierListSelected: findAllObjectsMatchInArrayOrdered(data.item.modifierListIds, modifierLists),
            });
        });
    }

    function updateModifierListOrder(modifierLists) {
        setData({
            ...data,
            item: {
                ...data.item,
                modifierListIds: modifierLists.map(modifierList => modifierList.id)
            }
        })
    }

    /**
     * Function that triggers form validation.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns the object with the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.name = data.item.name.en.length < 1 ? t('form.requiredField') : "";
        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleDialogState(isOpen, field = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            field: field,
            translations: data.item[field]
        });
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleAttachDialogState(isOpen, field = "") {
        if (isOpen) {
            modifierListApi.fetchListDialog(data.organization.id)
                .then((r) => {
                    formValChangeWithParentElementWithNameAndValue("modifierLists", r.data.returnobject, data, setData);
                })
                .catch((e) => {
                    // console.log(e);
                    setAlertBox({
                        isOpen: true,
                        message: t("errorPages.somethingWentWrong"),
                        backgroundColor: "#ef5350",
                    });
                });
        }

        setAttachDialog({
            ...attachDialog,
            isOpen: isOpen,
            field: field,
        });
    }

    /**
     * This function will trigger the inclusion of the selected modifier lists to the item
     */
    function addItemModifierLists(modifierLists, field) {
        let newModifierListIds = [...data.item.modifierListIds, ...modifierLists.map(modifierList => modifierList.id)];
        setData({
            ...data,
            item: {
                ...data.item,
                [field]: newModifierListIds
            },
            modifierListSelected: data.modifierLists?.filter((modifierList) => newModifierListIds?.includes(modifierList.id))
        });
        setAttachDialog({
            ...attachDialog,
            isOpen: false,
            field: "",
        });
    }

    /**
     * This function will trigger the item field translations configuration
     */
    function configureItemTranslations(translations, field) {
        document.querySelectorAll(`[name="item.${field}.en"]`)[0].value = translations.en;
        setData({
            ...data,
            item: {
                ...data.item,
                [field]: translations
            }
        });
        setDialog({
            ...dialog,
            isOpen: false
        });
    }

    /**
     * Function to trigger the remove of the item.
     * @property {*} event
     * @property {string} field The field of the selected item field.
     */
    function configureTranslations(event, field) {
        handleDialogState(true, field);
    }



    // ===================================== PHOTOS
    /**
     * Callback that handles file upload change.
     * @param {string} fileBase64 the new base64 image 
     */
    function handleFileChange(fileBase64) {
        let newPhotos = data.item.photos;
        newPhotos.push(getAssetDefaultObject(fileBase64, data.item.photos.length));
        formValChangeWithParentElementWithNameAndValue("item.photos", newPhotos, data, setData, t('table.valueReuired'));
    };

    function updatePhotoHiddenState(event, index) {
        const updatedPhotos = data.item.photos.map((photo, i) => {
            if (i === index) {
                return {
                    ...photo,
                    hidden: !photo.hidden,
                };
            }
            return photo;
        });
        formValChangeWithParentElementWithNameAndValue("item.photos", updatedPhotos, data, setData);
    }

    function updatePhotoCoverState(event, index) {
        let updatedPhotos = data.item.photos.map((photo, i) => {
            console.log(i)
            return {
                ...photo,
                cover: (i === index) ? true : false
            };
        });
        formValChangeWithParentElementWithNameAndValue("item.photos", updatedPhotos, data, setData);
    }

    function deletePhoto(event, index) {
        const updatedPhotos = data.item.photos;
        if (index > -1) updatedPhotos.splice(index, 1);
        formValChangeWithParentElementWithNameAndValue("item.photos", updatedPhotos, data, setData);
    }

    /**
    * The rest endpoint to update item photos order
    * 
    * @param {array} photos  the photos after reorder
    */
    function updatePhotoOrder(photos) {
        setData({
            ...data,
            item: {
                ...data.item,
                photos: photos
            }
        })
    }
    // ===================================== ALLERGY

    /**
     * Update a specific allergy for a given item/modifier.
     * 
     * @param {string} field - The allergy to be shown.
     */
    function handleAllergyChange(field) {
        const allergies = data.item.allergies;

        const index = allergies.indexOf(field);

        if (index !== -1) {
            // If target string exists in the array, remove it
            allergies.splice(index, 1);
        } else {
            // If target string doesn't exist, add it to the array
            allergies.push(field);
        }

        formValChangeWithParentElementWithNameAndValue("item.allergies", allergies, data, setData);
    };

    // ===================================== Extra icon

    /**
     * Update a specific extra icon for a given item/modifier.
     * 
     * @param {string} field - The extra icon to be shown.
     */
    function handleExtraIconChange(field) {
        const extraIcons = data.item.extraIcons;

        const index = extraIcons.indexOf(field);

        if (index !== -1) {
            // If target string exists in the array, remove it
            extraIcons.splice(index, 1);
        } else {
            // If target string doesn't exist, add it to the array
            extraIcons.push(field);
        }

        formValChangeWithParentElementWithNameAndValue("item.extraIcons", extraIcons, data, setData);
    };


    // ============ Nutrients
    function handleNutrientChange(index, field, value) {
        let newNutrients = data.item.nutrients;
        newNutrients[index][field] = value;
        formValChangeWithParentElementWithNameAndValue("item.nutrients", newNutrients, data, setData);
    }

    function handleAddNutrient() {
        let newNutrients = data.item.nutrients;
        newNutrients.push(getItemNutrientsDefaultObject());
        formValChangeWithParentElementWithNameAndValue("item.nutrients", newNutrients, data, setData);
    }

    function removeNutrient(index) {
        let newNutrients = data.item.nutrients;
        newNutrients.splice(index, 1);
        formValChangeWithParentElementWithNameAndValue("item.nutrients", newNutrients, data, setData);
    }

    // ============ Prices
    function handlePriceChange(index, field, value) {
        let newPrices = data.item.prices;
        newPrices[index][field] = value;
        formValChangeWithParentElementWithNameAndValue("item.prices", newPrices, data, setData);
    }

    function handleAddPrice() {
        let newPrices = data.item.prices;
        if (newPrices.length === 0)
            newPrices.push(getItemPriceDefaultObject("STANDARD"));
        else
            newPrices.push(getItemPriceDefaultObject());
        formValChangeWithParentElementWithNameAndValue("item.prices", newPrices, data, setData);
    }

    function removePrice(index) {
        let newPrices = data.item.prices;
        newPrices.splice(index, 1);
        formValChangeWithParentElementWithNameAndValue("item.prices", newPrices, data, setData);
    }


    // ===============================================================================RENDER

    if (redirect) {
        navigate(path);
    } else if (!isLoaded) {
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        const { isError } = data;
        // let defaultMenuUtemProvider = "";
        // if (!data.isAdd && data.item !== null) {
        //     if (data.item !== undefined && data.item.provider !== undefined && data.menuItemProviderOptions.length > 0)
        //         defaultMenuUtemProvider = data.menuItemProviderOptions.find(provider => equalsIgnoreCase(provider, data.item.provider));
        // }

        return (
            // ITEM ADD - EDIT
            <CustomGrid role="main" id={"item-" + data.isAdd ? "add" : "edit"} sx={mainTag()}>
                {(organizationID !== undefined && !hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('itemOrModifier.title'), url: `/${getLanguageFromURL()}/organization/${organizationID}/item-or-modifier` },
                            ]}
                            instanceName={(data.item !== null) ? data.item.name.en : ""}
                        />
                    </CustomGrid>
                }

                {(organizationID !== undefined && hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('itemOrModifier.title'), url: `/${getLanguageFromURL()}/item-or-modifier` },
                            ]}
                            instanceName={(data.item !== null) ? data.item.name.en : ""}
                        />
                    </CustomGrid>
                }

                {organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('itemOrModifier.title'), url: `/${getLanguageFromURL()}/item-or-modifier` },
                            ]}
                            instanceName={(data.item !== null) ? data.item.name.en : ""}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    <CustomAddEditTitle
                        isAdd={data.isAdd}
                        title={data.item.isModifier ? t('itemOrModifier.pageTitleModifier') : t('itemOrModifier.pageTitleItem')}
                        subtitle={data.item.isModifier ? t('itemOrModifier.pageTitleModifier') : t('itemOrModifier.pageTitleItem')}
                        instanceName={(data.item !== null) ? data.item.name.en : ""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="item" onSubmit={(e) => { return false; }}>
                        {data.isAdd &&
                            <input type="hidden" />
                        }

                        {!data.isAdd && organizationID !== undefined &&
                            <input defaultValue={organizationID} name="organizationID" type="hidden" id="organizationID" />
                        }

                        {!data.isAdd && itemID !== undefined &&
                            <input defaultValue={itemID} name="id" type="hidden" id="id" />
                        }


                        <TabContext value={value}>
                            <CustomTabs
                                initValue={value}
                                instances={[
                                    { name: t('itemOrModifier.details'), value: "details" },
                                    { name: t('itemOrModifier.photos'), value: "photos" },
                                    { name: t('itemOrModifier.nutrients.label'), value: "nutrients" },
                                    { name: t('itemOrModifier.advanced'), value: "advanced" }
                                ]}
                                setValue={setValue}
                            />

                            <TabPanel value={"details"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="name">{t('label.name')}</InputLabel>
                                        <CustomFlagTextField
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="item.name.en"
                                            id="name"
                                            onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                            defaultValue={data.item !== null && data.item.name !== null ? data.item.name.en : ""}
                                            onTranslationSelect={configureTranslations}
                                            field="name"
                                            error={isError.name.length > 0 ? true : false}
                                            helperText={isError.name}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="remark">{t('itemOrModifier.remark')}</InputLabel>
                                        <CustomFlagTextField
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="item.remark.en"
                                            id="remark"
                                            onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                            defaultValue={data.item !== null && data.item.remark !== null ? data.item.remark.en : ""}
                                            onTranslationSelect={configureTranslations}
                                            field="remark"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="description">{t('label.description')}</InputLabel>
                                        <CustomFlagTextArea
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="item.description.en"
                                            id="description"
                                            onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                            defaultValue={data.item !== null && data.item.description !== null ? data.item.description.en : ""}
                                            onTranslationSelect={configureTranslations}
                                            field="description"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="extraNote">{t('itemOrModifier.extraNote')}</InputLabel>
                                        <CustomTextArea
                                            name="item.extraNote"
                                            id="extraNote"
                                            defaultValue={data.item !== null ? data.item.extraNote : ""}
                                            onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="provider">{t('itemOrModifier.provider')}</InputLabel>
                                        <CustomSelect
                                            id="provider"
                                            name="item.provider"
                                            required={false}
                                            defaultValue={defaultMenuUtemProvider}
                                            options={data.menuItemProviderOptions}
                                            onChange={(event) => onSelectChange(event, data, setData)}
                                        />
                                    </Grid> */}

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="points">{t('itemOrModifier.loyaltyPoints')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            step={getItemLoyaltyPoints().step}
                                            min={getItemLoyaltyPoints().min}
                                            max={getItemLoyaltyPoints().max}
                                            name="item.loyaltyPoints"
                                            value={data.item.loyaltyPoints}
                                            inputAdornmentAfterLabel={'itemOrModifier.points'}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, getItemLoyaltyPoints().length, getItemLoyaltyPoints().min, getItemLoyaltyPoints().max, getItemLoyaltyPoints().isFloat);
                                                if (value === undefined) value = data.item.loyaltyPoints;
                                                formValChangeWithParentElementWithNameAndValue("item.loyaltyPoints", parseInt(value), data, setData)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="pvcRecyclingTax">{t('itemOrModifier.pvcRecyclingTax')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            step={getItemPvcRecyclingTax().step}
                                            min={getItemPvcRecyclingTax().min}
                                            max={getItemPvcRecyclingTax().max}
                                            name="item.pvcRecyclingTax"
                                            value={data.item.pvcRecyclingTax}
                                            required={true}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, getItemPvcRecyclingTax().length, getItemPvcRecyclingTax().min, getItemPvcRecyclingTax().max, getItemPvcRecyclingTax().isFloat);
                                                if (value === undefined) value = data.item.pvcRecyclingTax;
                                                formValChangeWithParentElementWithNameAndValue("item.pvcRecyclingTax", parseInt(value), data, setData)
                                            }}
                                        />
                                    </Grid>

                                    {!data.item.isModifier &&
                                        <Grid item xs={6} md={6}>
                                            <InputLabel required={false} htmlFor="itemCategories">{t('itemCategories.label')}</InputLabel>
                                            <CustomSelectMultilingual
                                                id="itemCategories"
                                                isMultiple={true}
                                                name="item.itemCategories"
                                                translationPath="itemCategories"
                                                required={true}
                                                value={data.item.itemCategories}
                                                options={data.itemCategories}
                                                onChange={(event) => formValChangeWithParentElementWithNameAndValue("item.itemCategories", event.target.value, data, setData)}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={6} md={6}>
                                        <InputLabel required={false} htmlFor="itemVatType">{t('itemVatTypes.label')}</InputLabel>
                                        <CustomSelectMultilingual
                                            id="itemVatType"
                                            isMultiple={false}
                                            name="item.itemVatType"
                                            translationPath="itemVatTypes"
                                            required={true}
                                            value={data.item.itemVatType}
                                            options={data.itemVatTypes}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("item.itemVatType", event.target.value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <FormControlLabel control={<Switch defaultChecked={data.item.isModifier} onChange={(event) => onControlChange(event, data, setData, "item", "isModifier")} />} label={t('itemOrModifier.isModifier')} />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <FormControlLabel control={<Switch defaultChecked={data.item.enabled} onChange={(event) => onControlChange(event, data, setData, "item", "enabled")} />} label={t('itemOrModifier.enabled')} />
                                        {/* <FormControlLabel control={<Switch defaultChecked={data.item.isDeleted} onChange={(event) => onControlChange(event, data, setData, "item", "isDeleted")} />} label={t('label.isDeleted')} /> */}
                                    </Grid>

                                    <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                                        <CustomCollapsableFieldset
                                            label={t('label.price')}
                                            isOpen={true}
                                            children={<>
                                                <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "10px" }}>
                                                    <Grid item xs={12} md={12}>
                                                        {data.item.prices.map((price, index) => (
                                                            <Grid container spacing={2} key={index} sx={{ marginTop: 0, alignItems: "center" }}>

                                                                <Grid item xs={12} md={3}>
                                                                    <InputLabel required={true} htmlFor="priceListType">{t('itemOrModifier.priceType')}</InputLabel>

                                                                    {index > 0 &&
                                                                        <CustomObjectSelect
                                                                            id="priceListType"
                                                                            required={true}
                                                                            value={price.priceListType}
                                                                            options={data.loyaltyCategories?.filter((category) => data.item.prices.map(price => !price.priceListType?.includes(category.id)))}
                                                                            onChange={(event) => handlePriceChange(index, "priceListType", event.target.value)}
                                                                            sx={{ maxHeight: "50px" }}
                                                                        />
                                                                    }

                                                                    {index === 0 &&
                                                                        <CustomTextField
                                                                            type="text"
                                                                            name="priceListType"
                                                                            id="priceListType"
                                                                            value={"STANDARD"}
                                                                            disabled={true}
                                                                        />
                                                                    }
                                                                </Grid>

                                                                <Grid item xs={12} md={2}>
                                                                    <InputLabel required={true} htmlFor="price">{t('label.price')}</InputLabel>
                                                                    <CustomTextField
                                                                        type="number"
                                                                        step={getItemPrice().step}
                                                                        min={getItemPrice().min}
                                                                        max={getItemPrice().max}
                                                                        value={price.price}
                                                                        required={true}
                                                                        onChange={(event) => {
                                                                            let value = validateInputNumber(event.target.value, getItemPrice().length, getItemPrice().min, getItemPrice().max, getItemPrice().isFloat);
                                                                            if (value === undefined) value = price.price;
                                                                            handlePriceChange(index, "price", parseFloat(value))
                                                                        }}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={4}>
                                                                    <InputLabel required={false} htmlFor="price">{t('label.name')}</InputLabel>
                                                                    <CustomTextField
                                                                        type="text"
                                                                        name="priceListName"
                                                                        id="priceListName"
                                                                        value={price.priceListName}
                                                                        onChange={(event) => handlePriceChange(index, "priceListName", event.target.value)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={2}>
                                                                    <InputLabel required={false} htmlFor="discountPercent">{t('loyalty.category.discountPercentage')}</InputLabel>
                                                                    <CustomTextField
                                                                        type="number"
                                                                        step={geItemDiscountPercentage().step}
                                                                        min={geItemDiscountPercentage().min}
                                                                        max={geItemDiscountPercentage().max}
                                                                        name="discountPercent"
                                                                        value={price.discountPercent}
                                                                        inputAdornmentAfterLabel={'loyalty.settings.%'}
                                                                        onChange={(event) => {
                                                                            let value = validateInputNumber(event.target.value, geItemDiscountPercentage().length, geItemDiscountPercentage().min, geItemDiscountPercentage().max, geItemDiscountPercentage().isFloat);
                                                                            if (value === undefined) value = price.discountPercent;
                                                                            handlePriceChange(index, "discountPercent", parseFloat(value))
                                                                        }}
                                                                    />
                                                                </Grid>

                                                                {/* <div className="form-item form-item-2get">
                                                                        <InputLabel required={true} htmlFor="currencyString">{t('label.currency')}</InputLabel>
                                                                        <select defaultValue={defaultCurrencySelected} id="currencyString" name="currencyString" className="form-control-select">
                                                                            <option value="" disabled hidden style={{ "color": "#9ea5aa" }}>{t('actions.selectOption')}</option>
                                                                            {data.currencyOptions.map((currencyString) =>
                                                                                <option value={currencyString} key={currencyString}>{currencyString}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                */}

                                                                {(index > 0 || (index === 0 && data.item.prices?.length === 1)) &&
                                                                    <Grid item xs={12} md={1}>
                                                                        <CustomIconButton
                                                                            keyValue={`btn-delete-${index}`}
                                                                            onClick={removePrice}
                                                                            icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                            iconSx={{ marginRight: "0px!important" }}
                                                                            sx={{
                                                                                backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                                ":hover": {
                                                                                    backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                                },
                                                                                ...cardItemButtonGroupStackButton(),
                                                                                marginTop: "20px"
                                                                            }}
                                                                        >
                                                                            {t('actions.delete')}
                                                                        </CustomIconButton>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        ))}
                                                    </Grid>

                                                    <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                        <CustomIconButton
                                                            sx={addCardActionAreaButtonStyle()}
                                                            backgroundColor="#dcf1f7"
                                                            icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                                            onClick={handleAddPrice}>
                                                            Add
                                                        </CustomIconButton>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            }
                                        />
                                    </Grid>


                                    {!data.item.isModifier &&
                                        <Grid item xs={12} md={12}>
                                            <Grid container spacing={3}>
                                                <Grid item>
                                                    <InputLabel required={false} htmlFor="modifierListIds">{t('modifierList.title')}
                                                        <CustomChip label={data.item.modifierListIds !== null ? data.item.modifierListIds.length : 0} />
                                                    </InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <CustomIconButton
                                                        onClick={() => handleAttachDialogState(true, "modifierListIds")}
                                                        icon={<AddIcon style={attachDialogAddButtonAddIcon()} />}
                                                        iconSx={attachDialogAddButtonIcon()}
                                                        sx={attachDialogAddButton()}
                                                        label={t('modifierList.addModifier')} />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <CustomModifierListDraggableTableItem
                                                    emptyMessage={t('modifierList.emptymodifierList')}
                                                    item={data.modifierListSelected}
                                                    removeModifierList={removeModifierList}
                                                    save={updateModifierListProperties}
                                                    updateModifierListOrder={updateModifierListOrder}
                                                    organizationID={organizationID}
                                                    enableDraggable={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>
                            </TabPanel>

                            <TabPanel value={"photos"} sx={{ paddingTop: "0px" }}>
                                <PhotoComponent
                                    photos={data.item.photos}
                                    showEmptyMessage={true}
                                    emptyMessage={t('assets.emptyPhotos')}
                                    imagePath={`https://storage.usee.gr/usee/organization/${data.organization.id}/item-or-modifier/${data.item.id}/`}
                                    updatePhotoHiddenState={updatePhotoHiddenState}
                                    updatePhotoCoverState={updatePhotoCoverState}
                                    deletePhoto={deletePhoto}
                                    handleFileChange={handleFileChange}
                                    updatePhotoOrder={updatePhotoOrder}
                                />
                            </TabPanel>

                            <TabPanel value={"nutrients"} sx={{ marginTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item sx={{ borderRight: "1px solid lightgrey" }} xs={12} sm={2} >
                                        <Grid container spacing={2} rowGap={2} sx={{ border: "1px solid lightgrey", paddingBottom: "25px", marginTop: "-16px" }} direction="column">
                                            <Grid item xs={1} sx={{ paddingTop: "30px!important", paddingBottom: "20px" }} />
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.grams')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.energy')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.calories')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.fat')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.sat_fat')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.trans_fat')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.carb')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.sugars')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.fiber')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.protein')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.salt')}</strong>
                                            </Grid>
                                            {/* <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.sodium')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.vitaminA')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.vitaminC')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.calcium')}</strong>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <strong>{t('itemOrModifier.nutrients.iron')}</strong>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                    {data.item.nutrients.map((nutrient, index) => (
                                        <Grid item key={index} xs={2} sm={2} >
                                            <Grid container spacing={2} sx={{ border: "1px solid lightgrey", paddingRight: "15px", paddingBottom: "15px", alignItems: "center" }} direction="column">
                                                <Grid item xs={1}>
                                                    <CustomIconButton
                                                        keyValue={`btn-delete-${index}`}
                                                        onClick={removeNutrient}
                                                        icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                        iconSx={{ marginRight: "0px!important" }}
                                                        sx={{
                                                            backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                            ":hover": {
                                                                backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                            },
                                                            ...cardItemButtonGroupStackButton(),
                                                            marginBottom: "7px"
                                                        }}
                                                    >
                                                        {t('actions.delete')}
                                                    </CustomIconButton>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.grams}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrient().step}
                                                        min={getItemNutrient().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.gramsAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = validateInputNumber(event.target.value, -1, getItemNutrient().min, null, getItemNutrient().isFloat);
                                                            if (value === undefined) value = nutrient.grams;
                                                            handleNutrientChange(index, "grams", value)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.energy}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrient().step}
                                                        min={getItemNutrient().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.enrgeyAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = validateInputNumber(event.target.value, -1, getItemNutrient().min, null, getItemNutrient().isFloat);
                                                            if (value === undefined) value = nutrient.energy;
                                                            handleNutrientChange(index, "energy", value)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.calories}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrient().step}
                                                        min={getItemNutrient().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.caloriesAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = validateInputNumber(event.target.value, -1, getItemNutrient().min, null, getItemNutrient().isFloat);
                                                            if (value === undefined) value = nutrient.calories;
                                                            handleNutrientChange(index, "calories", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.fat}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.trans_fatAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.fat;
                                                            handleNutrientChange(index, "fat", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.sat_fat}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.sat_fatAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.sat_fat;
                                                            handleNutrientChange(index, "sat_fat", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.trans_fat}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.trans_fatAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.trans_fat;
                                                            handleNutrientChange(index, "trans_fat", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.carb}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.carbAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.carb;
                                                            handleNutrientChange(index, "carb", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.sugars}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.sugarsAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.sugars;
                                                            handleNutrientChange(index, "sugars", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.fiber}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.fiberAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.fiber;
                                                            handleNutrientChange(index, "fiber", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.protein}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.proteinAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.protein;
                                                            handleNutrientChange(index, "protein", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.salt}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.saltAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.salt;
                                                            handleNutrientChange(index, "salt", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                {/* <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.sodium}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.sodiumAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.sodium;
                                                            handleNutrientChange(index, "sodium", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.vitaminA}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.vitaminAAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.vitaminA;
                                                            handleNutrientChange(index, "vitaminA", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.vitaminC}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.vitaminCAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.vitaminC;
                                                            handleNutrientChange(index, "vitaminC", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.calcium}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.calciumAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.calcium;
                                                            handleNutrientChange(index, "calcium", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <CustomTextField
                                                        value={nutrient.iron}
                                                        type="number"
                                                        variant="standard"
                                                        step={getItemNutrientDetails().step}
                                                        min={getItemNutrientDetails().min}
                                                        inputAdornmentAfterLabel={'itemOrModifier.nutrients.ironAfterLabel'}
                                                        onChange={(event) => {
                                                            let value = event.target.value //validateInputNumber(event.target.value, -1, getItemNutrientDetails().min, null, getItemNutrientDetails().isFloat);
                                                            if (value === undefined) value = nutrient.iron;
                                                            handleNutrientChange(index, "iron", parseFloat(value))
                                                        }}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                    ))}

                                    <Grid item justifyContent={"center"} xs={1.4} sm={1.4} sx={{ border: "1px solid lightgrey" }}>
                                        <CustomIconButton
                                            sx={addCardActionAreaButtonStyle()}
                                            backgroundColor="#dcf1f7"
                                            icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                            onClick={handleAddNutrient}
                                        />
                                    </Grid>
                                </Grid>

                            </TabPanel>

                            <TabPanel value={"advanced"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                        <InputLabel required={false} htmlFor="allergies">{t('itemOrModifier.allergies.label')}</InputLabel>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-eggs`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<EggsIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.eggs')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("eggs")}
                                                    isFocused={data.item.allergies?.includes("eggs")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-wheat`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<WheatIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.wheat')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("wheat")}
                                                    isFocused={data.item.allergies?.includes("wheat")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-fish`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<FishIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.fish')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("fish")}
                                                    isFocused={data.item.allergies?.includes("fish")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-mustard`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<MustardIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.mustard')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("mustard")}
                                                    isFocused={data.item.allergies?.includes("mustard")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-celery`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<CeleryIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.celery')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("celery")}
                                                    isFocused={data.item.allergies?.includes("celery")}
                                                />
                                            </Grid>
                                            {/* 
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}> 
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-gluten`}
                                                    backgroundColor="#dcf1f7"
                                                    // icon={<GlutenIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.gluten')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("gluten")}
                                                    isFocused={data.item.allergies?.includes("gluten")}
                                                />
                                            </Grid> */}
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-nuts`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<PeanutIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.nuts')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("nuts")}
                                                    isFocused={data.item.allergies?.includes("nuts")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-milk`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<MilkIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.milk')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("milk")}
                                                    isFocused={data.item.allergies?.includes("milk")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-molluscs`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<MusselIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.molluscs')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("molluscs")}
                                                    isFocused={data.item.allergies?.includes("molluscs")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-crustaceans`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<ShrimpIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.crustaceans')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("crustaceans")}
                                                    isFocused={data.item.allergies?.includes("crustaceans")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-soya`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<SoyIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.soya')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("soya")}
                                                    isFocused={data.item.allergies?.includes("soya")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-lupin`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<LupineIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.lupin')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("lupin")}
                                                    isFocused={data.item.allergies?.includes("lupin")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-allergy-seasame`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<SesameIcon {...getItemButtonIcon()} />}
                                                    label={t('itemOrModifier.allergies.seasame')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleAllergyChange("seasame")}
                                                    isFocused={data.item.allergies?.includes("seasame")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                                        <InputLabel required={false} htmlFor="extrs-icons">{t('itemOrModifier.extraIcons.label')}</InputLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-extraIcons-vegan`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<VeganIcon {...getItemButtonIcon()} fill="#5f9e70" />}
                                                    label={t('itemOrModifier.extraIcons.vegan')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleExtraIconChange("VEGAN")}
                                                    isFocused={data.item.extraIcons?.includes("VEGAN")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-extraIcons-vegetarian`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<VegetarianIcon {...getItemButtonIcon()} fill="green" />}
                                                    label={t('itemOrModifier.extraIcons.vegetarian')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleExtraIconChange("VEGETARIAN")}
                                                    isFocused={data.item.extraIcons?.includes("VEGETARIAN")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-extraIcons-spicy`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<PepperIcon {...getItemButtonIcon()} fill="red" />}
                                                    label={t('itemOrModifier.extraIcons.spicy')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleExtraIconChange("SPICY")}
                                                    isFocused={data.item.extraIcons?.includes("SPICY")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={6} sm={4} md={4} lg={2.4}>
                                                <CustomIconButton
                                                    keyValue={`btn-extraIcons-new`}
                                                    backgroundColor="#dcf1f7"
                                                    icon={<NewIcon {...getItemButtonIcon()} fill="green" />}
                                                    label={t('itemOrModifier.extraIcons.new')}
                                                    iconSx={allergyButtonIcon()}
                                                    sx={allergyButton()}
                                                    variant='outlined'
                                                    onClick={(event) => handleExtraIconChange("NEW")}
                                                    isFocused={data.item.extraIcons?.includes("NEW")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>

                        <Grid container spacing={2}>
                            {/* <Grid item>
                                <CustomIconButton
                                    variant="outlined"
                                    keyValue="save"
                                    onClick={() => data.isAdd ? addItem(true) : editItem()}
                                    label={t('actions.save')}
                                    sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                />
                            </Grid> */}

                            {data.isAdd &&
                                <>
                                    {/* <Grid item>
                                        <CustomIconButton
                                            variant="outlined"
                                            keyValue="save"
                                            onClick={() => addItem(false)}
                                            label={t('actions.saveAndCreateNew')}
                                            sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                        />
                                    </Grid> */}

                                    <Grid item>
                                        <CustomIconButton
                                            variant="outlined"
                                            keyValue="cancel"
                                            link={path}
                                            label={t('actions.back')}
                                            color="error"
                                        />
                                    </Grid>
                                </>
                            }

                            {!data.isAdd &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        onClick={getItem}
                                        label={t('actions.cancel')}
                                        color="error"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabSecondStyle()}
                        onClick={() => data.isAdd ? addItem(true) : editItem()}
                    >
                        <Tooltip arrow title={t('actions.save')} sx={fabTooltipStyle()}>
                            <SaveIcon />
                        </Tooltip>
                    </Fab>

                    {data.isAdd &&
                        <Fab color="primary"
                            aria-label="add"
                            sx={fabStyle()}
                            onClick={() => addItem(false)}
                        >
                            <Tooltip arrow title={t('actions.saveAndCreateNew')} sx={fabTooltipStyle()}>
                                <SaveIcon style={{ pr: "7px", pt: "5px" }} /><AddIcon fontSize="7px" style={{ float: "right", ml: "11px", mt: "7px" }} />
                            </Tooltip>
                        </Fab>
                    }
                </CustomGrid>

                {attachDialog.isOpen &&
                    <AddModifierListDialog
                        isOpen={attachDialog.isOpen}
                        id="add-modifierLists"
                        title={`${t('actions.add')} ${t('modifierList.title')} ${t('modifierList.to')}`}
                        boldTitle={data.item.name.en}
                        searchLabel={`${t('actions.searchFor')} ${t('modifierList.title')}`}
                        noOptionsText={t('modifierList.emptyModifierListSearch')}
                        actionLabel={t("actions.add")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.add')} ${t('actions.deleteModal.confirm')}`}
                        action={addItemModifierLists}
                        handleOpen={handleAttachDialogState}
                        instances={data?.modifierLists?.filter(modifierList => !data.item?.modifierListIds?.includes(modifierList.id))}
                        dialogName="attachDialog"
                        field={attachDialog.field} />
                }

                {dialog.isOpen &&
                    <CustomTranslationsDialog
                        isOpen={dialog.isOpen}
                        id="translations"
                        title={t('actions.translationModal.title')}
                        action={configureItemTranslations}
                        handleOpen={handleDialogState}
                        translations={data.item[dialog.field]}
                        field={dialog.field} />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={6000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        action={alertBox.action}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default ItemAddEditComponent;