import { CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { Grid } from '@mui/material';
import analyticsApi from 'api/analytics';
import { LineChartLoading } from 'assets/lottie/LottieAnimations';
import CustomIconButton from 'components/Button/CustomIconButton';
import PaymentPieChart from 'components/Charts/PaymentPieChart';
import CustomersOrderBarChart from 'components/Charts/analyitics/CustomersOrderBarChart';
import EmployeesOrderBarChart from 'components/Charts/analyitics/EmployeesOrderBarChart';
import PaymentBarChart from 'components/Charts/analyitics/PaymentBarChart';
import CustomMuiDateTimeRangePicker from 'components/DatePicker/CustomMuiDateTimeRangePicker';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import CustomTypography from 'components/Typography/CustomTypography';
import { lottieChartOptions } from 'constants/animations/lottieOptions';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsGridDiv, lumanosimoTitleStyle, mainTagSearchItemOrderStatusIcon } from 'themes/defaultThemes';
import { dateTimeFormatterFilter } from 'utils/functions';
import { transformDataBar } from 'utils/functions/analytics';

/**
 * The AnalyticsExtra, that display customized {@link Grid}to provide the list of analytics extra detais
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AnalyticsExtra() {
    const { t } = useTranslation();
    const [currency, setCurrency] = useState("€");
    const [paymentMethodsChartPieData, setPaymentMethodsChartPieData] = useState([]);
    const [paymentMethodsChartPieDataBar, setPaymentMethodsChartPieDataBar] = useState([]);
    const [orderData, setOrderData] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // for search
    const [search, setSearch] = useState({
        organizationID: "",
        outletIDs: [],
        dateFrom: dateTimeFormatterFilter(),
        dateTo: null,
        employeeID: null
    });
    const [open, setOpen] = useState(false);

    function getData() {
        analyticsApi.paymentMethodPieChartDataHistoryBar(search).then((r) => {
            setPaymentMethodsChartPieDataBar({
                ...r.data.returnobject,
                series: r.data.returnobject?.series?.map((serie) => serie === null ? 0 : serie)
            });
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
        analyticsApi.paymentMethodPieChartDataHistoryPie(search).then((r) => {
            setPaymentMethodsChartPieData({
                ...r.data.returnobject,
                series: r.data.returnobject?.series?.map((serie) => serie === null ? 0 : serie)
            });
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })


        analyticsApi.fetchOrderAnalytics(search).then((r) => {
            setOrderData(r.data.returnobject);
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getData();
    }, [search]);

    // =============== RENDER

    return (
        <Grid container gap={2} sx={{ textAlign: "center", placeContent: "center" }}>
            <Grid item xs={1}>
                <CustomIconButton
                    keyValue={`btn-search-date`}
                    onClick={() => setOpen(!open)}
                    icon={<CalendarMonthIcon />}
                    iconSx={mainTagSearchItemOrderStatusIcon()}
                    sx={{ ...mainTagSearchItemOrderStatusIcon(), borderRadius: "10px" }}
                />

                <CustomDialogBasedOnScreenChildren
                    isOpen={open}
                    handleClose={() => setOpen(false)}
                    handleOpen={() => setOpen(true)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    actionLabel={t('actions.ok')}
                    handleAction={() => setOpen(false)}
                    children={
                        <>
                            <CustomMuiDateTimeRangePicker
                                dateRange={search}
                                setDateRange={setSearch}
                            />
                        </>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <div style={{ ...analyticsGridDiv(), width: "100%" }}>
                    {(isLoaded && paymentMethodsChartPieData?.series?.length > 0) &&
                        <>
                            <PaymentPieChart
                                title={t('analytics.chart.paymentMethods.label')}
                                height={450}
                                labels={paymentMethodsChartPieData.labels}
                                series={paymentMethodsChartPieData.series}
                            />
                            <PaymentBarChart
                                title={""}
                                height={120}
                                labels={paymentMethodsChartPieDataBar.labels}
                                series={transformDataBar(paymentMethodsChartPieDataBar).series}
                                currency={currency}
                            />
                        </>
                    }
                    {(!isLoaded || paymentMethodsChartPieData?.series === undefined || paymentMethodsChartPieData?.series?.length === 0) &&
                        <>
                            <Lottie
                                animationData={LineChartLoading}
                                {...lottieChartOptions}
                            />
                            <CustomTypography
                                variant="body2"
                                text={t('analytics.chart.noData')}
                                sx={{ lumanosimoTitleStyle }}
                            />
                        </>
                    }
                </div>
            </Grid>

            <Grid item xs={6}>
                <div style={analyticsGridDiv()}>
                    {(isLoaded && (orderData !== null && orderData?.employeeOrders && orderData?.employeeOrders?.length > 0)) &&
                        <>
                            <EmployeesOrderBarChart
                                title={t('analytics.chart.employees.totalOrders')}
                                employeeData={orderData.employeeOrders}
                            />
                        </>
                    }
                    {(!isLoaded || (isLoaded && (orderData === null || orderData?.employeeOrders?.length === 0))) &&
                        <>
                            <Lottie
                                animationData={LineChartLoading}
                                {...lottieChartOptions}
                            />
                            <CustomTypography
                                variant="body2"
                                text={t('analytics.chart.noData')}
                                sx={{ lumanosimoTitleStyle }}
                            />
                        </>
                    }
                </div>
            </Grid>

            <Grid item xs={5}>
                <div style={analyticsGridDiv()}>
                    {(isLoaded && (orderData !== null && orderData?.customerOrders && orderData?.customerOrders?.length > 0)) &&
                        <>
                            <CustomersOrderBarChart
                                title={t('analytics.chart.customers.totalOrders')}
                                customerData={orderData.customerOrders}
                            />
                        </>
                    }
                    {(!isLoaded || (isLoaded && (orderData === null || orderData?.customerOrders?.length === 0))) &&
                        <>
                            <Lottie
                                animationData={LineChartLoading}
                                {...lottieChartOptions}
                            />
                            <CustomTypography
                                variant="body2"
                                text={t('analytics.chart.noData')}
                                sx={{ lumanosimoTitleStyle }}
                            />
                        </>
                    }
                </div>
            </Grid>


            <Grid item xs={12} sm={6} md={4}>

            </Grid>
        </Grid>
    );
}

export default AnalyticsExtra;