import SyncIcon from '@mui/icons-material/Sync';
import { Button, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * The Custom Button, that display an button.
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomRefreshButton({ label, onClick }) {
   const { t } = useTranslation();

   return (
      <ThemeProvider theme={createTheme({
         components: {
            MuiButton: {
               styleOverrides: {
                  root: {
                     backgroundColor: "#fff",
                     margin: "0px",
                     fontSize: "8px",
                     fontWeight: "900",
                     color: "#2596be",
                     ":hover": {
                        backgroundColor: "#000"
                     }
                  },
                  startIcon: {
                     marginRight: "0px!important"
                  }
               }
            }
         }
      })}>

         <Tooltip arrow title={t('actions.reload')}>
            <Button startIcon={<SyncIcon style={{ marginRight: "0px" }} />} onClick={onClick} style={{ backgroundColor: "#54b1d2", borderRadius: "50px", color: "white", padding: "8px 0px", minWidth: "40px", marginRight: "10px" }} >{label}</Button>
         </Tooltip>
      </ThemeProvider>
   );
}

export default CustomRefreshButton;