import React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { createTheme, InputAdornment, ThemeProvider } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

/**
 * The Custom Chip Multi Select Field, that display customized {@link Chip}.
 * @property {string} id the text field id
 * @property {string} labelID the text field label id
 * @property {string} name the text field name
 * @property {string} label Applies the theme typography styles.
 * @property {array} defaultValue the text field default value
 * @property {boolean} required if the value is required at this text field 
 * @property {boolean} error if text field is at an error state
 * @property {boolean} isMultiple if multiple selection is enabled
 * @property {function} onChange the function that handles the select input change
 * @property {function} onDelete the function that handles the removal of a chip
 * @property {string} startAdornmentLabel the label to be presented at the start of the input field
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSelectWithChip({ id = "", labelID = "", name = "", label, defaultValue, value, required = true, error = false, isMultiple = true, color = "default", onChange, onDelete, options, startAdornmentLabel, sx, width = "100%" }) {
    function getMuiTheme() {
        return createTheme({
            components: {
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            borderRadius: "30px",
                            width: width,
                            ...sx
                        }
                    }
                }
            }
        })
    }

    return (
        <>
            {label !== undefined && <InputLabel id={labelID} >{label}</InputLabel>}
            <ThemeProvider theme={getMuiTheme()}>
                <Select
                    labelId={labelID}
                    multiple={isMultiple}
                    onChange={onChange}
                    input={<OutlinedInput id={id} name={name} startAdornment={
                        <InputAdornment disableTypography position="end" sx={{ fontWeight: "bold" }}>
                            {startAdornmentLabel}</InputAdornment>}
                    />}
                    required={required}
                    defaultValue={defaultValue}
                    value={value}
                    renderValue={(selected) => {
                        return (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip color={color} key={value} label={value} onDelete={(event) => onDelete(event, value)} onMouseDown={(event) => { event.stopPropagation(); }} />
                            ))}
                        </Box>
                        );
                    }}
                    MenuProps={MenuProps}
                >
                    {options.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </ThemeProvider>
        </>
    );
}

export default CustomSelectWithChip;