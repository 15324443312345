import { Card, CardActionArea } from '@mui/material';
import React from 'react';
import CustomBarcode from 'components/Barcode/CustomBarcode';

/**
 * The Custom Loyalty Card front side, that display the customized front side of the {@link Card}.
 * 
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {object} category loyalty card details
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomLoyaltyCardBackSide({ category, sx, onClick, userFullName = "" }) {
    return (
        <Card variant="outlined"
            key={`card-${category.id}-back`}
            sx={{ ...sx }}
            onClick={onClick}
        >
            <CardActionArea sx={{ justifyContent: 'flex-end', height: "100%", width: "100%", textAlignLast: "center" }} key={`card- back-ontent-${category.id}`}>
                <CustomBarcode {...category.barcodeConfiguration} value={userFullName !== "" ? category.loyaltyNumber : undefined} preview={userFullName === ""} />
            </CardActionArea>
        </Card>
    );
}

export default CustomLoyaltyCardBackSide;