export function findCurrency(currencyCode) {
    switch (currencyCode) {
        case "EUR":
            return "€"
        default:
            return "€"
    }
}

export function priceTextWithCurrency(currencyCode, price) {
    return `${findCurrency(currencyCode)} ${parseFloat(price).toFixed(2)}`;
}


export function createItemsRequirePayment(order) {
    const itemsRequirePayment = [];

    // Create a map to store already paid quantities for each item
    const paidQuantitiesMap = new Map();

    order.paymentActionsHistory?.forEach((paymentAction) => {
        paymentAction?.items?.forEach((item) => {
            const key = `${item.indexInOrderCart}-${item.itemID}`;
            const paidQuantity = paidQuantitiesMap.get(key) || 0;

            // Update the map with the new paid quantity
            paidQuantitiesMap.set(key, paidQuantity + item.quantityPaid);
        });
    });
    // store unavailable quantity of each item
    const unavailableQuantitiesMap = new Map();
    order.cart?.forEach((cartItem) => {
        if (cartItem?.unavailable) {
            const key = `${cartItem.indexInOrderCart}-${cartItem.itemID}`;
            const unavailableQuantity = unavailableQuantitiesMap.get(key) || 0;
            // Update the map with the new paid quantity
            paidQuantitiesMap.set(key, unavailableQuantity + cartItem.unavailableQuantity);
        }
    })

    // Combine the paidQuantitiesMap and unavailableQuantitiesMap
    const mergedMap = new Map([...paidQuantitiesMap]);
    unavailableQuantitiesMap.forEach((quantity, key) => {
        if (mergedMap.has(key)) {
            // If the key already exists in mergedMap, add the quantities
            mergedMap.set(key, mergedMap.get(key) + quantity);
        } else {
            // If the key doesn't exist, just set it
            mergedMap.set(key, quantity);
        }
    });


    order.cart?.forEach((item, index) => {
        const key = `${index}-${item.id}`;
        const paidQuantity = mergedMap.get(key) || 0;

        const remainingQuantity = item.quantity - paidQuantity;

        if (remainingQuantity > 0) {
            itemsRequirePayment.push({
                indexInOrderCart: index,
                id: item.id,
                description: item.description,
                modifiers: item.modifiers,
                notes: item.notes,
                price: item.price,
                name: item.name,
                pvcRecyclingTax: item.pvcRecyclingTax,
                quantity: remainingQuantity,
            });
        }
    });

    return itemsRequirePayment;
}

/**
* Calculate total quantity based on payment selection.
*
* @param {Object} itemsRequirePayment - The order object containing cart details.
* @param {Array} selectedItemsToPay - Array of selected items for payment.
* @param {boolean} payAll - Flag indicating whether to pay for all items or selected ones.
* @returns {number} - Total quantity to be paid.
*/
export function calculateChipQuantity(itemsRequirePayment, selectedItemsToPay, payAll) {
    // Check if payAll is true
    if (payAll) {
        // If payAll is true, sum the quantities of all items in the order's cart
        const totalQuantity = itemsRequirePayment?.reduce((sum, item) => sum + (item.quantity || 0), 0);
        return totalQuantity || 0;
    } else {
        // If payAll is false, sum the quantities of selected items to pay
        const totalQuantity = selectedItemsToPay?.reduce((sum, selectedItem) => sum + (selectedItem.quantityPaid || 0), 0);
        return totalQuantity || 0;
    }
}

/**
 * Calculate total quantity based on payment selection across multiple orders.
 *
 * This function supports calculating the total quantity to be paid either for all items across
 * multiple orders or for selected items in specified orders. The `selectedItemsToPayPerOrder` parameter
 * is expected to be an array of objects, each containing an `orderID` and a `selectedItemsToPayPerOrder` array,
 * indicating the items selected for payment in that order. The function iterates over each specified
 * order, summing up the quantities of either all items in the cart or the selected items, based on
 * the `payAll` flag.
 *
 * @param {Array} orders - An array of order objects, each containing cart details and an orderID.
 * @param {Array} selectedItemsToPayPerOrder - Array of objects, each including an `orderID` and an array of `selectedItemsToPayPerOrder`.
 * @param {boolean} payAll - Flag indicating whether to pay for all items or selected ones.
 * @returns {number} - Total quantity to be paid across all specified orders.
 */
export function calculateChipQuantityBulkOrdersPayment(orders, selectedItemsToPayPerOrder, payAll) {
    let totalQuantity = 0;

    if (payAll) {
        // Sum the quantities of all items across all orders
        orders.forEach(order => {
            totalQuantity += order.itemsRequirePayment?.reduce((sum, item) => sum + (item.quantity || 0), 0) || 0;
        });
    } else {
        // Iterate over each entry in selectedItemsToPayPerOrder to sum the quantities of selected items
        selectedItemsToPayPerOrder?.forEach(selection => {
            // Find the corresponding order by orderID
            const order = orders?.find(o => o.orderID === selection.orderID);
            if (order) {
                // Sum the quantities of selected items in this order
                if (selection?.selectedItemsToPay?.length > 0)
                    totalQuantity += selection?.selectedItemsToPay?.reduce((sum, selectedItem) => {
                        const itemInOrder = order.itemsRequirePayment?.find(item => item.id === selectedItem.itemID);
                        if (itemInOrder) {
                            // Ensure not to exceed the actual item quantity in the cart
                            return sum + Math.min(selectedItem.quantityPaid, itemInOrder.quantity);
                        }
                        return sum;
                    }, 0);
                else {
                    totalQuantity += 0;
                }
            }
        });
    }

    return totalQuantity;
}


/**
* Calculate total quantity.
*
* @param {Array} cart - Array of selected items for payment.
* @returns {number} - Total quantity to be paid.
*/
export function calculateCartChipQuantity(items) {
    const totalQuantity = items?.reduce((sum, item) => sum + (item.quantity || 0), 0);
    return totalQuantity || 0;
}

/**
* Generate an array of tabs based on certain conditions.
*
* @param {Array} itemsRequirePayment - Array of items that require payment.
* @param {Object} order - The order object containing payment actions history.
* @param {Object} t - The translation function for internationalization.
* @returns {Array} - Array of tabs with name and value properties.
*/
export function generateTabs(itemsRequirePayment, order, t) {
    const tabs = [];

    // Check if itemsRequirePayment has items
    if (itemsRequirePayment?.length > 0) {
        // If itemsRequirePayment has items, add a tab for remaining items to pay
        tabs.push({ name: t('pda.remainToPay'), value: "1" });
    }

    // Always add a tab for viewing the order cart
    tabs.push({ name: t('customer.view.tabs.orders.order.cart'), value: "0" });

    // Check if order has payment actions history
    if (order.paymentActionsHistory?.length > 0) {
        // If order has payment actions history, add a tab for payment history
        tabs.push({ name: t('pda.payment.label'), value: "2" });
    }

    return tabs;
}

/**
 * Prepare items for payment action history by transforming the item array.
 *
 * @param {Array} items - Array of items to be transformed for payment action history.
 * @returns {Array} - Transformed array with properties for payment action history.
 */
export function prepareItemsForPaymentActionHistory(items) {
    // Ensure items array is provided and not empty
    if (!Array.isArray(items) || items.length === 0) {
        return [];
        // throw new Error("No items provided or items array is empty.");
    }

    // Transform and filter items in a single iteration
    const transformedAndFilteredItems = items.reduce((acc, item) => {
        console.log(item)
        try {
            // Calculate the total item price from item plus modifiers and taxes
            const totalItemPrice = calculateTotalPriceFromItemPlusSumModifiersPricePlusPvcRecyclingTax(item);

            // Optionally, apply discounts if any (assuming a function or logic to calculate it exists)
            // const discount = item.discount ? calculateDiscountForItem(item, totalItemPrice) : 0;
            // const finalPrice = totalItemPrice - discount;

            // Calculate amount paid (considering the quantity)
            const amountPaid = totalItemPrice * item.quantity;

            // If quantityPaid (quantity) is more than 0, add the item to the accumulator
            if (item.quantity > 0) {
                acc.push({
                    indexInOrderCart: item.indexInOrderCart,
                    itemID: item.id,
                    price: totalItemPrice,
                    amountPaid,
                    quantityPaid: item.quantity,
                });
            }
        } catch (error) {
            // console.error(`Error processing item ${item.id}: ${error.message}`);
        }

        return acc;
    }, []);

    return transformedAndFilteredItems;
}

/**
* Calculate the total price for an item, including modifiers and PVC recycling tax.
*
* @param {Object} item - The item object with properties like modifiers, price, and pvcRecyclingTax.
* @returns {number} - Total price for the item.
*/
export function calculateTotalPriceFromItemPlusSumModifiersPricePlusPvcRecyclingTax(item) {
    const modifiersPriceSum = item?.modifiers?.reduce((sum, modifier) => sum + modifier.price, 0);
    return item.price + modifiersPriceSum + item.pvcRecyclingTax;
}

/**
 * Prepare selected items for payment action history by transforming the item array.
 *
 * @param {Array} selectedItems - Array of selected items to be transformed for payment action history.
 * @param {Array} itemsRequirePayment - Array of items that require payment with their respective quantities.
 * @param {Array} newSelectedItems - The new array of selected items to add or update.
 * @returns {Array} - Transformed array with properties for payment action history.
 */
export function prepareSelectedItemsForPaymentActionHistory(selectedItems, itemsRequirePayment, newSelectedItems) {
    let updatedSelectedItems = [...selectedItems];

    // Process each new selected item
    newSelectedItems?.forEach(newItem => {

        // Check if the item already exists in selectedItems
        const existingIndex = updatedSelectedItems.findIndex(item => item.itemID === newItem.cartItem.id);

        if (existingIndex !== -1) {
            // Item exists, check if quantities match
            if (updatedSelectedItems[existingIndex].quantityPaid === newItem.newQuantityTaPay) {
                // If quantities match, remove the existing item
                updatedSelectedItems.splice(existingIndex, 1);
            } else {
                // If quantities don't match, update quantity
                updatedSelectedItems[existingIndex].quantityPaid = newItem.newQuantityTaPay;
            }
        } else {
            // Item doesn't exist, add it
            updatedSelectedItems.push({
                indexInOrderCart: newItem.index,
                itemID: newItem.cartItem?.id,
                amountPaid: calculateTotalPriceFromItemPlusSumModifiersPricePlusPvcRecyclingTax(newItem.cartItem) * newItem.newQuantityTaPay,
                price: calculateTotalPriceFromItemPlusSumModifiersPricePlusPvcRecyclingTax(newItem.cartItem),
                name: newItem.cartItem?.name,
                quantityPaid: newItem.newQuantityTaPay,
            });
        }
    });

    // Validate quantities against itemsRequirePayment
    updatedSelectedItems?.forEach(selectedItem => {
        const matchingItem = itemsRequirePayment.find(item => item.id === selectedItem.itemID);
        if (matchingItem && selectedItem.quantityPaid > matchingItem.quantity) {
            // If quantity exceeds available quantity, set it to the available quantity
            selectedItem.quantityPaid = matchingItem.quantity;
        }
    });

    return updatedSelectedItems;
}

/**
 * Maps items that require payment to the selected items, adjusting quantities as needed and
 * considering both itemID and indexInOrderCart for matching.
 * 
 * This version of the function ensures that an item is considered the same not only by its ID
 * but also its position (indexInOrderCart) in the order. This is important for scenarios where
 * the same item might be added to the cart multiple times at different positions and potentially
 * with different payment requirements. The function iterates over the items that require payment
 * and looks for matches in the selected items based on these criteria, adjusting quantities as necessary.
 * 
 * @param {Array} selectedItems - The list of selected items by the user. Each item should include
 *                                `itemID`, `quantityPaid`, and `indexInOrderCart`.
 * @param {Array} itemsRequirePayment - The list of items requiring payment. Each item in this list
 *                                      should have an `id`, a `quantity`, and an `indexInOrderCart`.
 * @returns {Array} A list of items requiring payment with updated quantities based on user selection,
 *                  including consideration for the specific order position of each item.
 */
export function previewSelectedItemsToPay(selectedItems, itemsRequirePayment) {
    return itemsRequirePayment?.map(requiredItem => {
        // Find a matching item based on both itemID and indexInOrderCart
        const selectedItem = selectedItems?.find(item =>
            item.itemID === requiredItem.id && item.indexInOrderCart === requiredItem.indexInOrderCart
        );

        const quantityPaid = selectedItem ? selectedItem.quantityPaid : 0;

        return {
            ...requiredItem,
            quantity: quantityPaid
        };
    });
}

/**
 * Maps items that require payment to the selected items, adjusting quantities as needed and
 * considering both itemID and indexInOrderCart for matching.
 * 
 * This version of the function ensures that an item is considered the same not only by its ID
 * but also its position (indexInOrderCart) in the order. This is important for scenarios where
 * the same item might be added to the cart multiple times at different positions and potentially
 * with different payment requirements. The function iterates over the items that require payment
 * and looks for matches in the selected items based on these criteria, adjusting quantities as necessary.
 * 
 * @param {Array} selectedItems - The list of selected items by the user. Each item should include
 *                                `itemID`, `quantityPaid`, and `indexInOrderCart`.
 * @param {Array} itemsRequirePayment - The list of items requiring payment. Each item in this list
 *                                      should have an `id`, a `quantity`, and an `indexInOrderCart`.
 * @returns {Array} A list of items requiring payment with updated quantities based on user selection,
 *                  including consideration for the specific order position of each item.
 */
export function previewSelectedItemsToPayBulkPayAll(selectedItems, itemsRequirePayment) {
    return itemsRequirePayment?.map(requiredItem => {
        // Find a matching item based on both itemID and indexInOrderCart
        const selectedItem = selectedItems?.find(item =>
            item.itemID === requiredItem.id && item.indexInOrderCart === requiredItem.indexInOrderCart
        );

        const quantityPaid = selectedItem ? selectedItem.quantityPaid : 0;

        return {
            ...requiredItem,
            quantity: quantityPaid
        };
    });
}


/**
 * Calculates the total amount due from selected items.
 *
 * This function iterates over a list of selected items, each containing a quantity and a price,
 * and calculates the total amount by summing up the product of the quantity and price for each item.
 * It is designed to work with any array of objects that represent items with quantity and price fields,
 * making it versatile for different types of shopping cart or order summaries.
 *
 * @param {Array} selectedItems - An array of objects representing the selected items. Each object must have
 *                                at least two properties: `quantity` (number) and `price` (number), where
 *                                `quantity` represents the number of units selected, and `price` represents the
 *                                cost per unit.
 * @returns {number} - The total amount due for the selected items, calculated as the sum of the product of
 *                     quantity and price for each item. The return value is a number, which can be a floating
 *                     point number if any price is specified as a decimal.
 */
export function calculateTotalAmountFromSelectedItems(selectedItems, payAll) {
    if (payAll) {
        return selectedItems.reduce((total, item) => {
            return total + (item.quantity * calculateTotalPriceFromItemPlusSumModifiersPricePlusPvcRecyclingTax(item));
        }, 0);
    } else {
        return selectedItems.reduce((total, item) => {
            return total + (item.quantityPaid * item.price);
        }, 0);
    }
}

/**
 * Calculates the total amount due from selected items across multiple orders, based on payment actions.
 *
 * This function iterates over a list of orders, each associated with a unique orderID and a paymentAction array.
 * The paymentAction array for each order contains items to be paid, with each item including details necessary for
 * calculating the total payment amount. The function can calculate the total based on all items (if payAll is true)
 * or based on specifically paid quantities and amounts (if payAll is false).
 *
 * @param {Array} selectedItems - An array of objects, each representing an order with an `orderID` and
 *                                a `paymentAction` array. The `paymentAction` array contains objects that
 *                                include either `quantity` and `price` fields or `quantityPaid` and `amountPaid` fields.
 * @param {boolean} payAll - A flag indicating whether to calculate the total amount based on all items (`true`)
 *                           or based on the quantities and amounts specified in `paymentAction` (`false`).
 * @returns {number} - The total amount due across all orders, based on the specified payment actions.
 */
export function calculateBulkOrdersTotalAmountFromSelectedItems(selectedItems, payAll) {
    let orderTotal = 0;
    if (payAll) {
        return selectedItems?.reduce((grandTotal, { itemsRequirePayment }) => {
            orderTotal = itemsRequirePayment?.reduce((total, item) => {
                // Calculate total based on all items' quantity and price
                return total + (item.quantity * calculateTotalPriceFromItemPlusSumModifiersPricePlusPvcRecyclingTax(item));
            }, 0);

            return grandTotal + orderTotal;
        }, 0);
    } else {
        return selectedItems?.reduce((grandTotal, { selectedItemsToPay }) => {
            orderTotal = selectedItemsToPay?.reduce((total, item) => {
                // Calculate total based on specified quantities and amounts to be paid
                return total + (item.quantityPaid * item.price);
            }, 0);

            return grandTotal + orderTotal;
        }, 0);
    }
}

export default class functions {
    static findCurrency(currencyCode) { return findCurrency(currencyCode); }
    static priceTextWithCurrency(currencyCode, price) { return priceTextWithCurrency(currencyCode, price); }
}


