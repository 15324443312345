import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete, Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItem,
    ListItemText,
    Popper,
    TextField, ThemeProvider,
    Typography,
    createTheme
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * The AddModifierListDialog, that display a customized dialog
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {string} title the dialog title
 * @property {string} searchLabel the search autocomplete field label
 * @property {string} noOptionsText the no options text for autocomplete
 * @property {string} actionLabel the dialog action label
 * @property {array} instances the array of modifier list
 * @property {function} action the function that handles the dialog action button click
 * @property {function} handleOpen the function that handles the dialog open state
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AddModifierListDialog({ isOpen = false, id = "dialog", title, boldTitle, searchLabel, noOptionsText, actionLabel, action, instances = [], handleOpen, field, dialogName }) {
    // const PopperMy = function (props) {
    //     const anchorEl = document.getElementById('id="modifier-item-select-listbox"');
    //     return (<Popper {...props}
    //         modifiers={[
    //             {
    //                 name: 'flip',
    //                 enabled: false, // Disable flipping if necessary
    //             },
    //             {
    //                 name: 'preventOverflow',
    //                 enabled: false, // Disable overflow prevention if necessary
    //                 options: {
    //                     altAxis: true, // Adjust alternate axis behavior if necessary
    //                     tether: false, // Disable tethering if necessary                        
    //                     rootBoundary: 'viewport',
    //                 },
    //             },
    //         ]}
    //         // anchorEl={anchorEl}
    //         style={{ width: "100%", margin: "20px", height: "100%", overflowY: 'hidden' }}
    //         popupRef={(node) => {
    //             console.log(node, node.querySelector('.Mui-selected'))
    //             if (node) {
    //                 // Scroll to the selected item after the popup opens
    //                 const selectedOption = node.querySelector('.Mui-selected');
    //                 if (selectedOption) {
    //                     selectedOption.scrollIntoView({ block: 'nearest' });
    //                 }
    //             }
    //         }}
    //         placement='bottom'
    //         keepMounted={false}
    //     />)
    // }

    const { t } = useTranslation();
    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);
    const [value, setValue] = useState([]);

    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpen) {
        setOpen(isOpen);
        handleOpen(isOpen, field);
    }

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiFilledInput: {
                    styleOverrides: {
                        input: {
                            backgroundColor: "white",
                        },
                        root: {
                            backgroundColor: "white",
                            border: "1px solid grey"
                        }
                    }
                },
                MuiDialogContainer: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "white",
                            paddingBottom: "100px"
                        }
                    }
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "white",
                            zIndex: 111111111,
                            padding: 0
                        }
                    }
                },
                MuiDialogContent: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "white",
                            paddingBottom: "100px"
                        }
                    }
                },
                MuiDialog: {
                    styleOverrides: {
                        paper: {
                            minWidth: "60%",
                            maxWidth: "90%",
                            minHeight: "70%",
                            maxHeight: "90%",
                            overflowY: "hidden",
                            // top: "10%",
                        },
                        container: {
                            textAlign: "-webkit-center"
                        }
                    }
                },
                MuiListItemText: {
                    styleOverrides: {
                        secondary: {
                            fontWeight: 700,
                            fontSize: "11px"
                        }
                    }
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        secondary: {
                            // height: "900px",
                            overflow: "scroll"
                        },
                        listbox: {
                            marginTop: 0,
                            padding: 0,
                            height: "100%"
                        },
                        popper: {
                            height: "fit-content"
                        },
                        paper: {
                            marginLeft: "25px",
                            marginRight: "25px",
                            // overflowY: "hidden",
                            zIndex: "2"
                        }
                    }
                },
                MuiChip: {
                    styleOverrides: {
                        label: {
                            whiteSpace: "pre-wrap!important"
                        },
                        root: {
                            padding: "7px!important",
                            marginBottom: "10px",
                            height: "fit-content"
                        }
                    }
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            paddingRight: "50px",
                            zIndex: "110000000000"
                        }
                    }
                }
            }
        });
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Dialog
                id={id}
                open={open}
                // scroll={"papper"}
                onClose={() => handleDialogState(false)}
                aria-describedby="alert-dialog-slide-description"
                className="dialog-title"
            >
                <DialogTitle className="dialog-headers" style={{ textAlignLast: "center" }}>
                    <Typography variant="title" sx={{ fontSize: "18px" }}>{`${title} `}</Typography>
                    <Typography variant="title" sx={{ color: "#205d7b", fontSize: "19px" }}>{boldTitle}</Typography>
                    {/* <IconButton onClick={() => handleDialogState(false)} sx={{ float: "right" }}>
                        <CloseRounded />
                    </IconButton> */}
                </DialogTitle>
                <DialogContent sx={{ marginBottom: "80px", marginTop: "20px" }} id="dialog-content">

                    <Autocomplete
                        id="modifier-list-select"
                        options={instances}
                        autoHighlight
                        disableCloseOnSelect={true}
                        disableListWrap={false}
                        multiple={true}
                        open={true}
                        limitTags={2}
                        getOptionLabel={(option) => option.name}
                        noOptionsText={noOptionsText}
                        popupIcon={<></>}
                        sx={{ height: "100%", border: "none" }}
                        ListboxProps={{
                            style: {
                                height: '100%', border: "none", width: "100%",
                            }
                        }}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            // setTimeout(() => {
                            //     const optionEl = document.getElementById(newValue[newValue.length - 1].id);
                            //     optionEl?.scrollIntoView();
                            // }, 1);
                        }}
                        disablePortal={true}
                        renderOption={(props, option, { selected }) => {
                            return <ListItem id={option.id}>
                                <Grid
                                    item
                                    key={option.id}
                                    {...props}
                                    sx={{ borderBottom: (instances[instances.length - 1].id !== option.id) ? "1px solid darkgrey" : "none", paddingTop: "20px", display: "contents", width: "100%" }}>

                                    <Grid container gap={0} alignItems='center' sx={12} sm={12}>
                                        <Grid item xs={1}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selected}
                                            />
                                        </Grid>

                                        <Grid item xs={9}>
                                            <Typography variant="inherit" sx={{ fontWeight: 600, fontSize: "15px" }}>{option.name}</Typography>
                                            <ListItem style={{ display: "block" }} >
                                                {option.attachedModifiers.length > 0 &&
                                                    option.attachedModifiers.map((modifier) => {
                                                        return (
                                                            <Chip label={modifier.name} />
                                                        );
                                                    })
                                                }
                                                {option.attachedModifiers.length === 0 &&
                                                    <Typography variant="inherit" sx={{ fontSize: "15px", color: "#205d7b" }}>{t('modifierList.emptymodifierList')}</Typography>
                                                }
                                            </ListItem>
                                        </Grid>

                                        <Grid item sx={{ right: 0 }} xs={2}>
                                            <ListItem style={{ display: "block" }}>
                                                <ListItemText secondary={option.allowMultipleSelection ? t('label.multiple') : t('label.single')}></ListItemText>
                                                {option.allowMultipleSelection &&
                                                    <>
                                                        <div style={{ display: "flex" }}>
                                                            <Typography variant="inherit" sx={{ fontSize: "12px" }}>{`${t('modifierList.minModifiers')}: `}</Typography>
                                                            <Typography variant="inherit" sx={{ fontWeight: 600, fontSize: "12px" }}>{option.minModifiers}</Typography>
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Typography variant="inherit" sx={{ fontSize: "12px" }}>{`${t('modifierList.maxModifiers')}: `}</Typography>
                                                            <Typography variant="inherit" sx={{ fontWeight: 600, fontSize: "12px" }}>{option.maxModifiers}</Typography>
                                                        </div>
                                                    </>
                                                }
                                            </ListItem>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={searchLabel}
                                variant='filled'
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />

                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button
                        className="dialog-cancel-btn"
                        sx={{
                            textTransform: "none !important",
                            backgroundColor: "#ebf5f9",
                            ":hover": {
                                backgroundColor: (value.length !== 0 ? "#ebf5f9" : "lightgrey")
                            }
                        }}
                        onClick={() => handleDialogState(false)}>{t('actions.cancel')}
                    </Button>

                    <Button
                        className="dialog-action-btn"
                        sx={{
                            textTransform: "none !important",
                            marginLeft: "40px!important",
                            backgroundColor: (value.length !== 0 ? "#205d7b" : "lightgrey"),
                            color: "white !important",
                            ":hover": {
                                backgroundColor: (value.length !== 0 ? "#205d7b" : "lightgrey"),
                                color: "white !important"
                            }
                        }}
                        disabled={(value.length === 0 ? true : false)}
                        onClick={() => action(value, field)}>{`${actionLabel} ${value.length} ${value.length === 1 ? t('modifierList.list') : t('modifierList.lists')}`}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default AddModifierListDialog;