import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, Fab, FormControlLabel, Grid, InputLabel, Switch, Tooltip } from "@mui/material";
import itemApi from 'api/item';
import modifierListApi from 'api/modifierList';
import en from "assets/images/flags/en.png";
import CustomAddEditBreadcrumbMultiParent from "components/Breadcrumb/CustomAddEditBreadcrumbMultiParent";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomChip from 'components/Chip/CustomChip';
import AttachItemOrModifierDialog from "components/Dialogs/AttachItemOrModifierDialog";
import CustomDialog from 'components/Dialogs/CustomDialog';
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomItemModifierDraggableTable from "components/Table/CustomItemModifierDraggableTable";
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import CustomTextField from 'components/Text/CustomTextField';
import CustomAddEditTitle from "components/Title/CustomAddEditTitle";
import { getDefaultModifierListObject } from 'constants/defaultObjects';
import { getModifierListMaxModifiers, getModifierListMinModifiers } from 'constants/fieldProperties';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { attachDialogAddButton, attachDialogAddButtonAddIcon, attachDialogAddButtonIcon, fabSecondStyle, fabStyle, fabTooltipStyle, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, textFieldError } from 'themes/defaultThemes';
import { hasRoleAdminMenu } from 'utils/auth';
import { formIsValid, formValChangeWithParentElement, formValChangeWithParentElementWithNameAndValue, onControlChange } from "utils/form-validation";
import { findAllObjectsMatchInArrayOrdered, validateInputNumber } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The ModifierListAddEditComponent that allow the ADMIN to create a 
 * new modifier list or edit an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ModifierListAddEditComponent() {
    const { t } = useTranslation();

    const { organizationID } = useParams();
    const { modifierListID } = useParams();
    const navigate = useNavigate();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    path += `/modifier-list`;

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {object} organization the organization basic details (id and name) 
     * @property {object} modifierList the modifier list details
     * @property {*} icon the legend fa icon
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        userAuth: null,
        isAdd: modifierListID === undefined,
        organizations: {
            id: organizationID,
            name: ""
        },
        modifierList: null,
        items: [],
        modifiers: [],
        modifiersSelected: [],
        itemsSelected: [],
        icon: AddIcon,
        // error messages per field
        isError: {
            name: "",
            displayName: "",
            minModifiers: "",
            maxModifiers: ""
        }
    });

    // to render DOM based on rest api call status, if true the rest api call completed
    const [redirect, setRedirect] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313",
        action: undefined
    });

    // pop dialog for delete
    const [deleteDialog, setDeleteDialog] = useState({
        isOpen: false,
        id: "",
        field: "",
        name: "",
    });

    // popup dialog for translations
    const [dialog, setDialog] = useState({
        isOpen: false,
        message: t("label.configureTranslations"),
        id: "",
        field: "",
        translations: {}
    });

    // popup dialog for attach items, modifiers
    const [attachDialog, setAttachDialog] = useState({
        isOpen: false,
        field: ""
    });

    const [actionButtonLoading, setActionButtonLoading] = useState(false);

    useEffect(() => {
        getModifierList();
    }, []);

    //change document title
    useDocumentTitle(`Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t('org.pageTitle') : ""} | ${t('modifierList.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`);

    /**
     * The rest endpoint to get the modfier list.
     */
    function getModifierList() {
        if (data.isAdd) {
            modifierListApi.fetchNew(organizationID).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            modifierList: {
                                organizationID: organizationID,
                                ...getDefaultModifierListObject()
                            },
                            items: r.data.returnobject.items,
                            modifiers: r.data.returnobject.modifiers,
                            organization: r.data.returnobject.organization
                        });
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            modifierListApi.fetchOne(organizationID, modifierListID).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            modifierList: r.data.returnobject.modifierList,
                            items: r.data.returnobject.items,
                            modifiers: r.data.returnobject.modifiers,
                            organization: r.data.returnobject.organization,
                            modifiersSelected: findAllObjectsMatchInArrayOrdered(r.data.returnobject.modifierList.attachedModifierIds, r.data.returnobject.modifiers),
                            itemsSelected: findAllObjectsMatchInArrayOrdered(r.data.returnobject.modifierList.attachedItemIds, r.data.returnobject.items)
                        });
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
            }).catch((e) => {
                // console.log(e);
            });
        }
    }

    /**
     * Gets called when the user clicks on the save button, and triggers 
     * the creating of the modifierList.
     */
    function addModifierList(redirectAfter = false) {
        if (!actionButtonLoading && formValidation()) {
            setActionButtonLoading(true);
            modifierListApi.create(data.modifierList).then((r) => {
                let message = "message" in r.data ?
                    (r.data.code === "SUCCESS") ?
                        `${t('modifierList.pageTitle')} "${r.data.returnobject.displayName.en}" ${t('actions.createSuccessfully')}`
                        :
                        r.data.message
                    : t("errorPages.somethingWentWrong");
                setActionButtonLoading(false);
                Promise.resolve()
                    .then(() => {
                        if (redirectAfter)
                            window.location.href = path;
                        else
                            setAlertBox({
                                isOpen: true,
                                message: message,
                                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313",
                                action: (r.data.code === "SUCCESS") ? <Button color="secondary" size="small" onClick={() => window.location.href = `${path}/${r.data.returnobject.id}`}>{t('actions.view')}</Button> : undefined
                            });

                    })
                    .then(() => {
                        if (!redirectAfter)
                            window.location.reload();
                        // formValChangeWithParentElementWithNameAndValue("modifierList", getDefaultModifierListObject(), data, setData, "");
                    })
            }).catch((e) => {
                // console.log(e)
                setActionButtonLoading(false);
            });
        }
    }

    /**
     * Function that triggers the modification of the modifierList
     */
    function editModifierList() {
        if (formValidation()) {
            setActionButtonLoading(true);
            modifierListApi.update(data.modifierList).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                setActionButtonLoading(false);
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
                setActionButtonLoading(false);
            });
        }
    }

    /**
     * Function that triggers form validation.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that triggers the removal of an Id.
     * @param {*} idToDelete 
     */
    function removeId(event, idToDelete, field) {
        const objectName = field.split(".")[0];
        let name = field.split(".")[1];
        let ids = data[objectName][name];
        const index = ids.indexOf(idToDelete);
        // only splice array when item is found
        if (index > -1) ids.splice(index, 1); // 2nd parameter means remove one item only
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: ids
            }
        });
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns the object with the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.name = data.modifierList.name.length < 1 ? t('form.requiredField') : "";
        isError.displayName = data.modifierList.displayName.en.length < 1 ? t('form.requiredField') : "";
        if (data.modifierList.allowMultipleSelection) {
            if (data.modifierList.minModifiers < 0) {
                isError.minModifiers = "The minimum value must be greater than or equal to 0.";
            }
            if (data.modifierList.minModifiers > data.modifierList.attachedModifierIds.length) {
                isError.maxModifiers = "The minimum value must be less than or equal to the modifiers length.";
            }
            // Check if max is greater than or equal to minModifiers
            if (data.modifierList.maxModifiers < data.modifierList.minModifiers) {
                isError.maxModifiers = "The maximum value must be greater than or equal to the minimum value.";
            }
            // Check if max is less than or equal to listLength
            if (data.modifierList.maxModifiers > data.modifierList.attachedModifierIds.length) {
                isError.maxModifiers = "The maximum value must be less than or equal to the modifiers length.";
            }
        }

        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...data.alertBox,
            isOpen: isOpen
        });
    }

    function addItermOrModifier(instances, field) {
        let newIds = [...data.modifierList[field === "modifiers" ? "attachedModifierIds" : "attachedItemIds"], ...instances.map(instance => instance.id)];
        setData({
            ...data,
            modifierList: {
                ...data.modifierList,
                [field === "modifiers" ? "attachedModifierIds" : "attachedItemIds"]: newIds
            },
            [`${field}Selected`]: findAllObjectsMatchInArrayOrdered(newIds, data[field]),
        });
        setAttachDialog({
            ...attachDialog,
            isOpen: false,
            field: ""
        });
    }

    function updateModifierOrder(modifiers) {
        setData({
            ...data,
            modifierList: {
                ...data.modifierList,
                attachedModifierIds: modifiers.map(modifier => modifier.id),
                modifiersSelected: modifiers
            }
        })
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     * @property {string} dialogName The dialog name
     */
    function handleDialogState(isOpen, field = "", dialogName = "dialog") {
        let newData = {
            isOpen: isOpen,
            field: field
        }
        if (dialogName === "dialog") {
            newData.translations = data.modifierList[field];
            setDialog({
                ...dialog,
                ...newData
            });
        }

        if (dialogName === "attachDialog") {
            Promise.resolve()
                .then(() => {
                    itemApi.fetchListDialog(data.organization.id, field === "modifiers")
                        .then((r) => {
                            formValChangeWithParentElementWithNameAndValue(field, r.data.returnobject, data, setData);
                        })
                        .catch((e) => {
                            // console.log(e);
                            setAlertBox({
                                isOpen: true,
                                message: t("errorPages.somethingWentWrong"),
                                backgroundColor: "#ef5350",
                            });
                        });
                })
            setAttachDialog({
                ...attachDialog,
                ...newData
            });
        }
    }

    /**
     * This function will trigger the modifierList field translations configuration
     */
    function configureModifierListTranslations(translations, field) {
        document.querySelectorAll(`[name="modifierList.${field}.en"]`)[0].value = translations.en;
        setData({
            ...data,
            modifierList: {
                ...data.modifierList,
                [field]: translations
            },
        });
        setDialog({
            ...dialog,
            isOpen: false,
            field: "",
            translations: {}
        });
    }

    /**
     * Function that triggers the removal of aitem/modifier.
     * @param {*} itemOrModifierToDelete 
     */
    function removeItemOrModifier() {
        let { id, field } = deleteDialog;
        let itemOrModifierIds = (field === "modifiers") ? data.modifierList.attachedModifierIds : data.modifierList.attachedItemIds;
        const index = itemOrModifierIds.indexOf(id);
        // only splice array when item is found
        if (index > -1) itemOrModifierIds.splice(index, 1); // 2nd parameter means remove one item only
        let newData = findAllObjectsMatchInArrayOrdered(itemOrModifierIds, data[field]);
        setData({
            ...data,
            modifierList: {
                ...data.modifierList,
                [field]: itemOrModifierIds
            },
            [field + "Selected"]: newData
        });
        setDeleteDialog({
            ...deleteDialog,
            isOpen: false,
            id: "",
            field: ""
        });
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} modifierID The id of the selected modifier to be removed.
     * @property {string} itemID The id of the selected item to be removed.
     */
    function handleDeleteDialogState(isOpen, id = "", name = "", field = "items") {
        setDeleteDialog({
            ...deleteDialog,
            isOpen: isOpen,
            id: id,
            field: field,
            name: name
        });
    }


    /**
     * Function to trigger the remove of the admin broker.
     * @property {*} event
     * @property {string} field The field of the selected organization field.
     * @property {string} dialogName The dialog name
     */
    function configureTranslations(event, field, dialogName) {
        handleDialogState(true, field, dialogName);
    }

    // ===============================================================================RENDER

    if (redirect) {
        navigate(path);
    } else if (!isLoaded) {
        return <LoadingSkeleton lines={9} />
    } else {
        const { isError } = data;

        return (
            // MODIFIER-LIST ADD - EDIT
            <CustomGrid role="main" id={"modifier-" + data.isAdd ? "add" : "edit"} sx={mainTag()}>
                {(organizationID !== undefined && !hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('modifierList.title'), url: `/${getLanguageFromURL()}/organization/${organizationID}/modifier-list` },
                            ]}
                            instanceName={(data.modifierList !== null) ? data.modifierList.name.en : ""}
                        />
                    </CustomGrid>
                }


                {(organizationID !== undefined && hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('modifierList.title'), url: `/${getLanguageFromURL()}/modifier-list` },
                            ]}
                            instanceName={(data.modifierList !== null) ? data.modifierList.name.en : ""}
                        />
                    </CustomGrid>
                }

                {organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('modifierList.title'), url: `/${getLanguageFromURL()}/modifier-list` },
                            ]}
                            instanceName={(data.modifierList !== null) ? data.modifierList.name.en : ""}
                        />
                    </CustomGrid>
                }


                <CustomGrid sx={mainTagTitle()}>
                    <CustomAddEditTitle
                        isAdd={data.isAdd}
                        title={t('modifierList.pageTitle')}
                        subtitle={t('modifierList.pageTitle')}
                        instanceName={(data.modifierList !== null) ? data.modifierList.name : ""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="modifierList" onSubmit={(e) => { return false; }}>
                        {data.isAdd &&
                            <input type="hidden" />
                        }

                        {!data.isAdd && organizationID !== undefined &&
                            <input defaultValue={organizationID} name="organizationID" type="hidden" id="organizationID" />
                        }

                        {!data.isAdd && modifierListID !== undefined &&
                            <input defaultValue={modifierListID} name="id" type="hidden" id="id" />
                        }

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <InputLabel required={false} htmlFor="name">{t('label.name')}</InputLabel>
                                <CustomTextField
                                    type="text"
                                    name="modifierList.name"
                                    id="name"
                                    step="1"
                                    min={0}
                                    defaultValue={data.modifierList !== null ? data.modifierList.name : ""}
                                    required={true}
                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                    helperText={isError.name}
                                    sx={textFieldError()}
                                    error={isError.name.length > 0 ? true : false}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel required={false} htmlFor="displayName">{t('label.displayName')}</InputLabel>
                                <CustomFlagTextField
                                    country="en"
                                    flag={en}
                                    placeholder=""
                                    name="modifierList.displayName.en"
                                    id="displayName"
                                    onChange={(event) => {
                                        formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))
                                    }}
                                    defaultValue={data.modifierList !== null && data.modifierList.displayName !== null ? data.modifierList.displayName.en : ""}
                                    onTranslationSelect={() => configureTranslations(true, "displayName")}
                                    field="displayName"
                                    helperText={isError.displayName}
                                    error={isError.displayName.length > 0 ? true : false}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel control={<Switch defaultChecked={data.modifierList.allowMultipleSelection}
                                            onChange={(event) => {
                                                if (!event.target.checked)
                                                    setData({
                                                        ...data,
                                                        isError: {
                                                            ...data.isError,
                                                            minModifiers: "",
                                                            maxModifiers: ""
                                                        }
                                                    });
                                                onControlChange(event, data, setData, "modifierList", "allowMultipleSelection")
                                            }} />
                                        } label={t('modifierList.allowMultipleSelection')} />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        {data.modifierList.allowMultipleSelection &&
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} md={6}>
                                                    <CustomTextField
                                                        type="number"
                                                        step={getModifierListMinModifiers().step}
                                                        min={getModifierListMinModifiers().min}
                                                        name="modifierList.minModifiers"
                                                        id="minModifiers"
                                                        variant="outlined"
                                                        value={data.modifierList.minModifiers}
                                                        inputAdornmentLabel="modifierList.minModifiers"
                                                        required={false}
                                                        onChange={(event) => {
                                                            let value = parseInt(validateInputNumber(event.target.value, getModifierListMinModifiers().length, getModifierListMinModifiers().min, data.modifierList.attachedModifierIds.length, getModifierListMinModifiers().isFloat));
                                                            if (value === undefined) value = data.modifierList.minModifiers;
                                                            formValChangeWithParentElementWithNameAndValue("modifierList.minModifiers", (value), data, setData)
                                                            // if min modifiers >  max modifiers make max = min 
                                                            if (value > data.modifierList.maxModifiers && value <= data.modifierList.attachedModifierIds.length)
                                                                formValChangeWithParentElementWithNameAndValue("modifierList.maxModifiers", value, data, setData)
                                                        }}
                                                        helperText={isError.minModifiers}
                                                        sx={textFieldError()}
                                                        error={isError.minModifiers.length > 0 ? true : false}
                                                    // onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>

                                                <Grid item xs={6} md={6}>
                                                    <CustomTextField
                                                        type="number"
                                                        step={getModifierListMaxModifiers().step}
                                                        min={data.modifierList.minModifiers}
                                                        max={data.modifierList.attachedModifierIds.length}
                                                        name="modifierList.maxModifiers"
                                                        id="maxModifiers"
                                                        variant="outlined"
                                                        value={data.modifierList.maxModifiers}
                                                        inputAdornmentLabel="modifierList.maxModifiers"
                                                        required={false}
                                                        onChange={(event) => {
                                                            let value = validateInputNumber(event.target.value, getModifierListMinModifiers().length, data.modifierList.minModifiers, data.modifierList.attachedModifierIds.length, getModifierListMinModifiers().isFloat);
                                                            if (value === undefined) value = data.modifierList.maxModifiers;
                                                            formValChangeWithParentElementWithNameAndValue("modifierList.maxModifiers", parseInt(value), data, setData)
                                                        }}
                                                        helperText={isError.maxModifiers}
                                                        sx={textFieldError()}
                                                        error={isError.maxModifiers.length > 0 ? true : false}
                                                    // onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <FormControlLabel control={<Switch defaultChecked={data.modifierList.enabled} onChange={(event) => onControlChange(event, data, setData, "modifierList", "enabled")} />} label={t('modifierList.enabled')} />
                            </Grid>

                            <Divider />
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <InputLabel required={false} htmlFor="attachedModifierIds">{t('modifierList.modifiers')}
                                            <CustomChip label={data.modifierList.attachedModifierIds !== null ? data.modifierList.attachedModifierIds.length : 0} />
                                        </InputLabel>
                                    </Grid>
                                    <Grid item>
                                        <CustomIconButton
                                            onClick={() => handleDialogState(true, "modifiers", "attachDialog")}
                                            icon={<AddIcon style={attachDialogAddButtonAddIcon()} />}
                                            iconSx={attachDialogAddButtonIcon()}
                                            sx={attachDialogAddButton()}
                                            label={t('modifierList.addModifier')} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomItemModifierDraggableTable
                                        emptyMessage={t('modifierList.emptymodifierList')}
                                        item={data.modifiersSelected}
                                        removeItemOrModifier={(isOpen, item, field) => handleDeleteDialogState(isOpen, item.id, item.name, "modifiers")}
                                        // removeItemOrModifier={removeItemOrModifier}
                                        field="modifiers"
                                        organizationID={data.organization.id}
                                        updateModifierOrder={updateModifierOrder}
                                        enableDraggable={true}
                                    />
                                </Grid>
                            </Grid>

                            <Divider style={{ margin: "10px 0" }} />

                            <Grid item xs={12} md={12}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <InputLabel required={false} htmlFor="attachedItemIds">{t('modifierList.items')}
                                            <CustomChip label={data.modifierList.attachedItemIds !== null ? data.modifierList.attachedItemIds.length : 0} />
                                        </InputLabel>
                                    </Grid>
                                    <Grid item>
                                        <CustomIconButton
                                            onClick={() => { handleDialogState(true, "items", "attachDialog") }}
                                            icon={<AddIcon style={attachDialogAddButtonAddIcon()} />}
                                            iconSx={attachDialogAddButtonIcon()}
                                            sx={attachDialogAddButton()}
                                            label={t('modifierList.addItem')} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomItemModifierDraggableTable
                                        emptyMessage={t('modifierList.emptyItemList')}
                                        item={data.itemsSelected}
                                        removeItemOrModifier={(isOpen, item, field) => handleDeleteDialogState(isOpen, item.id, item.name, "items")}
                                        // removeItemOrModifier={removeItemOrModifier}
                                        field="items"
                                        organizationID={data.organization.id}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ mt: "20px", pl: "20px" }}>
                                {/* <Grid item>
                                    <LoadingButton
                                        variant="outlined"
                                        type="button"
                                        keyValue="save"
                                        data-redirect="stay"
                                        onClick={() => data.isAdd ? addModifierList(true) : editModifierList()}
                                        sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                        loading={actionButtonLoading}
                                    // loadingPosition="end"
                                    >
                                        {t('actions.save')}
                                    </LoadingButton>
                                </Grid> */}

                                {data.isAdd &&
                                    <>
                                        {/* <Grid item>
                                            <LoadingButton
                                                variant="outlined"
                                                keyValue="save"
                                                onClick={() => addModifierList(false)}
                                                label={t('actions.saveAndCreateNew')}
                                                sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                                data-redirect="stay"
                                            >
                                                {t('actions.saveAndCreateNew')}
                                            </LoadingButton>
                                        </Grid> */}

                                        <Grid item>
                                            <LoadingButton
                                                variant="outlined"
                                                type="button"
                                                keyValue="cancel"
                                                data-redirect="stay"
                                                link={path}
                                                color="error"
                                            >
                                                {t('actions.back')}
                                            </LoadingButton>
                                        </Grid>
                                    </>
                                }

                                {!data.isAdd &&
                                    <Grid item>
                                        <LoadingButton
                                            variant="outlined"
                                            type="button"
                                            keyValue="cancel"
                                            data-redirect="stay"
                                            onClick={() => getModifierList()}

                                            color="error"
                                        >
                                            {t('actions.cancel')}
                                        </LoadingButton>
                                    </Grid>
                                }
                            </Grid>

                        </Grid>
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabSecondStyle()}
                        onClick={() => data.isAdd ? addModifierList(true) : editModifierList()}
                    >
                        <Tooltip arrow title={t('actions.save')} sx={fabTooltipStyle()}>
                            <SaveIcon />
                        </Tooltip>
                    </Fab>

                    {data.isAdd &&
                        <Fab color="primary"
                            aria-label="add"
                            sx={fabStyle()}
                            onClick={() => addModifierList(false)}
                        >
                            <Tooltip arrow title={t('actions.saveAndCreateNew')} sx={fabTooltipStyle()}>
                                <SaveIcon style={{ pr: "7px", pt: "5px" }} /><AddIcon fontSize="7px" style={{ float: "right", ml: "11px", mt: "7px" }} />
                            </Tooltip>
                        </Fab>
                    }
                </CustomGrid>

                {attachDialog.isOpen &&
                    <AttachItemOrModifierDialog
                        isOpen={attachDialog.isOpen}
                        id="add-item-or-modifier"
                        title={`${t('actions.add')} ${attachDialog.field === "modifiers" ? t('modifierList.modifiers') : t('modifierList.items')} ${t('modifierList.to')}`}
                        boldTitle={data.modifierList.name}
                        searchLabel={`${t('actions.searchFor')} ${attachDialog.field === "modifiers" ? t('modifierList.modifiers') : t('modifierList.items')}`}
                        noOptionsText={`${attachDialog.field === "modifiers" ? t('modifierList.emptyModifiersSearch') : t('modifierList.emptyItemsSearch')}`}
                        actionLabel={t("actions.add")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.add')} ${t('actions.deleteModal.confirm')}`}
                        action={addItermOrModifier}
                        handleOpen={handleDialogState}
                        instances={data[attachDialog.field]?.filter(modifierList => !data.modifierList[(attachDialog.field === "modifiers" ? "attachedModifierIds" : "attachedItemIds")].includes(modifierList.id))}
                        dialogName="attachDialog"
                        field={attachDialog.field}
                    />
                }

                {dialog.isOpen &&
                    <CustomTranslationsDialog
                        isOpen={dialog.isOpen}
                        id="translations"
                        title={t('actions.translationModal.title')}
                        action={configureModifierListTranslations}
                        handleOpen={handleDialogState}
                        translations={dialog.translations}
                        field={dialog.field} />
                }

                {deleteDialog.isOpen &&
                    <CustomDialog
                        isOpen={deleteDialog.isOpen}
                        id="remove"
                        title={t('actions.removeModal.title')}
                        actionLabel={t("actions.removeModal.message")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.removeModal.notice')} ${t('actions.removeModal.confirm')} ${deleteDialog.field === "modifiers" ? t('itemOrModifier.pageTitleModifier') : t('itemOrModifier.pageTitleItem')}`}
                        boldMessage={<> <span style={{ fontWeight: "bold" }}>{deleteDialog.name}</span> <span>{` ${t('actions.removeModal.from')} `}</span> <span style={{ fontWeight: "bold" }}> {data.modifierList.name}</span></>}
                        action={removeItemOrModifier}
                        handleOpen={handleDeleteDialogState}
                        actionColor="#e91e1e"
                    />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        action={alertBox.action}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default ModifierListAddEditComponent; 