import { CloseRounded } from '@mui/icons-material';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTypography from 'components/Typography/CustomTypography';
import { getOrderPaymentActionItemQuantity } from 'constants/fieldProperties';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    orderCartItemQuantityTextStyle, orderItemHeaderTextStyle, orderItemNoteTextStyle, orderTextStyle
} from 'themes/defaultThemes';
import { validateInputNumber } from 'utils/functions';
import { priceTextWithCurrency } from 'utils/functions/order';

/**
 * CustomSinglePastOrderCardItemPay Component
 * @param {Object} props - Component props
 * @param {Object} props.cartItem - The item from the order cart
 * @param {string} props.currency - Currency code
 * @param {Function} props.onPay - Callback function when paying an item
 * @param {boolean} props.isPaid - Flag indicating if the item is paid
 */
function CustomSinglePastOrderCardItemPay({ cartItem, currency, onPay, selectedItemsToPay, payAll, index, initGridItemMarginTop = "20px" }) {
    const { t } = useTranslation();
    const [modifiersPrice, setModifiersPrice] = useState(0);
    const [modifiersText, setModifiersText] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [quantityToPay, setQuantityToPay] = useState(() => {
        const selectedItem = selectedItemsToPay?.find(
            item => item.itemID === cartItem.id && item.indexInOrderCart === cartItem?.indexInOrderCart
        );
        return selectedItem?.quantityPaid || cartItem.quantity;
    });

    const [selectedItems, setSelectedItems] = useState([]);

    const isPaid = cartItem.paymentActionsHistory && cartItem.paymentActionsHistory.some(payment => payment.items.every(item => item.isPaid));

    useEffect(() => {
        // Calculate and set modifiers text
        cartModifiersToText(cartItem?.modifiers);
    }, []);

    /**
     * Converts cart modifiers to text and calculates total price
     * @param {Array} modifiers - Array of modifiers for the item
     */
    function cartModifiersToText(modifiers) {
        let text = '';
        let modifiersTotalPrice = 0;
        if (modifiers?.length > 0) {
            modifiers?.forEach((modifier) => {
                text += `${modifier.name}${modifiers?.length > 1 ? ', ' : ''}`;
                modifiersTotalPrice += modifier.price;
            });
        }

        setModifiersPrice(modifiersTotalPrice);
        setModifiersText(text);
    }

    /**
     * Handle checkbox change for unpaid items
     */
    function handleCheckboxChange() {
        handlePay(true)
    };

    /**
     * Close the payment dialog
     */
    function handleDialogClose() {
        setDialogOpen(false);
    };

    /**
     * Handle payment for unpaid items
     */
    function handlePay(isPayAll = false) {
        let newQuantityTaPay = isPayAll ? cartItem.quantity : quantityToPay;
        // Update the selected items list
        const updatedItems = [...selectedItems, { cartItem, newQuantityTaPay, index }];
        setSelectedItems(updatedItems);

        // Perform payment logic and pass updatedItems to the parent component
        onPay(updatedItems);

        // Close the dialog and reset state
        setDialogOpen(false);
        setSelectedItems([]);
    };

    function handleClickToSelecteQuantity() {
        setDialogOpen(true);
    }
    // console.log(cartItem, selectedItemsToPay, payAll, selectedItems, quantityToPay)

    return (
        <>
            <Grid item xs={12} md={12} sx={{ marginTop: initGridItemMarginTop, opacity: isPaid ? 0.5 : 1 }}>
                <Grid container>
                    <Grid item xs={2} md={2} sx={{ alignSelf: 'center' }}>
                        {/* ALL QUANTITY selected */}
                        {!payAll && selectedItemsToPay.find(item => item.itemID === cartItem.id && item.indexInOrderCart === cartItem?.indexInOrderCart && quantityToPay === cartItem.quantity) &&
                            <Checkbox
                                checked={true}
                                onChange={handleCheckboxChange}
                                color="primary"

                            />
                        }

                        {/* SOME selected */}
                        {!payAll && selectedItemsToPay.find(item => item.itemID === cartItem.id && item?.indexInOrderCart === cartItem?.indexInOrderCart && quantityToPay !== cartItem?.quantity) &&
                            <Checkbox
                                onChange={handleCheckboxChange}
                                color="primary"
                                indeterminate={true}
                            />
                        }

                        {/* NONE selected */}
                        {!payAll && !selectedItemsToPay.find(item => item.itemID === cartItem.id && item?.indexInOrderCart === cartItem?.indexInOrderCart && quantityToPay !== cartItem?.quantity) && !selectedItemsToPay.find(item => item.itemID === cartItem.id && item.indexInOrderCart === cartItem?.indexInOrderCart && quantityToPay === cartItem.quantity) &&
                            <Checkbox
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                        }

                        {/* ALL items selected */}
                        {payAll &&
                            <Checkbox
                                onChange={handleCheckboxChange}
                                checked={true}
                            />
                        }
                    </Grid>

                    <Grid item xs={2} md={2} sx={{ alignSelf: 'center' }} onClick={handleClickToSelecteQuantity}>
                        <CustomTypography variant="span" text={cartItem.quantity} sx={orderCartItemQuantityTextStyle()} />
                    </Grid>

                    <Grid item xs={8} md={8} sx={{ alignSelf: 'center' }} onClick={handleClickToSelecteQuantity}>
                        <Grid container sx={{ marginLeft: '10px', textAlign: 'left' }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={cartItem?.name} sx={orderItemHeaderTextStyle()} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={modifiersText} sx={orderTextStyle()} />
                            </Grid>

                            {cartItem.notes.length > 0 && (
                                <Grid item xs={12} md={12}>
                                    <CustomTypography
                                        variant="body2"
                                        text={`${t('customer.view.tabs.orders.order.notes')} ${cartItem.notes}`}
                                        sx={orderItemNoteTextStyle()}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} md={12}>
                                <CustomTypography
                                    variant="body2"
                                    text={priceTextWithCurrency(currency, (cartItem.price * cartItem.quantity) + modifiersPrice)}
                                    sx={orderItemNoteTextStyle()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Payment Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle className="dialog-headers">
                    {t('pda.dialogPay.title')}
                    <IconButton onClick={() => { setDialogOpen(false) }} sx={{ float: "right" }}>
                        <CloseRounded />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container sx={{ minHeight: "200px" }}>
                        <Grid item xs={12} sx={{ marginTop: "20px" }}>
                            <CustomTextField
                                label={`${t('pda.dialogPay.info')} (${t('pda.dialogPay.max')}: ${cartItem.quantity})`}
                                type="number"
                                step={getOrderPaymentActionItemQuantity().step}
                                min={getOrderPaymentActionItemQuantity().min}
                                max={cartItem.quantity}
                                name="min"
                                value={quantityToPay}
                                onChange={(event) => {
                                    let value = validateInputNumber(event.target.value, getOrderPaymentActionItemQuantity().length, getOrderPaymentActionItemQuantity().min, cartItem.quantity, getOrderPaymentActionItemQuantity().isFloat);
                                    if (value === undefined) value = null;
                                    setQuantityToPay(parseInt(value))
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    {handlePay && <Button className="dialog-action-btn" onClick={() => handlePay(false)}>{t('actions.pay')}</Button>}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CustomSinglePastOrderCardItemPay;