import { LoadingButton } from '@mui/lab';
import { Button, Fab, Grid, InputLabel, Stack, useMediaQuery } from '@mui/material';
import { ActionAnimations, SwipeableList } from '@sandstreamdev/react-swipeable-list';
import requestApi from 'api/request';
import { NoRequests } from 'assets/lottie/LottieAnimations';
import { AddIcon, ArrowBackIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomAddRequestDialog from 'components/Dialogs/request/CustomAddRequestDialog';
import { lottieDialogLoopOptions } from 'constants/animations/lottieOptions';
import { MORE_REQUESTS_ADD_SIZE } from 'constants/fieldProperties';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { customerRequestEmptyInfoTextStyle, fabStyle, orderBackBtnStyle, pastCustomerRequestsMoreButtonStyle } from 'themes/defaultThemes';
import { hasRoleBuseer } from 'utils/auth';
import { dateTimeFormatterFilter, prepareTablePaginationSortParams } from 'utils/functions';
import CustomPDACustomerRequestCard from './CustomPDACustomerRequestCard';
import { getDefaultDataRequest, getDefaultSearchRequest } from 'constants/defaultObjects';
import { updateRequestAll, updateRequestData, updateRequestSearch } from 'store/request/requestActions';

/**
 * The CustomPDARequestGrid, that display a custom {@link Grid} for customer request PDA
 * 
 * @property {string} label the instance name
 * @property {*} icon the icon represented the instance
 * @property {int} totalNumber the total number of the instances presented
 * @property {string} link the onclick card navigation link
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPDARequestGrid({ handleReset, isViewRequestsPage, setAlertBox,
    equipmentID, organizationID, outletID,
    getPDADisplay
}) {
    const isMobile = useMediaQuery('(max-width:468px)');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // data, search
    const requestData = useSelector(state => state?.request?.data || getDefaultDataRequest(organizationID, outletID));
    const requestSearch = useSelector(state => state?.request?.search || getDefaultSearchRequest(organizationID, outletID, equipmentID));

    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [requestDialogOpen, setRequestDialogOpen] = useState(false);

    // enable more button to view older requests
    const [requestsEnableMoreButton, setRequestsEnableMoreButton] = useState(true);

    /**
     * Update a specific request requestSearch field valuer.
     * 
     * @param {string} field - The requestSearch field to alter its value.
     * @param {string} fieldValue - The new field value.
     */
    function handleRequestSearchChange(field, fieldValue) {
        dispatch(updateRequestSearch({
            ...requestSearch,
            [field]: fieldValue,
            equipmentID: equipmentID
        }))
    }
    console.log(equipmentID, requestSearch)
    /**
     * 
     * @param {*} event 
     * @param {*} id 
     * @param {*} status 
     */
    function changeRequestStatus(event, id, status, trigerBySnackbar = false) {
        requestApi.changeRequestStatus(id, status).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313",
                action: (r.data.code === "SUCCESS") ? <Button color="inherit" size="small" sx={{ color: "white", border: "1px solid white", borderRadius: "20px" }} onClick={(event) => changeRequestStatus(event, id, "PENDING", trigerBySnackbar)}>{t('actions.undo')}</Button> : undefined
            });
            getCustomerRequests();
            if (getPDADisplay !== undefined) getPDADisplay();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
      * The rest endpoint to get the requests list.
      */
    function getCustomerRequests() {
        requestApi.fetchAll(prepareTablePaginationSortParams(requestData.pagination),
            { ...requestSearch, dateFrom: dateTimeFormatterFilter(), dateTo: null, equipmentID: equipmentID }
        ).then((r) => {
            Promise.resolve()
                .then(() => {
                    dispatch(updateRequestAll({
                        ...requestData,
                        userAuth: r.data.returnobject.userAuth,
                        customerRequests: r.data.returnobject.customerRequests,
                        equipments: r.data.returnobject.equipments,
                        organization: r.data.returnobject.organization,
                        outlet: r.data.returnobject.outlet,
                        types: r.data.returnobject.types,
                        statuses: r.data.returnobject.statuses,
                        pagination: {
                            ...requestData.pagination,
                            count: r.data.returnobject.customerRequests.totalPages,
                            size: r.data.returnobject.customerRequests.size,
                            page: r.data.returnobject.customerRequests.number
                        }
                    },
                        { ...requestData, organizationID: organizationID, outlet: outletID, equipmentID: equipmentID, status: "PENDING" }));
                })
                .then(() => {
                    if (r.data.returnobject.customerRequests.totalPages <= (r.data.returnobject.customerRequests.number + 1)) {
                        setRequestsEnableMoreButton(false)
                    }
                })
                .then(() => {
                    if (hasRoleBuseer(r.data.returnobject.userAuth.roles))
                        dispatch(updateRequestSearch({
                            ...requestSearch,
                            type: "CLEAN_TABLE"
                        }))
                })
        }).catch((e) => {
            // console.log(e);
        })
    }

    useEffect(() => {
        if (isViewRequestsPage) {
            getCustomerRequests();

            const fetchDataInterval = setInterval(() => {
                getCustomerRequests(); // Fetch data
            }, 5000); // 5 seconds interval

            // Clear interval on component unmount to avoid memory leaks
            return () => clearInterval(fetchDataInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isViewRequestsPage]);

    return (
        <>
            <CustomIconButton
                icon={<ArrowBackIcon />}
                sx={orderBackBtnStyle()}
                onClick={() => handleReset()}
                label={t('pda.backButtonEqDialog')}
            />

            <>
                {(requestData.customerRequests?.content?.length > 0) &&
                    <Stack direction="row" sx={{ padding: "10px", alignItems: "center", display: "contents" }}>
                        <Grid container gap={1} spacing={1} sx={{ display: "contents" }}>
                            <Grid item xs={12}>
                                <SwipeableList
                                    sx={{ overflowY: "visible" }}
                                >
                                    {requestData.customerRequests?.content?.map((customerRequest, index) => (
                                        <CustomPDACustomerRequestCard
                                            customerRequest={customerRequest}
                                            index={index}
                                            showDivider={index + 1 !== requestData.customerRequests.content?.length}
                                            handleAction={(event, customerRequestID, status) => changeRequestStatus(event, customerRequestID, status)}
                                            action={ActionAnimations.RETURN}
                                        />
                                    ))}
                                </SwipeableList>
                            </Grid>

                            {requestsEnableMoreButton &&
                                <Grid item xs={12} md={12}>
                                    <LoadingButton
                                        sx={pastCustomerRequestsMoreButtonStyle()}
                                        onClick={() => {
                                            setActionButtonLoading(true);
                                            dispatch(updateRequestData({
                                                ...requestData,
                                                "pagination.size": requestData.pagination.size + MORE_REQUESTS_ADD_SIZE
                                            }))
                                        }}
                                        variant="outlined"
                                        type="button"
                                        key="loadMore"
                                        data-redirect="stay"
                                        loading={actionButtonLoading}
                                    >
                                        {t('request.moreRequests')}
                                    </LoadingButton>
                                </Grid>
                            }
                        </Grid>
                    </Stack>
                }

                {(requestData.customerRequests?.content?.length === 0) &&
                    <div style={{ textAlign: "center", marginTop: "150px", marginBottom: "60px", alignItems: "center", width: "100%" }}>
                        <Lottie
                            animationData={NoRequests}
                            {...lottieDialogLoopOptions}
                        />

                        <InputLabel sx={customerRequestEmptyInfoTextStyle()}>{t('request.empty')}</InputLabel>
                    </div>
                }

                <Fab
                    color="primary"
                    aria-label="add"
                    sx={{ ...fabStyle(), position: isMobile ? "fixed" : "absolute" }}
                    onClick={() => setRequestDialogOpen(true)}
                >
                    <AddIcon />
                </Fab>
            </>

            {requestDialogOpen &&
                <CustomAddRequestDialog
                    getCustomerRequests={getCustomerRequests}
                    isOpen={requestDialogOpen}
                    onClose={() => setRequestDialogOpen(false)}
                    organizationID={requestData.organization?.id}
                    outletID={requestData.outlet?.id}
                    title={`${t('actions.add')} ${t('request.pageTitle')}`}
                    equipmentOptions={requestData.equipments}
                    handleOpen={() => setRequestDialogOpen(false)}
                    setAlertBox={setAlertBox}
                    equipmentID={equipmentID}
                    handleClose={() => setRequestDialogOpen(false)}
                    getPDADisplay={getPDADisplay}
                />
            }
        </>
    );
}

export default CustomPDARequestGrid;