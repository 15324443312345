
export function transformDataBar(inputData) {
    var transformedData = {
        series: []
    };

    for (var i = 0; i < inputData?.labels?.length; i++) {
        var label = inputData.labels[i];
        var value = inputData.series[i];

        transformedData.series.push({
            name: label,
            data: [value]
        });
    }

    return transformedData;
}

export function transformDataBarEmployees(inputArray) {
    let cashData = [];
    let cardData = [];

    inputArray?.forEach(item => {
        cashData.push(item.cashAmount);
        cardData.push(item.cardAmount);
    });

    return [
        { name: "cash", data: cashData },
        { name: "card", data: cardData }
    ];
}