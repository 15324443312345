import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Settings as SettingsIcon } from "@mui/icons-material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import { mainTag } from "themes/defaultThemes";
import { reloadPage } from "utils/page";
import useDocumentTitle from "utils/useDocumentTitle";
import orderApi from "api/order";
import kitchenDisplayApi from "api/kitchenDisplay";

import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomIconButton from "components/Button/CustomIconButton";
import { getLanguageFromURL } from "utils/language";
import CustomButton from "components/Button/CustomButton";
import { getDefaultKitchenDisplaySettings } from "constants/defaultObjects";
import OrderCard from "./orderCard";
/**
 * The UserListComponent, that allow the user to view the users list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function KitchenDisplayComponent() {
  const { t } = useTranslation();
  const { organizationID } = useParams();
  const { outletID } = useParams();
  const navigate = useNavigate();

  /**
   * @type {object}
   *
   * @property {object} userAuth the authenticated user infromation
   * @property {object} alertBox holds snackbar infromation and style
   * @property {array} orders the orders list
   * @property {object} outlet tha outlet basic details (id and name, isActive)
   * @property {object} organization tha organization basic details (id and name)
   * @property {object} pagination includes all pageable details (page, size, sort, direction)
   */
  const [data, setData] = useState({
    userAuth: null,
    orders: null,
    showLabel: false,
    organization: {
      id: organizationID,
      name: "",
    },
    outlet: {
      id: outletID,
      name: "",
    },
    organizations: [],
    outlets: [],
    statuses: [],
    paymentMethods: [],
    currency: "€",
  });
  const [minimizeOrders, setMinimizeOrder] = useState(false);
  const [displaySettings, setDisplaySettings] = useState(
    getDefaultKitchenDisplaySettings()
  );

  //change document title
  useDocumentTitle(`Usee  | ${t("display.kitchen.label")}`);

  const [search, setSearch] = useState({
    organizationID: "635e94f54960603c9bd07641", //organizationID,
    outletID: "63b31564c78b8371cf2d61ab", //outletID !== undefined ? outletID : undefined,
    // field: "username",
    orderNumber: "",
    // statuses: ["PENDING, WORKING, DONE"],
    statuses: ["DONE"],
    equipmentID: "",
  });
  // if true the menu item is clicked and a redirect to page should perform
  const [isLoaded, setIsLoaded] = useState(false);

  // snackbar details
  const [alertBox, setAlertBox] = useState({
    isOpen: false,
    message: "",
    backgroundColor: "#a71313",
  });

  //fetch setting here (we won't stop loading and will used default setting until setting is fetch)
  useEffect(() => {
    getKitchenDisplaySettings();
  }, []);

  useEffect(() => {
    getOrders();
  }, []);

  /**
   * The rest endpoint to get the orders list.
   * @property {number} page the list page number
   */
  function getOrders(page) {
    orderApi
      .fetchAllKitchen(search)
      .then((r) => {
        setIsLoaded(true);
        setData({
          ...data,
          userAuth: r.data.returnobject.userAuth,
          organization: r.data.returnobject.organization,
          outlet: r.data.returnobject.outlet,
          orders: r.data.returnobject.orders,
          paymentMethods: r.data.returnobject.paymentMethods,
          statuses: r.data.returnobject.statuses,
          // organizations: r.data.returnobject.organizations,
          // outlets: r.data.returnobject.outlets,
          currency: r.data.returnobject.currency,
        });
        if (r.data.message) {
          setAlertBox({
            isOpen: true,
            message: r.data.message,
            backgroundColor: "#4caf50",
          });
        }
      })
      .catch((e) => {
        // console.log(e);
        setAlertBox({
          isOpen: true,
          message: "Fail to fetch orders",
          backgroundColor: "#ef5350",
        });
      });
  }

  function getKitchenDisplaySettings() {
    // organizationID, outletID
    kitchenDisplayApi
      .fetchOne(search)
      .then((r) => {
        setDisplaySettings(r.data.returnobject.displaySettings);
      })
      .catch((e) => {
        console.log(e);
        setAlertBox({
          isOpen: true,
          message: "Fail to fetch display settings",
          backgroundColor: "#ef5350",
        });
      });
  }

  /**
   * Function that handles the snackbar open or close state.
   * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
   */
  function handleSnackbarState(isOpen) {
    setAlertBox({
      ...alertBox,
      isOpen: isOpen,
    });
  }

  // ===============================================================================RENDER

  const orderComponent = (status) => (
    <Grid
      container
      spacing={2}
      //direction={minimizeOrders ? "row" : "column"}
      //flexWrap='nowrap'
      sx={{
        //height: "100%",
        maxHeight: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        pb: "30px",
      }}
    >
      {data.orders.map((item, i) => (
        <Grid
          item
          key={i}
          md={minimizeOrders ? 3 : 4}
          xs={12}
          sx={{ display: "flex", width: "100%" }}
        >
          <OrderCard
            order={item}
            setting={displaySettings}
            minimize={minimizeOrders}
            currency={data.currency}
            setAlertBox={setAlertBox}
          />
        </Grid>
      ))}
    </Grid>
  );

  if (!isLoaded) {
    return <LoadingSkeleton lines={9} />;
  } else {
    return (
      <div
        // id='order-list'
        style={{
          ...mainTag(),
          top: "9px",
          paddingBottom: 0,

          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {alertBox.isOpen && (
          <CustomSnackbar
            isOpen={alertBox.isOpen}
            autoHideDuration={3000}
            message={alertBox.message}
            backgroundColor={alertBox.backgroundColor}
            handleSnackbarOpen={handleSnackbarState}
          />
        )}
        {/* header */}
        <Grid item sx={{ width: "100%" }}>
          <Grid
            container
            justifyContent='flex-end'
            alignItems='center'
            spacing={2}
          >
            {/* toggle card button */}
            <Grid item>
              <CustomButton
                variant='contained'
                label={
                  minimizeOrders
                    ? t("orders.maximizeOrders")
                    : t("orders.minimizeOrders")
                }
                sx={{
                  fontSize: "12px",
                  px: "20px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#2596be !important",
                    color: "white",
                  },
                }}
                onClick={() => setMinimizeOrder((m) => !m)}
              />
            </Grid>
            {/* home Icon */}
            <Grid item>
              <CustomButton
                variant='contained'
                label={t("orders.home")}
                startIcon={
                  <FiberManualRecordIcon style={{ fontSize: "0.6rem" }} />
                }
                sx={{
                  fontSize: "12px",
                  px: "20px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#2596be !important",
                    color: "white",
                  },
                }}
                onClick={() =>
                  navigate(`/${getLanguageFromURL()}/display/kitchen/`)
                }
              />
            </Grid>
            {/* setting Icon */}
            <Grid item>
              <CustomIconButton
                onClick={() =>
                  navigate(`/${getLanguageFromURL()}/display/kitchen/settings`)
                }
                marginTop='-1%'
                icon={<SettingsIcon />}
                sx={{ borderRadius: "20px", minWidth: "40px" }}
                iconSx={{ margin: 0 }}
                tooltipTitle={t("menu.settings")}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* orders */}
        {data.orders && (
          <Grid
            item
            sx={{ width: "100%", pt: "25px", flex: 1, maxHeight: "100%" }}
          >
            {orderComponent("DONE")}
          </Grid>
        )}
      </div>
    );
  }
}

export default KitchenDisplayComponent;
