import { CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { Grid } from '@mui/material';
import analyticsApi from 'api/analytics';
import { LineChartLoading } from 'assets/lottie/LottieAnimations';
import CustomIconButton from 'components/Button/CustomIconButton';
import EmployeesOrderBarChart from 'components/Charts/analyitics/EmployeesOrderBarChart';
import PaymentBarChart from 'components/Charts/analyitics/PaymentBarChart';
import CustomMuiDateTimeRangePicker from 'components/DatePicker/CustomMuiDateTimeRangePicker';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import CustomTypography from 'components/Typography/CustomTypography';
import { lottieChartOptions } from 'constants/animations/lottieOptions';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { lumanosimoTitleStyle, mainTagSearchItemOrderStatusIcon } from 'themes/defaultThemes';
import { dateTimeFormatterFilter } from 'utils/functions';
import { transformDataBarEmployees } from 'utils/functions/analytics';

/**
 * The AnalyticsEmployees, that display customized {@link Grid}to provide the list of analytics employee detais
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AnalyticsEmployees({ employeeID }) {
    const { t } = useTranslation();
    const [currency, setCurrency] = useState("€");
    const [paymentMethodsChartEmployeeData, setPaymentMethodsChartEmployeeData] = useState([]);
    const [orderData, setOrderData] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // for search
    const [search, setSearch] = useState({
        organizationID: "",
        outletIDs: [],
        dateFrom: dateTimeFormatterFilter(),
        dateTo: null,
        employeeID: employeeID
    });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getData();
    }, [search]);

    function getData() {
        analyticsApi.paymentMethodPieChartDataHistoryEmployee(search).then((r) => {
            let data = r.data.returnobject?.map((instance) => { return ((instance.employee === null) ? { ...instance, employee: { id: "0", name: "N/A" } } : instance) });
            let transfData = (data !== null && data?.length > 0) ? {
                series: transformDataBarEmployees(data),
                categories: data?.map((instance) => instance.employee.name)
            }
                :
                null;
            setPaymentMethodsChartEmployeeData(transfData);
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })

        analyticsApi.fetchOrderAnalytics(search).then((r) => {
            setOrderData(r.data.returnobject);
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }

    // =============== RENDER
    return (
        <Grid container gap={2} sx={{ textAlign: "center", placeContent: "center" }}>
            <Grid item xs={1}>
                <CustomIconButton
                    keyValue={`btn-search-date`}
                    onClick={() => setOpen(!open)}
                    icon={<CalendarMonthIcon />}
                    iconSx={mainTagSearchItemOrderStatusIcon()}
                    sx={{ ...mainTagSearchItemOrderStatusIcon(), borderRadius: "10px" }}
                />
                <CustomDialogBasedOnScreenChildren
                    isOpen={open}
                    handleClose={() => setOpen(false)}
                    handleOpen={() => setOpen(true)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    actionLabel={t('actions.ok')}
                    handleAction={() => setOpen(false)}
                    children={
                        <>
                            <CustomMuiDateTimeRangePicker
                                dateRange={search}
                                setDateRange={setSearch}
                            />
                        </>
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <div style={{ textAlign: "center", borderRadius: "30px", background: "white", padding: "20px", textAlign: "-webkit-center" }}>
                    {(isLoaded && paymentMethodsChartEmployeeData !== null) &&
                        <>
                            <PaymentBarChart
                                title={t('analytics.chart.employees.totalRevenue')}
                                height={400}
                                // labels={paymentMethodsChartEmployeeData.labels}
                                series={paymentMethodsChartEmployeeData.series}
                                categories={paymentMethodsChartEmployeeData.categories}
                                isEmployees={true}
                                currency={currency}
                            />
                        </>
                    }
                    {paymentMethodsChartEmployeeData === null &&
                        <>
                            <Lottie
                                animationData={LineChartLoading}
                                {...lottieChartOptions}
                            />
                            <CustomTypography
                                variant="body2"
                                text={t('analytics.chart.noData')}
                                sx={{ lumanosimoTitleStyle }}
                            />
                        </>
                    }
                </div>
            </Grid>


            <Grid item xs={12}>
                <div style={{ textAlign: "center", borderRadius: "30px", background: "white", padding: "20px", textAlign: "-webkit-center" }}>
                    {(isLoaded && orderData?.employeeOrders !== null && orderData?.employeeOrders?.length > 0) &&
                        <>
                            {(orderData != null && orderData?.employeeOrders) &&
                                <EmployeesOrderBarChart
                                    title={t('analytics.chart.employees.totalOrders')}
                                    employeeData={orderData.employeeOrders}
                                />
                            }
                        </>
                    }
                    {(!isLoaded || (isLoaded && (orderData?.employeeOrders === null || orderData?.employeeOrders?.length === 0))) &&
                        <>
                            <Lottie
                                animationData={LineChartLoading}
                                {...lottieChartOptions}
                            />
                            <CustomTypography
                                variant="body2"
                                text={t('analytics.chart.noData')}
                                sx={{ lumanosimoTitleStyle }}
                            />
                        </>
                    }
                </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>

            </Grid>
        </Grid>
    );
}

export default AnalyticsEmployees;