import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { mainAreaBody } from 'themes/defaultThemes';

/**
 * The CustomTable, that display a table if the provided array is not empty. Otherwise it 
 * presents a corresponding empty table message.
 * 
 * @property {string} divID the id of the outer div of the table tag
 * @property {string} classAppend extra classes for the table div element
 * @property {object} instances the list of items to be presented on a table if any
 * @property {boolean} showEmptyMessage to specify if the empty list message should be shown
 * @property {string} emptyMessage The message to presented if the @link(instances) is empty
 * @property {array} table_columns The datatable columns configuration
 * @property {string} keyField Tells react-bootstrap-table2 which column is unique.
 * @property {array} defaultSorted accept an object array which allow you to define the default sort columns when first render.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTable({ divID, classAppend = "", instances, showEmptyMessage = true, emptyMessage = "No data available.", table_columns, keyField, defaultSorted = [{ dataField: 'key', order: 'desc' }], hasCleanStyle = false }) {

    const [options, setOptions] = useState({
        filterType: 'checkbox',
        confirmFilters: false,
        download: false,
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        filter: false,
        filterArrayFullMatch: false,
        fixedHeader: false,
        fixedSelectColumn: false,
        jumpToPage: false,
        resizableColumns: false,
        pagination: false,
        print: false,
        rowHover: false,
        search: false,
        selectableRowsOnClick: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
    });

    function getMuiTheme() {
        return createTheme({
            components: {
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            color: "#fff",
                            height: hasCleanStyle ? "5px !important" : "58px",
                            backgroundColor: hasCleanStyle ? "white !important" : "#6d8092",
                            color: hasCleanStyle ? "#6d8092" : "white !important",
                            fontWeight: hasCleanStyle ? "900" : "300",
                            fontSize: "14px",
                            verticalAlign: "middle",
                            borderBottom: "2px solid #dee2e6",
                            paddingLeft: "10px"
                        },
                        ":first-child": {
                            paddingLeft: "20px"
                        }
                    }
                },
                MUIDataTableBodyCell: {
                    styleOverrides: {
                        root: {
                            height: hasCleanStyle ? "20px" : "58px",
                            bordeBottom: "2px solid #ededed",
                            border: "medium none",
                            verticalAlign: "middle",
                            color: "#6e8192",
                            height: "-webkit-fill-available"
                        },
                        "svg.svg-inline--fa.fa-ellipsis-v.fa-w-6": {
                            color: "#6d8092"
                        },
                        "svg:not(:root).svg-inline--fa": {
                            color: "#6d8092"
                        }
                    }
                },
                MuiTableCell: {
                    backgroundColor: "#f5f5f5",
                    padding: "10px 15px 10px 10px"
                },
                MUIDataTable: {
                    paper: {
                        boxShadow: "none"
                    },
                    responsiveBase: {
                        position: "initial",
                        display: "table !important",
                        height: "auto"
                    }
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            position: "relative !important",
                            marginLeft: "20px"
                        }
                    }
                }
            }
        })
    }

    return (
        <>
            {(showEmptyMessage && (instances === null || instances.length === 0)) &&
                <div className="body" style={mainAreaBody()}>
                    {emptyMessage}
                </div>
            }

            {(instances !== null && instances.length > 0) &&
                <div id={divID} className={`table-wrapper ${classAppend}`}>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            data={instances}
                            columns={table_columns}
                            options={options}
                        />
                    </ThemeProvider>
                </div>
            }
        </>
    );
}

export default CustomTable;