import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import CloseRounded from "@mui/icons-material/CloseRounded";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandIcon from "@mui/icons-material/Expand";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewListIcon from "@mui/icons-material/ViewList";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import CustomButton from "components/Button/CustomButton";
import AddUpdateCategoryDialog from "components/Dialogs/feedback/AddUpdateCategoryDialog";
import AddUpdateQuestionDialog from "components/Dialogs/feedback/AddUpdateQuestionDialog";
import DeleteFeedbackFromDialog from "components/Dialogs/feedback/DeleteFeedbackFromDialog";
import { suggestedQuestionsNumber } from "constants/defaultObjects";
import formatText from "utils/formatText";
import reorder from "utils/reOrder";

function FeedbackFormMainGrid({ categories, updateCategories }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(
    categories?.map((x) => `panel-${x.id}`) || []
  );
  const [moreMenu, setMoreMenu] = useState({
    active: null,
    category: null,
  });
  const [showAddQuestionDialog, setShowAddQuestionDialog] = useState({
    active: false,
    categoryId: null,
    question: undefined,
  });
  const [showMaxQuestionDialog, setShowMaxQuestionDialog] = useState({
    active: false,
    categoryId: null,
    question: undefined,
  });
  const [deleteQuestionDialog, setDeleteQuestionDialog] = useState({
    active: false,
    categoryId: null,
    question: null,
  });
  const [editFeedbackCategoryDialog, setEditFeedbackCategoryDialog] = useState({
    active: false,
    category: null,
  });
  const [deleteFeedbackCategoryDialog, setDeleteFeedbackCategoryDialog] =
    useState({
      active: false,
      category: null,
    });

  const toogleAccordianHandler = (id) => {
    setExpanded((exp) =>
      !exp.includes(`panel-${id}`)
        ? [...exp, `panel-${id}`]
        : exp.filter((x) => `${x}` !== `panel-${id}`)
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const ordered = reorder(
      categories,
      result.source.index,
      result.destination.index
    );

    updateCategories(ordered);
  };

  const onQuestionsDragEnd = (result, categoryId) => {
    if (!result.destination) return;
    const ordered = reorder(
      categories.find((x) => x.id === categoryId).questions,
      result.source.index,
      result.destination.index
    );

    updateCategories(
      categories.map((x) => {
        if (x.id === categoryId) {
          x.questions = ordered;
        }
        return x;
      })
    );
  };

  const addQuestionClickHandler = (id) => {
    if (
      categories.reduce((sum, c) => c.questions.length + sum, 0) >=
      suggestedQuestionsNumber
    ) {
      setShowMaxQuestionDialog({
        active: true,
        categoryId: id,
        question: undefined,
      });
    } else {
      setShowAddQuestionDialog({
        active: true,
        categoryId: id,
        question: undefined,
      });
    }
  };
  const moreMenuId = "more-menu";
  const renderMoreMenu = (
    <Menu
      id={moreMenuId}
      anchorEl={moreMenu.active}
      open={Boolean(moreMenu.active)}
      onClose={() => {
        setMoreMenu({
          active: null,
          category: null,
        });
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem
        onClick={() => {
          addQuestionClickHandler(moreMenu.category?.id);

          setMoreMenu({
            active: null,
            category: null,
          });
        }}
        sx={{ fontSize: "12px", fontWeight: 500 }}
      >
        {t("feedback.addQuestion")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          setEditFeedbackCategoryDialog({
            active: true,
            category: moreMenu.category,
          });
          setMoreMenu({
            active: null,
            category: null,
          });
        }}
        sx={{ fontSize: "12px", fontWeight: 500 }}
      >
        {t("feedback.edit")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          setDeleteFeedbackCategoryDialog({
            active: true,
            category: moreMenu.category,
          });
          setMoreMenu({
            active: null,
            category: null,
          });
        }}
        sx={{ fontSize: "12px", fontWeight: 500 }}
      >
        {t("feedback.delete")}
      </MenuItem>
    </Menu>
  );

  const maxQuestionDialog = (
    <Dialog
      open={showMaxQuestionDialog.active}
      onClose={() => {
        setShowMaxQuestionDialog({
          active: false,
          categoryId: null,
          question: undefined,
        });
      }}
      aria-describedby='alert-dialog-slide-description'
      className='dialog-title'
    >
      <DialogTitle className='dialog-headers'>
        {t("feedback.maxQuestionDialog.title")}
        <IconButton
          onClick={() => {
            setShowMaxQuestionDialog({
              active: false,
              categoryId: null,
              question: undefined,
            });
          }}
          sx={{ float: "right" }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: "white" }}>
        <DialogContentText className='dialog-desciption'>
          {t("feedback.maxQuestionDialog.message", {
            suggestedQuestionsNumber: suggestedQuestionsNumber,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions className='dialog-actions'>
        <Button
          className='dialog-cancel-btn'
          onClick={() => {
            setShowMaxQuestionDialog({
              active: false,
              categoryId: null,
              question: undefined,
            });
          }}
        >
          {t("feedback.maxQuestionDialog.cancel")}
        </Button>
        <Button
          className='dialog-action-btn'
          sx={{
            color: "#3593D2",
            ":hover": { backgroundColor: "#3593D2" + "52" },
          }}
          onClick={() => {
            setShowAddQuestionDialog({
              active: true,
              categoryId: showMaxQuestionDialog.categoryId,
              question: showMaxQuestionDialog.question,
            });
            setShowMaxQuestionDialog({
              active: false,
              categoryId: null,
              question: undefined,
            });
          }}
        >
          {t("feedback.maxQuestionDialog.proceed").toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <div>
      {renderMoreMenu}
      {maxQuestionDialog}

      <AddUpdateCategoryDialog
        open={editFeedbackCategoryDialog.active}
        editData={editFeedbackCategoryDialog.category}
        onClose={() => {
          setEditFeedbackCategoryDialog({
            active: false,
            id: null,
          });
        }}
        onSubmit={(data) => {
          updateCategories(
            categories.map((c) => {
              if (c.id === data.id) {
                return {
                  ...c,
                  ...data,
                };
              }
              return c;
            })
          );
          setEditFeedbackCategoryDialog({
            active: false,
            category: null,
          });
        }}
      />

      <DeleteFeedbackFromDialog
        open={deleteFeedbackCategoryDialog.active}
        onClose={() => {
          setDeleteFeedbackCategoryDialog({
            active: false,
            category: null,
          });
        }}
        title={t("actions.deleteModal.title")}
        message={`${t("actions.deleteModal.notice")} ${t(
          "actions.deleteModal.confirm"
        )} : `}
        boldMessage={deleteFeedbackCategoryDialog.category?.name.en}
        action={() => {
          updateCategories(
            categories.filter(
              (c) => c.id !== deleteFeedbackCategoryDialog.category?.id
            )
          );
          setDeleteFeedbackCategoryDialog({
            active: false,
            category: null,
          });
        }}
      />

      <AddUpdateQuestionDialog
        open={showAddQuestionDialog.active}
        editData={showAddQuestionDialog.question}
        onClose={() => {
          setShowAddQuestionDialog({
            active: false,
            categoryId: null,
            question: undefined,
          });
        }}
        onSubmit={(data) => {
          //edit
          if (showAddQuestionDialog.question) {
            updateCategories(
              categories.map((c) => {
                if (c.id === showAddQuestionDialog.categoryId) {
                  return {
                    ...c,
                    questions: c.questions.map((q) => {
                      if (q.id === data.id) {
                        return data;
                      }
                      return q;
                    }),
                  };
                }
                return c;
              })
            );
          }
          //add
          else {
            updateCategories(
              categories.map((c) => {
                if (c.id === showAddQuestionDialog.categoryId) {
                  return {
                    ...c,
                    questions: [...c.questions, data],
                  };
                }
                return c;
              })
            );
          }

          if (!expanded.includes(`panel-${showAddQuestionDialog.categoryId}`)) {
            toogleAccordianHandler(showAddQuestionDialog.categoryId);
          }
          setShowAddQuestionDialog({
            active: false,
            categoryId: null,
            question: undefined,
          });
        }}
      />

      <DeleteFeedbackFromDialog
        open={deleteQuestionDialog.active}
        onClose={() => {
          setDeleteQuestionDialog({
            active: false,
            categoryId: null,
            question: null,
          });
        }}
        title={t("actions.deleteModal.title")}
        message={`${t("actions.deleteModal.notice")} ${t(
          "actions.deleteModal.confirm"
        )} : `}
        boldMessage={deleteQuestionDialog.question?.question?.en}
        action={() => {
          updateCategories(
            categories.map((c) => {
              if (c.id === deleteQuestionDialog.categoryId) {
                return {
                  ...c,
                  questions: c.questions.filter(
                    (q) => q.id !== deleteQuestionDialog.question?.id
                  ),
                };
              }
              return c;
            })
          );
          setDeleteQuestionDialog({
            active: false,
            categoryId: null,
            question: null,
          });
        }}
      />

      <Grid container direction='column'>
        {/* heading */}
        <Grid item>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            gap={2}
          >
            <Grid item>
              <Typography
                variant='h6'
                style={{ color: "#000", fontWeight: "500" }}
              >
                {t("feedback.categories")}
              </Typography>
            </Grid>
            {/* collapseAll */}
            <Grid item>
              {categories.length > 0 && (
                <Grid container gap={2}>
                  {/* expandAll */}
                  <CustomButton
                    variant='contained'
                    keyValue='save'
                    label={t("feedback.expandAll")}
                    sx={{
                      // ...formSaveButton(),
                      fontSize: "12px",
                      px: "20px",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#2596be !important",
                        color: "white",
                      },
                      marginTop: 0,
                    }}
                    startIcon={<ExpandIcon />}
                    onClick={() =>
                      setExpanded(categories.map((x) => `panel-${x.id}`))
                    }
                  />

                  {/* collapseAll */}
                  <CustomButton
                    variant='contained'
                    keyValue='save'
                    label={t("feedback.collapseAll")}
                    sx={{
                      // ...formSaveButton(),
                      fontSize: "12px",
                      px: "20px",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#2596be !important",
                        color: "white",
                      },
                      marginTop: 0,
                    }}
                    startIcon={<CloseFullscreenIcon />}
                    onClick={() => setExpanded([])}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='page'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {categories.map((item, i) => {
                  const isDisable = item.addedFromAdmin;
                  //  defaultCategories.some(
                  //   (x) => x.id === item.id
                  // );
                  const expandedRow = expanded.includes(`panel-${item.id}`);
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={`${item.id}`}
                      index={i}
                      isDragDisabled={isDisable}
                    >
                      {(provided, snapshot) => (
                        <Grid
                          item
                          key={i}
                          sx={{ mt: i === 0 ? "25px" : "12px" }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Accordion
                            elevation={0}
                            expanded={expandedRow}
                            onChange={() => {
                              if (item.questions.length > 0) {
                                toogleAccordianHandler(item.id);
                              }
                            }}
                            sx={{
                              p: 0,
                              margin: 0,
                              "& .MuiAccordionSummary-root:hover:not(.Mui-disabled)":
                              {
                                cursor:
                                  item.questions.length > 0
                                    ? "pointer"
                                    : "unset",
                              },
                              "& .MuiAccordionSummary-root.Mui-expanded": {
                                minHeight: "unset",
                              },
                            }}
                          >
                            <AccordionSummary
                              aria-controls={`panel-${item.id}`}
                              id={`panel-${item.id}`}
                              sx={{
                                p: 0,
                                margin: 0,
                              }}
                            >
                              <Grid container gap={2}>
                                {/* arrow icons */}
                                <Grid
                                  item
                                  sx={{
                                    visibility:
                                      item.questions.length > 0
                                        ? "visible"
                                        : "hidden",
                                  }}
                                >
                                  {expandedRow ? (
                                    <KeyboardArrowDownIcon />
                                  ) : (
                                    <KeyboardArrowRightIcon />
                                  )}
                                </Grid>
                                {/* drag icon, name actions */}
                                <Grid
                                  item
                                  sx={{
                                    flex: 1,
                                    pb: expandedRow ? 0 : "8px",
                                    borderBottom: expandedRow
                                      ? 0
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                  }}
                                >
                                  <Grid container gap={2}>
                                    {/* drag icon */}
                                    <Grid item>
                                      <IconButton
                                        {...provided.dragHandleProps}
                                        sx={{ p: 0 }}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <ViewListIcon />
                                      </IconButton>
                                    </Grid>
                                    {/* name actions */}
                                    <Grid
                                      item
                                      sx={{
                                        flex: 1,
                                        pb: expandedRow ? "8px" : 0,
                                        borderBottom: expandedRow
                                          ? "1px solid rgba(0, 0, 0, 0.12)"
                                          : 0,
                                      }}
                                    >
                                      <Grid
                                        container
                                        justifyContent='space-between'
                                      >
                                        {/* name */}
                                        <Grid item>
                                          <Typography
                                            variant='subtitle1'
                                            style={{
                                              color: "#000",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {item.name.en}
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          {/* add question, more menu */}
                                          {!isDisable && (
                                            <Grid container gap={2}>
                                              {/* add question */}
                                              <CustomButton
                                                variant='contained'
                                                keyValue='save'
                                                label={t(
                                                  "feedback.addQuestion"
                                                )}
                                                sx={{
                                                  // ...formSaveButton(),
                                                  color: "#000",
                                                  fontSize: "12px",
                                                  px: "20px",
                                                  textTransform: "none",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "transparent !important",
                                                    color: "#000",
                                                  },
                                                  marginTop: 0,
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  addQuestionClickHandler(
                                                    item.id
                                                  );
                                                }}
                                              />
                                              <IconButton
                                                disableRipple
                                                sx={{
                                                  p: 0,
                                                }}
                                                aria-controls={
                                                  Boolean(moreMenu.active)
                                                    ? moreMenuId
                                                    : undefined
                                                }
                                                aria-haspopup='true'
                                                aria-expanded={
                                                  Boolean(moreMenu.active)
                                                    ? "true"
                                                    : undefined
                                                }
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setMoreMenu({
                                                    active: e.currentTarget,
                                                    category: item,
                                                  });
                                                }}
                                              >
                                                <MoreVertIcon
                                                  sx={{ color: "#000" }}
                                                />
                                              </IconButton>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </AccordionSummary>

                            <AccordionDetails sx={{ p: 0 }}>
                              <DragDropContext
                                onDragEnd={(result) =>
                                  onQuestionsDragEnd(result, item.id)
                                }
                              >
                                <Droppable droppableId={`questions-${item.id}`}>
                                  {(questionProvided) => (
                                    <div
                                      {...questionProvided.droppableProps}
                                      ref={questionProvided.innerRef}
                                    >
                                      {item.questions.map((question, qInd) => (
                                        <Draggable
                                          key={question.id}
                                          draggableId={`${question.id}`}
                                          index={qInd}
                                          isDragDisabled={isDisable}
                                        >
                                          {(
                                            questionProvided,
                                            questionSnapshot
                                          ) => (
                                            <Grid
                                              container
                                              gap={2}
                                              key={i}
                                              sx={{ mt: "15px" }}
                                              ref={questionProvided.innerRef}
                                              {...questionProvided.draggableProps}
                                            >
                                              <Grid
                                                item
                                                sx={{ visibility: "hidden" }}
                                              >
                                                <KeyboardArrowDownIcon />
                                              </Grid>
                                              {/* drag icon, name actions */}
                                              <Grid
                                                item
                                                sx={{
                                                  flex: 1,
                                                  pl: "35px",
                                                }}
                                              >
                                                <Grid
                                                  container
                                                  gap={2}
                                                  sx={{
                                                    pb: "13px",
                                                    borderBottom:
                                                      "1px solid rgba(0, 0, 0, 0.12)",
                                                  }}
                                                >
                                                  <Grid item>
                                                    <IconButton
                                                      {...questionProvided.dragHandleProps}
                                                      sx={{ p: 0 }}
                                                      onClick={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                    >
                                                      <ViewListIcon />
                                                    </IconButton>
                                                  </Grid>

                                                  {/* name actions */}
                                                  <Grid
                                                    item
                                                    sx={{
                                                      flex: 1,
                                                    }}
                                                  >
                                                    <Grid
                                                      container
                                                      justifyContent='space-between'
                                                    >
                                                      {/* question */}
                                                      <Grid item>
                                                        <Typography
                                                          variant='subtitle1'
                                                          style={{
                                                            color: "#000",
                                                            fontWeight: "500",
                                                          }}
                                                          dangerouslySetInnerHTML={{
                                                            __html: formatText(
                                                              question.question
                                                                .en
                                                            ),
                                                          }}
                                                        />
                                                        {/* {formatText(
                                                            question.question.en
                                                          )} */}
                                                        {/* {question.question.en} */}
                                                        {/* </Typography> */}
                                                      </Grid>
                                                      {/* edit delete */}
                                                      <Grid item>
                                                        {!isDisable && (
                                                          <Grid
                                                            container
                                                            alignItems='center'
                                                            gap={3}
                                                            sx={{
                                                              marginRight:
                                                                "20px",
                                                            }}
                                                          >
                                                            <Tooltip title='Edit'>
                                                              <IconButton
                                                                sx={{
                                                                  p: 0,
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                                disableRipple
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.stopPropagation();
                                                                  setShowAddQuestionDialog(
                                                                    {
                                                                      active: true,
                                                                      categoryId:
                                                                        item.id,
                                                                      question:
                                                                        question,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <CreateIcon />
                                                              </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title='Delete'>
                                                              <IconButton
                                                                sx={{
                                                                  p: 0,
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                                disableRipple
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.stopPropagation();
                                                                  setDeleteQuestionDialog(
                                                                    {
                                                                      active: true,
                                                                      categoryId:
                                                                        item.id,
                                                                      question:
                                                                        question,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <DeleteIcon />
                                                              </IconButton>
                                                            </Tooltip>
                                                          </Grid>
                                                        )}
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Draggable>
                                      ))}
                                      {questionProvided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </div>
  );
}

export default FeedbackFormMainGrid;