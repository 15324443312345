import { TabContext, TabPanel } from '@mui/lab';
import { Grid } from '@mui/material';
import orderApi from 'api/order';
import { ArrowBackIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomTabs from 'components/Tab/CustomTabs';
import CustomOrderActionsHistoryTimeline from 'components/Timeline/CustomOrderActionsHistoryTimeline';
import CustomOrderPaymentActionsHistoryTimeline from 'components/Timeline/CustomOrderPaymentActionsHistoryTimeline';
import CustomTypography from 'components/Typography/CustomTypography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mainTag, orderBackBtnStyle, pastOrderViewHeaderTextStyle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import CustomSinglePastOrderCardItem from './CustomSinglePastOrderCardItem';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from 'components/Loading/LoadingSkeleton';
import CustomSinglePastOrderCardInfoEdit from './CustomSinglePastOrderCardInfoEdit';

/**
 * The CustomPDAViewOrderGrid, that display a custom {@link Grid} for view order from PDA
 * 
 * @property {string} label the instance name
 * @property {*} icon the icon represented the instance
 * @property {int} totalNumber the total number of the instances presented
 * @property {string} link the onclick card navigation link
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPDAViewOrderGrid({ orderID, handleReset, setAlertBox }) {
    const { t } = useTranslation();

    /**
     * @type {object}
     * 
     * @property {object} userAuth the authenticated user infromation
     * @property {object} order the order details
     * @property {array} equipments the equipment option list
     * @property {array} statuses the status option list
     * @property {string} outlet outlet currency
     * @property {object} newEquipmentID tha field to keep the new equipment value
     */
    const [data, setData] = useState({
        userAuth: null,
        order: null,
        equipments: [],
        statuses: [],
        types: [],
        currency: "€",
        newEquipmentID: null
    });

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (isFirstTime)
            getOrder();
        setIsFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get new order basic details
     */
    function getOrder() {
        orderApi.fetchOne(orderID).then((r) => {
            if (r.data.code === "SUCCESS") {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            order: r.data.returnobject.order,
                            equipments: r.data.returnobject.equipments,
                            statuses: r.data.returnobject.statuses,
                            types: r.data.returnobject.types,
                            currency: r.data.returnobject.currency
                        })
                    })
                    .then(() => setOrderType(r.data.returnobject.order.type))
                    .then(() => setOrderStatus(r.data.returnobject.order.status))
                    .then(() => setIsLoaded(true))

            }
        }).catch((e) => {
            // console.log(e);
        })
    }

    // ========================= Order Type
    const [orderTypeAnchorEl, setOrderTypeAnchorEl] = useState(null);

    function updateOrderTypeOpenCallback(event) {
        setOrderTypeAnchorEl(event.currentTarget);
    };

    function updateOrderTypeCloseCallback() {
        setOrderTypeAnchorEl(null);
    };

    function updateOrderTypeCallback(newValue) {
        formValChangeWithParentElementWithNameAndValue("cart.type", newValue, data, setData);
        changeType(data.order?.id, newValue)
        // dispatch(updateCartPaymentMethod(data.outlet.id, [newValue]));
        // Optionally close the popover
        updateOrderTypeCloseCallback();
    };

    const openOrderType = Boolean(orderTypeAnchorEl);

    // ========================= PERSONS 
    const [personsAnchorEl, setPersonsAnchorEl] = useState(null);

    function updatePersonsOpenCallback(event) {
        setPersonsAnchorEl(event.currentTarget);
    };

    function updatePersonsCloseCallback() {
        setPersonsAnchorEl(null);
    };

    function updatePersonsCallback(newValue) {
        formValChangeWithParentElementWithNameAndValue("cart.persons", newValue, data, setData);
        // Optionally close the popover
        // updatePersonsCloseCallback();
    };


    function updateOrderPersonsCallback(persons) {
        changePersons(data.order?.id, persons)
    };
    const openPersons = Boolean(personsAnchorEl);

    /**
     * 
     * @param {*} event 
     * @param {*} id 
     * @param {*} status 
     */
    function changeOrderStatus(event, id, status) {
        orderApi.updateOrderStatus(id, status).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            if (r.data?.code === "SUCCESS")
                getOrder();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * 
     * @param {*} event 
     * @param {*} id 
     * @param {*} status 
     */
    function changeType(id, newValue) {
        let data = {
            type: newValue
        };
        orderApi.updateType(id, data).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            if (r.data?.code === "SUCCESS")
                getOrder();
        }).catch((e) => {
            // console.log(e);
        })
    }
    const [orderType, setOrderType] = useState('');

    /**
     * 
     * @param {*} id 
     */
    function changePersons(id, persons) {
        let data = {
            persons: persons
        };
        orderApi.updatePersons(id, data).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            if (r.data?.code === "SUCCESS")
                getOrder();
        }).catch((e) => {
            // console.log(e);
        })
    }


    // ========================= EQUIPMENT 
    const [equipmentAnchorEl, setEquipmentAnchorEl] = useState(null);
    const [equipment, setEquipment] = useState('');

    function updateEquipmentOpenCallback(event) {
        setEquipment(equipment?.label)
        setEquipmentAnchorEl(event.currentTarget);
    };

    function updateEquipmentCloseCallback() {
        setEquipmentAnchorEl(null);
    };

    function updateEquipmentValue(newValue) {
        setEquipment(newValue);
    };

    function updateEquipmentCallback(event) {
        // validateNewEquipmentLabel(equipment);
        changeTable(event, data.order?.id)
    };

    /**
     * 
     * @param {*} event 
     * @param {*} id 
     * @param {*} status 
     */
    function changeTable(event, id) {
        let data = {
            equipmentID: equipment?.id
        };
        orderApi.updateTable(id, data).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            if (r.data?.code === "SUCCESS")
                getOrder();
        }).catch((e) => {
            // console.log(e);
        })
    }

    const openEquipment = Boolean(equipmentAnchorEl);

    /**
     * The rest endpoint to validate the new inputed equipment label and return its details (id, label).
     */
    // function validateNewEquipmentLabel(eq) {
    //     equipmentApi.validateExistance(data.outlet?.id, eq).then((r) => {
    //         if (r.data?.code === "SUCCESS" && r.data?.returnobject !== null) {
    //             // Optionally close the popover
    //             updateEquipmentCloseCallback();
    //             setEquipmentInvalid(false);
    //         } else {
    //             setEquipmentInvalid(true);
    //         }
    //     }).catch((e) => {
    //         // console.log(e);
    //     });
    // }


    // ========================= ORDER STATUS 
    const [orderStatusAnchorEl, setOrderStatusAnchorEl] = useState(null);
    const [orderStatus, setOrderStatus] = useState('');

    function updateOrderStatusOpenCallback(event) {
        setOrderStatus(orderStatus?.label)
        setOrderStatusAnchorEl(event.currentTarget);
    };

    function updateOrderStatusCloseCallback() {
        setOrderStatusAnchorEl(null);
    };

    function updateOrderStatusValue(newValue) {
        setOrderStatus(newValue);
    };

    function updateOrderStatusCallback(event) {
        changeOrderStatus(event, data.order?.id, orderStatus)
    };

    const openOrderStatus = Boolean(orderStatusAnchorEl);

    const [value, setValue] = useState("info");

    // ============================== RENDER
    if (!isLoaded) {
        return <CustomGrid role="main" id="pda-order-view" sx={mainTag()}>
            <LoadingSkeleton lines={9} />
        </CustomGrid>
    } else {
        return (
            <>
                <Grid item xs={3} md={3}>
                    <CustomIconButton
                        icon={<ArrowBackIcon />}
                        sx={orderBackBtnStyle()}
                        onClick={() => handleReset()}
                        label={t('pda.backButtonEqDialog')}
                    />
                </Grid>

                <Grid item xs={8} md={8}>
                    <CustomTypography variant="body2" text={t('pda.order.viewSingle')} sx={pastOrderViewHeaderTextStyle()} />
                </Grid>

                <Grid item xs={12} md={12}>
                    <TabContext value={value}>
                        <CustomTabs
                            initValue={value}
                            instances={[
                                { name: t('pda.tabsView.info'), value: "info" },
                                { name: t('pda.tabsView.cart'), value: "cart" },
                                { name: t('pda.tabsView.status'), value: "status" },
                                { name: t('pda.tabsView.payment'), value: "payment" },
                            ]}
                            setValue={setValue}
                        />

                        <TabPanel value={"info"} sx={{ paddingTop: "0px" }}>
                            <Grid container gap={1}>
                                <CustomSinglePastOrderCardInfoEdit
                                    order={data.order}
                                    equipment={data.equipments?.find((eq) => eq.id === data.order?.equipmentID)}
                                    equipments={data.equipments}
                                    statuses={data.statuses}
                                    outlet={data.outlet}
                                    //equipment
                                    equipmentAnchorEl={equipmentAnchorEl}
                                    updateEquipmentOpenCallback={updateEquipmentOpenCallback}
                                    updateEquipmentCloseCallback={updateEquipmentCloseCallback}
                                    updateEquipmentCallback={updateEquipmentCallback}
                                    updateEquipmentValue={updateEquipmentValue}
                                    openEquipment={openEquipment}
                                    //status
                                    changeOrderStatus={changeOrderStatus}
                                    orderStatusAnchorEl={orderStatusAnchorEl}
                                    orderStatus={orderStatus}
                                    updateOrderStatusOpenCallback={updateOrderStatusOpenCallback}
                                    updateOrderStatusCloseCallback={updateOrderStatusCloseCallback}
                                    updateOrderStatusValue={updateOrderStatusValue}
                                    updateOrderStatusCallback={updateOrderStatusCallback}
                                    openOrderStatus={openOrderStatus}
                                    //persons
                                    personsAnchorEl={personsAnchorEl}
                                    openPersons={openPersons}
                                    updatePersonsCloseCallback={updatePersonsCloseCallback}
                                    updatePersonsCallback={updatePersonsCallback}
                                    updatePersonsOpenCallback={updatePersonsOpenCallback}
                                    updateOrderPersonsCallback={updateOrderPersonsCallback}
                                    //type
                                    orderTypeAnchorEl={orderTypeAnchorEl}
                                    openOrderType={openOrderType}
                                    orderType={orderType}
                                    updateOrderTypeCloseCallback={updateOrderTypeCloseCallback}
                                    updateOrderTypeCallback={updateOrderTypeCallback}
                                    updateOrderTypeOpenCallback={updateOrderTypeOpenCallback}
                                    types={data.types}
                                />
                            </Grid>
                        </TabPanel>

                        <TabPanel value={"cart"} sx={{ paddingTop: "0px" }}>
                            <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                                <Grid container gap={1}>
                                    {data.order?.cart?.map((cartItem) =>
                                        <Grid item xs={12} md={12}>
                                            <CustomSinglePastOrderCardItem cartItem={cartItem} currency={data.order?.outlet?.currency} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={"status"} sx={{ paddingTop: "0px" }}>
                            <CustomOrderActionsHistoryTimeline timelineDetails={data.order?.actionsHistory} />
                        </TabPanel>

                        <TabPanel value={"payment"} sx={{ paddingTop: "0px" }}>
                            <CustomOrderPaymentActionsHistoryTimeline timelineDetails={data.order?.paymentActionsHistory} currency={data.currency} items={data.order?.cart} />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </>
        );
    }
}

export default CustomPDAViewOrderGrid;