import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

/**
 * The ListPageSkeleton, that display a box with animated skeletons.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ListPageSkeleton({ isMobile }) {
    let rowStyle = { borderRadius: "5px", mb: "5px" }
    return (
        <Box sx={{ width: "90%", padding: `20px ${isMobile ? "0" : "30px"}` }}>
            <Grid container gap={2} columnSpacing={1}>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" animation="wave" width={40} height={40} sx={{...rowStyle, mb: "20px"}} />
                </Grid>

                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" animation="wave" width={200} height={50} sx={{...rowStyle, mb: "20px"}} />
                </Grid>

                <Box sx={{ flexGrow: 1 }} />

                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" animation="wave" width={80} height={40} sx={{...rowStyle, mb: "20px"}} />
                </Grid>
            </Grid>
            {/* Skeleton for tabs */}
            <Skeleton variant="rounded" animation="wave" height={80} sx={rowStyle} />
            <Skeleton variant="rounded" animation="wave" height={30} sx={rowStyle} />
            <Skeleton variant="rounded" animation="wave" height={30} sx={rowStyle} />
            <Skeleton variant="rounded" animation="wave" height={30} sx={rowStyle} />
            <Skeleton variant="rounded" animation="wave" height={30} sx={rowStyle} />
            <Skeleton variant="rounded" animation="wave" height={30} sx={rowStyle} />
            <Skeleton variant="rounded" animation="wave" height={30} sx={rowStyle} />
            <Skeleton variant="rounded" animation="wave" height={30} sx={rowStyle} />
            <Skeleton variant="rounded" animation="wave" height={30} sx={rowStyle} />
        </Box>
    );
}

export default ListPageSkeleton;