import * as React from 'react';
import * as _ from 'lodash';

export const defaultOptions = {
  mobileLimit: 468,
  tabletLimit: 768,
  shouldListenOnResize: true,
};

export const ScreenType = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

export function withGetScreen(WrappedComp, options = defaultOptions) {
  const WithGetScreen = () => {
    const [currentSize, setCurrentSize] = React.useState(
      getSize(window.innerWidth)
    );

    const onResize = _.throttle(() => {
      const newSize = getSize(window.innerWidth);
      if (newSize !== currentSize) {
        setCurrentSize(newSize);
      }
    }, 100);

    React.useEffect(() => {
      if (options.shouldListenOnResize) {
        window.addEventListener('resize', onResize);
      }

      return () => {
        onResize.cancel();
        window.removeEventListener('resize', onResize);
      };
    }, []);

    const getSize = (size) => {
      if (size <= options.mobileLimit) {
        return ScreenType.MOBILE;
      } else if (size >= options.tabletLimit) {
        return ScreenType.DESKTOP;
      } else {
        return ScreenType.TABLET;
      }
    };

    const isMobile = () => {
      return currentSize === ScreenType.MOBILE;
    };

    const isTablet = () => {
      return currentSize === ScreenType.TABLET;
    };

    const isDesktop = () => {
      return currentSize === ScreenType.DESKTOP;
    };

    const detectMethods = {
      isMobile,
      isTablet,
      isDesktop,
    };

    return <WrappedComp {...detectMethods} />;
  };

  return WithGetScreen;
}

export function isMobileDevice() {
  // console.log(navigator.userAgent)
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WebView/i.test(navigator.userAgent);
}