import {
    Close as CloseIcon,
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import {
    BalloonBottom,
    BalloonTop,
    Border10LabelFlat,
    Bottom,
    D1Label,
    Hexagon10Label,
    RibbonBottom,
    RibbonTop,
    SimpleBottom,
    SimpleTop,
    Top,
    Cinema,
    Phone,
    RoundBorder,
    CircleLabel,
    CircleThinFlat,
    Corners,
    CornersLabelFlat,
    DonitDisturb,
    LockScreen,
    LockscreenCircle,
    LockscreenRounded,
    RoundedBorder10LabelFlat,
    RoundedCorners,
    RoundedCornersFlat,
} from 'assets/svg/SvgIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import { getQrcodeFrameButtonIconProperties, getQrcodeFrameButtonProperties } from 'constants/fieldProperties';
import { noneCardButton } from 'themes/qrcode/defaultTheme';

/**
 * Custom QRcode eye radius options
 * @returns 
 */
function QRCodeFrame({
    qrCodeTemplate,
    setQrCodeTemplate,
    formValChangeWithParentElementWithNameAndValue
}) {

    let withLabel = qrCodeTemplate.frameText.length > 0;

    return (
        <>
            <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
                <Grid container gap={2}>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeFrameButtonProperties()}
                            keyValue={`no-frame`}
                            onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "none", qrCodeTemplate, setQrCodeTemplate)}
                            isFocused={qrCodeTemplate.frameStyle === "none"}
                            icon={<CloseIcon sx={noneCardButton()} />}
                        />
                    </Grid>

                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeFrameButtonProperties()}
                            keyValue={`frame-outer-circle-thin-flat`}
                            onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "CircleThinFlat", qrCodeTemplate, setQrCodeTemplate)}
                            isFocused={qrCodeTemplate.frameStyle === "CircleThinFlat"}
                            icon={<CircleThinFlat {...getQrcodeFrameButtonIconProperties()} />}
                        />
                    </Grid>

                    {withLabel &&
                        <>
                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-simlple-top`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "SimpleTop", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "SimpleTop"}
                                    icon={<SimpleTop {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-simlple-bottom`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "SimpleBottom", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "SimpleBottom"}
                                    icon={<SimpleBottom {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-balloon-top`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "BalloonTop", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "BalloonTop"}
                                    icon={<BalloonTop {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-balloon-bottom`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "BalloonBottom", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "BalloonBottom"}
                                    icon={<BalloonBottom {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-ribbon-top`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "RibbonTop", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "RibbonTop"}
                                    icon={<RibbonTop {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-ribbon-bottom`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "RibbonBottom", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "RibbonBottom"}
                                    icon={<RibbonBottom {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-top`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "Top", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "Top"}
                                    icon={<Top {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-bottom`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "Bottom", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "Bottom"}
                                    icon={<Bottom {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-phone`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "Phone", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "Phone"}
                                    icon={<Phone {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-cinema`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "Cinema", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "Cinema"}
                                    icon={<Cinema {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-round-border`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "RoundBorder", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "RoundBorder"}
                                    icon={<RoundBorder {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-corners-label-flat`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "CornersLabelFlat", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "CornersLabelFlat"}
                                    icon={<CornersLabelFlat {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>


                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-dontdisturb`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "DonitDisturb", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "DonitDisturb"}
                                    icon={<DonitDisturb {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-lockscreen-circle`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "LockscreenCircle", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "LockscreenCircle"}
                                    icon={<LockscreenCircle {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>
                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-lockscreen-rounded`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "LockscreenRounded", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "LockscreenRounded"}
                                    icon={<LockscreenRounded {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            {/* <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-lockscreen`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "LockScreen", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "LockScreen"}
                                    icon={<LockScreen {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid> */}

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-roundborder10-label-flat`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "RoundedBorder10LabelFlat", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "RoundedBorder10LabelFlat"}
                                    icon={<RoundedBorder10LabelFlat {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-hexagon10-label`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "Hexagon10Label", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "Hexagon10Label"}
                                    icon={<Hexagon10Label {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-circle-label`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "CircleLabel", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "CircleLabel"}
                                    icon={<CircleLabel {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-rounded-corners-flat`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "RoundedCornersFlat", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "RoundedCornersFlat"}
                                    icon={<RoundedCornersFlat {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>
                        </>
                    }

                    {!withLabel &&
                        <>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-corners`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "Corners", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "Corners"}
                                    icon={<Corners {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-3d-1-label`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "D1Label", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "D1Label"}
                                    icon={<D1Label {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                            <Grid item>
                                <CustomIconButton
                                    {...getQrcodeFrameButtonProperties()}
                                    keyValue={`frame-outer-rounded-corners`}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("frameStyle", "RoundedCorners", qrCodeTemplate, setQrCodeTemplate)}
                                    isFocused={qrCodeTemplate.frameStyle === "RoundedCorners"}
                                    icon={<RoundedCorners {...getQrcodeFrameButtonIconProperties()} />}
                                />
                            </Grid>

                        </>
                    }


                </Grid>
            </Grid>
        </>


    );
}
export default QRCodeFrame;
