import React from 'react';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import CustomMenuParentCategoriesDraggableTableItem from "components/Table/CustomMenuParentCategoriesDraggableTableItem";

/**
 * A Custom Draggable List.
 * 
 * @param {array} parentCategories the array of the parent categories
 * @param {array} categoryOptions the array of the categories
 * @param {array} itemOptions the array of the items
 * @param {boolean} showEmptyMessage to specify if the empty list message should be shown
 * @param {string} emptyMessage The message to presented if the `label` is empty
 * @param {boolean} expandAll to specify if the all child elements should be shown
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuParentCategoriesDraggableTable({ parentCategories, showEmptyMessage = true, emptyMessage, expandAll, organizationID, handleTranslationsDialogState,
    removeParentCategory, updateParentCategoryName, updateParentCategoryIsActive, updateMenuParentCategoriesOrder, allowParentCategories,// parent categories
    categoryOptions, addCategoryToParentCategory, updateCategoryName, updateCategoryIsActive, updateMenuCategoriesOrder, removeCategory,// categories
    itemOptions, removeItemFromCategory, handleDialogState, updateItemOrder //items
}) {
    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = async (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }
        const oredereCategories = reorder(parentCategories, result.source.index, result.destination.index)
        parentCategories = oredereCategories;
        updateMenuParentCategoriesOrder(oredereCategories)
    }

    return (
        <>
            {(showEmptyMessage && (parentCategories === null || parentCategories.length === 0)) &&
                <div className="body small">
                    {emptyMessage}
                </div>
            }

            {(parentCategories !== null && parentCategories.length > 0) &&
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-menu-parentCategories-list" type="PARENT-CATEGORIES">
                        {(provided, snapshot) => (
                            <ul className="droppable-menu-parentCategories-list" ref={provided.innerRef} {...provided.droppableProps} style={{
                                background: snapshot.isDraggingOver ? "lightblue" : '',
                                border: "2px dashed #ccc",
                                borderRadius: "4px",
                                paddingLeft: 0
                            }} >
                                {parentCategories?.map((parentCategory, index) =>
                                    <>
                                        {parentCategory?.name?.en !== "default" &&
                                            <CustomMenuParentCategoriesDraggableTableItem
                                                organizationID={organizationID}
                                                parentCategory={parentCategory}
                                                index={index}
                                                key={parentCategory.id}
                                                removeParentCategory={removeParentCategory}
                                                updateParentCategoryName={updateParentCategoryName}
                                                handleTranslationsDialogState={handleTranslationsDialogState}
                                                updateParentCategoryIsActive={updateParentCategoryIsActive}
                                                allowParentCategories={allowParentCategories}
                                                // categories
                                                categoryOptions={categoryOptions}
                                                addCategoryToParentCategory={addCategoryToParentCategory}
                                                updateCategoryName={updateCategoryName}
                                                updateCategoryIsActive={updateCategoryIsActive}
                                                updateMenuCategoriesOrder={updateMenuCategoriesOrder}
                                                removeCategory={removeCategory}
                                                // items
                                                itemOptions={itemOptions}
                                                removeItemFromCategory={removeItemFromCategory}
                                                handleDialogState={handleDialogState}
                                                updateItemOrder={updateItemOrder}
                                            />
                                        }
                                    </>
                                )}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </>
    );
}

export default CustomMenuParentCategoriesDraggableTable;