/**
 * Reload page
 */
export function reloadPage() {
    window.location.reload(false);
}


export default class functions {
    static reloadPage() { return reloadPage(); }
}