import { Checkbox, FormControlLabel } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import React from 'react';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * The Custom Checkbox, that display customized {@link Checkbox}.
 * @property {string} name the Checkbox name value
 * @property {string} selected If `true`, the component is checked.
 * @property {string} label the Checkbox text value
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {function} onChange Callback that triggered when value is changed
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCheckbox({ name, selected, label, sx = { paddingBottom: "10px" }, onChange, disabled = false }) {

    return (
        <FormControlLabel control={
            <Checkbox
                name={name}
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
                onChange={onChange}
                sx={sx}
            />}
            label={label}
            disabled={disabled}
        />
    );
}

export default CustomCheckbox;