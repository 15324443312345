import { Grid, ListItem } from "@mui/material";
import CustomActionImage from 'components/Assets/CustomActionImage';
import { Draggable } from "react-beautiful-dnd";

/**
 * A Custom Draggable List Item for a single photo.
 * @property {object} item a specific modifier-list object
 * @property {int} index modifier-list item index
 * @property {*} removeEquipment function that removes the modifier-list instance
 * @property {*} save function that updates a modifier-list details
 * @param {boolean} enableDraggable Has value true when draggable icon is visible.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPhotoDraggableTableItem({ photo, index, handleDialogState, imagePath, setCover, setHidden, deleteImage }) {

    return (
        <Draggable draggableId={`photo-${index}`} index={index} key={`photo-${index}`}>
            {(provided, snapshot) => (
                <>
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                            userSelect: "none",
                            background: snapshot.isDragging ? 'rgb(235,235,235)' : '',
                            padding: "20px",
                            borderRadius: "4px",
                            ...provided.draggableProps.style
                        }}
                    >
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <CustomActionImage
                                    {...photo}
                                    viewImage={handleDialogState}
                                    imagePath={imagePath}
                                    setCover={setCover}
                                    setHidden={setHidden}
                                    deleteImage={deleteImage}
                                    index={index}
                                    dragHandleProps={provided.dragHandleProps}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </>
            )}
        </Draggable>
    );
}

export default CustomPhotoDraggableTableItem;