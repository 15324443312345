import {
    Grid
} from '@mui/material';
import CustomTypography from 'components/Typography/CustomTypography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { orderCartItemQuantityTextStyle, orderItemHeaderTextStyle, orderItemNoteTextStyle, orderTextStyle } from 'themes/defaultThemes';
import { priceTextWithCurrency } from 'utils/functions/order';

/**
 * The CustomSinglePastOrderCard, that display each customer past customer.view.tabs.orders.order.
 * 
 * @param {object} order the past order details
 * @param {fucntion} handlePastOrderAddFeedbackClick Callback that handles redirects past order add feedback page.
 *
 * @returns {JSX.Element} A React component that renders a past customer.view.tabs.orders.order.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSinglePastOrderCardItem({ cartItem, currency }) {
    const { t } = useTranslation();
    const [modifiersPrice, setModifiersPrice] = useState(0);
    const [modifiersText, setModifiersText] = useState("");

    useEffect(() => {
        cartModifiersToText(cartItem.modifiers);
    }, []);

    function cartModifiersToText(modifiers) {
        let text = "";
        let modifiersTotalPrice = 0;
        modifiers.map((modifier) => {
            text += `${modifier.name}${modifiers.length > 1 ? ", " : ""}`;
            modifiersTotalPrice += modifier.price;
        });

        setModifiersPrice(modifiersTotalPrice);
        setModifiersText(text);
    }

    return (
        <>
            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="span" text={cartItem.quantity} sx={orderCartItemQuantityTextStyle()} />
                    </Grid>

                    <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={cartItem.name} sx={orderItemHeaderTextStyle()} />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={modifiersText} sx={orderTextStyle()} />
                            </Grid>

                            {cartItem.notes.length > 0 &&
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={`${t('customer.view.tabs.orders.order.notes')} ${cartItem.notes}`} sx={orderItemNoteTextStyle()} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={priceTextWithCurrency(currency, (cartItem.price * cartItem.quantity) + modifiersPrice)} sx={orderItemNoteTextStyle()} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default CustomSinglePastOrderCardItem;