import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to get the equipments list from the database.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the equipments list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    if (search.organizationID !== null && search.organizationID !== undefined && search.organizationID !== "") {
        data = { organizationID: search.organizationID }
    }
    if (search.outletID !== null && search.outletID !== undefined && search.outletID !== "") {
        data = { ...data, outletID: search.outletID }
    }
    if (search.section !== null && search.section !== undefined && search.section !== "") {
        data = { ...data, section: search.section }
    }
    if (search.floor !== null && search.floor !== undefined && search.floor !== "") {
        data = { ...data, floor: search.floor }
    }
    if (search.label !== null && search.label !== undefined && search.label !== "") {
        data = { ...data, label: search.label }
    }
    if (search.type !== null && search.type !== undefined && search.type !== "") {
        data = { ...data, type: search.type }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}equipment`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}equipment`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to get the equipments list from the database.
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the equipments list
 */
export async function fetchAllList(search) {
    let data = {};
    if (search.organizationID !== null && search.organizationID !== undefined && search.organizationID !== "") {
        data = { organizationID: search.organizationID }
    }
    if (search.outletID !== null && search.outletID !== undefined && search.outletID !== "") {
        data = { ...data, outletID: search.outletID }
    }
    if (search.outletSections?.length > 0) {
        data = { ...data, outletSections: search.outletSections }
    }
    if (search.includedIds?.length > 0) {
        data = { ...data, includedIds: search.includedIds }
    }
    if (search.excludedIds?.length > 0) {
        data = { ...data, outletSections: search.excludedIds }
    }
    return await request.get(includeParamsToUrl(`${API}equipment/list`, data));
}

/**
 * The rest request which attempts to get the equipments list from the database.
 * @param {*} orgID The id of the equipemnt outlet organization to be fetched
 * @param {*} outletID The id of the equipment outlet
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * 
 * @returns an object with an attribute containing the outlets list
 */
export async function fetchAllByOrganizationIDAndOutletID(orgID, outletID, pageable) {
    let config = {
        baseURL: process.env.REACT_APP_AXIOSBASE,
        params: pageable !== undefined ? pageable : {}
    }

    return await request.get(`${API}organization/${orgID}/outlet/${outletID}/equipment`, config);
}

/**
 * The rest request which return required information to be presented on add new equipment page.
 * @param {*} organizationID The id of the equipment organization id
 * @param {*} outletID The id of the equipment outlet id
 * @returns an object with an attribute containing the requested information to be presented on the equipment page
 */
export async function fetchNew(organizationID, outletID) {
    let data = {};
    if (outletID !== null && outletID !== "" && outletID !== undefined) {
        data.outletID = outletID;
    }
    if (organizationID !== null && organizationID !== "" && outletID !== organizationID) {
        data.organizationID = organizationID;
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}equipment/add`, data));
    else return await request.get(includeParamsToUrl(`${API}equipment/add`));
}

/**
 * The rest request which attempts to get an existing equipment information.
 * @param {*} organizationID The id of the equipment organization id
 * @param {*} outletID The id of the equipment outlet id
 * @param {*} id The id of the equipment to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the equipment
 */
export async function fetchOne(organizationID, outletID, id) {
    let data = {};
    if (outletID !== null && outletID !== "" && outletID !== undefined) {
        data.outletID = outletID;
    }
    if (organizationID !== null && organizationID !== "" && outletID !== organizationID) {
        data.organizationID = organizationID;
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}equipment/${id}`, data));
    else return await request.get(includeParamsToUrl(`${API}equipment/${id}`));
}
/**
 * The rest request which attempts to get an existing equipment past orders information.
 * @param {*} id The id of the equipment to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the equipment
 */
export async function findAllPastorders(id) {
    return await request.get(includeParamsToUrl(`${API}equipment/${id}/order`));
}

/**
 * The rest request which attempts to create a new equipment.
 * @param {*} data A JSON object which contains new equipment infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}equipment`, data);
}

/**
 * The rest request which attempts to bulk create equipments.
 * @param {*} data A JSON object which contains new equipments infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function bulkAdd(data) {
    return await request.post(`${API}equipment/bulkadd`, data);
}

/**
 * The rest request which attempts to update an existing equipment.
 * @param {*} data A JSON object which contains the updated infromation of the equipment
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}equipment`, data);
}

/**
 * The rest request which attempts to enable an existing equipment.
 * @param {*} equipmentID The id of the equipment to be enabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function activate(equipmentID) {
    return await request.post(`${API}equipment/${equipmentID}/activate`);
}

/**
 * The rest request which attempts to disable an existing equipment.
 * @param {*} equipmentID The id of the equipment to be disabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function disable(equipmentID) {
    return await request.post(`${API}equipment/${equipmentID}/disable`);
}

/**
 * The rest request to delete a specific equipment.
 * @param {*} id The id of the equipment to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}equipment/${id}`);
}

/**
 * The rest request which attempts to validate an user provided equipment.
 * @param {*} outletID The id of the equipment outlet id
 * @param {*} equipmentLabel The id of the equipment to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the equipment
 */
export async function validateExistance(outletID, equipmentLabel) {
    let data = {};
    if (outletID !== null && outletID !== "" && outletID !== undefined) {
        data.outletID = outletID;
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}equipment/validate/${equipmentLabel}`, data));
    else return await request.get(includeParamsToUrl(`${API}equipment/validate/${equipmentLabel}`));
}
const equipmentApi = {
    fetchAll,
    fetchAllByOrganizationIDAndOutletID,
    fetchAllList,
    findAllPastorders,
    fetchNew,
    fetchOne,
    bulkAdd,
    create,
    update,
    activate,
    disable,
    deleteById,
    validateExistance
}

export default equipmentApi;