import { CopyAll } from '@mui/icons-material';
import { Autocomplete, Checkbox, FormControlLabel, Grid, ListItem, Switch, TextField, Typography } from '@mui/material';
import CodeMirror from "@uiw/react-codemirror";
import JsonViewer from "@uiw/react-json-view";
import Split from "@uiw/react-split";
import itemApi from 'api/item';
import { ArrowBackIcon, CheckBoxIcon, CheckBoxOutlineBlankIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import CustomTypography from 'components/Typography/CustomTypography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exportItemDialogStyle } from 'themes/defaultThemes';
import { transformToFinalFormat } from 'utils/item';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * The ItemExportDialog, that display a customized dialog to select items to export in the desired format.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ItemExportDialog({
    isOpen = false,
    handleClose,
    id = "",
    message,
    handleOpen,
    includeCloseIcon = false,
    counter,
    organizationID = ""
}) {
    const { t } = useTranslation();

    /**
     * @type {object}
     * 
     * @property {object} userAuth the authenticated user infromation
     * @property {array} items the menu items list
     */
    const [data, setData] = useState({
        userAuth: null,
        items: []
    });

    const [selectedItemIds, setSelectedItemIds] = useState([]);
    const [includePhotos, setIncludePhotos] = useState(false);
    const [showExportPreview, setShowExportPreview] = useState(false);
    const [exportedItemsJson, setExportedItemsJson] = useState([]);


    useEffect(() => {
        if (showExportPreview) getExportedItems();
        else getItems();
    }, [showExportPreview]);

    /**
     * The rest endpoint to get the items list.
     */
    function getItems() {
        itemApi.fetchListDialog(organizationID, false).then((r) => {
            setData({
                ...data,
                items: r.data.returnobject,
            });
        }).catch((e) => {
            // console.log(e);
        })
    }

    function getExportedItems() {
        itemApi.exportJson(organizationID, selectedItemIds?.map((item) => item.id)).then((r) => {
            if (r.data.code === "SUCCESS")
                setExportedItemsJson(r.data.returnobject);
        }).catch((e) => {
            // console.log(e);
        })
    }


    function copyToClipBoard() {
        const exportedItemsStr = JSON.stringify(transformToFinalFormat(exportedItemsJson, includePhotos, organizationID));
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = exportedItemsStr;
        document.body.appendChild(textarea);

        // Select the text inside the textarea
        textarea.select();
        textarea.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text to the clipboard
        document.execCommand('copy');

        // Remove the temporary textarea
        document.body.removeChild(textarea);

        // Optionally, provide feedback to the user
        alert('Text copied to clipboard!');
    }

    function downloadJsonFile() {
        const jsonString = JSON.stringify(transformToFinalFormat(exportedItemsJson, includePhotos, organizationID), null, 10);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.download = 'items.json'; // Name of the downloaded file
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }

    // =========================================== RENDER

    return (
        <CustomDialogBasedOnScreenChildren
            isOpen={isOpen}
            aria-describedby="dialog"
            sx={exportItemDialogStyle()}
            cancelLabel={t('actions.cancel')}
            actionLabel={showExportPreview ? t('actions.export') : t('button.next')}
            title={t('itemOrModifier.export.title')}
            children={
                <>
                    {!showExportPreview &&
                        <>
                            <Autocomplete
                                key="item"
                                id="items-select"
                                disablePortal={true}
                                options={data.items}
                                autoHighlight
                                disableCloseOnSelect
                                multiple={true}
                                open={true}
                                getOptionLabel={(option) => option.name}
                                noOptionsText={t('modifierList.emptyItemsSearch')}
                                popupIcon={<></>}
                                onChange={(event, newValue) => {
                                    setSelectedItemIds(newValue);
                                }}
                                ListboxProps={{
                                    style: {
                                        height: '100%', border: "none", width: "100%",
                                    }
                                }}
                                renderOption={(props, option, { selected }) => {
                                    return <ListItem id={option.id} key={option.id} {...props} style={{ borderBottom: (data.items[data.items.length - 1].id !== option.id) ? "1px solid darkgrey" : "none", paddingTop: "20px" }}>
                                        <div key={option.id + "-1"} style={{ display: "contents" }}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selected}
                                            />
                                        </div>
                                        <div key={option.id + "-2"} style={{ display: "block", width: "90%" }}>
                                            <Typography variant="inherit" sx={{ fontWeight: 600, fontSize: "15px" }}>{option.name}</Typography>
                                        </div>
                                    </ListItem>
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`${t('actions.searchFor')} ${t('modifierList.items')}`}
                                        variant='filled'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </>
                    }
                    {showExportPreview &&
                        <>
                            <Grid container gap={2}>
                                <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                                    <CustomIconButton
                                        icon={<ArrowBackIcon />}
                                        onClick={() => setShowExportPreview(false)}
                                        label={t('actions.back')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                defaultChecked={includePhotos}
                                                onChange={() => setIncludePhotos(!includePhotos)}
                                            />
                                        }
                                        sx={{ marginLeft: "20px" }}
                                        label={t('itemOrModifier.export.includePhotos')}
                                    />
                                </Grid>

                                <Grid item xs={5} sx={{ textAlign: "left", borderRight: "1px solid lightgrey" }}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                                            <CustomTypography text={t('itemOrModifier.export.editor')} variant="span" sx={{ fontWeight: "bold" }} />

                                            <CustomIconButton
                                                icon={<CopyAll />}
                                                onClick={() => copyToClipBoard()}
                                                tooltipTitle={t('itemOrModifier.export.copy')}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Split direction="vertical" gutter>
                                                <CodeMirror
                                                    value={JSON.stringify(exportedItemsJson, null, 2)}
                                                    options={{
                                                        mode: 'javascript',
                                                        theme: 'default',
                                                        lineNumbers: true,
                                                    }}
                                                />
                                            </Split>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={5} sx={{ textAlign: "left" }}>
                                    <CustomTypography text={t('itemOrModifier.export.viewer')} variant="body2" sx={{ fontWeight: "bold", textAlign: "center" }} />
                                    <Split direction="vertical" gutter>
                                        <JsonViewer
                                            value={exportedItemsJson}
                                            name="JSON Data"
                                            displayDataTypes={false}
                                            displayObjectSize={false}
                                            enableClipboard={false}
                                        />
                                    </Split>
                                </Grid>
                            </Grid>
                        </>
                    }
                </>
            }
            handleClose={handleClose}
            id={id}
            message={message}
            counter={counter}
            includeCloseIcon={includeCloseIcon}
            handleOpen={handleOpen}
            handleAction={() => showExportPreview ? downloadJsonFile() : setShowExportPreview(true)}
        />
    );
}

export default ItemExportDialog;