import UploadFile from "@mui/icons-material/UploadFile";
import { Box, Fab, Grid, TableCell, TableRow, Tooltip } from "@mui/material";
import itemApi from "api/item";
import { DownloadIcon } from "assets/mui/MuiIcons";
import { AddIcon } from "assets/svg/SvgIcons";
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomIconButton from "components/Button/CustomIconButton";
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from 'components/Dialogs/CustomDialog';
import ItemExportDialog from "components/Dialogs/iitem/export/ItemExportDialog";
import ItemImportDialog from "components/Dialogs/iitem/import/ItemImportDialog";
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from "components/Select/CustomObjectSelect";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomExpandablePageableTable from 'components/Table/CustomExpandablePageableTable';
import CustomTable from 'components/Table/CustomTable';
import CustomTitle from "components/Title/CustomTitle";
import { getDateTimeFormatter } from 'constants/calendar';
import { getDefaultSortDirectionData } from 'constants/sortDirection';
import { CSVImporter } from "csv-import-react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagTitle } from 'themes/defaultThemes';
import { hasRoleAdmin, hasRoleAdminMenu, hasRoleAdminOrDirectorOrManager } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { reloadPage } from 'utils/page';
import { actionFormatter, dateFormatter, fieldFormatter, iconFormatter, idFormatterLinkWithParent, itemNameFormatter } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The Item List component to view the items of the system.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ItemListComponent() {
   const { t } = useTranslation();
   const { organizationID } = useParams();
   const navigate = useNavigate();

   let path = `/${getLanguageFromURL()}`
   if (organizationID !== undefined) path += `/organization/${organizationID}`;
   // if (outletID !== undefined) path += `/outlet/${outletID}`;
   path += `/item-or-modifier`;

   /**
    * @type {object}
    * 
    * @property {object} userAuth the authenticated user infromation
    * @property {object} organization holds the organization information
    * @property {array} items the menu items list
    * @property {array} providerOptions the different provider options
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
    */
   const [data, setData] = useState({
      userAuth: null,
      items: [],
      item: null,
      organization: {
         id: organizationID,
         name: ""
      },
      organizations: [],
      providerOptions: [],
      pagination: {
         count: -1,
         size: 10,
         page: 0,
         ...getDefaultSortDirectionData
      }
   });

   //change document title
   useDocumentTitle(`Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t('org.pageTitle') : ""} | ${t('itemOrModifier.title')}`)

   // to perform initial request
   const [isFirstTime, setIsFirstTime] = useState(true);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);
   // if true the menu item is clicked and a redirect to page should perform
   const [redirect, setRedirect] = useState(false);
   // for search
   const [search, setSearch] = useState({
      organizationID: organizationID,
      name: {
         en: ""
      },
      isModifier: "All",
      provider: "",
      itemVatType: ""
   });
   // pop dialog for delete
   const [dialog, setDialog] = useState({
      isOpen: false,
      itemID: "",
      name: "",
   });
   const [exportDialogOpen, setExportDialogOpen] = useState(false);
   const [importDialogOpen, setImportDialogOpen] = useState(false);
   const [importCsvDialogOpen, setImportCsvDialogOpen] = useState(false);

   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });


   useEffect(() => {
      getItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      getItems();
      setIsFirstTime(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const identifier = setTimeout(() => {
         if (!isFirstTime) getItems();
      }, 700);

      return () => {
         clearTimeout(identifier);
      }
   }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])


   /**
    * The rest endpoint to get the items list.
    */
   function getItems() {
      let sear = search;
      if (hasRoleAdminMenu(data.userAuth?.roles)) {
         sear.organizationID = localStorage.getItem("oid")
      }
      itemApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
         Promise.resolve()
            .then(() => {
               setData({
                  ...data,
                  items: r.data.returnobject?.page,
                  userAuth: r.data.returnobject.userAuth,
                  providerOptions: r.data.returnobject.providerOptions,
                  organization: r.data.returnobject.organization,
                  organizations: r.data.returnobject.organizations,
                  pagination: {
                     ...data.pagination,
                     count: r.data.returnobject?.page?.totalPages,
                     size: r.data.returnobject?.page?.size,
                     page: r.data.returnobject?.page?.number,
                  }
               });
            })
            .then(() => {
               if (r.data.returnobject?.userAuth && !hasRoleAdminMenu(r.data.returnobject?.userAuth?.roles)) {
                  let organizationID = r.data.returnobject.organization !== null ? r.data.returnobject.organization.id : null;
                  if ((search.organizationID !== null && hasRoleAdmin(r.data.returnobject.userAuth.roles)) || !hasRoleAdmin(r.data.returnobject.userAuth.roles))
                     if (organizationID !== search.organizationID)
                        setSearch({ ...search, organizationID: organizationID });
               }
               // if ((search.organizationID !== null && hasRoleAdmin(r.data.returnobject.userAuth.roles)) || !hasRoleAdmin(r.data.returnobject.userAuth.roles))
               //     if (organizationID !== search.organizationID || (outletID !== search.outletID))
               //         setSearch({ ...search, organizationID: organizationID, outletID: outletID !== null ? outletID : (r.data.returnobject.outlets.size === 1) ? r.data.returnobject.outlets.outlets[0].id : null });
            })
            .then(() => {
               setIsLoaded(true);
            })
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected item
    * @param {*} event
    * @param {string} id the id of the item
    */
   function enableItem(event, id) {
      itemApi.enabled(id).then((r) => {
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getItems();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected item
    * @param {*} event
    * @param {string} id the id of the item
    */
   function disableItem(event, id) {
      itemApi.disable(id).then((r) => {
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getItems();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Function that handles the delete modal open or close data.
    * @property {boolean} isOpen The state of the dialog open status.
    * @property {string} itemID The id of the selected item to be removed.
    */
   function handleDialogState(isOpen, itemID = "", name = "") {
      setDialog({
         ...dialog,
         isOpen: isOpen,
         itemID: itemID,
         name: name
      });
   }

   /**
    * 
    * Function that triggers the removal of the selected equipmentID.
    * @property {*} event
    * @property {string} equipmentID The id of the selected equipmentID to be removed.
    */
   function deleteItem(event, itemID, name = "") {
      handleDialogState(true, itemID, name);
   }

   /**
    * Gets called to remove the selected item
    */
   function deleteI() {
      itemApi.deleteById(dialog.itemID).then((r) => {
         handleDialogState(false);
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getItems();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Function that handles the snackbar open or close data.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }

   /**
    * Function that handles the pagination information.
    * @property {int} page the page to be redirected
    */
   function handlePaginationChange(page) {
      formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
   }

   /**
    * Function that handles the pagination information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleRowsPerPageChange(size) {
      let pagination = data.pagination;
      pagination.size = size;
      pagination.page = 0;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }

   /**
    * Function that handles the sort information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleColumnSortChange(changedColumn, direction) {
      let pagination = data.pagination;
      pagination.sort = changedColumn;
      pagination.direction = direction;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }


   function onFilterChange(event) {
      if (event !== null) {
         let field = event.field;
         let value = event.value;
         if (event.field === "isModifier") {
            if (value === t("itemOrModifier.item")) {
               value = false;
            } else if (value === t("itemOrModifier.modifier")) {
               value = true;
            }
         } else if (event.field === "itemVatType") {
            if (value === t("itemVatTypes.SIX_PERCENT")) {
               value = "SIX_PERCENT";
            } else if (value === t("itemVatTypes.THIRTEEN_PERCENT")) {
               value = "THIRTEEN_PERCENT";
            } else if (value === t("itemVatTypes.TWENTY_FOUR_PERCENT")) {
               value = "TWENTY_FOUR_PERCENT";
            }
         }

         formValChangeWithParentElementWithNameAndValue(field, value, search, setSearch)
      } else {
         setSearch({
            ...search,
            name: {
               en: ""
            },
            isModifier: "All",
            provider: "",
            itemVatType: ""
         })
      }
   }


   function importItems(importedData) {
      let d = importedData.rows.map((row) => row.values);
      d = d.map(item => ({
         ...item,
         itemVatType: item.itemVatType || "NONE" // This will set itemVatType to "NONE" if it's empty or null
      }));
      setImportCsvDialogOpen(false);
      itemApi.importCsv(data.organization.id, d).then((r) => {
         if (r.data.code === "SUCCESS") {
            setImportCsvDialogOpen(false);
            getItems();
         } else {
            setAlertBox({
               isOpen: true,
               message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
               backgroundColor: "#a71313"
            });
         }
      }).catch((e) => {
         // console.log(e);
      })
   }

   // ===============================================================================RENDER

   if (redirect) {
      reloadPage();
   } else if (!isLoaded) {
      return <CustomGrid role="main" id="items-list" sx={mainTag()}>
         <LoadingSkeleton lines={9} />
      </CustomGrid>
   } else {
      /**
       * datatable columns.
       */
      const table_columns = [
         {
            name: "enabled",
            label: t('itemOrModifier.status'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return iconFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     {
                        enable: (event, rowIndex) => enableItem(event, rowIndex),
                        disable: (event, rowIndex) => disableItem(event, rowIndex),
                        positionOfId: 1
                     });
               }
            }
         },
         {
            name: "id",
            label: t('label.id'),
            options: {
               viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
               filter: false,
               sort: false,
               empty: false,
               display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
               customBodyRender: (value, tableMeta) => {
                  return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
               }
            }
         },
         {
            name: "name",
            label: t('label.name'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('label.name')}: ${v}`,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  let row = tableMeta.tableData[tableMeta.rowIndex];
                  let positionOfId = 1;
                  return itemNameFormatter(value?.en, row, 3, `${path}/${row[positionOfId]}`);
               }
            }
         },
         {
            name: "description",
            options: {
               viewColumns: false,
               filter: false,
               display: false
            }
         },
         {
            name: "isModifier",
            label: t('label.type'),
            options: {
               filter: true,
               filterType: 'select',
               customFilterListRender: v => `${t('label.type')}: ${v}`,
               filterOptions: {
                  names: [t("itemOrModifier.item"), t("itemOrModifier.modifier")]
               },
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return fieldFormatter(value ? t("itemOrModifier.modifier") : t("itemOrModifier.item"));
               }
            }
         },
         {
            name: "itemVatType",
            label: t('itemVatTypes.labelSingle'),
            options: {
               filter: true,
               filterType: 'select',
               customFilterListRender: v => `${t('itemVatTypes.labelSingle')}: ${v}`,
               filterOptions: {
                  names: [t("itemVatTypes.SIX_PERCENT"), t("itemVatTypes.THIRTEEN_PERCENT"), t("itemVatTypes.TWENTY_FOUR_PERCENT")]
               },
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return value ? fieldFormatter(t(`itemVatTypes.${value}`)) : "---";
               }
            }
         },
         // {
         //     name: "provider",
         //     label: t('itemOrModifier.provider'),
         //     options: {
         //         filter: false,
         //         sort: true,
         //         empty: false
         //     }
         // },
         {
            name: "lastActivity",
            label: t('label.lastActivity'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateTimeFormatter());
               }
            }
         },
         {
            name: "",
            label: t('actions.label'),
            options: {
               filter: false,
               sort: false,
               empty: true,
               customBodyRender: (value, tableMeta) => {
                  return actionFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     1,
                     null,
                     null,
                     null,
                     2,
                     path,
                     data.showLabel ? t('actions.edit') : undefined,
                     (event, rowIndex, name) => deleteItem(event, rowIndex, name),
                     data.showLabel ? t('actions.delete') : undefined,
                     true
                  )
               },
               setCellProps: () => ({ className: "click action" })
            }
         },
         {
            name: "prices",
            label: "",
            options: {
               viewColumns: false,
               display: false,
               filter: false,
               sort: false,
               empty: true,
            }
         },
      ];


      const table_inner_columns = [
         {
            name: "price",
            label: t('label.price'),
            options: {
               filter: false,
               sort: false
            }
         },
         {
            name: "discountPercent",
            label: t('itemOrModifier.discountPercent'),
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta) => {
                  return `${value}%`;
               }
            }
         },
         {
            name: "priceListType",
            label: t('itemOrModifier.priceType'),
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta) => {
                  if (value === "STANDARD")
                     return t("itemOrModifier.STANDARD")
                  return <span>{value}</span>;
               }
            }
         }
      ];

      // let defaultTypeSelected = search.isModifier !== null ? search.isModifier : null;
      // let defaultProviderSelected = data.item !== null ? data.item.provider : "";

      return (
         <CustomGrid role="main" id="items-list" sx={mainTag()}>
            {(organizationID !== undefined && !hasRoleAdminMenu(data.userAuth.roles)) &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomBreadcrumbMultiParent
                     parents={[
                        { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                        { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` }
                     ]}
                     instanceName={t('itemOrModifier.title')}
                  />
               </CustomGrid>
            }

            <Grid container spacing={2} sx={{ alignItems: "center" }}>
               <Grid item xs={"auto"}>
                  <CustomRefreshButton onClick={getItems} marginTop="0" />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <CustomGrid sx={mainTagTitle()}>
                     <CustomTitle
                        title={t('itemOrModifier.title')}
                        subtitle={""}
                     />
                  </CustomGrid>
               </Grid>

               <Grid item xs={12} sm={8.2} sx={{ merginLeft: 0 }}>
                  <Grid container sx={mainTagSearch()} spacing={2}>

                     <Box sx={{ flexGrow: 1 }} /> {/* Spacing to push the following items to the right */}

                     {/* {data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) &&
                                 <>
                                    <Grid item>
                                       <CustomObjectSelect
                                             name="organizationID"
                                             required={true}
                                             defaultValue={search.organizationID}
                                             labelID="organizationID" label={t('org.pageTitle')}
                                             options={data.organizations}
                                             onChange={(event) => setSearch({ ...search, organizationID: event.target.value })}
                                             sx={mainTagSearchObjectSelect()}
                                             field="name"
                                       />
                                    </Grid>
                                    <Grid item>
                                       <CustomSelect
                                             name="provider"
                                             required={true}
                                             defaultValue={defaultProviderSelected}
                                             labelID="provider"
                                             label={t('itemOrModifier.provider')}
                                             options={["", ...data.providerOptions]}
                                             onChange={(event) => onSelectChange(event, search, setSearch)}
                                             sx={{ marginRight: "15px", maxHeight: "50px", minWidth: "150px", width: "170px" }}
                                       />
                                    </Grid>
                                 </>
                           } */}

                     {/* <Grid item>
                              <CustomSelectWithLabels
                                 name="isModifier"
                                 value={search.isModifier}
                                 labelID="isModifier"
                                 label={t('label.type')}
                                 options={[
                                    { "label": t("label.all"), "value": "All" },
                                    { "label": t("itemOrModifier.item"), "value": false },
                                    { "label": t("itemOrModifier.modifier"), "value": true }
                                 ]}
                                 onChange={(event) => onSelectChange(event, search, setSearch)}
                                 sx={mainTagSearchObjectSelect()}
                              />
                           </Grid>

                           <Grid item>
                              <CustomTextField
                                 type="search"
                                 name="name.en"
                                 id="name"
                                 variant="filled"
                                 label={t('actions.search') + " by " + t('label.name')}
                                 defaultValue={search.name !== null ? search.name.en : ""}
                                 InputProps={{
                                    endAdornment: (
                                       <InputAdornment position="end" >
                                          <CustomSearchButton onClick={getItems} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 required={false}
                                 // sx={mainTagSearchItem()} 
                                 onChange={(event) => formValChangeWithParentElementSelect(event, search, setSearch, t('table.valueReuired'))}
                              />

                           </Grid>

                     {/* {data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) && */}
                     {hasRoleAdminMenu(data.userAuth?.roles) &&
                        <Grid item xs={"auto"}>
                           <label htmlFor="organizationID">{t('org.pageTitle')}</label>
                           <CustomObjectSelect
                              name="organizationID"
                              required={true}
                              value={localStorage.getItem("oid")}
                              // labelID="organizationID" label={t('org.pageTitle')}
                              options={data.organizations}
                              onChange={(event) => { localStorage.setItem("oid", event.target?.value); setSearch({ ...search, organizationID: event.target?.value }) }}
                              field={"name"}
                              sx={{ borderRadius: "30px" }}
                           />
                        </Grid>
                     }


                     <Grid item>
                        <CustomIconButton
                           onClick={() => setImportDialogOpen(true)}
                           icon={<UploadFile style={{ marginRight: "0px" }} />}
                           className="icon-wrapper tr link"
                           iconSx={{ marginRight: "0px!important" }}
                           tooltipTitle={`${t('actions.import')} ${t('label.json')}`}
                           sx={{ backgroundColor: "#54b1d2", borderRadius: "50px", color: "white", padding: "8px 0px", minWidth: "40px", marginRight: "10px", marginTop: "1.5%" }}
                        />
                     </Grid>

                     <Grid item>
                        <CustomIconButton
                           onClick={() => setImportCsvDialogOpen(true)}
                           icon={<UploadFile style={{ marginRight: "0px" }} />}
                           className="icon-wrapper tr link"
                           iconSx={{ marginRight: "0px!important" }}
                           tooltipTitle={`${t('actions.import')} ${t('label.csv')}`}
                           sx={{ backgroundColor: "#54b1d2", borderRadius: "50px", color: "white", padding: "8px 0px", minWidth: "40px", marginRight: "10px", marginTop: "1.5%" }}
                        />
                     </Grid>

                     <Grid item>
                        <CustomIconButton
                           onClick={() => setExportDialogOpen(true)}
                           icon={<DownloadIcon style={{ marginRight: "0px" }} />}
                           className="icon-wrapper tr link"
                           iconSx={{ marginRight: "0px!important" }}
                           tooltipTitle={t('actions.export')}
                           sx={{ backgroundColor: "#54b1d2", borderRadius: "50px", color: "white", padding: "8px 0px", minWidth: "40px", marginRight: "10px", marginTop: "1.5%" }}
                        />
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>

            <CustomGrid sx={mainAreaTable()} container={false}>
               <CustomExpandablePageableTable
                  instances={data.items}
                  emptyMessage={t('itemOrModifier.emptyMessage')}
                  table_columns={table_columns}
                  keyField="id"
                  defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                  pagination={data.pagination}
                  handlePaginationChange={handlePaginationChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handleColumnSortChange={handleColumnSortChange}
                  filterType="dropdown"
                  filter={true}
                  onFilterChange={onFilterChange}
                  extraOptions={{
                     renderExpandableRow: (rowData, rowMeta) => {
                        return (
                           <>
                              {(rowData[8] !== undefined && rowData[8].length !== 0) &&
                                 <TableRow>
                                    <TableCell colSpan={rowData.length + 1}>
                                       <CustomTable
                                          instances={rowData[8]}
                                          emptyMessage={t('modifierList.empty')}
                                          table_columns={table_inner_columns}
                                          keyField="id"
                                          hasCleanStyle={true}
                                       />
                                    </TableCell>
                                 </TableRow>
                              }
                           </>
                        );
                     }
                  }}
               />

               {((search.organizationID && hasRoleAdminMenu(data.userAuth.roles)) || hasRoleAdminOrDirectorOrManager(data.userAuth.roles)) &&
                  <Fab color="primary"
                     aria-label="add"
                     sx={fabStyle()}
                     onClick={() => navigate(`${path}/add`)}
                  >
                     <Tooltip arrow title={t('actions.add') + " " + t('itemOrModifier.title')} sx={fabTooltipStyle()}>
                        <AddIcon {...fabStyleIcon()} />
                     </Tooltip>
                  </Fab>
               }
            </CustomGrid>

            {dialog.isOpen &&
               <CustomDialog
                  isOpen={dialog.isOpen}
                  id="delete"
                  title={t('actions.deleteModal.title')}
                  actionLabel={t("actions.deleteModal.message")}
                  cancelLabel={t("actions.cancel")}
                  message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('itemOrModifier.titleDelete')}: `}
                  boldMessage={<span style={{ fontWeight: "bold" }}>{dialog.name}</span>}
                  action={deleteI}
                  handleOpen={handleDialogState}
                  actionColor="#e91e1e"
               />
            }

            {exportDialogOpen &&
               <ItemExportDialog
                  isOpen={exportDialogOpen}
                  handleClose={() => setExportDialogOpen(false)}
                  handleOpen={() => setExportDialogOpen(true)}
                  handleAction={() => setExportDialogOpen(false)}
                  anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'right',
                  }}
                  organizationID={data.organization.id}
               />
            }

            {importDialogOpen &&
               <ItemImportDialog
                  isOpen={importDialogOpen}
                  handleClose={() => setImportDialogOpen(false)}
                  handleOpen={() => setImportDialogOpen(true)}
                  handleAction={() => setImportDialogOpen(false)}
                  anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'right',
                  }}
                  organizationID={data.organization.id}
                  getItems={getItems}
               />
            }

            <CSVImporter
               modalIsOpen={importCsvDialogOpen}
               modalOnCloseTriggered={() => setImportCsvDialogOpen(false)}
               darkMode={false}
               onComplete={(data) => importItems(data)}
               customStyles={{
                  "color-primary": "#47a6d2",
                  "color-secondary": "#47a6d2",
               }}
               template={{
                  columns: [
                     {
                        name: "nameEN",
                        key: "nameEN",
                        required: true,
                        data_type: "text",
                        description: "The English name of the item",
                     },
                     {
                        name: "nameEL",
                        key: "nameEL",
                        required: false,
                        data_type: "text",
                        description: "The Greek name of the item",
                     },
                     {
                        name: "descriptionEN",
                        key: "descriptionEN",
                        required: false,
                        data_type: "text",
                        description: "The English description of the item",
                     },
                     {
                        name: "descriptionEL",
                        key: "descriptionEL",
                        required: false,
                        description: "The Greek description of the item",
                     },
                     {
                        name: "price",
                        key: "price",
                        data_type: "number",
                        required: true,
                     },
                     {
                        name: "itemVatType",
                        key: "itemVatType",
                        data_type: "text",
                        required: false,
                     },
                  ],
               }}
            />

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }
         </CustomGrid>
      );
   }
}

export default ItemListComponent;