import React from 'react';
import { ReactComponent as Waves } from 'assets/svg/divider/shape/top/waves.svg';
import { ReactComponent as WavesOpacity } from 'assets/svg/divider/shape/top/wavesOpacity.svg';
import { ReactComponent as Curve } from 'assets/svg/divider/shape/top/curve.svg';
import { ReactComponent as CurveAsymmetrical } from 'assets/svg/divider/shape/top/curveAsymmetrical.svg';
import { ReactComponent as Triangle } from 'assets/svg/divider/shape/top/triangle.svg';
import { ReactComponent as TriangleAsymmetrical } from 'assets/svg/divider/shape/top/triangleAsymmetrical.svg';
import { ReactComponent as Tilt } from 'assets/svg/divider/shape/top/tilt.svg';
import { ReactComponent as TiltLeft } from 'assets/svg/divider/shape/top/tiltLeft.svg';
import { ReactComponent as Arrow } from 'assets/svg/divider/shape/top/arrow.svg';
import { ReactComponent as Split } from 'assets/svg/divider/shape/top/split.svg';
import { ReactComponent as Book } from 'assets/svg/divider/shape/top/book.svg';

import { ReactComponent as WavesInvert } from 'assets/svg/divider/shape/top/invert/waves.svg';
import { ReactComponent as WavesOpacityInvert } from 'assets/svg/divider/shape/top/invert/wavesOpacity.svg';
import { ReactComponent as CurveInvert } from 'assets/svg/divider/shape/top/invert/curve.svg';
import { ReactComponent as CurveAsymmetricalInvert } from 'assets/svg/divider/shape/top/invert/curveAsymmetrical.svg';
import { ReactComponent as TriangleInvert } from 'assets/svg/divider/shape/top/invert/triangle.svg';
import { ReactComponent as TriangleAsymmetricalInvert } from 'assets/svg/divider/shape/top/invert/triangleAsymmetrical.svg';
import { ReactComponent as ArrowInvert } from 'assets/svg/divider/shape/top/invert/arrow.svg';
import { ReactComponent as SplitInvert } from 'assets/svg/divider/shape/top/invert/split.svg';
import { ReactComponent as BookInvert } from 'assets/svg/divider/shape/top/invert/book.svg';

const TopShapeDividerSVGComponent = ({ type, style, isInverted = false }) => {
    let TopShapeDividerSVGComponent;

    if (!isInverted) {
        switch (type) {
            case 'waves':
                TopShapeDividerSVGComponent = Waves;
                break;
            case 'wavesOpacity':
                TopShapeDividerSVGComponent = WavesOpacity;
                break;
            case 'curve':
                TopShapeDividerSVGComponent = Curve;
                break;
            case 'curveAsymmetrical':
                TopShapeDividerSVGComponent = CurveAsymmetrical;
                break;
            case 'triangle':
                TopShapeDividerSVGComponent = Triangle;
                break;
            case 'triangleAsymmetrical':
                TopShapeDividerSVGComponent = TriangleAsymmetrical;
                break;
            case 'tilt':
                TopShapeDividerSVGComponent = Tilt;
                break;
            case 'tiltLeft':
                TopShapeDividerSVGComponent = TiltLeft;
                break;
            case 'arrow':
                TopShapeDividerSVGComponent = Arrow;
                break;
            case 'split':
                TopShapeDividerSVGComponent = Split;
                break;
            case 'book':
                TopShapeDividerSVGComponent = Book;
                break;
            default:
                TopShapeDividerSVGComponent = null;
                break;
        }
    } else {
        switch (type) {
            case 'waves':
                TopShapeDividerSVGComponent = WavesInvert;
                break;
            case 'wavesOpacity':
                TopShapeDividerSVGComponent = WavesOpacityInvert;
                break;
            case 'curve':
                TopShapeDividerSVGComponent = CurveInvert;
                break;
            case 'curveAsymmetrical':
                TopShapeDividerSVGComponent = CurveAsymmetricalInvert;
                break;
            case 'triangle':
                TopShapeDividerSVGComponent = TriangleInvert;
                break;
            case 'triangleAsymmetrical':
                TopShapeDividerSVGComponent = TriangleAsymmetricalInvert;
                break;
            case 'arrow':
                TopShapeDividerSVGComponent = ArrowInvert;
                break;
            case 'split':
                TopShapeDividerSVGComponent = SplitInvert;
                break;
            case 'book':
                TopShapeDividerSVGComponent = BookInvert;
                break;
            default:
                TopShapeDividerSVGComponent = null;
                break;
        }

    }

    return TopShapeDividerSVGComponent ? <TopShapeDividerSVGComponent style={style} /> : null;
};

export default TopShapeDividerSVGComponent;
