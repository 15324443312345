import { Card, Fab, Grid, InputLabel, Stack } from '@mui/material';
import orderApi from 'api/order';
import { NoOrders } from 'assets/lottie/LottieAnimations';
import { AddIcon, ArrowBackIcon } from 'assets/mui/MuiIcons';
import "assets/scss/swipeable-list.scss";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomTypography from 'components/Typography/CustomTypography';
import { lottieOrderOptions } from 'constants/animations/lottieOptions';
import { MORE_ORDER_ADD_SIZE } from 'constants/fieldProperties';
import { getDefaultSortDirectionData } from 'constants/sortDirection';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fabStyle, orderBackBtnStyle, orderEmptyInfoTextStyle, pastOrderMoreOrdersButtonStyle, pastOrderViewHeaderTextStyle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { dateTimeFormatterFilter } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';
import CustomPDAPastOrderCardSimplified from './CustomPDAPastOrderCardSimplified';
import CustomPDAViewOrderGrid from './CustomPDAViewOrderGrid';

/**
 * The CustomPDAViewOrders, that display a custom {@link Card} for PDA view orders page
 * 
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPDAViewOrders({
    organizationID, outletID, equipmentID,
    handleReset, handleSelectedOrderToPay, handleCreateOrder,
    isViewOrdersPage,
    setAlertBox
}) {
    const { t } = useTranslation();
    const [isViewOrderPage, setIsViewOrderPage] = useState(false);
    const [selectedOrderID, setSelectedOrderID] = useState(null);

    const [orderData, setOrderData] = useState({
        userAuth: null,
        orders: [],
        userFullName: "",
        pagination: {
            size: 10,
            page: 0,
            ...getDefaultSortDirectionData
        }
    });

    // for search
    const [orderSearch, setOrderSearch] = useState({
        // outletIDs: [outletID],
        organizationID: organizationID,
        equipmentID: equipmentID,
        dateFrom: dateTimeFormatterFilter(),
        lang: getLanguageFromURL(),
        dateTo: null
    });

    useEffect(() => {
        if (isViewOrdersPage)
            getOrders();


        const fetchDataInterval = setInterval(() => {
            getOrders(); // Fetch data
        }, 5000); // 5 seconds interval

        // Clear interval on component unmount to avoid memory leaks
        return () => clearInterval(fetchDataInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isViewOrdersPage]);

    useEffect(() => {
        if (isViewOrdersPage)
            getOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderData.pagination.size]);

    /**
     * The rest endpoint to get the outlet orders.
     */
    function getOrders() {
        let search = {
            ...orderSearch,
            lang: getLanguageFromURL(),
            dateFrom: dateTimeFormatterFilter(),
            dateTo: null
        }
        orderApi.fetchAll(orderData.pagination, search).then((r) => {
            if (r.data.code === "SUCCESS") {
                setOrderData({
                    ...orderData,
                    userAuth: r.data.returnobject.userAuth,
                    orders: r.data.returnobject.orders,
                    pagination: {
                        ...orderData.pagination,
                        size: r.data.returnobject.orders.size
                    }
                });

                if (r.data.returnobject.orders.totalPages <= r.data.returnobject.orders.number + 1) {
                    setEnableOrdersMoreButton(false)
                }
            }
        }).catch((e) => {
            // console.log(e);
        });
    }

    function handlSelectedOrderToView(orderID) {
        setSelectedOrderID(orderID);
        setIsViewOrderPage(true)
    }
    function handlBackFromSelectedOrderToView() {
        setSelectedOrderID(null);
        setIsViewOrderPage(false);
    }

    const [enableOrdersMoreButton, setEnableOrdersMoreButton] = useState(true);

    return (
        <>
            {!isViewOrderPage &&
                <>
                    <Grid item xs={3} md={3}>
                        <CustomIconButton
                            icon={<ArrowBackIcon />}
                            sx={orderBackBtnStyle()}
                            onClick={() => handleReset()}
                            label={t('pda.backButtonEqDialog')}
                        />
                    </Grid>

                    <Grid item xs={8} md={8}>
                        <CustomTypography variant="body2" text={t('pda.order.view')} sx={pastOrderViewHeaderTextStyle()} />
                    </Grid>

                    {orderData.orders?.content?.length > 0 &&
                        <Stack direction="row" sx={{ padding: "10px", alignItems: "center", display: "contents" }}>
                            <Grid container gap={1} sx={{ display: "contents" }}>
                                {orderData.orders?.content?.map((order, index) =>
                                    <CustomPDAPastOrderCardSimplified
                                        order={order}
                                        showDivider={index + 1 !== orderData.orders?.content?.length}
                                        handlePastOrderClick={() => handlSelectedOrderToView(order.id)}
                                        handlePastOrderPayClick={() => handleSelectedOrderToPay(order)}//navigate(`/${getLanguageFromURL()}/pay/order/${order.id}`)}
                                    />

                                )}

                                {enableOrdersMoreButton &&
                                    <Grid item xs={12} md={12}>
                                        <CustomIconButton
                                            sx={pastOrderMoreOrdersButtonStyle()}
                                            onClick={() => formValChangeWithParentElementWithNameAndValue("pagination.size", orderData.pagination.size + MORE_ORDER_ADD_SIZE, orderData, setOrderData)}
                                            variant="outlined"
                                            label={t('pga.moreOrders')}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Stack>
                    }


                    {(orderData.orders === null || orderData.orders?.content?.length === 0) &&
                        <Grid item xs={12} md={12}>
                            <div style={{ textAlign: "center" }}>
                                <Lottie
                                    animationData={NoOrders}
                                    {...lottieOrderOptions}
                                />

                                <InputLabel sx={orderEmptyInfoTextStyle()}>{t('orders.empty')}</InputLabel>
                            </div>
                        </Grid>
                    }

                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={{ ...fabStyle(), position: "absolute" }}
                        onClick={() => handleCreateOrder()}
                    >
                        <AddIcon />
                    </Fab>
                </>
            }

            {isViewOrderPage &&
                <CustomPDAViewOrderGrid
                    orderID={selectedOrderID}
                    setAlertBox={setAlertBox}
                    handleReset={() => handlBackFromSelectedOrderToView()}
                />
            }
        </>
    );
}

export default CustomPDAViewOrders;