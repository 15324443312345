import {
    Button,
    Card,
    CardActionArea,
    FormControlLabel,
    Grid,
    InputLabel,
    Stack,
    SvgIcon,
    Switch,
    TextField,
    Tooltip
} from '@mui/material';
import {
    Delete as DeleteIcon,
    Gradient as GradientIcon,
    Image as ImageIcon,
    Palette as PaletteIcon
} from '@mui/icons-material';
import { AddIcon, VisibilityIcon, VisibilityOffIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomDialogBasedOnScreen from 'components/Dialogs/CustomDialogBasedOnScreen';
import { getCouponCardProperties } from 'constants/couponCardProperties';
import { getDefaultCouponEmployee } from 'constants/defaultObjects';
import { useEffect, useState } from 'react';
import { addCardActionAreaButtonIconStyle, addCardActionAreaButtonStyle, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, loyaltyBackgroundButtonActiveStyle } from 'themes/defaultThemes';
import CustomCustomerCouponCard from './CustomCustomerCouponCard';
import couponApi from 'api/coupon';
import { t } from 'i18next';
import CustomTabs from 'components/Tab/CustomTabs';
import { TabContext, TabPanel } from '@mui/lab';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import en from "assets/images/flags/en.png";
import CustomTranslationsDialog from 'components/Dialogs/CustomTranslationsDialog';
import CustomTextField from 'components/Text/CustomTextField';
import CustomSelect from 'components/Select/CustomSelect';
import { getCouponCardTestValue } from 'constants/loyaltyCardTestValue';
import CustomFontStyle from 'components/Text/CustomFontStyle';
import { MuiColorInput } from 'mui-color-input';
import CustomMarginField from 'components/Text/CustomMarginField';
import { validateInputNumber } from 'utils/functions';
import { getCouponCardBarcodeFontSize, getCouponCardBarcodeHeight, getItemOfferPercentage, getItemPrice } from 'constants/fieldProperties';
import CustomTextFieldWithIcons from 'components/Text/CustomTextFieldWithIcons';
import LoadingSkeleton from 'components/Loading/LoadingSkeleton';
import CustomImage from 'components/Assets/CustomImage';
import { getThemeColor } from 'constants/theme';
import { getTheme } from 'utils/theme';
import CustomImageCropper from 'components/Assets/CustomImageCropper';
import CustomChip from 'components/Chip/CustomChip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

/**
 * The CustomCustomerCouponCard, that display  an add coupon card.
 * 
 * @param {object} order the past order details
 * @param {string} lang the lang code
 *
 * @returns {JSX.Element} A React component that renders a past order.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCustomerAddCouponCard({ sx, organization, outletID, refetchData, customerID }) {
    let style = { sx };
    // pop dialog for add
    const [isOpen, setIsOpen] = useState(false);
    const [redeemInAllOutlets, setRedeemInAllOutlets] = useState(false);
    const [coupon, setCoupon] = useState(getDefaultCouponEmployee(organization.id, !redeemInAllOutlets ? outletID : "", customerID));
    const [data, setData] = useState({
        status: [],
        backgroundTypes: [],
        formatOptions: [],
        fontOptions: [],
        textAlignOptions: [],
        textPositionOptions: [],
        logoImgSrc: "",
        currency: "€",
    })
    const [isLoaded, setIsLoaded] = useState(false);
    const [value, setValue] = useState("configuration");
    const currentDate = new Date(); // Get current date and time

    // for search
    const [search, setSearch] = useState({
        organizationID: organization.id,
        outletID: outletID
    });

    const [isError, setIsError] = useState({
        label: ""
    });

    const [dialog, setDialog] = useState({
        isOpen: false,
        id: "",
        field: "",
        translations: {}
    });

    /**
     * The rest endpoint to submit new note to customer.
     */
    function submitCoupon() {
        if (coupon.expirationDate !== null && coupon.expirationDate !== "" && !((coupon.value > 0 && coupon.percentage > 0) || (coupon.value === 0 && coupon.percentage === 0)))
            couponApi.create(coupon).then((r) => {
                if (r.data.code === "SUCCESS") {
                    refetchData();
                    setIsOpen(false);
                }
            }).catch((e) => {
                // console.log(e);
            })
    }
    /**
   * The rest endpoint to get the loyalty configuration.
   */
    function getCouponNew() {
        couponApi.fetchNew(search.organizationID, search.outletID).then((r) => {
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        // userAuth: r.data.returnobject.userAuth,
                        // organization: r.data.returnobject.organization,
                        // outlet: r.data.returnobject.outlet,
                        status: r.data.returnobject.status,
                        backgroundTypes: r.data.returnobject.backgroundTypes,
                        logoImgSrc: r.data.returnobject.logoImgSrc,
                        fontOptions: r.data.returnobject.fontOptions,
                        formatOptions: r.data.returnobject.formatOptions,
                        textAlignOptions: r.data.returnobject.textAlignOptions,
                        textPositionOptions: r.data.returnobject.textPositionOptions,
                    });
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        });
    }

    useEffect(() => {
        if (isOpen)
            getCouponNew();
    }, [isOpen]);

    /**
       * This function will trigger the outlet field translations configuration
       */
    function configureFieldTranslations(translations, field) {
        document.querySelectorAll(`[name="${field}.en"]`)[0].value = translations.en;
        setCoupon({
            ...coupon,
            [field]: translations
        });
        setDialog({
            ...dialog,
            isOpen: false,
            field: "",
            translations: {}
        });
    }

    /**
     * Function to trigger the remove of the admin broker.
     * @property {*} event
     * @property {string} field The field of the selected outlet field.
     */
    function configureTranslations(event, field) {
        handleTranslationsDialogState(true, field);
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpened If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleTranslationsDialogState(isOpened, field = "label") {
        setDialog({
            ...dialog,
            isOpen: isOpened,
            field: field,
            translations: coupon[field]
        });
    }

    //  ==================== FILE

    const [previewUrl, setPreviewUrl] = useState(null);

    /**
     * Callback that handles file upload change.
     * @param {string} fileBase64 the new base64 image 
     */
    function handleFileChange(fileBase64) {
        setCoupon({
            ...coupon,
            backgroundImage: fileBase64
        });
        setPreviewUrl(fileBase64);
    };

    function removeImage() {
        setCoupon({
            ...coupon,
            backgroundImage: ""
        })
        setPreviewUrl(null)
    }

    // ===============================================================================RENDER

    return (
        <Grid>
            <Card variant="outlined"
                key={`card-new-coupon`}
                sx={{
                    ...getCouponCardProperties(),
                    ...style
                }}
            >
                <CardActionArea sx={{ justifyContent: 'flex-end', height: "100%", width: "100%", textAlign: "-webkit-center" }}>
                    {/* <CardActionArea sx={addCardActionAreaStyle()}> */}
                    <CustomIconButton
                        sx={addCardActionAreaButtonStyle()}
                        backgroundColor="transparent"
                        icon={<AddIcon sx={addCardActionAreaButtonIconStyle()} />}
                        onClick={() => setIsOpen(true)}
                    />
                </CardActionArea>
            </Card>

            <CustomDialogBasedOnScreen
                isOpen={isOpen}
                body={<>
                    {!isLoaded &&
                        <LoadingSkeleton lines={9} />
                    }

                    {isLoaded &&
                        <>
                            {/* PREVIEW */}
                            <CustomCustomerCouponCard coupon={coupon} organization={organization} />

                            {/* EDIT SECTION */}
                            <TabContext value={value}>
                                <CustomTabs
                                    initValue={value}
                                    instances={[
                                        { name: t('coupon.tabs.configuration').toUpperCase(), value: "configuration" },
                                        { name: t('coupon.tabs.style').toUpperCase(), value: "style" },
                                        { name: t('coupon.tabs.barcodeStyle').toUpperCase(), value: "barcodeStyle" },
                                    ]}
                                    setValue={setValue}
                                />

                                <TabPanel value={"configuration"} sx={{ paddingTop: "0px" }}>
                                    <Grid container spacing={1} sx={{ textAlign: "left" }}>
                                        <Grid item xs={12}>
                                            <InputLabel required={false} htmlFor="label">{t('label.label')}</InputLabel>
                                            <CustomFlagTextField
                                                country="en"
                                                flag={en}
                                                placeholder=""
                                                name="label.en"
                                                id="label"
                                                defaultValue={coupon.label.en}
                                                onTranslationSelect={configureTranslations}
                                                field="label"
                                                onChange={(event) => {
                                                    let value = event.target.value;
                                                    if (value === "") setIsError({ ...isError, name: t('error.valueRequired') });
                                                    formValChangeWithParentElementWithNameAndValue("label.en", value, coupon, setCoupon)
                                                }}
                                                helperText={isError.label}
                                                error={isError.label.length > 0}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel required={false} htmlFor="description">{t('label.description')}</InputLabel>
                                            <CustomFlagTextField
                                                country="en"
                                                flag={en}
                                                placeholder=""
                                                name="description.en"
                                                id="description"
                                                value={coupon.description.en}
                                                onTranslationSelect={configureTranslations}
                                                field="description"
                                                onChange={(event) => {
                                                    formValChangeWithParentElementWithNameAndValue("description.en", event.target.value, coupon, setCoupon)
                                                }}
                                            />
                                            <CustomChip label={"%code"} onClick={() => formValChangeWithParentElementWithNameAndValue("description.en", coupon.description.en + " %code", coupon, setCoupon)} />
                                            <CustomChip label={"%value"} onClick={() => formValChangeWithParentElementWithNameAndValue("description.en", coupon.description.en + " %value", coupon, setCoupon)} />
                                            <CustomChip label={"%percentage"} onClick={() => formValChangeWithParentElementWithNameAndValue("description.en", coupon.description.en + " %percentage", coupon, setCoupon)} />
                                        </Grid>

                                        <Grid item xs={6} >
                                            <InputLabel required={false} htmlFor="value">{t('label.value')}</InputLabel>
                                            <CustomTextField
                                                type="number"
                                                name="value"
                                                id="value"
                                                step={getItemPrice().step}
                                                min={getItemPrice().min}
                                                max={getItemPrice().max}
                                                value={coupon.value}
                                                inputAdornmentAfterLabel={`offer.${data.currency}`}
                                                required={true}
                                                onChange={(event) => {
                                                    let value = validateInputNumber(event.target.value, getItemPrice().length, getItemPrice().min, getItemPrice().max, getItemPrice().isFloat);
                                                    if (value === undefined) value = coupon.value;
                                                    formValChangeWithParentElementWithNameAndValue("value", parseFloat(value), coupon, setCoupon)
                                                }}
                                                error={(coupon.value > 0 && coupon.percentage > 0) || (coupon.value === 0 && coupon.percentage === 0)}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <InputLabel required={false} htmlFor="percentage">{t('offer.percentage')}</InputLabel>
                                            <CustomTextField
                                                type="number"
                                                name="percentage"
                                                id="percentage"
                                                step={getItemOfferPercentage().step}
                                                min={getItemOfferPercentage().min}
                                                max={getItemOfferPercentage().max}
                                                value={coupon.percentage}
                                                inputAdornmentAfterLabel={'offer.%'}
                                                required={true}
                                                onChange={(event) => {
                                                    let value = validateInputNumber(event.target.value, getItemOfferPercentage().length, getItemOfferPercentage().min, getItemOfferPercentage().max, getItemOfferPercentage().isFloat);
                                                    if (value === undefined) value = coupon.percentage;
                                                    formValChangeWithParentElementWithNameAndValue("percentage", parseFloat(value), coupon, setCoupon)
                                                }}
                                                error={(coupon.value > 0 && coupon.percentage > 0) || (coupon.value === 0 && coupon.percentage === 0)}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <InputLabel required={false} htmlFor="code">{t('coupon.code')}</InputLabel>
                                            <CustomTextField
                                                type="text"
                                                name="code"
                                                id="code"
                                                value={coupon.code}
                                                onChange={(event) =>
                                                    formValChangeWithParentElementWithNameAndValue("code", event.target.value, coupon, setCoupon)}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <InputLabel required={false} htmlFor="expirationDate">{t('coupon.expirationDate')}</InputLabel>

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    inputFormat="DD/MM/YYYY hh:mm:ss"
                                                    renderInput={(params) => <TextField {...params} />}
                                                    name="expirationDate"
                                                    value={coupon.expirationDate}
                                                    minDate={currentDate}
                                                    onChange={(newValue) => formValChangeWithParentElementWithNameAndValue("expirationDate", newValue, coupon, setCoupon)}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControlLabel control={
                                                <Switch
                                                    defaultChecked={redeemInAllOutlets}
                                                    onChange={() => setRedeemInAllOutlets(!redeemInAllOutlets)} />}
                                                sx={{ marginLeft: "20px" }}
                                                label={t('coupon.redeemInAllOutlets')}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value={"style"} sx={{ paddingTop: "0px" }}>
                                    <Grid container spacing={2} sx={{ marginTop: '5px', justifyContent: "center" }}>
                                        <Grid item xs={12} md={6}>
                                            <MuiColorInput id="baseLabelColor"
                                                label={t('coupon.baseLabelColor')}
                                                name="coupon.baseLabelColor"
                                                format="hex8"
                                                value={coupon.baseLabelColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("baseLabelColor", value, coupon, setCoupon)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <MuiColorInput id="baseDescriptionColor"
                                                label={t('coupon.baseDescriptionColor')}
                                                name="coupon.baseDescriptionColor"
                                                format="hex8"
                                                value={coupon.baseDescriptionColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("baseDescriptionColor", value, coupon, setCoupon)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <MuiColorInput id="baseExpiresColor"
                                                label={t('coupon.baseExpiresColor')}
                                                name="coupon.baseExpiresColor"
                                                format="hex8"
                                                value={coupon.baseExpiresColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("baseExpiresColor", value, coupon, setCoupon)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <Stack direction="row" sx={{ paddingBottom: "10px" }}>
                                                <InputLabel required={false} style={{ fontWeight: "bold", paddingTop: "5px", paddingRight: "62%" }} >{t('coupon.background')}</InputLabel>
                                                <Tooltip arrow title={t("coupon.backgroundColor1")}>
                                                    <PaletteIcon
                                                        sx={{ padding: "3px", ...((coupon.backgroundType === "COLOR" && coupon.gradientBackground === false) ? loyaltyBackgroundButtonActiveStyle() : {}) }}
                                                        onClick={() => setCoupon({ ...coupon, backgroundType: "COLOR", gradientBackground: false })} />
                                                </Tooltip>
                                                <Tooltip arrow title={t("coupon.gradientBackground")}>
                                                    <GradientIcon
                                                        sx={{ padding: "3px", ...((coupon.backgroundType === "COLOR" && coupon.gradientBackground === true) ? loyaltyBackgroundButtonActiveStyle() : {}) }}
                                                        onClick={() => setCoupon({ ...coupon, backgroundType: "COLOR", gradientBackground: true })} />
                                                </Tooltip>
                                                <Tooltip arrow title={t("coupon.backgroundImage")}>
                                                    <ImageIcon
                                                        sx={{ padding: "3px", ...((coupon.backgroundType === "IMAGE") ? loyaltyBackgroundButtonActiveStyle() : {}) }}
                                                        onClick={() => setCoupon({ ...coupon, backgroundType: "IMAGE" })} />
                                                </Tooltip>
                                            </Stack>
                                        </Grid>

                                        {coupon.backgroundType === "COLOR" &&
                                            <Grid item xs={12} md={12}>
                                                <Stack direction="row" spacing={1} sx={{ paddingBottom: "10px", width: "100%" }}>
                                                    <Stack direction="column" sx={{ width: "50%" }}>
                                                        {/* <InputLabel required={false} htmlFor="backgroundColor1">{t('coupon.backgroundColor1')}</InputLabel> */}
                                                        <MuiColorInput id="backgroundColor1"
                                                            label={t('coupon.backgroundColor1')}
                                                            name="coupon.backgroundColor1"
                                                            format="hex8"
                                                            value={coupon.backgroundColor1}
                                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("backgroundColor1", value, coupon, setCoupon)}
                                                        />
                                                    </Stack>

                                                    <>
                                                        {coupon.gradientBackground &&
                                                            <Stack direction="column" sx={{ width: "50%" }}>
                                                                {/* <InputLabel required={false} htmlFor="backgroundColor2">{t('coupon.backgroundColor2')}</InputLabel> */}
                                                                <MuiColorInput id="backgroundColor2"
                                                                    label={t('coupon.backgroundColor2')}
                                                                    name="coupon.backgroundColor2"
                                                                    format="hex8"
                                                                    value={coupon.backgroundColor2}
                                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("backgroundColor2", value, coupon, setCoupon)}
                                                                />
                                                            </Stack>
                                                        }
                                                    </>
                                                </Stack>
                                            </Grid>
                                        }

                                        <Grid item xs={12} md={12}>
                                            {coupon.backgroundType === "IMAGE" &&
                                                <>
                                                    <Grid container direction="row" spacing={2}>
                                                        <Grid item xs={12} md={12}>
                                                            <InputLabel required={false} htmlFor="url">{t('coupon.backgroundImage')}</InputLabel>
                                                        </Grid>
                                                        <Grid item xs={12} md={12}>
                                                            {(previewUrl === null && (coupon.backgroundImage !== null && coupon.backgroundImage !== "")) &&
                                                                <Grid container direction="row" spacing={2}>
                                                                    <Grid item xs={10} md={10}>
                                                                        <CustomImage
                                                                            alt="complex"
                                                                            src={coupon.backgroundImage}
                                                                            sx={{ height: "220px" }} />

                                                                    </Grid>
                                                                    <Grid item xs={0} md={2}>
                                                                        <CustomIconButton
                                                                            keyValue={`btn-delete-${coupon.id}`}
                                                                            onClick={removeImage}
                                                                            icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                            className="icon-wrapper tr link"
                                                                            iconSx={{ marginRight: "0px!important" }}
                                                                            sx={{
                                                                                backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                                ":hover": {
                                                                                    backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                                },
                                                                                ...cardItemButtonGroupStackButton()
                                                                            }}
                                                                        >
                                                                            {t('actions.delete')}
                                                                        </CustomIconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            }

                                                            {/* <CustomTextField
                                                                type="text"
                                                                name="coupon.backgroundImage"
                                                                id="backgroundImage"
                                                                required={true}
                                                                defaultValue={coupon.backgroundImage}
                                                                onChange={(event) => formValChangeWithParentElementWithNameAndValue("backgroundImage", event.target.value, coupon, setCoupon)}
                                                            /> */}

                                                            {previewUrl &&
                                                                <Grid container direction="row" spacing={2}>
                                                                    <Grid item xs={10} md={10}>
                                                                        <img src={previewUrl} alt="Preview" height="220px" />
                                                                    </Grid>
                                                                    <Grid item xs={0} md={2}>
                                                                        <CustomIconButton
                                                                            keyValue={`btn-delete-${coupon.id}`}
                                                                            onClick={removeImage}
                                                                            icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                            className="icon-wrapper tr link"
                                                                            iconSx={{ marginRight: "0px!important" }}
                                                                            sx={{
                                                                                backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                                ":hover": {
                                                                                    backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                                },
                                                                                ...cardItemButtonGroupStackButton()
                                                                            }}
                                                                        >
                                                                            {t('actions.delete')}
                                                                        </CustomIconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} md={12}>
                                                            <Button>
                                                                {/* Choose File */}
                                                                <CustomImageCropper storeCroppedImage={handleFileChange} />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value={"barcodeStyle"} sx={{ paddingTop: "0px" }}>
                                    <Grid container spacing={2} sx={{ marginTop: '5px', justifyContent: "center" }}>
                                        <Grid item xs={12} md={7}>
                                            <InputLabel required={false} htmlFor="font">{t('loyalty.settings.barcode.format')}</InputLabel>
                                            <CustomSelect
                                                name="coupon.barcodeConfiguration.format"
                                                required={true}
                                                value={coupon.barcodeConfiguration.format}
                                                options={data.formatOptions}
                                                sx={{ maxHeight: "62px" }}
                                                onChange={(event) => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.format", event.target.value, coupon, setCoupon)}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} md={8}>
                                        <CustomTextField
                                            type="number" step="1.00" min={0}
                                            name="coupon.barcodeConfiguration.width"
                                            inputAdornmentLabel="loyalty.settings.barcode.width"
                                            defaultValue={coupon.barcodeConfiguration.width}
                                            required={true}
                                            disabled={true}
                                            startAdormentWidth="150px"
                                            inputWidth={"80%"}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.width", parseInt(event.target.value), coupon, setCoupon)}
                                        />
                                    </Grid> */}
                                        <Grid item xs={12} md={5}>
                                            <CustomTextField
                                                type="number"
                                                step={getCouponCardBarcodeHeight().step}
                                                min={getCouponCardBarcodeHeight().min}
                                                max={getCouponCardBarcodeHeight().max}
                                                name="coupon.barcodeConfiguration.height"
                                                inputAdornmentLabel="loyalty.settings.barcode.height"
                                                value={coupon.barcodeConfiguration.height}
                                                required={true}
                                                sx={{ marginTop: "16px" }}
                                                onChange={(event) => {
                                                    let value = validateInputNumber(event.target.value, getCouponCardBarcodeHeight().length, getCouponCardBarcodeHeight().min, getCouponCardBarcodeHeight().max, getCouponCardBarcodeHeight().isFloat);
                                                    if (value === undefined) value = coupon.barcodeConfiguration.height;
                                                    formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.height", parseFloat(value), coupon, setCoupon)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <CustomTextFieldWithIcons
                                                name="coupon.barcodeConfiguration.displayValue"
                                                type="text"
                                                defaultValue={getCouponCardTestValue()}
                                                inputAdornmentLabel="loyalty.settings.barcode.displayValue"
                                                endIcon={<SvgIcon component={coupon.barcodeConfiguration.displayValue ? VisibilityIcon : VisibilityOffIcon}
                                                    id="visibility-icon"
                                                    onClick={() => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.displayValue", !coupon.barcodeConfiguration.displayValue, coupon, setCoupon)} style={{ cursor: "pointer" }} />
                                                }
                                                disabled={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <Stack spacing={4} direction="row" sx={{ paddingBottom: "5px", width: "100%", placeContent: "center" }}>
                                                <CustomFontStyle
                                                    // data
                                                    data={coupon}
                                                    setData={setCoupon}
                                                    objectnName="barcodeConfiguration."
                                                    // option lists
                                                    fontOptions={data.fontOptions}
                                                    textAlignOptions={data.textAlignOptions}
                                                    textPositionOptions={data.textPositionOptions}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <InputLabel required={false} style={{ fontWeight: "bold" }} >{t('coupon.font')}</InputLabel>
                                            <Grid container spacing={2} >
                                                {/* sx={{ justifyContent: "center" }}> */}
                                                {/* <Grid item xs={12} md={8}>
                                                        <InputLabel required={false} htmlFor="font">{t('coupon.family')}</InputLabel>
                                                        <CustomSelect
                                                            name="coupon.barcodeConfiguration.font"
                                                            required={true}
                                                            defaultValue={coupon.barcodeConfiguration.font}
                                                            options={fontFamilyOptions}
                                                            sx={{ maxHeight: "60px" }}
                                                            onClick={(event) => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.font", event.target.value, coupon, setCoupon)}
                                                        />
                                                    </Grid> */}

                                                <Grid item xs={12} md={4}>
                                                    <CustomTextField
                                                        type="number"
                                                        step={getCouponCardBarcodeFontSize().step}
                                                        min={getCouponCardBarcodeFontSize().min}
                                                        max={getCouponCardBarcodeFontSize().max}
                                                        name="coupon.barcodeConfiguration.fontSize"
                                                        label={t('loyalty.settings.barcode.fontSize')}
                                                        sx={{ marginTop: "16px" }}
                                                        value={coupon.barcodeConfiguration.fontSize}
                                                        onChange={(event) => {
                                                            let value = validateInputNumber(event.target.value, getCouponCardBarcodeFontSize().length, getCouponCardBarcodeFontSize().min, getCouponCardBarcodeFontSize().max, getCouponCardBarcodeFontSize().isFloat);
                                                            if (value === undefined) value = coupon.barcodeConfiguration.fontSize;
                                                            formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.fontSize", parseInt(value), coupon, setCoupon)
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            {/* <InputLabel required={false} htmlFor="background">{t('loyalty.settings.barcode.background')}</InputLabel> */}
                                            <MuiColorInput id="background"
                                                label={t('loyalty.settings.barcode.background')}
                                                name="coupon.barcodeConfiguration.background"
                                                format="hex8"
                                                value={coupon.barcodeConfiguration.background}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.background", value, coupon, setCoupon)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {/* <InputLabel required={false} htmlFor="lineColor">{t('loyalty.settings.barcode.lineColor')}</InputLabel> */}
                                            <MuiColorInput id="lineColor"
                                                label={t('loyalty.settings.barcode.lineColor')}
                                                name="coupon.barcodeConfiguration.lineColor"
                                                format="hex8"
                                                value={coupon.barcodeConfiguration.lineColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("barcodeConfiguration.lineColor", value, coupon, setCoupon)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <InputLabel required={false} htmlFor="margin" style={{ fontWeight: "bold" }}>{t('loyalty.settings.barcode.margin')}</InputLabel>
                                            <Grid container spacing={2} sx={{ justifyContent: "center", marginTop: "5px" }}>
                                                <Grid item xs={12} md={12}>
                                                    <CustomMarginField
                                                        // data
                                                        data={coupon}
                                                        displayValue={coupon.barcodeConfiguration.displayValue}
                                                        setData={setCoupon}
                                                        objectnName="barcodeConfiguration."
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>


                            {/* ACTIONS BUTTONS */}
                            <Grid container spacing={1}>
                                <Grid item xs={6} sx={{ textAlign: "center" }}>
                                    <Button
                                        variant='outlined'
                                        color="error"
                                        sx={{
                                            textTransform: "none !important",
                                        }}
                                        onClick={() => { setCoupon(getDefaultCouponEmployee()); setIsOpen(false) }}>{t('actions.cancel')}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: "center" }}>
                                    <Button
                                        variant='outlined'
                                        color="success"
                                        sx={{
                                            textTransform: "none !important",
                                        }}
                                        // disabled={(value.length === 0 ? true : false)}
                                        onClick={() => submitCoupon()}>
                                        {t('actions.submit')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    }
                </>}
                handleClose={() => { setCoupon(getDefaultCouponEmployee()); setIsOpen(false) }}
            />


            {dialog.isOpen &&
                <CustomTranslationsDialog
                    isOpen={dialog.isOpen}
                    id="translations"
                    title={t('actions.translationModal.title')}
                    action={configureFieldTranslations}
                    handleOpen={handleTranslationsDialogState}
                    translations={coupon[dialog.field]}
                    field={dialog.field}
                />
            }
        </Grid>
    );
}

export default CustomCustomerAddCouponCard;