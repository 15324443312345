import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { thunk } from 'redux-thunk';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './rootReducer';
import { cleanupMiddleware } from './clean/cleanStoreAction';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk, cleanupMiddleware));
// Dispatch an action to set the cleanup timer
store.dispatch({ type: 'SET_CLEANUP_TIMER' });
export const persistor = persistStore(store);
export default store;