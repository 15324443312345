import { Menu as MenuIcon } from '@mui/icons-material';
import { BottomNavigation, createTheme, Fab, Grid, List, ThemeProvider, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import CustomImageAvatar from "components/Avatar/CustomImageAvatar";
import CustomListItem from "components/Drawer/CustomListItem";
import CustomSwippableDrawer from 'components/Drawer/CustomSwippableDrawer';
import CustomFooter from 'components/Footer/CustomFooter';
import TopHeader from "components/header/TopHeader";
import AuthRoutes from "components/Routes/AuthRoutes";
import { getMenuItems } from "constants/menuItems";
import { AuthConsumer } from "context/AuthContext";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { fabStyleToggleMenuButton } from 'themes/defaultThemes';
import { hasAnyOfRoles } from 'utils/auth';
import { isMobileDevice } from 'utils/getScreen';
import useDocumentBodyClass from 'utils/useDocumentBodyClass';

/**
 * The Home component after user has Logged in successfully.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AuthenticatedHomeComponent() {

    const SIDE_NAV_WIDTH = 270;
    // const TOP_NAV_HEIGHT = 45;

    const isMobile = useMediaQuery('(max-width:468px)');
    const { t } = useTranslation();
    useDocumentBodyClass("no-touch usee");
    const menuItems = getMenuItems();

    // DRAWER
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            display: "flow-root !important",
                            position: "relative"
                        }
                    }
                },
                MuiDrawer: {
                    styleOverrides: {
                        paper: {
                            display: "flow-root !important",
                            position: "relative"
                        }
                    }
                }
            }
        })
    }

    return (
        <React.Fragment>
            <AuthConsumer>
                {({ user, sidenav, handleSidenavStateChange }) => (
                    <>
                        {!isMobile &&
                            <TopHeader key="th" />
                        }
                        {isMobile && !isMobileDevice() &&
                            <Fab color="primary"
                                aria-label="add"
                                sx={fabStyleToggleMenuButton()}
                                onClick={() => handleSidenavStateChange(!sidenav.expanded)}
                            >
                                <MenuIcon />
                            </Fab>
                        }

                        <div id="content-wrapper" className="column" data-select2-id="content-wrapper" key="cw">
                            <ThemeProvider theme={getMuiTheme()}>

                                {!isMobile &&
                                    <Drawer
                                        id={`layout-sidebar${(sidenav.expanded) ? "-expanded" : ""}`}
                                        variant="permanent"
                                        open={sidenav.expanded}
                                        PaperProps={{
                                            sx: {
                                                backgroundColor: 'background.default',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: `100%`,
                                                p: 1,
                                                top: 0,
                                                padding: 0,
                                                width: SIDE_NAV_WIDTH,
                                                zIndex: (theme) => theme.zIndex.appBar - 100
                                            }
                                        }}
                                    >
                                        <Grid container gap={0} sx={{ height: '100%' }}>
                                            {/* user avatar */}
                                            <CustomImageAvatar name={user.username} sx={{ display: (!sidenav.expanded) ? "none" : "-webkit-box", marginTop: "10px" }} />

                                            {/* menu items */}
                                            <Grid item id="menu-items"
                                                xs={12}
                                                sx={{
                                                    height: 'calc(100% - 130px)',
                                                }}>
                                                <List>
                                                    {menuItems.map((menuItem) =>
                                                        hasAnyOfRoles(user.roles, menuItem.roles) &&
                                                        <CustomListItem key={menuItem.label} sidenav={sidenav} label={t(`menu.${menuItem.label}`)} icon={menuItem.icon} link={menuItem.link} roles={user.roles} children={menuItem.children} />
                                                    )}
                                                </List>
                                            </Grid>

                                            {sidenav.expanded &&
                                                <Grid item xs={12}>
                                                    <BottomNavigation
                                                        showLabels
                                                    >
                                                        <CustomFooter />
                                                    </BottomNavigation>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Drawer>
                                }
                            </ThemeProvider>

                            <div id={`main-wrapper${(sidenav.expanded) ? "-expanded" : ""}`} className="tr">
                                <AuthRoutes />
                            </div>
                        </div>

                        {isMobile && !isMobileDevice() &&
                            <CustomSwippableDrawer
                                open={isMobile ? sidenav.expanded : false}
                                onClose={handleDrawerClose}
                                onOpen={handleDrawerOpen}
                                anchor={"left"}
                                sx={{ width: SIDE_NAV_WIDTH }}
                            >
                                <Drawer
                                    id={`layout-sidebar${(sidenav.expanded) ? "-expanded" : ""}`}
                                    variant="permanent"
                                    open={sidenav.expanded}
                                    PaperProps={{
                                        sx: {
                                            backgroundColor: 'white',
                                            p: 1,
                                            width: SIDE_NAV_WIDTH,
                                            // height: `calc(100% - ${TOP_NAV_HEIGHT}px)`,
                                            zIndex: (theme) => theme.zIndex.appBar - 100
                                        }
                                    }}
                                >
                                    <Grid container gap={1} sx={{ height: '100%' }}>
                                        {/* user avatar */}
                                        <CustomImageAvatar name={user.username} sx={{ display: (!sidenav.expanded) ? "none" : "-webkit-box", marginTop: "10px" }} />

                                        {/* menu items */}
                                        <Grid item xs={12}
                                            id="menu-items"
                                            sx={{
                                                height: 'calc(100% - 140px)',
                                            }}>
                                            <List>
                                                {menuItems.map((menuItem) =>
                                                    hasAnyOfRoles(user.roles, menuItem.roles) &&
                                                    <CustomListItem key={menuItem.label} sidenav={sidenav} label={t(`menu.${menuItem.label}`)} icon={menuItem.icon} link={menuItem.link} roles={user.roles} children={menuItem.children} />
                                                )}
                                            </List>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <BottomNavigation
                                                showLabels
                                            >
                                                <CustomFooter />
                                            </BottomNavigation>
                                        </Grid>
                                    </Grid>
                                </Drawer>
                            </CustomSwippableDrawer>
                        }
                    </>
                )}
            </AuthConsumer>
        </React.Fragment>
    );
}

export default AuthenticatedHomeComponent;