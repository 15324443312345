import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const EmployeesOrderBarChart = ({ employeeData, title, subtitle }) => {
    const { t } = useTranslation();
    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            },
            height: "auto"
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true
            }
        },
        xaxis: {
            categories: employeeData?.map(employee => employee?.employee?.name),
        },
        yaxis: {
            title: {
                text: t('analytics.chart.employees.yTitle')
            },
            labels: {
                formatter: function (value) {
                    return value;
                }
            }
        },
        title: {
            text: title,
            align: 'center',
            style: {
                color: "#333333",
                fontSize: "18px",
                fill: "#333333",
                fontFamily: "Lumanosimo",
                fontWeight: 900,
                paddingTop: "5px",
                paddingBottom: "10px",
                marginBottom: 0
            }
        },
        subtitle: {
            text: subtitle,
            align: 'center',
            style: {
                color: "#666666",
                fontSize: "12px",
                fill: "#666666",
                fontWeight: 400
            }
        },
    };

    const series = [{
        name: t('analytics.chart.employees.dataLabel'),
        data: employeeData?.map(employee => employee.value)
    }];

    return (
        <div>
            {employeeData !== null &&
                <Chart options={options} series={series} type="bar" height={options.xaxis.categories?.length < 2 ? 120 : 400} />
            }
        </div>
    );
};

export default EmployeesOrderBarChart;
