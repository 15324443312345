import {
    Grain as GrainIcon,
    PauseCircle as PauseCircleIcon,
    PlayCircle as PlayCircleIcon,
    Save as SaveIcon
} from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Fab, Grid } from "@mui/material";
import inventoryApi from 'api/inventory';
import itemApi from "api/item";
import {
    Infinite
} from 'assets/svg/SvgIcons';
import CustomAddEditBreadcrumbMultiParent from "components/Breadcrumb/CustomAddEditBreadcrumbMultiParent";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomChip from 'components/Chip/CustomChip';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTabs from 'components/Tab/CustomTabs';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitle from 'components/Title/CustomTitle';
import { getDefaultItemsInventory } from 'constants/defaultObjects';
import { getItemInventorySortage } from 'constants/fieldProperties';
import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, inventoryButton, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagTitle } from 'themes/defaultThemes';
import { hasRoleAdmin, hasRoleAdminMenu } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { findCurrency, validateInputNumber } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { idFormatterLinkWithParent } from 'utils/table';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The ItemInventoryComponent that allow the ADMIN to create a 
 * new item or edit an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ItemInventoryComponent() {
    const { t } = useTranslation();

    const { organizationID } = useParams();
    const navigate = useNavigate();
    let path = `/${getLanguageFromURL()}`;
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    path += `/inventory`;

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {object} organization the organization basic details (id and name) 
     * @property {array} items the items list
     */
    const [data, setData] = useState({
        userAuth: null,
        organization: {
            id: organizationID,
            name: ""
        },
        organizations: [],
        outlet: {
            id: organizationID,
            name: ""
        },
        outlets: [],
        menus: [],
        itemInventory: {},
        currency: "€",
    });


    const [search, setSearch] = useState({
        organizationID: organizationID,
        outletID: "",
        name: ""
    });

    const [value, setValue] = useState("items");

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getItemsInventory();
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getItemsInventory();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
    }, [search])

    //change document title
    useDocumentTitle(`Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t('org.pageTitle') : ""} | ${t('inventory.label')}`);

    /**
     * The rest endpoint to get the item.
     */
    function getItemsInventory() {
        let sear = search;
        if (hasRoleAdminMenu(data.userAuth?.roles)) {
            sear.organizationID = localStorage.getItem("oid")
        }

        inventoryApi.fetchOneInventory(sear).then((r) => {
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        itemInventory: r.data.returnobject.itemInventory ? r.data.returnobject.itemInventory : getDefaultItemsInventory(),
                        outlet: r.data.returnobject.outlet,
                        outlets: r.data.returnobject.outlets,
                        organization: r.data.returnobject.organization,
                        organizations: r.data.returnobject.organizations,
                        items: r.data.returnobject.items,
                        menus: r.data.returnobject.menus,
                        isAdd: !r.data.returnobject.itemInventory,
                        currency: findCurrency(r.data.returnobject.currency)
                    });
                })
                .then(() => {
                    let obj = {}
                    if (r.data.returnobject?.organization !== null) {
                        obj.organizationID = r.data.returnobject.organization.id
                    }
                    if (r.data.returnobject?.outlet !== null) {
                        obj.outletID = r.data.returnobject.outlet.id
                    }
                    setSearch({
                        ...search,
                        ...obj
                    })
                })
                .then(() => {
                    setIsLoaded(true);
                })
                .then(() => {
                    setIsFirstTime(false);
                })
        }).catch((e) => {
            // console.log(e);
        });
    }

    /**
     * Gets called when the user clicks on the save button, and triggers 
     * the creating of the item.
     */
    function addItemsInventory() {
        if (data.outlet.id) {
            let itemInventory = data.itemInventory;
            itemInventory.organizationID = data.organization.id;
            itemInventory.outletID = data.outlet.id;
            itemApi.create(itemInventory).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313",
                });
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the modification of the item
     */
    function editItemsInventory() {
        if (data.outlet.id) {
            inventoryApi.update(data.itemInventory).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }


    function updateItemAvailable(id) {
        let items = data.itemInventory.items;
        let index = items.findIndex(obj => obj.itemID === id);

        // only splice array when item is found
        if (index > -1) {
            items[index].available = !items[index].available;
            // TODO: should alter the sortage as well?
            formValChangeWithParentElementWithNameAndValue("itemInventory.items", items, data, setData, "")
        }
    }


    function updateItemSortage(id, sortage) {
        let items = data.itemInventory.items;
        let index = items.findIndex(obj => obj.itemID === id);

        // only splice array when item is found
        if (index > -1) {
            if (sortage !== -1 && sortage !== 0) {
                let value = validateInputNumber(sortage, getItemInventorySortage().length, getItemInventorySortage().min, getItemInventorySortage().max, getItemInventorySortage().isFloat);
                if (value === undefined) value = items[index].sortage;
                items[index].sortage = value;
            } else
                items[index].sortage = sortage;
            formValChangeWithParentElementWithNameAndValue("itemInventory.items", items, data, setData, "")
        }
    }


    function updateAlertItemSortage(id, sortage) {
        let items = data.itemInventory.items;
        let index = items.findIndex(obj => obj.itemID === id);

        // only splice array when item is found
        if (index > -1) {
            if (sortage !== -1 && sortage !== 0) {
                let value = validateInputNumber(sortage, getItemInventorySortage().length, getItemInventorySortage().min, getItemInventorySortage().max, getItemInventorySortage().isFloat);
                if (value === undefined) value = items[index].sortage;
                items[index].alertSortage = value;
            } else
                items[index].alertSortage = sortage;
            formValChangeWithParentElementWithNameAndValue("itemInventory.items", items, data, setData, "")
        }
    }


    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }


    /**
     * Function that handles the pagination information.
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(page) {
        formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
    }

    /**
     * Function that handles the pagination information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        let pagination = data.pagination;
        pagination.size = size;
        pagination.page = 0;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }
    /**
     * Function that handles the sort information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }

    function onFilterChange(event) {
        if (event !== null) {
            let field = event.field;
            let value = event.value;

            formValChangeWithParentElementWithNameAndValue(field, value, search, setSearch)
        } else {
            setSearch({
                ...search,
                name: "",
            })
        }
    }
    // ===============================================================================RENDER

    if (redirect) {
        navigate(path);
    } else if (!isLoaded) {
        console.log("hi")
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {

        let lang = getLanguageFromURL();

        /**
         * datatable columns.
         */
        const table_columns = [
            {
                name: "itemID",
                label: t('label.id'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
                    }
                }
            },
            {
                name: `name`,
                label: t('label.name'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.name')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return value[lang];
                    }
                }
            },
            {
                name: "price",
                label: t('inventory.defaultPrice'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        let price = parseFloat(value + "").toFixed(2);
                        return `${price} ${data.currency}`;
                    }
                }
            },
            {
                name: "available",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return <CustomIconButton
                            variant="outlined"
                            keyValue={`btn-${tableMeta.rowIndex}`}
                            onClick={() => {
                                updateItemAvailable(tableMeta.tableData[tableMeta.rowIndex][0]);
                                updateItemSortage(tableMeta.tableData[tableMeta.rowIndex][0], -1);
                            }}
                            label={t(value ? 'inventory.pauseAvailability' : 'inventory.resumeAvailability')}
                            sx={{ ...inventoryButton(value) }}
                            icon={value ? <PauseCircleIcon /> : <PlayCircleIcon />}
                        />
                    }
                }
            },
            {
                name: "sortage",
                label: t('inventory.sortage'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return <>
                            {tableMeta.tableData[tableMeta.rowIndex][3] ?
                                <>
                                    <Grid container spacing={2} gap={2} sx={{ minWidth: "250px" }}>
                                        <Grid item xs={"auto"}>
                                            <CustomIconButton
                                                keyValue={`item-inventory-unlimited-${tableMeta.rowIndex}`}
                                                onClick={() => updateItemSortage(tableMeta.tableData[tableMeta.rowIndex][0], -1)}
                                                variant='outlined'
                                                backgroundColor="#dcf1f7"
                                                isFocused={value === -1}
                                                sx={{ borderRadius: "50px", padding: "5px" }}
                                                tooltipTitle={t('inventory.unlimited')}
                                                icon={
                                                    <Infinite
                                                        width={20}
                                                        height={20}
                                                        fill={"#54b1d2"}
                                                        style={{
                                                            padding: 10,
                                                            borderRadius: 25
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={"auto"}>
                                            <CustomIconButton
                                                keyValue={`item-inventory-quantity-${tableMeta.rowIndex}`}
                                                onClick={() => updateItemSortage(tableMeta.tableData[tableMeta.rowIndex][0], 0)}
                                                variant='outlined'
                                                backgroundColor="#dcf1f7"
                                                isFocused={value > -1}
                                                sx={{ borderRadius: "50px", padding: "5px" }}
                                                tooltipTitle={t('inventory.quantity')}
                                                icon={
                                                    <GrainIcon
                                                        width={20}
                                                        height={20}
                                                        fill={"#54b1d2"}
                                                        style={{
                                                            color: "#54b1d2",
                                                            padding: 10,
                                                            borderRadius: 25
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>

                                        {value > -1 &&
                                            <>
                                                <Grid item xs={4}>
                                                    <CustomTextField
                                                        type="number"
                                                        step={getItemInventorySortage().step}
                                                        min={getItemInventorySortage().min}
                                                        // max={getItemInventorySortage().max}
                                                        name="min"
                                                        value={value}
                                                        required={true}
                                                        inputAdornmentLabel="inventory.sortage"
                                                        onChange={(event) => {
                                                            updateItemSortage(tableMeta.tableData[tableMeta.rowIndex][0], event.target.value)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <CustomTextField
                                                        type="number"
                                                        step={getItemInventorySortage().step}
                                                        min={getItemInventorySortage().min}
                                                        // max={getItemInventorySortage().max}
                                                        name="min"
                                                        value={tableMeta.tableData[tableMeta.rowIndex][5]}
                                                        required={true}
                                                        inputAdornmentLabel="inventory.alertSortage"
                                                        onChange={(event) => {
                                                            updateItemSortage(tableMeta.tableData[tableMeta.rowIndex][0], event.target.value)
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </>
                                :
                                <CustomChip label={0} />
                            }
                        </>
                    }
                }
            },
            {
                name: "alertSortage",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    display: false
                }
            },
        ];

        /**
        * offers datatable columns.
        */
        const table_columns_offers = [
            {
                name: "instanceID",
                label: t('label.id'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
                    }
                }
            },
            {
                name: `name`,
                label: t('label.name'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.name')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return value[lang];
                    }
                }
            },
            {
                name: "price",
                label: t('inventory.price'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        let price = parseFloat(value + "").toFixed(2);
                        return price ? `${price} ${data.currency}` : "";
                    }
                }
            },
            {
                name: "percentage",
                label: t('inventory.percentage'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return value ? `${value} ${t('inventory.%')}` : "";
                    }
                }
            },
            {
                name: "available",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return <CustomIconButton
                            variant="outlined"
                            keyValue={`btn-${tableMeta.rowIndex}`}
                            onClick={() => {
                                updateItemAvailable(tableMeta.tableData[tableMeta.rowIndex][0]);
                                updateItemSortage(tableMeta.tableData[tableMeta.rowIndex][0], -1);
                            }}
                            label={t(value ? 'inventory.pauseAvailability' : 'inventory.resumeAvailability')}
                            sx={{ ...inventoryButton(value) }}
                            icon={value ? <PauseCircleIcon /> : <PlayCircleIcon />}
                        />
                    }
                }
            },
            {
                name: "sortage",
                label: t('inventory.sortage'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return <>
                            {tableMeta.tableData[tableMeta.rowIndex][3] ?
                                <>
                                    <Grid container spacing={2} gap={2} sx={{ minWidth: "250px" }}>
                                        <Grid item xs={"auto"}>
                                            <CustomIconButton
                                                keyValue={`item-inventory-unlimited-${tableMeta.rowIndex}`}
                                                onClick={() => updateItemSortage(tableMeta.tableData[tableMeta.rowIndex][0], -1)}
                                                variant='outlined'
                                                backgroundColor="#dcf1f7"
                                                isFocused={value === -1}
                                                sx={{ borderRadius: "50px", padding: "5px" }}
                                                tooltipTitle={t('inventory.unlimited')}
                                                icon={
                                                    <Infinite
                                                        width={20}
                                                        height={20}
                                                        fill={"#54b1d2"}
                                                        style={{
                                                            padding: 10,
                                                            borderRadius: 25
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={"auto"}>
                                            <CustomIconButton
                                                keyValue={`item-inventory-quantity-${tableMeta.rowIndex}`}
                                                onClick={() => updateItemSortage(tableMeta.tableData[tableMeta.rowIndex][0], 0)}
                                                variant='outlined'
                                                backgroundColor="#dcf1f7"
                                                isFocused={value > -1}
                                                sx={{ borderRadius: "50px", padding: "5px" }}
                                                tooltipTitle={t('inventory.quantity')}
                                                icon={
                                                    <GrainIcon
                                                        width={20}
                                                        height={20}
                                                        fill={"#54b1d2"}
                                                        style={{
                                                            color: "#54b1d2",
                                                            padding: 10,
                                                            borderRadius: 25
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>

                                        {value > -1 &&
                                            <>
                                                <Grid item xs={4}>
                                                    <CustomTextField
                                                        type="number"
                                                        step={getItemInventorySortage().step}
                                                        min={getItemInventorySortage().min}
                                                        // max={getItemInventorySortage().max}
                                                        name="min"
                                                        value={value}
                                                        required={true}
                                                        inputAdornmentLabel="inventory.sortage"
                                                        onChange={(event) => {
                                                            updateItemSortage(tableMeta.tableData[tableMeta.rowIndex][0], event.target.value)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <CustomTextField
                                                        type="number"
                                                        step={getItemInventorySortage().step}
                                                        min={getItemInventorySortage().min}
                                                        // max={getItemInventorySortage().max}
                                                        name="min"
                                                        value={tableMeta.tableData[tableMeta.rowIndex][5]}
                                                        required={true}
                                                        inputAdornmentLabel="inventory.alertSortage"
                                                        onChange={(event) => {
                                                            updateAlertItemSortage(tableMeta.tableData[tableMeta.rowIndex][0], event.target.value)
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </>
                                :
                                <CustomChip label={0} />
                            }
                        </>
                    }
                }
            },
            {
                name: "alertSortage",
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    display: false
                }
            },
        ];

        return (
            // INVENTORY
            <CustomGrid role="main" id={"inventory"} sx={mainTag()}>
                {(organizationID !== undefined && !hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                            ]}
                            instanceName={t('inventory.label')}
                        />
                    </CustomGrid>
                }

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <CustomGrid sx={mainTagTitle()}>
                            <CustomTitle
                                title={t('inventory.label')}
                                subtitle={t('inventory.subtitle')}
                            />
                        </CustomGrid>
                    </Grid>

                    <Grid item sx={12} sm={9}>
                        <Grid container spacing={2} sx={{ justifyContent: "right" }}>
                            {(!hasRoleAdminMenu(data.userAuth?.roles) && data.outlets !== null) &&
                                <Grid item xs={12} sm={5}>
                                    <CustomObjectSelect
                                        name="outletID"
                                        required={true}
                                        defaultValue={search.outletID}
                                        labelID="outletID"
                                        label={t('outlet.pageTitle')}
                                        options={data.outlets}
                                        onChange={(event) => formValChangeWithParentElementWithNameAndValue("outletID", event.target.value, search, setSearch, "")}
                                        field="name"
                                    />
                                </Grid>
                            }

                            {hasRoleAdminMenu(data.userAuth?.roles) && data.organizations != null &&
                                <Grid item xs={12} sm={"auto"}>
                                    <label htmlFor="organizationID">{t('org.pageTitle')}</label>
                                    <CustomObjectSelect
                                        name="organizationID"
                                        required={true}
                                        value={localStorage.getItem("oid")}
                                        // labelID="organizationID" label={t('org.pageTitle')}
                                        options={data.organizations}
                                        onChange={(event) => { localStorage.setItem("oid", event.target?.value); setSearch({ ...search, organizationID: event.target?.value }) }}
                                        field={"name"}
                                        sx={{ borderRadius: "30px" }}
                                    />
                                </Grid>
                            }


                            {(hasRoleAdminMenu(data.userAuth?.roles) && search.organizationID !== null && data.outlets != null) &&
                                <Grid item xs={12} sm={"auto"}>
                                    <label htmlFor="outletID">{t('outlet.pageTitle')}</label>
                                    <CustomObjectSelect
                                        name="outletID"
                                        required={true}
                                        value={search.outletID}
                                        // labelID="organizationID" label={t('org.pageTitle')}
                                        options={data.outlets}
                                        onChange={(event) => setSearch({ ...search, outletID: event.target?.value })}
                                        field={"name"}
                                        sx={{ borderRadius: "30px" }}
                                    />
                                </Grid>
                            }

                            {/* <CustomObjectSelect
                            name="menuID"
                            required={true}
                            defaultValue={search.menuID}
                            labelID="menuID"
                            label={t('menus.pageTitle')}
                            options={[{ id: "", name: "none" }, ...data.menus]}
                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("menuID", event.target.value, search, setSearch, "")}
                            field="name"
                        /> */}
                        </Grid>
                    </Grid>
                </Grid>

                {data.itemInventory !== null &&
                    <CustomGrid sx={mainAreaTable()} container={false}>
                        <TabContext value={value}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <CustomTabs
                                        initValue={value}
                                        instances={[
                                            { name: t('inventory.tabs.items'), value: "items" },
                                            { name: t('inventory.tabs.offers'), value: "offers" },
                                        ]}
                                        setValue={setValue}
                                    />
                                </Grid>

                                <TabPanel value={"items"} sx={{ paddingTop: "0px" }}>
                                    <Grid item xs={12} md={12}>
                                        <MUIDataTable
                                            data={data.itemInventory.items}
                                            columns={table_columns}
                                            options={{
                                                download: false,
                                                print: false,
                                                rowHover: false,
                                                selectableRows: "none",
                                                filter: true,
                                                onFilterChange: (changedColumn, filterList, type, index) => {
                                                    // console.log(`Filter changed in column: ${changedColumn}`);
                                                    // console.log(`Current filter list:`, filterList);
                                                    // console.log(`Type of filter change: ${type}`);
                                                    // console.log(`Index of changed column: ${index}`);
                                                    if (type === "reset") { onFilterChange(null) }
                                                    else
                                                        onFilterChange({
                                                            field: changedColumn,
                                                            value: filterList[index][0],
                                                            index: index
                                                        })
                                                },
                                            }}
                                        />
                                    </Grid>
                                </TabPanel>


                                <TabPanel value={"offers"} sx={{ paddingTop: "0px" }}>
                                    <Grid item xs={12} md={12}>
                                        <MUIDataTable
                                            data={data.itemInventory.offers}
                                            columns={table_columns_offers}
                                            options={{
                                                download: false,
                                                print: false,
                                                rowHover: false,
                                                selectableRows: "none",
                                                filter: true,
                                                onFilterChange: (changedColumn, filterList, type, index) => {
                                                    // console.log(`Filter changed in column: ${changedColumn}`);
                                                    // console.log(`Current filter list:`, filterList);
                                                    // console.log(`Type of filter change: ${type}`);
                                                    // console.log(`Index of changed column: ${index}`);
                                                    if (type === "reset") { onFilterChange(null) }
                                                    else
                                                        onFilterChange({
                                                            field: changedColumn,
                                                            value: filterList[index][0],
                                                            index: index
                                                        })
                                                },
                                            }}
                                        />
                                    </Grid>
                                </TabPanel>
                            </Grid>

                        </TabContext>
                        <Fab color="primary"
                            aria-label="add"
                            sx={fabStyle()}
                            onClick={data.isAdd ? addItemsInventory : editItemsInventory}
                        >
                            <SaveIcon />
                        </Fab>
                    </CustomGrid>
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={6000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default ItemInventoryComponent;