import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Divider,
    Grid,
    InputLabel,
    Tooltip
} from '@mui/material';
import { faCreditCard, faLeaf, faMoneyBill } from 'assets/fontAwesome/FontAwesomeIcons';
import { EditIcon, MessageIcon, PercentIcon, PlaceIcon, ReceiptIcon, ReviewsIcon, ShoppingCartIcon } from 'assets/mui/MuiIcons';
import CheckoutEquipmentPopover from 'components/Popover/CheckoutEquipmentPopover';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { mainTagSearchItemOrderStatusIcon, orderButton, orderCartTitleTextStyle, orderDiscountTextStyle, orderDiscountTextsStyle, orderDiscountValueTextStyle, orderFontAwesomeIconStyle, orderHeaderTextStyle, orderIconStyle, orderItemFontAwesomeIconStyle, orderItemMuiIconStyle, orderItemPriceTextStyle, orderItemTaxPriceTextStyle, orderItemTaxTextStyle, orderItemTotalPriceTextStyle, orderItemTotalTextStyle, orderReviewGridStyle, orderTextStyle, pastOrderDateTextStyle } from 'themes/defaultThemes';
import { dateTimeFormatter } from 'utils/functions';
import { getAllergies } from 'utils/functions/allergy';
import { findCurrency, priceTextWithCurrency } from 'utils/functions/order';
import CustomSinglePastOrderCardItem from './CustomSinglePastOrderCardItem';
import CheckoutOrderStatusPopover from 'components/Popover/CheckoutOrderStatusPopover';
import { orderActionCellButtonsFormatterEdit } from 'utils/table';
import CustomIconButton from 'components/Button/CustomIconButton';
import OrderTypePopover from 'components/Popover/OrderTypePopover';
import CustomRatingCardCustomer from 'components/Grid/feedback/CustomRatingCardCustomer';

/**
 * The CustomSinglePastOrderCardEdit, that display each customer past order (EDIT).
 * 
 * @param {object} order the past order details
 * @param {fucntion} handlePastOrderAddFeedbackClick Callback that handles redirects past order add feedback page.
 *
 * @returns {JSX.Element} A React component that renders a past order.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSinglePastOrderCardEdit({ order, equipment, equipments, statuses, outlet, rating,
    equipmentAnchorEl, updateEquipmentOpenCallback, updateEquipmentCloseCallback, updateEquipmentCallback, openEquipment, handleInputChange,
    changeOrderStatus, orderStatusAnchorEl, orderStatus, updateOrderStatusOpenCallback, updateOrderStatusCloseCallback, updateOrderStatusValue, updateOrderStatusCallback, openOrderStatus,
    orderTypeAnchorEl, openOrderType, orderType, updateOrderTypeCloseCallback, updateOrderTypeOpenCallback, types, updateOrderTypeCallback
}) {
    const { t } = useTranslation();


    // ==================================== RENDER
    return (
        <>
            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={4} md={3} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={`${t('label.id')}:`} sx={orderHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={8} md={9} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={order.id} sx={pastOrderDateTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={4} md={3} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={`${t('label.dateCreated')}:`} sx={orderHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={8} md={9} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={dateTimeFormatter(order.dateCreated, "DD/MM/yyyy - HH:mm")} sx={pastOrderDateTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={4} md={3} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={`${t('cart.orderType.label')}:`} sx={orderHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={8} md={9} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="body2" text={`${t(`cart.orderType.${order.type}`)}`} sx={orderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={8} md={8} sx={{ display: "flex" }}>
                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateOrderTypeOpenCallback} /></Tooltip>
                                        <OrderTypePopover
                                            anchorEl={orderTypeAnchorEl}
                                            open={openOrderType}
                                            handleClose={updateOrderTypeCloseCallback}
                                            inputValue={orderType}
                                            handleUpdate={updateOrderTypeCallback}
                                            types={types}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={4} md={3} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={`${t('order.status')}:`} sx={orderHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={8} md={9} sx={{ alignSelf: "center" }}>

                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="body2" text={order.status} sx={orderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={8} md={8} sx={{ display: "flex" }}>
                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateOrderStatusOpenCallback} /></Tooltip>
                                        <CheckoutOrderStatusPopover
                                            anchorEl={orderStatusAnchorEl}
                                            open={openOrderStatus}
                                            orderStatus={orderStatus}
                                            handleClose={updateOrderStatusCloseCallback}
                                            inputValue={order?.status}
                                            handleInputChange={updateOrderStatusValue}
                                            handleUpdate={updateOrderStatusCallback}
                                            statuses={statuses}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid directioon="column" gap={2} rowSpacing={2} sx={{ justifyContent: "center", width: "100%" }}>
                            {orderActionCellButtonsFormatterEdit(changeOrderStatus, order).map((button) =>
                                <CustomIconButton
                                    key={`${button.key}`}
                                    onClick={(event) => button.onClick(event, order.id, undefined)}
                                    icon={button.icon}
                                    backgroundColor={"#e8eeef"}
                                    variant="outlined"
                                    disabled={button.disabled}
                                    iconSx={mainTagSearchItemOrderStatusIcon()}
                                    sx={{ ...orderButton(), width: "auto", margin: "3px" }}
                                    isFocused={order.status === button.key}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <PlaceIcon sx={orderIconStyle} />
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.equipmentLabel')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="body2" text={equipment?.label} sx={orderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={8} md={8} sx={{ display: "flex" }}>
                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateEquipmentOpenCallback} /></Tooltip>
                                        <CheckoutEquipmentPopover
                                            anchorEl={equipmentAnchorEl}
                                            open={openEquipment}
                                            handleClose={updateEquipmentCloseCallback}
                                            inputValue={equipment?.label}
                                            handleInputChange={handleInputChange}
                                            handleUpdate={updateEquipmentCallback}
                                            equipments={equipments}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <ShoppingCartIcon sx={orderIconStyle} />
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.orderTotal')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={`${findCurrency(order?.outlet?.currency)} ${order?.totalAmmount}`} sx={orderTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {order?.tipAmount > 0 &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={12} md={12} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" te xt={t('order.orderTotal')} sx={orderHeaderTextStyle()} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <CustomTypography variant="body2" text={`${findCurrency(order?.outlet?.currency)} ${order.tipAmount}`} sx={orderTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Grid item xs={12} md={12} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        {order?.paymentMethod === "cash" &&
                            <FontAwesomeIcon key={"icon-cash"} icon={faMoneyBill} style={orderFontAwesomeIconStyle()} />
                        }
                        {order?.paymentMethod === "card" &&
                            <FontAwesomeIcon key={"icon-card"} icon={faCreditCard} style={orderFontAwesomeIconStyle()} />
                        }
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.paymentMethod')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={order?.paymentMethod} sx={orderTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {order?.hasGivenFeedback &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={2} md={2}>
                            <ReviewsIcon sx={orderIconStyle} />
                        </Grid>

                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                            {rating !== null &&
                                <CustomRatingCardCustomer rating={rating} ratingStyle={outlet?.ratingStyle} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }

            {/* {(!order?.hasGivenFeedback && order.status === "DONE") &&
                    TODO: send reminder
            } */}

            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

            <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                <Grid container gap={1}>
                    <Grid item xs={12} md={12}>
                        <InputLabel sx={orderCartTitleTextStyle()}>{t('customer.view.tabs.orders.order.cart')}</InputLabel>
                    </Grid>

                    {order?.cart?.map((cartItem) =>
                        <Grid item xs={12} md={12}>
                            <CustomSinglePastOrderCardItem cartItem={cartItem} currency={order?.outlet?.currency} />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {order?.pvcRecyclingTax > 0 &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <FontAwesomeIcon key={"icon-env-tax"} icon={faLeaf} style={orderItemFontAwesomeIconStyle()} />
                        </Grid>

                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.pvcRecyclingTax')} sx={orderItemTaxTextStyle()} />
                        </Grid>

                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={priceTextWithCurrency(order?.outlet?.currency, order?.pvcRecyclingTax)} sx={orderItemTaxPriceTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }


            {order?.discount?.loyalty || order?.discount?.coupon &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <PercentIcon sx={orderItemMuiIconStyle} />
                        </Grid>

                        <Grid item xs={10} md={10}>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.discount')} sx={orderDiscountTextStyle()} />
                                </Grid>

                                {order?.discount?.loyalty?.discountPercentage > 0 &&
                                    <Grid item xs={12} md={12}>
                                        <Grid container>
                                            <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                                <CustomTypography variant="body2" text={`${t('customer.view.tabs.orders.order.loyalty')} (${order?.discount?.loyalty?.name}):`} sx={orderDiscountTextsStyle()} />
                                            </Grid>

                                            <Grid item xs={2} md={2}>
                                                <CustomTypography variant="body2" text={`${order?.discount?.loyalty?.discountPercentage} %`} sx={orderDiscountValueTextStyle()} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {order?.discount?.coupon?.value > 0 &&
                                    <Grid item xs={12} md={12}>
                                        <Grid container>
                                            <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                                <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.coupon')} sx={orderDiscountTextsStyle()} />
                                            </Grid>
                                            <Grid item xs={2} md={2}>
                                                <CustomTypography variant="body2" text={priceTextWithCurrency(order?.outlet?.currency, order?.discount?.coupon?.value)} sx={orderItemTotalPriceTextStyle()} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Divider light flexItem sx={{ borderRightWidth: 5, width: "80%", marginLeft: "11%", marginTop: "20px" }} />

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <ReceiptIcon sx={orderItemMuiIconStyle} />
                    </Grid>

                    <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.paymentTotal')} sx={orderItemTotalTextStyle()} />
                    </Grid>

                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={priceTextWithCurrency(order?.outlet?.currency, order?.totalAmmount)} sx={orderItemTotalPriceTextStyle()} />
                    </Grid>
                </Grid>
            </Grid>

            {order?.comments?.length > 0 &&
                <Grid item xs={12} md={12} sx={orderReviewGridStyle()}>
                    <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                        <Grid item xs={2} md={2}>
                            <MessageIcon sx={orderIconStyle} />
                        </Grid>

                        <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={order?.comments} sx={orderItemPriceTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }


            {order?.allergies?.length > 0 &&
                <>
                    <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

                    <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                        <Grid container gap={1}>
                            <Grid item xs={12} md={12}>
                                <InputLabel sx={orderCartTitleTextStyle()}>{t('customer.view.tabs.orders.order.allergies')}</InputLabel>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                {getAllergies(order?.allergies)}
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
}

export default CustomSinglePastOrderCardEdit;