import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to create a new items inventory.
 * @param {*} data A JSON object which contains new items inventory infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}item/inventory`, data);
}

/**
 * The rest request which attempts to update an existing items inventory.
 * @param {*} data A JSON object which contains the updated infromation of the items inventory
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}item/inventory`, data);
}

/**
 * The rest request which attempts to get an existing items inventory information.
 * 
 * @returns an object with an attribute containing the current infromation of the item
 */
export async function fetchOneInventory(search) {
    let data = {};
    if (search.organizationID) {
        data.organizationID = search.organizationID;
    }
    if (search.outletID) {
        data.outletID = search.outletID;
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}item/inventory`, data));
    else return await request.get(includeParamsToUrl(`${API}item/inventory`));
}
const inventoryApi = {
    fetchOneInventory,
    create,
    update
}

export default inventoryApi;