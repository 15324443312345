import { pdaActionTypes } from "./pdaActionTypes";

export const updatePdaData = (data) => ({
    type: pdaActionTypes.UPDATE_PDA_DATA,
    payload: { data }
});

export const updatePdaAll = (data, tabValue) => ({
    type: pdaActionTypes.UPDATE_PDA_ALL,
    payload: { data, tabValue }
});

export const updatePdaTabValue = (tabValue) => ({
    type: pdaActionTypes.UPDATE_PDA_TAB_VALUE,
    payload: { tabValue }
});

export const updatePdaRefetchData = (refetchData) => ({
    type: pdaActionTypes.UPDATE_PDA_REFETCH_DATA,
    payload: { refetchData }
});
