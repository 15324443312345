import React, { Suspense, useEffect, useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";

import { mainArea } from "themes/defaultThemes";
import CustomGrid from "components/Grid/CustomGrid";
import formatText from "utils/formatText";

import RatingMultipleChoice from "components/Rating/RatingMultipleChoice";
import RatingFreeText from "components/Rating/RatingFreeText";
import Rating_1_To_5 from "components/Rating/Rating_1_To_5";
import RatingBoolean from "components/Rating/RatingBoolean";

function FeedbackFormPreview({ categories }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData((d) =>
      categories.map((c) => {
        c.questions = c.questions.map((q) => {
          let existingCat = d.find((x) => x.id === c.id);
          if (!existingCat) q.answer = "";
          let existingQues = existingCat?.questions.find((x) => x.id === q.id);
          if (!existingQues) q.answer = "";
          else {
            if (q.type === "multipleChoice") {
              if (q.allowMultipleChoices) q.answer = [];
              else q.answer = "";
            } else {
              q.answer = existingQues.answer;
            }
          }

          return q;
        });
        return c;
      })
    );
  }, [categories]);

  const onChange = (updatedQuestion, categoryId, questionId) => {
    setData((d) =>
      d.map((c) => {
        if (c.id === categoryId) {
          c.questions = c.questions.map((q) => {
            if (q.id === questionId) {
              return updatedQuestion;
            }
            return q;
          });
        }
        return c;
      })
    );
  };
  return (
    <CustomGrid
      container
      direction='column'
      sx={{ ...mainArea(), marginTop: 0 }}
    >
      {/* categories */}
      {data
        //.filter((item) => item.active)
        .map((item, i) => (
          <Grid
            item
            key={item.id}
            sx={{ marginTop: i === 0 ? 0 : "15px", width: "100%" }}
          >
            <Typography
              variant='h5'
              align='left'
              sx={{ color: "#000", fontWeight: "700" }}
            >
              {item.name.en}
            </Typography>

            {/* questions */}
            {item?.questions
              .filter((question) => question.active)
              .map((question, questionIndex) => {
                // const InputComponent = React.lazy(() =>
                //   import(`./types/${question.type}`)
                // );

                return (
                  <Grid
                    container
                    direction='column'
                    key={question.id}
                    sx={{ marginTop: "10px" }}
                  >
                    {/* question */}
                    <Grid item sx={{ width: "100%" }}>
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: "#000",
                          fontWeight: "500",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: formatText(
                            `${question.question.en}${question.required === true
                              ? "<span style='color:red; font-weight: 900;'>*</span>"
                              : ""
                            }`
                          ),
                        }}
                      />
                    </Grid>
                    {/* answer */}
                    <Grid item sx={{ width: "100%", marginTop: "7px" }}>
                      {/*
                      <Suspense fallback={<div>Loading...</div>}>
                         <InputComponent
                          question={question}
                          onChange={(updatedQuestion) => {
                            setData((d) =>
                              d.map((c) => {
                                if (c.id === item.id) {
                                  c.questions = c.questions.map((q) => {
                                    if (q.id === question.id) {
                                      return updatedQuestion;
                                    }
                                    return q;
                                  });
                                }
                                return c;
                              })
                            );
                          }}
                        /> 
                      </Suspense>
                        */}

                      {question.type === "Boolean" && (
                        <RatingBoolean
                          question={question}
                          onChange={(updatedQuestion) =>
                            onChange(updatedQuestion, item.id, question.id)
                          }
                        />
                      )}
                      {question.type === "multipleChoice" && (
                        <RatingMultipleChoice
                          question={question}
                          onChange={(updatedQuestion) =>
                            onChange(updatedQuestion, item.id, question.id)
                          }
                        />
                      )}
                      {question.type === "FreeText" && (
                        <RatingFreeText
                          question={question}
                          onChange={(updatedQuestion) =>
                            onChange(updatedQuestion, item.id, question.id)
                          }
                        />
                      )}
                      {question.type === "Rating_1_To_5" && (
                        <Rating_1_To_5
                          question={question}
                          onChange={(updatedQuestion) =>
                            onChange(updatedQuestion, item.id, question.id)
                          }
                        />
                      )}
                    </Grid>
                    {/* divider */}
                    {item.questions.length !== questionIndex + 1 && (
                      <Grid item sx={{ width: "100%", marginTop: "10px" }}>
                        <Divider />
                      </Grid>
                    )}
                  </Grid>
                );
              })}
          </Grid>
        ))}
    </CustomGrid>
  );
}

export default FeedbackFormPreview;