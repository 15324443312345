import { request } from 'constants/alias';
import { API } from "constants/config";

/**
 * The rest request which attempts to fetch the viva payment url 
 * 
 * @param search 
 * 
 * @returns an object with an attribute containing the vat details
 */
export async function getPaymentUrl(subDetails) {
    return await request.post(`${API}vivaWallet/payment/subscription`, subDetails);
}

/**
 * The rest request which attempts to fetch the viva payment url 
 * 
 * @param search 
 * 
 * @returns an object with an attribute containing the vat details
 */
export async function getPaymentUrlReccurent(subDetails) {
    return await request.post(`${API}vivaWallet/payment/subscription/recurrent`, subDetails);
}

export async function checkForVerifiedPayment(transactionId) {
    return await request.get(`${API}checkForVerifiedPayment/${transactionId}`);
}

export async function checkForVerifiedPaymentSubscriptionRecurrent(transactionId) {
    return await request.get(`${API}vivaWallet/payment/subscription/check/${transactionId}/recurrent`);
}

export async function checkForVerifiedPaymentSubscription(transactionId) {
    return await request.get(`${API}vivaWallet/payment/subscription/check/${transactionId}`);
}

const vivaPaymentApi = {
    getPaymentUrl,
    getPaymentUrlReccurent,
    checkForVerifiedPayment,
    checkForVerifiedPaymentSubscription,
    checkForVerifiedPaymentSubscriptionRecurrent
}

export default vivaPaymentApi;