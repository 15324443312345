import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Drawer,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

export default function LeftSider({
  drawerWidth,
  open,
  Items,
  cardWidth,
  showParent,
  ParentData,
  organizationID,
  showItemsImages,
}) {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [hideUnused, setHideUnused] = useState(true);
  const usedIds = ParentData.filter((x) => x.name?.en !== "default").flatMap(
    (pd) => pd.itemIds.map((parentSubItem) => parentSubItem?.id)
  );

  return (
    <Drawer
      sx={{
        //width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? drawerWidth : 0,
          boxSizing: "border-box",
          pb: "100px",
          position: "sticky",
          //height: "100vh"
        },
      }}
      variant='persistent'
      anchor='left'
      open={open}
    >
      <Grid item sx={{ width: "100%", mt: "10px", px: "20px" }}>
        <Grid container alignItems='center' gap='10px'>
          <Grid item sx={{ flex: 1 }}>
            <TextField
              fullWidth
              placeholder='Search by name'
              variant='outlined'
              size='small'
              //sx={{ width: cardWidth }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>

          <Grid item>
            <Tooltip
              title={
                hideUnused
                  ? showParent
                    ? t('menus.cms.showUsedCategories')
                    : t('menus.cms.showUsedItems')
                  : showParent
                    ? t('menus.cms.hideUsedCategories')
                    : t('menus.cms.hideUsedItems')
              }
            >
              <IconButton
                sx={{ p: 0 }}
                onClick={() => setHideUnused((h) => !h)}
              >
                {hideUnused ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Draggable
        key={"Items"}
        draggableId={"Items"}
        index={0}
        isDragDisabled={true}
      >
        {(provided, snapshot) => (
          <Grid
            container
            direction='column'
            sx={{ mt: "10px", px: "20px" }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Droppable isDropDisabled droppableId={"Items"} type={"Categories"}>
              {(dropProvided, dropSnapshot) => (
                <div
                  {...dropProvided.droppableProps}
                  ref={dropProvided.innerRef}
                >
                  {Items.map((subCategory, i) => {
                    let itemUsed = usedIds.includes(subCategory.id);
                    return (
                      <Draggable
                        key={subCategory.id}
                        draggableId={subCategory.id}
                        index={i}
                      >
                        {(dragProvided, dragSnapshot) => (
                          <Grid
                            item
                            sx={{
                              width: "100%",
                              mt: "10px",
                              display:
                                hideUnused && itemUsed
                                  ? "none"
                                  : subCategory.name.en
                                    ?.toLowerCase()
                                    ?.includes(search.toLowerCase())
                                    ? "block"
                                    : "none",
                            }}
                            key={i}
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                          >
                            <Grid
                              container
                              component={Paper}
                              style={{ padding: "4px 10px", width: cardWidth }}
                            >
                              <Grid item>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {showParent ? (
                                    !showItemsImages ? null : subCategory.photo ? (
                                      <img
                                        src={
                                          subCategory.image
                                            ? subCategory.photo.imageSrc
                                            : `${process.env.REACT_APP_IMAGE_HOST}/${organizationID}/category/${subCategory.id}/${subCategory.photo.imageSrc}`
                                        }
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={"/dev/category.svg"}
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    )
                                  ) : showItemsImages ? (
                                    subCategory.photo ||
                                      subCategory?.photos?.length > 0 ? (
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_HOST}/${organizationID}/item/${subCategory.id}/${subCategory.photo ? subCategory.photo.imageSrc : subCategory.photos.find((x) => x.cover === true).imageSrc}`}
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={"/dev/item.svg"}
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    )
                                  ) : null}
                                  <Typography variant='body2'>
                                    {subCategory.name.en}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item> </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    );
                  })}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
        )}
      </Draggable>
    </Drawer>
  );
}
