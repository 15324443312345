import { useLocation } from "react-router-dom";
import CustomGrid from '../../components/Grid/CustomGrid';
import { mainTag } from '../../themes/defaultThemes';
import useDocumentTitle from "../../utils/useDocumentTitle";

/**
 * The TestComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function TestComponent() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const token = params.get("token")

    //change document title
    useDocumentTitle(`Usee | Test`);

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="test" sx={mainTag()}>
            <p>Token: {token}</p>
        </CustomGrid>
    );
}

export default TestComponent;