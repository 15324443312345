import { Fab, Grid, Tooltip } from "@mui/material";
import organizationApi from "api/organization";
import { AddIcon } from "assets/svg/SvgIcons";
import CustomRefreshButton from "components/Button/CustomRefreshButton";
import CustomGrid from "components/Grid/CustomGrid";
import ListPageSkeleton from "components/Loading/ListPageSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from "components/Title/CustomTitle";
import { getDateFormatter } from "constants/calendar";
import { getDefaultSortDirectionData } from "constants/sortDirection";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagTitle } from "themes/defaultThemes";
import { formValChangeWithParentElementWithNameAndValue } from "utils/form-validation";
import { prepareTablePaginationSortParams } from "utils/functions";
import { getLanguageFromURL } from "utils/language";
import { dateFormatter, fieldFormatter, iconFormatter, idFormatterLinkWithParent } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The Organization List component to view the organizations of the system.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OrganizationListComponent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    //change document title
    useDocumentTitle(`Usee | ${t('org.title')}`)

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {object} organizations the organizations (pageable)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        organizations: null,
        pagination: {
            count: -1,
            size: 10,
            page: 0,
            ...getDefaultSortDirectionData
        }
    });

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // for search
    const [search, setSearch] = useState({
        field: "name",
        name: "",
        vatNumber: ""
    });

    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        orgID: "",
        name: ""
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getOrganizations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getOrganizations();
        setIsFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getOrganizations();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
    }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])

    /**
     * The rest endpoint to get the organizations list.
     * @property {number} page the list page number
     */
    function getOrganizations(page) {
        organizationApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
            setIsLoaded(true);
            setData({
                ...data,
                userAuth: r.data.returnobject.userAuth,
                organizations: r.data.returnobject.page,
                pagination: {
                    ...data.pagination,
                    count: r.data.returnobject.page.totalPages,
                    size: r.data.returnobject.page.size,
                    page: r.data.returnobject.page.number
                }
            });
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Gets called to activate the selected organization
     * @param {*} event
     * @param {string} id the id of the organization
     */
    function activateOrganization(event, id) {
        organizationApi.activate(id).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getOrganizations();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Gets called to activate the selected organization
     * @param {*} event
     * @param {string} id the id of the organization 
     */
    function disableOrganization(event, id) {
        organizationApi.disable(id).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getOrganizations();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that triggers the multi select input value change.
     * @param {*} event 
     */
    function onSelectSearchChange(event) {
        let field = event.target.value;
        setSearch({
            ...search,
            field: field,
            name: null,
            vatNumber: "",
            [field]: ""
        });
    }
    /**
     * Function that handles the snackbar open or close state.
     * 
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the pagination information.
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(page) {
        formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
    }

    /**
     * Function that handles the pagination information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        let pagination = data.pagination;
        pagination.size = size;
        pagination.page = 0;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }

    /**
     * Function that handles the sort information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <ListPageSkeleton />
    } else {
        /**
         * datatable columns.
         */
        const table_columns = [
            {
                name: "active",
                label: t('label.isActive'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return iconFormatter(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            {
                                enable: (event, rowIndex) => activateOrganization(event, rowIndex),
                                disable: (event, rowIndex) => disableOrganization(event, rowIndex),
                                positionOfId: 1
                            }
                        );
                    }
                }
            },
            {
                name: "id",
                label: t('label.id'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], `/${getLanguageFromURL()}/organization`);
                    }
                }
            },
            {
                name: "name",
                label: t('label.name'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.name')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value?.en;
                    }
                }
            },
            {
                name: "vatNumber",
                label: t('label.vatNumber'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.vatNumber')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value;
                    }
                }
            },
            {
                name: "counters",
                label: t('label.emails'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return fieldFormatter(value?.email);
                    }
                }
            },
            {
                name: "counters",
                label: t('label.smss'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return fieldFormatter(value?.sms);
                    }
                }
            },
            {
                name: "counters",
                label: t('label.ticketCounter'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return fieldFormatter(value?.receiptTicket);
                    }
                }
            },
            {
                name: "counters",
                label: t('label.receiptTicketCounter'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return fieldFormatter(value?.receiptTicket);
                    }
                }
            },
            {
                name: "dateCreated",
                label: t('label.dateCreated'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateFormatter());
                    }
                }
            }
        ];

        return (
            <CustomGrid role="main" id="organization-list" sx={mainTag()}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={"auto"}>
                        <CustomRefreshButton onClick={getOrganizations} marginTop="1.5%" />
                    </Grid>

                    <Grid item xs={11} sm={3}>
                        <CustomGrid sx={mainTagTitle()}>
                            <CustomTitle
                                title={t('org.title')}
                                subtitle={t('org.subtitle')}
                            />
                        </CustomGrid>
                    </Grid>

                    <CustomGrid sx={mainAreaTable()} container={false}>
                        <CustomPageableTable
                            instances={data.organizations}
                            emptyMessage={t('org.emptyMessage')}
                            table_columns={table_columns}
                            keyField="id"
                            defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                            pagination={data.pagination}
                            handlePaginationChange={handlePaginationChange}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            handleColumnSortChange={handleColumnSortChange}
                            filter={true}
                            onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                                ...search,
                                name: "",
                                vatNumber: ""
                            })}
                        />
                    </CustomGrid>


                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={() => navigate(`/${getLanguageFromURL()}/organization/add`)}
                    >
                        <Tooltip arrow title={t('org.addOrganizationLabel')} sx={fabTooltipStyle()}>
                            <AddIcon {...fabStyleIcon()} />
                        </Tooltip>
                    </Fab>
                </Grid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}


export default OrganizationListComponent;