import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import CustomFlagTextField from "components/Text/CustomFlagTextField";
import en from "assets/images/flags/en.png";
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";
import { useTranslation } from "react-i18next";
import CustomFlagTextArea from "components/Text/CustomFlagTextArea";
import { getTranslationDefaultObject } from "constants/defaultObjects";

const translations = getTranslationDefaultObject();
export default function FeedbackPopup({ open, setOpen, onSubmit, editData }) {
  const { t } = useTranslation();

  const intitialData = {
    redeemPoints: 0,
    name: translations,
    description: translations,
  };
  const [data, setData] = useState(intitialData);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState();
  const [dialog, setDialog] = useState({
    isOpen: false,
    message: t("label.configureTranslations"),
    id: "",
    field: "",
    translations: {},
  });

  useEffect(() => {
    if (open && editData && Object.keys(editData).length > 0) {
      setData(editData);
      setEdit(true);
    }
  }, [editData, open]);

  function handleDialogState(isOpen, field = "") {
    setDialog({
      ...dialog,
      isOpen: isOpen,
      field: field,
      translations: data[field],
    });
  }

  function configureTranslations(event, field) {
    handleDialogState(true, field);
  }
  function configureItemTranslations(translations, field) {
    setData({
      ...data,
      [field]: translations,
    });
    setDialog({
      ...dialog,
      isOpen: false,
    });
  }

  const validate = () => {
    if (data.name?.en === "" || data.name?.en?.length < 4) {
      return {
        success: false,
        message: "Name length cannot be less than 3 characters",
      };
    }
    return {
      success: true,
      message: "",
    };
  };

  const submitHandler = () => {
    ///call api and do error handling
    let vali = validate();
    if (vali.success === false) {
      setError(vali.message);
      return;
    } else {
      setError();
    }
    onSubmit(data);
    setData(intitialData);
  };

  const inputSx = {
    mt: "4px",
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.13)",
    },
  };

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        {dialog.isOpen && (
          <CustomTranslationsDialog
            isOpen={dialog.isOpen}
            id='translations'
            title={t("actions.translationModal.title")}
            action={configureItemTranslations}
            handleOpen={handleDialogState}
            translations={data[dialog.field]}
            field={dialog.field}
          />
        )}

        <div style={{ position: "absolute", top: 5, right: 5 }}>
          <IconButton sx={{ p: 0 }} onClick={() => setOpen(false)}>
            <CloseOutlined fontSize='small' sx={{ fill: "#000" }} />
          </IconButton>
        </div>

        <Typography
          variant='h5'
          align='center'
          sx={{ color: "#34353A", fontWeight: 700 }}
        >
          {edit
            ? t("feedback.addUpdateDialog.update")
            : t("feedback.addUpdateDialog.add")}
        </Typography>

        <Grid container direction='column'>
          {/* name */}
          <Grid item sx={{ width: "100%", mt: "20px" }}>
            <Typography variant='body2'>
              {t("feedback.addUpdateDialog.name")}
            </Typography>

            <CustomFlagTextField
              country='en'
              flag={en}
              placeholder=''
              name='data.name.en'
              id='name'
              defaultValue={
                data !== null && data.name !== null ? data.name.en : ""
              }
              onTranslationSelect={configureTranslations}
              field='name'
              value={data.name.en}
              onChange={(e) =>
                setData((d) => {
                  return {
                    ...d,
                    name: {
                      ...d.name,
                      en: e.target.value,
                    },
                  };
                })
              }
              sx={{ mt: "4px" }}
              otherProps={{
                inputProps: {
                  maxLength: 20,
                },
              }}
            //helperText={isError.name}
            //error={isError.name.length > 0}
            />
          </Grid>

          {/* description */}
          <Grid item sx={{ width: "100%", mt: "20px" }}>
            <Typography variant='body2'>
              {t("feedback.addUpdateDialog.description")}
            </Typography>

            <CustomFlagTextArea
              country='en'
              flag={en}
              placeholder=''
              name='data.description.en'
              id='description'
              value={data.description.en}
              onChange={(e) =>
                setData((d) => {
                  return {
                    ...d,
                    description: {
                      ...d.name,
                      en: e.target.value,
                    },
                  };
                })
              }
              className='form-control'
              defaultValue={
                data.description !== null ? data.description.en : ""
              }
              onTranslationSelect={configureTranslations}
              field='description'
              width='99.5%'
              sx={{ mt: "4px" }}
            />
          </Grid>

          {/* redeemPoints */}
          <Grid item sx={{ width: "100%", mt: "20px" }}>
            <Typography variant='body2'>
              {t("feedback.addUpdateDialog.redeemPoints")}
            </Typography>
            <TextField
              fullWidth
              variant='outlined'
              sx={inputSx}
              value={data.redeemPoints}
              onChange={(e) => {
                if (!e.target.value) {
                  setData((d) => {
                    return {
                      ...d,
                      redeemPoints: "",
                    };
                  });
                }
                if (!isNaN(e.target.value) && /^[0-9]+$/.test(e.target.value)) {
                  setData((d) => {
                    return {
                      ...d,
                      redeemPoints: e.target.value,
                    };
                  });
                }
              }}
            />
          </Grid>

          {error && (
            <Typography
              variant='body2'
              sx={{ mt: "5px", color: "red", fontWeight: 500 }}
            >
              {error}
            </Typography>
          )}

          {/* buttons */}
          <Grid item sx={{ width: "100%", mt: "20px" }}>
            <Grid container wrap='nowrap' justifyContent='flex-end' spacing={2}>
              <Grid item>
                <Button
                  fullWidth
                  size='large'
                  variant='outlined'
                  sx={{
                    border: "1px solid #D0D5DD",
                    background: "transparent",
                    boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                    color: "#344054",
                  }}
                  onClick={() => {
                    setOpen(false);
                    setError();
                    setData(intitialData);
                  }}
                >
                  {t("feedback.addUpdateDialog.cancel")}
                </Button>
              </Grid>
              
              <Grid item>
                <Button
                  fullWidth
                  variant='contained'
                  size='large'
                  onClick={submitHandler}
                // onClick={edit ? editHandler : submitHandler}
                >
                  {/* {loading?.active && loading?.action === "submit" && (
                        <CircularProgress
                          size='1rem'
                          color='inherit'
                          sx={{ marginRight: "10px" }}
                        />
                      )} 
                  */}
                  {t("feedback.addUpdateDialog.submit")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
