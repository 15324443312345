import { orderActionTypes } from "./orderActionTypes";

export const updateOrderData = (data) => ({
    type: orderActionTypes.UPDATE_ORDER_DATA,
    payload: { data }
});

export const updateOrderDataPagination = (data) => ({
    type: orderActionTypes.UPDATE_ORDER_DATA_PAGINATION,
    payload: { data }
});

export const updateOrderSearch = (search, refetchData = true) => ({
    type: orderActionTypes.UPDATE_ORDER_SEARCH,
    payload: { search, refetchData }
});

export const updateOrderAll = (data, search, refetchData) => ({
    type: orderActionTypes.UPDATE_ORDER_ALL,
    payload: { data, search, refetchData }
});

export const updateOrderRefetchData = (refetchData) => ({
    type: orderActionTypes.UPDATE_ORDER_REFETCH_DATA,
    payload: { refetchData }
});

