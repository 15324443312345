import ReactApexChart from 'react-apexcharts';

/**
 * The Pie Chart Component. The pie chart accepts a single dimensional series.
 * Pie Charts are instrumental visualization tools useful in expressing data and 
 * information in terms of percentages, ratios. A pie chart is most effective when 
 * dealing with a small collection of data. It is constructed by clustering all the 
 * required data into a circular shaped frame wherein the data are depicted in slices.
 * The pie/donut slices can be crafted by patterns/gradients/images for increasing the 
 * visual appeal.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function PaymentPieChart({ percentage, height, series, title, subtitle, labels, currency }) {
  const chartData = {
    options: {
      labels: labels,
      series: series,
      height: height,
      plotOptions: {
        pie: {
          offsetX: 0,
          offsetY: 0,
          customScale: 0.8,
          expandOnClick: true,
          dataLabels: {
            offset: 70
          }
        }
      },
      chart: {
        type: 'pie',
        height: height,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        expandOnClick: true,
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        width: '100%'
      },
      title: {
        text: title,
        align: 'center',
        style: {
          color: "#333333",
          fontSize: "18px",
          fill: "#333333",
          fontFamily: "Lumanosimo",
          fontWeight: 900,
          paddingTop: "5px",
          paddingBottom: "10px",
          marginBottom: 0
        }
      },
      subtitle: {
        text: subtitle,
        align: 'center',
        style: {
          color: "#666666",
          fontSize: "12px",
          fill: "#666666",
          fontWeight: 400
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.labels[opts.seriesIndex] + ":\n " + opts.w.globals.initialSeries[opts.seriesIndex];
        },
        style: {
          fontSize: "15px",
          fontWeight: "bold",
          colors: ["black"],
          fill: "black"
        },
        dropShadow: {
          enabled: false
        }
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={chartData.options}
        series={series}
        type="pie"
        height={height}
      />
    </div>
  );
};

export default PaymentPieChart;