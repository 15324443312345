
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Card, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import React, { useState } from 'react';
import { getFlipCardConfiguration, getLoyaltyCardProperties } from 'constants/loyaltyCardProperties';
import { getThemeColor } from 'constants/theme';
import { cardItemButtonGroupStackButton } from 'themes/defaultThemes';
import { getTheme } from 'utils/theme';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomFlipCard from 'components/Card/loyalty/CustomFlipCard';
import CustomLoyaltyCardBackSide from 'components/Card/loyalty/CustomLoyaltyCardBackSide';
import CustomLoyaltyCardFrontSide from 'components/Card/loyalty/CustomLoyaltyCardFrontSide';
import { MINIO_HOST } from 'constants/hosts';
import { getLoyaltyCategoryPhoto } from 'utils/functions/assets';


/**
 * The Custom Loyalty Card, that display customized {@link Card}.
 * 
 * @property {string} name the Checkbox name value
 * @property {string} selected If `true`, the component is checked.
 * @property {string} label the Checkbox text value
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {function} onChange Callback that triggered when value is changed
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomLoyaltyCard({ category,
    organizationID = "", logoImgSrc = "",
    cardProperties, handleDialogState, deleteCategory,
    isFlippedValue = false,
    userFullName = ""
}) {
    const flipConfiguration = getFlipCardConfiguration();
    const [isFlipped, setFlipped] = useState(isFlippedValue);

    /**
     * Callback that handles the card flip
     */
    function flipCard() {
        setFlipped(!isFlipped);
    }

    let style = {};
    if (category.backgroundType === "IMAGE") {

        let categoryBackgroundImage = !category.backgroundImage.includes("https://") ?
            getLoyaltyCategoryPhoto(organizationID, category.id, category.backgroundImage)
            : category.backgroundImage;
        style = {
            backgroundImage: `url(${categoryBackgroundImage})`,
            backgroundSize: "cover"
        }
    } else {
        if (!category.gradientBackground)
            style = {
                backgroundColor: `${category.backgroundColor1}!important`,
            }
        else
            style = {
                background: `linear-gradient(to right bottom, ${category.backgroundColor1}, ${category.backgroundColor2})!important`,
            }
    }

    return (
        <Grid>
            {userFullName === "" &&
                <Stack direction="row" sx={{ right: 0, top: 0, position: "relative", justifyContent: "right", zIndex: 10 }} key={`stack-${category.id}`}>
                    <CustomIconButton
                        keyValue={`btn-edit-${category.id}`}
                        onClick={() => handleDialogState(true, category.id, false)}
                        icon={<EditIcon
                            style={{ marginRight: "0px" }} />}
                        className="icon-wrapper tr link"
                        iconSx={{ marginRight: "0px!important" }}
                        sx={{
                            ...cardItemButtonGroupStackButton(),
                            backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColor"]
                        }}
                    >
                        {t('actions.edit')}
                    </CustomIconButton>
                    <CustomIconButton
                        keyValue={`btn-delete-${category.id}`}
                        onClick={(event) => deleteCategory(event, category.id, category.name.en)}
                        icon={<DeleteIcon
                            style={{ marginRight: "0px" }} />}
                        className="icon-wrapper tr link"
                        iconSx={{ marginRight: "0px!important" }}
                        sx={{
                            ...cardItemButtonGroupStackButton(),
                            backgroundColor: "red"
                        }}
                    >
                        {t('actions.delete')}
                    </CustomIconButton>
                </Stack>
            }
            <Stack direction="row" sx={{ marginTop: "-30px", zIndex: -1 }} key={`stack-card-${category.id}`}>
                {!cardProperties.showBarcode &&
                    <CustomLoyaltyCardFrontSide
                        category={category}
                        cardProperties={cardProperties}
                        handleDialogState={handleDialogState}
                        deleteCategory={deleteCategory}
                        organizationID={organizationID}
                        logoImgSrc={logoImgSrc}
                        userFullName={userFullName}
                        sx={{
                            ...getLoyaltyCardProperties(),
                            ...style
                        }} />
                }
                {cardProperties.showBarcode &&
                    <CustomFlipCard
                        isFlipped={isFlipped}
                        flipSpeedBackToFront={flipConfiguration.flipSpeedBackToFront}
                        flipConfiguration={flipConfiguration.flipSpeedFrontToBack}
                    >
                        <CustomLoyaltyCardFrontSide
                            category={category}
                            cardProperties={cardProperties}
                            onClick={flipCard}
                            handleDialogState={handleDialogState}
                            deleteCategory={deleteCategory}
                            organizationID={organizationID}
                            logoImgSrc={logoImgSrc}
                            userFullName={userFullName}
                            sx={{
                                ...getLoyaltyCardProperties(),
                                ...style
                            }} />
                        <CustomLoyaltyCardBackSide
                            category={category}
                            cardProperties={cardProperties}
                            onClick={flipCard}
                            handleDialogState={handleDialogState}
                            deleteCategory={deleteCategory}
                            userFullName={userFullName}
                            sx={{
                                ...getLoyaltyCardProperties(),
                                ...style
                            }} />
                    </CustomFlipCard>
                }
            </Stack>
        </Grid>
    );
}

export default CustomLoyaltyCard;