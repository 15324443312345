import React, { useEffect, useRef } from "react";
import {
    Ellipse,
    Group,
    Image,
    Rect,
    Text,
    Transformer
} from "react-konva";
import useImage from "use-image";

function RenderFloorShape({
    layerRef,
    shapeWidth,
    shapePosition,
    rotation,
    renderComponent,
    availableOnline,
    id,
    type,
    shape,
    name,
    minCover,
    maxCover,
    fill,
    borderColor,
    isSelected,
    onSelect,
    onChange,
}) {
    const shapeRef = useRef();
    const trRef = useRef();
    const nameRef = useRef(null);
    const nameRefShape = useRef(null);
    const [nameTextWidth, setNameTextWidth] = React.useState(0);

    const minMaxCover = useRef(null);
    const minMaxRefShape = useRef(null);

    const [img] = useImage("/dev/online.png");

    useEffect(() => {
        //on name change this will make rechtangle equal to text length width
        // if (nameRef?.current?.textWidth) {
        setNameTextWidth(nameRef?.current?.getTextWidth() + 6);
        //}
        //on name change this will make move rechtangle to exact x position
        // if (shapeRef.current?.attrs?.x) {
        //   nameRef?.current?.parent.x(
        //     shapeRef.current?.attrs?.x - (nameRef?.current?.textWidth + 4) / 2
        //   );
        // }
        // if (shapeRef.current?.attrs?.y) {
        //   nameRef?.current?.parent?.y(
        //     type === "SHAPE"
        //       ? shapeRef.current?.attrs?.y +
        //           shapeRef?.current?.attrs?.height / 2 -
        //           10
        //       : shapeRef.current?.attrs?.y +
        //           shapeRef?.current?.attrs?.height / 2 -
        //           7
        //   );
        // }
    }, [name]);
    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    // const handleDragBoundFunc = (pos) => {
    //   const layer = layerRef.current;
    //   const rect = layer.findOne(`.${id}`);
    //   let newX = Math.max(0, Math.min(pos.x, layer.width() - rect.width()));
    //   let newY = Math.max(0, Math.min(pos.y, layer.height() - rect.height()));

    //   return { x: newX, y: newY };
    // };
    const handleDragBoundFunc = (pos) => {
        const layer = layerRef.current;
        const rect = layer.findOne(`.${id}`);

        const width = rect.width();
        const height = rect.height();
        const offsetX = width / 2;
        const offsetY = height / 2;
        const newX = Math.max(offsetX, Math.min(layer.width() - offsetX, pos.x));
        const newY = Math.max(offsetY, Math.min(layer.height() - offsetY, pos.y));
        return {
            x: newX,
            y: newY,
        };
    };

    const handleTransformBoundFunc = (oldAttrs, newAttrs) => {
        const layer = layerRef.current;

        // Restrict the size and position of the rectangle to stay within the stage bounds
        const newRectAttrs = { ...oldAttrs, ...newAttrs };
        if (
            newRectAttrs.x + newRectAttrs.width > layer.width() ||
            newRectAttrs.y + newRectAttrs.height > layer.height() ||
            newRectAttrs.x < -1 ||
            newRectAttrs.y < -1
        ) {
            return oldAttrs;
        }
        return newRectAttrs;
    };

    return (
        <React.Fragment>
            <Group
                {...shapePosition}
                {...shapeWidth}
                offset={{
                    x: shapeWidth.width / 2,
                    y: shapeWidth.height / 2,
                }}
                draggable
                dragBoundFunc={handleDragBoundFunc}
                onDragMove={(e) => {
                    //we have to move all text here as well
                    // if (nameRef?.current && shapeRef.current) {
                    //   // moving name group x-xis
                    //   nameRef.current.parent.x(
                    //     e.target.x() +
                    //       e.target.width() / 2 -
                    //       (nameRef?.current?.textWidth + 4) / 2
                    //   );
                    //   nameRef.current.parent.y(
                    //     type === "SHAPE"
                    //       ? e.target.y() + e.target.height() / 2 - 10
                    //       : e.target.y() + e.target.height() - 7
                    //   );
                    // }
                }}
                onDragEnd={(e) => {
                    const layer = layerRef.current;
                    let position = {
                        left: e.target.x(),
                        top: e.target.y(),
                    };
                    // if (shape?.includes("circle")) {
                    //   if (position.left - e.target.width() / 2 < 0) {
                    //     position.left += e.target.width() / 2;
                    //   }
                    //   if (position.top - e.target.height() / 2 < 0) {
                    //     position.top += e.target.height() / 2;
                    //   }
                    //   if (
                    //     position.left - e.target.width() / 2 + e.target.width() >
                    //     layer.width()
                    //   ) {
                    //     position.left = layer.width() - e.target.width() / 2;
                    //   }
                    //   if (
                    //     position.top - e.target.height() / 2 + e.target.height() >
                    //     layer.height()
                    //   ) {
                    //     position.top = layer.height() - e.target.height() / 2;
                    //   }
                    // }

                    onChange({
                        position: position,
                    });
                }}
                transformBoundFunc={handleTransformBoundFunc}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = e.target;

                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    const size = {
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    };
                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);

                    onChange({
                        position: {
                            left: node.x(),
                            top: node.y(),
                        },
                        rotation: node.rotation(),
                        // set minimal value
                        size: size,
                    });
                }}
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
            >
                {renderComponent({
                    x: shape?.includes("circle") ? shapeWidth.width / 2 : 0,
                    y: shape?.includes("circle") ? shapeWidth.height / 2 : 0,
                    ...shapeWidth,
                    name: id,
                    fill,
                    stroke: borderColor,
                    strokeWidth: 1,
                })}
                {/* <Rect x={0} y={0} {...shapeWidth} name={name} fill={fill} /> */}
                {availableOnline && type !== "SHAPE" && (
                    <Image
                        image={img}
                        x={shapeWidth.width / 2 - 12}
                        y={shapeWidth.height / 2 - 12}
                        width={22}
                        height={22}
                        zIndex={2}
                    />
                )}
            </Group>

            {/* to show name only */}
            <Group
                rotation={rotation}
                x={shapePosition.x}
                y={shapePosition.y - 10}
                offset={{
                    x: shapeWidth.width / 2,
                    y: shapeWidth.height / 2,
                }}
                listening={false}
                zIndex={4}
            >
                <Rect
                    ref={nameRefShape}
                    height={15}
                    width={nameTextWidth} //this does not change as state change
                    cornerRadius={5}
                    fill='#000'
                    x={shapeWidth.width / 2 - nameTextWidth / 2}
                    y={shapeWidth.height}
                />
                <Text
                    ref={nameRef}
                    x={shapeWidth.width / 2 - nameTextWidth / 2 + 3}
                    y={shapeWidth.height + 1}
                    text={name}
                    ellipsis={true}
                    //height={20}
                    align='center'
                    verticalAlign='middle'
                    fontSize={13}
                    fontFamily='Calibri'
                    fill='#fff'
                    background='#000'
                    zIndex={4}
                />
            </Group>

            {isSelected && (
                <Transformer
                    zIndex={1}
                    ref={trRef}
                    rotateEnabled={true}
                    // rotateEnabled={true}
                    // anchorCornerRadius={10}
                    // anchorStrokeWidth={2}
                    // anchorFill="#29abe2"
                    // borderDash={[5, 5]}
                    boundBoxFunc={handleTransformBoundFunc}
                />
            )}
        </React.Fragment>
    );
}


export default RenderFloorShape;