import { useDrop } from "react-dnd";

function DropFloorItem({ onDrop, children }) {
  // const [{ canDrop, isOver }, drop] = useDrop(() => ({
  //   accept: "shape",
  //   drop: (item, monitor) => {
  //     const clientOffset = monitor.getClientOffset();

  //     if (item) {
  //       onDrop(item.type, item.shape, clientOffset);
  //     }
  //   },
  //   collect: (monitor) => ({
  //     isOver: monitor.isOver(),
  //     canDrop: monitor.canDrop(),
  //   }),
  // }));

  // const isActive = canDrop && isOver;

  // return (
  //   <div ref={drop} style={{ width: "100%", height: "100%" }}>
  //     {children}
  //   </div>
  // );
}

export default DropFloorItem;
