import { Grid } from "@mui/material";
import vivaPaymentApi from "api/vivaPayment";
import { LottieSuccessAnimation } from "assets/lottie/LottieAnimations";
import { ArrowBackIcon } from "assets/mui/MuiIcons";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTypography from "components/Typography/CustomTypography";
import { lottieDefaultOptions } from "constants/animations/lottieOptions";
import Lottie from "lottie-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPaymentSuccessText, mainTag, orderBackBtnStyle } from 'themes/defaultThemes';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";
import WebFont from "webfontloader";

/**
 * The SuccessPaymentComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SuccessPaymentComponent() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { path } = useParams();
    const params = new URLSearchParams(location.search);
    const tid = params.get("tid");//transaction id

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Lumanosimo']
            }
        });

        checkForVerifiedPayment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function checkForVerifiedPayment() {
        vivaPaymentApi.checkForVerifiedPaymentSubscriptionRecurrent(tid).then((checked) => {
            if (checked)
                navigate(`/${getLanguageFromURL()}/subscription`)
            else
                console.log("fail")
            // setAlertBox({
            //     isOpen: true,
            //     message: t("subscription.paymentFailure"),
            //     backgroundColor: "#a71313"
            // });
        }).catch((e) => {
            // console.log(e);
        });
    }
    //change document title
    useDocumentTitle(t('subscription.organization.payment.success.label'));

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="test2" sx={mainTag()}>
            <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                <CustomIconButton
                    icon={<ArrowBackIcon />}
                    sx={orderBackBtnStyle()}
                    onClick={() => navigate(`/${getLanguageFromURL()}/subscription`)}
                    label={t('subscription.organization.payment.success.backButton')}
                />
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={{textAlign: "-webkit-center"}}>
                    <Lottie
                        {...lottieDefaultOptions}
                        animationData={LottieSuccessAnimation}
                        height={200}
                        width={200}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <CustomTypography variant="body2" text={t('subscription.organization.payment.success.infoPayment')} sx={getPaymentSuccessText} />
                    {/* <CartSuccess order={order} outlet={outlet}/> */}
                </Grid>
            </Grid>
        </CustomGrid>
    );
}

export default SuccessPaymentComponent;