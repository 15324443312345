import { dateTimeFormatter, dateTimeFormatterFilter } from "utils/functions";
import { getCouponDefaultBarcodeConfiguration } from "./couponCardProperties";
import { getDefaultOrderSortDirection, getDefaultSortDirectionData } from "./sortDirection";

export const getTranslationDefaultObject = () => {
    return {
        en: "",
        de: "",
        fr: "",
        it: "",
        ru: "",
        el: "",
        uk: "",
        es: "",
        nl: "",
        cs: "",
        pl: "",
        bg: "",
        ar: "",
        et: "",
        lt: "",
        ro: "",
        tr: "",
        text: ""
    }
}


export const getDayWorkingHoursDefaultObject = () => {
    return { start: null, end: null, open: true }
}


export const getDefaultPrinterObject = () => {
    return {
        organizationID: "",
        outletID: "",
        friendlyName: "",
        printNodeId: "",
        type: "PrintNode",
        printerIP: "",
        printerPort: "9100",
        paperSize: "_80mm",
        outletSections: [],
        actionsValue: [],
        includedEquipmentIds: [],
        excludedEquipmentIds: [],
        employeeIds: [],
        active: true,
        tickets: [],
        configuration: {
            name: "",
            fill: "#000000",
            borderColor: "#000000",
            canvas: {
                position: {
                    left: 0,
                    top: 0
                },
                rotation: 0,
                size: {
                    height: 0,
                    width: 0
                }
            }
        },
        itemCategories: []
    };
}

export const getSectionEquipmentDefaultObject = (id) => {
    return {
        id: id,
        configuration: {
            fill: "",
            borderColor: "",
            canvas: {
                position: {
                    left: 0,
                    top: 0
                },
                rotation: 0,
                size: {
                    width: "30px",
                    height: "30px"
                }
            }
        }
    };
}

export const getDefaultModifierListObject = () => {
    return {
        name: "",
        displayName: getTranslationDefaultObject(),
        minModifiers: 0,
        maxModifiers: 0,
        attachedModifierIds: [],
        attachedItemIds: [],
        allowMultipleSelection: false,
        attachedModifiers: [],
        enabled: true
    };
}

export const getDefaultWifiConfigurationObject = () => {
    return {
        locationName: getTranslationDefaultObject(),
        ssid: "",
        password: "",
        enableQRcode: true,
        enabled: true,
        encryption: "nopass"
    };
}

export const getDefaultPrinterTicketObject = (action, showFields = []) => {
    return {
        action: action,
        showFields: [{ startText: "", endText: "", replicas: 1, showFields: showFields, lang: "en" }]
    };
}

export const getDefaultPickupOrderDisplaySettings = () => {
    return {
        preparingOrderBackgroundColor: "#54b1d2",
        preparingOrderFontColor: "#ffffff",
        preparingOrderHeaderFontColor: "#153e52",
        readyOrderBackgroundColor: "#153e52",
        readyOrderFontColor: "#ffffff",
        readyOrderHeaderFontColor: "#54b1d2",
        fontSize: 70,
        headerFontSize: 64,
    };
}

export const getDefaultKitchenDisplaySettings = () => {
    return {
        collapsePendingOrders: false,
        autoAcceptOrders: false,
        defaultOrderPreparationTime: 15,
        showPendingAndWorkingOrdersTogether: true,
        pendingOrderColor: "#FEC9BF",
        workingOrderColor: "#FFF",
        doneOrderColor: "#B4EAB4",
        modifierColor: "#FEEEA8",
        orderCommentColor: "#FFF",
        itemNoteColor: "#FFF",
        allergiesColor: "#FFC9BF",
    };
}

export const getDefaultUserObject = () => {
    return {
        username: "",
        password: "",
        firstName: "",
        fatherName: "",
        lastName: "",
        email: "",
        phone: "30",
        address: {
            country: {
                id: ""
            },
            address: "",
            city: "",
            postalCode: ""
        },
        invocationAddress: "",
        jobDescription: "",
        roles: [],
        newPassword: "",
        verifyPassword: "",
        forgotPasswordCode: "",
        forgotPasswordCodeTries: 0,
        preferedLanguage: "en",
        notificationSettings: {
            notificationWebEnabled: true,
            webNotificationActionsEnabled: [],
            notificationEmailEnabled: true,
            emailNotificationActionsEnabled: [],
            notificationMobileEnabled: true,
            mobileNotificationActionsEnabled: []
        },
        outletIds: [],
        defaultOutletID: "",
        workingHours: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: []
        },
        sections: [],
        enabled: true
    };
}

export const getWorkingHourDay = () => {
    return {
        start: null,
        end: null,
        open: true
    };
}

export const getDefaultSubscription = () => {
    return {
        organizationID: "",
        planID: "",
        status: "",
        actionsHistory: [],
    };
}


export const getDefaultSubscriptionPlan = () => {
    return {
        name: "",
        type: "",
        description: "",
        services: [],
        details: [],
        prices: [{
            reason: "Normal",
            currencyString: "EUR",
            price: 0.0
        }],
        available: true,
        visibleOnlyToAdmin: false
    };
}

export const getSubscriptionPlanPriceDefaultObject = (reason = "") => {
    return {
        reason: reason,
        currencyString: "EUR",
        price: 0.00
    };
}

export const getSubscriptionPlanServiceDefaultObject = () => {
    return {
        key: "",
        value: 0.00,
        oneTimePayment: false
    };
}

export const getSubscriptionPlanDetailDefaultObject = () => {
    return {
        key: "",
        value: ""
    };
}


// orrganization
export const getDefaultOrganization = () => {
    return {
        name: getTranslationDefaultObject(),
        emails: [],
        telephones: [],
        outletIds: [],
        smsCounter: 0,
        emailCounter: 0,
        timezone: "",
        tags: [],
        planId: "",
        vatNumber: "",
        socialProfiles: getDefaultSocialProfilesList(),
        // subscriptionExpirationDate: "",
        vivaWalletConfiguration: {
            appSourceCode: "",
            clientId: "",
            clientSecret: "",
            merchantId: "",
            apiKey: ""
        },
        addressDetails: {
            address: "",
            city: "",
            country: null,
            longitude: "",
            latitude: ""
        },
        logo: "",
        active: true,
        monthsPassedWithoutOrderToMarkAsReturning: 2,
        daysPassedFromLastOrderToMarkAsRecent: 7
    };
}

export const getDefaultSocialProfilesList = () => {
    return [
        {
            name: "FACEBOOK",
            url: ""
        },
        {
            name: "INSTAGRAM",
            url: ""
        },
        {
            name: "LINKEDIN",
            url: ""
        },
        {
            name: "PINTEREST",
            url: ""
        },
        {
            name: "SNAPCHAT",
            url: ""
        },
        {
            name: "TIKTOK",
            url: ""
        },
        {
            name: "TWITTER",
            url: ""
        },
        {
            name: "YOUTUBE",
            url: ""
        }
    ];
}

// OUTLET
export const getDefaultOutlet = () => {
    return {
        name: getTranslationDefaultObject(),
        description: getTranslationDefaultObject(),
        extraNote: getTranslationDefaultObject(),
        email: "",
        emailConfigurations: [
        ],
        telephones: [],
        vatDetails: {
            inheritFromOrganization: true,
            vatNumber: ""
        },
        restrictBoundRadius: 0,
        thirdPartyPosId: "",
        url: "",
        photos: [],
        tags: [],
        richTags: [],
        orderCounter: 0,
        socialProfiles: {
            inheritFromOrganization: true,
            socialProfiles: getDefaultSocialProfilesList()
        },
        settings: {
            hours: {
                timezone: "(UTC+02:00) Athens, Bucharest",
                timeFormat: "TWELVE_HOURS",
                hours: {
                    monday: [],
                    tuesday: [],
                    wednesday: [],
                    thursday: [],
                    friday: [],
                    saturday: [],
                    sunday: []
                },
                holidays: [],
                vacation: [],
                indicator: {
                    nowOpenMessage: {
                        en: "",
                        de: "",
                        fr: "",
                        it: "",
                        ru: "",
                        el: "",
                        uk: "",
                        es: "",
                        nl: "",
                        cs: "",
                        pl: "",
                        bg: "",
                        ar: "",
                        et: "",
                        lt: "",
                        ro: "",
                        tr: "",
                        text: ""
                    },
                    nowCloseMessage: {
                        en: "",
                        de: "",
                        fr: "",
                        it: "",
                        ru: "",
                        el: "",
                        uk: "",
                        es: "",
                        nl: "",
                        cs: "",
                        pl: "",
                        bg: "",
                        ar: "",
                        et: "",
                        lt: "",
                        ro: "",
                        tr: "",
                        text: ""
                    },
                    includeTime: true,
                    includeDay: true,
                    showOpeningClosingSoonWarning: true,
                    openingSoonMessage: {
                        en: "",
                        de: "",
                        fr: "",
                        it: "",
                        ru: "",
                        el: "",
                        uk: "",
                        es: "",
                        nl: "",
                        cs: "",
                        pl: "",
                        bg: "",
                        ar: "",
                        et: "",
                        lt: "",
                        ro: "",
                        tr: "",
                        text: ""
                    },
                    closingSoonMessage: {
                        en: "",
                        de: "",
                        fr: "",
                        it: "",
                        ru: "",
                        el: "",
                        uk: "",
                        es: "",
                        nl: "",
                        cs: "",
                        pl: "",
                        bg: "",
                        ar: "",
                        et: "",
                        lt: "",
                        ro: "",
                        tr: "",
                        text: ""
                    },
                    openSoonWarning: 45,
                    closeSoonWarning: 45,
                    displayMode: "NORMAL",
                    output: "TABLE",
                    includeHolidays: false,
                    includeVacations: false
                }
            },
            taxNumberPercentage: 0,
            taxType: "INCLUSIVE",
            currency: "EUR",
            paymentMethods: [
                "cash",
                "card"
            ],
            automaticallyProduceReceiptForCashPayments: false,
            showLogo: true,
            showCover: true,
            showPhotos: false,
            allowReviews: true,
            showReviewsAverageScore: true,
            showReviews: true,
            ratingStyle: "star",
            allowReservations: true,
            allowComments: true,
            enableCart: true,
            enableOrder: true,
            allowOrdesFromAuthenticatedUsersOnly: false,
            refundOrderDurationDays: 0,
            refundItemsDurationDays: 0,
            allowUsersToLeaveFeedback: false,
            splashScreenConfiguration: {
                showLogo: true,
                showDescription: false,
                description: "",
                backgroundColor: "#47a6d2",
                descriptionColor: "#000000",
                durationInSeconds: 5,
                enable: false
            },
            vivaWallet: null,
            qrcodeTemplates: [],
            // requests
            allowedRequestTypes: [],
            requestExtraCustomButtons: [],
            // after scan
            allowActionFromAuthenticatedUserOnly: false,
            allowRequestsFromAuthenticatedUserOnly: false,
            allowRatingsFromAuthenticatedUserOnly: false,
            afterScanLandOnMenuPage: false,
            afterScanLandOnMenuPageButtonOptions: [],
            extraOutletActionButtons: [],
            enableLanguagesForMenu: ["en"],
            // rating
            minRatingNumberToGoogle: 4,
            // inventory
            enableInventoryManagement: false,
            //menu
            menuLayout: "GRID",
            // style
            backgroundColor: "#f2f2f2",
            headerFont: "sans-serif",
            headerColor: "#000000", 
            headerFontSize: 12, 
            textFont: "sans-serif", 
            textColor: "#808080", 
            textFontSize: 10, 
            textInfoFont: "sans-serif", 
            textInfoColor: "#000", 
            textInfoFontSize: 10,
            buttonFont: "sans-serif",
            buttonColor: "#ffffff", 
            buttonBackgroundColor: "#47a6d2", 
            buttonFontSize: 16, 
            chipTextColor: "#000000", 
            chipPercentageTextColor: "#009688", 
            chip1plus1TextColor: "#ff9800", 
            chipFontSize: 9, 
            // wifi
            wifiConfigurations: []
        },
        addressDetails: {
            address: "",
            city: "",
            postalCode: "",
            state: "",
            country: null,
            longitude: "",
            latitude: ""
        },
        vivaWalletConfiguration: {
            appSourceCode: "",
            clientId: "",
            clientSecret: "",
            merchantId: "",
            apiKey: ""
        },
        googleConfiguration: {
            locationID: ""
        },
        isActive: true
    };
}


export const getDefaultQrcodeTemplate = () => {
    return {
        id: "",
        name: "",
        value: "",
        ecLevel: "Q",
        enableCORS: false,
        size: 200,
        quietZone: 10,
        bgColor: "#FFFFFF",
        fgColor: "#000000",
        icon: "none",
        logoImage: "",
        logoWidth: 40,
        logoHeight: 40,
        logoOpacity: 1.0,
        removeQrCodeBehindLogo: false,
        logoPadding: "5",
        logoPaddingStyle: "circle",
        qrStyle: "squares",
        hasCustomDesign: "false",
        eyeRadius: [
            { // top/left eye
                outer: 'square',
                inner: 'square',
            },
            { // top/right eye
                outer: 'square',
                inner: 'square',
            },
            { // bottom/left eye
                outer: 'square',
                inner: 'square',
            },
        ],
        eyeColor: [
            { // top/left eye
                outer: 'black',
                inner: 'black',
            },
            { // top/right eye
                outer: 'black',
                inner: 'black',
            },
            { // bottom/left eye
                outer: 'black',
                inner: 'black',
            },
        ],
        frameColor: "#FFFFFF",
        frameStyle: "none",
        frameText: "",
        frameTextColor: "#000000",
        frameTextFontSize: "12",
        frameTextFontFamily: ""
    }
}

export const getDefaultOutletTicketObject = (action, showFields = []) => {
    return {
        action: action,
        details: [getDefaultOutletTicketDetailObject(showFields)]
    };
}

export const getDefaultOutletTicketDetailObject = (showFields = []) => {
    return { isEnabled: true, to: [], cc: [], bcc: [], subject: "", showFields: showFields };
}

export const getDefaultOutletTicketEmailWithActiveFlagObject = () => {
    return {
        isEnabled: true,
        email: ""
    };
}

// splash screen
export const getDefaultSplashScreenConfiguration = () => {
    return {
        showLogo: true,
        showDescription: false,
        description: "",
        backgroundColor: "#000",
        descriptionColor: "#fff",
        durationInSeconds: 5
    };
}

// LOYALTY
export const getDefaultLoyaltyCategory = () => {
    return {
        name: getTranslationDefaultObject(),
        baseColor: "#000",
        backgroundColor1: "#e8e8e8",
        backgroundColor2: "#9a9a9a",
        backgroundImage: "https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800",
        gradientBackground: false,
        backgroundType: "COLOR",
        font: "Arial",
        targetPoints: 0,
        discountPercentage: 0,
        couponValue: 0,
        barcodeConfiguration: getDefaultBarcodeConfiguration()
    };
}

export const getLoyaltyCardProperties = () => {
    return {
        width: "310px",
        height: "170px",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        borderRadius: "20px",
        marginRight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        boxShadow: 2,
        overflow: "visible"
    };
}

export const getDefaultBarcodeConfiguration = () => {
    return {
        value: "1234567890asdfghjklzxcvb",
        width: 1.75,
        height: 60,
        format: "CODE128",
        displayValue: true,
        fontOptions: "none", //TODO: remember to get the value of the field
        font: "monospace",
        textAlign: "center",
        textPosition: "bottom",
        textMargin: 2,
        fontSize: 20,
        background: "#ffffff",
        lineColor: "#000000",
        hasCustomMargin: false,
        margin: 10,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10
    };
}

export const getFlipCardConfiguration = () => {
    return {
        flipSpeedBackToFront: 2,
        flipSpeedFrontToBack: 2,
        cardStyles: { back: {}, front: {} },
        cardZIndex: 'auto',
        containerStyle: {},
        flipDirection: "horizontal",
    };
}

// COUPON
export const getDefaultCouponEmployee = (organizationID = "", outletID = "", customerID = "") => {
    return {
        organizationID: organizationID,
        outletID: outletID,
        customerID: customerID,
        label: getTranslationDefaultObject(),
        description: getTranslationDefaultObject(),
        status: "Active",
        value: 0.00,
        percentage: 0,
        code: "",
        referenceType: "EMPLOYEE",
        expirationDate: "",
        barcodeConfiguration: getCouponDefaultBarcodeConfiguration(),
        backgroundType: "COLOR",
        baseLabelColor: "#000000",
        baseDescriptionColor: "#000000",
        baseExpiresColor: "#000000",
        backgroundColor1: "#ffffff",
        backgroundColor2: "#9a9a9a",
        backgroundImage: "",
        gradientBackground: false,
    };
}

// ITEM 
export const getItemPriceDefaultObject = (priceListType = "") => {
    return {
        priceListId: "",
        priceListName: "",
        price: 0.00,
        discountPercent: 0.00,
        priceListType: priceListType,
        addedFromPanel: true
    };
}

export const getDefaultItemModifier = () => {
    return {
        provider: "none",
        name: getTranslationDefaultObject(),
        description: getTranslationDefaultObject(),
        remark: getTranslationDefaultObject(),
        extraNote: "",
        photos: [],
        prices: [getItemPriceDefaultObject("STANDARD")],
        modifierListIds: [],
        nutrients: [],
        allergies: [],
        extraIcons: [],
        itemCategories: [],
        itemVatType: "NONE",
        isModifier: false,
        isDeleted: false,
        enabled: true
    };
}

export const getItemNutrientsDefaultObject = () => {
    return {
        grams: 0,
        energy: 0,
        calories: 0,
        protein: 0,
        carb: 0,
        sugars: 0,
        fat: 0,
        sat_fat: 0,
        trans_fat: 0,
        fiber: 0,
        salt: 0
        // sodium: 0,
        // vitaminA: 0,
        // vitaminC: 0,
        // calcium: 0,
        // iron: 0
    };
}

export const getDefaultItemsInventory = () => {
    return {
        items: [],
    };
}

export const getDefaultItemsInventoryAvailability = (itemID) => {
    return {
        itemID: itemID,
        available: true,
        sortage: -1 //unlimited
    };
}


export const getDefaultItemOffer = () => {
    return {
        name: getTranslationDefaultObject(),
        description: getTranslationDefaultObject(),
        photos: [],
        price: null,
        percentage: null,
        categories: [],
        itemCategories: [],
        loyaltyPoints: 0,
        pvcRecyclingTax: 0.00,
        hours: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: []
        },
        outletIds: [],
        loyaltyCategoryIds: [],
        type: "OFFER",
        enabled: true,
    };
}



export const getDefaultItemCategory = (nameEn = "") => {
    return {
        name: {
            ...getTranslationDefaultObject(),
            en: nameEn,
        },
        itemIds: [],
        enabled: true
    };
}

export const getSectionDefaultObject = () => {
    return {
        name: getTranslationDefaultObject(),
        equipments: [],
        active: true
    };
}


export const getSectionEquipment = (id) => {
    return {
        id: id,
        configuration: {
            fill: "#000000",
            borderColor: "#000000",
            canvas: {
                position: {
                    left: 0,
                    top: 0
                },
                rotation: 0,
                size: {
                    height: 0,
                    width: 0
                }
            }
        }
    };
}

// EQUIPMENT
export const getEquipmentDefaultObject = () => {
    return {
        floor: "",
        type: "",
        label: "",
        details: [],
        tags: [],
        description: getTranslationDefaultObject(),
        active: true
    };
}

export const getEquipmentBulkAddDefaultObject = () => {
    return {
        floor: "",
        type: "",
        startNumber: 0,
        endNumber: 0,
        labelBegginingText: "",
        details: [],
        tags: [],
        description: getTranslationDefaultObject(),
        active: true
    };
}


// FEEDBACK FORM
export const getFeedbackFormDefaultObject = () => {
    return {
        name: getTranslationDefaultObject(),
        description: getTranslationDefaultObject(),
        attachedOutletIDs: [],
        feedback: []
    };
}

export const getFeedbackFormCategoryDefaultObject = () => {
    return {
        name: getTranslationDefaultObject(),
        description: getTranslationDefaultObject(),
        questions: []
    };
}


export const getFeedbackFormCategoryQuestionDefaultObject = () => {
    return {
        question: getTranslationDefaultObject(),
        description: getTranslationDefaultObject(),
        questions: [],
        type: "",
        isActive: true,
        style: "",
        choices: [],
        allowMultipleChoices: false,
        minChoices: 1,
        maxChoices: 1,
        promptText: "",
        maxAllowedCharacters: ""
    };
}


export const getFeedbackFormCategoryQuestionkMultipleChoiceOptionDefaultObject = () => {
    return {
        optionKey: 0,
        optionValue: getTranslationDefaultObject(),
    };
}

// MENU
export const getMenuDefaultObject = () => {
    return {
        name: getTranslationDefaultObject(),
        description: getTranslationDefaultObject(),
        extraNote: getTranslationDefaultObject(),
        parentCategoryIds: [],
        categoryIds: [],
        outletIds: [],
        hasRecurrence: false,
        startDate: dateTimeFormatter(new Date(), "yyyy-MM-DDTHH:mm:ss.SSS") + "Z",
        endDate: null,
        recurrenceDaysNumber: 0,
        active: true,
        settings: {
            photos: [],
            showAdvancedCMS: true,
            allowParentCategories: true,
            baseColor: "#000",
            font: "Arial",
            itemBackgroundColor: "#fff",
            itemHeaderColor: "#000",
            itemHeaderFontSize: "16px",
            itemDescColor: "#000",
            itemDescFontSize: "14px",
            itemPriceColor: "#47a6d2",
            itemPriceFontSize: "14px",
            navigationMenuFont: "Arial",
            navigationMenuFontSize: "12px",
            navigationMenuBackgroundColor: "#fff",
            navigationMenuSelectedOptionFontColor: "#47a6d2",
            navigationMenuUnSelectedOptionFontColor: "#000",
            navigationMenuStyle: "ROUND",
            buttonFont: "Arial",
            buttonColor: "#fff",
            buttonBackgroundColor: "#47a6d2",
            buttonFontSize: "18px",
            layout: [
                "LIST"
            ],
            itemPhotoStyle: "SQUARE",
            showCurrency: true,
            showDescription: true,
            showPointsPerItem: true,
            allowCommentsOnItem: true,
            iconPositionStart: false,
            menuBackgroundColor: "#ffffff00",
            menuHeaderColor: "#000",
            menuHeaderFontSize: "16px",
            menuDescColor: "#000",
            menuDescFontSize: "14px",
            menuPhotoStyle: "SQUARE",
            parentCategoryBackgroundColor: "#ffffff00",
            parentCategoryHeaderColor: "#000",
            parentCategoryHeaderFontSize: "16px",
            parentCategoryDescColor: "#000",
            parentCategoryDescFontSize: "14px",
            parentCategoryPhotoStyle: "SQUARE",
            parentCategoryLayout: "GRID",
            categoryBackgroundColor: "#ffffff00",
            categoryHeaderColor: "#000",
            categoryHeaderFontSize: "22px",
            categoryDescColor: "#000",
            categoryDescFontSize: "14px",
            chipTextColor: "#47a6d2"
        }
    };
}



// ASSETS
export const getAssetDefaultObject = (imageSrcBase64 = "", index = 0) => {
    return {
        imageSrc: imageSrcBase64,
        cover: index === 0 ? true : false,
        hidden: false,
    };
}

// REQUEST
export const getDefaultRequestObject = (organizationID = "", outletID = "", equipmentID = "") => {
    return {
        organizationID: organizationID,
        outletID: outletID,
        equipmentID: equipmentID,
        request: "",
        draftOrder: null,
        label: getTranslationDefaultObject(),
        icon: "",
        type: "",
        status: "PENDING"
    };
}

export const getExtraCustomRequestButtonDefaultObject = () => {
    return {
        icon: "",
        label: getTranslationDefaultObject()
    };
}
export const getExtraOutletActionButtonsDefaultObject = () => {
    return {
        icon: "",
        label: getTranslationDefaultObject(),
        url: ""
    };
}

// pda

export const getOrderPaymentActionHistoryItem = () => {
    return {
        itemID: "",
        indexInOrderCart: 0,//means the index that have this item in the cart.items fields
        quantityPaid: 0,
        amountPaid: 0.00
    };
}

export const getDefaultOrderBulkPaymentActionHistoryItem = (orderID, paymentMethod = "cash", status = "PAYMENT", items = [], couponID = null, treat = false) => {
    return {
        orderID: orderID,
        paymentAction: {
            paymentMethod: paymentMethod,
            status: status,
            items: items,
            couponID: couponID,
            treat: treat,
        }
    };
}

export const getOrderBulkPaymentActionHistoryItem = (orderID, items, couponID = null, paymentMethod = "cash", treat = false, tipAmountPaid = 0.0) => {
    return {
        orderID: orderID,
        paymentAction: {
            paymentMethod: paymentMethod,
            status: "COMBINE_PAYMENT",
            items: items,
            couponID: couponID,
            treat: treat,
            tipAmountPaid: tipAmountPaid
        }
    };
}


// ========== ANALYTICS

export const getDefaultAnalyticsGeneralObject = () => {
    return {
        counters: {
            sms: 0,
            email: 0,
            ticket: 0,
            receiptTicket: 0
        },
        customersCounter: 0,
        employeesCounter: 0,
        equipmentsCounter: 0,
        feedbacksCounter: 0,
        itemsOrModifiersCounter: 0,
        menusCounter: 0,
        modifierListsCounter: 0,
        outletsCounter: 0,
        printersCounter: 0,
        requestsCounter: 0,
        sectionsCounter: 0,
    }
}

export const getDefaultAnalyticsOrderObject = () => {
    return {
        employeeOrders: null,
        customerOrders: null,
        anonymousOrders: null,
        orderWhoPieData: null,
        totalOrders: 0
    }
}

// ============================ FEEDBACK
export const suggestedQuestionsNumber = 2;
export const defaultFeedbackFormCategories = [
    {
        id: "default",
        addedFromAdmin: true,
        name: {
            ...getTranslationDefaultObject(),
        },
        description: getTranslationDefaultObject(),
        questions: [
            {
                id: "question1",
                question: {
                    ...getTranslationDefaultObject(),
                    en: "How was your overal experience at this place?",
                },
                description: getTranslationDefaultObject(),
                required: true,
                active: true,
                type: "Rating_1_To_5",
                ratingQuestionStyle: "Stars"
            },
        ],
    },
    {
        id: "default1",
        addedFromAdmin: true,
        name: {
            ...getTranslationDefaultObject(),
            en: "Review"
        },
        description: getTranslationDefaultObject(),
        questions: [
            {
                id: "question1",
                question: {
                    ...getTranslationDefaultObject(),
                    en: "Quality"
                },
                description: {
                    ...getTranslationDefaultObject(),
                    en: "How satisfactory was the quality of the products?"
                },
                required: false,
                active: true,
                type: "Rating_1_To_5",
                ratingQuestionStyle: "Stars",
            },
            {
                id: "question2",
                question: {
                    ...getTranslationDefaultObject(),
                    en: "Service"
                },
                description: {
                    ...getTranslationDefaultObject(),
                    en: "What did you think of the store's service?"
                },
                required: false,
                active: true,
                type: "Rating_1_To_5",
                ratingQuestionStyle: "Stars",
            },
            {
                id: "question3",
                question: {
                    ...getTranslationDefaultObject(),
                    en: "Speed"
                },
                description: {
                    ...getTranslationDefaultObject(),
                    en: "How quickly did you receive your order?"
                },
                required: false,
                active: true,
                type: "Rating_1_To_5",
                ratingQuestionStyle: "Stars",
            },
        ],
    },
];


// ============================ CHAT
export const getDefaultChatChannel = () => {
    return {
        // id: "",
        // outletID: "",
        // organizationID: "",
        // employeeIds: [],
        color: "",
        image: "",
        urgent: false,
        messageHistory: []
    };
}

export const getDefaultChatChannelMessage = (userAuth) => {
    return {
        // id: "",
        message: "",
        response: false,
        attachments: [],
        photos: [],
        user: {
            id: userAuth !== null ? userAuth.id : "",
            name: ""
        }
    };
}

// ============================ INTERVAL PAGES
export const getDefaultDataRequest = (organizationID, outletID) => {
    return {
        userAuth: null,
        customers: [],
        customerRequests: [],
        requestExtraCustomButtons: [],
        types: [],
        statuses: [],
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        equipments: [],
        pagination: {
            count: -1,
            size: 10,
            page: 0,
            ...getDefaultSortDirectionData
        }
    };
}

export const getDefaultSearchRequest = (organizationID, outletID, equipmentID) => {
    return {
        organizationID: organizationID,
        outletID: outletID,
        // field: "firstName",
        equipmentID: equipmentID,
        customerName: "",
        employeeName: "",
        equipmentLabel: "",
        status: "PENDING",
        type: "",
        dateFrom: dateTimeFormatterFilter(),
        dateTo: null,
        label: ""
    }
}

export const getDefaultDataPda = () => {
    return {
        userAuth: null,
        organization: null,
        outlet: null,
        equipments: [],
        equipmentUnpaidOrders: [],
        requests: [],
        mySections: [],
        currency: "€",
        isFirstTime: true,
        dateFrom: dateTimeFormatterFilter(),
        dateTo: null
    }
}

export const getDefaultDataOrder = (organizationID, outletID) => {
    return {
        userAuth: null,
        orders: null,
        showLabel: false,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        organizations: [],
        outlets: [],
        equipments: [],
        statuses: [],
        paymentMethods: [],
        employees: [],
        pagination: {
            count: -1,
            size: 15,
            page: 0,
            ...getDefaultOrderSortDirection,
        },
        currency: "€"
    }
}

export const getDefaultSearchOrder = (organizationID) => {
    return {
        organizationID: organizationID,
        outletIDs: [],
        field: "customerName",
        equipmentID: "",
        customerPhoneNumber: "",
        customerName: "",
        orderNumber: "",
        paymentMethods: [],//cash, card, none
        statuses: [],//PENDING, WORKING, DONE,
        isPaid: null,
        dateFrom: dateTimeFormatterFilter(),
        dateTo: null
    }
}