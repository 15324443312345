import { request } from "constants/alias";
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from "utils/functions";

/**
 * The rest request which attempts to get the ratings list from the database.
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the ratings list
 */
export async function fetchAll(search, pageable) {
  let data = {};
  if (search.customerID !== undefined && search.customerID !== "") {
    data = { ...data, customerID: search.customerID }
  }
  if (search.organizationID !== undefined && search.organizationID !== "") {
    data = { ...data, organizationID: search.organizationID }
  }
  if (search.outletID !== undefined && search.outletID !== "") {
    data = { ...data, outletID: search.outletID }
  }

  if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}rating`, pageable !== undefined ? { ...pageable, ...data } : data));
  else return await request.get(includeParamsToUrl(`${API}rating`, pageable !== undefined ? pageable : {}));
}

export async function fetchCounts(search) {
  let data = {};
  if (search.customerID !== undefined && search.customerID !== "") {
    data = { ...data, customerID: search.customerID }
  }
  if (search.organizationID !== undefined && search.organizationID !== "") {
    data = { ...data, organizationID: search.organizationID }
  }
  if (search.outletID !== undefined && search.outletID !== "") {
    data = { ...data, outletID: search.outletID }
  }

  if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/ratings`, data));
  else return await request.get(includeParamsToUrl(`${API}analytics/ratings`, null));
}

const ratingApi = {
  fetchAll,
  fetchCounts
};

export default ratingApi;
