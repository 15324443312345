import { TabContext } from '@mui/lab';
import { Grid, InputAdornment } from '@mui/material';
import { Stack } from '@mui/system';
import customerApi from "api/customer";
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomSearchButton from 'components/Button/CustomSearchButton';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomTabs from 'components/Tab/CustomTabs';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitle from 'components/Title/CustomTitle';
import { getDefaultUserSortDirection } from 'constants/sortDirection';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagSearchItem, mainTagSearchObjectSelect, mainTagTitle } from 'themes/defaultThemes';
import { hasRoleAdmin } from 'utils/auth';
import { formValChangeWithParentElementSelect, formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { findCurrency, prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';
import { reloadPage } from "utils/page";
import { customerUsernameFormatterLink, fieldFormatter } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The CustomerListComponent, that allow the customer to view the customers list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomerListComponent() {

   const { t } = useTranslation();
   const { organizationID } = useParams();
   const { outletID } = useParams();
   let path = `/${getLanguageFromURL()}`
   if (organizationID !== undefined) path += `/organization/${organizationID}`;
   if (outletID !== undefined) path += `/outlet/${outletID}`;
   path += `/customer`;

   //change document title
   useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('customer.pageTitle')}`);

   /**
    * @type {object}
    * 
   * @property {object} userAuth the authenticated customer infromation
   * @property {array} customers the customers list
   * @property {object} organization tha organization basic details (id and name)
   * @property {object} pagination includes all pageable details (page, size, sort, direction)
    */
   const [data, setData] = useState({
      userAuth: null,
      customers: [],
      organization: {
         id: organizationID,
         name: ""
      },
      pagination: {
         count: -1,
         size: 15,
         page: 0,
         ...getDefaultUserSortDirection
      },
      currency: "€"
   });

   const [value, setValue] = useState("all");

   // to perform initial request
   const [isFirstTime, setIsFirstTime] = useState(true);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);
   // if true the menu item is clicked and a redirect to page should perform
   const [redirect, setRedirect] = useState(false);
   // for search
   const [search, setSearch] = useState({
      organizationID: organizationID,
      outletID: outletID !== undefined ? outletID : undefined,
      field: "username",
      username: "",
      email: "",
      phone: "",
      showOrderRecentlty: false,
      showReturning: false
   });

   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });

   const [searchValue, setSearchValue] = useState('');

   useEffect(() => {
      getCustomers();
      setIsFirstTime(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (value === "all") {
         setSearch({
            ...search,
            showOrderRecentlty: false,
            showReturning: false
         });
      } else if (value === "returning") {
         setSearch({
            ...search,
            showOrderRecentlty: false,
            showReturning: true
         });
      } else if (value === "orderRecently") {
         setSearch({
            ...search,
            showOrderRecentlty: true,
            showReturning: false
         });
      }
   }, [value]);

   useEffect(() => {
      const identifier = setTimeout(() => {
         if (!isFirstTime) getCustomers();
      }, 700);

      return () => {
         clearTimeout(identifier);
      }
   }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])


   /**
    * The rest endpoint to get the customers list.
    */
   function getCustomers() {
      customerApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
         setData({
            ...data,
            customers: r.data.returnobject.customers,
            userAuth: r.data.returnobject.userAuth,
            organization: r.data.returnobject.organization,
            pagination: {
               ...data.pagination,
               count: r.data.returnobject.customers.totalPages,
               size: r.data.returnobject.customers.size,
               page: r.data.returnobject.customers.number
            },
            currency: findCurrency(r.data.returnobject.outletCurrency)
         });
         setIsLoaded(true);
      }).catch((e) => {
         // console.log(e);
      })

   }

   /**
    * Function that triggers the multi select input value change.
    * @param {*} event 
    */
   function onSelectSearchChange(event) {
      let field = event.target.value;
      setSearchValue("");
      setSearch({
         ...search,
         field: field,
         username: "",
         email: "",
         phone: "",
         [field]: ""
      });
   }

   /**
    * Function that handles the snackbar open or close state.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }
   /**
    * Function that handles the pagination information.
    * @property {int} page the page to be redirected
    */
   function handlePaginationChange(page) {
      formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
   }

   /**
    * Function that handles the pagination information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleRowsPerPageChange(size) {
      let pagination = data.pagination;
      pagination.size = size;
      pagination.page = 0;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }
   /**
    * Function that handles the sort information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleColumnSortChange(changedColumn, direction) {
      let pagination = data.pagination;
      pagination.sort = changedColumn;
      pagination.direction = direction;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }

   function onFilterChange(event) {
      console.log(event)
      if (event !== null) {
         let index = event.index;
         let field = "";
         let value = event.value;
         if (index === 0) {
            field = `username`;
         } else if (index === 3) {
            field = `phone`;
         } else if (index === 4) {
            field = `email`;
         } else {
            field = event.field
         }

         formValChangeWithParentElementWithNameAndValue(field, value, search, setSearch)
      } else {
         setSearch({
            ...search,
            username: "",
            email: "",
            phone: "",
         })
      }
   }

   // ===============================================================================RENDER

   if (redirect) {
      reloadPage();
   } else if (!isLoaded) {
      return <LoadingSkeleton lines={9} />;
   } else {
      /**
       * datatable columns.
       */
      const table_columns = [
         {
            name: "customer",
            label: t('customer.username'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('label.username')}: ${v}`,
               sort: true,
               customBodyRender: (value, tableMeta) => {
                  return customerUsernameFormatterLink(value, tableMeta.tableData[tableMeta.rowIndex], `${path}`);
               }
            }
         },
         {
            name: "customer",
            label: t('customer.firstName'),
            options: {
               filter: false,
               sort: true,
               customBodyRender: (value) => {
                  return value?.firstName;
               }
            }
         },
         {
            name: "customer",
            label: t('customer.lastName'),
            options: {
               filter: false,
               sort: true,
               customBodyRender: (value) => {
                  return value?.lastName;
               }
            }
         },
         {
            name: "customer",
            label: t('customer.phone'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('customer.phone')}: ${v}`,
               sort: true,
               customBodyRender: (value) => {
                  return value?.phone;
               }
            }
         },
         {
            name: "customer",
            label: t('customer.email'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('customer.email')}: ${v}`,
               sort: true,
               customBodyRender: (value) => {
                  return value?.email;
               }
            }
         },
         {
            name: "order",
            label: t('customer.order.ordersNumber'),
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value) => {
                  return fieldFormatter(value?.ordersNumber);
               }
            }
         },
         {
            name: "order",
            label: t('customer.order.orderTotalAmmount'),
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value) => {
                  return fieldFormatter(`${value?.orderTotalAmmount} ${data.currency}`);
               }
            }
         },
         {
            name: "loyalty",
            label: t('customer.loyalty.name'),
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value) => {
                  return fieldFormatter(value?.name?.en);
               }
            }
         },
         {
            name: "loyalty",
            label: t('customer.loyalty.loyaltyNumber'),
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value) => {
                  return fieldFormatter(value?.loyaltyNumber);
               }
            }
         },
         {
            name: "loyalty",
            label: t('customer.loyalty.points'),
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value) => {
                  return fieldFormatter(value?.points);
               }
            }
         }
      ];

      return (
         <CustomGrid role="main" id="customer-list" sx={mainTag()}>
            {outletID !== undefined && organizationID !== undefined &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomBreadcrumbMultiParent
                     parents={[
                        { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                        { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                        { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                        { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` }
                     ]}
                     instanceName={t('employee.title')}
                  />
               </CustomGrid>
            }

            {outletID === undefined && organizationID !== undefined &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomBreadcrumbMultiParent
                     isAdd={data.isAdd}
                     parents={[
                        { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                        { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` }
                     ]}
                     instanceName={t('employee.title')}
                  />
               </CustomGrid>
            }

            <Grid container spacing={2} sx={{ alignItems: "center" }}>
               <Grid item xs={"auto"}>
                  <CustomRefreshButton onClick={getCustomers} marginTop="-1%" />
               </Grid>

               <Grid item xs={11} sm={3}>
                  <CustomGrid sx={mainTagTitle()}>
                     <CustomTitle
                        title={`${t('customer.title')}`}
                        subtitle={t('customer.subtitle')}
                     />
                  </CustomGrid>
               </Grid>
            </Grid>

            <CustomGrid sx={mainAreaTable()} container={false}>
               {!hasRoleAdmin(data.userAuth?.roles) &&
                  <TabContext value={value}>
                     <CustomTabs
                        initValue={value}
                        instances={[
                           { name: t('customer.tabs.all').toUpperCase(), value: "all" },
                           { name: t('customer.tabs.returning').toUpperCase(), value: "returning" },
                           { name: t('customer.tabs.orderRecently').toUpperCase(), value: "orderRecently" }
                        ]}
                        setValue={setValue}
                     />
                  </TabContext>
               }

               <CustomPageableTable
                  instances={data.customers}
                  emptyMessage={t('table.noData')}
                  table_columns={table_columns}
                  addPageUrl=""
                  keyField="customer.firstName"
                  defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                  pagination={data.pagination}
                  handlePaginationChange={handlePaginationChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handleColumnSortChange={handleColumnSortChange}
                  customToolbar={true}
                  filter={true}
                  onFilterChange={onFilterChange}
               />
            </CustomGrid>

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }
         </CustomGrid>
      );
   }
}

export default CustomerListComponent;