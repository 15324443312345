import {
    Dialog,
    DialogContent,
    useMediaQuery
} from '@mui/material';
import CustomSwippableDrawer from 'components/Drawer/CustomSwippableDrawer';

/**
 * The CustomDialogBasedOnScreen, that display a customized dialog
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {*} body the body of the dialog
 * @property {function} handleClose the function that handles the dialog close state
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */

function CustomDialogBasedOnScreen({ isOpen = false, body, handleClose, paperSx }) {
    const isMobile = useMediaQuery('(max-width:468px)');

    return (
        <>
            {!isMobile && isOpen &&
                <Dialog
                    open={isOpen}
                    onClose={() => handleClose(false)}
                    aria-describedby="dialog"
                    paperSx={paperSx}
                >
                    <DialogContent sx={{ textAlign: "-webkit-center", minWidth: "60%" }}>
                        {body}
                    </DialogContent>
                </Dialog>
            }

            {isMobile && isOpen &&
                <CustomSwippableDrawer
                    open={isOpen}
                    onClose={() => handleClose(false)}
                    anchor={isMobile ? "bottom" : "left"}
                    paperSx={paperSx}
                >
                    {body}
                </CustomSwippableDrawer>
            }
        </>
    );
}

export default CustomDialogBasedOnScreen;