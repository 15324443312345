import { Stack } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "components/Dialogs/CustomDialog";
import CustomGrid from "components/Grid/CustomGrid";
import CustomActionImage from "components/Assets/CustomActionImage";

function CustomImagesGrid({
    images,
    imagePath = "https://storage.usee.gr/usee/organization/635e94f54960603c9bd07641/outlet/63b31564c78b8371cf2d61ab/item/63a4532a88162740ed3b02f8/",
    setCover, setHidden, deleteImage
}) {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    function handleDialogState(open) {
        setOpen(open);
    };

    function handleClose() {
        setOpen(false);
    };
    return (
        <Stack direction="row" sx={{
            padding: "10px",
            alignItems: "center",
            width: "100%",
            // boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
            position: "relative"
        }}>
            <CustomGrid container gap={2} sx={{
                alignItems: "center",
                position: "relative"
            }}>
                {images.length > 0 &&
                    images.map((image, index) =>
                        <CustomActionImage {...image}
                            viewImage={handleDialogState}
                            imagePath={imagePath}
                            setCover={setCover}
                            setHidden={setHidden}
                            deleteImage={deleteImage}
                            index={index}
                        />
                    )}
            </CustomGrid>


            {open &&
                <CustomDialog
                    isOpen={open}
                    id="delete"
                    title={t('actions.deleteModal.title')}
                    actionLabel={t("actions.deleteModal.message")}
                    cancelLabel={t("actions.cancel")}
                    message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')}`}
                // action={deleteU}
                // handleOpen={handleDialogState}
                />
            }

        </Stack>
    );
}

export default CustomImagesGrid;