import { Tab, Tabs } from '@mui/material';
// import { withStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { replaceLastTwoChars } from 'utils/functions';

/**
 * The CustomPreviewMenuMobileTabsWithChip, that display the preview style of the navigation menu for categories using tabs and chips.
 * 
 * @param {object} item the menu item details
 * @param {string} priceColor the color for price to have
 * @param {string} lang the lang code
 * @param {fucntion} handleItemDialogState Callback that handles the open item details dialog for more details and to fill in fields to add one or more to cart.
 *
 * @returns {JSX.Element} A React component that renders a menu item.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPreviewMenuMobileTabsWithChip({ navigationMenuFont = "Arial", navigationMenuFontSize = "16px", navigationMenuBackgroundColor = "#fff",
    navigationMenuSelectedOptionFontColor = "#47a6d2", navigationMenuUnSelectedOptionFontColor = "#000", navigationMenuStyle = "ROUND", width = "97.5%" }) {

    const [value, setValue] = React.useState(1);
    const { t } = useTranslation();
    let backgroundChipColor = replaceLastTwoChars(navigationMenuSelectedOptionFontColor, "1c");

    let categories = [
        {
            name: `${t('menus.settings.category')} 1`,
        },
        {
            name: `${t('menus.settings.category')} 2`,
        },
        {
            name: `${t('menus.settings.category')} 3`,
        },
        {
            name: `${t('menus.settings.category')} 4`,
        },
        {
            name: `${t('menus.settings.category')} 5`,
        },
        {
            name: `${t('menus.settings.category')} 6`,
        },
        {
            name: `${t('menus.settings.category')} 7`,
        },
        {
            name: `${t('menus.settings.category')} 8`,
        }
    ];

    return (
        <>
            <Tabs
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                selectionFollowsFocus={true}
                orientation="horizontal"
                onChange={(event, newValue) => setValue(newValue)}
                TabIndicatorProps={{
                    style: {
                        // backgroundColor: navigationMenuSelectedOptionFontColor,
                        display: "none"
                    }
                }}
                sx={{
                    marginBottom: "40px",
                    width: width,
                    font: navigationMenuFont,
                    background: navigationMenuBackgroundColor,
                    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
                }}
            >
                {categories.map((category, index) =>
                    <Tab
                        key={index}
                        value={index}
                        classes={{ indicator: navigationMenuSelectedOptionFontColor }}
                        label={category.name}
                        sx={{
                            fontSize: navigationMenuFontSize,
                            borderRadius: navigationMenuStyle === "ROUND" ? "10px" : "0px",
                            fontWeight: "bold",
                            margin: "10px 1px",
                            textTransform: "initial",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            color: navigationMenuUnSelectedOptionFontColor,
                            "&:hover": {
                                backgroundColor: backgroundChipColor,
                                color: navigationMenuSelectedOptionFontColor
                            },
                            "&:focus": {
                                backgroundColor: backgroundChipColor,
                                color: navigationMenuSelectedOptionFontColor,
                            },
                            "&:active": {
                                background: backgroundChipColor,
                                color: navigationMenuSelectedOptionFontColor,
                            },
                            '&.Mui-selected': {
                                backgroundColor: backgroundChipColor,
                                color: navigationMenuSelectedOptionFontColor
                            },
                        }}
                    />
                )}
            </Tabs>
            <Tabs
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                selectionFollowsFocus={true}
                orientation="vertical"
                TabIndicatorProps={{
                    style: {
                        // backgroundColor: navigationMenuSelectedOptionFontColor,
                        display: "none"
                    }
                }}
                sx={{
                    marginBottom: "40px",
                    width: width,
                    font: navigationMenuFont,
                    background: navigationMenuBackgroundColor,
                    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
                }}
                onChange={(event, newValue) => setValue(newValue)}
            >
                {categories.map((category, index) =>
                    <Tab
                        key={index}
                        value={index}
                        classes={{ indicator: navigationMenuSelectedOptionFontColor }}
                        label={category.name}
                        sx={{
                            fontSize: navigationMenuFontSize,
                            borderRadius: navigationMenuStyle === "ROUND" ? "10px" : "0px",
                            fontWeight: "bold",
                            margin: "10px 1px",
                            textTransform: "initial",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            color: navigationMenuUnSelectedOptionFontColor,
                            "&:hover": {
                                backgroundColor: backgroundChipColor,
                                color: navigationMenuSelectedOptionFontColor
                            },
                            "&:focus": {
                                backgroundColor: backgroundChipColor,
                                color: navigationMenuSelectedOptionFontColor,
                            },
                            "&:active": {
                                background: backgroundChipColor,
                                color: navigationMenuSelectedOptionFontColor,
                            },
                            '&.Mui-selected': {
                                backgroundColor: backgroundChipColor,
                                color: navigationMenuSelectedOptionFontColor
                            },
                        }}
                    />
                )}
            </Tabs>
        </>
    );
}

export default CustomPreviewMenuMobileTabsWithChip;