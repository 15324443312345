
import { Circle as CircleIcon, Delete as DeleteIcon, Edit as EditIcon, PowerSettingsNew as PowerSettingsNewIcon, Wifi } from '@mui/icons-material';
import { Card, CardActionArea, Divider, Grid, SvgIcon } from '@mui/material';
import { Stack } from '@mui/system';
import { CheckIcon, CloseIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomChip from 'components/Chip/CustomChip';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDefaultWifiConfigurationObject } from 'constants/defaultObjects';
import { getThemeColor } from 'constants/theme';
import { t } from 'i18next';
import { activeWifiIcon, cardItemButtonGroupStack, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, cardItemContent, cardItemContentActionArea, cardItemContentStackButtonIcon, getOrderButtonIcon, textWifiHeaderTypography, textWifiItemTypography } from 'themes/defaultThemes';
import { getTheme } from 'utils/theme';

/**
 * The Custom Wifi configuration preview Card, that display a customized {@link Card}.
 * 
 * @property {string} name the Checkbox name value
 * @property {string} selected If `true`, the component is checked.
 * @property {string} label the Checkbox text value
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {function} onChange Callback that triggered when value is changed
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomWifiConfigurationPreviewCard({
   wifiConfiguration = getDefaultWifiConfigurationObject(),
   index = 0,
   sx = {},
   updateWifiConfiguration,
   updateWifiConfigurationState,
   deleteWifiConfiguration }) {

   return (
      <Grid>
         <Stack direction="row" sx={cardItemButtonGroupStack()} key={`stack-wifi-${index}`}>
            <CustomIconButton
               keyValue={`btn-power-${index}`}
               onClick={(event) => updateWifiConfigurationState(index, wifiConfiguration.enabled ? false : true)}
               icon={<PowerSettingsNewIcon style={cardItemButtonGroupStackButtonIcon()} />}
               iconSx={{ marginRight: "0px!important" }}
               sx={{
                  backgroundColor: getThemeColor()[getTheme()][wifiConfiguration.enabled ? "powerOffButtonBackgroundColor" : "powerOnButtonBackgroundColor"],
                  ":hover": {
                     backgroundColor: getThemeColor()[getTheme()][wifiConfiguration.enabled ? "powerOffButtonBackgroundColorHover" : "powerOnButtonBackgroundColorHover"],
                  },
                  ...cardItemButtonGroupStackButton()
               }}
               tooltipTitle={t(`actions.${wifiConfiguration.enabled ? "powerOff" : "powerOn"}`)}
            />
            <CustomIconButton
               keyValue={`btn-edit-${index}`}
               onClick={(event) => updateWifiConfiguration(index)}
               icon={<EditIcon style={cardItemButtonGroupStackButtonIcon()} />}
               iconSx={{ marginRight: "0px!important" }}
               sx={{
                  backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColor"],
                  ":hover": {
                     backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColorHover"],
                  },
                  ...cardItemButtonGroupStackButton()
               }}
               tooltipTitle={t('actions.edit')}
            />
            <CustomIconButton
               keyValue={`btn-delete-${index}`}
               onClick={(event) => deleteWifiConfiguration(event, index)}
               icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
               iconSx={{ marginRight: "0px!important" }}
               sx={{
                  backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                  ":hover": {
                     backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                  },
                  ...cardItemButtonGroupStackButton()
               }}
               tooltipTitle={t('actions.delete')}
            />
         </Stack>
         <Stack direction="row" sx={cardItemContentStackButtonIcon()} key={`stack-card-${index}`}>
            <Card variant="outlined"
               key={`card-${index}-stack`}
               sx={{
                  ...cardItemContent(),
                  minWidth: "320px",
                  minHeight: "150px",
                  width: "auto",
                  ...sx
               }}
            >
               <CardActionArea sx={cardItemContentActionArea()} key={`card- back-ontent-${index}`}>
                  {/* PRINTER FRIENDLY NAME */}
                  <CustomTypography
                     level="h6"
                     sx={{ ...textWifiHeaderTypography(), height: "20px" }}
                     text={wifiConfiguration?.locationName?.en}
                  />
                  {wifiConfiguration?.locationName?.en && <Divider />}

                  {/* REST OF WIFI DETAILS */}
                  <Grid container direction="column" sx={{ height: "100%", paddingTop: "10px" }}>
                     <Grid direction="row" sx={{ height: "100%", display: "flex", justifyContent: "center", paddingLeft: 0, marginLeft: 0 }} >
                        <CustomIconButton
                           icon={<Wifi
                              style={{
                                 fontSize: "60px",
                                 padding: "10px"
                              }} />}
                           sx={{
                              borderRadius: "50px",
                              height: "fit-content",
                              placeSelf: "center",
                              boxShadow: "0px 0px 15px 0px #dcdcdc",
                              marginTop: "-40px"
                           }}
                           iconSx={{
                              backgroundColor: "transparent",
                              padding: "0px",
                              margin: "0px"
                           }}
                        />
                        <SvgIcon component={CircleIcon} sx={{ ...activeWifiIcon(), color: wifiConfiguration?.enabled ? "green" : "red" }} />
                     </Grid>

                     <Grid direction="row">
                        <Grid item xs={12} md={12} spacing={1}>
                           <Grid direction="row" sx={{ textAlign: "left" }}>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomTypography
                                    level="h6"
                                    sx={{ ...textWifiItemTypography(), textDecoration: "underline" }}
                                    text={`${t('outlet.settings.wifi.ssid')}:`}
                                 />
                              </Grid>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomChip label={wifiConfiguration?.ssid} rowIndex={0} />
                              </Grid>
                           </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} spacing={1}>
                           <Grid container sx={{ textAlign: "left" }}>
                              <Grid item xs={8.5}>
                                 <CustomTypography
                                    level="h6"
                                    sx={{ ...textWifiItemTypography(), textDecoration: "underline" }}
                                    text={`${t('outlet.settings.wifi.enableQRcode')}:`}
                                 />
                              </Grid>
                              <Grid item xs={3}>
                                 {wifiConfiguration?.enableQRcode &&
                                    <CheckIcon {...getOrderButtonIcon()} sx={{ fill: "green" }} />
                                 }
                                 {!wifiConfiguration?.enableQRcode &&
                                    <CloseIcon {...getOrderButtonIcon()} sx={{ fill: "red" }} />
                                 }
                              </Grid>
                           </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} spacing={1}>
                           <Grid container sx={{ textAlign: "left" }}>
                              <Grid item xs={6.5}>
                                 <CustomTypography
                                    level="h6"
                                    sx={{ ...textWifiItemTypography(), textDecoration: "underline" }}
                                    text={`${t('outlet.settings.wifi.encryption.label')}:`}
                                 />
                              </Grid>
                              <Grid item xs={5}>
                                 <CustomChip label={wifiConfiguration?.encryption} rowIndex={0} />
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </CardActionArea>
            </Card>
         </Stack>
      </Grid>
   );
}

export default CustomWifiConfigurationPreviewCard;