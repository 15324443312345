
import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * The Custom Text Field, that display customized {@link TextField}.
 * @property {string} id the text field id
 * @property {string} name the text field name
 * @property {string} type the text field type
 * @property {int} step  For number type case, the number step counter
 * @property {int} min  For number type case, the min value
 * @property {int} max  For number type case, the max value
 * @property {string} className the text field class
 * @property {string} label the text field label
 * @property {string} label the text field start input-adorment label
 * @property {string} defaultValue the text field default value
 * @property {string} placeholder the text field placeholder
 * @property {string} helperText the text field helper text
 * @property {boolean} required if the value is required at this text field 
 * @property {boolean} error if text field is at an error state
 * @property {function} onChange function that triggered when value is changed
 * @property {*} sx  Applies the theme textfield styles.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTextFieldWithIcons({ id = "", name = "", type = "", step, min, max, variant, disabled = false, className, label = undefined, startIcon, endIcon, defaultValue, value, placeholder, helperText, required = true, error = false, onChange, sx, inputAdornmentLabel, startAdormentWidth = "200px", autocomplete, startIconMarginTopEnable = false, onPaste }) {
    const { t } = useTranslation();
    return (
        <TextField
            autoComplete={autocomplete}
            id={id}
            name={name}
            type={type}
            step={step}
            min={min}
            max={max}
            variant={variant}
            disabled={disabled}
            className={className}
            label={label}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            helperText={helperText}
            required={required}
            error={error}
            onChange={onChange}
            onPaste={onPaste}
            style={{
                background: "white",
                border: "none",
                width: "99.5%",
                ...sx
            }}
            InputProps={{
                startAdornment: (
                    <>
                        {startIcon !== undefined &&
                            <InputAdornment position="end" sx={{ paddingTop: startIconMarginTopEnable ? "15px" : "initial" }} >
                                {startIcon}
                            </InputAdornment>
                        }
                        {inputAdornmentLabel !== undefined && <span style={{ width: startAdormentWidth, fontWeight: 600 }}>{t(inputAdornmentLabel)}</span>}
                    </>
                ),
                endAdornment: (
                    <InputAdornment position="end" >
                        {endIcon}
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default CustomTextFieldWithIcons;