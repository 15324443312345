import reorder from "./reOrder";
export default function reorderNested(list, source, destination) {
  const currentParent = list.find((ele) => ele.id === source.droppableId);
  const nextParent = list.find((ele) => ele.id === destination.droppableId);

  if (!currentParent || !nextParent) return list;
  const current = currentParent.itemIds;
  const next = nextParent.itemIds;
  const target = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);

    return list.map((li) => {
      if (li.id === source.droppableId) {
        li.itemIds = reordered;
      }
      return li;
    });
  }

  //checking if source drop already exist in list
  if (next.some((x) => x.id === target.id)) {
    return list;
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);

  // insert into next
  next.splice(destination.index, 0, target);

  return list.map((li) => {
    if (li.id === source.droppableId) {
      li.itemIds = current;
    }
    if (li.id === destination.droppableId) {
      li.itemIds = next;
    }
    return li;
  });
}
