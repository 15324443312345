import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import { Fab, FormControlLabel, Grid, InputLabel, Stack, Switch } from "@mui/material";
import sectionApi from 'api/section';
import en from "assets/images/flags/en.png";
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomChip from 'components/Chip/CustomChip';
import CustomDialog from 'components/Dialogs/CustomDialog';
import CustomTranslationsDialog from 'components/Dialogs/CustomTranslationsDialog';
import AttachEquipmentDialog from 'components/Dialogs/equipment/AttachEquipmentDialog';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomEquipmentDraggableTable from 'components/Table/CustomEquipmentDraggableTable';
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import CustomTitleBoldSubtitle from "components/Title/CustomTitleBoldSubtitle";
import { getSectionDefaultObject, getSectionEquipment } from 'constants/defaultObjects';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/material.css';
import { useNavigate, useParams } from "react-router-dom";
import { attachDialogAddButton, attachDialogAddButtonAddIcon, attachDialogAddButtonIcon, attachDialogGridDraggable, fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, textFieldError } from 'themes/defaultThemes';
import { formIsValid, formValChangeWithParentElementWithNameAndValue, formValChangeWithParents } from 'utils/form-validation';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The SectionAddEditComponent that triggers the creation of a 
 * new section form or the modification of an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SectionAddEditComponent() {
   const { t } = useTranslation();
   const { organizationID } = useParams();
   const { outletID } = useParams();
   const { sectionID } = useParams();

   const navigate = useNavigate();
   let path = `/${getLanguageFromURL()}`
   if (organizationID !== undefined) path += `/organization/${organizationID}`;
   if (outletID !== undefined) path += `/outlet/${outletID}`;
   path += `/section`;

   /**
    * @type {object}
    * 
    * @property {boolean} isAdd to define if requested an edit or an add of a section
    * @property {object} userAuth the authenticated user infromation
    * @property {object} section an empty object if add or the selected section to be editted
    * @property {object} isError list of indication of empty form required field 
    *                           after submit to present error message to the
    *                           fields/attributes with true value
    */
   const [data, setData] = useState({
      userAuth: null,
      isAdd: sectionID === undefined,
      organization: {
         id: organizationID,
         name: ""
      },
      outlet: {
         id: outletID,
         name: ""
      },
      outlets: [],
      section: null,
      organizations: [],
      equipments: [],
      // error messages per field
      isError: {
         name: "",
      },
   });

   // popup dialog for attach items, modifiers
   const [attachDialog, setAttachDialog] = useState({
      isOpen: false,
      field: ""
   });


   //change document title
   useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('section.title')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`);


   // if true the menu item is clicked and a redirect to page should perform
   const [redirect, setRedirect] = useState(false);
   // to render DOM based on rest api call status, if true the rest api call completed
   const [isLoaded, setIsLoaded] = useState(false);

   // popup dialog for delete
   const [dialog, setDialog] = useState({
      isOpen: false,
      message: t("label.configureTranslations"),
      id: "",
      field: "",
      translations: {}
   });

   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });

   // pop dialog for delete
   const [deleteDialog, setDeleteDialog] = useState({
      isOpen: false,
      id: "",
      field: "",
      label: "",
   });


   useEffect(() => {
      getSection();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   /**
    * The rest endpoint to get the section default (add) or current (edit) information.
    */
   function getSection() {
      if (data.isAdd) {
         sectionApi.fetchNew(organizationID, outletID).then((r) => {
            setData({
               ...data,
               userAuth: r.data.returnobject.userAuth,
               section: getSectionDefaultObject(),
               equipments: r.data.returnobject.equipments,
               outlets: r.data.returnobject.outlets,
               outlet: r.data.returnobject.outlet,
               organizations: r.data.returnobject.organizations,
               organization: r.data.returnobject.organization
            });
            setIsLoaded(true);
         }).catch((e) => {
            // console.log(e);
         })
      } else {
         sectionApi.fetchOne(organizationID, outletID, sectionID).then((r) => {
            setData({
               ...data,
               userAuth: r.data.returnobject.userAuth,
               section: r.data.returnobject.section,
               equipments: r.data.returnobject.equipments,
               outlets: r.data.returnobject.outlets,
               outlet: r.data.returnobject.outlet,
               organizations: r.data.returnobject.organizations,
               organization: r.data.returnobject.organization
            });
            setIsLoaded(true);
         }).catch((e) => {
            // console.log(e);
         })
      }
   }

   /**
    * Gets called when the user clicks on the save button, and triggers 
    * the creation of the new feeedback form.
    */
   function addSection() {
      if (formValidation()) {
         let section = data.section;
         section.organizationID = data.organization.id;
         section.outletID = data.outlet.id;
         sectionApi.create(section).then((r) => {
            setAlertBox({
               isOpen: true,
               message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
               backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            setRedirect((r.data.code === "SUCCESS" ? true : false));
         }).catch((e) => {
            // console.log(e)
         });
      }
   }

   /**
    * Gets called when the user clicks on the save button, and triggers 
    * the edit of the selected feeedback form.
    */
   function editSection() {
      if (formValidation()) {
         let section = data.section;
         sectionApi.update(section).then((r) => {
            setAlertBox({
               isOpen: true,
               message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
               backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
         }).catch((e) => {
            // console.log(e)
         });
      }
   }

   /**
    * Function that create error messages for each required field that are not filled out.
    * 
    * @returns the object with the error messages for each form field
    */
   function createErrorMessages() {
      let isError = { ...data.isError };
      isError.name = data.section.name.en.length < 1 ? t('form.requiredField') : "";
      setData({
         ...data,
         isError
      });
      return isError;
   }

   /**
    * Function that triggers form validation.
    * @returns true if form is Valid
    */
   function formValidation() {
      let isError = createErrorMessages();
      if (formIsValid(isError)) {
         return true;
      } else {
         return false;
      }
   }

   /**
    * Function that handles the delete modal open or close state.
    * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
    * @property {string} field The field of the selected text translations to be removed.
    */
   function handleDialogState(isOpen, field = "") {
      setDialog({
         ...dialog,
         isOpen: isOpen,
         field: field,
         translations: data.section[field]
      });
   }

   /**
    * This function will trigger the outlet field translations configuration
    */
   function configureSectionTranslations(translations, field) {
      document.querySelectorAll(`[name="section.${field}.en"]`)[0].value = translations.en;
      setData({
         ...data,
         section: {
            ...data.section,
            [field]: translations
         }
      });
      setDialog({
         ...dialog,
         isOpen: false,
         field: "",
         translations: {}
      });
   }

   /**
    * Function to trigger the remove of the admin broker.
    * @property {*} event
    * @property {string} field The field of the selected outlet field.
    */
   function configureTranslations(event, field) {
      handleDialogState(true, field);
   }

   /**
    * Function that handles the snackbar open or close state.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }


   /**
    * Function that handles the attach modal open or close state.
    * 
    * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
    */
   function handleAttachDialogState(isOpen) {
      setAttachDialog({
         ...attachDialog,
         isOpen: isOpen
      });
   }

   function addEquipments(instances) {
      console.log(instances)
      let newEquipments = [...data.section.equipments, ...instances.map(instance => getSectionEquipment(instance.id))];
      console.log(newEquipments)
      setData({
         ...data,
         section: {
            ...data.section,
            equipments: newEquipments
         }
      });
      setAttachDialog({
         ...attachDialog,
         isOpen: false
      });
   }

   /**
    * Function that handles the delete modal open or close state.
    * @property {boolean} isOpen The state of the dialog open status.
    * @property {string} id The id of the selected equipment to be removed.
    */
   function handleDeleteDialogState(isOpen, id = "", label = "", field = "equipments") {
      setDeleteDialog({
         ...deleteDialog,
         isOpen: isOpen,
         id: id,
         field: field,
         label: label
      });
   }

   /**
    * Function that triggers the removal of an equipment.
    * @param {*} itemOrModifierToDelete 
    */
   function removeEquipment() {
      const newArray = data.section.equipments.filter((obj) => obj.id !== deleteDialog.id);

      setData({
         ...data,
         section: {
            ...data.section,
            equipments: newArray
         }
      });
      setDeleteDialog({
         ...deleteDialog,
         isOpen: false,
         id: "",
         field: ""
      });
   }

   // ===============================================================================RENDER

   if (redirect) {
      navigate(path);
   } else if ((!isLoaded && !data.isAdd) || data.section === null) {
      return (
         <LoadingSkeleton lines={9} />
      );
   } else {

      return (
         <CustomGrid role="main" id={"section-" + data.isAdd ? "add" : "edit"} sx={mainTag()}>
            {outletID !== undefined && organizationID !== undefined &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomAddEditBreadcrumbMultiParent
                     isAdd={data.isAdd}
                     parents={[
                        { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                        { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                        { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                        { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                        { name: t('section.pageTitle'), url: path }
                     ]}
                     instanceName={(data.section !== null && data.section.name !== null) ? data.section.name.en : ""}
                  />
               </CustomGrid>
            }

            {outletID === undefined && organizationID !== undefined &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomAddEditBreadcrumbMultiParent
                     isAdd={data.isAdd}
                     parents={[
                        { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                        { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                        { name: t('section.pageTitle'), url: path }
                     ]}
                     instanceName={(data.section !== null && data.section.name !== null) ? data.section.name.en : ""}
                  />
               </CustomGrid>
            }

            {(outletID === undefined && organizationID === undefined) &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomAddEditBreadcrumbMultiParent
                     isAdd={data.isAdd}
                     parents={[
                        { name: t('section.pageTitle'), url: path }
                     ]}
                     instanceName={(data.section !== null && data.section.name !== null) ? data.section.name.en : ""}
                  />
               </CustomGrid>
            }

            <CustomGrid sx={mainTagTitle()}>
               <CustomTitleBoldSubtitle
                  title={`${t('section.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`}
                  subtitleBeforeText={data.isAdd ? t('actions.tableCreateNewLink') + t('section.title') : t('section.title') + " "}
                  subtitleboldText={!data.isAdd && data.section !== null && data.section !== undefined && data.section.name.en}
                  subtitleAfterText={""}
               />
            </CustomGrid>

            <CustomGrid sx={mainArea()} container={false}>
               <form id="section" onSubmit={(e) => { return false; }}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="nane">{t('label.name')}</InputLabel>
                        <CustomFlagTextField
                           country="en"
                           flag={en}
                           placeholder=""
                           name="section.name.en"
                           id="name"
                           onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                           className={data.isError.name.length > 0 ? "is-invalid" : ""}
                           defaultValue={data.section !== null && data.section.name !== null ? data.section.name.en : ""}
                           onTranslationSelect={configureTranslations}
                           field="name"
                           error={data.isError.name.length > 0 ? true : false}
                           helperText={data.isError.name}
                           sx={textFieldError()}
                        />
                     </Grid>

                     <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                           <Grid item>
                              <InputLabel required={false} htmlFor="attachedEquimenpIds">{t('equipment.title')}
                                 <CustomChip label={data.section.equipments !== null ? data.section.equipments.length : 0} />
                              </InputLabel>
                           </Grid>
                           <Grid item>
                              <CustomIconButton
                                 onClick={() => handleAttachDialogState(true)}
                                 icon={<AddIcon style={attachDialogAddButtonAddIcon()} />}
                                 iconSx={attachDialogAddButtonIcon()}
                                 sx={attachDialogAddButton()}
                                 label={t('equipment.addLabel')} />
                           </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <CustomEquipmentDraggableTable
                              emptyMessage={t('section.emptyEquipment')}
                              item={data.equipments.filter(option => {
                                 return data.section.equipments.some((eq) => eq.id === option.id);
                              })}
                              removeEquipment={(isOpen, item) => handleDeleteDialogState(isOpen, item.id, item.label, "modifiers")}
                              field="equipments"
                              organizationID={data.organization.id}
                              enableDraggable={false}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Stack direction="column" spacing={0}>
                     <FormControlLabel control={<Switch defaultChecked={data.section.active} onChange={() => formValChangeWithParentElementWithNameAndValue("active", !data.section.active, data, setData)} />} sx={{ marginBottom: "20px" }} label={t('label.isActive')} />
                  </Stack>
                  <Grid container spacing={2}>
                     {/* <Grid item>
                        <CustomIconButton
                           variant="outlined"
                           keyValue="save"
                           onClick={data.isAdd ? addSection : editSection}
                           label={t('actions.save')}
                           sx={{ ...formSaveButton(), marginLeft: "20px" }}
                        />
                     </Grid> */}

                     {data.isAdd &&
                        <Grid item>
                           <CustomIconButton
                              variant="outlined"
                              keyValue="cancel"
                              link={path}
                              label={t('actions.back')}
                              sx={formSaveButton()}
                              color="error"
                           />
                        </Grid>
                     }

                     {!data.isAdd &&
                        <Grid item>
                           <CustomIconButton
                              variant="outlined"
                              keyValue="cancel"
                              onClick={getSection}
                              label={t('actions.cancel')}
                              sx={formSaveButton()}
                              color="error"
                           />
                        </Grid>
                     }
                  </Grid>
               </form>

               <Fab color="primary"
                  aria-label="add"
                  sx={fabStyle()}
                  onClick={data.isAdd ? addSection : editSection}
               >
                  <SaveIcon />
               </Fab>
            </CustomGrid>

            {attachDialog.isOpen &&
               <AttachEquipmentDialog
                  isOpen={attachDialog.isOpen}
                  id="add-equipment"
                  title={`${t('actions.add')} ${t('equipment.title')} ${t('equipment.to')}`}
                  boldTitle={data.section.name.en}
                  searchLabel={`${t('actions.searchFor')} ${t('equipment.title')}`}
                  noOptionsText={`${t('equipment.emptyEquipmentsSearch')}`}
                  actionLabel={t("actions.add")}
                  cancelLabel={t("actions.cancel")}
                  message={`${t('actions.add')} ${t('actions.deleteModal.confirm')}`}
                  action={addEquipments}
                  handleOpen={handleAttachDialogState}
                  instances={data.equipments.filter(option => !data.section.equipments.includes(option.id))}
               // instances={data[attachDialog.field].filter(equipment => !data.equipment[(attachDialog.field === "modifiers" ? "attachedModifierIds" : "attachedItemIds")].includes(equipment.id))}
               />
            }

            {deleteDialog.isOpen &&
               <CustomDialog
                  isOpen={deleteDialog.isOpen}
                  id="remove"
                  title={t('actions.removeModal.title')}
                  actionLabel={t("actions.removeModal.message")}
                  cancelLabel={t("actions.cancel")}
                  message={`${t('actions.removeModal.notice')} ${t('actions.removeModal.confirm')} ${t('section.title')}: `}
                  boldMessage={<span style={{ fontWeight: "bold" }}>{deleteDialog.label}</span>}
                  action={removeEquipment}
                  handleOpen={handleDeleteDialogState}
                  actionColor="#e91e1e"
               />
            }
            {dialog.isOpen &&
               <CustomTranslationsDialog
                  isOpen={dialog.isOpen}
                  id="translations"
                  title={t('actions.translationModal.title')}
                  action={configureSectionTranslations}
                  handleOpen={handleDialogState}
                  translations={data.section[dialog.field]}
                  field={dialog.field} />
            }

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }
         </CustomGrid>
      );
   }
}

export default SectionAddEditComponent;