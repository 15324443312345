
import {
    AllOutIcon,
    CheckIcon,
    ScheduleIcon
} from 'assets/mui/MuiIcons';
import { getOrderButtonIcon } from "themes/defaultThemes";

export function getRequestFilterStatusIcon(status) {
    if (status === "ALL") {
        return <AllOutIcon {...getOrderButtonIcon()} />
    } else if (status === "DONE") {
        return <CheckIcon {...getOrderButtonIcon()} />
    } else if (status === "PENDING") {
        return <ScheduleIcon {...getOrderButtonIcon()} />
    }
    return null;
}
