import React from "react";
import CustomGrid from '../../components/Grid/CustomGrid';
import { mainTag } from '../../themes/defaultThemes';
import useDocumentTitle from "../../utils/useDocumentTitle";

/**
 * The TestComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function Test2Component() {
    //change document title
    useDocumentTitle(`Usee | Test`);

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="test2" sx={mainTag()}>
            <p>Token: {localStorage.getItem("token")}</p>
        </CustomGrid>
    );
}

export default Test2Component;