import { Grid } from "@mui/material";
import CustomGrid from 'components/Grid/CustomGrid';
import AnalyticsRatings from "components/Grid/analytics/AnalyticsRatings";
import CustomTitle from "components/Title/CustomTitle";
import { useTranslation } from "react-i18next";
import { mainTag, mainTagTitle } from 'themes/defaultThemes';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The RatingComponent, that presents a Rating component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function RatingComponent() {
    const { t } = useTranslation();

    //change document title
    useDocumentTitle(`Usee | ${t('rating.label')}`);

    // ===============================================================================RENDER

    return (
        <CustomGrid role="main" id="rating" sx={mainTag()}>
            <Grid container spacing={2} sx={{ alignItems: "center", mb: "20px" }}>
                <Grid item xs={12} sm={3}>
                    <CustomGrid sx={mainTagTitle()}>
                        <CustomTitle
                            title={t('rating.label')}
                            subtitle={t('rating.subtitle')}
                        />
                    </CustomGrid>

                </Grid>
            </Grid>

            <AnalyticsRatings />
        </CustomGrid>
    );
}

export default RatingComponent;