import {
    CloseRounded,
    CropFree as CropFreeIcon,
    Image as ImageIcon,
    Info as InfoIcon,
    ColorLens as PalletteIcon,
    Pattern as PatternIcon,
    Tune as TuneIcon,
    Visibility as VisibilityIcon
} from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import {
    Box,
    Button,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Radio,
    RadioGroup,
    Slider,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';
import {
    LogoPaddingCircle,
    LogoPaddingSquare,
    PatternCircle,
    PatternSquare
} from 'assets/svg/SvgIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import CustomChip from 'components/Chip/CustomChip';
import CustomQRCode from 'components/QRCode/CustomQRCode';
import QRCodeEyeColor from 'components/QRCode/QRCodeEyeColor';
import QRCodeFrame from 'components/QRCode/QRCodeFrame';
import CustomTabs from 'components/Tab/CustomTabs';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTypography from 'components/Typography/CustomTypography';
import { getQrcodeEyeTabsIconProperties, getQrcodeFrameFontSize, getQrcodeLogoHeight, getQrcodeLogoOpacity, getQrcodeLogoPadding, getQrcodeLogoWidth, getQrcodeQuietZone } from 'constants/fieldProperties';
import { MuiColorInput } from 'mui-color-input';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { infoTooltip, logoStyle } from 'themes/defaultThemes';
import { getFrameGrid, getPatternGrid } from 'themes/qrcode/defaultTheme';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';

/**
 * The AddEditQRcodeTemplate, that display a customized dialog to add/edit a qrcode template.
 *
 * @param {object} qrcode the object containing details for qrcode template
 * @param {boolean} isOpen If the values is `true`, the modal should be open and visible. Default value is false.
 * @param {boolean} isAdd If the values is `true`, the modal refering to creation of a new qrcode template.
 * @param {function} handleOpen the function that handles the dialog open state
 * @param {function} addQrCodeTemplate the function that handles the creation of a  qrcode template
 * @param {function} updateQrCodeTemplate the function that handles the modification of a qrcode template
 * @param {string} logoImgSrc the outlet logo image
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AddEditQRcodeTemplate({
    qrcode,
    isOpen = false,
    isAdd,
    handleOpen,
    addQrCodeTemplate,
    updateQrCodeTemplate,
    logoImgSrc,
    organizationID,
    outletID,
    index
}) {
    const { t } = useTranslation();
    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);
    const [qrCodeTemplate, setQrCodeTemplate] = useState(qrcode);
    const [value, setValue] = React.useState("assets");
    const [eyeTabValue, setEyeTabValue] = React.useState("left");
    console.log(qrCodeTemplate)

    useEffect(() => {
        setQrCodeTemplate(qrcode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qrcode]);

    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpened If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpened) {
        setOpen(isOpened);
        handleOpen(isOpened);
    }

    function handleAction() {
        setOpen(false);
        handleOpen(false);
        isAdd ? addQrCodeTemplate(qrCodeTemplate) : updateQrCodeTemplate(qrCodeTemplate, index)
    }

    function updateEyeColor(position = "left", isOuterEye = false, newColorValue) {
        let newEyeColor = qrCodeTemplate.eyeColor;

        let positionIndex = 0;
        switch (position) {
            case "left":
                positionIndex = 0;
                break;
            case "right":
                positionIndex = 1;
                break;
            case "bottom":
                positionIndex = 2;
                break;

            default:
                break;
        }

        newEyeColor[positionIndex][isOuterEye ? "outer" : "inner"] = newColorValue;
        formValChangeWithParentElementWithNameAndValue("eyeColor", newEyeColor, qrCodeTemplate, setQrCodeTemplate)
    }

    function updateEyeRadius(position = "left", isOuterEye = false, newRadiusValue) {
        let newEyeRadius = qrCodeTemplate.eyeRadius;

        let positionIndex = 0;
        switch (position) {
            case "left":
                positionIndex = 0;
                break;
            case "right":
                positionIndex = 1;
                break;
            case "bottom":
                positionIndex = 2;
                break;

            default:
                break;
        }

        newEyeRadius[positionIndex][isOuterEye ? "outer" : "inner"] = newRadiusValue;
        formValChangeWithParentElementWithNameAndValue("eyeRadius", newEyeRadius, qrCodeTemplate, setQrCodeTemplate)
    }


    return (
        <Dialog
            open={open}
            onClose={() => { handleDialogState(false) }}
            aria-describedby="alert-dialog-slide-description"
            className="dialog-title"
        >
            <DialogTitle className="dialog-headers">
                {isAdd ? `${t('actions.add')} ${t('qrcode.templates.labelSingle')}` : `${t('actions.edit')} ${t('qrcode.templates.labelSingle')}`}
                {/* {isAdd ? `${t('actions.add')} ${t('loyalty.qrcode.labelSingle')}` : `${t('actions.edit')} ${t('loyalty.qrcode.labelSingle')} '${qrCodeTemplate.name.en}'`} */}
                <IconButton onClick={() => { handleDialogState(false) }} sx={{ float: "right" }}>
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ background: "#fff" }}>
                <Box component="div" sx={{ padding: "10px", textAlign: "center", marginBottom: "40px", maxWidth: "90%" }}>

                    {/* PREVIEW QRCODE */}
                    <CustomQRCode
                        {...qrCodeTemplate}
                        value={"usee"}
                        logoImage={qrCodeTemplate.icon !== "none" ? logoImgSrc.includes("base64") ?
                            logoImgSrc
                            :
                            `https://storage.usee.gr/usee/organization/${organizationID}/outlet/${outletID}/${logoImgSrc}`
                            : ""}
                    />
                </Box>

                <TabContext value={value}>
                    <CustomTabs
                        initValue={value}
                        instances={[
                            { name: t('qrcode.settings.assets.label'), value: "assets", icon: <ImageIcon /> },
                            { name: t('qrcode.settings.patterns.label'), value: "patterns", icon: <PatternIcon /> },
                            { name: t('qrcode.settings.colors.label'), value: "color", icon: <PalletteIcon /> },
                            { name: t('qrcode.settings.frame.label'), value: "frame", icon: <CropFreeIcon /> },
                            { name: t('qrcode.settings.eyes.label'), value: "eyes", icon: <VisibilityIcon /> },
                            { name: t('qrcode.settings.options.label'), value: "options", icon: <TuneIcon /> }
                        ]}
                        setValue={setValue}
                        sx={{ marginBottom: "20px" }}
                        scrollButtons="auto"
                    />

                    <TabPanel value={"assets"} sx={{ paddingTop: "0px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                {(logoImgSrc === null || logoImgSrc === "") &&
                                    <CustomTypography text={t('qrcode.settings.logoMissing')} />
                                }

                                {(logoImgSrc !== null && logoImgSrc !== "") &&
                                    <CardMedia
                                        component="img"
                                        image={logoImgSrc.includes("base64") ?
                                            logoImgSrc
                                            :
                                            `https://storage.usee.gr/usee/organization/${organizationID}/outlet/${outletID}/${logoImgSrc}`}
                                        alt={"logo"}
                                        sx={logoStyle()}
                                    />
                                }
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomCheckbox
                                    onChange={(event) => {
                                        formValChangeWithParentElementWithNameAndValue("icon", event.target.checked ? "logo" : "none", qrCodeTemplate, setQrCodeTemplate);
                                    }}
                                    selected={qrCodeTemplate.icon !== "none"}
                                    label={t('qrcode.templates.includeLogo')}
                                />
                            </Grid>

                            {qrCodeTemplate.icon !== "none" &&
                                <>
                                    <Grid item xs={12} md={8}>
                                        <FormControlLabel control={<Switch
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("removeQrCodeBehindLogo", event.target.checked, qrCodeTemplate, setQrCodeTemplate)}
                                            checked={qrCodeTemplate.removeQrCodeBehindLogo} />}
                                            sx={{ marginLeft: "20px" }}
                                            label={t('qrcode.templates.removeQrCodeBehindLogo')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                        <Grid container gap={4}>
                                            <Grid item xs={3.5} md={3.5}>
                                                <InputLabel required={false} htmlFor="logoWidth">{t('qrcode.settings.assets.logoWidth')}</InputLabel>
                                                <Slider
                                                    value={qrCodeTemplate.logoWidth}
                                                    min={getQrcodeLogoWidth().min}
                                                    max={getQrcodeLogoWidth().max}
                                                    step={getQrcodeLogoWidth().step}
                                                    valueLabelDisplay={"on"}
                                                    sx={{ marginTop: "40px", marginLeft: "0px" }}
                                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("logoWidth", event.target.value, qrCodeTemplate, setQrCodeTemplate)}
                                                />
                                            </Grid>

                                            <Grid item xs={3.5} md={3.5}>
                                                <InputLabel required={false} htmlFor="logoHeight">{t('qrcode.settings.assets.logoHeight')}</InputLabel>
                                                <Slider
                                                    value={qrCodeTemplate.logoHeight}
                                                    min={getQrcodeLogoHeight().min}
                                                    max={getQrcodeLogoHeight().max}
                                                    step={getQrcodeLogoHeight().step}
                                                    valueLabelDisplay={"on"}
                                                    sx={{ marginTop: "40px", marginLeft: "0px" }}
                                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("logoHeight", event.target.value, qrCodeTemplate, setQrCodeTemplate)}
                                                />
                                            </Grid>

                                            <Grid item xs={3} md={3}>
                                                <InputLabel required={false} htmlFor="logoOpacity">{t('qrcode.settings.assets.logoOpacity')}</InputLabel>
                                                <Slider
                                                    value={qrCodeTemplate.logoOpacity}
                                                    min={getQrcodeLogoOpacity().min}
                                                    max={getQrcodeLogoOpacity().max}
                                                    step={getQrcodeLogoOpacity().step}
                                                    valueLabelDisplay={"on"}
                                                    sx={{ marginTop: "40px", marginLeft: "0px" }}
                                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("logoOpacity", event.target.value, qrCodeTemplate, setQrCodeTemplate)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
                                        <Grid container gap={2}>
                                            <Grid item xs={12} md={12}>
                                                <InputLabel required={false} htmlFor="logoPadding">{t('qrcode.settings.assets.logoPadding')}</InputLabel>
                                            </Grid>

                                            <Grid item xs={6} md={6}>
                                                <Slider
                                                    value={qrCodeTemplate.logoPadding}
                                                    min={getQrcodeLogoPadding().min}
                                                    max={getQrcodeLogoPadding().max}
                                                    step={getQrcodeLogoPadding().step}
                                                    valueLabelDisplay={"on"}
                                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("logoPadding", event.target.value, qrCodeTemplate, setQrCodeTemplate)}
                                                    sx={{ marginTop: "40px" }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CustomIconButton
                                                    keyValue={`logo-padding-style-square`}
                                                    onClick={() => formValChangeWithParentElementWithNameAndValue("logoPaddingStyle", "square", qrCodeTemplate, setQrCodeTemplate)}
                                                    variant='outlined'
                                                    backgroundColor="#dcf1f7"
                                                    isFocused={qrCodeTemplate.logoPaddingStyle === "square"}
                                                    sx={getFrameGrid()}
                                                    label={undefined}
                                                    icon={<LogoPaddingSquare />}
                                                />
                                            </Grid>
                                            <Grid item >
                                                <CustomIconButton
                                                    keyValue={`logo-padding-style-circle`}
                                                    onClick={() => formValChangeWithParentElementWithNameAndValue("logoPaddingStyle", "circle", qrCodeTemplate, setQrCodeTemplate)}
                                                    variant='outlined'
                                                    backgroundColor="#dcf1f7"
                                                    isFocused={qrCodeTemplate.logoPaddingStyle === "circle"}
                                                    sx={getFrameGrid()}
                                                    label={undefined}
                                                    icon={<LogoPaddingCircle />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </TabPanel>

                    <TabPanel value={"color"} sx={{ paddingTop: "0px" }}>
                        <Grid container gap={2}>
                            <Grid item xs={5.5}>
                                <InputLabel required={false} htmlFor="bgColor">{t('qrcode.settings.colors.bgColor')}</InputLabel>
                                <MuiColorInput
                                    id="bgColor"
                                    name="bgColor"
                                    format="hex8"
                                    value={qrCodeTemplate.bgColor}
                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("bgColor", value, qrCodeTemplate, setQrCodeTemplate)}
                                />
                            </Grid>
                            <Grid item xs={5.5}>
                                <InputLabel required={false} htmlFor="fgColor">{t('qrcode.settings.colors.fgColor')}</InputLabel>
                                <MuiColorInput
                                    id="fgColor"
                                    name="fgColor"
                                    format="hex8"
                                    value={qrCodeTemplate.fgColor}
                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("fgColor", value, qrCodeTemplate, setQrCodeTemplate)}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={"frame"} sx={{ paddingTop: "0px" }}>
                        <Grid container gap={2}>
                            <Grid item xs={12} md={6}>
                                <InputLabel required={false} htmlFor="frameText">{t('qrcode.settings.frame.frameText')}</InputLabel>
                                <CustomTextField
                                    name="frameText"
                                    value={qrCodeTemplate.frameText}
                                    required={false}
                                    sx={{ border: "none" }}
                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("frameText", event.target.value, qrCodeTemplate, setQrCodeTemplate)}
                                />
                                <CustomChip label={"%table_label"} onClick={() => formValChangeWithParentElementWithNameAndValue("frameText", qrCodeTemplate?.frameText + " %table_label", qrCodeTemplate, setQrCodeTemplate)} />
                            </Grid>

                            {qrCodeTemplate.frameText !== "" &&
                                <>
                                    <Grid item xs={6} md={6}>
                                        <InputLabel required={false} htmlFor="frameTextColor">{t('qrcode.settings.frame.frameTextColor')}</InputLabel>
                                        <MuiColorInput
                                            id="frameTextColor"
                                            name="frameTextColor"
                                            format="hex8"
                                            value={qrCodeTemplate.frameTextColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("frameTextColor", value, qrCodeTemplate, setQrCodeTemplate)}
                                        />
                                    </Grid>
                                    <Grid item xs={5.5} md={5.5}>
                                        <InputLabel required={false} htmlFor="frameTextFontSize">{t('qrcode.settings.frame.frameTextFontSize')}</InputLabel>
                                        <Slider
                                            aria-label="Frame font Size"
                                            value={qrCodeTemplate.frameTextFontSize}
                                            step={getQrcodeFrameFontSize().step}
                                            min={getQrcodeFrameFontSize().min}
                                            max={getQrcodeFrameFontSize().max}
                                            valueLabelDisplay="on"
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("frameTextFontSize", event.target.value, qrCodeTemplate, setQrCodeTemplate)}
                                            sx={{ marginTop: "30px" }}
                                        />
                                    </Grid>
                                </>
                            }

                            <Grid item xs={6} md={6}>
                                <InputLabel required={false} htmlFor="frameColor">{t('qrcode.settings.frame.frameColor')}</InputLabel>
                                <MuiColorInput
                                    id="frameColor"
                                    name="frameColor"
                                    format="hex8"
                                    value={qrCodeTemplate.frameColor}
                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("frameColor", value, qrCodeTemplate, setQrCodeTemplate)}
                                />
                            </Grid>
                            {/* <Divider  variant="fullWidth" absolute={true}/>
                            <InputLabel>{t('qrcode.settings.frame.style')}</InputLabel> */}

                            <QRCodeFrame
                                qrCodeTemplate={qrCodeTemplate}
                                setQrCodeTemplate={setQrCodeTemplate}
                                formValChangeWithParentElementWithNameAndValue={formValChangeWithParentElementWithNameAndValue}
                            />

                        </Grid>
                    </TabPanel>

                    <TabPanel value={"eyes"} sx={{ paddingTop: "0px" }}>
                        <TabContext value={eyeTabValue}>
                            <CustomTabs
                                initValue={eyeTabValue}
                                instances={[
                                    {
                                        name: t('qrcode.settings.eyes.left'),
                                        value: "left",
                                        icon: <CustomQRCode {...getQrcodeEyeTabsIconProperties(0)} />
                                    },
                                    {
                                        name: t('qrcode.settings.eyes.right'),
                                        value: "right",
                                        icon: <CustomQRCode {...getQrcodeEyeTabsIconProperties(1)} />
                                    },
                                    {
                                        name: t('qrcode.settings.eyes.bottom'),
                                        value: "bottom",
                                        icon: <CustomQRCode {...getQrcodeEyeTabsIconProperties(2)} />
                                    },
                                ]}
                                setValue={setEyeTabValue}
                                sx={{ marginBottom: "20px" }}
                                centered={true}
                            />

                            <Divider />

                            <TabPanel value={"left"} sx={{ paddingTop: "10px" }}>
                                <QRCodeEyeColor
                                    qrCodeTemplate={qrCodeTemplate}
                                    whichEye="left"
                                    index={0}
                                    updateEyeRadius={updateEyeRadius}
                                    updateEyeColor={updateEyeColor}
                                />
                            </TabPanel>

                            <TabPanel value={"right"} sx={{ paddingTop: "10px" }}>
                                <QRCodeEyeColor
                                    qrCodeTemplate={qrCodeTemplate}
                                    whichEye="right"
                                    index={1}
                                    updateEyeRadius={updateEyeRadius}
                                    updateEyeColor={updateEyeColor}
                                />
                            </TabPanel>

                            <TabPanel value={"bottom"} sx={{ paddingTop: "10px" }}>
                                <QRCodeEyeColor
                                    qrCodeTemplate={qrCodeTemplate}
                                    whichEye="bottom"
                                    index={2}
                                    updateEyeRadius={updateEyeRadius}
                                    updateEyeColor={updateEyeColor}
                                />
                            </TabPanel>

                        </TabContext>
                    </TabPanel>

                    <TabPanel value={"patterns"} sx={{ paddingTop: "0px" }}>
                        <Grid container gap={2}>
                            <Grid item>
                                <CustomIconButton
                                    keyValue={`pattern-square`}
                                    onClick={(value) => formValChangeWithParentElementWithNameAndValue("qrStyle", "squares", qrCodeTemplate, setQrCodeTemplate)}
                                    variant='outlined'
                                    isFocused={qrCodeTemplate.qrStyle === "squares"}
                                    backgroundColor="#dcf1f7"
                                    sx={getPatternGrid()}
                                    label={undefined}
                                    icon={<PatternSquare width={60} height={60} style={{ background: "transparent" }} />}
                                />
                            </Grid>
                            <Grid item>
                                <CustomIconButton
                                    keyValue={`pattern-dots`}
                                    onClick={(value) => formValChangeWithParentElementWithNameAndValue("qrStyle", "dots", qrCodeTemplate, setQrCodeTemplate)}
                                    variant='outlined'
                                    isFocused={qrCodeTemplate.qrStyle === "dots"}
                                    backgroundColor="#dcf1f7"
                                    sx={getPatternGrid()}
                                    label={undefined}
                                    icon={<PatternCircle width={60} height={60} style={{ background: "transparent" }} />}
                                />
                            </Grid>
                        </Grid>

                    </TabPanel>

                    <TabPanel value={"options"}>
                        <Grid container gap={1}>
                            <Grid item xs={5.5} md={5.5}>
                                <Grid container>
                                    <Grid item>
                                        <Typography variant="body2" >{t('qrcode.settings.options.ecLevel.label')}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip arrow placement="right" title={t('qrcode.settings.options.ecLevel.info')} componentsProps={{ tooltip: { sx: infoTooltip() } }}><InfoIcon /></Tooltip>
                                    </Grid>
                                </Grid>
                                <RadioGroup
                                    value="Q"
                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("ecLevel", event.target.value, qrCodeTemplate, setQrCodeTemplate)}
                                >
                                    <FormControlLabel value="L" control={<Radio />} label={t('qrcode.settings.options.ecLevel.L')} />
                                    <FormControlLabel value="M" control={<Radio />} label={t('qrcode.settings.options.ecLevel.M')} />
                                    <FormControlLabel value="Q" control={<Radio />} label={t('qrcode.settings.options.ecLevel.Q')} />
                                    <FormControlLabel value="H" control={<Radio />} label={t('qrcode.settings.options.ecLevel.H')} />
                                </RadioGroup>
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <InputLabel required={false} htmlFor="quietZone">{t('qrcode.settings.options.quietZone')}</InputLabel>
                                <Slider
                                    aria-label="Quiet Zone"
                                    value={qrCodeTemplate.quietZone}
                                    step={getQrcodeQuietZone().step}
                                    min={getQrcodeQuietZone().min}
                                    max={getQrcodeQuietZone().max}
                                    valueLabelDisplay="on"
                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("quietZone", event.target.value, qrCodeTemplate, setQrCodeTemplate)}
                                    sx={{ marginTop: "30px" }}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>

                </TabContext>
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button className="dialog-cancel-btn" onClick={() => { handleDialogState(false) }}>{t("actions.cancel")}</Button>
                <Button className="dialog-action-btn" onClick={() => { handleAction() }}>{isAdd ? t('actions.create') : t('actions.save')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddEditQRcodeTemplate;