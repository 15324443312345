import { Fab, Grid, Tooltip } from "@mui/material";
import subscriptionApi from "api/subscription";
import { AddIcon } from "assets/svg/SvgIcons";
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from 'components/Dialogs/CustomDialog';
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from 'components/Title/CustomTitle';
import { getDateTimeFormatter } from "constants/calendar";
import { getDefaultSortDirectionData } from "constants/sortDirection";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router";
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagTitle } from 'themes/defaultThemes';
import { hasRoleAdmin } from "utils/auth";
import { formValChangeWithParentElementWithNameAndValue } from "utils/form-validation";
import { prepareTablePaginationSortParams } from "utils/functions";
import { getLanguageFromURL } from 'utils/language';
import { reloadPage } from "utils/page";
import { actionFormatter, dateFormatter, idFormatterLinkWithParent, idFormatterLinkWithParentAndNameLabel, statusFormatter } from 'utils/table';
import useDocumentTitle from 'utils/useDocumentTitle';

/**
 * The SubscriptionListComponent, that allow the user to view the subscriptions list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SubscriptionListComponent() {
   const { t } = useTranslation();
   const { organizationID } = useParams();
   const navigate = useNavigate();
   //change document title
   useDocumentTitle(`Usee | ${t('subscription.title')}`)

   /**
    * @type {object}
    * 
   * @property {object} userAuth the authenticated user infromation
   * @property {array} subscriptions the subscriptions list
   * @property {array} statusOptions the different status options
   * @property {array} subscriptionPlans the subscription options to select from
   * @property {array} organizations the organization options to select from
   * @property {object} pagination includes all pageable details (page, size, sort, direction)
    */
   const [data, setData] = useState({
      userAuth: null,
      subscriptions: null,
      statusOptions: [],
      subscriptionPlans: [],
      organizations: [],
      pagination: {
         count: -1,
         size: 10,
         page: 0,
         ...getDefaultSortDirectionData
      }
   });

   // to perform initial request
   const [isFirstTime, setIsFirstTime] = useState(true);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);
   // if true the menu item is clicked and a redirect to page should perform
   const [redirect, setRedirect] = useState(false);
   // for search
   const [search, setSearch] = useState({
      organizationID: "",
      planID: "",
      status: ""
   });

   // pop dialog for delete
   const [dialog, setDialog] = useState({
      isOpen: false,
      subscriptionID: "",
      name: ""
   });
   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });

   useEffect(() => {
      getSubscriptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [searchValue, setSearchValue] = useState('');

   useEffect(() => {
      getSubscriptions();
      setIsFirstTime(false);
   }, []);

   useEffect(() => {
      const identifier = setTimeout(() => {
         if (!isFirstTime) getSubscriptions();
      }, 700);

      return () => {
         clearTimeout(identifier);
      }
   }, [searchValue, search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])

   /**
    * The rest endpoint to get the subscriptions list.
    * @property {number} page the list page number
    */
   function getSubscriptions() {
      subscriptionApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
         setData({
            ...data,
            userAuth: r.data.returnobject.userAuth,
            subscriptions: r.data.returnobject.page,
            statusOptions: r.data.returnobject.statusOptions,
            subscriptionPlans: r.data.returnobject.subscriptionPlans,
            organizations: r.data.returnobject.organizations,
            pagination: {
               ...data.pagination,
               count: r.data.returnobject.page.totalPages,
               size: r.data.returnobject.page.size,
               page: r.data.returnobject.page.number
            }
         });
         setIsLoaded(true);
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * 
    * Function that triggers the removal of the selected subscription plan.
    * 
    * @property {*} event
    * @property {string} subscriptionID The id of the selected subscription plan to be removed.
    */
   function deleteSubscription(event, subscriptionID, name) {
      handleDialogState(true, subscriptionID, name);
   }

   /**
    * Function that handles the delete modal open or close data.
    * 
    * @property {boolean} isOpen The state of the dialog open status.
    * @property {string} subscriptionID The id of the selected subscription plan to be removed.
    */
   function handleDialogState(isOpen, subscriptionID = "", name = "") {
      setDialog({
         ...dialog,
         isOpen: isOpen,
         subscriptionID: subscriptionID,
         name: name
      });
   }

   /**
    * Gets called to remove the selected subscription plan
    */
   function deleteS() {
      subscriptionApi.deleteById(dialog.subscriptionID).then((r) => {
         handleDialogState(false);
         setAlertBox({
            ...alertBox,
            isAOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getSubscriptions();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected subscription plan
    * @param {*} event
    * @param {string} id the id of the subscription plan
    */
   function activateSubscription(event, id) {
      subscriptionApi.activate(id).then((r) => {
         setAlertBox({
            ...alertBox,
            isAOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getSubscriptions();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected subscription plan
    * @param {*} event
    * @param {string} id the id of the subscription plan 
    */
   function disableSubscription(event, id) {
      subscriptionApi.cancel(id).then((r) => {
         setAlertBox({
            ...alertBox,
            isAOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getSubscriptions();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Function that handles the snackbar open or close state.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }

   /**
    * Function that handles the pagination information.
    * @property {int} page the page to be redirected
    */
   function handlePaginationChange(page) {
      formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
   }

   /**
    * Function that handles the pagination information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleRowsPerPageChange(size) {
      let pagination = data.pagination;
      pagination.size = size;
      pagination.page = 0;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }
   /**
    * Function that handles the sort information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleColumnSortChange(changedColumn, direction) {
      let pagination = data.pagination;
      pagination.sort = changedColumn;
      pagination.direction = direction;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }

   function onFilterChange(event) {
      if (event !== null) {
         let field = "";
         let value = "";
         if (event.field === "organization") {
            value = data.organizations?.find((org) => org.name === event.value)?.id
            field = `${event.field}ID`;
         } else if (event.field === "subscriptionPlan") {
            value = data.subscriptionPlans?.find((plan) => plan.name === event.value)?.id
            field = `planID`;
         } else {
            value = event.value
            field = event.field
         }

         formValChangeWithParentElementWithNameAndValue(field, value, search, setSearch)
      } else {
         setSearch({
            ...search,
            organizationID: "",
            planID: "",
            status: ""
         })
      }
   }

   // ===============================================================================RENDER

   /**
    * datatable columns.
    */
   const table_columns = [
      {
         name: "id",
         label: t('label.id'),
         options: {
            filter: false,
            sort: false,
            empty: false,
            display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
            customBodyRender: (value, tableMeta) => {
               return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], `/${getLanguageFromURL()}/subscription`);
            }
         }
      },
      {
         name: "organization",
         label: t('org.pageTitle'),
         options: {
            filter: true,
            filterType: 'dropdown',
            customFilterListRender: v => `${t('org.pageTitle')}: ${v}`,
            filterOptions: {
               names: data.organizations.map(org => org.name),
            },
            sort: false,
            empty: false,
            display: organizationID !== null ? true : false,
            customBodyRender: (value, tableMeta) => {
               return idFormatterLinkWithParentAndNameLabel(value, tableMeta.tableData[tableMeta.rowIndex], `/${getLanguageFromURL()}/organization`);
            }
         }
      },
      {
         name: "subscriptionPlan",
         label: t('subscriptionPlan.pageTitle'),
         options: {
            filter: true,
            filterType: 'dropdown',
            customFilterListRender: v => `${t('subscriptionPlan.pageTitle')}: ${v}`,
            filterOptions: {
               names: data.subscriptionPlans.map(plan => plan.name),
            },
            sort: false,
            empty: false,
            customBodyRender: (value, tableMeta) => {
               return idFormatterLinkWithParentAndNameLabel(value, tableMeta.tableData[tableMeta.rowIndex], `/${getLanguageFromURL()}/subscription-plan`);
            }
         }
      },
      {
         name: "status",
         label: t('label.status'),
         options: {
            filter: true,
            filterOptions: {
               names: data.statusOptions
            },
            filterType: 'select',
            customFilterListRender: v => `${t('label.status')}: ${v}`,
            sort: true,
            empty: false,
            customBodyRender: (value, tableMeta) => {
               return statusFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: 'N/A' });
            }
         }
      },
      {
         name: "dateCreated",
         label: t('label.dateCreated'),
         options: {
            filter: false,
            sort: true,
            empty: false,
            customBodyRender: (value, tableMeta) => {
               return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateTimeFormatter());
            }
         }
      },
      {
         name: "dueDate",
         label: t('label.dueDate'),
         options: {
            filter: false,
            sort: true,
            empty: false,
            customBodyRender: (value, tableMeta) => {
               return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], "dueDate", getDateTimeFormatter(), t);
            }
         }
      },
      {
         name: "",
         label: "",
         options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {
               return actionFormatter(value,
                  tableMeta.tableData[tableMeta.rowIndex],
                  tableMeta.rowIndex,
                  0,
                  null,
                  null,
                  null,
                  null,
                  `/${getLanguageFromURL()}/subscription`,
                  data.showLabel ? t('actions.edit') : undefined,
                  (event, rowIndex, name) => deleteSubscription(event, rowIndex, name),
                  data.showLabel ? t('actions.delete') : undefined,
                  true
               )
            },
            setCellProps: () => ({ className: "click action" })
         }
      }
   ];

   // ===============================================================================RENDER

   if (redirect) {
      reloadPage();
   } else if (!isLoaded) {
      return <LoadingSkeleton lines={9} />;
   } else {
      let defaultStatusSelected = "";
      let defaultOrganizationSelected = "";
      let defaultSubscriptionPlanSelected = "";
      if (search.status !== "") {
         defaultStatusSelected = search.status;
         // if (data.search.organization !== "" && data.organizations.length !== 0) defaultOrganizationSelected = data.organizations.find(org => equalsIgnoreCase(org.id, data.search.organizationID)).id;
         // if (data.search.subscriptionPlan !== "" && data.subscriptionPlans.length !== 0) defaultSubscriptionPlanSelected = data.subscriptionPlans.find(plan => equalsIgnoreCase(plan.id, data.search.planID)).id;
      }

      return (
         <CustomGrid role="main" id="subscription-list" sx={mainTag()}>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
               <Grid item xs={"auto"}>
                  <CustomRefreshButton onClick={getSubscriptions} marginTop="0" />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <CustomGrid sx={mainTagTitle()}>
                     <CustomTitle
                        title={t('subscription.title')}
                        subtitle={t('subscription.subtitle')}
                     />
                  </CustomGrid>
               </Grid>
            </Grid>

            <CustomGrid sx={mainAreaTable()} container={false}>
               <CustomPageableTable
                  instances={data.subscriptions}
                  emptyMessage={t('table.noData') + t('actions.tableCreateNewLink') + t('subscription.pageTitle')}
                  table_columns={table_columns}
                  addPageUrl={`/${getLanguageFromURL()}/subscription/add`}
                  keyField="id"
                  defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                  pagination={data.pagination}
                  handlePaginationChange={handlePaginationChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handleColumnSortChange={handleColumnSortChange}
                  filter={true}
                  onFilterChange={onFilterChange}
               />
            </CustomGrid>

            <Fab color="primary"
               aria-label="add"
               sx={fabStyle()}
               onClick={() => navigate(`/${getLanguageFromURL()}/subscription/add`)}
            >
               <Tooltip arrow title={t('actions.add') + " " + t('subscription.pageTitle')} sx={fabTooltipStyle()}>
                  <AddIcon {...fabStyleIcon()} />
               </Tooltip>
            </Fab>

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }

            {dialog.isOpen &&
               <CustomDialog
                  isOpen={dialog.isOpen}
                  id="delete"
                  title={t('actions.deleteModal.title')}
                  actionLabel={t("actions.deleteModal.message")}
                  cancelLabel={t("actions.cancel")}
                  message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} the ${t('subscription.pageTitle')}. `}
                  boldMessage={<span style={{ fontWeight: "bold" }}>{dialog.subscriptionID}</span>}
                  action={deleteS}
                  handleOpen={handleDialogState}
                  actionColor="#e91e1e"
               />
            }
         </CustomGrid>
      );
   }
}

export default SubscriptionListComponent;