
import { Grid } from '@mui/material';
import CustomGrid from 'components/Grid/CustomGrid';
import AnalyticsEmployee from 'components/Grid/analytics/AnalyticsEmployee';
import { AuthConsumer } from 'context/AuthContext';
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/material.css';
import { mainTag } from 'themes/defaultThemes';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The EmployeeStatsComponent shows employee analtics.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function EmployeeStatsComponent() {
    const { t } = useTranslation();

    //change document title
    useDocumentTitle(`Usee | ${t('analytics.label')}`);

    // ===============================================================================RENDER

    return (
        <AuthConsumer>
            {({ user }) => (
                <CustomGrid role="main" id={"analytics"} sx={mainTag()}>
                    <Grid item xs={12}>
                        <AnalyticsEmployee
                            employeeID={user?.id}
                            forPDA={true}
                        />
                    </Grid>
                </CustomGrid>
            )}
        </AuthConsumer>
    );
}

export default EmployeeStatsComponent;