export const getEyeRadius = (option, scaleFactor) => {
    if (option === "square") return 0 * scaleFactor;
    else if (option === "circle") return 20 * scaleFactor;
    else if (option === "curved") return 2 * scaleFactor;
    else if (option === "rounded") return 3 * scaleFactor;
    else if (option === "leafTopLeft") return [10 * scaleFactor, 10 * scaleFactor, 0 * scaleFactor, 10 * scaleFactor];
    else if (option === "leafTopRight") return [10 * scaleFactor, 10 * scaleFactor, 10 * scaleFactor, 0 * scaleFactor];
    else if (option === "leafBottomLeft") return [10 * scaleFactor, 0 * scaleFactor, 10 * scaleFactor, 10 * scaleFactor];
    else if (option === "leafTBottomRight") return [0 * scaleFactor, 10 * scaleFactor, 10 * scaleFactor, 10 * scaleFactor];
    else if (option === "leafRight") return [10 * scaleFactor, 0 * scaleFactor, 10 * scaleFactor, 0 * scaleFactor];
    else if (option === "leafLeft") return [0 * scaleFactor, 10 * scaleFactor, 0 * scaleFactor, 10 * scaleFactor];
    else if (option === "diamondLeft") return [0 * scaleFactor, 5 * scaleFactor, 0 * scaleFactor, 5 * scaleFactor];
    else if (option === "diamondRight") return [5 * scaleFactor, 0 * scaleFactor, 5 * scaleFactor, 0 * scaleFactor];
    else if (option === "gridTopLeft") return [20 * scaleFactor, 0 * scaleFactor, 0 * scaleFactor, 0 * scaleFactor];
    else if (option === "gridTopRight") return [0 * scaleFactor, 20 * scaleFactor, 0 * scaleFactor, 0 * scaleFactor];
    else if (option === "gridBottomLeft") return [0 * scaleFactor, 0 * scaleFactor, 0 * scaleFactor, 20 * scaleFactor];
    else if (option === "gridBottomRight") return [0 * scaleFactor, 0 * scaleFactor, 20 * scaleFactor, 0 * scaleFactor];
    // else if (option === "alien") return0*scaleFactor;
    else if (option === "eyeLeft") return [0 * scaleFactor, 20 * scaleFactor, 0 * scaleFactor, 20 * scaleFactor];
    else if (option === "eyeRight") return [20 * scaleFactor, 0 * scaleFactor, 20 * scaleFactor, 0 * scaleFactor];
    else return 0 * scaleFactor;
}