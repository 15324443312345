import { Button, Fab, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AddIcon } from "assets/mui/MuiIcons";
import { useTranslation } from "react-i18next";

/**
 * Add Chat-Channel Dialog Content.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function EmptyChatChannelsOrUnselected({ channels, selectedChatChannel, setOpenChatChannelDialog, setOpenChatDrawer }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));
console.log(channels)
    return (
        <Grid
            container
            direction='column'
            flexWrap='nowrap'
            alignItems='center'
            justifyContent='center'
            sx={{ height: "100%", width: "100%", p: "24px" }}
        >
            <Grid item display='flex' flexDirection='column' justifyContent='center'>
                <Typography variant='h5' align='center'>
                    {channels?.length > 0 ? (selectedChatChannel === null ? t('chat.channel.selectOne') : "") : t('chat.channel.emptyLabel')}
                </Typography>
                {matchesXS && (
                    <>
                        {channels?.length > 0 &&
                            <Button
                                variant='contained'
                                fullWidth={false}
                                sx={{
                                    background: "#17a2b8",
                                    my: "1em",
                                    textTransform: "none",
                                    fontWeight: 700,
                                }}
                                onClick={() => setOpenChatDrawer(true)}
                            >
                                {t('chat.channels.selectNow')}
                            </Button>
                        }
                        {channels?.length == 0 &&
                            <Fab color="primary"
                                aria-label="add"
                                sx={
                                    {
                                        position: 'fixed',
                                        bottom: 16,
                                        right: 16,
                                        background: "#205d7b",
                                        color: "#fff",
                                    }
                                }
                                onClick={() => setOpenChatChannelDialog(true)}
                            >
                                <AddIcon />
                            </Fab>
                        }
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default EmptyChatChannelsOrUnselected;
