import { getQrcodeEyeColorProperties } from 'constants/fieldProperties';
import CustomQRCode from './CustomQRCode';
import { MuiColorInput } from 'mui-color-input';

import { Grid, InputLabel, withTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QRCodeEyeRadius from './QRCodeEyeRadius';


/**
 * Custom QRcode eye color options
 * @returns 
 */
function QRCodeEyeColor({
    qrCodeTemplate,
    whichEye = "left",
    index = 0,
    updateEyeRadius,
    updateEyeColor
}) {
    const { t } = useTranslation();

    function calculateIndex(whichEye = "left") {
        let eindex = 0;
        switch (whichEye) {
            case 'left':
                eindex = 0;
                break;
            case 'right':
                eindex = 2;
                break;
            case 'bottom':
                eindex = 4;
                break;
            default:
                eindex = 0;
                break;
        }
        return eindex;
    }

    return (
        <>
            <Grid container gap={2}>
                <Grid item xs={12} md={12}>
                    <InputLabel>{t('qrcode.settings.eyes.color')}</InputLabel>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Grid container gap={2}>
                        <Grid item xs={12} sm={5.5}>
                            <Grid container gap={0}>
                                <Grid item >
                                    <CustomQRCode {...getQrcodeEyeColorProperties(calculateIndex(whichEye))} />
                                </Grid>
                                <Grid item xs={8}>
                                    <MuiColorInput
                                        format="hex8"
                                        value={qrCodeTemplate.eyeColor[index].outer}
                                        onChange={(value) => updateEyeColor(whichEye, true, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={5.5}>
                            <Grid container gap={0}>
                                <Grid item >
                                    <CustomQRCode {...getQrcodeEyeColorProperties(calculateIndex(whichEye) + 1)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <MuiColorInput
                                        format="hex8"
                                        value={qrCodeTemplate.eyeColor[index].inner}
                                        onChange={(value) => updateEyeColor(whichEye, false, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <QRCodeEyeRadius
                    qrCodeTemplate={qrCodeTemplate}
                    whichEye={whichEye}
                    index={index}
                    updateEyeRadius={updateEyeRadius}
                />
            </Grid>
        </>
    );
}
export default QRCodeEyeColor;
