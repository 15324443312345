
import { Delete as DeleteIcon, DragHandle, Favorite as FavoriteIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { Card, CardActionArea, CardMedia, Grid, ListItemIcon } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import React from 'react';
import { cardItemButtonGroupStack, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, cardItemContentStackButtonIcon, imageCardItemContent, imageCardItemContentActionArea, imageCardItemContentActionAreaMedia } from 'themes/defaultThemes';
import CustomIconButton from 'components/Button/CustomIconButton';

/**
 * The Custom Action Image Card, that display a customized {@link Card}.
 * 
 * @property {string} name the Checkbox name value
 * @property {string} selected If `true`, the component is checked.
 * @property {string} label the Checkbox text value
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {function} deleteImage Callback that triggered to remove an image
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomActionImage({ imageSrc = "", cover = false, hidden = false, index = 0, alt = "", deleteImage, viewImage, imagePath, setCover, setHidden, dragHandleProps }) {

    return (
        <Grid key={index}>
            <Stack direction="row" sx={cardItemButtonGroupStack()} key={`stack-${imageSrc}-${index}`}>
                {setCover &&
                    <CustomIconButton
                        keyValue={`btn-cover-${imageSrc}-${index}`}
                        onClick={(event) => setCover(event, index)}
                        icon={<FavoriteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                        iconSx={{ marginRight: "0px!important" }}
                        backgroundColor={"green"}
                        tooltipTitle={t(`assets.cover`)}
                        sx={{
                            ...cardItemButtonGroupStackButton(),
                            backgroundColor: cover ? "#4CAF50" : "#4caf50a6",
                            "&:hover": {
                                backgroundColor: "#4CAF50",
                                color: "white"
                            }
                        }}
                    >
                        {t('actions.edit')}
                    </CustomIconButton>
                }

                {setHidden &&
                    <CustomIconButton
                        keyValue={`btn-view-${imageSrc}-${index}`}
                        onClick={(event) => setHidden(event, index)}
                        icon={hidden ? <VisibilityOffIcon style={cardItemButtonGroupStackButtonIcon()} /> : <VisibilityIcon style={cardItemButtonGroupStackButtonIcon()} />}
                        iconSx={{ marginRight: "0px!important" }}
                        tooltipTitle={t(`assets.hidden`)}
                        sx={{
                            backgroundColor: "#54b1d2",
                            ...cardItemButtonGroupStackButton()
                        }}
                    >
                        {t('actions.edit')}
                    </CustomIconButton>
                }

                {dragHandleProps &&
                    <CustomIconButton
                        keyValue={`btn-drag-${imageSrc}-${index}`}
                        // onClick={(event) => setHidden(event, index)}
                        icon={<DragHandle style={{ ...cardItemButtonGroupStackButtonIcon(), color: "white" }} />}
                        iconSx={{ marginRight: "0px!important" }}
                        tooltipTitle={t(`assets.hidden`)}
                        sx={{
                            backgroundColor: "#9e92a5",
                            ...cardItemButtonGroupStackButton()
                        }}
                    >
                        {t('actions.drag')}
                    </CustomIconButton>
                }

                {deleteImage &&
                    <CustomIconButton
                        keyValue={`btn-delete-${imageSrc}-${index}`}
                        onClick={(event) => deleteImage(event, imageSrc, index)}
                        icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                        iconSx={{ marginRight: "0px!important" }}
                        tooltipTitle={t(`assets.delete`)}
                        sx={{
                            backgroundColor: "red",
                            ...cardItemButtonGroupStackButton()
                        }}
                        {...dragHandleProps}
                    >
                        {t('actions.delete')}
                    </CustomIconButton>
                }
            </Stack>
            <Stack direction="row" sx={{ ...cardItemContentStackButtonIcon(), opacity: hidden ? 0.4 : 1 }} key={`stack-card-${imageSrc}-${index}`}>
                <Card variant="outlined"
                    key={`card-${imageSrc}-${index}-stack`}
                    sx={imageCardItemContent()}
                >
                    <CardActionArea sx={imageCardItemContentActionArea()} key={`card- back-ontent-${imageSrc}-${index}`}>
                        {imageSrc !== null && <CardMedia component="img" image={imageSrc?.includes("base64") ? imageSrc : imagePath + imageSrc} alt={alt} sx={imageCardItemContentActionAreaMedia()} />}
                    </CardActionArea>
                </Card>
            </Stack>
        </Grid>
    );
}

export default CustomActionImage;