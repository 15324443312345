import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardActionArea, DialogActions, DialogContent, Grid, InputLabel, TextField } from '@mui/material';
import en from "assets/images/flags/en.png";
import CustomDialogBasedOnScreen from 'components/Dialogs/CustomDialogBasedOnScreen';
import CustomTranslationsDialog from 'components/Dialogs/CustomTranslationsDialog';
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import { getSocialFontAwesomeIconNames } from 'constants/fontAwesomeIconNames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { textFieldError } from 'themes/defaultThemes';

function CustomFontAwesomeIconPicker({ icon, label, index, handleExtraRequestButtonChange }) {
    const { t } = useTranslation();
    const iconNames = getSocialFontAwesomeIconNames;
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    // pop dialog for translations
    const [dialog, setDialog] = useState({
        isOpen: false,
        id: "",
        translations: {}
    });

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        setSelectedIcon(null);
    }

    function handleSave() {
        // Do something with selectedIcon and iconText
        handleExtraRequestButtonChange(index, "icon", selectedIcon);
        handleClose();
    }

    /**
     * Function to trigger the remove of the admin broker.
     * @property {*} event
     * @property {string} field The field of the selected outlet field.
     */
    function configureTranslations(event, field) {
        handleDialogState(true, field);
    }
    /**
      * Function that handles the delete modal open or close state.
      * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     */
    function handleDialogState(isOpen) {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            translations: label
        });
    }
    /**
    * This function will trigger the outlet field translations configuration
    */
    function configureLabelTranslations(translations) {
        document.querySelectorAll(`[name="label"]`)[0].value = translations.en;
        handleExtraRequestButtonChange(index, "label", translations);
        setDialog({
            ...dialog,
            isOpen: false,
            translations: {}
        });
    }

    const filteredIcons = iconNames.filter(iconName =>
        iconName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Grid container gap={2}>
            <Grid item xs={"auto"} sx={{ alignSelf: "center" }}>
                <Card
                    variant="outlined"
                    onClick={() => handleOpen()}
                    sx={{
                        width: 'fit-content',
                        borderRadius: "20px",
                        boxShadow:
                            '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                    }}
                >
                    <CardActionArea
                        sx={{
                            minWidth: '50px',
                            minHeight: '50px',
                            padding: '0 5px',
                            textAlign: "center",
                            position: 'relative',
                        }}
                    >
                        {/* {(icon === null || icon === '' || icon === undefined) &&
                            <FontAwesomeIcon icon={fas["faWindowClose"]} size="2x"  />
                        } */}
                        {(icon !== null && icon !== '') &&
                            <FontAwesomeIcon icon={fas[icon]} size="2x" />
                        }
                    </CardActionArea>
                </Card>
            </Grid>

            <Grid item xs={7} md={7}>
                <InputLabel required={true} htmlFor="name">{t('label.label')}</InputLabel>
                <CustomFlagTextField
                    country="en"
                    flag={en}
                    placeholder=""
                    name="label"
                    id="label"
                    onTranslationSelect={configureTranslations}
                    field="name"
                    value={label?.en}
                    error={label?.en?.length === 0 ? true : false}
                    sx={textFieldError()}
                    onChange={(event) => handleExtraRequestButtonChange(index, "label", { ...label, en: event.target.value })}
                />
            </Grid>

            <CustomDialogBasedOnScreen
                isOpen={open}
                handleClose={handleClose}
                body={
                    <>
                        <DialogContent>
                            <TextField
                                label="Search Icons"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {filteredIcons?.map((iconName, index) => (
                                    <Card
                                        key={index}
                                        variant="outlined"
                                        onClick={() => handleOpen()}
                                        sx={{
                                            width: 'fit-content',
                                            borderRadius: "20px",
                                            margin: '10px', textAlign: 'center', cursor: 'pointer',
                                            background: selectedIcon === iconName ? '#47a6d2' : 'initial',
                                            border: selectedIcon === iconName ? '1px solid #47a6d2' : '1px solid lightgrey',
                                        }}
                                    >
                                        <CardActionArea
                                            sx={{
                                                minWidth: '50px',
                                                minHeight: '50px',
                                                padding: '0 5px',
                                                position: 'relative', // Set position relative for Badge
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={fas[iconName]}
                                                size="2x"
                                                onClick={() => setSelectedIcon(iconName)}
                                                color={selectedIcon === iconName ? 'white' : 'initial'}
                                            />
                                        </CardActionArea>
                                    </Card>
                                ))}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button variant={"outlined"} onClick={handleSave}>{t('actions.save')}</Button>
                            <Button variant={"outlined"} onClick={handleClose}>{t('actions.cancel')}</Button>
                        </DialogActions>
                    </>
                }
            />

            {dialog.isOpen &&
                <CustomTranslationsDialog
                    isOpen={dialog.isOpen}
                    id="translations"
                    title={t('actions.translationModal.title')}
                    action={configureLabelTranslations}
                    handleOpen={handleDialogState}
                    translations={label}
                    field={dialog.field} />
            }
        </Grid>
    );
}


export default CustomFontAwesomeIconPicker;