import { Avatar, Grid } from '@mui/material';
import React from 'react';
import CustomTypography from 'components/Typography/CustomTypography';

/**
 * The Custom Image Avatar, that display a letter avatar on the sidebar.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomImageAvatar({ name = "", image = "", sx }) {
    return (
        <Grid sx={{ alignSelf: "center", paddingTop: "10px", width: "100%", ...sx }}>
            <Avatar src={image} sx={{ bgcolor: "#ddf3ffa1", width: 50, height: 50, margin: "0px 20px" }} />
            <CustomTypography text={name} variant="body2" sx={{ paddingTop: "20px" }} />
        </Grid>
    );
}

export default CustomImageAvatar;