import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import CustomTypography from "../../components/Typography/CustomTypography";
import OrderItems from "./orderItems";
import orderApi from "../../api/order";

export default function AssignTimerDialog({
  open,
  onClose,
  order,
  setting,
  currency,
  onSubmit,
}) {
  const { t } = useTranslation();
  const times = [1, 2, 3, 5, 7, 10, 15, 20, 25, "custom"];
  const [customTimer, setCustomTimer] = useState(false);
  const [customTime, setCustomTime] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const submitHandler = (time) => {
    setLoading(true);
    orderApi
      .updateOrderStatus(order.id, "WORKING", time)
      .then((r) => {
        onSubmit(time, r);

        // if (r.data.message) {
        //   setAlertBox({
        //     isOpen: true,
        //     message: r.data.message,
        //     backgroundColor: "#4caf50",
        //   });
        // }

        setLoading(false);
        onClose();
      })
      .catch((e) => {
        // console.log(e);
        setError("Fail to Assign time");
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogContent>
        {loading ? (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            sx={{ minHeight: "40vh" }}
          >
            <CircularProgress sx={{ color: "#527b98" }} />
          </Grid>
        ) : (
          <>
            <Grid container spacing={3} style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: "25px", right: "1px" }}>
                <IconButton
                  disableRipple
                  onClick={onClose}
                  sx={{ p: "2px", background: "#DDDFE2" }}
                >
                  <CloseIcon
                    sx={{
                      fill: "rgba(0, 0, 0, 0.87)",
                      border: 0,
                      borderRadius: "50%",
                      fontSize: "1.2rem",
                    }}
                  />
                </IconButton>
              </div>
              {/* order Info */}
              <Grid item sm={6} xs={12}>
                {/* orderNumber and customerName and timer */}
                <Grid
                  container
                  direction={"row"}
                  spacing={1}
                  justifyContent='space-between'
                  alignItems={"center"}
                >
                  {/* orderNumber and customerName */}
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#DDDFE2",
                        //boxShadow: `0px 0px 15px 0px #dcdcdc`,
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      <CustomTypography
                        text={`#${order?.orderNumber}`}
                        sx={{ fontWeight: "600" }}
                      />
                    </Box>
                    {order?.customer && (
                      <CustomTypography
                        text={`${order?.customer?.name}`}
                        sx={{ fontWeight: "600" }}
                        variant='body1'
                      />
                    )}
                  </Grid>
                  {/* timer */}
                  <Grid item>
                    <CustomTypography
                      text={order?.equipment?.label}
                      sx={{
                        fontWeight: 600,
                      }}
                    />
                  </Grid>
                </Grid>
                {/* Order labels */}
                <Grid container sx={{ mt: "15px" }}>
                  <Chip
                    size='small'
                    label={"new customer"}
                    color='primary'
                    sx={{ background: "#66A15F" }}
                  />
                </Grid>
                {/* Order Items */}
                {order?.cart.length > 0 && (
                  <Grid container sx={{ mt: "35px" }}>
                    <OrderItems
                      order={order}
                      // setting={setting}
                      currency={currency}
                    />
                  </Grid>
                )}
              </Grid>
              {/* time input */}

              <Grid item sm={6} xs={12} sx={{ mt: "8px" }}>
                <CustomTypography
                  text={t("orders.assignTimeDialog.heading")}
                  sx={{ fontWeight: "600", textAlign: "center" }}
                  variant='body1'
                />
                <Grid container justifyContent='center' sx={{ mt: "18px" }}>
                  <Grid item sx={{ width: "80%" }}>
                    <CustomTypography
                      text={t("orders.assignTimeDialog.heading")}
                      sx={{ textAlign: "center" }}
                      variant='body2'
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: "15px" }}>
                  {times.map((item, i) => (
                    <Grid item xs={item === "custom" ? 12 : 4} key={i}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                        component={Paper}
                        elevation={0}
                        sx={{
                          borderRadius: "4px",
                          background:
                            item === "custom" || item > 20
                              ? "#f8a4b9"
                              : "#97ccf1",
                          minHeight: "55px",
                          py: item === "custom" ? 0 : "3px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (item === "custom") {
                            setCustomTimer(true);
                            setCustomTime("");
                          } else {
                            setCustomTimer(false);
                            submitHandler(item);
                          }
                        }}
                      >
                        <Grid item>
                          <CustomTypography
                            text={
                              item === "custom"
                                ? t("orders.assignTimeDialog.custom")
                                : item
                            }
                            variant='subtitle1'
                            sx={{
                              fontWeight: item === "custom" ? 700 : 900,
                              textAlign: "center",
                              fontSize: "20px",
                              color:
                                item === "custom" || item > 20
                                  ? "#f33061"
                                  : "#527b98",
                            }}
                          />
                        </Grid>
                        {item !== "custom" && (
                          <Grid item>
                            <CustomTypography
                              text={t("orders.assignTimeDialog.minute")}
                              variant='subtitle2'
                              sx={{
                                textAlign: "center",
                                color: item > 20 ? "#f33061" : "#527b98",
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                {/* custom timer input */}
                {customTimer && (
                  <Grid container direction='column' sx={{ mt: "10px" }}>
                    <TextField
                      fullWidth
                      placeholder={t(
                        "orders.assignTimeDialog.customInputPlaceholder"
                      )}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Button
                              variant='contained'
                              sx={{ textTransform: "none" }}
                              onClick={() => {
                                if (customTime === 0) return;
                                submitHandler(customTime * 1);
                              }}
                            >
                              {t("orders.assignTimeDialog.submit")}
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      value={customTime}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setCustomTime("");
                        }
                        if (
                          /[0-9]/.test(e.target.value) &&
                          !isNaN(e.target.value) &&
                          e.target.value <= 100
                        ) {
                          setCustomTime(e.target.value);
                        }
                      }}
                    />
                    <div style={{ marginTop: "15px" }} />
                    <Slider
                      value={customTime}
                      onChange={(e) => setCustomTime(e.target.value)}
                      aria-label='Default'
                      valueLabelDisplay='auto'
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {error && (
              <Typography
                variant='body1'
                align='center'
                sx={{ mt: "15px", color: "red" }}
              >
                {error}
              </Typography>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
