import React, { useState } from "react";

import { Grid, Typography, Button } from "@mui/material";
import {
  ErrorOutline as ErrorOutlineIcon,
  Apple as AppleIcon,
} from "@mui/icons-material";

import AppleLogin from "react-apple-login";

export default function AppleAuth() {
  const [error, setError] = useState({
    status: false,
    message: "",
  });
  const handleLogin = async (response) => {
    console.log(response);
    setError({
      status: false,
      message: "",
    });

    try {
      // const result = await axios.post(`/api/v1/auth/social`, {
      //   token: response.accessToken,
      //   method: 'apple',
      // });
      // if (result.data.code === 'SUCCESS') {
      //   await localStorage.setItem('tappio-jwt', result.data.token);
      // } else {
      //   setError({
      //     status: true,
      //     message: result.data.message,
      //   });
      // }
    } catch (err) {
      console.log(err?.response?.data?.message);

      setError({
        status: true,
        message: err.response?.data?.message || "Something went wrong",
      });
    }
  };
  return (
    <>
      <AppleLogin
        render={(renderProps) => (
          <Button
            fullWidth
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#000",
              backgroundColor: "#fff",
              borderRadius: "12px",
              fontWeight: 700,
              textTransform: "none",
              boxShadow: "0px 3px 9px 0px #00000029",
              padding: "10px",
              "&:hover": {
                color: "#000",
                backgroundColor: "#fff",
              },
            }}
            onClick={() => renderProps.onClick()}
          >
            <AppleIcon style={{ marginRight: "10px" }} /> Login with Apple
          </Button>
        )}
        //scope='name email picture birthday gender'
        scope='name email phone'
        redirectURI={process.env.REACT_APP_APPLE_REDIRECT_URL}
        clientId={process.env.REACT_APP_APPLE_CLIENT_ID} //serviceID
        responseType='code'
        onSuccess={handleLogin}
        //onFailure
        usePopup={true}
      />

      {error.status && (
        <Grid item style={{ marginTop: "1em" }}>
          <Typography
            variant='subtitle2'
            style={{ display: "flex", alignItems: "center" }}
          >
            {" "}
            <ErrorOutlineIcon
              style={{ fill: "red", marginRight: "4px" }}
            />{" "}
            {error.message}
          </Typography>
        </Grid>
      )}
    </>
  );
}
