import {
    Save as SaveIcon
} from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Button, Fab, FormControlLabel, Grid, InputLabel, Switch, TextField } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import menuApi from "api/menu";
import en from "assets/images/flags/en.png";
import itemImage from 'assets/images/item.jpg';
import { EyeFrameCurved, EyeFrameSquare } from 'assets/svg/SvgIcons';
import TopShapeDividerSVGComponent from 'assets/svg/divider/shape/top/topShapes';
import CustomImageCropper from 'components/Assets/CustomImageCropper';
import CustomImagesGrid from 'components/Assets/CustomImagesGrid';
import CustomAddEditBreadcrumbMultiParent from "components/Breadcrumb/CustomAddEditBreadcrumbMultiParent";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import CustomPreviewItemDialog from 'components/Dialogs/CustomPreviewItemDialog';
import CustomTranslationsAreaDialog from "components/Dialogs/CustomTranslationsAreaDialog";
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomMenuLayoutButtons from 'components/Menu/CustomMenuLayoutButtons';
import CustomPreviewMenuItems from 'components/Menu/CustomPreviewMenuItems';
import CustomPreviewMenuMobileTabsWithChip from 'components/Menu/CustomPreviewMenuMobileTabsWithChip';
import CustomSelect from 'components/Select/CustomSelect';
import CustomSelectObjectWithChipAndIcon from "components/Select/CustomSelectObjectWithChipAndIcon";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTabs from 'components/Tab/CustomTabs';
import CustomFlagTextArea from "components/Text/CustomFlagTextArea";
import CustomFlagTextField from "components/Text/CustomFlagTextField";
import CustomTextField from "components/Text/CustomTextField";
import CustomAddEditTitle from "components/Title/CustomAddEditTitle";
import { getAssetDefaultObject, getMenuDefaultObject } from "constants/defaultObjects";
import { getQrcodeEyeRadiusButtonIconProperties, getQrcodeEyeRadiusButtonProperties } from 'constants/fieldProperties';
import { getFontFamilyOptions } from 'constants/fontOptions';
import { MuiColorInput } from 'mui-color-input';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, mainArea, mainTagBreadcrumb, mainTagLeft, mainTagTitle, textFieldError } from 'themes/defaultThemes';
import { hasRoleAdminMenu } from 'utils/auth';
import { formIsValid, formValChangeWithParentElement, formValChangeWithParentElementSelect, formValChangeWithParentElementWithNameAndValue, onControlChangeWithParent, onSelectChange } from "utils/form-validation";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The MenuAddEditComponent that allow the ADMIN, DIRECTOR, MANAGER to create a 
 * new menu or edit an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function MenuAddEditComponent() {
    const { t } = useTranslation();

    const { organizationID } = useParams();
    const { menuID } = useParams();
    const navigate = useNavigate();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    path += `/menu`;

    const shapeDivider = [
        "none",
        "waves",
        "wavesOpacity",
        "curve",
        "curveAsymmetrical",
        "triangle",
        "triangleAsymmetrical",
        "tilt",
        "arrow",
        "split",
        "book"
    ];

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {boolean} isAdd to define if requested an edit or an add of the knowledge base category
     * @property {object} menu the menu details
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        userAuth: null,
        isAdd: menuID === undefined,
        organization: {
            id: organizationID,
            name: ""
        },
        menu: null,
        selectedOutlets: [],
        itemPhotoStyles: [],
        menuChipStyles: [],
        layoutOptions: [],
        outlets: [],
        shapeDivider: "",
        // error messages per field
        isError: {
            name: ""
        },
    });

    useEffect(() => {
        getMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //change document title
    useDocumentTitle(`Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles))? " | " + t('org.pageTitle') : ""} | ${t('menus.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`);


    // isLoaded to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);

    const [tabValue, setTabValue] = useState("details");
    const [settingstabValue, setSettingsTabValue] = useState("photos");

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    // pop-up dialog for translations
    const [dialog, setDialog] = useState({
        isOpen: false,
        message: t("label.configureTranslations"),
        id: "",
        field: "",
        translations: {}
    });

    /**
     * The rest endpoint to get the menu.
     */
    function getMenu() {
        if (data.isAdd) {
            menuApi.fetchNew(organizationID).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            menu: getMenuDefaultObject(),
                            // selectedOutlets: r.data.returnobject.selectedOutlets,
                            outlets: r.data.returnobject.outlets,
                            organization: r.data.returnobject.organization,
                            layoutOptions: r.data.returnobject.layoutOptions,
                            itemPhotoStyles: r.data.returnobject.itemPhotoStyles,
                            menuChipStyles: r.data.returnobject.menuChipStyles
                        });
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            menuApi.fetchOne(organizationID, menuID).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            menu: r.data.returnobject.menu,
                            userAuth: r.data.returnobject.userAuth,
                            // selectedOutlets: r.data.returnobject.selectedOutlets,
                            outlets: r.data.returnobject.outlets,
                            organization: r.data.returnobject.organization,
                            layoutOptions: r.data.returnobject.layoutOptions,
                            itemPhotoStyles: r.data.returnobject.itemPhotoStyles,
                            menuChipStyles: r.data.returnobject.menuChipStyles
                        });
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
            }).catch((e) => {
                // console.log(e);
            });
        }
    }

    /**
     * Gets called when the user clicks on the save button, and triggers 
     * the creating of the menu.
     */
    function addMenu() {
        if (formValidation()) {
            let menu = data.menu;
            menu.organizationID = data.organization.id;
            menuApi.create(menu).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                setData({
                    ...data,
                    menu: r.data.returnobject,
                    isAdd: false
                })
                if (r.data.code === "SUCCESS")
                    navigate(`${path}/${r.data.returnobject.id}`)
                // formValChangeWithParentElementWithNameAndValue("isAdd", false, data, setData);
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the modification of the menu
     */
    function editMenu() {
        if (formValidation()) {
            menuApi.update(data.menu).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns the object with the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.name = data.menu.name.en.length < 1 ? t('form.requiredField') : "";
        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     * @property {string} dialogName The dialog name
     */
    function handleDialogState(isOpen, field = "", dialogName = "dialog") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            field: field,
            translations: data.menu[field]
        });
    }

    /**
     * This function will trigger the menu field translations configuration
     */
    function configureMenuTranslations(translations, field) {
        document.querySelectorAll(`[name="menu.${field}.en"]`)[0].value = translations.en;
        setData({
            ...data,
            menu: {
                ...data.menu,
                [field]: translations
            }
        });
        setDialog({
            ...dialog,
            isOpen: false,
            field: "",
            translations: {}
        });
    }

    /**
     * Function to trigger the remove of the menu.
     * 
     * @property {*} event
     * @property {string} field The field of the selected menu field.
     */
    function configureTranslations(event, field) {
        handleDialogState(true, field);
    }

    /**
     * Function that triggers form validation.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that triggers the removal of an Id.
     * @param {*} idToDelete 
     */
    function removeId(event, idToDelete, field) {
        let ids = data.menu[field];
        const index = ids.indexOf(idToDelete);
        // only splice array when item is found
        if (index > -1) ids.splice(index, 1); // 2nd parameter means remove one item only
        setData({
            ...data,
            [field]: ids
        });
    }


    // =============================================================================== Settings 

    function handleTabChange(event, newValue) {
        setSettingsTabValue(newValue);
    };


    /**
     * Function that triggers the modification of the layout style
     * @param {*} tagToDelete 
     */
    function updateLayoutStyle(event, layoutOption, fieldName, isList = false) {
        let layouts = data.menu.settings.layout;
        if (isList) {
            if (layouts.includes(layoutOption)) {
                const index = layouts.indexOf(layoutOption);
                // only splice array when item is found
                if (index > -1) layouts.splice(index, 1); // 2nd parameter means remove one item only
            } else {
                layouts.push(layoutOption);
            }
        } else {
            layouts = layoutOption;
        }

        formValChangeWithParentElementWithNameAndValue(fieldName, layouts, data, setData)
    }

    // ===================================== PPHOTOS
    /**
     * Callback that handles file upload change.
     * @param {string} fileBase64 the new base64 image 
     */
    function handleFileChange(fileBase64) {
        let newPhotos = data.menu.settings.photos;
        newPhotos.push(getAssetDefaultObject(fileBase64, data.menu.settings.photos.length));
        formValChangeWithParentElementWithNameAndValue("menu.settings.photos", newPhotos, data, setData, t('table.valueReuired'));
    };

    function updatePhotoHiddenState(event, index) {
        const updatedPhotos = data.menu.settings.photos.map((photo, i) => {
            if (i === index) {
                return {
                    ...photo,
                    hidden: !photo.hidden,
                };
            }
            return photo;
        });
        formValChangeWithParentElementWithNameAndValue("menu.settings.photos", updatedPhotos, data, setData);
    }

    function updatePhotoCoverState(event, index) {
        let updatedPhotos = data.menu.settings.photos.map((photo, i) => {
            return {
                ...photo,
                cover: (i === index) ? true : false
            };
        });
        formValChangeWithParentElementWithNameAndValue("menu.settings.photos", updatedPhotos, data, setData);
    }
    function deletePhoto(event, index) {
        const updatedPhotos = data.menu.settings.photos;
        if (index > -1) updatedPhotos.splice(index, 1);
        formValChangeWithParentElementWithNameAndValue("menu.settings.photos", updatedPhotos, data, setData);
    }


    // ===============================================================================RENDER

    if (redirect) {
        navigate(path);
    } else if (!isLoaded) {
        return <LoadingSkeleton lines={9} />;
    } else {
        const { isError } = data;

        return (
            // MENU ADD - EDIT
            <CustomGrid role="main" id={"menu-" + data.isAdd ? "add" : "edit"} sx={mainTagLeft()}>
                {(organizationID !== undefined && !hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomAddEditBreadcrumbMultiParent
                        isAdd={data.isAdd}
                        parents={[
                            { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                            { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                            { name: t('menus.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/menu` },
                        ]}
                        instanceName={(data.menu !== null) ? data.menu.name.en : ""}
                    />
                }


                {(organizationID !== undefined && hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomAddEditBreadcrumbMultiParent
                        isAdd={data.isAdd}
                        parents={[
                            { name: t('menus.pageTitle'), url: `/${getLanguageFromURL()}/menu` },
                        ]}
                        instanceName={(data.menu !== null) ? data.menu.name.en : ""}
                    />
                }


                {organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('menus.title'), url: `/${getLanguageFromURL()}/menu` },
                            ]}
                            instanceName={(data.menu !== null) ? data.menu.name.en : ""}
                        />
                    </CustomGrid>
                }


                <CustomGrid sx={mainTagTitle()}>
                    <CustomAddEditTitle
                        isAdd={data.isAdd}
                        title={t('menus.pageTitle')}
                        subtitle={t('menus.pageTitle')}
                        instanceName={(data.menu !== null) ? data.menu.name.en : ""}
                    />
                </CustomGrid>

                {!data.isAdd &&
                    <Grid item xs={12} md={3}>
                        <CustomIconButton
                            variant="outlined"
                            keyValue="categories"
                            onClick={() => window.location.href = `${path}/${menuID}/categories`}
                            label={t('menuCategories.addLabel')}
                        />
                    </Grid>
                }

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="menu" onSubmit={(e) => { return false; }}>
                        {data.isAdd &&
                            <input type="hidden" />
                        }
                        {!data.isAdd && data.menu !== null &&
                            <input defaultValue={data.menu.id} name="id" type="hidden" id="id" />
                        }

                        <TabContext value={tabValue}>
                            <CustomTabs
                                initValue={tabValue}
                                instances={[
                                    { name: t('menus.tabs.details'), value: "details" },
                                    { name: t('menus.tabs.settings'), value: "settings" }
                                ]}
                                setValue={setTabValue}
                                sx={{ marginBottom: "50px", padding: 0 }}
                                scrollButtons="auto"
                            />

                            <TabPanel value={"details"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="name">Name</InputLabel>
                                        <CustomFlagTextField
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="menu.name.en"
                                            id="name"
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            defaultValue={data.menu !== null && data.menu.name !== null ? data.menu.name.en : ""}
                                            onTranslationSelect={(event) => configureTranslations(event, "name")}
                                            field="name"
                                            error={isError.name.length > 0 ? true : false}
                                            helperText={isError.name}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="outletIds">{t('outlet.title')}</InputLabel>
                                        <CustomSelectObjectWithChipAndIcon
                                            id="moutletIds"
                                            name="menu.outletIds"
                                            required={false}
                                            linkPathPattern={`/${getLanguageFromURL()}/organization/${organizationID}/outlet`}
                                            defaultValue={data.menu.outletIds}
                                            options={data.outlets}
                                            onChange={(event) => onSelectChange(event, data, setData)}
                                            onDelete={(event, idToDelete) => removeId(event, idToDelete, "outletIds")}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="description">{t('label.description')}</InputLabel>
                                        <CustomFlagTextArea
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="menu.description.en"
                                            id="description"
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            defaultValue={data.menu !== null && data.menu.description !== null ? data.menu.description.en : ""}
                                            onTranslationSelect={(event) => configureTranslations(event, "description")}
                                            field="description"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="extraNote">{t('itemOrModifier.extraNote')}</InputLabel>
                                        <CustomFlagTextArea
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="menu.extraNote.en"
                                            id="description"
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            defaultValue={data.menu !== null && data.menu.extraNote !== null ? data.menu.extraNote.en : ""}
                                            onTranslationSelect={(event) => configureTranslations(event, "extraNote")}
                                            field="extraNote"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} sx={{ marginBottom: "40px" }}>
                                        <FormControlLabel control={<Switch defaultChecked={data.menu.active} onChange={(event) => onControlChangeWithParent(event, data, setData, "menu.active")} />} label={data.menu.active ? t('menu.active') : t('menu.deactive')} />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <InputLabel required={false} htmlFor="recurrence">{t('menus.recurrence')}</InputLabel>
                                        <FormControlLabel control={<Switch defaultChecked={data.menu.hasRecurrence} onChange={(event) => onControlChangeWithParent(event, data, setData, "menu.hasRecurrence")} />} label={data.menu.hasRecurrence ? t('label.isEnabled') : t('label.isDisabled')} />
                                        {data.menu.hasRecurrence &&
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="startDate">{t('menus.startDate')}</InputLabel>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            name="menu.startDate"
                                                            inputFormat="DD/MM/YYYY"
                                                            onChange={(newValue) => {
                                                                setData({ ...data, menu: { ...data.menu, startDate: newValue } });
                                                            }}
                                                            value={data.menu.startDate}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="endDate">{t('menus.endDate')}</InputLabel>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            name="menu.endDate"
                                                            inputFormat="DD/MM/YYYY"
                                                            onChange={(newValue) => {
                                                                setData({ ...data, menu: { ...data.menu, endDate: newValue } });
                                                            }}
                                                            value={data.menu.endDate}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="recurrenceDaysNumber">{t('menus.recurrence')}</InputLabel>
                                                    <CustomTextField
                                                        type="number"
                                                        step="1"
                                                        min={0}
                                                        name="menu.recurrenceDaysNumber"
                                                        id="recurrenceDaysNumber"
                                                        variant="filled"
                                                        defaultValue={data.menu !== null ? data.menu.recurrenceDaysNumber : ""}
                                                        inputAdornmentLabel='menus.recurrenceDaysNumberBeforeLabel'
                                                        inputAdornmentAfterLabel='menus.recurrenceDaysNumberAfterLabel'
                                                        required={false}
                                                        sx={{ marginBotttom: "40px" }}
                                                        onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"settings"} sx={{ paddingTop: "0px" }}>
                                <TabContext value={settingstabValue}>
                                    <CustomTabs
                                        initValue={settingstabValue}
                                        instances={[
                                            { name: t('menus.tabs.photos'), value: "photos" },
                                            { name: t('menus.tabs.menus'), value: "menus" },
                                            { name: t('menus.tabs.parentCategories'), value: "parentCategories" },
                                            { name: t('menus.tabs.categories'), value: "categories" },
                                            { name: t('menuCategories.items'), value: "items" },
                                            { name: t('itemOrModifier.pageTitleItem'), value: "item" },
                                            { name: t('menus.settings.navigationMenu'), value: "nav" },
                                            // { name: t('menus.tabs.outlet'), value: "outlet" }
                                        ]}
                                        setValue={setSettingsTabValue}
                                        sx={{ marginBottom: "50px", padding: 0 }}
                                        scrollButtons="auto"
                                    />

                                    {/* PHOTOS */}
                                    <TabPanel value="photos">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomImagesGrid
                                                            images={data.menu.settings.photos}
                                                            imagePath={`https://storage.usee.gr/usee/organization/${data.organization.id}/menu/${data.menu.id}/`}
                                                            setHidden={(event, index) => updatePhotoHiddenState(event, index)}
                                                            setCover={(event, index) => updatePhotoCoverState(event, index)}
                                                            deleteImage={(event, imageSrc, index) => deletePhoto(event, index)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={12}>
                                                        <Button>
                                                            {/* Choose File */}
                                                            <CustomImageCropper storeCroppedImage={handleFileChange} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    {/* MENUS */}
                                    <TabPanel value="menus">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="menuBackgroundColor">{t('menus.settings.menuBackgroundColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="menuBackgroundColor"
                                                    name="menu.settings.menuBackgroundColor"
                                                    format="hex8"
                                                    value={data.menu.settings.menuBackgroundColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.menuBackgroundColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="menuPhotoStyle">{t('menus.settings.menuPhotoStyle')}</InputLabel>

                                                <Grid container gap={2}>
                                                    <Grid item>
                                                        <CustomIconButton
                                                            {...getQrcodeEyeRadiusButtonProperties()}
                                                            keyValue={`menu-square`}
                                                            onClick={() => formValChangeWithParentElementWithNameAndValue("menu.settings.menuPhotoStyle", "SQUARE", data, setData)}
                                                            isFocused={data.menu.settings.menuPhotoStyle === "SQUARE"}
                                                            icon={<EyeFrameSquare {...getQrcodeEyeRadiusButtonIconProperties()} />}
                                                        />
                                                    </Grid>

                                                    <Grid item>
                                                        <CustomIconButton
                                                            {...getQrcodeEyeRadiusButtonProperties()}
                                                            keyValue={`menu-round`}
                                                            onClick={() => formValChangeWithParentElementWithNameAndValue("menu.settings.menuPhotoStyle", "ROUND", data, setData)}
                                                            isFocused={data.menu.settings.menuPhotoStyle === "ROUND"}
                                                            icon={<EyeFrameCurved {...getQrcodeEyeRadiusButtonIconProperties()} />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="menuHeaderColor">{t('menus.settings.menuHeaderColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="menuHeaderColor"
                                                    name="menu.settings.menuHeaderColor"
                                                    format="hex8"
                                                    value={data.menu.settings.menuHeaderColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.menuHeaderColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="menuHeaderFontSize">{t('menus.settings.menuHeaderFontSize')}</InputLabel>
                                                <CustomTextField
                                                    name="menu.settings.menuHeaderFontSize"
                                                    defaultValue={data.menu.settings.menuHeaderFontSize}
                                                    required={true}
                                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.menuHeaderFontSize", event.target.value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="menuDescColor">{t('menus.settings.menuDescColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="menuDescColor"
                                                    name="menu.settings.menuDescColor"
                                                    format="hex8"
                                                    value={data.menu.settings.menuDescColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.menuDescColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="menuDescFontSize">{t('menus.settings.menuDescFontSize')}</InputLabel>
                                                <CustomTextField
                                                    name="menu.settings.menuDescFontSize"
                                                    defaultValue={data.menu.settings.menuDescFontSize}
                                                    required={true}
                                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.menuDescFontSize", event.target.value, data, setData)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    {/* PARENT CATEGORIES */}
                                    <TabPanel value="parentCategories">
                                        <Grid item xs={12} md={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <CustomCheckbox
                                                        name="menu.settings.allowParentCategories"
                                                        selected={data.menu.settings.allowParentCategories}
                                                        onChange={(event) => onControlChangeWithParent(event, data, setData, "menu.settings.allowParentCategories")}
                                                        label={t('menus.settings.allowParentCategories')}
                                                    // disabled={data.menu.parentCategoryIds.length > 1}
                                                    />
                                                </Grid>

                                                {data.menu.settings.allowParentCategories &&
                                                    <>
                                                        <Grid item xs={12} md={6}></Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <CustomCheckbox
                                                                name="menu.settings.showAdvancedCMS"
                                                                selected={data.menu.settings.showAdvancedCMS}
                                                                onChange={(event) => onControlChangeWithParent(event, data, setData, "menu.settings.showAdvancedCMS")}
                                                                label={t('menus.settings.showAdvancedCMS')}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}></Grid>
                                                    </>
                                                }

                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="parentCategoryBackgroundColor">{t('menus.settings.parentCategoryBackgroundColor')}</InputLabel>
                                                    <MuiColorInput
                                                        id="parentCategoryBackgroundColor"
                                                        name="menu.settings.parentCategoryBackgroundColor"
                                                        format="hex8"
                                                        value={data.menu.settings.parentCategoryBackgroundColor}
                                                        sx={{ width: "100%" }}
                                                        onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.parentCategoryBackgroundColor", value, data, setData)}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="parentCategoryPhotoStyle">{t('menus.settings.parentCategoryPhotoStyle')}</InputLabel>

                                                    <Grid container gap={2}>
                                                        <Grid item>
                                                            <CustomIconButton
                                                                {...getQrcodeEyeRadiusButtonProperties()}
                                                                keyValue={`parent-category-square`}
                                                                onClick={() => formValChangeWithParentElementWithNameAndValue("menu.settings.parentCategoryPhotoStyle", "SQUARE", data, setData)}
                                                                isFocused={data.menu.settings.parentCategoryPhotoStyle === "SQUARE"}
                                                                icon={<EyeFrameSquare {...getQrcodeEyeRadiusButtonIconProperties()} />}
                                                            />
                                                        </Grid>

                                                        <Grid item>
                                                            <CustomIconButton
                                                                {...getQrcodeEyeRadiusButtonProperties()}
                                                                keyValue={`parent-category-round`}
                                                                onClick={() => formValChangeWithParentElementWithNameAndValue("menu.settings.parentCategoryPhotoStyle", "ROUND", data, setData)}
                                                                isFocused={data.menu.settings.parentCategoryPhotoStyle === "ROUND"}
                                                                icon={<EyeFrameCurved {...getQrcodeEyeRadiusButtonIconProperties()} />}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="parentCategoryHeaderColor">{t('menus.settings.parentCategoryHeaderColor')}</InputLabel>
                                                    <MuiColorInput
                                                        id="parentCategoryHeaderColor"
                                                        name="menu.settings.parentCategoryHeaderColor"
                                                        format="hex8"
                                                        value={data.menu.settings.parentCategoryHeaderColor}
                                                        sx={{ width: "100%" }}
                                                        onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.parentCategoryHeaderColor", value, data, setData)}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="parentCategoryHeaderFontSize">{t('menus.settings.parentCategoryHeaderFontSize')}</InputLabel>
                                                    <CustomTextField
                                                        name="menu.settings.parentCategoryHeaderFontSize"
                                                        defaultValue={data.menu.settings.parentCategoryHeaderFontSize}
                                                        required={true}
                                                        onChange={(event) => formValChangeWithParentElement("menu.settings.parentCategoryHeaderFontSize", event.target.value, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="parentCategoryDescColor">{t('menus.settings.parentCategoryDescColor')}</InputLabel>
                                                    <MuiColorInput
                                                        id="parentCategoryDescColor"
                                                        name="menu.settings.parentCategoryDescColor"
                                                        format="hex8"
                                                        value={data.menu.settings.parentCategoryDescColor}
                                                        sx={{ width: "100%" }}
                                                        onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.parentCategoryDescColor", value, data, setData)}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <InputLabel required={false} htmlFor="parentCategoryDescFontSize">{t('menus.settings.parentCategoryDescFontSize')}</InputLabel>
                                                    <CustomTextField
                                                        name="menu.settings.parentCategoryDescFontSize"
                                                        defaultValue={data.menu.settings.parentCategoryDescFontSize}
                                                        required={true}
                                                        onChange={(event) => formValChangeWithParentElement("menu.settings.parentCategoryDescFontSize", event.target.value, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>

                                                <CustomMenuLayoutButtons
                                                    updateLayoutStyle={updateLayoutStyle}
                                                    activeLayout={data.menu.settings.parentCategoryLayout}
                                                    fieldName="menu.settings.parentCategoryLayout"
                                                    isList={false}
                                                    layoutOptions={data.layoutOptions}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    {/* CATEGORIES */}
                                    <TabPanel value="categories">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="categoryBackgroundColor">{t('menus.settings.categoryBackgroundColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="categoryBackgroundColor"
                                                    name="menu.settings.categoryBackgroundColor"
                                                    format="hex8"
                                                    value={data.menu.settings.categoryBackgroundColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.categoryBackgroundColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="categoryHeaderColor">{t('menus.settings.categoryHeaderColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="categoryHeaderColor"
                                                    name="menu.settings.categoryHeaderColor"
                                                    format="hex8"
                                                    value={data.menu.settings.categoryHeaderColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.categoryHeaderColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="categoryHeaderFontSize">{t('menus.settings.categoryHeaderFontSize')}</InputLabel>
                                                <CustomTextField
                                                    name="menu.settings.categoryHeaderFontSize"
                                                    defaultValue={data.menu.settings.categoryHeaderFontSize}
                                                    required={true}
                                                    onChange={(event) => formValChangeWithParentElement("menu.settings.categoryHeaderFontSize", event.target.value, data, setData, t('table.valueReuired'))}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="categoryDescColor">{t('menus.settings.categoryDescColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="categoryDescColor"
                                                    name="menu.settings.categoryDescColor"
                                                    format="hex8"
                                                    value={data.menu.settings.categoryDescColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.categoryDescColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="categoryDescFontSize">{t('menus.settings.categoryDescFontSize')}</InputLabel>
                                                <CustomTextField
                                                    name="menu.settings.categoryDescFontSize"
                                                    defaultValue={data.menu.settings.categoryDescFontSize}
                                                    required={true}
                                                    onChange={(event) => formValChangeWithParentElement("menu.settings.categoryDescFontSize", event.target.value, data, setData, t('table.valueReuired'))}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    {/* ITEMS */}
                                    <TabPanel value="items">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={12} sx={{ textAlign: "-webkit-center" }}>
                                                <CustomPreviewMenuItems {...data.menu.settings} layoutOptions={data.layoutOptions} />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="itemBackgroundColor">{t('menus.settings.itemBackgroundColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="itemBackgroundColor"
                                                    name="menu.settings.itemBackgroundColor"
                                                    format="hex8"
                                                    value={data.menu.settings.itemBackgroundColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemBackgroundColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="itemHeaderColor">{t('menus.settings.itemHeaderColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="itemHeaderColor"
                                                    name="menu.settings.itemHeaderColor"
                                                    format="hex8"
                                                    value={data.menu.settings.itemHeaderColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemHeaderColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="itemHeaderFontSize">{t('menus.settings.itemHeaderFontSize')}</InputLabel>
                                                <CustomTextField
                                                    name="menu.settings.itemHeaderFontSize"
                                                    defaultValue={data.menu.settings.itemHeaderFontSize}
                                                    required={true}
                                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="itemDescColor">{t('menus.settings.itemDescColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="itemDescColor"
                                                    name="menu.settings.itemDescColor"
                                                    format="hex8"
                                                    value={data.menu.settings.itemDescColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemDescColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="itemDescFontSize">{t('menus.settings.itemDescFontSize')}</InputLabel>
                                                <CustomTextField
                                                    name="menu.settings.itemDescFontSize"
                                                    defaultValue={data.menu.settings.itemDescFontSize}
                                                    required={true}
                                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="itemPriceColor">{t('menus.settings.itemPriceColor')}</InputLabel>
                                                <MuiColorInput id="itemPriceColor"
                                                    name="menu.settings.itemPriceColor"
                                                    format="hex8"
                                                    value={data.menu.settings.itemPriceColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemPriceColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="itemPriceFontSize">{t('menus.settings.itemPriceFontSize')}</InputLabel>
                                                <CustomTextField
                                                    name="menu.settings.itemPriceFontSize"
                                                    defaultValue={data.menu.settings.itemPriceFontSize}
                                                    required={true}
                                                    sx={{ border: "none" }}
                                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                />
                                            </Grid>

                                            <CustomMenuLayoutButtons
                                                updateLayoutStyle={updateLayoutStyle}
                                                activeLayout={data.menu.settings.layout}
                                                fieldName="menu.settings.layout"
                                                isList={true}
                                                layoutOptions={data.layoutOptions}
                                            />

                                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                                <InputLabel required={true} htmlFor="itemPhotoStyle">{t('menus.settings.itemPhotoStyle.label')}</InputLabel>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={5} >
                                                        <img src={itemImage} width={112} height={98}
                                                            alt={t('menus.settings.itemPhotoStyle.SQUARE')}
                                                            onClick={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemPhotoStyle", data.itemPhotoStyles[1], data, setData)}
                                                            style={{ borderRadius: "10px", marginRight: "20px", opacity: data.menu.settings.itemPhotoStyle === "SQUARE" ? 1 : 0.5, boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" }}
                                                        />
                                                        <InputLabel required={false} style={{ width: "40%", fontSize: "11px" }}>{t('menus.settings.itemPhotoStyle.SQUARE')}</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={5} >
                                                        <img src={itemImage} width={112} height={98}
                                                            alt={t('menus.settings.itemPhotoStyle.ROUND')}
                                                            onClick={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.itemPhotoStyle", data.itemPhotoStyles[0], data, setData)}
                                                            style={{ borderRadius: "50%", opacity: data.menu.settings.itemPhotoStyle === "ROUND" ? 1 : 0.5, boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" }}
                                                        />
                                                        <InputLabel required={false} style={{ width: "40%", fontSize: "11px" }}>{t('menus.settings.itemPhotoStyle.ROUND')}</InputLabel>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomCheckbox
                                                            name="menu.settings.showCurrency"
                                                            selected={data.menu.settings.showCurrency}
                                                            onChange={(event) => onControlChangeWithParent(event, data, setData, "menu.settings.showCurrency")}
                                                            label={t('menus.settings.showCurrency')}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <CustomCheckbox
                                                            name="menu.settings.showDescription"
                                                            selected={data.menu.settings.showDescription}
                                                            onChange={(event) => onControlChangeWithParent(event, data, setData, "menu.settings.showDescription")}
                                                            label={t('menus.settings.showDescription')}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    {/* Item popup */}
                                    <TabPanel value="item">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={7} sx={{ textAlign: "-webkit-center" }}>
                                                <CustomPreviewItemDialog menuConfiguration={data.menu?.settings} />
                                            </Grid>

                                            <Grid item xs={12} md={5}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <InputLabel required={false} htmlFor="baseColor">{t('menus.settings.baseColor')}</InputLabel>
                                                        <MuiColorInput id="baseColor"
                                                            name="menu.settings.baseColor"
                                                            format="hex8"
                                                            value={data.menu.settings.baseColor}
                                                            sx={{ width: "100%" }}
                                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.baseColor", value, data, setData)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <InputLabel required={false} htmlFor="font">{t('menus.settings.font')}</InputLabel>
                                                        <CustomSelect name="menu.settings.font" required={true}
                                                            defaultValue={data.menu.settings.font}
                                                            options={getFontFamilyOptions()}
                                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <InputLabel required={false} htmlFor="buttonFont">{t('menus.settings.buttonFont')}</InputLabel>
                                                        <CustomSelect name="menu.settings.buttonFont" required={true}
                                                            defaultValue={data.menu.settings.buttonFont}
                                                            options={getFontFamilyOptions()}
                                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <InputLabel required={false} htmlFor="buttonColor">{t('menus.settings.buttonColor')}</InputLabel>
                                                        <MuiColorInput id="buttonColor"
                                                            name="menu.settings.buttonColor"
                                                            format="hex8"
                                                            value={data.menu.settings.buttonColor}
                                                            sx={{ width: "100%" }}
                                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.buttonColor", value, data, setData)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <InputLabel required={false} htmlFor="buttonColor">{t('menus.settings.buttonBackgroundColor')}</InputLabel>
                                                        <MuiColorInput id="buttonBackgroundColor"
                                                            name="menu.settings.buttonBackgroundColor"
                                                            format="hex8"
                                                            value={data.menu.settings.buttonBackgroundColor}
                                                            sx={{ width: "100%" }}
                                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.buttonBackgroundColor", value, data, setData)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <InputLabel required={false} htmlFor="buttonFontSize">{t('menus.settings.buttonFontSize')}</InputLabel>
                                                        <CustomTextField
                                                            name="menu.settings.buttonFontSize"
                                                            defaultValue={data.menu.settings.buttonFontSize}
                                                            required={true}
                                                            sx={{ border: "none" }}
                                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <InputLabel required={false} htmlFor="chipTextColor">{t('menus.settings.chipTextColor')}</InputLabel>
                                                        <MuiColorInput id="chipTextColor"
                                                            name="menu.settings.chipTextColor"
                                                            format="hex8"
                                                            value={data.menu.settings.chipTextColor}
                                                            sx={{ width: "100%" }}
                                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.chipTextColor", value, data, setData)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} md={12}>
                                                                <CustomCheckbox
                                                                    name="menu.settings.showPointsPerItem"
                                                                    selected={data.menu.settings.showPointsPerItem}
                                                                    onChange={(event) => onControlChangeWithParent(event, data, setData, "menu.settings.showPointsPerItem")}
                                                                    label={t('menus.settings.showPointsPerItem')}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={12}>
                                                                <CustomCheckbox
                                                                    name="menu.settings.allowCommentsOnItem"
                                                                    selected={data.menu.settings.allowCommentsOnItem}
                                                                    onChange={(event) => onControlChangeWithParent(event, data, setData, "menu.settings.allowCommentsOnItem")}
                                                                    label={t('menus.settings.allowCommentsOnItem')}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    {/* Navigation Menu */}
                                    <TabPanel value="nav">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                <CustomPreviewMenuMobileTabsWithChip {...data.menu.settings} />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="navigationMenuFont">{t('menus.settings.navigationMenuFont')}</InputLabel>
                                                <CustomSelect
                                                    name="menu.settings.navigationMenuFont"
                                                    required={true}
                                                    defaultValue={data.menu.settings.navigationMenuFont}
                                                    options={getFontFamilyOptions()}
                                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="navigationMenuFontSize">{t('menus.settings.navigationMenuFontSize')}</InputLabel>
                                                <CustomTextField
                                                    name="menu.settings.navigationMenuFontSize"
                                                    defaultValue={data.menu.settings.navigationMenuFontSize}
                                                    required={true}
                                                    sx={{ border: "none" }}
                                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="navigationMenuBackgroundColor">{t('menus.settings.navigationMenuBackgroundColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="navigationMenuBackgroundColor"
                                                    name="menu.settings.navigationMenuBackgroundColor"
                                                    format="hex8"
                                                    value={data.menu.settings.navigationMenuBackgroundColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.navigationMenuBackgroundColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="navigationMenuSelectedOptionFontColor">{t('menus.settings.navigationMenuSelectedOptionFontColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="navigationMenuSelectedOptionFontColor"
                                                    name="menu.settings.navigationMenuSelectedOptionFontColor"
                                                    format="hex8"
                                                    value={data.menu.settings.navigationMenuSelectedOptionFontColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.navigationMenuSelectedOptionFontColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="navigationMenuUnSelectedOptionFontColor">{t('menus.settings.navigationMenuUnSelectedOptionFontColor')}</InputLabel>
                                                <MuiColorInput
                                                    id="navigationMenuUnSelectedOptionFontColor"
                                                    name="menu.settings.navigationMenuUnSelectedOptionFontColor"
                                                    format="hex8"
                                                    value={data.menu.settings.navigationMenuUnSelectedOptionFontColor}
                                                    sx={{ width: "100%" }}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("menu.settings.navigationMenuUnSelectedOptionFontColor", value, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel required={false} htmlFor="navigationMenuStyle">{t('menus.settings.navigationMenuStyle')}</InputLabel>
                                                <CustomSelect name="menu.settings.navigationMenuStyle" required={true}
                                                    defaultValue={data.menu.settings.navigationMenuStyle}
                                                    options={data.menuChipStyles}
                                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("menu.settings.navigationMenuStyle", event.target.value, data, setData)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    {/* Outlet */}
                                    <TabPanel value="outlet">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                {/* include review */}
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <CustomSelect
                                                    name="shapeDivider"
                                                    required={true}
                                                    defaultValue={data.shapeDivider}
                                                    options={shapeDivider}
                                                    onChange={(event) => formValChangeWithParentElementSelect(event, data, setData, t('table.valueReuired'))}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <MuiColorInput
                                                    label={t('outlet.settings.splashScreenConfiguration.backgroundColor')}
                                                    format="hex8"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TopShapeDividerSVGComponent
                                                    type={data.shapeDivider}
                                                    style={{
                                                        fill: 'blue', // Change the fill color to blue
                                                        width: '100%', // Set the width to 100%
                                                        height: '100px', // Set the height to 200px
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </TabContext>

                            </TabPanel>
                        </TabContext>

                        <Grid container spacing={2}>
                            {/* <Grid item>
                                <CustomIconButton
                                    variant="outlined"
                                    keyValue="save"
                                    onClick={data.isAdd ? addMenu : editMenu}
                                    label={t('actions.save')}
                                    sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                />
                            </Grid> */}

                            {data.isAdd &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        link={path}
                                        label={t('actions.back')}
                                        color="error"
                                    />
                                </Grid>
                            }

                            {!data.isAdd &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        onClick={getMenu}
                                        label={t('actions.cancel')}
                                        color="error"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={data.isAdd ? addMenu : editMenu}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {(dialog.isOpen && dialog.field === "name") &&
                    <CustomTranslationsDialog
                        isOpen={dialog.isOpen} id="translations"
                        title={t('actions.translationModal.title')}
                        actionLabel={t('actions.save')}
                        action={configureMenuTranslations}
                        handleOpen={handleDialogState}
                        translations={dialog.translations}
                        field={dialog.field}
                    />
                }
                {(dialog.isOpen && (dialog.field === "description" || dialog.field === "extraNote")) &&
                    <CustomTranslationsAreaDialog
                        isOpen={dialog.isOpen} id="translations"
                        title={t('actions.translationModal.title')}
                        actionLabel={t('actions.save')}
                        action={configureMenuTranslations}
                        handleOpen={handleDialogState}
                        translations={dialog.translations}
                        field={dialog.field}
                    />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={6000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default MenuAddEditComponent;