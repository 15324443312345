import { createTheme, FormControl, FormHelperText, ThemeProvider } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomChip from 'components/Chip/CustomChip';

/**
 * The Custom Select Field, that display customized select tag.
 * 
 * @property {string} id the text field id
 * @property {string} labelID the text field label id
 * @property {string} name the input field name
 * @property {string} label Applies the theme typography styles.
 * @property {array} defaultValue the text field default value
 * @property {boolean} required if the value is required at this text field 
 * @property {boolean} isMultiple if multiple selection is enabled
 * @property {function} onChange the function that handles the select input change
 * @property {array} options the different option list
 * @property {string} helperText a helper text
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomObjectSelect({ id, labelID = "", name = "", label, defaultValue, value, required = true, isMultiple = false, onChange, options, helperText = "", sx, field = "name", idField = "id", error }) {

    const { t } = useTranslation();

    function getMuiTheme(sx) {
        return createTheme({
            components: {
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            borderRadius: "30px",
                            width: "100%",
                            ...sx
                        }
                    }
                },
                MuiFormControl: {
                    styleOverrides: {
                        root: {
                            marginTop: 0,
                            margin: "0px !important"
                        }
                    }

                }
            }
        })
    }

    function body() {
        return <>
            {label !== undefined && <InputLabel id={labelID} >{label}</InputLabel>}
            <ThemeProvider theme={getMuiTheme(sx)}>
                <Select
                    labelId={labelID}
                    multiple={isMultiple}
                    onChange={onChange}
                    input={<OutlinedInput id={id} name={name} />}
                    required={required}
                    defaultValue={defaultValue}
                    value={value}
                    label={label}
                    error={error}
                >
                    {options.map((item) => (
                        <MenuItem
                            key={"option-" + item[idField]}
                            value={item[idField]}
                        >
                            {item[field] === "all" ? t("search.all") : item[field]}
                            {item.type !== undefined &&
                                <CustomChip label={item.type} />
                            }
                        </MenuItem>
                    ))}
                </Select>
            </ThemeProvider>
            {helperText !== "" && <FormHelperText>{helperText}</FormHelperText>}
        </>
    }

    return (
        <>
            {label !== undefined &&
                <FormControl sx={{ m: 1, minWidth: 120, margin: "0 8px" }}>
                    {body()}
                </FormControl>
            }{label === undefined &&
                body()
            }

        </>
    );
}

export default CustomObjectSelect;