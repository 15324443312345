import { Add as AddIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { Button, Fab, Grid, Stack, Tooltip } from "@mui/material";
import loyaltyCategoryApi from 'api/loyaltyCategory';
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from 'components/Dialogs/CustomDialog';
import AddEditLoyaltyCategory from 'components/Dialogs/loyalty/AddEditLoyaltyCategory';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomPageableTable from 'components/Table/CustomPageableTable';
import CustomTitle from 'components/Title/CustomTitle';
import { getDefaultLoyaltyCategory, getLoyaltyCardProperties } from 'constants/defaultObjects';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagTitle, pageAddButton } from 'themes/defaultThemes';
import { hasRoleAdmin } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { actionFormatterLoyalty, fieldFormatter } from 'utils/table';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The LoyaltySettingsComponent that allow the ADMIN to configure
 * loyalty settings.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function LoyaltySettingsTableComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const navigate = useNavigate();

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} | ${t('loyalty.settings.label')}`);

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    path += `/loyalty/category`;

    /**
     * @type {object}
     * @property {*} icon the legend fa icon
     * @property {object} userAuth the authenticated user infromation
     * @property {object} organization the organization basic details (id and name)
     * @property {object} outlet tha outlet basic details (id and name)
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        icon: AddIcon,
        userAuth: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        loyaltyCategories: [],
        logoImgSrc: "",
        formatOptions: [],
        fontOptions: [],
        textAlignOptions: [],
        textPositionOptions: [],
        organizations: [],
        newCategory: {
            organizationID: organizationID,
            outletID: outletID,
            ...getDefaultLoyaltyCategory()
        },
        pagination: {
            count: -1,
            size: 10,
            page: 0
        },
        // error messages per field
        isError: {
            name: "",
            url: ""
        }
    });


    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        categoryID: "",
        isAdd: 0//add or 1 for edit
    });

    // pop dialog for delete
    const [deleteDialog, setDeletedDialog] = useState({
        isOpen: false,
        categoryID: "",
        nameEn: ""
    });

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    // for search
    const [search, setSearch] = useState({
        organizationID: organizationID,
        outletID: outletID,
        name: ""
    });

    useEffect(() => {
        getLoyaltySettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getLoyaltySettings();
        setIsFirstTime(false);
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getLoyaltySettings();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
    }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])

    /**
     * The rest endpoint to get the loyalty configuration.
     */
    function getLoyaltySettings(page) {
        loyaltyCategoryApi.fetchAll(search, prepareTablePaginationSortParams(data.pagination)).then((r) => {
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        organization: r.data.returnobject.organization,
                        outlet: r.data.returnobject.outlet,
                        loyaltyConfiguration: r.data.returnobject.loyaltyConfiguration,
                        loyaltyCategories: r.data.returnobject.loyaltyCategories,
                        logoImgSrc: r.data.returnobject.logoImgSrc,
                        fontOptions: r.data.returnobject.fontOptions,
                        formatOptions: r.data.returnobject.formatOptions,
                        textAlignOptions: r.data.returnobject.textAlignOptions,
                        textPositionOptions: r.data.returnobject.textPositionOptions,
                        organizations: r.data.returnobject.organizations,
                        pagination: {
                            ...data.pagination,
                            count: r.data.returnobject.loyaltyCategories.totalPages,
                            size: r.data.returnobject.loyaltyCategories.size,
                            page: page !== undefined ? page : r.data.returnobject.loyaltyCategories.number
                        } 
                    });
                })
                .then(() => {
                    let organizationID = r.data.returnobject.organization !== null ? r.data.returnobject.organization.id : null;
                    let outletID = r.data.returnobject.outlet !== null ? r.data.returnobject.outlet.id : null;
                    if ((search.organizationID !== null && hasRoleAdmin(r.data.returnobject.userAuth.roles)) || !hasRoleAdmin(r.data.returnobject.userAuth.roles))
                        if (organizationID !== search.organizationID)
                            setSearch({ ...search, organizationID: organizationID });
                    // if ((search.organizationID !== null && hasRoleAdmin(r.data.returnobject.userAuth.roles)) || !hasRoleAdmin(r.data.returnobject.userAuth.roles))
                    //     if (organizationID !== search.organizationID || (outletID !== search.outletID))
                    //         setSearch({ ...search, organizationID: organizationID, outletID: outletID !== null ? outletID : (r.data.returnobject.outlets.size === 1) ? r.data.returnobject.outlets.outlets[0].id : null });
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        });
    }

    /**
     * Function that triggers the creation of a loyalty category
     */
    function addLoyaltyCategory(category) {
        category.organizationID = search.organizationID;
        loyaltyCategoryApi.create(category).then((r) => {
            Promise.resolve()
                .then(() => {
                    getLoyaltySettings();
                })
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: r.data.message,
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    setDialog({
                        isOpen: false,
                        categoryID: "",
                        nameEn: "",
                        isAdd: 0//add or 1 for edit
                    });
                })
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that triggers the modification of a loyalty category
     */
    function updateLoyaltyCategory(category) {
        loyaltyCategoryApi.update(category).then((r) => {
            Promise.resolve()
                .then(() => {
                    if (r.data.code === "SUCCESS")
                        getLoyaltySettings()
                })
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: r.data.message,
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    setDialog({
                        isOpen: false,
                        categoryID: "",
                        isAdd: 0//add or 1 for edit
                    });
                })
        }).catch((e) => {
            // console.log(e)
        });
    }

    function deleteLoyaltyCategory() {
        loyaltyCategoryApi.deleteById(deleteDialog.categoryID).then((r) => {
            handleDeleteDialogState(false, "");
            Promise.resolve()
                .then(() => {
                    if (r.data.code === "SUCCESS")
                        getLoyaltySettings()
                })
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: r.data.message,
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    setDeletedDialog({
                        isOpen: false,
                        categoryID: "",
                        nameEn: "",
                    });
                })
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} categoryID The id of the selected category to be editted.
     */
    function handleDialogState(isOpen, categoryID = "", isAdd = true, name = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            categoryID: categoryID,
            isAdd: isAdd,
            name: name
        });
    }

    /**
     * Function that handles the delete modal open or close data.
     * 
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} categoryID The id of the selected loyalty category to be removed.
     * @property {string} nameEn The loyalty category name to be removed.
     */
    function handleDeleteDialogState(isOpen, categoryID = "", nameEn = "") {
        setDeletedDialog({
            ...deleteDialog,
            isOpen: isOpen,
            categoryID: categoryID,
            nameEn: nameEn
        });
    }

    /**
     * Function that triggers the removal of the selected categoryID.
     * 
     * @property {*} event
     * @property {string} categoryID The id of the selected loyalty category to be removed.
     * @property {string} nameEn The loyalty category name to be removed.
     */
    function deleteCategory(event, categoryID, nameEn) {
        handleDeleteDialogState(true, categoryID, nameEn);
    }

    /**
     * Function that handles the pagination information.
     * @property {*} event
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(event, page) {
        getLoyaltySettings(page)
    }

    /**
     * Function that handles the pagination information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        let pagination = data.pagination;
        pagination.size = size;
        pagination.page = 0;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }

    /**
     * Function that handles the sort information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }

    /**
     * datatable columns default sorted.
     */
    const defaultSorted = {
        name: 'targetPoints',
        direction: 'asc'
    };

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <CustomGrid role="main" id="loyalty-settings" sx={mainTag()}>
            <LoadingSkeleton lines={9} />
        </CustomGrid>
    } else {
        /**
       * datatable columns.
       */
        const table_columns = [
            {
                name: "id",
                label: t('label.id'),
                options: {
                    viewColumns: false,
                    filter: false,
                    display: false,
                }
            },
            {
                name: "name",
                label: t('label.name'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.name')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value.en;
                    }
                }
            },
            {
                name: "targetPoints",
                label: t('loyalty.category.targetPointsClean'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return fieldFormatter(value, undefined, ` ${t('loyalty.category.points')}`);
                    }
                }
            },
            {
                name: "discountPercentage",
                label: t('loyalty.category.discountPercentageClean'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return fieldFormatter(value, undefined, "%");
                    }
                }
            },
            {
                name: "couponValue",
                label: t('loyalty.category.couponValueClean'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return fieldFormatter(value);
                    }
                }
            },
            {
                name: "",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return actionFormatterLoyalty(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            0,
                            1,
                            (event, categoryID, name) => handleDialogState(true, categoryID, false, name.en),
                            data.showLabel ? t('actions.edit') : undefined,
                            (event, categoryID, name) => deleteCategory(event, categoryID, name.en),
                            data.showLabel ? t('actions.delete') : undefined
                        )
                    },
                    setCellProps: () => ({ className: "click action" })
                }
            }
        ];

        return (
            // Loyalty Settings
            <CustomGrid role="main" id="loyalty-settings" sx={mainTag()}>
                {organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('loyalty.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('loyalty.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/loyalty` },
                            ]}
                            instanceName={t('loyalty.category.label')}
                        />
                    </CustomGrid>
                }


                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={"auto"}>
                        <CustomRefreshButton onClick={getLoyaltySettings} marginTop="-1%" />
                    </Grid>
                    <Grid item xs={"auto"}>
                        <CustomIconButton onClick={() => navigate(`${path}/settings`)}
                            marginTop="-1%"
                            icon={<SettingsIcon />}
                            sx={{ borderRadius: "20px", minWidth: "40px" }}
                            iconSx={{ margin: 0 }}
                            tooltipTitle={t('loyalty.settings.label')}
                        />
                    </Grid>

                    <Grid item xs={10} sm={3}>
                        <CustomGrid sx={mainTagTitle()}>
                            <Grid item >
                                <CustomTitle
                                    title={t('loyalty.category.label')}
                                    subtitle={""}
                                />
                            </Grid>
                        </CustomGrid>
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <Stack direction="row" sx={mainTagSearch()}>
                            <CustomGrid sx={mainTagSearch()} direction="column" container={false} spacing={2}>
                                <Grid container spacing={2} sx={{ justifyContent: "right" }}>
                                    {/* <Grid item>
                                        <CustomTextField
                                            type="search"
                                            name="name"
                                            id="name"
                                            variant="filled"
                                            label={t('actions.search') + " by " + t('label.name')}
                                            defaultValue={search.name !== null ? search.name : ""}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CustomSearchButton onClick={getLoyaltySettings} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            required={false}
                                            sx={{ minWidth: "300px" }}
                                            onChange={(event) => formValChangeWithParentElementSelect(event, search, setSearch, t('table.valueReuired'))}
                                        />
                                    </Grid> */}
                                </Grid>
                            </CustomGrid>
                        </Stack>
                    </Grid>
                </Grid>

                <CustomGrid sx={mainAreaTable()} container={false}>
                    <CustomPageableTable
                        instances={data.loyaltyCategories}
                        emptyMessage={t('loyalty.category.empty')}
                        table_columns={table_columns}
                        keyField="name.en"
                        defaultSorted={defaultSorted}
                        pagination={data.pagination}
                        handlePaginationChange={handlePaginationChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        handleColumnSortChange={handleColumnSortChange}
                        filter={true}
                        onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                            ...search,
                            name: ""
                        })}
                    />

                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={() => handleDialogState(true)}
                    >
                        <Tooltip arrow title={t('actions.add') + " " + t('loyalty.category.labelSingle')} sx={fabTooltipStyle()}>
                            <AddIcon {...fabStyleIcon()} />
                        </Tooltip>
                    </Fab>
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }

                {dialog.isOpen &&
                    <AddEditLoyaltyCategory
                        isOpen={dialog.isOpen}
                        handleOpen={handleDialogState}
                        category={dialog.isAdd ? data.newCategory : data.loyaltyCategories.content.find((category) => dialog.categoryID === category.id)}
                        backgroundTypeOptions={["COLOR", "IMAGE"]}
                        isAdd={dialog.isAdd}
                        addLoyaltyCategory={addLoyaltyCategory}
                        updateLoyaltyCategory={updateLoyaltyCategory}
                        formatOptions={data.formatOptions}
                        fontOptions={data.fontOptions}
                        textAlignOptions={data.textAlignOptions}
                        textPositionOptions={data.textPositionOptions}
                        cardProperties={data.loyaltyConfiguration.cardProperties}
                        sxCard={getLoyaltyCardProperties()}
                        organizationID={search.organizationID}
                        logoImgSrc={data.logoImgSrc}
                    />
                }

                {deleteDialog.isOpen &&
                    <CustomDialog
                        isOpen={deleteDialog.isOpen}
                        id="delete"
                        title={t('actions.deleteModal.title')}
                        actionLabel={t("actions.deleteModal.message")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('loyalty.category.labelSingle')}: `} a
                        boldMessage={<span style={{ fontWeight: "bold" }}>{deleteDialog.nameEn}</span>}
                        action={deleteLoyaltyCategory}
                        handleOpen={handleDeleteDialogState} />
                }
            </CustomGrid>
        );
    }
}

export default LoyaltySettingsTableComponent;