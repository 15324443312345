export const fontAwesomeBrandIconNames = [
    // 'faMonero',
    // 'faHooli',
    'faYelp',
    // 'faCcVisa',
    // 'faLastfm',
    // 'faShopware',
    // 'faCreativeCommonsNc',
    // 'faAws',
    // 'faRedhat',
    'faYoast',
    // 'faCloudflare',
    // 'faUps',
    // 'faWpexplorer',
    // 'faDyalog',
    // 'faBity',
    // 'faStackpath',
    // 'faBuysellads',
    // 'faFirstOrder',
    // 'faModx',
    // 'faGuilded',
    // 'faVnv',
    // 'faSquareJs',
    // 'faJsSquare',
    // 'faMicrosoft',
    // 'faQq',
    // 'faOrcid',
    // 'faJava',
    // 'faInvision',
    // 'faCreativeCommonsPdAlt',
    // 'faCentercode',
    // 'faGlideG',
    // 'faDrupal',
    // 'faHireAHelper',
    // 'faCreativeCommonsBy',
    // 'faUnity',
    // 'faWhmcs',
    // 'faRocketchat',
    // 'faVk',
    // 'faUntappd',
    // 'faMailchimp',
    // 'faCss3Alt',
    'faSquareReddit',
    'faRedditSquare',
    'faVimeoV',
    // 'faContao',
    // 'faSquareFontAwesome',
    // 'faDeskpro',
    // 'faSistrix',
    // 'faSquareInstagram',
    // 'faInstagramSquare',
    // 'faBattleNet',
    // 'faTheRedYeti',
    // 'faSquareHackerNews',
    // 'faHackerNewsSquare',
    // 'faEdge',
    // 'faThreads',
    // 'faNapster',
    // 'faSquareSnapchat',
    // 'faSnapchatSquare',
    // 'faGooglePlusG',
    // 'faArtstation',
    // 'faMarkdown',
    // 'faSourcetree',
    // 'faGooglePlus',
    // 'faDiaspora',
    // 'faFoursquare',
    // 'faStackOverflow',
    // 'faGithubAlt',
    // 'faPhoenixSquadron',
    // 'faPagelines',
    // 'faAlgolia',
    // 'faRedRiver',
    // 'faCreativeCommonsSa',
    // 'faSafari',
    // 'faGoogle',
    // 'faSquareFontAwesomeStroke',
    // 'faFontAwesomeAlt',
    // 'faAtlassian',
    'faLinkedinIn',
    // 'faDigitalOcean',
    // 'faNimblr',
    // 'faChromecast',
    // 'faEvernote',
    // 'faHackerNews',
    // 'faCreativeCommonsSampling',
    // 'faAdversal',
    // 'faCreativeCommons',
    // 'faWatchmanMonitoring',
    // 'faFonticons',
    // 'faWeixin',
    // 'faShirtsinbulk',
    // 'faCodepen',
    // 'faGitAlt',
    // 'faLyft',
    // 'faRev',
    // 'faWindows',
    // 'faWizardsOfTheCoast',
    // 'faSquareViadeo',
    // 'faViadeoSquare',
    // 'faMeetup',
    // 'faCentos',
    // 'faAdn',
    // 'faCloudsmith',
    // 'faPiedPiperAlt',
    // 'faSquareDribbble',
    // 'faDribbbleSquare',
    // 'faCodiepie',
    // 'faNode',
    // 'faMix',
    // 'faSteam',
    // 'faCcApplePay',
    'faScribd',
    // 'faDebian',
    // 'faOpenid',
    // 'faInstalod',
    // 'faExpeditedssl',
    // 'faSellcast',
    'faSquareTwitter',
    'faTwitterSquare',
    // 'faRProject',
    // 'faDelicious',
    // 'faFreebsd',
    // 'faVuejs',
    // 'faAccusoft',
    // 'faIoxhost',
    // 'faFonticonsFi',
    // 'faAppStore',
    // 'faCcMastercard',
    // 'faItunesNote',
    // 'faGolang',
    // 'faKickstarter',
    // 'faGrav',
    // 'faWeibo',
    // 'faUncharted',
    // 'faFirstdraft',
    'faSquareYoutube',
    'faYoutubeSquare',
    'faWikipediaW',
    // 'faWpressr',
    // 'faRendact',
    // 'faAngellist',
    // 'faGalacticRepublic',
    // 'faNfcDirectional',
    // 'faSkype',
    // 'faJoget',
    // 'faFedora',
    // 'faStripeS',
    // 'faMeta',
    // 'faLaravel',
    // 'faHotjar',
    // 'faBluetoothB',
    // 'faStickerMule',
    // 'faCreativeCommonsZero',
    // 'faHips',
    // 'faBehance',
    'faReddit',
    'faDiscord',
    // 'faChrome',
    // 'faAppStoreIos',
    // 'faCcDiscover',
    // 'faWpbeginner',
    // 'faConfluence',
    // 'faMdb',
    // 'faDochub',
    // 'faAccessibleIcon',
    // 'faEbay',
    // 'faAmazon',
    // 'faUnsplash',
    // 'faYarn',
    // 'faSquareSteam',
    // 'faSteamSquare',
    // 'fa500px',
    'faSquareVimeo',
    'faVimeoSquare',
    // 'faAsymmetrik',
    // 'faFontAwesome',
    // 'faFontAwesomeFlag',
    // 'faFontAwesomeLogoFull',
    // 'faGratipay',
    // 'faApple',
    // 'faHive',
    // 'faGitkraken',
    // 'faKeybase',
    // 'faApplePay',
    // 'faPadlet',
    // 'faAmazonPay',
    // 'faSquareGithub',
    // 'faGithubSquare',
    // 'faStumbleupon',
    // 'faFedex',
    // 'faPhoenixFramework',
    'faShopify',
    // 'faNeos',
    // 'faSquareThreads',
    // 'faHackerrank',
    // 'faResearchgate',
    // 'faSwift',
    // 'faAngular',
    // 'faSpeakap',
    // 'faAngrycreative',
    // 'faYCombinator',
    // 'faEmpire',
    // 'faEnvira',
    // 'faSquareGitlab',
    // 'faGitlabSquare',
    // 'faStudiovinari',
    // 'faPiedPiper',
    // 'faWordpress',
    // 'faProductHunt',
    // 'faFirefox',
    // 'faLinode',
    // 'faGoodreads',
    // 'faSquareOdnoklassniki',
    // 'faOdnoklassnikiSquare',
    // 'faJsfiddle',
    // 'faSith',
    // 'faThemeisle',
    // 'faPage4',
    // 'faHashnode',
    // 'faReact',
    // 'faCcPaypal',
    // 'faSquarespace',
    // 'faCcStripe',
    // 'faCreativeCommonsShare',
    // 'faBitcoin',
    // 'faKeycdn',
    // 'faOpera',
    // 'faItchIo',
    // 'faUmbraco',
    // 'faGalacticSenate',
    // 'faUbuntu',
    // 'faDraft2digital',
    // 'faStripe',
    // 'faHouzz',
    // 'faGg',
    // 'faDhl',
    'faSquarePinterest',
    'faPinterestSquare',
    // 'faXing',
    // 'faBlackberry',
    // 'faCreativeCommonsPd',
    // 'faPlaystation',
    // 'faQuinscape',
    // 'faLess',
    // 'faBloggerB',
    // 'faOpencart',
    // 'faVine',
    // 'faPaypal',
    // 'faGitlab',
    // 'faTypo3',
    // 'faRedditAlien',
    // 'faYahoo',
    // 'faDailymotion',
    // 'faAffiliatetheme',
    // 'faPiedPiperPp',
    // 'faBootstrap',
    // 'faOdnoklassniki',
    // 'faNfcSymbol',
    // 'faEthereum',
    // 'faSpeakerDeck',
    // 'faCreativeCommonsNcEu',
    // 'faPatreon',
    // 'faAvianex',
    // 'faEllo',
    // 'faGofore',
    // 'faBimobject',
    'faFacebookF',
    // 'faSquareGooglePlus',
    // 'faGooglePlusSquare',
    // 'faMandalorian',
    // 'faFirstOrderAlt',
    // 'faOsi',
    // 'faGoogleWallet',
    // 'faDAndDBeyond',
    // 'faPeriscope',
    // 'faFulcrum',
    // 'faCloudscale',
    // 'faForumbee',
    // 'faMizuni',
    // 'faSchlix',
    // 'faSquareXing',
    // 'faXingSquare',
    // 'faBandcamp',
    // 'faWpforms',
    // 'faCloudversify',
    // 'faUsps',
    // 'faMegaport',
    // 'faMagento',
    'faSpotify',
    // 'faOptinMonster',
    // 'faFly',
    // 'faAviato',
    // 'faItunes',
    // 'faCuttlefish',
    'faBlogger',
    'faFlickr',
    'faViber',
    // 'faSoundcloud',
    // 'faDigg',
    // 'faTencentWeibo',
    // 'faSymfony',
    // 'faMaxcdn',
    'faEtsy',
    'faFacebookMessenger',
    // 'faAudible',
    // 'faThinkPeaks',
    // 'faBilibili',
    // 'faErlang',
    'faXTwitter',
    // 'faCottonBureau',
    // 'faDashcube',
    // 'fa42Group',
    // 'faInnosoft',
    // 'faStackExchange',
    // 'faElementor',
    // 'faSquarePiedPiper',
    // 'faPiedPiperSquare',
    // 'faCreativeCommonsNd',
    // 'faPalfed',
    // 'faSuperpowers',
    // 'faResolving',
    // 'faXbox',
    'faSearchengin',
    'faTiktok',
    'faSquareFacebook',
    'faFacebookSquare',
    // 'faRenren',
    // 'faLinux',
    // 'faGlide',
    'faLinkedin',
    // 'faHubspot',
    // 'faDeploydog',
    // 'faTwitch',
    // 'faRavelry',
    // 'faMixer',
    // 'faSquareLastfm',
    // 'faLastfmSquare',
    'faVimeo',
    // 'faMendeley',
    // 'faUniregistry',
    // 'faFigma',
    // 'faCreativeCommonsRemix',
    // 'faCcAmazonPay',
    // 'faDropbox',
    'faInstagram',
    // 'faCmplid',
    'faFacebook',
    // 'faGripfire',
    // 'faJediOrder',
    // 'faUikit',
    // 'faFortAwesomeAlt',
    // 'faPhabricator',
    // 'faUssunnah',
    // 'faEarlybirds',
    // 'faTradeFederation',
    // 'faAutoprefixer',
    'faWhatsapp',
    // 'faSlideshare',
    // 'faGooglePlay',
    // 'faViadeo',
    // 'faLine',
    // 'faGoogleDrive',
    // 'faServicestack',
    // 'faSimplybuilt',
    // 'faBitbucket',
    // 'faImdb',
    // 'faDeezer',
    // 'faRaspberryPi',
    // 'faJira',
    // 'faDocker',
    // 'faScreenpal',
    // 'faBluetooth',
    // 'faGitter',
    // 'faDAndD',
    // 'faMicroblog',
    // 'faCcDinersClub',
    // 'faGgCircle',
    // 'faPiedPiperHat',
    // 'faKickstarterK',
    // 'faYandex',
    // 'faReadme',
    // 'faHtml5',
    // 'faSellsy',
    // 'faSass',
    // 'faWirsindhandwerk',
    // 'faWsh',
    // 'faBuromobelexperte',
    // 'faSalesforce',
    // 'faOctopusDeploy',
    // 'faMedapps',
    // 'faNs8',
    // 'faPinterestP',
    // 'faApper',
    // 'faFortAwesome',
    // 'faWaze',
    // 'faCcJcb',
    // 'faSnapchat',
    // 'faSnapchatGhost',
    // 'faFantasyFlightGames',
    // 'faRust',
    // 'faWix',
    // 'faSquareBehance',
    // 'faBehanceSquare',
    // 'faSupple',
    // 'faRebel',
    // 'faCss3',
    // 'faStaylinked',
    // 'faKaggle',
    // 'faSpaceAwesome',
    // 'faDeviantart',
    // 'faCpanel',
    // 'faGoodreadsG',
    // 'faSquareGit',
    // 'faGitSquare',
    // 'faSquareTumblr',
    // 'faTumblrSquare',
    // 'faTrello',
    // 'faCreativeCommonsNcJp',
    // 'faGetPocket',
    // 'faPerbyte',
    // 'faGrunt',
    // 'faWeebly',
    // 'faConnectdevelop',
    // 'faLeanpub',
    // 'faBlackTie',
    // 'faThemeco',
    // 'faPython',
    // 'faAndroid',
    // 'faBots',
    // 'faFreeCodeCamp',
    // 'faHornbill',
    // 'faJs',
    // 'faIdeal',
    // 'faGit',
    // 'faDev',
    'faSketch',
    // 'faYandexInternational',
    // 'faCcAmex',
    'faUber',
    // 'faGithub',
    // 'faPhp',
    // 'faAlipay',
    'faYoutube',
    // 'faSkyatlas',
    // 'faFirefoxBrowser',
    // 'faReplyd',
    // 'faSuse',
    // 'faJenkins',
    'faTwitter',
    // 'faRockrms',
    'faPinterest',
    // 'faBuffer',
    // 'faNpm',
    // 'faYammer',
    // 'faBtc',
    'faDribbble',
    // 'faStumbleuponCircle',
    // 'faInternetExplorer',
    // 'faStubber',
    'faTelegram',
    // 'faTelegramPlane',
    // 'faOldRepublic',
    // 'faOdysee',
    // 'faSquareWhatsapp',
    // 'faWhatsappSquare',
    // 'faNodeJs',
    // 'faEdgeLegacy',
    'faSlack',
    // 'faSlackHash',
    // 'faMedrt',
    // 'faUsb',
    'faTumblr',
    // 'faVaadin',
    // 'faQuora',
    // 'faSquareXTwitter',
    // 'faReacteurope',
    'faMedium',
    // 'faMediumM',
    // 'faAmilia',
    // 'faMixcloud',
    // 'faFlipboard',
    // 'faViacoin',
    // 'faCriticalRole',
    // 'faSitrox',
    // 'faDiscourse',
    // 'faJoomla',
    // 'faMastodon',
    'faAirbnb',
    // 'faWolfPackBattalion',
    // 'faBuyNLarge',
    // 'faGulp',
    // 'faCreativeCommonsSamplingPlus',
    // 'faStrava',
    // 'faEmber',
    // 'faCanadianMapleLeaf',
    // 'faTeamspeak',
    // 'faPushed',
    // 'faWordpressSimple',
    // 'faNutritionix',
    // 'faWodu',
    // 'faGooglePay',
    // 'faIntercom',
    // 'faZhihu',
    // 'faKorvue',
    // 'faPix',
    // 'faSteamSymbol'
]