import { Grid } from '@mui/material';
import React from 'react';

/**
 * The CustomTitle, that display each page custom title.
 * @property {string} title the page title
 * @property {string} subtitle the page subtitle
 * @property {string} subtitleClass the page subtitle class
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTitle({ title, subtitle = "", subtitleClass = "usee-h3" }) {
    return (
        <Grid>
            {title !== undefined && <h1>{title}</h1>}
            <h3 className={subtitleClass}>{subtitle}</h3>
        </Grid>
    );
}

export default CustomTitle;