
import { Button } from '@mui/base';
import { Grid } from '@mui/material';
import CustomImageCropper from 'components/Assets/CustomImageCropper';
import CustomPhotoDraggableTable from 'components/Table/draggable/photo/CustomPhotoDraggableTable';

/**
 * The PhotoComponent, that display customized {@link Grid}to provide the list of photos for items/menus/parent categories/categories/outlets.
 * 
 * @param {*} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @param {*} children The children of the drawer
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function PhotoComponent({
    imagePath, photos, showEmptyMessage, emptyMessage,
    updatePhotoHiddenState, updatePhotoCoverState, deletePhoto, handleFileChange, updatePhotoOrder,
    field, fieldId
}) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={0} sm={3}></Grid>
                    <Grid item xs={12} sm={6}>
                        <Button>
                            {/* Choose File */}
                            <CustomImageCropper storeCroppedImage={handleFileChange} />
                        </Button>
                    </Grid>
                    <Grid item xs={0} sm={3}></Grid>

                    <Grid item xs={12}>
                        <CustomPhotoDraggableTable
                            photos={photos}
                            showEmptyMessage={showEmptyMessage}
                            emptyMessage={emptyMessage}
                            imagePath={imagePath}
                            setHidden={(event, index) => updatePhotoHiddenState(event, index)}
                            setCover={(event, index) => updatePhotoCoverState(event, index)}
                            deletePhoto={(event, imageSrc, index) => deletePhoto(event, index)}
                            updatePhotoOrder={updatePhotoOrder}
                            field={field}
                            fieldId={fieldId}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
            </Grid>
        </Grid>
    );
}

export default PhotoComponent;