import { Box, Fab, Grid, Tooltip } from "@mui/material";
import itemOfferApi from "api/itemOffer";
import { AddIcon } from "assets/svg/SvgIcons";
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from 'components/Dialogs/CustomDialog';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from "components/Select/CustomObjectSelect";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from "components/Title/CustomTitle";
import { getDateTimeFormatter } from 'constants/calendar';
import { getDefaultSortDirectionData } from 'constants/sortDirection';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagTitle } from 'themes/defaultThemes';
import { hasRoleAdmin, hasRoleAdminMenu } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { reloadPage } from 'utils/page';
import { actionFormatter, dateFormatter, fieldFormatter, iconFormatter, idFormatterLinkWithParent, itemNameFormatter } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The Offer Item List component to view the items of the system.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ItemOfferListComponent() {
   const { t } = useTranslation();
   const { organizationID } = useParams();
   const navigate = useNavigate();

   let path = `/${getLanguageFromURL()}`
   if (organizationID !== undefined) path += `/organization/${organizationID}`;
   // if (outletID !== undefined) path += `/outlet/${outletID}`;
   path += `/offer`;

   /**
    * @type {object}
    * 
    * @property {object} userAuth the authenticated user infromation
    * @property {object} organization holds the organization information
    * @property {array} items the menu items list
    * @property {array} itemCategories the different item categories options
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
    */
   const [data, setData] = useState({
      userAuth: null,
      offers: [],
      organization: {
         id: organizationID,
         name: ""
      },
      organizations: [],
      outlet: {},
      outlets: [],
      itemCategories: [],
      types: [],
      pagination: {
         count: -1,
         size: 10,
         page: 0,
         ...getDefaultSortDirectionData
      }
   });

   //change document title
   useDocumentTitle(`Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t('org.pageTitle') : ""} | ${t('offer.title')}`)

   // to perform initial request
   const [isFirstTime, setIsFirstTime] = useState(true);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);
   // if true the menu item is clicked and a redirect to page should perform
   const [redirect, setRedirect] = useState(false);
   // for search
   const [search, setSearch] = useState({
      organizationID: organizationID,
      name: {
         en: ""
      },
      enabled: null
   });
   // pop dialog for delete
   const [dialog, setDialog] = useState({
      isOpen: false,
      offerID: "",
      name: "",
   });
   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });


   useEffect(() => {
      getOfferItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      getOfferItems();
      setIsFirstTime(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const identifier = setTimeout(() => {
         if (!isFirstTime) getOfferItems();
      }, 700);

      return () => {
         clearTimeout(identifier);
      }
   }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])


   /**
    * The rest endpoint to get the items list.
    */
   function getOfferItems() {
      let sear = search;
      if (hasRoleAdminMenu(data.userAuth?.roles)) {
         sear.organizationID = localStorage.getItem("oid")
      }
      itemOfferApi.fetchAll(prepareTablePaginationSortParams(data.pagination), sear).then((r) => {
         Promise.resolve()
            .then(() => {
               setData({
                  ...data,
                  userAuth: r.data.returnobject.userAuth,
                  offers: r.data.returnobject.offers,
                  organization: r.data.returnobject.organization,
                  organizations: r.data.returnobject.organizations,
                  outlet: r.data.returnobject.outlet,
                  outlets: r.data.returnobject.outlets,
                  itemCategories: r.data.returnobject.itemCategories,
                  types: r.data.returnobject.types,
                  pagination: {
                     ...data.pagination,
                     count: r.data.returnobject.offers.totalPages,
                     size: r.data.returnobject.offers.size,
                     page: r.data.returnobject.offers.number,
                  }
               });
            })
            .then(() => {
               let organizationID = r.data.returnobject.organization !== null ? r.data.returnobject.organization.id : null;
               if ((search.organizationID !== null && hasRoleAdmin(r.data.returnobject.userAuth.roles)) || !hasRoleAdmin(r.data.returnobject.userAuth.roles))
                  if (organizationID !== search.organizationID)
                     setSearch({ ...search, organizationID: organizationID });
               // if ((search.organizationID !== null && hasRoleAdmin(r.data.returnobject.userAuth.roles)) || !hasRoleAdmin(r.data.returnobject.userAuth.roles))
               //     if (organizationID !== search.organizationID || (outletID !== search.outletID))
               //         setSearch({ ...search, organizationID: organizationID, outletID: outletID !== null ? outletID : (r.data.returnobject.outlets.size === 1) ? r.data.returnobject.outlets.outlets[0].id : null });
            })
            .then(() => {
               setIsLoaded(true);
            })
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected item
    * @param {*} event
    * @param {string} id the id of the item
    */
   function enableOfferItem(event, id) {
      itemOfferApi.enabled(id).then((r) => {
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getOfferItems();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected item
    * @param {*} event
    * @param {string} id the id of the item
    */
   function disableOfferItem(event, id) {
      itemOfferApi.disable(id).then((r) => {
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getOfferItems();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Function that handles the delete modal open or close data.
    * @property {boolean} isOpen The state of the dialog open status.
    * @property {string} offerID The id of the selected item to be removed.
    */
   function handleDialogState(isOpen, offerID = "", name = "") {
      setDialog({
         ...dialog,
         isOpen: isOpen,
         offerID: offerID,
         name: name
      });
   }
   /**
    * 
    * Function that triggers the removal of the selected equipmentID.
    * @property {*} event
    * @property {string} equipmentID The id of the selected equipmentID to be removed.
    */
   function deleteOfferItem(event, offerID, name = "") {
      handleDialogState(true, offerID, name);
   }
   /**
    * Gets called to remove the selected item
    */
   function deleteI() {
      itemOfferApi.deleteById(dialog.offerID).then((r) => {
         handleDialogState(false);
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getOfferItems();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Function that handles the snackbar open or close data.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }

   /**
    * Function that handles the pagination information.
    * @property {int} page the page to be redirected
    */
   function handlePaginationChange(page) {
      formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
   }

   /**
    * Function that handles the pagination information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleRowsPerPageChange(size) {
      let pagination = data.pagination;
      pagination.size = size;
      pagination.page = 0;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }
   /**
    * Function that handles the sort information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleColumnSortChange(changedColumn, direction) {
      let pagination = data.pagination;
      pagination.sort = changedColumn;
      pagination.direction = direction;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }


   function onFilterChange(event) {
      if (event !== null) {
         let field = event.field;
         let value = event.value;
         if (event.field === "enabled") {
            if (value === t("offer.disabled")) {
               value = false;
            } else if (value === t("offer.enabled")) {
               value = true;
            }
         } else if (event.field === "type") {
            if (value === t("offer.type.OFFER")) {
               value = "OFFER";
            } else if (value === t("offer.type.COMBO")) {
               value = "COMBO";
            } else if (value === t("offer.type.HAPPY_HOUR")) {
               value = "HAPPY_HOUR";
            } else if (value === t("offer.type.ONE_PLUS_ONE")) {
               value = "ONE_PLUS_ONE";
            }
         }
         formValChangeWithParentElementWithNameAndValue(field, value, search, setSearch)
      } else {
         setSearch({
            ...search,
            name: {
               en: ""
            },
            enabled: null
         })
      }
   }

   // ===============================================================================RENDER

   if (redirect) {
      reloadPage();
   } else if (!isLoaded) {
      return <CustomGrid role="main" id="offers-list" sx={mainTag()}>
         <LoadingSkeleton lines={9} />
      </CustomGrid>
   } else {
      /**
       * datatable columns.
       */
      const table_columns = [
         {
            name: "enabled",
            label: t('offer.enabled'),
            options: {
               filter: true,
               filterType: 'select',
               customFilterListOptions: {
                  render: v => `${t('offer.enabled')}: ${v}`
               },
               filterOptions: {
                  names: [t("offer.enabled"), t("offer.disabled")]
               },
               sort: false,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return iconFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     {
                        enable: (event, rowIndex) => enableOfferItem(event, rowIndex),
                        disable: (event, rowIndex) => disableOfferItem(event, rowIndex),
                        positionOfId: 1
                     });
               }
            }
         },
         {
            name: "id",
            label: t('label.id'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
               customBodyRender: (value, tableMeta) => {
                  return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
               }
            }
         },
         {
            name: "name",
            label: t('label.name'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('label.name')}: ${v.en}`,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  let row = tableMeta.tableData[tableMeta.rowIndex];
                  let positionOfId = 1;
                  return itemNameFormatter(value?.en, row, 3, `${path}/${row[positionOfId]}`);
               }
            }
         },
         {
            name: "description",
            label: t('label.description'),
            options: {
               filter: false,
               display: true,
               customBodyRender: (value, tableMeta) => {
                  return value?.en
               }
            }
         },
         {
            name: "price",
            label: t('offer.price'),
            options: {
               filter: false,
               sort: false,
               empty: false
            }
         },
         {
            name: "percentage",
            label: t('offer.percentage'),
            options: {
               filter: false,
               sort: false,
               empty: false
            }
         },
         {
            name: "type",
            label: t('offer.type.label'),
            options: {
               filter: true,
               filterType: 'select',
               customFilterListRender: v => `${t('offer.type.label')}: ${v}`,
               filterOptions: {
                  names: data.types.map((value) => t(`offer.type.${value}`))
               },
               display: true,
               customBodyRender: (value, tableMeta) => {
                  return fieldFormatter(t(`offer.type.${value}`));
               }
            }
         },
         {
            name: "loyaltyPoints",
            label: t('offer.loyaltyPoints'),
            options: {
               filter: false,
               display: true
            }
         },
         {
            name: "lastActivity",
            label: t('label.lastActivity'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateTimeFormatter());
               }
            }
         },
         {
            name: "",
            label: t('actions.label'),
            options: {
               filter: false,
               sort: false,
               empty: true,
               customBodyRender: (value, tableMeta) => {
                  return actionFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     1,
                     null,
                     null,
                     null,
                     2,
                     path,
                     data.showLabel ? t('actions.edit') : undefined,
                     (event, rowIndex, name) => deleteOfferItem(event, rowIndex, name),
                     data.showLabel ? t('actions.delete') : undefined,
                     true
                  )
               },
               setCellProps: () => ({ className: "click action" })
            }
         }
      ];

      return (
         <CustomGrid role="main" id="offers-list" sx={mainTag()}>
            {(organizationID !== undefined && !hasRoleAdminMenu(data.userAuth.roles)) &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomBreadcrumbMultiParent
                     parents={[
                        { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                        { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` }
                     ]}
                     instanceName={t('offer.title')}
                  />
               </CustomGrid>
            }

            <Grid container spacing={2} sx={{ alignItems: "center" }}>
               <Grid item xs={"auto"}>
                  <CustomRefreshButton onClick={getOfferItems} marginTop="0" />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <CustomGrid sx={mainTagTitle()}>
                     <CustomTitle
                        title={t('offer.title')}
                        subtitle={t('offer.subtitle')}
                     />
                  </CustomGrid>
               </Grid>

               <Grid item xs={12} sm={8.2} sx={{ merginLeft: 0 }}>
                  <Grid container sx={mainTagSearch()} spacing={2}>
                     <Box sx={{ flexGrow: 1 }} /> {/* Spacing to push the following items to the right */}

                     {hasRoleAdminMenu(data.userAuth?.roles) &&
                        <Grid item xs={"auto"}>
                           <label htmlFor="organizationID">{t('org.pageTitle')}</label>
                           <CustomObjectSelect
                              name="organizationID"
                              required={true}
                              value={localStorage.getItem("oid")}
                              // labelID="organizationID" label={t('org.pageTitle')}
                              options={data.organizations}
                              onChange={(event) => { localStorage.setItem("oid", event.target?.value); setSearch({ ...search, organizationID: event.target?.value }) }}
                              field={"name"}
                              sx={{ borderRadius: "30px" }}
                           />
                        </Grid>
                     }
                  </Grid>
               </Grid>
            </Grid>

            <CustomGrid sx={mainAreaTable()} container={false}>
               <CustomPageableTable
                  instances={data.offers}
                  emptyMessage={t('offer.emptyMessage')}
                  table_columns={table_columns}
                  keyField="id"
                  defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                  pagination={data.pagination}
                  handlePaginationChange={handlePaginationChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handleColumnSortChange={handleColumnSortChange}
                  filterType="dropdown"
                  filter={true}
                  onFilterChange={onFilterChange}
               />
            </CustomGrid>

            <Fab color="primary"
               aria-label="add"
               sx={fabStyle()}
               onClick={() => navigate(`${path}/add`)}
            >
               <Tooltip arrow title={t('actions.add') + " " + t('offer.title')} sx={fabTooltipStyle()}>
                  <AddIcon {...fabStyleIcon()} />
               </Tooltip>
            </Fab>

            {dialog.isOpen &&
               <CustomDialog
                  isOpen={dialog.isOpen}
                  id="delete"
                  title={t('actions.deleteModal.title')}
                  actionLabel={t("actions.deleteModal.message")}
                  cancelLabel={t("actions.cancel")}
                  message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('offer.pageTitle')}: `}
                  boldMessage={<span style={{ fontWeight: "bold" }}>{dialog.name}</span>}
                  action={deleteI}
                  handleOpen={handleDialogState}
                  actionColor="#e91e1e"
               />
            }

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }
         </CustomGrid>
      );
   }
}

export default ItemOfferListComponent;