import React from 'react';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import CustomMenuCategoriesDraggableTableItem from "./CustomMenuCategoriesDraggableTableItem";

/**
 * A Custom Draggable List.
 * @property {string} parentCategoryId the id of the parent category of all categories
 * @property {number} parentCategoryIndex the index of the parent category inside the droppable-draggable
 * @property {array} categories the array of the categories
 * @property {boolean} showEmptyMessage to specify if the empty list message should be shown
 * @property {string} emptyMessage The message to presented if the `label` is empty
 * @property {*} removeCategory function that removes the modifier-list instance
 * @property {*} save function that updates a modifier-list details
 * @property {boolean} expandAll to specify if the all child elements should be shown
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuCategoriesDraggableTable({ parentCategoryId, parentCategoryIndex, categories, organizationID, showEmptyMessage = true, emptyMessage, expandAll, handleTranslationsDialogState,
    updateCategoryName, updateCategoryIsActive, updateMenuCategoriesOrder, removeCategory,// categories
    itemOptions, removeItemFromCategory, handleDialogState, updateItemOrder// items
}) {
    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = async (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }
        const oredereCategories = reorder(categories, result.source.index, result.destination.index)
        categories = oredereCategories;
        updateMenuCategoriesOrder(parentCategoryId, oredereCategories)

    }

    return (
        <>
            {
                (categories !== null && categories.length > 0) &&
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`droppable-menu-categories-${parentCategoryIndex}`} type={`PARENT-CATEGORIES-${parentCategoryIndex}-CATEGORIES`} key={`droppable-menu-categories-${parentCategoryIndex}`}>
                        {(provided, snapshot) => (
                            <ul key={`categories-list-${parentCategoryIndex}`} ref={provided.innerRef} {...provided.droppableProps} style={{
                                background: snapshot.isDraggingOver ? "lightblue" : '',
                                borderRadius: "4px",
                                paddingLeft: 0
                            }} >
                                {categories.map((category, index) => (
                                    <div key={index}>
                                        <CustomMenuCategoriesDraggableTableItem
                                            parentCategoryId={parentCategoryId}
                                            parentCategoryIndex={parentCategoryIndex}
                                            organizationID={organizationID}
                                            category={category}
                                            index={index}
                                            handleTranslationsDialogState={handleTranslationsDialogState}
                                            // categories
                                            updateCategoryName={updateCategoryName}
                                            updateCategoryIsActive={updateCategoryIsActive}
                                            updateMenuCategoriesOrder={updateMenuCategoriesOrder}
                                            removeCategory={removeCategory}
                                            removeItemFromCategory={removeItemFromCategory}
                                            // items
                                            itemOptions={itemOptions}
                                            handleDialogState={handleDialogState}
                                            updateItemOrder={updateItemOrder}
                                        />
                                    </div>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </>
    );
}

export default CustomMenuCategoriesDraggableTable;