
import { Circle as CircleIcon, Delete as DeleteIcon, Edit as EditIcon, Print as PrintIcon, PowerSettingsNew as PowerSettingsNewIcon } from '@mui/icons-material';
import { Card, CardActionArea, Divider, Grid, SvgIcon } from '@mui/material';
import { Stack } from '@mui/system';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomChip from 'components/Chip/CustomChip';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDefaultPrinterObject } from 'constants/defaultObjects';
import { getThemeColor } from 'constants/theme';
import { t } from 'i18next';
import { activePrinterIcon, cardItemButtonGroupStack, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, cardItemContent, cardItemContentActionArea, cardItemContentStackButtonIcon, printerDetailsRow, textPrinterHeaderTypography, textPrinterItemTypography } from 'themes/defaultThemes';
import { getTheme } from 'utils/theme';

/**
 * The Custom printer Card, that display a customized {@link Card}.
 * 
 * @property {string} name the Checkbox name value
 * @property {string} selected If `true`, the component is checked.
 * @property {string} label the Checkbox text value
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {function} onChange Callback that triggered when value is changed
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPrinterCard({
   printer = getDefaultPrinterObject(),
   onDialog = false,
   sx = {},
   updatePrinter,
   deletePrinter,
   equipmentOptions = [],
   availableSectionOptions = [],
   itemCategories = [],
   updatePrinterActiveState }) {

   function calculateTotalEquipment(printerDetails) {
      let total = 0;
      let sectionEquipments = [];
      if (printerDetails.outletSections !== undefined && printerDetails.outletSections !== null && printerDetails.outletSections.length > 0)
         sectionEquipments = equipmentOptions.filter((equipment) => {
            const equipmentId = equipment.id;
            const isEquipmentInSection = printer.outletSections.some((sectionId) => {
               const section = availableSectionOptions.find((section) => section.id === sectionId);
               if (section) {
                  return section.equipments.includes(equipmentId);
               }
               return false;
            });

            return isEquipmentInSection;
         });

      total += sectionEquipments.length;

      // Increase count for included equipment
      if (printerDetails.includedEquipmentIds !== null && printerDetails.includedEquipmentIds !== undefined)
         total += printerDetails.includedEquipmentIds.length;

      // Decrease count for excluded equipment
      if (printerDetails.excludedEquipmentIds !== null && printerDetails.includedEquipmentIds !== undefined)
         total -= printerDetails.excludedEquipmentIds.length;

      return total;
   }

   return (
      <Grid>
         {!onDialog &&
            <Stack direction="row" sx={cardItemButtonGroupStack()} key={`stack-${printer.id}`}>
               <CustomIconButton
                  keyValue={`btn-power-${printer.id}`}
                  onClick={(event) => updatePrinterActiveState(printer, printer.active ? false : true)}
                  icon={<PowerSettingsNewIcon style={cardItemButtonGroupStackButtonIcon()} />}
                  iconSx={{ marginRight: "0px!important" }}
                  sx={{
                     backgroundColor: getThemeColor()[getTheme()][printer.active ? "powerOffButtonBackgroundColor" : "powerOnButtonBackgroundColor"],
                     ":hover": {
                        backgroundColor: getThemeColor()[getTheme()][printer.active ? "powerOffButtonBackgroundColorHover" : "powerOnButtonBackgroundColorHover"],
                     },
                     ...cardItemButtonGroupStackButton()
                  }}
                  tooltipTitle={t(`actions.${printer.active ? "powerOff" : "powerOn"}`)}
               />
               <CustomIconButton
                  keyValue={`btn-edit-${printer.id}`}
                  onClick={(event) => updatePrinter(printer)}
                  icon={<EditIcon style={cardItemButtonGroupStackButtonIcon()} />}
                  iconSx={{ marginRight: "0px!important" }}
                  sx={{
                     backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColor"],
                     ":hover": {
                        backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColorHover"],
                     },
                     ...cardItemButtonGroupStackButton()
                  }}
                  tooltipTitle={t('actions.edit')}
               />
               <CustomIconButton
                  keyValue={`btn-delete-${printer.id}`}
                  onClick={(event) => deletePrinter(event, printer.id, printer.printNodeId, printer.friendlyName)}
                  icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                  iconSx={{ marginRight: "0px!important" }}
                  sx={{
                     backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                     ":hover": {
                        backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                     },
                     ...cardItemButtonGroupStackButton()
                  }}
                  tooltipTitle={t('actions.delete')}
               />
            </Stack>
         }
         <Stack direction="row" sx={cardItemContentStackButtonIcon()} key={`stack-card-${printer.id}`}>
            <Card variant="outlined"
               key={`card-${printer.id}-stack`}
               sx={{
                  ...cardItemContent(),
                  minWidth: "320px",
                  minHeight: "320px",
                  width: "auto",
                  ...sx
               }}
            >
               <CardActionArea sx={cardItemContentActionArea()} key={`card- back-ontent-${printer.id}`}>
                  {/* PRINTER FRIENDLY NAME */}
                  <CustomTypography
                     level="h6"
                     sx={{ ...textPrinterHeaderTypography(), height: "20px" }}
                     text={printer.friendlyName}
                  />
                  {printer.friendlyName && <Divider />}

                  {/* REST OF PRINTER DETAILS */}
                  <Grid container direction="column" sx={{ height: "100%" }}>
                     <Grid direction="row" sx={{ height: "100%", display: "flex", justifyContent: "center", paddingLeft: 0, marginLeft: 0, minWidth: "140px" }} >
                        <CustomIconButton
                           icon={<PrintIcon
                              color={`${printer.configuration.fill}!important`}
                              style={{
                                 fontSize: "60px",
                                 color: printer.configuration.fill,
                                 padding: "10px"
                              }} />}

                           sx={{
                              borderRadius: "50px",
                              backgroundColor: `${printer.configuration.fill}40`,
                              color: `${printer.configuration.fill}40`,
                              border: `1px dash ${printer.configuration.borderColor}`,
                              ":hover": {
                                 backgroundColor: `${printer.configuration.fill}40`,
                              },
                              height: "fit-content",
                              placeSelf: "center",
                              boxShadow: "0px 0px 15px 0px #dcdcdc",
                           }}

                           iconSx={{
                              backgroundColor: "transparent",
                              padding: "0px",
                              margin: "0px"
                           }}
                        />
                        <SvgIcon component={CircleIcon} sx={{ ...activePrinterIcon(), color: printer.active ? "green" : "red" }} />
                        <CustomChip
                           label={t(`printer.type.${printer.type === "IP" ? "ip" : "printNode"}`)}
                           sx={{
                              fontWeight: "900",
                              position: "absolute",
                              marginTop: "190px",
                              boxShadow: "0px 0px 15px 0px #dcdcdc",
                              background: "white"
                           }}
                        />
                        <CustomChip
                           label={t(`printer.paperSize.${printer.paperSize}`)}
                           sx={{
                              fontWeight: "900",
                              position: "absolute",
                              marginTop: "220px",
                              boxShadow: "0px 0px 15px 0px #dcdcdc",
                              background: "white"
                           }}
                        />
                     </Grid>

                     <Grid direction="row">
                        <Grid item xs={12} md={12} spacing={1} >
                           <Grid direction="row" sx={{ textAlign: "left" }}>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomTypography
                                    level="h6"
                                    sx={{ ...textPrinterItemTypography(), textDecoration: "underline" }}
                                    text={printer.type === "IP" ? `${printer.printerIP}:${printer.printerPort}` : `${t('printer.printNodeId')}:`}
                                 />
                              </Grid>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomChip label={printer.printNodeId ? printer.printNodeId : ""} rowIndex={0} />
                              </Grid>
                           </Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={12} spacing={1}>
                           <Grid direction="row" sx={{ textAlign: "left" }}>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomTypography
                                    level="h6"
                                    sx={textPrinterItemTypography()}
                                    text={`${t('printer.outletID')}:`}
                                 />
                              </Grid>
                              <Grid item xs={12} md={12} spacing={1} >
                              <CustomChip label={printer.outlet ? printer.outlet.name : ""} rowIndex={0} />
                              </Grid>
                           </Grid>
                        </Grid> */}
                        <Grid item xs={12} md={12} spacing={1}>
                           <Grid direction="row" sx={{ textAlign: "left" }}>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomTypography
                                    level="h6"
                                    sx={textPrinterItemTypography()}
                                    text={`${t('printer.outletSections')}:`}
                                 />
                              </Grid>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomChip label={printer.outletSections.length} rowIndex={0} />
                                 {/* {printer.outletSections.map((section) =>
                                    <CustomChip label={section} rowIndex={0} />
                                 )} */}
                              </Grid>
                           </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} spacing={1}>
                           <Grid direction="row" sx={{ textAlign: "left" }}>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomTypography
                                    level="h6"
                                    sx={textPrinterItemTypography()}
                                    text={`${t('printer.equipments')}:`}
                                 />
                              </Grid>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomChip label={calculateTotalEquipment(printer)} rowIndex={0} />
                              </Grid>
                           </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} spacing={1}>
                           <Grid container sx={{ textAlign: "left" }}>
                              <Grid item xs={12} md={12} spacing={1} >
                                 <CustomTypography
                                    level="h6"
                                    sx={textPrinterItemTypography()}
                                    text={`${t('itemCategories.label')}:`}
                                 />
                              </Grid>

                              {printer.itemCategories?.length === 0 && (
                                 <Grid item xs={12} md={12}>
                                    <CustomChip label={"-"} rowIndex={0} />
                                 </Grid>
                              )}

                              {printer.itemCategories?.map((itemCategory, index) => (
                                 <Grid item xs={"auto"}>
                                    <CustomChip label={t(`itemCategories.${itemCategory}`)} rowIndex={index} />
                                 </Grid>
                              ))}
                           </Grid>
                        </Grid>

                        {/* <Stack direction="row" spacing={0}}>
                           <CustomTypography
                              level="h6"
                              sx={textPrinterItemTypography()}
                              text={`${t('printer.employees')}:`}
                           />
                           <CustomChip label={printer.employeeIds ? printer.employeeIds.length : 0} rowIndex={0} />
                        </Stack> */}
                     </Grid>
                  </Grid>
               </CardActionArea>
            </Card>
         </Stack>
      </Grid>
   );
}

export default CustomPrinterCard;