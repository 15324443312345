import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
export function closeMenu() {
    // TODO - move it and make it more generic + change its name because it is used for both closing menu and button-wrapper
    // We have different approach when closing menu on medium sized devices to small sized devices
    document.getElementsByTagName('html')[0].classList.remove('dark');
    let body = document.getElementsByTagName('body')[0];
    body.classList.contains('screen-med') ? body.classList.add('menu-close') : this.closeMenuSmallScreen();
    this.removeOuterButtonWrapper();
};

/**
 * Gets called to handle notification and account popup visibility when click on the notification/account icon on top header menu.
 */
export function miniMenuToggleBox(event) {
    var li = event.target.closest('li');
    if (event.target.querySelector("path") === <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>) {
        li.classList.remove('open')
        return;
    }
    var elements = document.getElementById("mini-menu-wrapper").getElementsByTagName("nav")[0].getElementsByTagName("ul")[0].getElementsByTagName('li');

    // Close others
    for (let i = 0; i < elements.length; i++) {
        if (elements[i] !== li) {
            elements[i].classList.remove('open');
        }
    };

    if (!li.classList.contains('open')) {
        li.classList.add('open');
    } else {
        li.classList.remove('open');
    }
};

/**
 * Function to trigger the collapse of the Show more or Show less button.
 *
 * @param {type} event 
 * @returns {object} containing the icon to be displayed on the left side of 
 * the fieldset based on its collapsed status.
 */
export function collapsable(event) {
    var fieldset = event.target.parentNode;
    var svg = event.target.children[0];

    if (!fieldset.classList.contains('collapsed')) {
        fieldset.classList.add('collapsed');
        svg.classList.remove('fa-minus-square');
        svg.classList.add('fa-plus-square');
        return { icon: AddIcon };
    } else {
        fieldset.classList.remove('collapsed');
        svg.classList.remove('fa-plus-square');
        svg.classList.add('fa-minus-square');
        return { icon: RemoveIcon };
    }
};
export function getTheme() {
    return localStorage.getItem('theme');
}

export default class functions {
    static closeMenu() { closeMenu(); }
    static miniMenuToggleBox() { miniMenuToggleBox(); }
    static collapsable(e) { collapsable(e); }
    static getTheme() { getTheme(); }
}