import { useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import lightTableHeadColor from 'assets/scss/app.scss';
import { getDefaultSortDirection } from 'constants/sortDirection';
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { linkStyle, lumanosimoTitleStyle, lumanosimoTitleStylePlain, mainAreaBody } from 'themes/defaultThemes';

/**
 * The CustomCollapsiblePageableTable, that display a table if the provided array is not empty. Otherwise it 
 * presents a corresponding empty table message. The table also has pageable and collapsible functionality.
 *
 * @property {string} id The table id
 * @property {string} classAppend extra classes for the table div element
 * @property {object} instances the list of items to be presented on a table if any
 * @property {boolean} showEmptyMessage to specify if the empty list message should be shown
 * @property {string} emptyMessage The message to presented if the @link(instances) is empty
 * @property {string} addPageUrl The url of the add instance page if a reference should be included
 * @property {array} table_columns The datatable columns configuration
 * @property {string} keyField Tells react-bootstrap-table2 which column is unique.
 * @property {object} rowEvents Custom events on row
 * @property {array} defaultSorted accept an object array which allow you to define the default sort columns when first render.
 * @property {string} classes The table classes
 * @property {object} pagination includes all pageable details (page, size, sort, direction)
 * @property {object} options the table options
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomExpandablePageableTable({
    id = "",
    classAppend = "",
    instances,
    showEmptyMessage = true,
    emptyMessage,
    addPageUrl = "",
    table_columns,
    keyField,
    rowEvents,
    defaultSorted = getDefaultSortDirection(),
    classes,
    pagination,
    handlePaginationChange,
    handleRowsPerPageChange,
    handleColumnSortChange,
    onRowSelectionChange = undefined,
    extraOptions,
    filter = false,
    download = false,
    onFilterChange
}) {

    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:468px)');
    const [denseTable, setDenseTable] = useState(true);
    function getMuiTheme() {
        return createTheme({
            components: {
                // MUIDataTableSelectCell: {
                //     styleOverrides: {
                //         headerCell: {
                //             backgroundColor: "#54b1d2",
                //             color: "#fff"
                //         }
                //     }
                // },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            verticalAlign: "middle",
                            textAlign: "center",
                            height: "40px",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            fontWeight: "600",
                            color: "grey",
                            ...lumanosimoTitleStylePlain()
                        },
                        data: {
                            color: "initial",
                            ...lumanosimoTitleStyle()
                        },
                        sortAction: {
                            color: lightTableHeadColor
                        },
                        //         sortActive: {
                        //             color: "#$",
                        //             ":hover": {
                        //                 textDecoration: "underline"
                        //             }
                        //         },
                        contentWrapper: {
                            justifyContent: "center"
                        },
                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            verticalAlign: "middle",
                            textAlign: "center !important",
                        }
                    }
                }
            }
        })
    }

    return (
        <>
            {/* {showEmptyMessage && (instances === null || instances?.totalElements === 0 && !filter) &&
                <div className="body" style={{ ...mainAreaBody() }}>
                    {emptyMessage} {addPageUrl !== "" && <a className="link" href={addPageUrl}>{t('actions.clickHere')}</a>}
                </div>
            } */}

            {((instances !== null && instances?.totalElements > 0) || filter) &&
                <div className={`table-wrapper ${classAppend}`}>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            id={id}
                            data={instances?.content}
                            columns={table_columns}
                            scrollable
                            page={instances?.number}
                            rowsPerPage={instances?.size}
                            options={{
                                tableId: id,
                                serverSide: true,
                                caseSensitive: false,
                                textLabels: {
                                    ...t('table.labels'),
                                    body: {
                                        ...t('table.labels.body'),
                                        noMatch: <> {emptyMessage} {addPageUrl !== "" && <a className="link" style={linkStyle()} href={addPageUrl}>{t('actions.clickHere')}</a>}</>
                                    }
                                },

                                filter: filter,
                                filterDisplay: 'rows',
                                onFilterChange: (changedColumn, filterList, type, index) => {
                                    // console.log(`Filter changed in column: ${changedColumn}`);
                                    // console.log(`Current filter list:`, filterList);
                                    // console.log(`Type of filter change: ${type}`);
                                    // console.log(`Index of changed column: ${index}`);
                                    if (type === "reset") { onFilterChange(null) }
                                    else
                                        onFilterChange({
                                            field: changedColumn,
                                            value: filterList[index][0],
                                            index: index
                                        })
                                },

                                expandableRows: true,
                                expandableRowsHeader: false,
                                expandableRowsOnClick: false,

                                viewColumns: true,
                                fixedHeader: false,
                                fixedSelectColumn: false,
                                tableBodyMaxHeight: "100%",
                                tableBodyHeight: "auto",
                                resizableColumns: false,

                                pagination: true,
                                jumpToPage: !isMobile,

                                download: download,
                                print: false,
                                rowHover: false,
                                search: false,

                                selectableRows: onRowSelectionChange === undefined ? "none" : "multiple",
                                selectableRowsOnClick: false,
                                selectableRowsHeader: false,
                                selectableRowsHideCheckboxes: onRowSelectionChange === undefined,
                                selectToolbarPlacement: "none", //select toolbar will appear above default toolbar when a row is selected

                                sort: true,
                                sortFilterList: true,
                                sortOrder: defaultSorted,

                                page: pagination.page,
                                rowsSelected: [],
                                rowsPerPageOptions: [5, 10, 15, 50, 100],
                                rowsPerPage: pagination !== undefined ? pagination.size : 10,
                                responsive: isMobile ? "standard" : "vertical",
                                onChangePage: (pageIndex) => handlePaginationChange(pageIndex),
                                onChangeRowsPerPage: (numberOfRows) => handleRowsPerPageChange(numberOfRows),
                                onColumnSortChange: (changedColumn, direction) => handleColumnSortChange(changedColumn, direction),

                                count: instances?.totalElements,

                                setTableProps: () => {
                                    return {
                                        // padding: denseTable ? '5px' : '10px',
                                        // material ui v5 only
                                        size: denseTable ? 'small' : 'medium',
                                    };
                                },

                                ...extraOptions
                            }}
                        />
                    </ThemeProvider>

                    {/* {pagination.count > 1 && <CustomPagination {...pagination} handlePaginationChange={handlePaginationChange} rowsPerPage={instances.size} count={instances.totalElements} />} */}
                </div>
            }
        </>
    );
}

export default CustomExpandablePageableTable;