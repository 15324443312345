import { Fab, Grid, Tooltip } from "@mui/material";
import subscriptionPlanApi from "api/subscription-plan";
import { AddIcon } from "assets/svg/SvgIcons";
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from 'components/Dialogs/CustomDialog';
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from 'components/Title/CustomTitle';
import { getDateFormatter } from "constants/calendar";
import { getDefaultSubscriptionPlanSortDirection } from "constants/sortDirection";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagTitle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from "utils/form-validation";
import { prepareTablePaginationSortParams } from "utils/functions";
import { getLanguageFromURL } from 'utils/language';
import { reloadPage } from 'utils/page';
import { actionFormatter, dateFormatter, iconFormatter, idFormatterLinkWithParent, nanFormatter, typeFormatter } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The SubscriptionPlanListComponent, that allow the user to view the subscription plans list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SubscriptionPlanListComponent() {

   const { t } = useTranslation();
   const navigate = useNavigate();
   //change document title
   useDocumentTitle(`Usee | ${t('subscriptionPlan.title')}`)

   /**
    * @type {object}
    * 
    * @property {boolean} redirect if a redirect should be performed
    * @property {boolean} isLoaded to render DOM based on rest api call status, if true the rest api call completed
    * @property {object} userAuth the authenticated user infromation
    * @property {object} alertBox holds snackbar infromation and style
    * @property {array} subscriptionPlans the subscription plans list
    * @property {array} typeOptions the different type options
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
    * @property {object} dialog holds dialog for delete action
    * @property {object} alertBox holds snackbar infromation and style
    * @property {object} dialog holds dialog for delete action
    */
   const [data, setData] = useState({
      userAuth: null,
      subscriptionPlans: null,
      typeOptions: [],
      pagination: {
         count: -1,
         size: 10,
         page: 0,
         ...getDefaultSubscriptionPlanSortDirection
      }
   });

   // to perform initial request
   const [isFirstTime, setIsFirstTime] = useState(true);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);
   // if true the menu item is clicked and a redirect to page should perform
   const [redirect, setRedirect] = useState(false);
   // for search
   const [search, setSearch] = useState({
      name: "",
      type: ""
   });

   // pop dialog for delete
   const [dialog, setDialog] = useState({
      isOpen: false,
      subscriptionPlanID: "",
      name: ""
   });
   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });

   useEffect(() => {
      getSubscriptionPlans();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [searchValue, setSearchValue] = useState('');

   useEffect(() => {
      getSubscriptionPlans();
      setIsFirstTime(false);
   }, []);

   useEffect(() => {
      const identifier = setTimeout(() => {
         if (!isFirstTime) getSubscriptionPlans();
      }, 700);

      return () => {
         clearTimeout(identifier);
      }
   }, [searchValue, search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])

   /**
    * The rest endpoint to get the subscription plans list.
    * 
    * @property {number} page the list page number
    */
   function getSubscriptionPlans() {
      subscriptionPlanApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
         setData({
            ...data,
            subscriptionPlans: r.data.returnobject.page,
            userAuth: r.data.returnobject.userAuth,
            typeOptions: r.data.returnobject.typeOptions,
            pagination: {
               ...data.pagination,
               count: r.data.returnobject.page.totalPages,
               size: r.data.returnobject.page.size,
               page: r.data.returnobject.page.number
            }
         });
         setIsLoaded(true);
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * 
    * Function that triggers the removal of the selected subscription plan.
    * 
    * @property {*} event
    * @property {string} subscriptionPlanID The id of the selected subscription plan to be removed.
    */
   function deleteSubscriptionPlan(event, subscriptionPlanID, name) {
      handleDialogState(true, subscriptionPlanID, name);
   }

   /**
    * Function that handles the delete modal open or close data.
    * 
    * @property {boolean} isOpen The state of the dialog open status.
    * @property {string} subscriptionPlanID The id of the selected subscription plan to be removed.
    */
   function handleDialogState(isOpen, subscriptionPlanID = "", name = "") {
      setDialog({
         ...dialog,
         isOpen: isOpen,
         subscriptionPlanID: subscriptionPlanID,
         name: name
      });
   }

   /**
    * Gets called to remove the selected subscription plan
    */
   function deleteSP() {
      subscriptionPlanApi.deleteById(dialog.subscriptionPlanID).then((r) => {
         handleDialogState(false);
         setAlertBox({
            ...alertBox,
            isAOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getSubscriptionPlans();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected subscription plan
    * 
    * @param {*} event
    * @param {string} id the id of the subscription plan
    */
   function activateSubscriptionPlan(event, id) {
      subscriptionPlanApi.activate(id).then((r) => {
         setAlertBox({
            ...alertBox,
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getSubscriptionPlans();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected subscription plan
    * 
    * @param {*} event
    * @param {string} id the id of the subscription plan 
    */
   function disableSubscriptionPlan(event, id) {
      subscriptionPlanApi.disable(id).then((r) => {
         setAlertBox({
            ...alertBox,
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getSubscriptionPlans();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Function that triggers the multi select input value change.
    * 
    * @param {*} event 
    */
   function onSelectSearchChange(event) {
      let { name, value } = event.target;
      setSearchValue(value);
      setSearch({
         ...search,
         [name]: value
      });
   }

   /**
    * Function that handles the snackbar open or close state.
    * 
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }


   /**
    * Function that handles the pagination information.
    * @property {int} page the page to be redirected
    */
   function handlePaginationChange(page) {
      formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
   }

   /**
    * Function that handles the pagination information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleRowsPerPageChange(size) {
      let pagination = data.pagination;
      pagination.size = size;
      pagination.page = 0;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }
   /**
    * Function that handles the sort information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleColumnSortChange(changedColumn, direction) {
      let pagination = data.pagination;
      pagination.sort = changedColumn;
      pagination.direction = direction;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }


   // ===============================================================================RENDER

   if (redirect) {
      reloadPage();
   } else if (!isLoaded) {
      return <LoadingSkeleton lines={9} />;
   } else {
      let defaultTypeSelected = "";
      if (search !== null) defaultTypeSelected = search.type;

      /**
       * datatable columns.
       */
      const table_columns = [
         {
            name: "available",
            label: t('label.isAvailable'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return iconFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     {
                        enable: (event, rowIndex) => activateSubscriptionPlan(event, rowIndex),
                        disable: (event, rowIndex) => disableSubscriptionPlan(event, rowIndex),
                        positionOfId: 1
                     });
               }
            }
         },
         {
            name: "id",
            label: t('label.id'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], `/${getLanguageFromURL()}/subscription-plan`);
               }
            }
         },
         {
            name: "name",
            label: t('label.name'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('label.name')}: ${v}`,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return value;
               }
            }
         },
         {
            name: "description",
            label: t('label.description'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex]);
               }
            }
         },
         {
            name: "type",
            label: t('label.type'),
            options: {
               filter: true,
               filterOptions: {
                  names: data.typeOptions
               },
               filterType: 'select',
               customFilterListRender: v => `${t('label.type')}: ${t(`subscriptionPlan.type.${v}`)}`,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return typeFormatter(t(`subscriptionPlan.type.${value}`), tableMeta.tableData[tableMeta.rowIndex], t('label.na'));
               }
            }
         },
         {
            name: "dateCreated",
            label: t('label.dateCreated'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateFormatter());
               }
            }
         },
         {
            name: "",
            label: "",
            options: {
               filter: false,
               sort: false,
               empty: true,
               customBodyRender: (value, tableMeta) => {
                  return actionFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     1,
                     null,
                     null,
                     null,
                     2,
                     `/${getLanguageFromURL()}/subscription-plan`,
                     data.showLabel ? t('actions.edit') : undefined,
                     (event, id, name) => deleteSubscriptionPlan(event, id, name),
                     data.showLabel ? t('actions.delete') : undefined,
                     true,
                     false,
                     true
                  )
               },
               setCellProps: () => ({ className: "click action" })
            }
         }
      ];

      return (
         <CustomGrid role="main" id="subscription-plan-list" sx={mainTag()}>

            <Grid container spacing={2} sx={{ alignItems: "center" }}>
               <Grid item xs={"auto"}>
                  <CustomRefreshButton onClick={getSubscriptionPlans} marginTop="-1%" />
               </Grid>

               <Grid item xs={11} sm={3}>
                  <CustomGrid sx={mainTagTitle()}>
                     <CustomTitle
                        title={t('subscriptionPlan.pageTitle')}
                        subtitle={t('subscriptionPlan.subtitle')}
                     />
                  </CustomGrid>
               </Grid>
            </Grid>

            <CustomGrid sx={mainAreaTable()} container={false}>
               <CustomPageableTable
                  instances={data.subscriptionPlans}
                  emptyMessage={t('table.noData') + t('actions.tableCreateNewLink') + t('subscriptionPlan.title')}
                  table_columns={table_columns}
                  addPageUrl={`/${getLanguageFromURL()}/subscription-plan/add`}
                  keyField="name"
                  defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                  pagination={data.pagination}
                  handlePaginationChange={handlePaginationChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handleColumnSortChange={handleColumnSortChange}
                  filter={true}
                  filterType={"textField"}
                  onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                     name: "",
                     type: ""
                  })}
               />
            </CustomGrid>

            <Fab color="primary"
               aria-label="add"
               sx={fabStyle()}
               onClick={() => navigate(`/${getLanguageFromURL()}/subscription-plan/add`)}
            >
               <Tooltip arrow title={t('actions.add') + " " + t('subscriptionPlan.pageTitle')} sx={fabTooltipStyle()}>
                  <AddIcon {...fabStyleIcon()} />
               </Tooltip>
            </Fab>

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }

            {dialog.isOpen &&
               <CustomDialog
                  isOpen={dialog.isOpen}
                  id="delete"
                  title={t('actions.deleteModal.title')}
                  actionLabel={t("actions.deleteModal.message")}
                  cancelLabel={t("actions.cancel")}
                  message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('subscriptionPlan.pageTitle')}: `}
                  boldMessage={<span style={{ fontWeight: "bold" }}>{dialog.name}</span>}
                  action={deleteSP}
                  handleOpen={handleDialogState}
                  actionColor="#e91e1e"
               />
            }
         </CustomGrid>
      );
   }
}

export default SubscriptionPlanListComponent;