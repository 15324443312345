import { Box, Button, Grid, Popper } from '@mui/material';
import Popover from '@mui/material/Popover';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon, EditIcon } from 'assets/mui/MuiIcons';
import CustomSelectMultilingual from 'components/Select/CustomSelectMultilingual';
import { useTranslation } from 'react-i18next';

function CheckoutOrderStatusPopover({ anchorEl, open, handleClose, handleInputChange, handleUpdate, statuses, orderStatus }) {

  const { t } = useTranslation();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const searchLabel = `${t('actions.searchFor')} ${t('order.status')}`;
  const id = open ? 'simple-popover' : undefined;
  const PopperMy = function (props) {

    const anchorEl = document.getElementById('simple-popover');
    return (<Popper {...props}
      modifiers={[
        {
          name: 'flip',
          enabled: false, // Disable flipping if necessary
        },
        {
          name: 'preventOverflow',
          enabled: false, // Disable overflow prevention if necessary
          options: {
            altAxis: true, // Adjust alternate axis behavior if necessary
            tether: false, // Disable tethering if necessary
          },
        },
      ]}
      anchorEl={anchorEl}
      style={{ width: "100%", margin: "20px", overflowY: 'auto', height: anchorEl.clientHeight, transform: "translate(0px, 0px)" }}
      placement='bottom'
      keepMounted={true}
    />)
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ padding: '20px' }}>
        <Grid container gap={1}>
          <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
            <CustomSelectMultilingual
              id="statuses"
              isMultiple={false}
              name="statuses"
              translationPath="order.actions.statusCaps"
              required={true}
              defaultValue={orderStatus}
              options={statuses}
              onChange={(event) => handleInputChange(event.target.value)}
              sx={{ overflow: "auto" }}
            />
          </Grid>

          <Grid item xs={4} md={4} sx={{ alignSelf: "center" }}>
            <Button
              onClick={handleUpdate}
              color="primary"
              startIcon={<EditIcon />}
              variant="contained"
              sx={{ borderRadius: "30px" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
}

export default CheckoutOrderStatusPopover;