import CustomIconButton from "../Button/CustomIconButton";

//  allignment
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
// font options
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
//  font position
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { formValChangeWithParentElementWithNameAndValue } from "utils/form-validation";
import { equalsIgnoreCase, isStringContainsTextFromArrayOfSubstrings } from "utils/functions";

function CustomFontStyle({
    data, setData, objectnName = "",
    fontOptions, textAlignOptions, textPositionOptions
}) {
    const { t } = useTranslation();
    const cleanObjectName = objectnName.slice(0, -1);
    const buttonStyles = {
        width: "10%",
        padding: "5px",
        minWidth: "40px"
    }
    const buttonIconStyles = {
        marginLeft: "0px",
        marginRight: "0px",
    }

    function updateFontOptionValue(newFontOption) {
        if (equalsIgnoreCase(data[cleanObjectName].fontOptions, fontOptions[3])) return equalsIgnoreCase(newFontOption, fontOptions[1]) ? fontOptions[2] : fontOptions[1]; //unselect one clicked from both selected
        else if (equalsIgnoreCase(data[cleanObjectName].fontOptions, newFontOption)) return fontOptions[0];//unselect one
        else if (equalsIgnoreCase(data[cleanObjectName].fontOptions, fontOptions[0])) return newFontOption; //select one
        else return fontOptions[3]; //select both
    }

    return (
        <>
            <Stack direction="row" spacing={1} >
                <CustomIconButton
                    keyValue="bold"
                    variant="outlined"
                    icon={<FormatBoldIcon />}
                    sx={{ ...buttonStyles }}
                    iconSx={{ ...buttonIconStyles }}
                    tooltipTitle={t("loyalty.settings.barcode.fontOptions.bold")}
                    isFocused={isStringContainsTextFromArrayOfSubstrings(data[cleanObjectName]?.fontOptions, [fontOptions[1], fontOptions[3]])}
                    onClick={() => formValChangeWithParentElementWithNameAndValue(`${objectnName}fontOptions`, updateFontOptionValue(fontOptions[1]), data, setData)}
                />
                <CustomIconButton
                    keyValue="italic"
                    variant="outlined"
                    icon={<FormatItalicIcon />}
                    sx={{ ...buttonStyles }}
                    iconSx={{ ...buttonIconStyles }}
                    tooltipTitle={t("loyalty.settings.barcode.fontOptions.italic")}
                    isFocused={isStringContainsTextFromArrayOfSubstrings(data[cleanObjectName]?.fontOptions, [fontOptions[2], fontOptions[3]])}
                    onClick={() => formValChangeWithParentElementWithNameAndValue(`${objectnName}fontOptions`, updateFontOptionValue(fontOptions[2]), data, setData)}
                />
            </Stack>
            <Stack direction="row" spacing={1} >
                <CustomIconButton
                    keyValue="align-left"
                    variant="outlined"
                    icon={<FormatAlignLeftIcon />}
                    sx={{ ...buttonStyles }}
                    iconSx={{ ...buttonIconStyles }}
                    tooltipTitle={t("loyalty.settings.barcode.textAlign.left")}
                    isFocused={equalsIgnoreCase(data[cleanObjectName]?.textAlign, textAlignOptions[0])}
                    onClick={() => formValChangeWithParentElementWithNameAndValue(`${objectnName}textAlign`, textAlignOptions[0], data, setData)}
                />
                <CustomIconButton
                    keyValue="align-center"
                    variant="outlined"
                    icon={<FormatAlignCenterIcon />}
                    sx={{ ...buttonStyles }}
                    iconSx={{ ...buttonIconStyles }}
                    tooltipTitle={t("loyalty.settings.barcode.textAlign.center")}
                    isFocused={equalsIgnoreCase(data[cleanObjectName]?.textAlign, textAlignOptions[1])}
                    onClick={() => formValChangeWithParentElementWithNameAndValue(`${objectnName}textAlign`, textAlignOptions[1], data, setData)}
                />
                <CustomIconButton
                    keyValue="align-right"
                    variant="outlined"
                    icon={<FormatAlignRightIcon />}
                    sx={{ ...buttonStyles }}
                    iconSx={{ ...buttonIconStyles }}
                    tooltipTitle={t("loyalty.settings.barcode.textAlign.right")}
                    isFocused={equalsIgnoreCase(data[cleanObjectName]?.textAlign, textAlignOptions[2])}
                    onClick={() => formValChangeWithParentElementWithNameAndValue(`${objectnName}textAlign`, textAlignOptions[2], data, setData)}
                />
            </Stack>
            <Stack direction="row" spacing={1} >
                <CustomIconButton
                    keyValue="position-top"
                    variant="outlined"
                    icon={<VerticalAlignTopIcon />}
                    sx={{ ...buttonStyles }}
                    iconSx={{ ...buttonIconStyles }}
                    tooltipTitle={t("loyalty.settings.barcode.textPosition.top")}
                    isFocused={equalsIgnoreCase(data[cleanObjectName]?.textPosition, textPositionOptions[0])}
                    onClick={() => formValChangeWithParentElementWithNameAndValue(`${objectnName}textPosition`, textPositionOptions[0], data, setData)}
                />
                <CustomIconButton
                    keyValue="position-bottom"
                    variant="outlined"
                    icon={<VerticalAlignBottomIcon />}
                    sx={{ ...buttonStyles }}
                    iconSx={{ ...buttonIconStyles }}
                    tooltipTitle={t("loyalty.settings.barcode.textPosition.bottom")}
                    isFocused={equalsIgnoreCase(data[cleanObjectName]?.textPosition, textPositionOptions[1])}
                    onClick={() => formValChangeWithParentElementWithNameAndValue(`${objectnName}textPosition`, textPositionOptions[1], data, setData)}
                />
            </Stack>
        </>
    )
}
export default CustomFontStyle;
