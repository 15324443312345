import { requestActionTypes } from "./requestActionTypes";

export const updateRequestData = (data) => ({
    type: requestActionTypes.UPDATE_REQUEST_DATA,
    payload: { data }
});

export const updateRequestDataPagination = (data) => ({
    type: requestActionTypes.UPDATE_REQUEST_DATA_PAGINATION,
    payload: { data }
});

export const updateRequestSearch = (search, refetchData = true) => ({
    type: requestActionTypes.UPDATE_REQUEST_SEARCH,
    payload: { search, refetchData }
});

export const updateRequestAll = (data, search, refetchData) => ({
    type: requestActionTypes.UPDATE_REQUEST_ALL,
    payload: { data, search, refetchData }
});

export const updateRequestRefetchData = (refetchData) => ({
    type: requestActionTypes.UPDATE_REQUEST_REFETCH_DATA,
    payload: { refetchData }
});
