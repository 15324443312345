import {
    Divider,
    Grid,
    ListItem
} from '@mui/material';
import { PaymentIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { pastOrderAddFeedbackButtonIconStyle, pastOrderAddFeedbackButtonStyle, pastOrderCartTextStyle, pastOrderDateTextStyle, pastOrderOutletTextStyle } from 'themes/defaultThemes';
import { dateTimeFormatter } from 'utils/functions';

/**
 * The CustomPDAPastOrderCardSimplified, that display each customer past order -- simplified view.
 * 
 * @param {object} order the past order details
 * @param {string} lang the lang code
 * @param {fucntion} handlePastOrderClick Callback that handles redirects past order details page.
 *
 * @returns {JSX.Element} A React component that renders a past order.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPDAPastOrderCardSimplified({ index, order, handlePastOrderClick, handlePastOrderPayClick, handlePastOrderAddFeedbackClick, showDivider }) {
    const { t } = useTranslation();

    function cartToText() {
        let text = "";
        order.cart.map((cartItem) =>
            text += `${cartItem.quantity}x ${cartItem.name}, `
        );

        return text;
    }

    return (
        <>
            <Grid item xs={9} md={9}>
                <ListItem onClick={handlePastOrderClick}>
                    <Grid container sx={{ paddingLeftt: "10%" }}>
                        <Grid item xs={12} md={12}>
                            <CustomTypography variant="body2" text={`ID: ${order?.id}`} sx={pastOrderOutletTextStyle()} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CustomTypography variant="body2" text={`${dateTimeFormatter(order?.dateCreated, "DD/MM/yyyy - HH:mm")}`} sx={pastOrderDateTextStyle()} />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
                            <CustomTypography variant="body2" text={cartToText()} sx={pastOrderCartTextStyle()} />
                        </Grid>
                    </Grid>
                </ListItem>
            </Grid>

            <Grid item xs={2} md={2}>
                {order.hasGivenFeedback &&
                    <Grid item xs={5} md={5}>
                        {/* TODO:include assicateed feedback or open it  */}
                        {/* <CustomIconButton
                        sx={pastOrderAddFeedbackButtonStyle()}
                        iconSx={pastOrderAddFeedbackButtonIconStyle()}
                        onClick={handlePastOrderAddFeedbackClick}
                        variant="outlined"
                        icon={<AddIcon sx={pastOrderAddFeedbackButtonIconStyle()} />}
                        label={t('order.addFeedback')}
                    />*/}
                    </Grid>
                }

                {!order?.paid &&
                    <Grid item xs={5} md={5}>
                        <CustomIconButton
                            sx={pastOrderAddFeedbackButtonStyle()}
                            iconSx={pastOrderAddFeedbackButtonIconStyle()}
                            onClick={handlePastOrderPayClick}
                            variant="outlined"
                            icon={<PaymentIcon sx={pastOrderAddFeedbackButtonIconStyle()} />}
                            label={t('pda.unPaidOrderButton')}
                        />
                    </Grid>
                }
            </Grid>

            {showDivider &&
                <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", margin: "10px 0" }} />
            }
        </>
    );
}

export default CustomPDAPastOrderCardSimplified;