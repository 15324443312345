import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to get an existing loyalty categories information.
 * 
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the current infromation of the loyalty categories
 */
export async function fetchAll(search, pageable) {
    let data = {};
    if (search.organizationID !== null && search.organizationID !== undefined && search.organizationID !== "") {
        data = { organizationID: search.organizationID }
    }
    if (search.outletID !== null && search.outletID !== undefined && search.outletID !== "") {
        data = { ...data, outletID: search.outletID }
    }
    if (search.name !== null && search.name !== undefined && search.name !== "") {
        data = { ...data, name: search.name }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}loyalty/category`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}loyalty/category`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to create a new loyalty category.
 * @param {*} data A JSON object which contains new loyalty category infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}loyalty/category`, data);
}

/**
 * The rest request which attempts to update an existing loyalty category.
 * @param {*} data A JSON object which contains the updated infromation of the loyalty category
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}loyalty/category`, data);
}

/**
 * The rest request to delete a specific loyalty category.
 * @param {*} id The id of the loyalty category to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}loyalty/category/${id}`);
}

const loyaltyCategoryApi = {
    fetchAll,
    create,
    update,
    deleteById
}

export default loyaltyCategoryApi;