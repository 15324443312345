export const getQrcodeEyeTabsIcon = () => {
    return [
        {
            name: "leftTopr",
            eyeColor: [
                {
                    inner: "black",
                    outer: "black"
                },
                "lightgrey",
                "lightgrey"
            ]

        },
        {
            name: "right",
            eyeColor: [
                "lightgrey",
                {
                    inner: "black",
                    outer: "black"
                },
                "lightgrey"
            ]

        },
        {
            name: "bottom",
            eyeColor: [
                "lightgrey",
                "lightgrey",
                {
                    inner: "black",
                    outer: "black"
                }
            ]

        }
    ];
}

export const getDefaultQRcodes = () => {
    return [
        {
            name: "leftOuter",
            eyeColor: [
                {
                    inner: "lightgrey",
                    outer: "black"
                },
                "lightgrey",
                "lightgrey"
            ]

        },
        {
            name: "leftInner",
            eyeColor: [
                {
                    inner: "black",
                    outer: "lightgrey"
                },
                "lightgrey",
                "lightgrey"
            ]
        },
        {
            name: "righttOuter",
            eyeColor: [
                "lightgrey",
                {
                    inner: "lightgrey",
                    outer: "black"
                },
                "lightgrey"
            ]

        },
        {
            name: "righttInner",
            eyeColor: [
                "lightgrey",
                {
                    inner: "black",
                    outer: "lightgrey"
                },
                "lightgrey"
            ]
        },
        {
            name: "bottomOuter",
            eyeColor: [
                "lightgrey",
                "lightgrey",
                {
                    inner: "lightgrey",
                    outer: "black"
                }
            ]

        },
        {
            name: "bottomtInner",
            eyeColor: [
                "lightgrey",
                "lightgrey",
                {
                    inner: "black",
                    outer: "lightgrey"
                }
            ]
        }
    ];
}

export const getDefaultFullQRcodes = () => {
    return [
        {
            name: "outer",
            eyeColor: [
                {
                    inner: "lightgrey",
                    outer: "black"
                },
                {
                    inner: "lightgrey",
                    outer: "black"
                },
                {
                    inner: "lightgrey",
                    outer: "black"
                }
            ]

        },
        {
            name: "inner",
            eyeColor: [
                {
                    inner: "black",
                    outer: "lightgrey"
                },
                {
                    inner: "black",
                    outer: "lightgrey"
                },
                {
                    inner: "black",
                    outer: "lightgrey"
                }
            ]
        }
    ];

}

export const getDefaultSocialQRcodes = () => {
    return [
        {
            name: "Facebook",
            props: {

            }
        }
    ];
}

export const getDefaultQRcode = () => {
    return {
        id: "",
        value: "test",
        ecLevel: "M",
        enableCORS: false,
        size: 100,
        quietZone: 10,
        bgColor: "#fff",
        fgColor: "#000",
        logoImage: "",
        logoWidth: 20,
        logoHeight: 20,
        logoOpacity: 1,
        removeQrCodeBehindLogo: true,
        logoPadding: 5,
        logoPaddingStyle: "circle",
        qrStyle: "squares",
        eyeRadius: 0,
        eyeColor: "#000",
        hasCustomDesign: false
    };
}

export const getDefaultQRcodeOpacityMarks = () => {
    return [
        {
            value: 0,
            label: 0
        },
        {
            value: 0.1,
            // label: 0.1
        },
        {
            value: 0.2,
            // label: 0.2
        },
        {
            value: 0.3,
            // label: 0.3
        },
        {
            value: 0.4,
            // label: 0.4
        },
        {
            value: 0.5,
            label: 0.5
        },
        {
            value: 0.6,
            // label: 0.6
        },
        {
            value: 0.7,
            // label: 0.7
        },
        {
            value: 0.8,
            // label: 0.8
        },
        {
            value: 0.9,
            // label: 0.9
        },
        {
            value: 1,
            label: 1
        }
    ];
}