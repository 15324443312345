export const getThemeColor = () => {
    return {
        "light": {
            ButtonBackgroundColor: "#54b1d2",
            deleteButtonBackgroundColor: "#ac2020",
            deleteButtonBackgroundColorHover: "#800606",
            viewButtonBackgroundColor: "#54b1d2",
            viewButtonBackgroundColorHover: "#1b4e67",
            editButtonBackgroundColor: "#e99f35",
            saveButtonBackgroundColor: "#54b1d2",
            saveButtonFontColor: "#fff",
            addButtonBackgroundColor: "#54b1d2",
            addButtonFontColor: "#fff",
            editButtonBackgroundColorHover: "#d88611",
            powerOffButtonBackgroundColor: "#ac2020",
            powerOffButtonBackgroundColorHover: "#800606",
            powerOnButtonBackgroundColor: "#2a8b46",
            powerOnButtonBackgroundColorHover: "#14602a",
            trueCcontrolButtonColor: "#2a8b46",
            falseControlButtonColor: "#c43333",
            fabBackgroundColor: "#54b1d2",
            fabBackgroundColorHover: "#126481",
            fabFontColor: "#fff",
            actions: {
                WORKING: "#6495ed",
                PENDING: "#ffa500",
                DONE: "#008000",
                CANCELED: "#ff0000"
            },
            subscriptionStatus: {
                PENDING: "#ffa500",
                ACTIVE: "#008000",
                COMPLETED: "#008000",
                EXPIRED: "#ff0000",
                CANCELED: "#ff0000",
                UPGRADED: "#af82d9",
                DOWNGRADED: "#af82d9",
                EXCEPTION: '#ff0000',
                FAILED: "#ff0000",
                REFUSED: "#ff0000",
                UNKNOWN: "#ff0000",
                UPDATED: "#58cb8c",
                RENEWED: "#58cb8c"
            },
            orderStatus: {
                PENDING: "#ffa500",
                PENDING_PAYMENT: "#ffa500",
                PICKED_UP: "#ce00ff",
                SERVED: "#008000",
                WORKING: "#58cb8c",
                DONE: "#008000",
                CANCELED: "#ff0000",
                UNAVAILABLE_ITEMS: "#ff3d00",
                EXPIRED_PAYMENT: "#ff0000",
                ERROR: "#ff0000",
                UPDATE_EQUIPMENT: "#57d4fb",
                UPDATE_TYPE: "#57d4fb",
                UPDATE_PERSONS: "#57d4fb",
                UPDATE_STATUS: "#57d4fb",
            },
            loyaltyActions: {
                CLEAR: "#grey",
                ADD: "#008000",
                REDEEM: "#ff0000"
            }
        },
        "dark": {
            ButtonBackgroundColor: "#1b4e67",
            deleteButtonBackgroundColor: "#721010",
            deleteButtonBackgroundColorHover: "#590404",
            editButtonBackgroundColor: "#c78930",
            editButtonBackgroundColorHover: "#bf7711",
            saveButtonBackgroundColor: "#186e8c",
            saveButtonFontColor: "#fff",
            viewButtonBackgroundColor: "#1b4e67",
            viewButtonBackgroundColorHover: "#10374a",
            powerOffButtonBackgroundColor: "#721010",
            powerOffButtonBackgroundColorHover: "#590404",
            powerOnButtonBackgroundColor: "#1c6531",
            powerOnButtonBackgroundColorHover: "#174625",
            trueCcontrolButtonColor: "#1c6531",
            falseControlButtonColor: "#651c1c",
            fabBackgroundColor: "#205d7b",
            fabFontColor: "#fff",
            addButtonBackgroundColor: "#186e8c",
            addButtonFontColor: "#fff",
            actions: {
                WORKING: "#284e90",
                PENDING: "#98670d",
                DONE: "#025202",
                CANCELED: "#731111"
            },
            subscriptionStatus: {
                PENDING: "#98670d",
                ACTIVE: "#025202",
                COMPLETED: "#025202",
                EXPIRED: "#731111",
                CANCELED: "#731111",
                UPGRADED: "#73479b",
                DOWNGRADED: "#73479b",
                EXCEPTION: '#731111',
                REFUSED: "#731111",
                FAILED: "#731111",
                UNKNOWN: "#731111",
                UPDATED: "#479b6d",
                RENEWED: "#479b6d"
            },
            orderStatus: {
                PENDING: "#98670d",
                PENDING: "#98670d",
                PICKED_UP: "#8904a9",
                SERVED: "#025202",
                WORKING: "#98670d",
                DONE: "#025202",
                CANCELED: "#731111",
                UNAVAILABLE_ITEMS: "#ff3d00",
                EXPIRED_PAYMENT: "#731111",
                ERROR: "#731111",
                UPDATE_EQUIPMENT: "#206b83",
                UPDATE_TYPE: "#206b83",
                UPDATE_PERSONS: "#206b83",
                UPDATE_STATUS: "#206b83",
            },
            loyaltyActions: {
                CLEAR: "#grey",
                ADD: "#008000",
                REDEEM: "#ff0000"
            }
        }
    }
}

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
