import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to get the subscriptions list from the database.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the subscriptions list
 */
export async function fetchAll(pageable, search) {
   let data = {};
   if (search.organizationID !== null && search.organizationID !== undefined && search.organizationID !== "") {
      data = { organizationID: search.organizationID }
   }
   if (search.planID !== null && search.planID !== undefined && search.planID !== "") {
      data = { ...data, planID: search.planID }
   }
   if (search.status !== null && search.status !== undefined && search.status !== "") {
      data = { ...data, status: search.status }
   }

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}subscription`, pageable !== undefined ? { ...pageable, ...data } : data));
   else return await request.get(includeParamsToUrl(`${API}subscription`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to get the subscriptions list with the provided status from the database.
 * @param {string} organizationID the organization ID
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * 
 * @returns an object with an attribute containing the subscriptions list with specific status
 */
export async function fetchAllByOrg(organizationID, pageable) {
   let config = {
      baseURL: process.env.REACT_APP_AXIOSBASE,
      params: pageable !== undefined ? pageable : {}
   }

   return await request.get(`${API}subscription/org/${organizationID}`, config);
}

/**
 * The rest request which attempts to get an existing subscription information.
 * @param {*} subscriptionId The id of the subscription to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the subscription
 */
export async function fetchOne(subscriptionId) {
   return await request.get(`${API}subscription/${subscriptionId}`);
}

/**
 * The rest request which attempts to get an existing subscription information.
 * @param {*} organizationID The id of the subscription to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the subscription
 */
export async function fetchOneDirector(organizationID) {
   let data = {};
   if (organizationID !== "" && organizationID !== null && organizationID !== undefined) {
      data = { organizationID: organizationID }
   }

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}subscription/organization`, data));
   else return await request.get(includeParamsToUrl(`${API}subscription/organization`));
}

/**
 * The rest request which attempts to cancel a recurrent payment for subscription.
 * @param {*} organizationID The id of the subscription to cancel the recurrent payment
 * 
 * @returns an object with an attribute containing the current infromation of the subscription
 */
export async function cancelRecurrentSubscriptionPayment(organizationID) {
   let data = {};
   if (organizationID !== "" && organizationID !== null && organizationID !== undefined) {
      data = { organizationID: organizationID }
   }

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}subscription/organization/cancel/recurrent`, data));
   else return await request.get(includeParamsToUrl(`${API}subscription/organization/cancel/recurrent`));
}



/**
 * The rest request which return required information to be presented on add new subscription page.
 * @returns an object with an attribute containing the requested information to be presented on the subscription page
 */
export async function fetchNew() {
   return await request.get(`${API}subscription/add`);
}

/**
 * The rest request which attempts to create a new subscription.
 * @param {*} data A JSON object which contains new subscription infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
   return await request.post(`${API}subscription`, data);
}

/**
 * The rest request which attempts to renew a subscription.
 * @param {*} data A JSON object which contains new subscription infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function renew(subscriptionId) {
   return await request.post(`${API}subscription/${subscriptionId}/renew`);
}

/**
 * The rest request which attempts to activate an existing subscription.
 * @param {*} subscriptionId The id of the subscription to be activated
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function activate(subscriptionId) {
   return await request.post(`${API}subscription/${subscriptionId}/activate`);
}

/**
 * The rest request which attempts to cancel an existing subscription.
 * @param {*} subscriptionId The id of the subscription to be cancel
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function cancel(subscriptionId) {
   return await request.post(`${API}subscription/${subscriptionId}/cancel`);
}

/**
 * The rest request which attempts to update an existing subscription.
 * @param {*} data A JSON object which contains the updated infromation of the subscription
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
   return await request.put(`${API}subscription`, data);
}

/**
 * The rest request to delete a specific subscription.
 * @param {*} subscriptionId The id of the subscription to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(subscriptionId) {
   return await request.delete(`${API}subscription/${subscriptionId}`);
}

const subscriptionApi = {
   fetchAll,
   fetchAllByOrg,
   fetchOne,
   fetchOneDirector,
   fetchNew,
   create,
   renew,
   activate,
   cancel,
   update,
   deleteById,
   cancelRecurrentSubscriptionPayment
}

export default subscriptionApi;