import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import { Box, Fab, Grid } from "@mui/material";
import { MuiColorInput } from 'mui-color-input';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import kitchenDisplayApi from "api/kitchenDisplay";
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitle from 'components/Title/CustomTitle';
import { getDefaultKitchenDisplaySettings } from 'constants/defaultObjects';
import { fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagSearchItemOrderBox, mainTagSearchItemOrderBoxLegend, mainTagSearchItemOrderContainer, mainTagTitle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue, onControlChangeWithParent } from 'utils/form-validation';
import useDocumentTitle from "utils/useDocumentTitle";
import { getLanguageFromURL } from 'utils/language';
import { reloadPage } from "utils/page";

/**
 * The KitchenDisplaySettingsComponent that allow the ADMIN to configure
 * kitchen display settings.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function KitchenDisplaySettingsComponent() {

    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();

    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });
    /**
     * @type {object}
     * @property {*} icon the legend fa icon
     * @property {object} userAuth the authenticated user infromation
     * @property {object} organization the organization basic details (id and name)
     * @property {object} outlet tha outlet basic details (id and name)
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        icon: AddIcon,
        userAuth: null,
        isAdd: outletID === undefined,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        displaySettings: getDefaultKitchenDisplaySettings()
    });

    const [search, setSearch] = useState({
        organizationID: "635e94f54960603c9bd07641",//organizationID,
        outletID: "63b31564c78b8371cf2d61ab",//outletID !== undefined ? outletID : undefined,
    });

    useEffect(() => {
        getKitchenDisplaySettings();
    }, []);

    //change document title
    useDocumentTitle(`Usee | ${t('display.kitchen.label')} | ${t('display.kitchen.settings.label')}`);

    /**
     * The rest endpoint to get the kitchen display settings.
     */
    function getKitchenDisplaySettings() {
        // organizationID, outletID
        kitchenDisplayApi.fetchOne(search).then((r) => {
            setData({
                ...data,
                userAuth: r.data.returnobject.userAuth,
                organization: r.data.returnobject.organization,
                outlet: r.data.returnobject.outlet,
                displaySettings: r.data.returnobject.displaySettings,
            });
            setIsLoaded(true);
        }).catch((e) => {
            //     // console.log(e);
        });
    }

    /**
     * Function that triggers the modification of the loyalty settings
     */
    function editKitchenDisplaySettings() {
        kitchenDisplayApi.update(data.displaySettings).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            setRedirect((r.data.code === "SUCCESS" ? true : false));
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    // ===============================================================================RENDER

    if (redirect) {
        reloadPage();
    } else if (!isLoaded) {
        return <CustomGrid role="main" id={"kitchen-display-settings"} sx={mainTag()}>
            <LoadingSkeleton lines={9} />
        </CustomGrid>
    } else {
        return (
            // Kitchen Display Settings
            <CustomGrid role="main" id={"kitchen-display-settings"} sx={mainTag()}>
                <CustomGrid sx={mainTagBreadcrumb()}>
                    <CustomBreadcrumbMultiParent
                        parents={[
                            { name: t('display.kitchen.label'), url: `/${getLanguageFromURL()}/display/kitchen` },
                        ]}
                        instanceName={t('display.kitchen.settings.label')}
                    />
                </CustomGrid>

                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitle
                        title={`${t('display.kitchen.label')} ${t('display.kitchen.settings.label')}`}
                        subtitle={""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="display-settings" onSubmit={(e) => { return false; }}>
                        {data.isAdd &&
                            <input type="hidden" />
                        }

                        {organizationID !== undefined &&
                            <input defaultValue={organizationID} name="organizationID" type="hidden" id="organizationID" />
                        }

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CustomCheckbox
                                    name="displaySettings.autoAcceptOrders"
                                    selected={data.displaySettings.autoAcceptOrders}
                                    onChange={(event) => onControlChangeWithParent(event, data, setData, "displaySettings.autoAcceptOrders")}
                                    label={t('display.kitchen.settings.autoAcceptOrders')}
                                />
                            </Grid>

                            {data.displaySettings.autoAcceptOrders &&
                                <>
                                    <Grid item xs={12} md={6}></Grid>

                                    <Grid item xs={12} md={6}>
                                        <CustomTextField type="number" step="1" min={0} name="defaultOrderPreparationTime"
                                            defaultValue={data.displaySettings.defaultOrderPreparationTime}
                                            inputAdornmentLabel={'display.kitchen.settings.defaultOrderPreparationTime'}
                                            inputAdornmentAfterLabel={'display.kitchen.settings.minutes'}
                                            required={true}
                                            sx={{ border: "none" }}
                                            // InputProps={{
                                            //     style: {width: "40px"}
                                            // }}
                                            startAdormentWidth={"inherit"}
                                            onChange={(event) => { formValChangeWithParentElementWithNameAndValue("displaySettings.defaultOrderPreparationTime", parseInt(event.target.value), data, setData) }}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} md={6}></Grid> */}

                                    {/* <Grid item xs={12} md={4}>
                                        <CustomTextField type="number" step="1" min={0} max={300} name="autoAcceptPendingTimeInSeconds"
                                            defaultValue={data.displaySettings.autoAcceptPendingTimeInSeconds}
                                            inputAdornmentLabel={'display.kitchen.settings.autoAcceptPendingTimeInSeconds'}
                                            inputAdornmentAfterLabel={'display.kitchen.settings.seconds'}
                                            required={true}
                                            sx={{ border: "none" }}
                                            startAdormentWidth={"inherit"}
                                            onChange={(event) => { formValChangeWithParentElementWithNameAndValue("displaySettings.autoAcceptPendingTimeInSeconds", parseInt(event.target.value), data, setData) }}
                                        />
                                    </Grid> */}
                                </>
                            }

                            {!data.displaySettings.autoAcceptOrders &&
                                <>
                                    <Grid item xs={12} md={6}></Grid>

                                    <Grid item xs={12} md={6}>
                                        <CustomCheckbox
                                            name="displaySettings.showPendingAndWorkingOrdersTogether"
                                            selected={data.displaySettings.showPendingAndWorkingOrdersTogether}
                                            onChange={(event) => onControlChangeWithParent(event, data, setData, "displaySettings.showPendingAndWorkingOrdersTogether")}
                                            label={t('display.kitchen.settings.showPendingAndWorkingOrdersTogether')}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} md={6}>
                                            <CustomCheckbox
                                                name="displaySettings.collapsePendingOrders"
                                                selected={data.displaySettings.collapsePendingOrders}
                                                onChange={(event) => onControlChangeWithParent(event, data, setData, "displaySettings.collapsePendingOrders")}
                                                label={t('display.kitchen.settings.collapsePendingOrders')}
                                            />
                                        </Grid>
                                    */}

                                    <Grid item xs={12} md={6}>
                                    </Grid>

                                </>
                            }


                            {/* <Grid item xs={12} md={6}>

                                <Stack sx={{ marginLeft: "20px" }}>
                                    <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showBarcode}
                                        onChange={(event) => onControlChangeWithParent(event, data, setData, "loyaltyConfiguration.cardProperties.showBarcode")} />}
                                        label={t('loyalty.settings.showBarcode')}
                                    />
                                </Stack>

                            </Grid>
                            */}

                            <Grid item xs={12} md={6}>
                                <CustomTextField type="number" step="1" min={0} name="refreshTimeInSeconds"
                                    defaultValue={data.displaySettings.refreshTimeInSeconds}
                                    inputAdornmentLabel={'display.kitchen.settings.refreshTimeInSeconds'}
                                    inputAdornmentAfterLabel={'display.kitchen.settings.seconds'}
                                    required={true}
                                    sx={{ border: "none" }}
                                    // InputProps={{
                                    //     style: {width: "40px"}
                                    // }}
                                    startAdormentWidth={"inherit"}
                                    onChange={(event) => { formValChangeWithParentElementWithNameAndValue("displaySettings.refreshTimeInSeconds", parseInt(event.target.value), data, setData) }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <CustomCheckbox
                                    name="displaySettings.showPrice"
                                    selected={data.displaySettings.showPrice}
                                    onChange={(event) => onControlChangeWithParent(event, data, setData, "displaySettings.showPrice")}
                                    label={t('display.kitchen.settings.showPrice')}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} sx={{ marginTop: "20px" }}>
                                <Box component="fieldset" sx={mainTagSearchItemOrderBox()}>
                                    <legend style={mainTagSearchItemOrderBoxLegend()}>{t('display.kitchen.settings.orderColor')}</legend>
                                    <Grid container spacing={3} sx={mainTagSearchItemOrderContainer()}>

                                        {!data.displaySettings.autoAcceptOrders &&
                                            <Grid item xs={12} xl={3} lg={4} md={6} sm={6}>
                                                <MuiColorInput
                                                    label={t('display.kitchen.settings.pendingOrderColor')}
                                                    name="data.displaySettings.pendingOrderColor"
                                                    format="hex8"
                                                    value={data.displaySettings.pendingOrderColor}
                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.pendingOrderColor", value, data, setData)}
                                                />
                                            </Grid>
                                        }

                                        <Grid item xs={12} xl={3} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.kitchen.settings.workingOrderColor')}
                                                name="data.displaySettings.workingOrderColor"
                                                format="hex8"
                                                value={data.displaySettings.workingOrderColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.workingOrderColor", value, data, setData)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} xl={3} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.kitchen.settings.doneOrderColor')}
                                                name="data.displaySettings.doneOrderColor"
                                                format="hex8"
                                                value={data.displaySettings.doneOrderColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.doneOrderColor", value, data, setData)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} xl={3} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.kitchen.settings.modifierColor')}
                                                name="data.displaySettings.modifierColor"
                                                format="hex8"
                                                value={data.displaySettings.modifierColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.modifierColor", value, data, setData)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} xl={3} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.kitchen.settings.orderCommentColor')}
                                                name="data.displaySettings.orderCommentColor"
                                                format="hex8"
                                                value={data.displaySettings.orderCommentColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.orderCommentColor", value, data, setData)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} xl={3} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.kitchen.settings.itemNoteColor')}
                                                name="data.displaySettings.itemNoteColor"
                                                format="hex8"
                                                value={data.displaySettings.itemNoteColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.itemNoteColor", value, data, setData)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} xl={3} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.kitchen.settings.allergiesColor')}
                                                name="data.displaySettings.allergiesColor"
                                                format="hex8"
                                                value={data.displaySettings.allergiesColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.allergiesColor", value, data, setData)}
                                            />
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        <CustomIconButton variant="outlined" keyValue="save" onClick={editKitchenDisplaySettings} label={t('actions.save')} sx={formSaveButton()} />
                    </form>
                    <Fab color="primary"
                        aria-label="save"
                        sx={fabStyle()}
                        onClick={editKitchenDisplaySettings}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>


                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default KitchenDisplaySettingsComponent;