import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab';
import { Divider, Grid } from '@mui/material';
import SubscriptionHistoryInstanceOutletCharge from 'components/Grid/subscription/SubscriptionHistoryInstanceOutletCharge';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDateTimeFormatter } from 'constants/calendar';
import { useTranslation } from 'react-i18next';
import { subscriptionDetailsHistoryDateTextStyle, subscriptionHistoryCardStyle, subscriptionHistoryDetailsLabelStyle, subscriptionHistoryDetailsTextPriceStyle, subscriptionHistoryDetailsTextStyle, subscriptionHistoryStatusLabelStyle, subscriptionHistoryStatusLabelStyleDot } from 'themes/defaultThemes';
import { clearBeforePresent, dateTimeFormatter, findCurrency } from 'utils/functions';

/**
 * `CustomSubscriptionTimeline` Component
 * 
 * Renders a visual timeline representing the history of a subscription.
 * 
 * This component uses Material-UI's `Timeline` components to display each significant
 * event in the lifecycle of a subscription, such as changes in subscription plans,
 * charges, and overall subscription status.
 * 
 * Props:
 * - `timelineDetails`: Array of objects representing the timeline events. Each object
 *    should include details like status, planID, dateTime, and other relevant information.
 * - `subscriptionPlans`: Array of available subscription plans. Used to match plan IDs
 *    from `timelineDetails` to their corresponding names.
 * - `outletBranches`: Array or object containing information about outlet branches, 
 *    used for rendering specific details about outlet charges.
 * - `sx`: Custom styles to apply to the timeline component.
 * 
 * Each event in the timeline is represented by a `TimelineItem` which includes the
 * status of the event, details about subscription plan changes, outlet charges, and
 * other relevant data. Internationalization is handled via `useTranslation` for text display.
 * 
 * Example of `timelineDetails` object structure:
 * ```
 * [
 *   {
 *     status: "Active",
 *     planID: "plan_1",
 *     details: {
 *       outletCharges: [{...}],
 *       organizationCharges: 100,
 *       totalAmount: 200,
 *       currency: "USD",
 *       dateTime: "2021-01-01T00:00:00Z"
 *     },
 *     ...
 *   },
 *   ...
 * ]
 * ```
 * 
 * @param {Object} props Component props
 * @returns {React.Component} CustomSubscriptionTimeline component
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSubscriptionTimeline({ timelineDetails, subscriptionPlans, outletBranches, sx }) {
    const { t } = useTranslation();

    return (
        <Timeline
            sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                    marginLeft: "20px"
                },
            }}>
            {timelineDetails?.reverse()?.map((timelineDetail) =>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot sx={subscriptionHistoryStatusLabelStyleDot(timelineDetail?.status)}>
                            <CustomTypography variant="body2" text={t(`subscription.status.${timelineDetail?.status}`)} sx={subscriptionHistoryStatusLabelStyle(timelineDetail?.status)} />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={subscriptionHistoryCardStyle()}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} md={5}>
                                <CustomTypography variant="body2" text={t('subscriptionPlan.label')} sx={subscriptionHistoryDetailsLabelStyle()} />
                            </Grid>
                            <Grid item xs={6} md={7}>
                                <CustomTypography variant="body2" text={clearBeforePresent(subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === timelineDetail?.planID)?.name)} sx={subscriptionHistoryDetailsTextStyle()} />
                            </Grid>
                        </Grid>

                        {timelineDetail?.details?.outletCharges?.map((outletCharge) =>
                            <SubscriptionHistoryInstanceOutletCharge outletCharge={outletCharge} currency={findCurrency(timelineDetail?.details?.currency)} outletBranches={outletBranches} />
                        )}

                        {timelineDetail?.details?.organizationCharges &&
                            <>
                                <Grid container spacing={2} >
                                    <Grid item xs={7} md={7}>
                                        <CustomTypography variant="body2" text={t('subscription.organization.history.organizationCharges')} sx={subscriptionHistoryDetailsLabelStyle()} />
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <CustomTypography variant="body2" text={`${timelineDetail.details.organizationCharges} ${findCurrency(timelineDetail?.details?.currency)}`} sx={subscriptionHistoryDetailsTextStyle()} />
                                    </Grid>
                                </Grid>
                            </>
                        }

                        {timelineDetail?.details?.totalAmount &&
                            <>
                                <Grid container spacing={2} >
                                    <Grid item xs={7} md={7}>
                                        <CustomTypography variant="body2" text={t('subscription.organization.history.totalAmount')} sx={subscriptionHistoryDetailsLabelStyle()} />
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <CustomTypography variant="body2" text={`${timelineDetail.details.totalAmount} ${findCurrency(timelineDetail?.details?.currency)}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                                    </Grid>
                                </Grid>
                            </>
                        }

                        <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", margin: "5px 0px" }} />
                        <CustomTypography variant="body2" text={dateTimeFormatter(timelineDetail?.dateTime, getDateTimeFormatter())} sx={subscriptionDetailsHistoryDateTextStyle()} />
                    </TimelineContent>
                </TimelineItem>
            )
            }
        </Timeline>
    );
}

export default CustomSubscriptionTimeline;