import React from 'react';
import { getLangOptions } from 'constants/langOptions';
import { changeLanguage } from 'utils/language';
import { Grid } from '@mui/material';

/**
 * The header language selector component.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function LanguageSelector({ classVal = "auth" }) {

    /**
     * Handles the language change.
     * 
     * @param {*} event 
     * @param {String} path the path to redirect
     */
    function changeLanguageHandler(event, lang, path) {
        changeLanguage(lang);
        window.location.href = "/" + lang + path;
    }

    function getPath() {
        let pageUrl = window.location.pathname;
        let pageLang = pageUrl;
        if (pageUrl.includes("/el") || pageUrl.includes("/en")) {
            pageLang = pageLang.split("/")[1];
        }
        return pageUrl.split(pageLang)[1];
    }

    /**
     * Gets called to render the document html
     *
     * @return {ReactElement} markup
     * @author [Gina Chatzimarkaki]
     */


    return (
        <Grid container gap={1} className={`${classVal} lang-list`}>
            {getLangOptions().map((lang) => (
                <Grid item key={lang} className={`${classVal} lang-list-item`}>
                    <img src={require("assets/images/flags/" + lang + ".png")} alt={"flag " + lang.toUpperCase()} width="32" onClick={(event) => changeLanguageHandler(event, lang, getPath())} />
                    {/* {lang.toUpperCase()} */}
                </Grid>
            ))}
        </Grid>
    )
}

export default LanguageSelector;