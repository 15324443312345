import { TabContext, TabPanel } from "@mui/lab";
import { Box, CardMedia, Divider, Grid, InputLabel, Stack, Typography } from "@mui/material";
import customerApi from "api/customer";
import { AttachMoneyIcon, InsertInvitationIcon, LoyaltyIcon, SendIcon, ShoppingCartIcon } from "assets/mui/MuiIcons";
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomIconButton from "components/Button/CustomIconButton";
import SubmitAnimatedButton from "components/Button/SubmitAnimatedButton";
import CustomCustomerAddCouponCard from "components/Card/customer/CustomCustomerAddCouponCard";
import CustomCustomerCouponCard from "components/Card/customer/CustomCustomerCouponCard";
import CustomCustomerLoyaltyCard from "components/Card/customer/CustomCustomerLoyaltyCard";
import CustomCustomerNote from "components/Card/customer/CustomCustomerNote";
import CustomPastOrderCard from "components/Card/customer/CustomPastOrderCard";
import CustomLoyaltyCard from "components/Card/loyalty/CustomLoyaltyCard";
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomTabs from "components/Tab/CustomTabs";
import CustomTextArea from "components/Text/CustomTextArea";
import CustomTextField from "components/Text/CustomTextField";
import CustomTitleBoldSubtitle from 'components/Title/CustomTitleBoldSubtitle';
import CustomTypography from "components/Typography/CustomTypography";
import { getDateTimeFormatter, getDateTimeShortFormatter } from "constants/calendar";
import { getItemLoyaltyPoints } from "constants/fieldProperties";
import { CUSTOMER_PROFILE_PHOTO } from "constants/storage";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { customerDetailAssetStyle, customerDetailsGridStyle, customerDetailsHeaderStyleLabel, customerDetailsTextStyle, customerDetailsTextStyleLabel, customerNoteSubmitButtonIconStyle, customerNoteSubmitButtonStyle, customerNoteTextareaBoxStyle, customerNotesTextAreaStyle, customerShortDetailsTextStyle, customerShortDetailsTextStyleLabel, dividerStyle, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle } from "themes/defaultThemes";
import { formValChangeWithParentElement } from "utils/form-validation";
import { dateTimeFormatter, findCurrency, validateInputNumber } from "utils/functions";
import { getLanguageFromURL } from 'utils/language';
import { reloadPage } from "utils/page";
import useDocumentTitle from "utils/useDocumentTitle";
import WebFont from "webfontloader";

/**
 * The CustomerEmployeeViewComponent, that allow the customer to view the customers list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomerEmployeeViewComponent() {

    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { id } = useParams();
    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/customer`;

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('customer.pageTitle')}`);

    /**
     * @type {object}
     * 
    * @property {object} userAuth the authenticated customer infromation
    * @property {object} customer the customer details 
    * @property {object} organization tha organization basic details (id and name)
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        customer: {},
        organization: null,
        outlet: null,
        outletCurrency: "",
        requestTextFieldLimit: 100
    });
    const [newNote, setNewNote] = useState("");
    const [pointsToAdd, setPointsToAdd] = useState(0);

    // for search
    const [search, setSearch] = useState({
        organizationID: organizationID !== undefined ? organizationID : undefined,
        outletID: outletID !== undefined ? outletID : undefined,
    });


    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });


    const [tabValue, setTabValue] = useState("summary");

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Lumanosimo']
            }
        });
        getCustomerDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the customer details.
     */
    function getCustomerDetails() {
        customerApi.fetchOneView(search.organizationID, search.outletID, id).then((r) => {
            setData({
                ...data,
                customer: r.data.returnobject.customer,
                userAuth: r.data.returnobject.userAuth,
                organization: r.data.returnobject.organization,
                outlet: r.data.returnobject.outlet,
                outletCurrency: r.data.returnobject.outletCurrency
            });
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }


    /**
     * The rest endpoint to submit new note to customer.
     */
    function submitNewNote() {
        customerApi.submitNewNote(id, { note: newNote }).then((r) => {
            let newNotes = data.customer.notes;
            console.log(r.data.returnobject)
            newNote.push(r.data.returnobject)
            formValChangeWithParentElement("customer.notes", newNotes, data, setData)
            setNewNote("");
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * The rest endpoint to points to customer manually.
     */
    function addPoints() {
        customerApi.addPoints(id, { points: pointsToAdd }).then((r) => {
            if (r.data.code === "SUCCESS")
                setPointsToAdd(0);
            getCustomerDetails();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Callback to open dialog for coupon creation for customer
     */
    function addCouponToCustomer() {

    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }


    // ===============================================================================RENDER

    if (redirect) {
        reloadPage();
    } else if (!isLoaded) {
        return <LoadingSkeleton lines={9} />;
    } else {

        return (
            <CustomGrid role="main" id="customer-list" sx={mainTag()}>
                {outletID !== undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                                { name: t('customer.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/customer` }
                            ]}
                            instanceName={data.customer?.username}
                        />
                    </CustomGrid>
                }

                {outletID === undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isAdd={false}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('customer.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/customer` }
                            ]}
                            instanceName={data.customer?.username}
                        />
                    </CustomGrid>
                }

                {outletID === undefined && organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={false}
                            parents={[
                                { name: t('customer.pageTitle'), url: `/${getLanguageFromURL()}/customer` }
                            ]}
                            instanceName={data.customer?.username}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitleBoldSubtitle
                        title={`${t('customer.title')} | ${t('actions.edit')}`}
                        subtitleBeforeText={t('customer.title')}
                        subtitleboldText={data?.customer?.customer?.username}
                        subtitleAfterText={" profile page"}
                    />
                </CustomGrid>

                {/* Short Setails */}
                <CustomGrid sx={mainArea()} container={false}>
                    <Grid container spacing={2} gap={3}>
                        <Grid item sx={{ display: "flex" }}>
                            <Grid container gap={1}>
                                <Grid item>
                                    <InsertInvitationIcon color="black" sx={{ fontSize: "15px", color: "grey" }} />
                                </Grid>
                                <Grid item>
                                    <CustomTypography variant="body2" text={t('customer.view.since')} sx={customerShortDetailsTextStyleLabel} />
                                </Grid>
                                <Grid item>
                                    <CustomTypography variant="body2" text={dateTimeFormatter(data.customer?.dateCreated, getDateTimeShortFormatter())} sx={customerShortDetailsTextStyle} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ display: "flex" }}>
                            <Grid container gap={1}>
                                <Grid item>
                                    <ShoppingCartIcon color="black" sx={{ fontSize: "15px", color: "grey" }} />
                                </Grid>
                                <Grid item>
                                    <CustomTypography variant="body2" text={t('customer.view.orders')} sx={customerShortDetailsTextStyleLabel} />
                                </Grid>
                                <Grid item>
                                    <CustomTypography variant="body2" text={data.customer?.order?.ordersNumber} sx={customerShortDetailsTextStyle} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ display: "flex" }}>
                            <Grid container gap={1}>
                                <Grid item>
                                    <AttachMoneyIcon color="black" sx={{ fontSize: "15px", color: "grey" }} />
                                </Grid>
                                <Grid item>
                                    <CustomTypography variant="body2" text={t('customer.view.spent')} sx={customerShortDetailsTextStyleLabel} />
                                </Grid>
                                <Grid item>
                                    <CustomTypography variant="body2" text={`${findCurrency(data?.outletCurrency)} ${parseFloat(data?.customer?.order?.orderTotalAmmount).toFixed(2)}`} sx={customerShortDetailsTextStyle} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ display: "flex" }}>
                            <Grid container gap={1}>
                                <Grid item>
                                    <LoyaltyIcon color="black" sx={{ fontSize: "15px", color: "grey" }} />
                                </Grid>
                                <Grid item>
                                    <CustomTypography variant="body2" text={t('customer.view.currentPoints')} sx={customerShortDetailsTextStyleLabel} />
                                </Grid>
                                <Grid item>
                                    <CustomTypography variant="body2" text={`${data?.customer?.points} ${t('itemOrModifier.points')}`} sx={customerShortDetailsTextStyle} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <TabContext value={tabValue}>
                        <CustomTabs
                            initValue={tabValue}
                            instances={[
                                { name: t('customer.view.tabs.summary'), value: "summary" },
                                { name: t('customer.view.tabs.orders.label'), value: "orders" },
                                { name: t('customer.view.tabs.coupons.label'), value: "coupons" },
                                { name: t('customer.view.tabs.activity.label'), value: "activity" }
                            ]}
                            setValue={setTabValue}
                            sx={{ marginBottom: "40px", marginTop: "20px" }}
                        />

                        <TabPanel value={"summary"} sx={{ paddingTop: "0px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} sx={{ paddingRight: "20px" }} >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} sx={{ ...customerDetailsGridStyle(), marginBottom: "20px" }}>
                                            <CustomTypography variant="body2" text={t('customer.view.contactDetails.label')} sx={customerDetailsHeaderStyleLabel} />
                                            <Divider light flexItem sx={dividerStyle()} />

                                            {data.customer?.customer?.profileAsset?.imageSrc &&
                                                <CardMedia
                                                    sx={customerDetailAssetStyle}
                                                    image={CUSTOMER_PROFILE_PHOTO(data.customer?.customer?.id, data.customer?.customer?.profileAsset?.imageSrc)}
                                                    title={"Profile image"}
                                                />
                                            }

                                            <CustomTypography variant="body2" text={t('customer.view.contactDetails.username')} sx={customerDetailsTextStyleLabel} />
                                            <CustomTypography variant="body2" text={data.customer?.customer?.username} sx={customerDetailsTextStyle} />
                                            <CustomTypography variant="body2" text={t('customer.view.contactDetails.firstName')} sx={customerDetailsTextStyleLabel} />
                                            <CustomTypography variant="body2" text={data.customer?.customer?.firstName} sx={customerDetailsTextStyle} />
                                            <CustomTypography variant="body2" text={t('customer.view.contactDetails.lastName')} sx={customerDetailsTextStyleLabel} />
                                            <CustomTypography variant="body2" text={data.customer?.customer?.lastName} sx={customerDetailsTextStyle} />
                                            <CustomTypography variant="body2" text={t('customer.view.contactDetails.email')} sx={customerDetailsTextStyleLabel} />
                                            <CustomTypography variant="body2" text={data.customer?.customer?.email} sx={customerDetailsTextStyle} />
                                            <CustomTypography variant="body2" text={t('customer.view.contactDetails.phone')} sx={customerDetailsTextStyleLabel} />
                                            <CustomTypography variant="body2" text={data.customer?.customer?.phone} sx={customerDetailsTextStyle} />
                                            {/* <CustomTypography variant="body2" text={t('customer.view.contactDetails.address')} sx={customerDetailsTextStyleLabel} /> */}
                                            {/* <CustomTypography variant="body2" text={data.customer?.customer?.address} sx={customerDetailsTextStyle} /> */}
                                        </Grid>

                                        <Grid item xs={12} md={12} sx={customerDetailsGridStyle}>
                                            <CustomTypography variant="body2" text={t('customer.view.customProperties.label')} sx={customerDetailsHeaderStyleLabel} />
                                            <Divider light flexItem sx={dividerStyle()} />
                                            <CustomTypography variant="body2" text={t('customer.view.customProperties.storeCredit')} sx={customerDetailsTextStyleLabel} />
                                            <CustomTypography variant="body2" text={`${findCurrency(data.outletCurrency)} ${data.customer?.customer?.customProperties?.storeCredit > 0 ? data.customer?.customer?.customProperties?.storeCredit : 0}`} sx={customerDetailsTextStyle} />
                                            <CustomTypography variant="body2" text={t('customer.view.customProperties.status.label')} sx={customerDetailsTextStyleLabel} />
                                            <CustomTypography variant="body2" text={t(`customer.view.customProperties.status.${data.customer?.customer?.enabled}`)} sx={customerDetailsTextStyle} />
                                            <CustomTypography variant="body2" text={t('customer.view.customProperties.signup')} sx={customerDetailsTextStyleLabel} />
                                            <CustomTypography variant="body2" text={dateTimeFormatter(data.customer?.customer?.dateCreated, getDateTimeFormatter())} sx={customerDetailsTextStyle} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={8} sx={{ display: "flex" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container spacing={2}>
                                                <CustomTypography variant="body2" text={`${t('customer.view.tabs.orders.latestOrders.label')} (${data.customer?.order.ordersNumber}) - ${findCurrency(data?.outletCurrency)} ${parseFloat(data?.customer?.order?.orderTotalAmmount).toFixed(2)}`} sx={customerDetailsHeaderStyleLabel} />

                                                {data.customer?.orders?.map((order) => (
                                                    <CustomPastOrderCard order={order} outletCurrency={data?.outletCurrency} />
                                                ))}
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <CustomTypography variant="body2" text={t('customer.view.notes.label')} sx={customerDetailsHeaderStyleLabel} />

                                            <CustomTextArea
                                                id="newNote"
                                                placeholder={t('customer.view.notes.info')}
                                                variant="outlined"
                                                value={newNote}
                                                inputProps={{ maxLength: 100 }}
                                                maxLength={100}
                                                onChange={(event) => {
                                                    let newValue = event.target.value.slice(0, 100);
                                                    setNewNote(newValue);
                                                }}
                                                sx={customerNotesTextAreaStyle()}
                                                endDecorator={
                                                    <Box sx={customerNoteTextareaBoxStyle()}>
                                                        <Divider flexItem={true} />
                                                        <Typography level="span" sx={{ ml: 'auto', color: newNote.length === 100 ? "red" : "initial" }}>
                                                            {`${newNote.length} ${newNote.length === 1 ? t('customer.view.notes.itemCharLimit') : t('customer.view.notes.itemCharsLimit')} /${data.requestTextFieldLimit}`}
                                                        </Typography>

                                                        <CustomIconButton
                                                            sx={customerNoteSubmitButtonStyle()}
                                                            backgroundColor="transparent"
                                                            icon={<SendIcon sx={customerNoteSubmitButtonIconStyle()} />}
                                                            onClick={submitNewNote}
                                                            label={t('customer.view.notes.btn')}
                                                        />
                                                    </Box>
                                                }
                                            />

                                            {data.customer?.notes?.map((note) => (
                                                <CustomCustomerNote note={note} />
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={"orders"} sx={{ paddingTop: "0px" }}>
                            <CustomTypography variant="body2" text={`${t('customer.view.tabs.orders.latestOrders.label')} (${data.customer?.order.ordersNumber}) - ${findCurrency(data?.outletCurrency)} ${parseFloat(data?.customer?.order?.orderTotalAmmount).toFixed(2)}`} sx={customerDetailsHeaderStyleLabel} />

                            <Grid container spacing={2}>
                                {data.customer?.orders?.map((order) => (
                                    <CustomPastOrderCard order={order} outlet={{ currency: data?.outletCurrency }} />
                                ))}
                            </Grid>
                        </TabPanel>

                        <TabPanel value={"coupons"} sx={{ paddingTop: "0px" }}>
                            <Stack direction="row" sx={{ padding: "10px", width: "calc(100% - 40px)",  alignItems: "center" }}>
                                <Grid container spacing={2} sx={customerDetailsGridStyle()}>
                                    {data.customer?.coupons?.map((coupon) => {
                                        return (
                                            <CustomCustomerCouponCard coupon={coupon} organization={data.organization} />
                                        );
                                    })}
                                    <CustomCustomerAddCouponCard
                                        organization={data.organization}
                                        refetchData={()=> getCustomerDetails()}
                                        customerID={data.customer?.customer.id}
                                    />
                                </Grid>
                            </Stack>
                        </TabPanel>

                        <TabPanel value={"activity"} sx={{ paddingTop: "0px" }}>
                            <Grid container spacing={2} sx={{ placeContent: "center" }}>
                                <Grid item>
                                    <CustomLoyaltyCard
                                        cardProperties={data?.customer?.loyalty?.loyaltyConfiguration?.cardProperties}
                                        category={data?.customer?.loyalty}
                                        logoImgSrc={data?.organization?.photo}
                                        organizationID={data?.organization?.id}
                                        enableActions={false}
                                        userFullName={`${data?.customer?.customer?.firstName} ${data?.customer?.customer?.lastName}`}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    {data.customer?.actionsHistory?.length > 0 && data.customer?.actionsHistory?.map((loyaltyInstance) => {
                                        return (
                                            <CustomCustomerLoyaltyCard loyalty={loyaltyInstance} />
                                        );
                                    })}
                                </Grid>

                                {/* FORM */}
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2} sx={{ marginTop: "40px" }}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel required={false} htmlFor="points">{t('customer.view.tabs.activity.manualAddPointsLabel')}</InputLabel>
                                            <CustomTextField
                                                type="number"
                                                step={getItemLoyaltyPoints().step}
                                                min={getItemLoyaltyPoints().min}
                                                max={getItemLoyaltyPoints().max}
                                                name="item.loyaltyPoints"
                                                value={pointsToAdd}
                                                inputAdornmentAfterLabel={'itemOrModifier.points'}
                                                onChange={(event) => {
                                                    let value = validateInputNumber(event.target.value, getItemLoyaltyPoints().length, getItemLoyaltyPoints().min, getItemLoyaltyPoints().max, getItemLoyaltyPoints().isFloat);
                                                    if (value === undefined) value = pointsToAdd;
                                                    setPointsToAdd(parseInt(value));
                                                }}
                                            />
                                        </Grid>
                                        <SubmitAnimatedButton handleClick={addPoints} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default CustomerEmployeeViewComponent;