import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  MenuItem,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { CloseOutlined } from "@mui/icons-material";
import CustomFlagTextField from "components/Text/CustomFlagTextField";
import en from "assets/images/flags/en.png";
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";
import { useTranslation } from "react-i18next";
import CustomFlagTextArea from "components/Text/CustomFlagTextArea";
import { v4 as uuidv4 } from "uuid";

import { getTranslationDefaultObject } from "constants/defaultObjects";

const translations = getTranslationDefaultObject();

const fieldTypesOptions = [
  { label: "Boolean", value: "Boolean" },
  { label: "Multiple Choices", value: "multipleChoice" },
  { label: "Free Text", value: "FreeText" },
  { label: "Rating 1 To 5", value: "Rating_1_To_5" },
];

const booleanQuestionStyleOptions = [
  { label: "Like Dislike", value: "Like_Dislike" },
  { label: "Yes No", value: "Yes_No" },
  { label: "Agree Disagree", value: "Agree_Disagree" },
  { label: "True False", value: "True_False" },
];
const ratingStyleOptions = [
  { label: "Stars", value: "Stars" },
  { label: "Hearts", value: "Hearts" },
  { label: "Faces", value: "Faces" },
];

const removeFields = (data, type) => {
  let allowedFields = [
    "id",
    "active",
    "required",
    "question",
    "description",
    "type",
  ];

  if (type === "Boolean") {
    allowedFields = [...allowedFields, "booleanQuestionStyle"];
  } else if (type === "multipleChoice") {
    allowedFields = [
      ...allowedFields,
      "choices",
      "allowMultipleChoices",
      "minChoices",
      "maxChoices",
    ];
  } else if (type === "FreeText") {
    allowedFields = [...allowedFields, "promptText", "maxAllowedCharacters"];
  } else if (type === "Rating_1_To_5") {
    allowedFields = [...allowedFields, "ratingQuestionStyle"];
  }

  let returnData = {};
  Object.keys(data).map((key) => {
    if (allowedFields.includes(key)) {
      returnData[key] = data[key];
    }
  });
  return returnData;
};
function AddUpdateQuestionDialog({
  open,
  onClose,
  onSubmit,
  editData,
}) {
  const { t } = useTranslation();

  const initialChoice = { optionKey: 1, optionValue: translations };
  const intitialData = {
    active: true,
    required: false,
    question: translations,
    description: translations,
    type: "",
    ratingQuestionStyle: "", //for Rating_1_To_5,
    choices: [initialChoice], //multipleChoice,
    allowMultipleChoices: false, //multipleChoice,
    minChoices: 1, //multipleChoice,
    maxChoices: 1, //multipleChoice,
    promptText: "", //FreeText
    maxAllowedCharacters: "", ///FreeText
  };
  const [data, setData] = useState(intitialData);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState();
  const [dialog, setDialog] = useState({
    isOpen: false,
    message: t("label.configureTranslations"),
    id: "",
    field: "",
    translations: {},
  });

  useEffect(() => {
    if (open && editData && Object.keys(editData).length > 0) {
      setData(editData);
      setEdit(true);
    } else {
      setData(intitialData);
      setEdit(false);
    }
  }, [editData, open]);

  function handleDialogState(isOpen, field = "") {
    setDialog({
      ...dialog,
      isOpen: isOpen,
      field: field,
      translations: data[field],
    });
  }

  function configureTranslations(event, field) {
    handleDialogState(true, field);
  }
  function configureItemTranslations(translations, field) {
    if (field.includes("choices")) {
      //we set index on field for each choice on their input
      let ind = +field.split("-")[1];

      setData((d) => {
        return {
          ...d,
          choices: d.choices.map((c, i) => {
            if (i === ind) {
              c.optionValue = translations;
            }
            return c;
          }),
        };
      });
    } else {
      setData({
        ...data,
        [field]: translations,
      });
    }
    setDialog({
      ...dialog,
      isOpen: false,
    });
  }

  const validate = () => {
    if (data.question?.en === "" || data.question?.en?.length < 4) {
      return {
        success: false,
        message: "Question length cannot be less than 3 characters",
      };
    }
    if (data.type === "") {
      return {
        success: false,
        message: "Type Cannot be empty",
      };
    }
    if (data.type === "multipleChoice") {
      if (data.choices.some((x) => x.optionValue.en === "")) {
        return {
          success: false,
          message: "Multiple Choice value cannot be empty",
        };
      }
      if (data.allowMultipleChoices) {
        if (data.minChoices === "" || data.maxChoices === "") {
          return {
            success: false,
            message:
              "Min and Max choice cannot be empty when multiple choices are allowed",
          };
        }
      }
    } else if (data.type === "FreeText") {
      // if (data.promptText === "") {
      //   return {
      //     success: false,
      //     message: "Prompt Text value cannot be empty",
      //   };
      // }
    } else if (data.type === "Rating_1_To_5") {
      if (data.ratingQuestionStyle === "") {
        return {
          success: false,
          message: "Rating Style value cannot be empty",
        };
      }
    } else if (data.type === "Boolean") {
      if (data.booleanQuestionStyle === "") {
        return {
          success: false,
          message: "Boolean Question Style value cannot be empty",
        };
      }
    }
    return {
      success: true,
      message: "",
    };
  };
  const submitHandler = () => {
    ///call api and do error handling
    let vali = validate();
    if (vali.success === false) {
      setError(vali.message);
      return;
    } else {
      setError();
    }

    let filteredData = removeFields(data, data.type);
    let apiResponse = {
      id: uuidv4(),
      ...filteredData,

      choices: filteredData.choices
        ? filteredData.choices.map((c, ci) => {
            return {
              ...c,
              optionKey: ci,
            };
          })
        : undefined,
    };
    onSubmit(apiResponse);
    setData(intitialData);
  };

  const editHandler = () => {
    ///call api and do error handling
    let vali = validate();
    if (vali.success === false) {
      setError(vali.message);
      return;
    } else {
      setError();
    }
    let filteredData = removeFields(data, data.type);

    onSubmit({
      ...filteredData,
      choices: filteredData.choices
        ? filteredData.choices.map((c, ci) => {
            return {
              ...c,
              optionKey: ci,
            };
          })
        : undefined,
    });
    setData(intitialData);
  };
  const cancelHandler = () => {
    onClose();
    setError();
    setData(intitialData);
  };

  const inputSx = {
    mt: "4px",
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.13)",
    },
  };
  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        {dialog.isOpen && (
          <CustomTranslationsDialog
            isOpen={dialog.isOpen}
            id='translations'
            title={t("actions.translationModal.title")}
            action={configureItemTranslations}
            handleOpen={handleDialogState}
            translations={dialog.translations}
            field={dialog.field}
          />
        )}

        <div style={{ position: "absolute", top: 5, right: 5 }}>
          <IconButton sx={{ p: 0 }} onClick={cancelHandler}>
            <CloseOutlined fontSize='small' sx={{ fill: "#000" }} />
          </IconButton>
        </div>
        <Typography
          variant='h5'
          align='center'
          sx={{ color: "#34353A", fontWeight: 700 }}
        >
          {edit
            ? t("feedback.addUpdateQuestion.update")
            : t("feedback.addUpdateQuestion.add")}
        </Typography>

        <Grid container direction='column'>
          {/* active, require */}
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              width: "100%",
              mt: "20px",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={data?.active}
                  onChange={(e) => {
                    setData((d) => {
                      return {
                        ...d,
                        active: e.target.checked,
                      };
                    });
                  }}
                />
              }
              label={t("feedback.addUpdateQuestion.active")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data?.required}
                  onChange={(e) => {
                    setData((d) => {
                      return {
                        ...d,
                        required: e.target.checked,
                      };
                    });
                  }}
                />
              }
              label={t("feedback.addUpdateQuestion.required")}
            />
          </Grid>
          {/* addUpdateQuestion */}
          <Grid item sx={{ width: "100%", mt: "20px" }}>
            <Typography variant='body2'>
              {t("feedback.addUpdateQuestion.question")}
            </Typography>
            <CustomFlagTextArea
              country='en'
              flag={en}
              placeholder=''
              name='data.question.en'
              id='question'
              className='form-control'
              defaultValue={
                data !== null && data.question !== null ? data.question.en : ""
              }
              onTranslationSelect={configureTranslations}
              field='question'
              value={data.question.en}
              onChange={(e) =>
                setData((d) => {
                  return {
                    ...d,
                    question: {
                      ...d.question,
                      en: e.target.value,
                    },
                  };
                })
              }
              sx={{ mt: "4px" }}
              width='99.5%'
              //helperText={isError.name}
              //error={isError.name.length > 0}
            />
          </Grid>
          {/* description */}
          <Grid item sx={{ width: "100%", mt: "20px" }}>
            <Typography variant='body2'>
              {t("feedback.addUpdateQuestion.description")}
            </Typography>

            <CustomFlagTextArea
              country='en'
              flag={en}
              placeholder=''
              name='data.description.en'
              id='description'
              value={data.description.en}
              onChange={(e) =>
                setData((d) => {
                  return {
                    ...d,
                    description: {
                      ...d.name,
                      en: e.target.value,
                    },
                  };
                })
              }
              className='form-control'
              defaultValue={
                data.description !== null ? data.description.en : ""
              }
              onTranslationSelect={configureTranslations}
              field='description'
              width='99.5%'
              sx={{ mt: "4px" }}
            />
          </Grid>
          {/* type */}
          <Grid item sx={{ width: "100%", mt: "20px" }}>
            <Typography variant='body2'>
              {t("feedback.addUpdateQuestion.type")}
            </Typography>
            <TextField
              select
              fullWidth
              variant='outlined'
              sx={inputSx}
              value={data.type}
              onChange={(e) =>
                setData((d) => {
                  return {
                    ...d,
                    type: e.target.value,
                  };
                })
              }
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              {fieldTypesOptions.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item.value}
                  sx={{ fontSize: "12px", fontWeight: 500 }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {data.type === "multipleChoice" && (
            <Grid item sx={{ width: "100%", mt: "25px" }}>
              <Typography variant='body2'>
                {t("feedback.addUpdateQuestion.choices")}
              </Typography>
              {data.choices?.map((item, i) => (
                <Grid container alignItems='center' gap={1} key={i}>
                  <Grid item sx={{ flex: 1 }}>
                    <CustomFlagTextField
                      country='en'
                      flag={en}
                      placeholder={`Choice ${i + 1}`}
                      name='item.optionValue.en'
                      //id='name'
                      defaultValue={
                        item !== null && item.optionValue !== null
                          ? item.optionValue.en
                          : ""
                      }
                      onTranslationSelect={(e, field) => {
                        let ind = +field.split("-")[1];

                        setDialog({
                          ...dialog,
                          isOpen: true,
                          field: field,
                          translations: data.choices[ind].optionValue,
                        });
                      }}
                      field={`choices-${i}`}
                      value={item.optionValue.en}
                      onChange={(e) =>
                        setData((d) => {
                          return {
                            ...d,
                            choices: d.choices.map((c, ind) => {
                              if (ind === i) {
                                c.optionValue = {
                                  ...c.optionValue,
                                  en: e.target.value,
                                };
                              }
                              return c;
                            }),
                          };
                        })
                      }
                      sx={{ mt: "4px" }}

                      //helperText={isError.name}
                      //error={isError.name.length > 0}
                    />
                  </Grid>

                  {data.choices.length > 1 && (
                    <Grid item>
                      <IconButton
                        sx={{
                          color: "red",
                          visibility: i !== 0 ? "unset" : "hidden",
                        }}
                        onClick={() => {
                          if (i !== 0) {
                            setData((d) => {
                              let newChoices = d.choices.filter(
                                (c, ind) => ind !== i
                              );
                              return {
                                ...d,
                                choices: newChoices,
                                maxChoices: d.allowMultipleChoices
                                  ? newChoices.length
                                  : d.maxChoices,
                              };
                            });
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ))}
              {/* add new */}
              <Grid container justifyContent='flex-end'>
                <Button
                  variant='text'
                  size='small'
                  sx={{
                    fontSize: "12px",
                    px: "10px",
                    color: "#128849",
                    textTransform: "none",
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setData((d) => {
                      return {
                        ...d,
                        choices: [...d.choices, initialChoice],
                      };
                    });
                  }}
                >
                  {t("feedback.addUpdateQuestion.addNew")}
                </Button>
              </Grid>
              {/* allowMultipleChoices */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.allowMultipleChoices}
                    onChange={(e) =>
                      setData((d) => {
                        return {
                          ...d,
                          allowMultipleChoices: e.target.checked,
                        };
                      })
                    }
                  />
                }
                label={t("feedback.addUpdateQuestion.allowMultipleSelection")}
              />
              {/* minChoices ,maxChoices */}
              {data.allowMultipleChoices && (
                <Grid container gap={2} sx={{ mt: "10px" }}>
                  <Grid item sx={{ flex: 1 }}>
                    <Typography variant='body2'>
                      {t("feedback.addUpdateQuestion.min")}
                    </Typography>
                    <TextField
                      fullWidth
                      variant='outlined'
                      sx={inputSx}
                      value={data.minChoices}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setData((d) => {
                            return {
                              ...d,
                              minChoices: "",
                            };
                          });
                        }
                        if (
                          !isNaN(e.target.value) &&
                          /^[0-9]\d*(\.\d+)?$/.test(
                            parseFloat(e.target.value)
                          ) &&
                          parseFloat(e.target.value) > 0 &&
                          parseFloat(e.target.value) <= data.maxChoices
                        ) {
                          setData((d) => {
                            return {
                              ...d,
                              minChoices: e.target.value,
                            };
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ flex: 1 }}>
                    <Typography variant='body2'>
                      {t("feedback.addUpdateQuestion.max")}
                    </Typography>
                    <TextField
                      fullWidth
                      variant='outlined'
                      sx={inputSx}
                      value={data.maxChoices}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setData((d) => {
                            return {
                              ...d,
                              maxChoices: "",
                            };
                          });
                        }
                        if (
                          !isNaN(e.target.value) &&
                          /^[0-9]\d*(\.\d+)?$/.test(
                            parseFloat(e.target.value)
                          ) &&
                          parseFloat(e.target.value) > 0 &&
                          parseFloat(e.target.value) <= data.choices.length
                        ) {
                          setData((d) => {
                            return {
                              ...d,
                              maxChoices: e.target.value,
                            };
                          });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {/* promptText maxAllowedCharacters */}
          {data.type === "FreeText" && (
            <Grid item sx={{ width: "100%", mt: "25px" }}>
              <Typography variant='body2'>
                {t("feedback.addUpdateQuestion.promptText")}
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                sx={inputSx}
                value={data.promptText}
                onChange={(e) => {
                  setData((d) => {
                    return {
                      ...d,
                      promptText: e.target.value,
                    };
                  });
                }}
              />

              <Typography variant='body2' sx={{ mt: "25px" }}>
                {t("feedback.addUpdateQuestion.maxAllowedCharacters")}
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                sx={inputSx}
                value={data.maxAllowedCharacters}
                onChange={(e) => {
                  if (!e.target.value) {
                    setData((d) => {
                      return {
                        ...d,
                        maxAllowedCharacters: "",
                      };
                    });
                  }
                  if (
                    !isNaN(e.target.value) &&
                    /^[0-9]\d*(\.\d+)?$/.test(parseFloat(e.target.value))
                  ) {
                    setData((d) => {
                      return {
                        ...d,
                        maxAllowedCharacters: e.target.value,
                      };
                    });
                  }
                }}
              />
            </Grid>
          )}
          {/* ratingQuestionStyle  */}
          {data.type === "Rating_1_To_5" && (
            <Grid item sx={{ width: "100%", mt: "25px" }}>
              <Typography variant='body2'>
                {t("feedback.addUpdateQuestion.ratingStyle")}
              </Typography>
              <TextField
                select
                fullWidth
                variant='outlined'
                sx={inputSx}
                value={data.ratingQuestionStyle}
                onChange={(e) =>
                  setData((d) => {
                    return {
                      ...d,
                      ratingQuestionStyle: e.target.value,
                    };
                  })
                }
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
              >
                {ratingStyleOptions.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item.value}
                    sx={{ fontSize: "12px", fontWeight: 500 }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {/* booleanQuestionStyle  */}
          {data.type === "Boolean" && (
            <Grid item sx={{ width: "100%", mt: "25px" }}>
              <Typography variant='body2'>
                {t("feedback.addUpdateQuestion.booleanStyle")}
              </Typography>
              <TextField
                select
                fullWidth
                variant='outlined'
                sx={inputSx}
                value={data.booleanQuestionStyle}
                onChange={(e) =>
                  setData((d) => {
                    return {
                      ...d,
                      booleanQuestionStyle: e.target.value,
                    };
                  })
                }
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
              >
                {booleanQuestionStyleOptions.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item.value}
                    sx={{ fontSize: "12px", fontWeight: 500 }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {error && (
            <Typography
              variant='body2'
              align='center'
              sx={{ mt: "5px", color: "red", fontWeight: 500 }}
            >
              {error}
            </Typography>
          )}
          {/* buttons */}
          <Grid item sx={{ width: "100%", mt: "20px" }}>
            <Grid container wrap='nowrap' justifyContent='flex-end' spacing={2}>
              <Grid item>
                <Button
                  fullWidth
                  size='large'
                  variant='outlined'
                  sx={{
                    border: "1px solid #D0D5DD",
                    background: "transparent",
                    boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                    color: "#344054",
                  }}
                  onClick={cancelHandler}
                >
                  {t("feedback.addUpdateCategory.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  fullWidth
                  variant='contained'
                  size='large'
                  //onClick={ submitHandler}
                  onClick={edit ? editHandler : submitHandler}
                >
                  {/* {loading?.active && loading?.action === "submit" && (
                <CircularProgress
                  size='1rem'
                  color='inherit'
                  sx={{ marginRight: "10px" }}
                />
              )} */}
                  {t("feedback.addUpdateCategory.submit")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddUpdateQuestionDialog;