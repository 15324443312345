import { createTheme, FormControl, FormHelperText, InputAdornment, ThemeProvider } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';

/**
 * The Custom Select Field, that display customized select tag.
 * 
 * @property {string} id the text field id
 * @property {string} labelID the text field label id
 * @property {string} name the input field name
 * @property {string} label Applies the theme typography styles.
 * @property {array} defaultValue the text field default value
 * @property {boolean} required if the value is required at this text field 
 * @property {boolean} isMultiple if multiple selection is enabled
 * @property {function} onChange the function that handles the select input change
 * @property {array} options the different option list
 * @property {string} helperText a helper text
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSelectWithLabels({ id, labelID = "", name = "", label, defaultValue = [], required = true, isMultiple = false, onChange, options, helperText = "", sx, startAdormetText, variant = "outlined" }) {
    function getMuiTheme() {
        return createTheme({
            components: {
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            borderRadius: "30px",
                            ...sx
                        }
                    }
                }
            }
        })
    }

    return (
        <>
            <ThemeProvider theme={getMuiTheme()}>
                <FormControl fullWidth>
                    {label !== undefined && <InputLabel id={labelID} sx={{ background: "#ebf5fa" }}>{label}</InputLabel>}
                    <Select
                        id={labelID}
                        variant={variant}
                        labelId={labelID}
                        multiple={isMultiple}
                        onChange={onChange}
                        input={<OutlinedInput id={id} name={name} />}
                        required={required}
                        defaultValue={defaultValue}
                        label={label}
                        startAdornment={(
                            <span style={{ fontWeight: 600, paddingRight: "10px" }}>{startAdormetText}</span>
                        )}
                    >
                        {options.map((item) => (
                            <MenuItem
                                key={item.label}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </ThemeProvider>
            {helperText !== "" && <FormHelperText>{helperText}</FormHelperText>}
        </>
    );
}

export default CustomSelectWithLabels;