import { getDefaultDataPda } from "constants/defaultObjects";
import { pdaActionTypes } from "./pdaActionTypes";

const initialState = {
    data: getDefaultDataPda(),
    tabValue: "",
    refetchData: false
};

const pdaReducer = (state = initialState, action) => {
    switch (action.type) {
        case pdaActionTypes.UPDATE_PDA_DATA: {
            const { data } = action.payload;
            return {
                ...state,
                data: data
            };
        }
        case pdaActionTypes.UPDATE_PDA_TAB_VALUE: {
            const { tabValue } = action.payload;
            return {
                ...state,
                tabValue: tabValue
            };
        }
        case pdaActionTypes.UPDATE_PDA_REFETCH_DATA: {
            const { refetchData } = action.payload;
            return {
                ...state,
                refetchData: refetchData
            };
        }
        case pdaActionTypes.UPDATE_PDA_ALL: {
            const { data, tabValue } = action.payload;
            console.log(tabValue+"hi")
            return {
                ...state,
                data: data,
                tabValue: tabValue,
                refetchData: true
            };
        }
        // Implement additional cases for other properties
        default:
            return state;
    }
};

export default pdaReducer;