import {
    NewIcon,
    PepperIcon,
    VeganIcon,
    VegetarianIcon,
} from 'assets/svg/SvgIcons';
import { getItemExtraIconStyle } from 'themes/defaultThemes';

export function getExtraIcons(extraIcons) {
    let extraIconsList = [];
    if (extraIcons?.length === 0) return extraIconsList;

    if (extraIcons?.includes("NEW")) {
        extraIconsList.push(<NewIcon {...getItemExtraIconStyle()} fill="green" />)
    }
    if (extraIcons?.includes("SPICY")) {
        extraIconsList.push(<PepperIcon {...getItemExtraIconStyle()} fill="red" />)
    }
    if (extraIcons?.includes("VEGAN")) {
        extraIconsList.push(<VeganIcon {...getItemExtraIconStyle()} fill="#5f9e70" />)
    }
    if (extraIcons?.includes("VEGETARIAN")) {
        extraIconsList.push(<VegetarianIcon {...getItemExtraIconStyle()} fill="green" />)
    }
    return extraIconsList;
}

export default class functions {
    static getExtraIcons(extraIcons) { return getExtraIcons(extraIcons); };
}