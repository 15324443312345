
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * The Custom Button, that display a add button.
 * @property {function} Callback fired when the button is clicked.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomAddButton({ onClick, label, sx }) {
    const { t } = useTranslation();

    return (
        <ThemeProvider theme={createTheme({
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            color: "#54b1d2",
                            textTransform: "initial",
                            ...sx
                        },
                        startIcon: {
                            marginRight: "10px",
                            color: "#205d7b"
                        }
                    }
                }
            }
        })}>
            <Button onClick={onClick} >{`${t('actions.add')} ${label}`}</Button>
        </ThemeProvider>
    );
}

export default CustomAddButton;