import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import DehazeIcon from '@mui/icons-material/Dehaze';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import HeightIcon from '@mui/icons-material/Height';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { createTheme, Divider, FormControlLabel, ListItem, ListItemText, Switch, ThemeProvider, Tooltip, Typography } from "@mui/material";
import React, { useState } from 'react';
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { findAllObjectsMatchInArrayOrdered } from 'utils/functions';
import CustomAddButton from 'components/Button/CustomAddButton';
import CustomButton from 'components/Button/CustomButton';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomTextField from "components/Text/CustomTextField";
import CustomMenuCategoriesDraggableTable from 'components/Table/CustomMenuCategoriesDraggableTable';

/**
 * A Custom Draggable List Item.
 * @property {object} parentCategory a specific parent-category
 * @property {array} categoryOptions the array of the categories
 * @property {array} itemOptions the array of the items
 * @property {int} index modifier-list parentCategory index
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenuParentCategoriesDraggableTableItem({ parentCategory, index, expandAll, organizationID, handleTranslationsDialogState,
    removeParentCategory, updateParentCategoryName, updateParentCategoryIsActive, allowParentCategories,// parent categories
    categoryOptions, addCategoryToParentCategory, updateCategoryName, updateCategoryIsActive, updateMenuCategoriesOrder, removeCategory,// categories
    itemOptions, removeItemFromCategory, handleDialogState, updateItemOrder //items
}) {
    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [expand, setExpand] = useState(true);
    const [errorInput, setErrorInput] = useState("");
    const { t } = useTranslation();

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiInputAdornment: {
                    styleOverrides: {
                        root: {
                            fontWeight: 600,
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            maxHeight: "50px"
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Draggable draggableId={parentCategory.id} index={index} key={parentCategory.id}>
                {(provided, snapshot) => (
                    <>
                        {allowParentCategories &&
                            <ListItem
                                ref={provided.innerRef}
                                style={{
                                    background: snapshot.isDragging ? 'rgb(235,235,235)' : '',
                                    width: "100%",
                                    borderBottom: "1px",
                                    userSelect: "none",
                                    padding: 16,
                                    paddingLeft: "0px",
                                    borderRadius: "4px",
                                    ...provided.draggableProps.style
                                }}
                            >
                                <>
                                    {expand && <ExpandMoreIcon style={{ color: "darkgrey", fontSize: "20px", marginLeft: "5px", marginRight: "10px", visibility: (parentCategory?.categoryIds?.length > 0) ? "initial" : "hidden" }} onClick={() => setExpand(false)} />}
                                    {!expand && <ChevronRightIcon style={{ color: "darkgrey", fontSize: "20px", marginLeft: "5px", marginRight: "10px", visibility: (parentCategory?.categoryIds?.length > 0) ? "initial" : "hidden" }} onClick={() => setExpand(true)} />}

                                    <span {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                                        <HeightIcon style={{ fontSize: "19px", color: "lightgrey" }} />
                                        <DehazeIcon style={{ marginRight: 15, marginLeft: "-6px", fontSize: "20px", width: "15px", color: "lightgrey", paddingTop: "1px" }} />
                                    </span>

                                    <CustomIconButton
                                        onClick={() => handleDialogState(true, { parentCategoryId: parentCategory.id, categoryId: null, field: "parentCategory" }, "photosDialog")}
                                        icon={<PhotoLibraryIcon style={{ marginRight: "0px" }} />}
                                        iconSx={{ marginRight: "0px!important" }}
                                        tooltipTitle={t("assets.configurePhotos")}
                                        sx={{ backgroundColor: "white", borderRadius: "50px", padding: "4px 0px", minWidth: "20px", marginRight: "10px" }}
                                    />

                                    <CustomIconButton
                                        onClick={() => handleTranslationsDialogState(true, "parentCategory", parentCategory)}
                                        icon={<GTranslateIcon style={{ marginRight: "0px" }} />}
                                        iconSx={{ marginRight: "0px!important" }}
                                        tooltipTitle={t("label.configureTranslations")}
                                        sx={{ backgroundColor: "white", borderRadius: "50px", padding: "4px 0px", minWidth: "20px", marginRight: "10px" }}
                                    />

                                    {!show &&
                                        <div style={{ display: "flex" }}>
                                            <ListItemText primary={parentCategory.name.en} />
                                            <Tooltip title={t("actions.edit")}>
                                                <EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px", marginTop: "4px" }}
                                                    onClick={() => setShow(true)} />
                                            </Tooltip>
                                        </div>
                                    }

                                    {show &&
                                        <div style={{ display: "flex" }}>
                                            <CustomTextField
                                                id={`${parentCategory.id}-name`}
                                                type="text"
                                                defaultValue={parentCategory.name.en}
                                                variant="standard"
                                            />
                                            <Tooltip title={t("actions.save")}>
                                                <CheckIcon
                                                    style={{ color: "lightgrey", fontSize: "20px", marginLeft: "20px", marginTop: "15px" }}
                                                    onClick={() => { setShow(false); updateParentCategoryName(parentCategory, document.getElementById(`${parentCategory.id}-name`).value) }} />
                                            </Tooltip>
                                            <Tooltip title={t("actions.cancel")}>
                                                <ClearIcon
                                                    style={{ color: "lightgrey", fontSize: "20px", marginLeft: "20px", marginTop: "15px" }}
                                                    onClick={() => setShow(false)} />
                                            </Tooltip>
                                        </div>
                                    }

                                    <div style={{ display: "flex", right: 0, position: "absolute" }}>
                                        <>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 600, marginLeft: "50px", marginTop: "10px" }}>{t('menuCategories.categories') + "( " + parentCategory.categoryIds.length + " )"}</Typography>
                                        </>
                                        <>
                                            <Tooltip title={t("actions.delete")}><DeleteIcon style={{ color: "#205d7b", fontSize: "20px", marginLeft: "30px", marginRight: "20px", marginTop: "10px" }} onClick={() => removeParentCategory(parentCategory)} /></Tooltip>
                                            <CustomAddButton label={t('menuCategories.plain')} onClick={() => setShowAdd(true)} />
                                            <FormControlLabel control={<Switch defaultChecked={parentCategory.active} onChange={(event) => updateParentCategoryIsActive(parentCategory, !parentCategory.active)} />} sx={{ marginLeft: "20px" }} />
                                        </>
                                    </div>
                                </>
                            </ListItem>
                        }

                        {/* list categories */}
                        {(expand && parentCategory.categoryIds.length > 0) &&
                            <CustomMenuCategoriesDraggableTable
                                parentCategoryId={parentCategory.id}
                                parentCategoryIndex={index}
                                organizationID={organizationID}
                                // categories
                                categories={findAllObjectsMatchInArrayOrdered(parentCategory.categoryIds, categoryOptions)}
                                addCategoryToParentCategory={addCategoryToParentCategory}
                                updateCategoryName={updateCategoryName}
                                updateCategoryIsActive={updateCategoryIsActive}
                                updateMenuCategoriesOrder={updateMenuCategoriesOrder}
                                removeCategory={removeCategory}
                                //items
                                itemOptions={itemOptions}
                                removeItemFromCategory={removeItemFromCategory}
                                updateItemOrder={updateItemOrder}
                                // other
                                handleTranslationsDialogState={handleTranslationsDialogState}
                                expandAll={expandAll}
                                handleDialogState={handleDialogState}
                            />
                        }

                        {/* Add new category */}
                        {(parentCategory.categoryIds.length > 0 && !showAdd) &&
                            <CustomAddButton label={t('menuCategories.plain')} sx={{ marginLeft: "70px" }} onClick={() => setShowAdd(true)} />
                        }

                        {!allowParentCategories || (allowParentCategories && showAdd) &&
                            <div style={{ display: "flex", marginTop: "30px", marginBottom: "20px", marginLeft: "80px" }}>
                                <CustomTextField
                                    variant="standard"
                                    type="text"
                                    id={`${parentCategory.id}-category-name-new`}
                                    required={true}
                                    label={t('menuCategories.newCategory')}
                                    error={errorInput.length > 0 ? true : false}
                                    sx={{ width: "87%" }}
                                    onKeyUp={(e) => { if (e.key === 'Enter') { e.preventDefault(); setShowAdd(false); addCategoryToParentCategory(parentCategory) } }}
                                />
                                <CustomButton
                                    type="button"
                                    id="btn-create-menu-category"
                                    label={t('actions.create')}
                                    sx={{ backgroundColor: "#54b1d2", color: "white", textTransform: "initial", fontSize: "12px" }}
                                    sxHover={{ backgroundColor: "#34809b", color: "white", textTransform: "initial", fontSize: "12px" }}
                                    onClick={() => addCategoryToParentCategory(parentCategory)}
                                />
                                <CustomButton
                                    type="button"
                                    id="btn-cancel-menu-category"
                                    label={t('actions.cancel')}
                                    sx={{ backgroundColor: "red", color: "white", textTransform: "initial", fontSize: "12px", marginLeft: "10px", marginRight: "10px" }}
                                    sxHover={{ backgroundColor: "darkred", color: "white", textTransform: "initial", fontSize: "12px" }}
                                    onClick={() => setShowAdd(false)}
                                />
                            </div>
                        }

                        <Divider style={{ width: "91%", marginLeft: "70px" }} />
                    </>
                )}
            </Draggable>
        </ThemeProvider>
    );
}

export default CustomMenuParentCategoriesDraggableTableItem;