import GTranslateIcon from '@mui/icons-material/GTranslate';
import { createTheme, InputAdornment, ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomIconButton from 'components/Button/CustomIconButton';

/**
 * The Custom Flag Text Field, that display customized {@link TextField}.
 * @property {string} id the text field id
 * @property {string} name the text field name
 * @property {string} type the text field type
 * @property {string} className the text field class
 * @property {string} label Applies the theme typography styles.
 * @property {string} defaultValue the text field default value
 * @property {string} placeholder the text field placeholder
 * @property {string} helperText the text field helper text
 * @property {boolean} error if text field is at an error state
 * @property {string} country the text field language code
 * @property {string} flag the text field language flag
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomFlagTextField({ id = "", name = "", type = "text", className = "", label = "", defaultValue, value, placeholder, helperText = "", error = false, country = "en", flag, sx, onTranslationSelect, onChange, field }) {

    const { t } = useTranslation();

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiOutlinedInput: {
                    styleOverrides: {
                        input: {
                            marginBottom: 0
                        }
                    }
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            width: "100%!important",
                            padding: "11px",
                            marginBottom: 0
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: "10px" }}>
                <TextField
                    id={id}
                    name={name}
                    type={type}
                    className={className}
                    label={label}
                    value={value}
                    defaultValue={defaultValue}
                    placeholder={placeholder ? placeholder : placeholder === "" ? "" : t(`languages.${country}`)}
                    helperText={helperText}
                    error={error}
                    sx={{
                        ...sx,
                        padding: "0px"
                    }}
                    InputProps={{
                        startAdornment: <img src={flag} width="30" alt={country} style={{ placeSelf: "center", marginRight: "10px" }} />
                        ,
                        endAdornment: (
                            onTranslationSelect !== undefined ?
                                <InputAdornment position="end" >
                                    <CustomIconButton onClick={(event) => onTranslationSelect(event, field)} icon={<GTranslateIcon style={{ marginRight: "0px" }} />} className="icon-wrapper tr link" iconSx={{ marginRight: "0px!important" }} sx={{ backgroundColor: "white", borderRadius: "50px", padding: "8px 0px", minWidth: "40px", marginRight: "10px", marginTop: "-5px" }}></CustomIconButton>
                                </InputAdornment>
                                : <></>
                        )
                    }}
                    onChange={onChange}
                />
            </Box>
        </ThemeProvider>
    );
}

export default CustomFlagTextField;