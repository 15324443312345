import { CloseRounded } from '@mui/icons-material';
import {
    Dialog, DialogContent, IconButton
} from '@mui/material';

/**
 * The FullScreenDialog, that display a customized dialog.
 * 
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {function} handleClose the function that handles the dialog close state
 * @property {*} children the body of the dialog
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function FullScreenDialog({ isOpen = false, id = "", handleClose, children }) {

    return (
        <Dialog
            id={id}
            open={isOpen}
            onClose={() => { handleClose(false) }}
            fullWidth
            fullScreen
            PaperProps={{
                sx: {
                    overflowX: "hidden",
                },
            }}
        >
            <IconButton onClick={() => handleClose(false)} sx={{ position: "absolute", right: 0, width: '40px', zIndex: 1500 }}>
                <CloseRounded />
            </IconButton>
            <DialogContent sx={{ pt: "0" }} >
                {children}
            </DialogContent>
        </Dialog>
    );
}

export default FullScreenDialog;