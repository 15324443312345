import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete, Button,
    Checkbox,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
    Popper,
    TextField, ThemeProvider, Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * The AttachEquipmentDialog, that display a customized dialog to attach equipments to a section.
 * 
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {string} title the dialog title
 * @property {string} searchLabel the search autocomplete field label
 * @property {string} noOptionsText the no options text for autocomplete
 * @property {string} actionLabel the dialog action label
 * @property {array} instances the array of modifier list
 * @property {function} action the function that handles the dialog action button click
 * @property {function} handleOpen the function that handles the dialog open state
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AttachEquipmentDialog({ isOpen = false, id = "", title, boldTitle, searchLabel, noOptionsText, actionLabel, action, instances = [], handleOpen, field, dialogName, category }) {
    const PopperMy = function (props) {
        const anchorEl = document.getElementById('dialog-content');
        return (<Popper {...props}
            modifiers={[
                {
                    name: 'flip',
                    enabled: false, // Disable flipping if necessary
                },
                {
                    name: 'preventOverflow',
                    enabled: false, // Disable overflow prevention if necessary
                    options: {
                        altAxis: true, // Adjust alternate axis behavior if necessary
                        tether: false, // Disable tethering if necessary
                    },
                },
            ]}
            // anchorEl={anchorEl}
            style={{ width: "100%", margin: "20px", height: anchorEl.clientHeight, overflowY: 'auto', transform: "translate(0px, 0px)" }}
            placement='bottom'
            keepMounted={true}
        />)
    }

    const { t } = useTranslation();
    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);
    const [value, setValue] = useState([]);

    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpen) {
        setOpen(isOpen);
        handleOpen(isOpen, field, dialogName);
    }

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiFilledInput: {
                    styleOverrides: {
                        input: {
                            backgroundColor: "white",
                        },
                        root: {
                            backgroundColor: "white",
                            border: "1px solid grey"
                        }
                    }
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "white",
                            zIndex: 111111111,
                            padding: 0
                        }
                    }
                },
                MuiDialogContent: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "white",
                            paddingBottom: "100px"
                        }
                    }
                },
                MuiDialog: {
                    styleOverrides: {
                        paper: {
                            width: "60%",
                            minWidth: "60%"
                        }
                    }
                },
                MuiListItemText: {
                    styleOverrides: {
                        secondary: {
                            fontWeight: 700,
                            fontSize: "11px"
                        }
                    }
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        secondary: {
                            // height: "900px",
                            overflow: "scroll"
                        },
                        listbox: {
                            marginTop: 0,
                            padding: 0,
                            height: "40%"
                        },
                        popper: {
                            height: "fit-content"
                        },
                        paper: {
                            height: "fit-content",
                            marginLeft: "25px",
                            marginRight: "25px"
                        }
                    }
                },
                MuiChip: {
                    styleOverrides: {
                        label: {
                            whiteSpace: "pre-wrap!important"
                        },
                        root: {
                            padding: "7px!important",
                            marginBottom: "10px",
                            height: "fit-content"
                        }
                    }
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            paddingRight: "50px",
                        }
                    }
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "lightgrey",
                            marginLeft: "40px"
                        }
                    }
                }
            }
        });
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Dialog
                id={id}
                open={open}
                onClose={() => handleDialogState(false)}
                aria-describedby="alert-dialog-slide-description"
                className="dialog-title"
            >
                <DialogTitle className="dialog-headers" style={{ textAlignLast: "center" }}>
                    <Typography variant="title" sx={{ fontSize: "18px" }}>{`${title} `}</Typography>
                    <Typography variant="title" sx={{ color: "#205d7b", fontSize: "19px" }}>{boldTitle}</Typography>
                    {/* <IconButton onClick={() => handleDialogState(false)} sx={{ float: "right" }}>
                        <CloseRounded />
                    </IconButton> */}
                </DialogTitle>
                <DialogContent sx={{ marginBottom: "80px", marginTop: "20px", minHeight: "360px" }} id="dialog-content">
                    <Autocomplete
                        key="equipment"
                        id="equipment-select"
                        disablePortal={true}
                        options={instances}
                        autoHighlight
                        disableCloseOnSelect
                        multiple={true}
                        open={true}
                        getOptionLabel={(option) => option.label}
                        noOptionsText={noOptionsText}
                        popupIcon={<></>}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        PopperComponent={PopperMy}
                        renderOption={(props, option, { selected }) => {
                            return <>
                                <li key={option.id} {...props} style={{ borderBottom: (instances[instances.length - 1].id !== option.id) ? "1px solid darkgrey" : "none", paddingTop: "20px" }}>
                                    <div key={option.id + "-1"} style={{ display: "contents" }}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={selected}
                                        />
                                    </div>
                                    <div key={option.id + "-2"} style={{ display: "block", width: "90%" }}>
                                        <Typography variant="inherit" sx={{ fontWeight: 600, fontSize: "15px" }}>{option.label}</Typography>
                                    </div>
                                </li>
                            </>
                        }
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={searchLabel}
                                variant='filled'
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button className="dialog-cancel-btn" sx={{ textTransform: "none !important", backgroundColor: "#ebf5f9" }} onClick={() => handleDialogState(false)}>{t('actions.cancel')}</Button>
                    <Button className="dialog-action-btn" sx={{ textTransform: "none !important", marginLeft: "40px!important", backgroundColor: (value.length !== 0 ? "#205d7b" : "lightgrey"), color: "white!important" }} disabled={(value.length === 0 ? true : false)} onClick={() => action(value, field, category)}>{`${actionLabel} ${value.length} ${value.length === 1 ? t('equipment.attachEquipment') : t('equipment.attachEquipments')}`}</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default AttachEquipmentDialog;
