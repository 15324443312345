import { request } from 'constants/alias';
import { API } from "constants/config";
import { dateTimeFormatter, includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to create a new coupon.
 * @param {*} data A JSON object which contains new coupon infromation
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    data.expirationDate = dateTimeFormatter(data.expirationDate, "yyyy-MM-DDTHH:mm:ss.SSSSSS");
    return await request.post(`${API}coupon`, data);
}

/**
 * The rest request which return required information to be presented on add new item page.
 * @param {*} organizationID The id of the equipment organization id
 * @returns an object with an attribute containing the requested information to be presented on the item page
 */
export async function fetchNew(organizationID, outletID) {
    let data = {};
    if (organizationID !== null && organizationID !== "" && organizationID !== undefined) {
        data.organizationID = organizationID;
    }
    if (outletID !== null && outletID !== "" && outletID !== undefined) {
        data.outletID = outletID;
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}coupon/add`, data));
    else return await request.get(includeParamsToUrl(`${API}coupon/add`));
}

/**
 * The rest request which attempts to validate a user provided coupon.
 * @param {*} organizationID The id of the coupon organization id
 * @param {*} couponCode The id of the coupon code to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the coupon
 */
export async function validateCouponCode(organizationID, couponCode) {
    let data = {};
    if (organizationID !== null && organizationID !== "" && organizationID !== undefined) {
        data.organizationID = organizationID;
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}coupon/validate/${couponCode}`, data));
    else return await request.get(includeParamsToUrl(`${API}coupon/validate/${couponCode}`));
}

const couponApi = {
    create,
    fetchNew,
    validateCouponCode
}

export default couponApi;