
import { combineReducers } from 'redux';
import requestReducer from './request/requestReducer';
import pdaReducer from './pda/pdaReducer';
import orderReducer from './order/orderReducer';

const rootReducer = combineReducers({
  request: requestReducer,
  order: orderReducer,
  pda: pdaReducer
  // other reducers can be added here
});

export default rootReducer;