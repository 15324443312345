import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  DialogContent,
  Dialog,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CustomTypography from "components/Typography/CustomTypography";
import Timer from "./timer";

import AssignTimerDialog from "./assignTimerDialog";
import OrderItems from "./orderItems";
import orderApi from "api/order";
import LackOfProductModal from "./lackOfProductModal";
import * as moment from "moment";
const getCartColor = (status) => {
  if (status === "PENDING") {
    return "pendingOrderColor";
  } else if (status === "WORKING") {
    return "workingOrderColor";
  } else if (status === "DONE") {
    return "doneOrderColor";
  } else return "empty";
};

export default function OrderCard({
  order,
  setting,
  minimize,
  currency,
  updateOrderItemStatus,
  cancelOrder,
  onAssignTimer,
  onCompleteOrder,
  updateOrderItemQuantity,
  setAlertBox,
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState({
    active: false,
    action: "",
  });
  const [moreMenu, setMoreMenu] = useState({
    active: null,
    order: null,
  });
  const [showAssignTimerDialog, setShowAssignTimerDialog] = useState({
    active: false,
    order: null,
  });
  const [cancelOrderDialog, setCancelOrderDialog] = useState({
    active: false,
    order: null,
  });

  const [lackOfProductDialog, setLackOfProductDialog] = useState({
    active: false,
    order: null,
  });

  const changeOrderStatusHandler = (orderId, status, action, callback) => {
    setLoading({
      active: true,
      action: action,
    });
    orderApi
      .updateOrderStatus(orderId, status)
      .then((r) => {
        if (r.data.message) {
          setAlertBox({
            isOpen: true,
            message: r.data.message,
            backgroundColor: "#4caf50",
          });
        }

        setLoading({
          active: false,
          action: "",
        });
        if (callback) {
          callback();
        }
      })
      .catch((e) => {
        // console.log(e);
        setAlertBox({
          isOpen: true,
          message: "Fail to Update order status",
          backgroundColor: "#ef5350",
        });
        setLoading({
          active: false,
          action: "",
        });
      });
  };
  const moreMenuId = "more-menu";
  const renderMoreMenu = (
    <Menu
      id={moreMenuId}
      anchorEl={moreMenu.active}
      open={Boolean(moreMenu.active)}
      onClose={() =>
        setMoreMenu({
          active: null,
          order: null,
        })
      }
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem
        onClick={() => {
          setLackOfProductDialog({
            active: true,
            order: moreMenu.order,
          });
          setMoreMenu({
            active: null,
            order: null,
          });
        }}
      >
        {t("orders.lackOfProduct")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          setCancelOrderDialog({
            active: true,
            order: moreMenu.order,
          });
          setMoreMenu({
            active: null,
            order: null,
          });
        }}
      >
        {t("orders.cancelOrder")}
      </MenuItem>
    </Menu>
  );

  const renderCancelOrderDialog = (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={cancelOrderDialog.active}
      onClose={() => {
        setCancelOrderDialog({
          active: false,
          order: null,
        });
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        <Typography variant='h5' sx={{ color: "#34353A", fontWeight: 700 }}>
          {t("orders.cancelOrder")} #{cancelOrderDialog.order?.orderNumber}
        </Typography>
        <Typography variant='body1' sx={{ mt: "20px", color: "#4a4c50" }}>
          {t("orders.cancelMessage")} #{cancelOrderDialog.order?.orderNumber}?
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent='flex-end'
          sx={{ mt: "28px" }}
        >
          <Grid item>
            <Button
              variant='text'
              sx={{
                borderColor: "transparent",
                fontWeight: 600,
                color: "#4A4c50",
              }}
              onClick={() =>
                setCancelOrderDialog({
                  active: false,
                  order: null,
                })
              }
            >
              {t("orders.no")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              sx={{
                borderColor: "red",
                color: "red",
                fontWeight: 600,
              }}
              onClick={() =>
                changeOrderStatusHandler(
                  cancelOrderDialog.order?.id,
                  "CANCELED",
                  "cancel",
                  () => {
                    cancelOrder(cancelOrderDialog.order?.id);
                    setCancelOrderDialog({
                      active: false,
                      order: null,
                    });
                  }
                )
              }
              disabled={loading.active && loading.action === "cancel"}
            >
              {t("orders.yes")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const equipmentLabel = (
    <Box
      sx={{
        background: "#DDDFE2",
        //boxShadow: `0px 0px 15px 0px #dcdcdc`,
        borderRadius: "50px",
        padding: "3px 10px",
        mt: order.status === "WORKING" ? "10px" : 0,
      }}
    >
      <CustomTypography
        text={order?.equipment?.label}
        //text='Text'
        sx={{
          fontWeight: 600,
        }}
      />
    </Box>
  );
  return (
    <Paper
      sx={{
        background:
          getCartColor(order.status) === "empty"
            ? "#fff"
            : setting[getCartColor(order.status)],
        boxShadow: `0px 0px 15px 0px #dcdcdc`,
        borderRadius: "5px",
        boxSizing: "border-box",
        padding: minimize ? "15px" : "20px 15px",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {renderMoreMenu}
      {renderCancelOrderDialog}
      <AssignTimerDialog
        open={showAssignTimerDialog.active}
        onClose={() =>
          setShowAssignTimerDialog({
            active: false,
            order: null,
          })
        }
        onSubmit={(time, response) =>
          onAssignTimer(showAssignTimerDialog.order?.id, time, response)
        }
        order={showAssignTimerDialog.order}
        setting={setting}
        currency={currency}
      />
      <LackOfProductModal
        open={lackOfProductDialog.active}
        onClose={() =>
          setLackOfProductDialog({
            active: false,
            order: null,
          })
        }
        onSubmit={(orderId, items, response) => {
          updateOrderItemQuantity(orderId, items);
          if (response?.data?.message) {
            setAlertBox({
              isOpen: true,
              message: response.data.message,
              backgroundColor: "#4caf50",
            });
          }
        }}
        order={lackOfProductDialog.order}
      />
      {/* orderNumber and customerName and timer */}
      <Grid
        container
        direction={minimize ? "column" : "row"}
        spacing={1}
        justifyContent='space-between'
        alignItems={minimize ? "flex-start" : "center"}
      >
        {/* orderNumber and customerName */}
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: minimize ? "column" : "row",
            alignItems: minimize ? "flex-start" : "center",
            gap: "15px",
          }}
        >
          <Box
            sx={{
              background: minimize ? "unset" : "#DDDFE2",
              //boxShadow: `0px 0px 15px 0px #dcdcdc`,
              borderRadius: "5px",
              padding: minimize ? 0 : "10px",
            }}
          >
            <CustomTypography
              text={`#${order.orderNumber}`}
              sx={{ fontWeight: "600" }}
            />
          </Box>
          {order.customer && (
            <CustomTypography
              text={`${order.customer?.name}`}
              sx={{ fontWeight: "600" }}
              variant='body1'
            />
          )}
        </Grid>
        {/* timer */}
        <Grid item sx={{ mt: minimize ? "30px" : 0 }}>
          {order.status === "WORKING" && (
            <div
              style={{
                width: minimize ? "unset" : "80px",
                height: minimize ? "unset" : "80px",
              }}
            >
              <Timer
                timeInMinute={order.processingTimeInMinutes}
                createdTime={
                  order.actionsHistory.find((x) => x.status === "WORKING")
                    .dateTime
                }
                minimize={minimize}
              />
            </div>
          )}
          {order?.equipment?.label && equipmentLabel}
        </Grid>
      </Grid>
      {/* Order labels */}
      {!minimize && (
        <Grid
          container
          sx={{
            mt: "10px",
          }}
        >
          <Chip
            size='small'
            label={"new customer"}
            color='primary'
            sx={{ background: "#66A15F" }}
          />
        </Grid>
      )}
      <Grid
        item
        sx={{ pb: "10px", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      />
      {/* Order Items */}
      {!minimize ? (
        order.cart.length > 0 && (
          <Grid container sx={{ mt: "15px" }}>
            <OrderItems
              order={order}
              setting={setting}
              currency={currency}
              updateOrderItemStatus={updateOrderItemStatus}
              setAlertBox={setAlertBox}
            />
          </Grid>
        )
      ) : (
        <Grid container sx={{ mt: "15px" }}>
          <CustomTypography
            text={`${t("orders.totalItems")} ${order.cart.length}`}
          />
        </Grid>
      )}
      {(order.status === "PENDING" || order.status === "WORKING") && (
        <Grid
          item
          sx={{
            width: "100%",
            flex: 1,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          {/* buttons */}
          <Grid container spacing={1} sx={{ mt: "15px" }}>
            <Grid item sx={{ display: "flex", flex: 1 }}>
              <Button
                variant='contained'
                size={minimize ? "small" : "large"}
                fullWidth
                sx={{
                  background: "#308ECC",
                  textTransform: "none",
                  fontWeight: "600",
                  height: "100%",
                }}
                disabled={
                  loading.active && loading.action === `done-${order.id}`
                }
                onClick={() => {
                  if (order.status === "PENDING") {
                    setShowAssignTimerDialog({
                      active: true,
                      order: order,
                    });
                  } else if (order.status === "WORKING") {
                    changeOrderStatusHandler(
                      order.id,
                      "DONE",
                      `done-${order.id}`,
                      () => {
                        onCompleteOrder(order.id);
                      }
                    );
                  }
                }}
              >
                {order.status === "PENDING" && t("orders.moveToDone")}
                {order.status === "WORKING" && t("orders.complete")}
              </Button>
            </Grid>
            {order.status === "PENDING" && (
              <Grid item sx={{ display: "flex" }}>
                <IconButton
                  disableRipple
                  size={minimize ? "small" : "medium"}
                  sx={{
                    borderRadius: "4px",
                    background: "#DDE4E4",
                    boxShadow: `0px 0px 10px 0px #DDE4E4`,
                    height: "100%",
                  }}
                  aria-controls={
                    Boolean(moreMenu.active) ? moreMenuId : undefined
                  }
                  aria-haspopup='true'
                  aria-expanded={Boolean(moreMenu.active) ? "true" : undefined}
                  onClick={(e) =>
                    setMoreMenu({
                      active: e.currentTarget,
                      order: order,
                    })
                  }
                >
                  <MoreHorizIcon sx={{ color: "#308ECC" }} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
