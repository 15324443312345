import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Person, ShoppingCart } from '@mui/icons-material';
import { Badge, Box, Card, CardActionArea, Divider, Fab, Grid, InputAdornment, InputLabel, OutlinedInput, Stack, Tooltip } from '@mui/material';
import orderApi from 'api/order';
import { ArrowBackIcon, ArrowLeftIcon, EditIcon, MessageIcon, PlaceIcon, ReceiptIcon, SearchTwoToneIcon, SendIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomSingleCardItem from 'components/Card/item/CustomSingleCardItem';
import CustomGrid from 'components/Grid/CustomGrid';
import CheckoutPersonsPopover from 'components/Popover/CheckoutPersonsPopover';
import OrderTypePopover from 'components/Popover/OrderTypePopover';
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTypography from 'components/Typography/CustomTypography';
import { requestTextFieldLimit } from 'constants/textFieldLimit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkoutCommentTextareaBoxStyle } from 'themes/checkout/checkoutTheme';
import { cardItemContentActionArea, checkoutCommentTextareaBoxInfoCharStyle, fabStyle, mainTag, orderAddTextStyle, orderAddTextStyleLabel, orderBackBtnStyle, orderCartTitleTextStyle, orderIconStyle, orderItemFontAwesomeIconStyle, orderItemMuiIconStyle, orderItemTaxPriceTextStyle, orderItemTaxTextStyle, orderItemTotalPriceTextStyle, orderItemTotalTextStyle, pastOrderViewHeaderTextStyle } from 'themes/defaultThemes';
import { getOrderEquipmentAdd } from 'themes/qrcode/defaultTheme';
import { calculateLoyaltyPointsEarn, calculateQuantitiesSum, calculateTax, calculateTotalAmount } from 'utils/cart-functions';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { calculateCartChipQuantity, priceTextWithCurrency } from 'utils/functions/order';
import CustomPDAItemCard from '../item/CustomPDAItemCard';
import { getItems } from 'utils/item';

/**
 * The CustomPDAAddOrderGrid, that display a custom {@link Grid} for create order through PDA
 * 
 * @property {string} label the instance name
 * @property {*} icon the icon represented the instance
 * @property {int} totalNumber the total number of the instances presented
 * @property {string} link the onclick card navigation link
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPDAAddOrderGrid({ handleReset, setAlertBox,
    equipmentID, equipmentLabel, organizationID, outletID,
    isAddOrdersPage,
    getPDADisplay
}) {
    const { t } = useTranslation();

    /**
     * @type {object}
     * 
     * @property {object} userAuth the authenticated user infromation
     * @property {array} orders the orders list
     * @property {object} outlet tha outlet basic details (id and name, isActive)
     * @property {object} organization tha organization basic details (id and name)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        cart: {
            organizationID: "",
            outletID: "",
            allergies: [],
            pvcRecyclingTax: 0.0,
            comments: "",
            equipment: {
                id: "",
                label: ""
            },
            items: [],
            type: "DINE_IN",
            persons: 1,
            paymentMethod: "card",
            isPaid: false,
            loyaltyPointsEarned: 0,
            quantitiesSum: 0,
            totalAmmount: 0,
            discount: {
                loyalty: null,
                coupon: null
            },
            taxPercent: 0
            // Initialize other properties as needed
        },
        sections: [],
        outlets: [],
        equipments: [],
        menus: [],
        types: [],
        currency: "€",
        outletURL: ""
    });

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [sendOrder, setSendOrder] = useState(false);
    const [isShowItemDetails, setIsShowItemDetails] = useState(false);
    const [filterItems, setFilterItems] = useState("");

    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        id: "",
        item: null,
        cartIndex: -1,
        cartItemDefault: null
    });

    function addItemToCartCallback(item, modifiersPrice) {
        // outletId, organizationId, item, modifiersPrice, pvcRecyclingTax)
        // dispatch(addItemToCart(data.outlet.id, data.organization.id, cartItem, modifiersPrice));
        let newCart = data.cart;
        // Check if the item already exists in the cart
        const existingItemIndex = newCart?.items.findIndex(cartItem =>
            cartItem.id === item?.id &&
            cartItem.name === item?.name &&
            cartItem.nameEn === item?.nameEn &&
            cartItem.notes === item?.notes &&
            cartItem.price === item?.price &&
            cartItem.pvcRecyclingTax === item?.pvcRecyclingTax &&
            cartItem?.modifierListIds.length === item?.modifierListIds.length && // Check lengths first

            // Compare each modifierListId separately
            cartItem?.modifierListIds.every((modifier, index) => {
                return modifier.modifierListId === item?.modifierListIds[index].modifierListId &&
                    // Check if modifiers array lengths match
                    cartItem.modifierListIds[index].modifiers.length === item.modifierListIds[index]?.modifiers?.length &&
                    // Compare each modifier object in the modifiers array
                    cartItem.modifierListIds[index].modifiers.every((cartModifier, modifierIndex) =>
                        cartModifier.id === item.modifierListIds[index].modifiers[modifierIndex].id &&
                        cartModifier.price === item.modifierListIds[index].modifiers[modifierIndex].price
                    )
            })
        );

        if (existingItemIndex !== -1) {
            // Item exists, increase the quantity
            newCart.items[existingItemIndex].quantity += item.quantity;
        } else {
            // Item does not exist, add as new item
            newCart.items.push(item);
        }
        // update analytics fields
        newCart.totalAmmount += calculateTotalAmount(newCart);
        newCart.quantitiesSum += + calculateQuantitiesSum(newCart);
        newCart.loyaltyPointsEarned += calculateLoyaltyPointsEarn(newCart);

        setData({
            ...data,
            cart: newCart
        });

        setSelectedItem(null);
        setSelectedCategory(null);
        setSelectedParentCategory(null);
        setSelectedMenu(null);
    }

    useEffect(() => {
        if (isFirstTime)
            getOrder();
        setIsFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [selectedMenu, setSelectedMenu] = useState(null);
    const [selectedParentCategory, setSelectedParentCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showCheckout, setShowCheckout] = useState(false);

    function handleMenuSelect(menu) {
        setSelectedMenu(menu.id);
        if (data.menus?.filter((men) => men.id === menu.id)[0]?.parentCategories?.length === 1)
            handleParentCategorySelect(data.menus?.filter((men) => men.id === menu.id)[0]?.parentCategories[0]);
        else
            setSelectedParentCategory(null);
        setSelectedCategory(null);
        setSelectedItem(null);
        setIsShowItemDetails(false);
    }

    function handleParentCategorySelect(parentCategory) {
        setSelectedParentCategory(parentCategory.id);
        if (data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCat) => parentCat.id === parentCategory.id)[0]?.categories?.length === 1)
            handleCategorySelect(data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCat) => parentCat.id === parentCategory.id)[0]?.categories[0]);
        else
            setSelectedCategory(null);
        setSelectedItem(null);
        setIsShowItemDetails(false);
    }

    function handleCategorySelect(category) {
        setSelectedCategory(category.id);
        // Reset other selections
        if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories?.find((parentCategory) => parentCategory.id === selectedParentCategory)?.categories?.find((cat) => cat.id === category.id)?.items?.length === 1) {
            handleItemSelect(data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories?.find((parentCategory) => parentCategory.id === selectedParentCategory)?.categories?.find((cat) => cat.id === category.id)?.items);
            setIsShowItemDetails(true);
        } else {
            setSelectedItem(null);
            setIsShowItemDetails(false);
        }
    }

    function handleItemSelect(isOpen, item, cartIndex = -1, cartItemDefault = null) {
        setSelectedItem(item ? item.id : null);
        setIsShowItemDetails(isOpen);
        setIsShowItemDetails(isOpen);
        setDialog({
            ...dialog,
            id: item? item.id: null,
            item: item,
            cartIndex: cartIndex,
            cartItemDefault: cartItemDefault
        });
    }

    function handleBackFromCheckOut() {
        setSelectedMenu(null);
        setSelectedParentCategory(null);
        setSelectedCategory(null);
        setSelectedItem(null);
        setIsShowItemDetails(false);
        setShowCheckout(false);
    }

    /**
     * The rest endpoint to get new order basic details
     */
    function getOrder() {
        orderApi.fetchNew().then((r) => {
            if (r.data.code !== "EXCEPTION" && r.data.code !== "INVALID") {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            sections: r.data.returnobject.sections,
                            outlet: r.data.returnobject.outlet,
                            equipments: r.data.returnobject.equipments,
                            menus: r.data.returnobject.menus,
                            types: r.data.returnobject.types,
                            currency: r.data.returnobject.currency,
                            outletURL: r.data.returnobject.outletURL
                        })
                    })
                    .then(() => {
                        if (data.menus.length === 1)
                            setSelectedMenu(data.menus[0].id);
                        else
                            setSelectedMenu(null);
                    })
                    .then(() => {
                        getPDADisplay();
                    })
            }
        }).catch((e) => {
            // console.log(e);
        })
    }

    // ========================= Order Type
    const [orderTypeAnchorEl, setOrderTypeAnchorEl] = useState(null);

    function updateOrderTypeOpenCallback(event) {
        setOrderTypeAnchorEl(event.currentTarget);
    };

    function updateOrderTypeCloseCallback() {
        setOrderTypeAnchorEl(null);
    };

    function updateOrderTypeCallback(newValue) {
        formValChangeWithParentElementWithNameAndValue("cart.type", newValue, data, setData);
        // dispatch(updateCartPaymentMethod(data.outlet.id, [newValue]));
        // Optionally close the popover
        updateOrderTypeCloseCallback();
    };

    const openOrderType = Boolean(orderTypeAnchorEl);

    // ========================= PERSONS 
    const [personsAnchorEl, setPersonsAnchorEl] = useState(null);

    function updatePersonsOpenCallback(event) {
        setPersonsAnchorEl(event.currentTarget);
    };

    function updatePersonsCloseCallback() {
        setPersonsAnchorEl(null);
    };

    function updatePersonsCallback(newValue) {
        formValChangeWithParentElementWithNameAndValue("cart.persons", newValue, data, setData);
        // Optionally close the popover
        // updatePersonsCloseCallback();
    };

    function removeItemFromCart(itemID, indexInCart) {
        let newCart = data.cart;
        if (indexInCart > -1 && newCart.items[indexInCart].id === itemID) newCart?.items.splice(indexInCart, 1); // 2nd parameter means remove one item only

        setData({
            ...data,
            cart: newCart
        })
    }

    function updateItemInCart(itemID, newItem, indexInCart) {
        console.log(itemID, newItem, indexInCart)
        let newCart = data.cart;
        let existingItems = newCart?.items;
        if (existingItems[indexInCart]?.id === itemID)
            existingItems[indexInCart] = newItem;

        newCart.items = existingItems;
        setData({
            ...data,
            cart: newCart
        })

    }

    const openPersons = Boolean(personsAnchorEl);

    function addOrder(cart) {
        if (cart?.id === null || cart?.id === undefined || cart?.id === "") {
            setSendOrder(true);
            let order = {
                organizationID: organizationID,
                outletID: outletID,
                equipmentID: equipmentID,
                customer: null, //fill in from backend
                cart: cart?.items.map(item => ({
                    customer: null,
                    id: item.id,
                    name: item.name,
                    quantity: item.quantity,
                    price: item.price,
                    pvcRecyclingTax: item.pvcRecyclingTax,
                    notes: item.notes,
                    done: false,
                    modifiers: item.modifierListIds.flatMap(modifierList =>
                        modifierList?.modifiers.map(modifier => ({
                            id: modifier.id,
                            name: modifier.name, // This needs a lookup or mapping
                            price: modifier?.price
                        }))
                    ),
                    unavailable: false,
                    unavailableQuantity: 0


                })),
                allergies: cart?.allergies,
                pvcRecyclingTax: cart?.pvcRecyclingTax,
                comments: cart?.comments,
                discount: cart?.discount,
                loyaltyPointsEarned: calculateLoyaltyPointsEarn(cart),
                quantitiesSum: calculateQuantitiesSum(cart),
                totalAmmount: calculateTotalAmount(cart),
                status: "PENDING",
                type: cart?.type,
                paymentMethod: "cash",
                persons: cart?.persons,
                isPaid: false,
                currencyCode: data.outlet?.settings?.currency,
                vivaPaymentsDetails: {
                    token: "",
                    transactionId: "",
                    email: "",
                    amount: calculateTotalAmount(cart),
                    orderCode: "",
                    statusId: "",
                    fullName: "",
                    insDate: "",
                    cardNumber: "",
                    currencyCode: "",
                    customerTrns: "",
                    merchantTrns: "",
                    transactionTypeId: null,
                    recurringSupport: false,
                    totalInstallments: 0,
                    cardCountryCode: "",
                    cardIssuingBank: "",
                    currentInstallment: 0,
                    cardUniqueReference: "",
                    cardTypeId: 0,
                    digitalWalletId: 0,
                    orderHasBeenSentByScheduledJob: false,
                }
            }
            orderApi.create(order).then((r) => {
                if (r.data.code === "SUCCESS") {
                    handleReset();
                    getPDADisplay();
                    setSendOrder(false);
                }
            }).catch((e) => {
                setAlertBox({
                    isOpen: true,
                    message: "Fail to create order",
                    backgroundColor: "#ef5350",
                });
                setSendOrder(false);
            });
        } else {
        }
    }

    useEffect(() => {
        if (isAddOrdersPage)
            getOrder();
    }, [isAddOrdersPage]);

    // ===================================== BACK

    function goBack(from) {
        if (from === "items") {
            setSelectedCategory(null);
            if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories?.find((parentCategory) => parentCategory.id === selectedParentCategory)?.categories?.length === 1) {
                setSelectedParentCategory(null);
                if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories.length === 1)
                    setSelectedMenu(null);
            }
        } else if (from === "categories") {
            setSelectedParentCategory(null);
            if (data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories.length === 1)
                setSelectedMenu(null);
        } else if (from === "parentCategories")
            setSelectedMenu(null);
    }
    // ===============================================================================RENDER

    return (
        <>
            {!isShowItemDetails &&
                <>
                    <Grid item xs={3} md={3}>
                        <CustomIconButton
                            icon={<ArrowBackIcon />}
                            sx={orderBackBtnStyle()}
                            onClick={() => handleReset()}
                            label={t('pda.backButtonAddOderDialog')}
                        />
                    </Grid>

                    <Grid item xs={8} md={8}>
                        <CustomTypography variant="body2" text={t('pda.order.create')} sx={pastOrderViewHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <OutlinedInput
                            fullWidth
                            id="input-search-header"
                            placeholder={t('actions.search')}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon fontSize="small" />
                                </InputAdornment>
                            }
                            onChange={(event) => setFilterItems(event.target.value)}
                        />
                    </Grid>

                    <CustomGrid role="main" id="order-list" sx={mainTag()}>
                        <Grid container gap={1}>
                            {(filterItems === "" && !showCheckout && selectedMenu === null && selectedParentCategory === null && selectedCategory === null && selectedItem === null) &&
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={t('cart.menus')} sx={{ textAlign: "left", paddingLeft: "20px", fontWeight: "bold" }} />
                                    <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                        <Grid container gap={2}>
                                            {data.menus?.map((menu) =>
                                                <Grid>
                                                    <Card variant="outlined" onClick={() => handleMenuSelect(menu)}
                                                        sx={{
                                                            width: "fit-content",
                                                            boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                        }}
                                                    >
                                                        <CardActionArea sx={{
                                                            ...cardItemContentActionArea(),
                                                            minWidth: "80px",
                                                            minHeight: "50px",
                                                            padding: "0 5px"
                                                        }}>
                                                            <CustomTypography variant="body2" text={menu?.name} />
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Stack>
                                </Grid>
                            }

                            {(filterItems === "" && !showCheckout && selectedMenu !== null && selectedParentCategory === null && selectedCategory === null && selectedItem === null) &&
                                <>
                                    <Grid item xs={1} md={1}>
                                        <CustomIconButton
                                            icon={<ArrowLeftIcon />}
                                            // sx={orderBackBtnStyle()}
                                            onClick={() => goBack("parentCategories")}
                                        />
                                    </Grid>

                                    <Grid item xs={9} md={9}>
                                        <CustomTypography variant="body2" text={`${t('cart.parentCategories')}`} sx={{ textAlign: "left", paddingLeft: "50px", fontWeight: "bold" }} />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                            <Grid container gap={2}>
                                                {data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.map((parentCategory) =>
                                                    <Grid>
                                                        <Card variant="outlined" onClick={() => handleParentCategorySelect(parentCategory)}
                                                            sx={{
                                                                width: "fit-content",
                                                                boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                            }}
                                                        >
                                                            <CardActionArea sx={{
                                                                ...cardItemContentActionArea(),
                                                                minWidth: "80px",
                                                                minHeight: "50px",
                                                                padding: "0 5px"
                                                            }}>
                                                                <CustomTypography variant="body2" text={parentCategory?.name} />
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </>
                            }

                            {(filterItems === "" && !showCheckout && selectedMenu !== null && selectedParentCategory !== null && selectedCategory === null && selectedItem === null) &&
                                <>
                                    <Grid item xs={1} md={1}>
                                        <CustomIconButton
                                            icon={<ArrowLeftIcon />}
                                            // sx={orderBackBtnStyle()}
                                            onClick={() => goBack("categories")}
                                        />
                                    </Grid>

                                    <Grid item xs={9} md={9}>
                                        <CustomTypography variant="body2" text={`${t('cart.categories')}`} sx={{ textAlign: "left", paddingLeft: "50px", fontWeight: "bold" }} />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                            <Grid container gap={2}>
                                                {data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCategory) => parentCategory.id === selectedParentCategory)[0]?.categories?.map((category) =>
                                                    <Grid>
                                                        <Card variant="outlined" onClick={() => handleCategorySelect(category)}
                                                            sx={{
                                                                width: "fit-content",
                                                                boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                            }}
                                                        >
                                                            <CardActionArea sx={{
                                                                ...cardItemContentActionArea(),
                                                                minWidth: "80px",
                                                                minHeight: "50px",
                                                                padding: "0 5px"
                                                            }}>
                                                                <CustomTypography variant="body2" text={category?.name} />
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </>
                            }

                            {(filterItems === "" && !showCheckout && selectedMenu !== null && selectedParentCategory !== null && selectedCategory !== null && selectedItem === null) &&
                                <>
                                    <Grid item xs={1} md={1}>
                                        <CustomIconButton
                                            icon={<ArrowLeftIcon />}
                                            // sx={orderBackBtnStyle()}
                                            onClick={() => goBack("items")}
                                        />
                                    </Grid>

                                    <Grid item xs={9} md={9}>
                                        <CustomTypography variant="body2" text={`${t('cart.items')}`} sx={{ textAlign: "left", paddingLeft: "50px", fontWeight: "bold" }} />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                            <Grid container gap={2}>
                                                {data.menus?.find((menu) => menu.id === selectedMenu)?.parentCategories?.find((parentCategory) => parentCategory.id === selectedParentCategory)?.categories?.find((category) => category.id === selectedCategory)?.items?.map((item) =>
                                                    <Grid>
                                                        <Card variant="outlined" onClick={() => handleItemSelect(true, item)}
                                                            sx={{
                                                                width: "fit-content",
                                                                boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                            }}
                                                        >
                                                            <CardActionArea sx={{
                                                                ...cardItemContentActionArea(),
                                                                minWidth: "80px",
                                                                minHeight: "50px",
                                                                padding: "0 5px"
                                                            }}>
                                                                <CustomTypography variant="body2" text={item?.name} />
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </>
                            }

                            {(filterItems !== "" && !showCheckout) &&
                                <Grid item xs={12} md={12}>
                                    <Stack direction="row" sx={{ ...getOrderEquipmentAdd(), placeContent: "center" }}>
                                        <Grid container gap={2}>
                                            {getItems(data.menus, filterItems)?.map((item) =>
                                                <Grid>
                                                    <Card variant="outlined" onClick={() => handleItemSelect(true, item)}
                                                        sx={{
                                                            width: "fit-content",
                                                            boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                                                        }}
                                                    >
                                                        <CardActionArea sx={{
                                                            ...cardItemContentActionArea(),
                                                            minWidth: "80px",
                                                            minHeight: "50px",
                                                            padding: "0 5px"
                                                        }}>
                                                            <CustomTypography variant="body2" text={item?.name} />
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Stack>
                                </Grid>
                            }

                            {showCheckout &&
                                <>
                                    <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                                        <CustomIconButton
                                            icon={<ArrowBackIcon />}
                                            sx={orderBackBtnStyle()}
                                            onClick={() => handleBackFromCheckOut()}
                                            label={t('cart.backButton')}
                                        />
                                    </Grid>

                                    <Grid item xs={4.6} x={{ display: "flex", float: "right" }}>
                                        <Grid container gap={1}>
                                            <Grid item xs={3}>
                                                <PlaceIcon color="black" sx={orderIconStyle()} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Grid container sx={{ marginLeft: "10px" }}>
                                                    <Grid item xs={12}>
                                                        <CustomTypography variant="body2" text={t('cart.orderType.label')} sx={{ ...orderAddTextStyleLabel(), textAlign: "left" }} />
                                                    </Grid>
                                                    <Grid item xs={"auto"}>
                                                        <CustomTypography variant="body2" text={t(`cart.orderType.${data.cart?.type}`)} sx={orderAddTextStyle()} />
                                                    </Grid>
                                                    <Grid item xs={"auto"}>
                                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateOrderTypeOpenCallback} /></Tooltip>
                                                        <OrderTypePopover
                                                            anchorEl={orderTypeAnchorEl}
                                                            open={openOrderType}
                                                            handleClose={updateOrderTypeCloseCallback}
                                                            inputValue={data.cart?.type}
                                                            handleUpdate={updateOrderTypeCallback}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3.4}>
                                        <Grid container sx={{ paddingLeft: "10%" }}>
                                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                                <Person sx={orderIconStyle} />
                                            </Grid>

                                            <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                                                <Grid container sx={{ marginLeft: "30px", textAlign: "left" }}>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="body2" text={t('cart.persons')} sx={orderAddTextStyleLabel()} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                                        <CustomTypography variant="body2" text={data.cart?.persons} sx={{ ...orderAddTextStyle(), width: "auto" }} />
                                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updatePersonsOpenCallback} /></Tooltip>
                                                        <CheckoutPersonsPopover
                                                            anchorEl={personsAnchorEl}
                                                            open={openPersons}
                                                            handleClose={updatePersonsCloseCallback}
                                                            inputValue={data.cart?.persons}
                                                            handleUpdate={updatePersonsCallback}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Grid container sx={{ paddingLeft: "10%" }}>
                                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                                <PlaceIcon color="black" sx={orderIconStyle()} />
                                            </Grid>

                                            <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                                                <Grid container sx={{ marginLeft: "30px", textAlign: "left" }}>
                                                    <Grid item xs={12}>
                                                        <CustomTypography variant="body2" text={t('cart.equipmentLabel')} sx={orderAddTextStyleLabel()} />
                                                    </Grid>
                                                    <Grid item xs={"auto"}>
                                                        <CustomTypography variant="body2" text={equipmentLabel} sx={orderAddTextStyle()} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

                                    <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                                        <Grid container gap={1}>
                                            <Grid item xs={12} md={12}>
                                                <InputLabel sx={orderCartTitleTextStyle()}>{t('cart.label')}</InputLabel>
                                            </Grid>

                                            {data.cart?.items?.map((cartItem, index) =>
                                                <Grid item xs={12} md={12}>
                                                    <CustomSingleCardItem
                                                        outlet={data.outlet}
                                                        cartItem={cartItem}
                                                        currency={data.currency}
                                                        cart={data.cart}
                                                        removeItemFromCart={removeItemFromCart}
                                                        updateItemInCart={updateItemInCart}
                                                        indexInCart={index}
                                                        handleItemDialogState={() => handleItemSelect(true, cartItem, index, cartItem)}

                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>

                                    {data.cart?.pvcRecyclingTax > 0 &&
                                        <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                            <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                                <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                                    <FontAwesomeIcon key={"icon-env-tax"} icon={faLeaf} style={orderItemFontAwesomeIconStyle()} />
                                                </Grid>

                                                <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                                    <CustomTypography variant="body2" text={t('checkout.pvcRecyclingTax')} sx={orderItemTaxTextStyle()} />
                                                </Grid>

                                                <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                                    <CustomTypography variant="body2" text={priceTextWithCurrency(data.outlet?.currency, data.cart?.pvcRecyclingTax)} sx={orderItemTaxPriceTextStyle()} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                    <Divider light flexItem sx={{ borderRightWidth: 5, width: "80%", marginLeft: "11%", marginTop: "20px" }} />

                                    {data.cart?.taxPercent > 0 &&
                                        <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                            <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                                <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                                    {/* <ReceiptIcon sx={orderItemMuiIconStyle} /> */}
                                                </Grid>

                                                <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                                    <CustomTypography variant="body2" text={`${t('checkout.tax')}  (${data.cart?.taxPercent}%)`} sx={orderItemTotalTextStyle()} />
                                                </Grid>

                                                <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                                    <CustomTypography variant="body2" text={priceTextWithCurrency(data.currency, calculateTax(data.cart))} sx={orderItemTotalPriceTextStyle()} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                    <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                        <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                                <ReceiptIcon sx={orderItemMuiIconStyle} />
                                            </Grid>

                                            <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                                                <CustomTypography variant="body2" text={t('checkout.paymentTotal')} sx={orderItemTotalTextStyle()} />
                                            </Grid>

                                            <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                                                <CustomTypography variant="body2" text={priceTextWithCurrency(data.outlet?.currency, calculateTotalAmount(data.cart))} sx={orderItemTotalPriceTextStyle()} />
                                            </Grid>

                                            {/* <Grid item xs={12} md={12} sx={{ alignSelf: "center", marginTop: "20px" }}>
                                                <CustomTypography variant="body2" text={`${t('checkout.loyaltyPointsToEarn')} ${calculateLoyaltyPointsEarn(data.cart)} ${t('checkout.points')}`} sx={{ ...orderItemTotalPriceTextStyle(), fontWeight: "200" }} />
                                            </Grid> */}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={12} sx={{ marginTop: "50px" }}>
                                        <Grid container sx={{ paddingLeft: "10%" }}>
                                            <Grid item xs={2} md={2}>
                                                <MessageIcon sx={orderIconStyle} />
                                            </Grid>
                                            <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                                                <CustomTypography variant="body2" text={t('checkout.comments')} sx={orderItemTotalTextStyle()} />
                                            </Grid>
                                            <Grid item xs={12} md={12} sx={{ alignSelf: "center" }}>
                                                <CustomTextArea
                                                    placeholder={t('checkout.requestPlaceholder')}
                                                    value={data.cart.comments}
                                                    inputProps={{ maxLength: 100 }}
                                                    maxLength={100}
                                                    onChange={(event) => {
                                                        let newValue = event.target.value.slice(0, 100);
                                                        formValChangeWithParentElementWithNameAndValue("cart.comments", newValue, data, setData);
                                                    }}
                                                    variant="outlined"
                                                    sx={{ borderColor: "lightgrey", paddingTop: "15px" }}
                                                    endDecorator={
                                                        <Box sx={checkoutCommentTextareaBoxStyle()}>
                                                            <Divider flexItem={true} />
                                                            <span style={{ color: data.cart?.comments?.length === 100 ? "red" : "initial" }}>
                                                                {`${data.cart?.comments?.length}  `}
                                                            </span>
                                                            <span style={{ ...checkoutCommentTextareaBoxInfoCharStyle(), color: data.cart?.comments?.length === 100 ? "red" : "initial" }}>
                                                                {data.cart?.comments?.length === 1 ? t('checkout.requestCharLimit') : t('checkout.requestCharsLimit')}
                                                            </span>
                                                            <span style={{ color: data.cart?.comments?.length === 100 ? "red" : "initial" }}>
                                                                {` /${requestTextFieldLimit}`}
                                                            </span>

                                                        </Box>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </CustomGrid>

                    <Fab
                        color="primary"
                        aria-label="add"
                        disabled={sendOrder}
                        sx={fabStyle("absolute")}
                        onClick={() => setShowCheckout(true)}
                    >
                        <Badge badgeContent={calculateCartChipQuantity(data.cart?.items)} color="primary" style={{ color: 'blue' }}>
                            {!showCheckout &&
                                <ShoppingCart sx={{ color: "white" }} onClick={() => setShowCheckout(true)} />
                            }
                            {showCheckout &&
                                <SendIcon sx={{ color: "white" }} onClick={() => addOrder(data.cart)} />
                            }
                        </Badge>
                        {/* {t('checkout.buttons.sendOrder')} */}
                    </Fab>
                </>
            }

            {(isShowItemDetails && selectedItem !== null) &&
                <CustomPDAItemCard
                    organizationID={data.organization?.id}
                    outletURL={data.outletURL}
                    itemID={dialog.id !== null ? dialog.id : selectedItem}
                    isOpen={dialog.isOpen}
                    action={(dialog.cartIndex !== null && dialog.cartIndex !== -1) ? updateItemInCart : addItemToCartCallback}
                    handleOpen={() => { handleItemSelect(false, null) }}
                    handleReset={() => { handleItemSelect(false, null) }}
                    menuConfiguration={selectedMenu ? data.menus?.find(menu => menu.id === selectedMenu)?.settings : data.menus[0]?.settings}
                    outletAllowComments={false}
                    enableCart={true}
                    currency={data.currency}
                    cartIndex={dialog.cartIndex}
                    cartItemDefault={dialog.cartItemDefault}
                    extraDetails={dialog.id !== null ? undefined : {
                        menuID: selectedMenu,
                        menuName: data.menus?.find(menu => menu.id === selectedMenu)?.nameEN,
                        parentCategoryID: selectedParentCategory,
                        parentCategoryName: data.menus?.find(menu => menu.id === selectedMenu)?.parentCategories?.find(parentCategory => parentCategory.id === selectedParentCategory)?.nameEN,
                        regularItem: true,
                        categoryID: selectedCategory,
                        categoryName: data.menus?.filter((menu) => menu.id === selectedMenu)[0]?.parentCategories?.filter((parentCategory) => parentCategory.id === selectedParentCategory)[0]?.categories?.filter((category) => category.id === selectedCategory)[0]?.nameEN,
                    }}
                />
            }
        </>
    );
}

export default CustomPDAAddOrderGrid;