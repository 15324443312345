import { faCreditCard, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab';
import { Checkbox, Divider, FormControlLabel, Grid } from '@mui/material';
import CustomSinglePastOrderCardItemPaymentHistory from 'components/Card/order/CustomSinglePastOrderCardItemPaymentHistory';
import CustomChip from 'components/Chip/CustomChip';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDateTimeFormatter } from 'constants/calendar';
import { useTranslation } from 'react-i18next';
import { orderActionsDetailsHistoryDateTextStyle, orderActionsHistoryCardStyle, orderFontAwesomeIconStyle, orderPDAActionsDetailsHistoryEmptyTextStyle } from 'themes/defaultThemes';
import { dateTimeFormatter } from 'utils/functions';

/**
 * `CustomOrderPaymentActionsHistoryTimeline` Component
 * 
 * Renders a visual timeline representing the history of a order payment action.
 * 
 * This component uses Material-UI's `Timeline` components to display each significant
 * event in the lifecycle of a subscription, such as changes in subscription plans,
 * charges, and overall subscription status.
 * 
 * Props:
 * - `timelineDetails`: Array of objects representing the timeline events. Each object
 *    should include details like payment method, item, quantity, dateTime, and other relevant information.
 * - `sx`: Custom styles to apply to the timeline component.
 * 
 * @param {Object} props Component props
 * @returns {React.Component} CustomSubscriptionTimeline component
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomOrderPaymentActionsHistoryTimeline({ timelineDetails, currency, items }) {
    const { t } = useTranslation();

    return (
        <>
            {timelineDetails?.length > 0 &&
                <Timeline
                    sx={{

                        padding: 0,
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                            // marginLeft: "20px"
                        },
                    }}>
                    {timelineDetails?.reverse()?.map((timelineDetail) =>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot>
                                    {timelineDetail?.paymentMethod === "cash" &&
                                        <FontAwesomeIcon key={"icon-cash"} icon={faMoneyBill} style={orderFontAwesomeIconStyle()} />
                                    }
                                    {timelineDetail?.paymentMethod === "card" &&
                                        <FontAwesomeIcon key={"icon-card"} icon={faCreditCard} style={orderFontAwesomeIconStyle()} />
                                    }
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>

                            <TimelineContent sx={orderActionsHistoryCardStyle()}>
                                <Grid container spacing={2}>
                                    {timelineDetail?.items?.map((timelineItem) =>
                                        <Grid item xs={12} md={12}>
                                            <CustomSinglePastOrderCardItemPaymentHistory
                                                item={items?.find((item, index) => item.id === timelineItem.itemID && timelineItem.indexInOrderCart === index)}
                                                currency={currency}
                                                cartItem={timelineItem}
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid item xs={12} md={12} sx={{ marginTop: "40px" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={timelineDetail?.treat}
                                                color="primary"
                                            />}
                                        label={t('pda.payment.treat')}
                                    />

                                    {timelineDetail?.treat &&
                                        <CustomChip label={timelineDetail.employeeID} rowIndex={0} />
                                    }

                                </Grid>

                                <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", margin: "5px 0px" }} />
                                <CustomTypography variant="body2" text={dateTimeFormatter(timelineDetail?.dateTime, getDateTimeFormatter())} sx={orderActionsDetailsHistoryDateTextStyle()} />
                            </TimelineContent>
                        </TimelineItem>
                    )
                    }
                </Timeline>
            }

            {(timelineDetails === undefined || timelineDetails === null || timelineDetails?.length === 0) &&
                <CustomTypography variant="body2" text={t('pda.payment.emptyPaymentHistory')} sx={orderPDAActionsDetailsHistoryEmptyTextStyle()} />
            }
        </>
    );
}

export default CustomOrderPaymentActionsHistoryTimeline;