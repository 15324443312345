import CloseIcon from '@mui/icons-material/Close';
import EuroIcon from '@mui/icons-material/Euro';
import {
    Box, Button, Card, CardMedia, Checkbox, DialogActions, Divider, FormControl, FormControlLabel, FormGroup, Grid,
    Radio, RadioGroup, TextareaAutosize, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguageFromURL } from 'utils/language';
import itemImage from 'assets/images/item.jpg';
import { ArrowBackIcon, InfoIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import GroupedItemButtons from 'components/Button/GroupedItemButtons';
import CustomChip from 'components/Chip/CustomChip';
import CustomTypography from 'components/Typography/CustomTypography';
import CustomTypographyWithIcon from 'components/Typography/CustomTypographyWithIcon';
import { requestTextFieldLimit } from 'constants/textFieldLimit';
import { dividerStyle, itemNutrientCardTextStyle, itemNutrientDetailGramsTitleStyle, itemNutrientDetailTextStyle, itemNutrientDetailTitleStyle, itemNutrientSubDetailTitleStyle, itemNutrientText, justifyContentCenter, lumanosimoTitleStyle, menuItemDescriptionHeaderTextStyle, menuItemHeaderTextStyle, menuItemPriceHeaderTextStyle, menuItemPriceIconStyle, orderItemButtonStyle, requestTextareaBoxStyle, textAlignLeft, textAlignRight } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { findCurrency, replaceLastTwoChars } from 'utils/functions';
import { getAllergies } from 'utils/functions/allergy';
import { getExtraIcons } from 'utils/functions/extraIcon';
import WebFont from 'webfontloader';
import { getDefaltPhoto } from 'utils/functions/assets';

/**
 * The CustomPreviewItemDialog, that display a customized dialog
 * 
 * @param {string} id the dialog id
 * @param {string} title the dialog title
 * @param {string} message the dialog message
 * @param {string} cancelLabel the dialog cancel label
 * @param {string} actionLabel the dialog action label
 * @param {function} action the function that handles the dialog action button click
 * @param {function} handleOpen the function that handles the dialog open state
 *
 * @returns {JSX.Element} A React component that renders a dialog to view item details.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPreviewItemDialog({ menuConfiguration, currency = "", iconPositionStart = false, enableCart = true, outletAllowComments = true }) {
    const { t } = useTranslation();

    const [arrayOfPhotos, setArrayOfPhotos] = useState([]);
    const [viewNutrients, setViewNutrients] = useState(false);
    const [item, setItem] = useState({
        id: "test",
        name: "Espresso",
        nameEN: "Espresso",
        description: "A bold symphony of intense flavors and rich aroma, served in a single, powerful shot for the ultimate coffee experience.",
        remark: "",
        extraNote: "",
        photos: ["e0c92287-e3b8-4d6b-9a87-a1f20082fb6d.jpeg"],
        price: 30,
        modifierLists: [
            {
                "id": "63ba9bd4be1c1251a8516357",
                "name": "Select Sugar",
                "nameEN": null,
                "minModifiers": null,
                "maxModifiers": 1,
                "allowMultipleSelection": false,
                "enabled": null,
                "modifiers": [
                    {
                        "id": "63ba9d69be1c1251a8516359",
                        "name": "Plain",
                        "nameEN": "Plain",
                        "description": "",
                        "remark": "",
                        "extraNote": "",
                        "photos": [],
                        "price": 0,
                        "loyaltyPoints": 0,
                        "allergies": [],
                        "extraIcons": [],
                        "nutrients": [],
                        "sortage": 0,
                        "pvcRecyclingTax": 0,
                        "show": true,
                        "itemVatType": "NONE",
                        "available": false
                    },
                    {
                        "id": "63ba9d3bbe1c1251a8516358",
                        "name": "Medium",
                        "nameEN": "Medium",
                        "description": "",
                        "remark": "",
                        "extraNote": "",
                        "photos": [],
                        "price": 0,
                        "loyaltyPoints": 0,
                        "allergies": [],
                        "extraIcons": [],
                        "nutrients": [],
                        "sortage": 0,
                        "pvcRecyclingTax": 0,
                        "show": true,
                        "itemVatType": "NONE",
                        "available": false
                    }
                ]
            },
            {
                "id": "64d79f8171606b250dce667d",
                "name": "Extra",
                "nameEN": null,
                "minModifiers": 0,
                "maxModifiers": 1,
                "allowMultipleSelection": true,
                "enabled": null,
                "modifiers": [
                    {
                        "id": "64d79f0571606b250dce6678",
                        "name": "Chocolate",
                        "nameEN": "Chocolate",
                        "description": "",
                        "remark": "",
                        "extraNote": "",
                        "photos": [],
                        "price": 0,
                        "loyaltyPoints": 0,
                        "allergies": [],
                        "extraIcons": [],
                        "nutrients": [],
                        "sortage": 0,
                        "pvcRecyclingTax": 0,
                        "show": false,
                        "itemVatType": "NONE",
                        "available": false
                    },
                    {
                        "id": "64d79f1771606b250dce6679",
                        "name": "Cinnamon",
                        "nameEN": "Cinnamon",
                        "description": "",
                        "remark": "",
                        "extraNote": "",
                        "photos": [],
                        "price": 0,
                        "loyaltyPoints": 0,
                        "allergies": [],
                        "extraIcons": [],
                        "nutrients": [],
                        "sortage": 0,
                        "pvcRecyclingTax": 0,
                        "show": false,
                        "itemVatType": "NONE",
                        "available": false
                    }
                ]
            }
        ],
        "loyaltyPoints": 5,
        "allergies": [
            "milk"
        ],
        "extraIcons": [
            "NEW"
        ],
        "nutrients": [
            {
                "grams": 30,
                "energy": 0,
                "calories": 3,
                "protein": 0,
                "carb": 0.5,
                "sugars": 0,
                "fat": 0.1,
                "sat_fat": 0,
                "trans_fat": 0,
                "fiber": 0,
                "salt": 0
            }
        ],
        "sortage": 2,
        "pvcRecyclingTax": 0,
        "show": true,
        "itemVatType": "TWENTY_FOUR_PERCENT",
        "available": false
    });

    const [cartItem, setCartItem] = useState({
        id: "",
        name: "",
        quantity: 1,
        notes: "",
        price: 0,
        totalPrice: 0,
        pvcRecyclingTax: 0,
        loyaltyPointsEarned: 0,
        modifierListIds: []
    });

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Lumanosimo']
            }
        });
        initItem(item);
    }, []);

    /**
     * Function that initializes the modifierLists for cart item.
     * @param {*} item 
     */
    function initItem(itemTest) {
        let photos = [];
        photos.push(
            <CardMedia
                sx={{ height: 340 }}
                image={getDefaltPhoto(itemTest.photos[0])}
                title={itemTest.name}
            />
        )
        setArrayOfPhotos(photos);

        let modifierLists = [];
        let modifiersPrice = 0; // Initialize modifiersPrice

        itemTest.modifierLists.map((modifierList) => {
            // Initialize an array to hold the modifiers for this list
            let modifiers = [];

            if (modifierList.allowMultipleSelection) {
                // If multiple selection is allowed, don't add any default modifiers
                modifiers = [];
            } else {
                // If only single selection is allowed, add the first modifier
                let firstModifier = {
                    id: modifierList.modifiers[0].id,
                    name: modifierList.modifiers[0].name,
                    nameEn: modifierList.modifiers[0].nameEn,
                    price: modifierList.modifiers[0].price
                };
                modifiers.push(firstModifier);

                // Add the price of the first modifier to modifiersPrice
                modifiersPrice += firstModifier.price;
            }

            // Push the modifier list with its modifiers to modifierLists
            modifierLists.push({
                modifierListId: modifierList.id,
                name: modifierList.name,
                namenameEN: modifierList.nameEN,
                modifiers: modifiers
            });
        });

        // Set cart item with totalPrice including modifiersPrice
        setCartItem({
            id: itemTest.id,
            name: itemTest.name,
            nameEN: itemTest.nameEN,
            quantity: 1,
            notes: "",
            price: itemTest.price,
            totalPrice: itemTest.price + modifiersPrice,
            pvcRecyclingTax: itemTest.pvcRecyclingTax,
            modifierListIds: modifierLists,
            loyaltyPointsEarned: itemTest.loyaltyPoints
        });
    }

    return (
        <Card sx={{ width: "500px", marginBottom: "50px", boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" }}>
            <CloseIcon sx={{ position: "absolute", right: "20px", background: "white", color: "grey", borderRadius: "15px", width: "15px", padding: "2px 5px", marginTop: "10px", top: 20 }} />

            <Grid>
                {viewNutrients &&
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} md={12} sx={textAlignLeft()}>
                                <CustomIconButton
                                    icon={<ArrowBackIcon />}
                                    // sx={authBackBtnStyle()}
                                    onClick={() => setViewNutrients(false)}
                                    label={undefined}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <CustomTypography
                                    variant="body2"
                                    text={t('item.nutrients.label')}
                                    sx={{ ...menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor }), ...lumanosimoTitleStyle() }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ ...textAlignLeft(), padding: "10px" }}>
                                <Grid container>
                                    <Grid container xs={5} spacing={1.65} direction="column">
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={{ ...itemNutrientDetailTitleStyle(), paddingTop: "30px" }}
                                                text={""} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.energy')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.calories')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.fat')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientSubDetailTitleStyle()}
                                                text={t('item.nutrients.sat_fatDetailsLabel')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientSubDetailTitleStyle()}
                                                text={t('item.nutrients.trans_fatDetailsLabel')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.carb')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientSubDetailTitleStyle()}
                                                text={t('item.nutrients.sugarsDetailsLabel')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientSubDetailTitleStyle()}
                                                text={t('item.nutrients.fiberDetailsLabel')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.protein')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.salt')} />
                                        </Grid>
                                        {/* <Grid item xs={1}>
                                                <CustomTypography
                                        variant="body2"
                                        text={t('item.nutrients.sodium')}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                        variant="body2"
                                        text={t('item.nutrients.vitaminA')}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                        variant="body2"
                                        text={t('item.nutrients.vitaminC')}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                        variant="body2"
                                        text={t('item.nutrients.calcium')}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                        variant="body2"
                                        text={t('item.nutrients.iron')}/>
                                            </Grid> */}
                                    </Grid>

                                    {item.nutrients.map((nutrient) =>
                                        <Grid container xs={3} spacing={2} direction="column">
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailGramsTitleStyle()}
                                                    text={`${t('item.nutrients.gramsDetailsLabel')} ${nutrient.grams} ${(nutrient.grams > -1) ? t('item.nutrients.gramsAfterLabel') : t('item.nutrients.gramsPortionLabel')} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.energy} ${(nutrient.energy > -1) ? t('item.nutrients.enrgeyAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.calories} ${(nutrient.calories > -1) ? t('item.nutrients.caloriesAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.fat} ${(nutrient.fat > -1) ? t('item.nutrients.fatAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.sat_fat} ${(nutrient.sat_fat > -1) ? t('item.nutrients.sat_fatAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.trans_fat} ${(nutrient.trans_fat > -1) ? t('item.nutrients.trans_fatAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.carb} ${(nutrient.carb > -1) ? t('item.nutrients.carbAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.sugars} ${(nutrient.sugars > -1) ? t('item.nutrients.sugarsAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.fiber} ${(nutrient.fiber > -1) ? t('item.nutrients.fiberAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.protein} ${(nutrient.protein > -1) ? t('item.nutrients.proteinAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.salt} ${(nutrient.salt > -1) ? t('item.nutrients.saltAfterLabel') : "-"} `} />
                                            </Grid>
                                            {/* 
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.sodium} ${(nutrient.sodium > -1) ? t('item.nutrients.sodiumAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.vitaminA} ${(nutrient.vitaminA > -1) ? t('item.nutrients.vitaminAAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={t('item.nutrients.vitaminC')} />
                                                    text={`${nutrient.vitaminA} ${(nutrient.vitaminA > -1) ? t('item.nutrients.vitaminAAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={t('item.nutrients.calcium')} />
                                                    text={`${nutrient.calcium} ${(nutrient.calcium > -1) ? t('item.nutrients.calciumAAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.iron} ${(nutrient.iron > -1) ? t('item.nutrients.ironAAfterLabel') : "-"} `} />
                                            </Grid>
                                        */}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {!viewNutrients &&
                    <>
                        {arrayOfPhotos?.length === 1 &&
                            <Grid item xs={12} md={12}>
                                {arrayOfPhotos[0]}
                            </Grid>
                        }

                        {arrayOfPhotos?.length > 1 &&
                            <Grid item xs={12} md={12}>
                                {/* <Carousel
                                    animation="slide"
                                    swipe={true}
                                    autoPlay={false}
                                    navButtonsAlwaysVisible={true}
                                    cycleNavigation={true}
                                    fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
                                    navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                        style: {
                                            borderRadius: 0,
                                            background: '#bdbdbd94',
                                            borderRadius: "30px",
                                            color: "black"
                                        }
                                    }}
                                    navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                                        style: {
                                            bottom: '0'
                                        }
                                    }}
                                    NextIcon={<ArrowRightIcon />}             // Change the "inside" of the next button 
                                    PrevIcon={<ArrowLeftIcon />}             // Change the "inside of the prev button
                                >
                                    {arrayOfPhotos}
                                </Carousel> */}
                            </Grid>
                        }

                        <Grid item xs={12} md={12}>
                            <Grid container sx={{ background: "white", padding: "10px" }}>
                                <Grid item xs={12} md={12} sx={{ ...textAlignLeft(), marginTop: item.photos?.length > 0 ? "20px" : 0 }}>
                                    {getExtraIcons(item?.extraIcons)}
                                    <CustomTypography
                                        variant="body2"
                                        text={item.name}
                                        sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} sx={textAlignLeft()}>
                                    {item.description?.length > 0 &&
                                        <CustomTypography
                                            variant="body2"
                                            text={item.description}
                                            sx={menuItemDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.itemDescFontSize, color: menuConfiguration?.itemDescColor })}
                                        />
                                    }
                                </Grid>

                                <Grid item xs={6} md={6} sx={textAlignLeft()}>
                                    <CustomTypographyWithIcon
                                        variant="body2"
                                        text={cartItem?.totalPrice}
                                        sx={menuItemPriceHeaderTextStyle({ fontSize: menuConfiguration?.itemPriceFontSize, color: menuConfiguration?.itemPriceColor })}
                                        icon={<EuroIcon sx={menuItemPriceIconStyle({ fontSize: menuConfiguration?.itemPriceFontSize, color: menuConfiguration?.itemPriceColor })} />}
                                        iconPositionStart={iconPositionStart}
                                    />
                                </Grid>

                                <Grid item xs={6} md={6} sx={textAlignRight()}>
                                    {item.pvcRecyclingTax > 0 &&
                                        <CustomChip
                                            label={`${t('item.pvcRecyclingTax')}: ${item.pvcRecyclingTax} ${findCurrency(currency)}`}
                                            sx={{
                                                background: replaceLastTwoChars(menuConfiguration.chipTextColor, "1c"),
                                                color: menuConfiguration.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21"
                                            }}

                                        />
                                    }
                                    {item.loyaltyPoints > 0 && menuConfiguration?.showPointsPerItem &&
                                        <CustomChip
                                            label={`${item.loyaltyPoints} ${t('item.points')}`}
                                            sx={{
                                                background: replaceLastTwoChars(menuConfiguration.chipTextColor, "1c"),
                                                color: menuConfiguration.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21"
                                            }} />
                                    }
                                </Grid>

                                {item.nutrients?.length === 1 &&
                                    <Grid item xs={12} sm={12} sx={{ background: "lightgrey", borderRadius: "20px", padding: "10px" }} onClick={() => setViewNutrients(true)}>
                                        <Grid container sx={justifyContentCenter()}>
                                            <Grid item>
                                                <CustomTypography
                                                    variant="body2"
                                                    text={t('item.nutrients.label')}
                                                    sx={{ ...menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor }), ...lumanosimoTitleStyle() }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ paddingLeft: "10px" }}>
                                                <InfoIcon sx={{ fontSize: "15px" }} />
                                            </Grid>
                                        </Grid>

                                        {/* <Divider light flexItem sx={dividerStyle()} /> */}
                                        <Grid container gap={1.2} sx={{ ...textAlignLeft()}}>
                                            <Grid item xs={2.8} md={2.8} sx={itemNutrientCardTextStyle()} >
                                                <Grid container>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={t('item.caloriesShort')} sx={itemNutrientText()} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={`${item.nutrients[0].calories} ${t('item.nutrients.caloriesAfterLabel')}`} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={2.8} md={2.8} sx={itemNutrientCardTextStyle()} >
                                                <Grid container>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={t('item.proteinShort')} sx={itemNutrientText()} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={`${item.nutrients[0].protein} ${t('item.nutrients.proteinAfterLabel')}`} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={2.8} md={2.8} sx={itemNutrientCardTextStyle()} >
                                                <Grid container>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={t('item.nutrients.fat')} sx={itemNutrientText()} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={`${item.nutrients[0].fat} ${t('item.nutrients.fatAfterLabel')}`} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={2.8} md={2.8} sx={itemNutrientCardTextStyle()} >
                                                <Grid container>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={t('item.carbShort')} sx={itemNutrientText()} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={`${item.nutrients[0].carb} ${t('item.nutrients.carbAfterLabel')}`} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                <Grid item xs={12} md={12} sx={textAlignLeft()}>
                                    {item.allergies?.length > 0 &&
                                        <>
                                            <Divider light flexItem sx={dividerStyle()} />

                                            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                                <Grid container gap={1}>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography
                                                            variant="body2"
                                                            text={t('item.allergies')}
                                                            sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={12}>
                                                        {getAllergies(item.allergies)}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Divider light flexItem sx={dividerStyle()} />
                        </Grid>

                        {item.modifierLists?.length > 0 &&
                            <Grid item xs={12} sm={12} sx={{ padding: "0 10px" }}>
                                {item.modifierLists?.map((modifierList) =>
                                    <>
                                        {modifierList.modifiers?.length > 0 &&
                                            <Grid container sx={{ ...textAlignLeft(), marginBottom: "20px" }}>
                                                <Grid item xs={12} sm={12}>
                                                    <CustomTypography
                                                        variant="body2"
                                                        text={modifierList.name}
                                                        sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    {!modifierList.allowMultipleSelection &&
                                                        <FormControl component="fieldset" key={modifierList.id}>
                                                            <RadioGroup
                                                                name={modifierList.id}
                                                                value={cartItem.modifierListIds.find(ml => ml.modifierListId === modifierList.id)?.modifiers[0].id || ''}
                                                            // onChange={(event) => handleSingleSelectionChange(modifierList.id, event.target.value)}
                                                            >
                                                                <Grid container sx={{ ...textAlignLeft(), marginBottom: "20px" }}>
                                                                    {modifierList.modifiers?.map((modifier) =>
                                                                        <Grid item xs={6} sm={6}>
                                                                            <FormControlLabel
                                                                                value={modifier.id}
                                                                                control={<Radio />}
                                                                                label={modifier.name}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </RadioGroup>
                                                        </FormControl>
                                                    }

                                                    {modifierList.allowMultipleSelection &&
                                                        <FormGroup>
                                                            <Grid container>
                                                                {modifierList.modifiers?.map((modifier) =>
                                                                    <Grid item xs={6} sm={6}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={cartItem.modifierListIds.find(ml => ml.modifierListId === modifierList.id)?.modifiers.some(mod => mod.id === modifier.id)}
                                                                                />}
                                                                            label={modifier.name}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </FormGroup>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }
                                    </>
                                )}
                            </Grid>
                        }

                        {outletAllowComments && menuConfiguration?.allowCommentsOnItem &&
                            <>
                                <Grid item xs={12} sm={12} sx={{ padding: "10px", background: "#efefef" }}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} sx={textAlignLeft()}>
                                            <CustomTypography
                                                variant="body2"
                                                text={t('item.notes')}
                                                sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <TextareaAutosize
                                                placeholder={t('item.notesInfo')}
                                                // variant="outlined"
                                                value={item.notes}
                                                inputProps={{ maxLength: 100 }}
                                                maxLength={100}
                                                style={{ borderColor: "lightgrey", width: "95%", padding: "10px", borderRadius: "10px", marginTop: "10px" }}
                                                endDecorator={
                                                    <Box sx={requestTextareaBoxStyle()}>
                                                        <Divider flexItem={true} />
                                                        <Typography level="span" sx={{ ml: 'auto', color: cartItem?.notes.length === 100 ? "red" : "initial" }}>
                                                            {`${cartItem?.notes?.length} ${cartItem?.notes.length === 1 ? t('item.itemCharLimit') : t('item.itemCharsLimit')} /${requestTextFieldLimit}`}
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }

                        {enableCart &&
                            <Grid item xs={12} sm={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <Divider light flexItem sx={{ ...dividerStyle(), margin: 0 }} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container sx={{ paddingTop: "5px" }}>
                                            <Grid item xs={7} sm={7} sx={{ textAlign: "left", paddingLeft: "10px", paddingBottom: "10px" }}>
                                                <GroupedItemButtons counter={cartItem.quantity} sx={orderItemButtonStyle(
                                                    menuConfiguration.buttonBackgroundColor,
                                                    menuConfiguration.buttonFont,
                                                    menuConfiguration.buttonColor,
                                                    menuConfiguration.buttonFontSize)} />
                                            </Grid>

                                            <Grid item xs={5} sm={5}>
                                                {/* ADD TO CART */}
                                                <CustomIconButton
                                                    variant="outlined"
                                                    label={t('cart.addToCart')}
                                                    sx={
                                                        orderItemButtonStyle(
                                                            menuConfiguration.buttonBackgroundColor,
                                                            menuConfiguration.buttonFont,
                                                            menuConfiguration.buttonColor,
                                                            menuConfiguration.buttonFontSize
                                                        )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </>
                }
            </Grid>
        </Card>
    );
}

export default CustomPreviewItemDialog;