import { getDefaultDataRequest, getDefaultSearchRequest } from "constants/defaultObjects";
import { requestActionTypes } from "./requestActionTypes";

const initialState = {
    data: getDefaultDataRequest(),
    search: getDefaultSearchRequest(),
    refetchData: false
};

const requestReducer = (state = initialState, action) => {
    switch (action.type) {
        case requestActionTypes.UPDATE_REQUEST_DATA: {
            const { data } = action.payload;
            return {
                ...state,
                data: data,
                refetchData: false
            };
        }
        case requestActionTypes.UPDATE_REQUEST_DATA_PAGINATION: {
            const { data } = action.payload;
            return {
                ...state,
                data: data,
                refetchData: true
            };
        }
        case requestActionTypes.UPDATE_REQUEST_SEARCH: {
            const { search, refetchData } = action.payload;
            return {
                ...state,
                search: search,
                refetchData: refetchData
            };
        }
        case requestActionTypes.UPDATE_REQUEST_REFETCH_DATA: {
            const { refetchData } = action.payload;
            return {
                ...state,
                refetchData: refetchData
            };
        }
        case requestActionTypes.UPDATE_REQUEST_ALL: {
            const { data, search, refetchData } = action.payload;
            return {
                ...state,
                data: data,
                search: search,
                refetchData: refetchData
            };
        }
        // Implement additional cases for other properties
        default:
            return state;
    }
};

export default requestReducer;