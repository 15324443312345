import { MINIO_HOST } from 'constants/hosts';

export function getDefaltPhoto(photoImageSrc) {
    return `${MINIO_HOST}default/${photoImageSrc}`
}

export function getItemPhoto(organizationID, itemID, photoImageSrc) {
    return `${MINIO_HOST}organization/${organizationID}/item-or-modifier/${itemID}/${photoImageSrc}`
}

export function getOutletCoverPhoto(organizationID, outletID, photoImageSrc) {
    return `${MINIO_HOST}organization/${organizationID}/outlet/${outletID}/cover/${photoImageSrc}`
}
export function getOutletPhoto(organizationID, outletID, photoImageSrc) {
    return `${MINIO_HOST}organization/${organizationID}/outlet/${outletID}/cover/${photoImageSrc}`
}

export function getOutletLogo(organizationID, outletID, photoImageSrc) {
    return `${MINIO_HOST}organization/${organizationID}/outlet/${outletID}/${photoImageSrc}`
}

export function getMenuPhoto(organizationID, menuID, photoImageSrc) {
    return `${MINIO_HOST}organization/${organizationID}/menu/${menuID}/${photoImageSrc}`
}

export function getMenuParentCategoryPhoto(organizationID, parentCategoryID, photoImageSrc) {
    return `${MINIO_HOST}organization/${organizationID}/parentCategory/${parentCategoryID}/${photoImageSrc}`
}

export function getCouponPhoto(organizationID, couponID, photoImageSrc) {
    return `${MINIO_HOST}organization/${organizationID}/coupon/${couponID}/${photoImageSrc}`
}

export function getLoyaltyCategoryPhoto(organizationID, loyaltyCategoryID, photoImageSrc) {
    return `${MINIO_HOST}organization/${organizationID}/loyalty/category/${loyaltyCategoryID}/${photoImageSrc}`
}


export default class functions {
    static getDefaltPhoto(photoImageSrc) { return getDefaltPhoto(photoImageSrc) }
    static getItemPhoto(organizationID, itemID, photoImageSrc) { return getItemPhoto(organizationID, itemID, photoImageSrc); };
    static getOutletCoverPhoto(organizationID, outletID, photoImageSrc) { return getOutletCoverPhoto(organizationID, outletID, photoImageSrc); };
    static getOutletPhoto(organizationID, outletID, photoImageSrc) { return getOutletPhoto(organizationID, outletID, photoImageSrc); }
    static getOutletLogo(organizationID, outletID, photoImageSrc) { return getOutletLogo(organizationID, outletID, photoImageSrc); };
    static getMenuPhoto(organizationID, menuID, photoImageSrc) { return getMenuPhoto(organizationID, menuID, photoImageSrc); };
    static getMenuParentCategoryPhoto(organizationID, parentCategoryID, photoImageSrc) { return getMenuParentCategoryPhoto(organizationID, parentCategoryID, photoImageSrc); };
}