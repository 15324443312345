import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { gridSpacing } from 'constants/theme';
import { dateTimeFormatter } from 'utils/functions';
import ChatUserAvatar from './user/ChatUserAvatar';

/**
 * The ChartHistory, that display chat message history.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ChartHistory({ data, theme, user }) {
    return (
        <Grid item xs={12}>
            <Grid container spacing={gridSpacing}>
                {data?.map((history, index) => (
                    <React.Fragment key={index}>
                        {history.user.name !== user.name ? (
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing} sx={{ width: "100%" }}>
                                    <Grid item xs={10}>
                                        <Card
                                            sx={{
                                                width: "fit-content",
                                                display: 'inline-block',
                                                float: 'right',
                                            }}
                                        >
                                            <CardContent sx={{ p: 2, pb: '16px !important', width: 'fit-content', ml: 'auto' }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <div dangerouslySetInnerHTML={{ __html: history.message }} />
                                                    </Grid>
                                                    <Divider />
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            align="right"
                                                            variant="subtitle2"
                                                        >
                                                            {dateTimeFormatter(history.dateTime)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={"auto"}>
                                        <ChatUserAvatar user={history.user} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing} sx={{ width: "100%" }}>
                                    <Grid item xs={12} sm={7}>
                                        <Card
                                            sx={{
                                                width: "fit-content",
                                                display: 'inline-block',
                                                float: 'left',
                                            }}
                                        >
                                            <CardContent sx={{ p: 2, pb: '16px !important' }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <div dangerouslySetInnerHTML={{ __html: history.message }} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography align="right" variant="subtitle2">
                                                            {dateTimeFormatter(history.dateTime)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    );
}

ChartHistory.propTypes = {
    theme: PropTypes.object,
    data: PropTypes.array,
    user: PropTypes.object
};

export default ChartHistory;
