import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import logo from 'assets/images/logo.png';
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomTypography from "components/Typography/CustomTypography";
import { AuthConsumer } from 'context/AuthContext';
import { useState } from "react";
import { toggleMenuButton, toggleMenuIcon } from "themes/defaultThemes";
import MiniMenu from './mini-menu.component';

/**
 * The TopHeader, that display the Top bar with the search placehold, the 
 * account button and the notifications button.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function TopHeader() {
    //  redirect the information related to the redirect after successfully submit a query
    const [redirect, setRedirect] = useState({
        perform: false,
        url: ""
    })

    if (redirect.perform) {
        window.location.href = redirect.url;
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        return (
            <AuthConsumer>
                {({ sidenav, handleSidenavStateChange }) => (
                    <CustomGrid>
                        <Box sx={{ flexGrow: 1, background: "white" }}>
                            <AppBar position="fixed" style={{ background: "white", width: "100%" }} className={`column tr ${(sidenav.expanded) ? "expanded" : ""}`}>
                                <header id="header-wrapper" className={`column tr ${(sidenav.expanded) ? "expanded" : ""}`} role="banner">
                                    <Grid container gap={1} direction="row">
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                onClick={() => handleSidenavStateChange(!sidenav.expanded)}
                                                sx={toggleMenuButton()}
                                            >
                                                <MenuIcon sx={toggleMenuIcon()} onClick={() => handleSidenavStateChange(!sidenav.expanded)} />
                                            </Button>
                                        </Grid>

                                        <Grid item xs={"auto"}>
                                            <a href="/"><img src={logo} width="40" alt="" /></a>
                                        </Grid>

                                        <Grid item xs={"auto"}>
                                            <CustomTypography
                                                variant="h5"
                                                text="Usee"
                                                sx={{ paddingTop: "5px" }}
                                            />
                                        </Grid>

                                        <Box sx={{ flexGrow: 1 }} />

                                        <Grid item xs={"auto"} sx={{ alignSelf: "center" }}>
                                            <MiniMenu />
                                        </Grid>
                                    </Grid>
                                </header>
                            </AppBar>
                        </Box>
                    </CustomGrid>
                )}
            </AuthConsumer>
        );
    }
}

export default TopHeader;