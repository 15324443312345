import { Divider, Grid } from '@mui/material';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { subscriptionHistoryCardGridStyle, subscriptionHistoryDetailsLabelStyle, subscriptionHistoryDetailsTextPriceStyle, subscriptionHistoryDetailsTextStyle } from 'themes/defaultThemes';

/**
 * `SubscriptionPendingChargesInstanceOutletCharge` Component
 * 
 * @param {Object} props Component props
 * @returns {React.Component} SubscriptionPendingChargesInstanceOutletCharge component
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SubscriptionPendingChargesInstanceOutletCharge({ currency = "EUR", outletBranch, subscriptionPlan }) {
    const { t } = useTranslation();
console.log(subscriptionPlan)
    // Mapping service keys to their charges
    const serviceCharges = subscriptionPlan?.services?.reduce((charges, service) => {
        charges[service.key] = parseFloat(service.value);
        return charges;
    }, {});

    /**
     * Calculates the total amount based on the service usage counters and the service charges.
     *
     * This function iterates over the service counters in the provided data object and
     * multiplies each counter with its corresponding service charge defined in the
     * subscription plan. The total amount is the sum of these individual amounts.
     *
     * @returns {number} The calculated total amount based on the service usage and charges.
     */
    function calculateAmount() {
        let amount = 0;
        // Calculating the amount
        if (outletBranch.smsCounter) amount += outletBranch.smsCounter * (serviceCharges.SMS || 0);
        if (outletBranch.emailCounter) amount += outletBranch.emailCounter * (serviceCharges.EMAIL || 0);
        if (outletBranch.ticketCounter) amount += outletBranch.ticketCounter * (serviceCharges.PRINTER_TICKET || 0);
        if (outletBranch.receiptTicketCounter) amount += outletBranch.receiptTicketCounter * (serviceCharges.RECEIPT_TICKET || 0);

        return amount;
    }

    return (

        <Grid container sx={subscriptionHistoryCardGridStyle()}>
            {outletBranch?.outlet?.id &&
                <>
                    <Grid item xs={7} md={7}>
                        <CustomTypography variant="body2" text={t('subscription.organization.history.outlet')} sx={subscriptionHistoryDetailsLabelStyle()} />
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <CustomTypography variant="body2" text={outletBranch?.outlet?.name} sx={subscriptionHistoryDetailsTextStyle()} />
                    </Grid>
                </>
            }


            {outletBranch?.emailCounter !== undefined && outletBranch?.emailCounter !== null &&
                <>
                    <Grid item xs={7} md={7}>
                        <CustomTypography variant="body2" text={t('subscription.organization.history.emailCounter')} sx={subscriptionHistoryDetailsLabelStyle()} />
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <Grid container sx={{ paddingLeft: "10%", alignSelf: "center" }}>
                            <Grid item xs={5} md={5}>
                                <CustomTypography variant="body2" text={outletBranch.emailCounter} sx={subscriptionHistoryDetailsTextStyle()} />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <CustomTypography variant="body2" text={"*"} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                            <Grid item xs={5} md={5} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={`${serviceCharges.EMAIL} ${currency}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }

            {outletBranch?.ticketCounter !== undefined && outletBranch?.ticketCounter !== null &&
                <>
                    <Grid item xs={7} md={7}>
                        <CustomTypography variant="body2" text={t('subscription.organization.history.ticketCounter')} sx={subscriptionHistoryDetailsLabelStyle()} />
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <Grid container sx={{ paddingLeft: "10%", alignSelf: "center" }}>
                            <Grid item xs={5} md={5}>
                                <CustomTypography variant="body2" text={outletBranch.ticketCounter} sx={subscriptionHistoryDetailsTextStyle()} />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <CustomTypography variant="body2" text={"*"} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                            <Grid item xs={5} md={5} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={`${serviceCharges.PRINTER_TICKET} ${currency}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }

            {outletBranch?.receiptTicketCounter !== undefined && outletBranch?.receiptTicketCounter !== null &&
                <>
                    <Grid item xs={7} md={7}>
                        <CustomTypography variant="body2" text={t('subscription.organization.history.receiptTicketCounter')} sx={subscriptionHistoryDetailsLabelStyle()} />
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <Grid container sx={{ paddingLeft: "10%", alignSelf: "center" }}>
                            <Grid item xs={5} md={5}>
                                <CustomTypography variant="body2" text={outletBranch.receiptTicketCounter} sx={subscriptionHistoryDetailsTextStyle()} />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <CustomTypography variant="body2" text={"*"} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                            <Grid item xs={5} md={5} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={`${serviceCharges.RECEIPT_TICKET} ${currency}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }

            {outletBranch?.smsCounter !== undefined && outletBranch?.smsCounter !== null &&
                [
                    <Grid item xs={7} md={7}>
                        <CustomTypography variant="body2" text={t('subscription.organization.history.smsCounter')} sx={subscriptionHistoryDetailsLabelStyle()} />
                    </Grid>
                    ,
                    <Grid item xs={5} md={5}>
                        <Grid container sx={{ paddingLeft: "10%", alignSelf: "center" }}>
                            <Grid item xs={5} md={5}>
                                <CustomTypography variant="body2" text={outletBranch.smsCounter} sx={subscriptionHistoryDetailsTextStyle()} />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <CustomTypography variant="body2" text={"*"} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                            <Grid item xs={5} md={5} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={`${serviceCharges.SMS} ${currency}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                ]
            }


            <Grid item xs={7} md={7}>
                {/* <CustomTypography variant="body2" text={t('subscription.organization.history.amount')} sx={subscriptionHistoryDetailsLabelStyle()} /> */}
            </Grid>
            <Grid item xs={5} md={5}>
                <Divider light flexItem sx={{ borderRightWidth: 5, width: "20%", marginBottom: "5px" }} />
                <CustomTypography variant="body2" text={`${calculateAmount()}  ${currency}`} sx={subscriptionHistoryDetailsTextStyle()} />
            </Grid>

        </Grid>
    );
}

export default SubscriptionPendingChargesInstanceOutletCharge;