import { Box, Card, Grid, InputLabel, Stack } from '@mui/material';
import orderApi from 'api/order';
import { NoOrders } from 'assets/lottie/LottieAnimations';
import { ArrowBackIcon } from 'assets/mui/MuiIcons';
import "assets/scss/swipeable-list.scss";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomTypography from 'components/Typography/CustomTypography';
import { lottieOrderOptions } from 'constants/animations/lottieOptions';
import { MORE_ORDER_ADD_SIZE } from 'constants/fieldProperties';
import { getDefaultSortDirectionData } from 'constants/sortDirection';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { orderBackBtnStyle, orderEmptyInfoTextStyle, pastOrderMoreOrdersButtonStyle, pastOrderViewHeaderTextStyle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { dateTimeFormatterFilter } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';
import CustomPDAPastOrderCardSimplified from './CustomPDAPastOrderCardSimplified';

/**
 * The CustomPDAViewUnpaidOrders, that display a custom {@link Card} for PDA view unpaid orders page
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPDAViewUnpaidOrders({
    organizationID, outletID, equipmentID,
    handleReset, handleSelectedOrderToPay,
    isViewUnpaidOrdersPage,
    handleViewAllToPay
}) {
    const { t } = useTranslation();

    // for search
    const [orderSearch, setOrderSearch] = useState({
        // outletIDs: [outletID],
        organizationID: organizationID,
        equipmentID: equipmentID,
        dateFrom: dateTimeFormatterFilter(),
        dateTo: null,
        lang: "en"
    });

    const [unpaidOrdersData, setUnpaidOrdersData] = useState({
        userAuth: null,
        orders: null,
        userFullName: "",
        pagination: {
            size: 10,
            page: 0,
            ...getDefaultSortDirectionData
        }
    });

    const [unpaidOrdersEnableMoreButton, setUnpaidOrdersEnableMoreButton] = useState(true);

    /**
     * The rest endpoint to get the outlet orders.
     */
    function getUnpaidOrders() {
        let unpaidOrdersSearchData = {
            ...orderSearch,
            lang: getLanguageFromURL(),
            isPaid: false
        }
        orderApi.fetchAll(unpaidOrdersData.pagination, unpaidOrdersSearchData).then((r) => {
            if (r.data.code === "SUCCESS") {
                setUnpaidOrdersData({
                    ...unpaidOrdersData,
                    userAuth: r.data.returnobject.userAuth,
                    orders: r.data.returnobject.orders,
                    equipment: r.data.returnobject.equipment,
                    pagination: {
                        ...unpaidOrdersData.pagination,
                        size: r.data.returnobject.orders.size
                    }
                });

                if (r.data.returnobject.orders.totalPages <= r.data.returnobject.orders.number + 1) {
                    setUnpaidOrdersEnableMoreButton(false)
                }
            }
        }).catch((e) => {
            // console.log(e);
        });
    }

    useEffect(() => {
        if (isViewUnpaidOrdersPage) {
            getUnpaidOrders();

            const fetchDataInterval = setInterval(() => {
                getUnpaidOrders(); // Fetch data
            }, 5000); // 5 seconds interval

            // Clear interval on component unmount to avoid memory leaks
            return () => clearInterval(fetchDataInterval);
        }
    }, [isViewUnpaidOrdersPage]);

    useEffect(() => {
        if (isViewUnpaidOrdersPage)
            getUnpaidOrders();
    }, [unpaidOrdersData.pagination.size]);

    return (
        <>
            <Grid item xs={"auto"} sx={{ height: "30px" }}>
                <CustomIconButton
                    icon={<ArrowBackIcon />}
                    sx={orderBackBtnStyle()}
                    onClick={() => handleReset()}
                    label={t('pda.backButtonEqDialog')}
                />
            </Grid>

            <Grid item xs={"auto"} sx={{ height: "30px" }}>
                <CustomTypography variant="body2" text={t('pda.order.viewUnpaid')} sx={pastOrderViewHeaderTextStyle()} />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "right", mb: "30px" }}>
                <CustomIconButton
                    onClick={handleViewAllToPay}
                    variant="outlined"
                    label={t('pda.viewAll')}
                />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <Grid container gap={1} sx={{ placeContent: "center", display: "contents" }}>
                    <Grid item xs={12}>
                        {unpaidOrdersData.orders?.content?.map((order, index) =>
                            <Grid container>
                                <CustomPDAPastOrderCardSimplified
                                    order={order}
                                    index={index}
                                    // handlePastOrderClick={() => navigate(`/${getLanguageFromURL()}/pda/order/${order.id}`)}
                                    handlePastOrderPayClick={() => handleSelectedOrderToPay(order)}//navigate(`/${getLanguageFromURL()}/pay/order/${order.id}`)}
                                // handlePastOrderAddFeedbackClick={() => navigate(`${order.id}/feedback`)}
                                />
                            </Grid>
                        )}
                    </Grid>

                    {(unpaidOrdersEnableMoreButton && unpaidOrdersData.orders?.content?.length > 1) &&
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <CustomIconButton
                                sx={pastOrderMoreOrdersButtonStyle()}
                                onClick={() => formValChangeWithParentElementWithNameAndValue("pagination.size", unpaidOrdersData.pagination.size + MORE_ORDER_ADD_SIZE, unpaidOrdersData, setUnpaidOrdersData)}
                                variant="outlined"
                                label={t('pda.moreOrders')}
                            />
                        </Grid>
                    }

                    <Grid item xs={12}>
                        {(unpaidOrdersData.orders === null || unpaidOrdersData.orders?.content?.length === 0) &&
                            <div style={{ textAlign: "center" }}>
                                <Lottie
                                    animationData={NoOrders}
                                    {...lottieOrderOptions}
                                />

                                <InputLabel sx={orderEmptyInfoTextStyle()}>{t('pda.order.empty')}</InputLabel>
                            </div>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default CustomPDAViewUnpaidOrders