import styled from "@emotion/styled";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '99%',
    maxHeight: '100%',
});

function CustomImage({ alt, src, height = "80" }) {
    return (
        src !== "" &&
        <Img
            alt={alt}
            src={src}
            height={height}
            loading="lazy" />
    )
}
export default CustomImage;