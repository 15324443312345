import React, { useState, useEffect } from "react";
import { ResponsiveContainer, PieChart, Pie, Label } from "recharts";
import CustomTypography from "../../components/Typography/CustomTypography";

const OfferPieChart = ({ createdTime, timeInMinute, minimize }) => {
  const expirationDate = new Date(createdTime);
  expirationDate.setMinutes(expirationDate.getMinutes() + timeInMinute);

  const [timeLeft, setTimeLeft] = useState(
    new Date(expirationDate) - Date.now()
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(new Date(expirationDate) - Date.now());
    }, 1000);
    return () => clearInterval(intervalId);
  }, [createdTime, timeInMinute]);

  let data = [
    {
      name: "Remaining Time",
      value: timeLeft > 0 ? timeLeft : 0,
      fill: "#D9E2E2",
    },
    {
      name: "Expired Time",
      value: timeLeft > 0 ? Date.now() - new Date(createdTime) : 100,
      fill: timeLeft > 0 ? "#66A15F" : "#f74040",
    },
  ];

  if (minimize) {
    return (
      <CustomTypography
        text={
          timeLeft > 0
            ? timeLeft > 3600000
              ? new Date(timeLeft).toISOString().substr(11, 8)
              : new Date(timeLeft).toISOString().substr(14, 5)
            : "00:00"
        }
      />
    );
  }
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChart width={"100%"} height={"100%"}>
        <Pie
          data={data}
          cx='50%'
          cy='50%'
          paddingAngle={0}
          dataKey='value'
          legendType='none'
          innerRadius={32}
          outerRadius={36}
          stroke='none'
        >
          <Label
            value={
              timeLeft > 0
                ? timeLeft > 3600000
                  ? new Date(timeLeft).toISOString().substr(11, 8)
                  : new Date(timeLeft).toISOString().substr(14, 5)
                : "00:00"
            }
            position='center'
            style={{
              fontWeight: "600",

              fill: timeLeft > 0 ? "#101828" : "#f74040",
            }}
            // textBreakAll
            // fontSize='27px'
          >
            {timeLeft > 0
              ? timeLeft > 3600000
                ? new Date(timeLeft).toISOString().substr(11, 8)
                : new Date(timeLeft).toISOString().substr(14, 5)
              : "00:00"}
          </Label>
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default OfferPieChart;
