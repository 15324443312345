
import { Font, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import lumanosimoFont from 'assets/font/Lumanosimo-Regular.ttf';
import robotoBoldFont from 'assets/font/Roboto-Bold.ttf';
import robotoFont from 'assets/font/Roboto-Regular.ttf';

Font.register({ family: 'Lumanosimo', src: lumanosimoFont });

// Register Roboto font
Font.register({
    family: 'Roboto',
    fonts: [
        { src: robotoBoldFont, fontWeight: 900 },
        { src: robotoFont, fontWeight: 400 },
    ],
});

/**
 * The CustomQRSignature, that display customized Pdf view for the exported QR code.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomQRSignature({ sign = true, preview = false }) {
    const styles = StyleSheet.create({
        container: {
            backgroundColor: 'white',
            width: '90%',
            margin: '20px',
            textAlign: 'center',
            alignItems: 'center',
        },
        gridContainer: {
            flexDirection: 'row',
            width: '100%',
            border: '1px solid lightgrey',
            borderRadius: '50px',
            padding: '20px',
        },
        gridItem: {
            flex: 1,
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
        },
        label: {
            fontFamily: 'Roboto',
            fontWeight: 900,
            fontSize: '24px',
        },
        subLabel: {
            fontSize: '18px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            paddingBottom: "10px"
        },
        icon: {
            width: 200,
            height: 200,
        },
        poweredBy: {
            flexDirection: 'row',
            textAlign: 'center',
            alignItems: 'center',
            marginTop: 10
        },
        poweredByLabel: {
            fontSize: '24px',
            fontFamily: 'Roboto',
            fontWeight: 400
        },
        poweredByCompany: {
            fontSize: '24px',
            fontFamily: 'Roboto',
            fontWeight: 900,
        },
    });
    return (
        <>
            {!preview && (
                <View style={styles.container}>
                    {sign && (
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem}>
                                <Text style={styles.label}>Place Order</Text>
                                <Text style={styles.subLabel}>Παράγγειλε</Text>
                                <Svg style={{ width: 80, height: 80 }} viewBox="0 0 24 24">
                                    <Path fill="#000" d="M21 5c-1.11-.35-2.33-.5-3.5-.5-1.95 0-4.05.4-5.5 1.5-1.45-1.1-3.55-1.5-5.5-1.5S2.45 4.9 1 6v14.65c0 .25.25.5.5.5.1 0 .15-.05.25-.05C3.1 20.45 5.05 20 6.5 20c1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5V6c-.6-.45-1.25-.75-2-1zm0 13.5c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5V8c1.35-.85 3.8-1.5 5.5-1.5 1.2 0 2.4.15 3.5.5v11.5z" />
                                    <Path fill="#000" d="M17.5 10.5c.88 0 1.73.09 2.5.26V9.24c-.79-.15-1.64-.24-2.5-.24-1.7 0-3.24.29-4.5.83v1.66c1.13-.64 2.7-.99 4.5-.99zM13 12.49v1.66c1.13-.64 2.7-.99 4.5-.99.88 0 1.73.09 2.5.26V11.9c-.79-.15-1.64-.24-2.5-.24-1.7 0-3.24.3-4.5.83zm4.5 1.84c-1.7 0-3.24.29-4.5.83v1.66c1.13-.64 2.7-.99 4.5-.99.88 0 1.73.09 2.5.26v-1.52c-.79-.16-1.64-.24-2.5-.24z" />
                                </Svg>
                            </View>
                            <View style={styles.gridItem}>
                                <Text style={styles.label}>Pay</Text>
                                <Text style={styles.subLabel}>Πλήρωσε</Text>
                                <Svg style={{ width: 80, height: 80 }} viewBox="0 0 24 24">
                                    <Path fill="#000" d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
                                </Svg>
                            </View>
                            <View style={styles.gridItem}>
                                <Text style={styles.label}>Waiter Call</Text>
                                <Text style={styles.subLabel}>Κάλεσε Σερβιτόρο</Text>
                                <Svg style={{ width: 80, height: 80 }} viewBox="0 0 24 24">
                                    <Path fill="#000" d="M23 17c0 3.31-2.69 6-6 6v-1.5c2.48 0 4.5-2.02 4.5-4.5H23zM1 7c0-3.31 2.69-6 6-6v1.5C4.52 2.5 2.5 4.52 2.5 7H1zm7.01-2.68-4.6 4.6c-3.22 3.22-3.22 8.45 0 11.67s8.45 3.22 11.67 0l7.07-7.07c.49-.49.49-1.28 0-1.77s-1.28-.49-1.77 0l-4.42 4.42-.71-.71 6.54-6.54c.49-.49.49-1.28 0-1.77s-1.28-.49-1.77 0l-5.83 5.83-.71-.71 6.89-6.89c.49-.49.49-1.28 0-1.77s-1.28-.49-1.77 0l-6.89 6.89-.69-.7 5.48-5.48c.49-.49.49-1.28 0-1.77s-1.28-.49-1.77 0l-7.62 7.62c1.22 1.57 1.11 3.84-.33 5.28l-.71-.71c1.17-1.17 1.17-3.07 0-4.24l-.35-.35 4.07-4.07c.49-.49.49-1.28 0-1.77-.5-.48-1.29-.48-1.78.01z" />
                                </Svg>
                            </View>
                        </View>
                    )}
                    <View style={styles.poweredBy}>
                        <Text style={styles.poweredByLabel}>Powered by </Text>
                        <Text style={styles.poweredByCompany}>Usee.gr</Text>
                    </View>
                </View>
            )}
        </>
    );
}

export default CustomQRSignature;