import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import { formatISOWithTimezone } from 'utils/functions/date';
/**
 * The rest request which attempts to get an existing request information.
 * 
 * @param {*} lang TThe lang code for multilingual fields
 * @param {*} outletUrl The url of the loyalty outlet to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the loyalties 
 */
export async function fetchAll(pageable, search) {
   let data = {};
   if (search.organizationID !== undefined && search.organizationID !== "") {
      data = { ...data, organizationID: search.organizationID }
   }
   if (search.outletID !== undefined && search.outletID !== "") {
      data = { ...data, outletID: search.outletID }
   }
   if (search.equipmentID !== undefined && search.equipmentID !== "") {
      data = { ...data, equipmentID: search.equipmentID }
   }
   if (search.customerName !== undefined && search.customerName !== "") {
      data = { ...data, customerName: search.customerName }
   }
   if (search.employeeName !== undefined && search.employeeName !== "") {
      data = { ...data, employeeName: search.employeeName }
   }
   if (search.equipmentLabel !== undefined && search.equipmentLabel !== "") {
      data = { ...data, equipmentLabel: search.equipmentLabel }
   }
   if (search.type !== undefined && search.type !== "" && search.type !== null) {
      data = { ...data, type: search.type }
   }
   if (search.status !== undefined && search.status !== "" && search.status !== null) {
      data = { ...data, status: search.status }
   }
   if (search.label !== undefined && search.label !== "" && search.label !== null) {
      data = { ...data, label: search.label }
   }
   if (search.dateFrom !== null && search.dateFrom !== "" && search.dateFrom !== undefined) {
      data = { ...data, dateFrom: formatISOWithTimezone(new Date(search.dateFrom)) };
   }
   if (search.dateTo !== null && search.dateTo !== "" && search.dateTo !== undefined) {
      data = { ...data, dateTo: formatISOWithTimezone(new Date(search.dateTo)) };
   }

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}request`, pageable !== undefined ? { ...pageable, ...data } : data));
   else return await request.get(includeParamsToUrl(`${API}request`, pageable !== undefined ? pageable : {}));
}

export async function changeRequestStatus(requestID, status) {
   return await request.post(`${API}request/${requestID}/${status}`);
}
/**
 * The rest request which return required information to be presented on add new request page.
 * 
 * @returns an object with an attribute containing the requested information to be presented on request page
 */
export async function fetchNew() {
   return await request.get(includeParamsToUrl(`${API}request/add`));
}

/**
 * The rest request which attempts to create a new request.
 * 
 * @param {*} data A JSON object which contains new request infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
   return await request.post(`${API}request`, data);
}
const requestApi = {
   fetchNew,
   fetchAll,
   create,
   changeRequestStatus
}

export default requestApi;