
import { CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Grid } from '@mui/material';
import analyticsApi from 'api/analytics';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomMuiDateTimeRangePicker from 'components/DatePicker/CustomMuiDateTimeRangePicker';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import CustomGrid from 'components/Grid/CustomGrid';
import AnalyticsEmployees from 'components/Grid/analytics/AnalyticsEmployees';
import AnalyticsExtra from 'components/Grid/analytics/AnalyticsExtra';
import AnalyticsGeneral from 'components/Grid/analytics/AnalyticsGeneral';
import AnalyticsRatings from 'components/Grid/analytics/AnalyticsRatings';
import AnalyticsRevenue from 'components/Grid/analytics/AnalyticsRevenue';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomTabs from 'components/Tab/CustomTabs';
import CustomPageableTableWithFilters from 'components/Table/CustomPageableTableWithFilters';
import { getDefaultAnalyticsSortDirection } from "constants/sortDirection";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/material.css';
import { useParams } from 'react-router-dom';
import { mainAreaTable, mainTag, mainTagSearchItemOrderStatus, mainTagSearchItemOrderStatusIcon } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { dateTimeFormatterFilter } from 'utils/functions';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The AnalyticsComponent that triggers the creation of a 
 * new section form or the modification of an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AnalyticsComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();

    /**
     * @type {object}
     * 
     * @property {boolean} isAdd to define if requested an edit or an add of a section
     * @property {object} section an empty object if add or the selected section to be editted
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        outlets: [],
        currency: "€",
        // table
        pagination: {
            count: -1,
            size: 10,
            page: 0,
            ...getDefaultAnalyticsSortDirection
        },
    });

    const [value, setValue] = useState("items");

    //analytics data
    const [revenueAnalyticsData, setRevenueAnalyticsData] = useState([]);
    const [cartAnalyticsData, setCartAnalyticsData] = useState([]);

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);

    // for search
    const [search, setSearch] = useState({
        organizationID: "",
        outletIDs: [],
        dateFrom: dateTimeFormatterFilter(),
        dateTo: null,
        employeeID: null
    });
    const toggle = () => setOpen(!open);
    const [open, setOpen] = useState(false);
    const [dateRange, setDateRange] = useState({});

    //change document title
    useDocumentTitle(`Usee | ${t('analytics.label')}`);


    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(false);

    // popup dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        message: t("label.configureTranslations"),
        id: "",
        field: "",
        translations: {}
    });

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    /**
     * The rest endpoint to get the section default (add) or current (edit) information.
     */
    function getData() {

        getTableData();
    }

    function getTableData() {
        analyticsApi.getCartAnalytics(search).then((r) => {
            setData({
                ...data,
                outlets: r.data.returnobject.outlets
            });
            setCartAnalyticsData(r.data.returnobject.analytics);

            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }

    useEffect(() => {
        getData();
        setIsFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getTableData();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
    }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the pagination information.
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(page) {
        formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
    }

    /**
     * Function that handles the pagination information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        let pagination = data.pagination;
        pagination.size = size;
        pagination.page = 0;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }
    /**
     * Function that handles the sort information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        /**
         * datatable columns.
         */
        const table_columns = [
            {
                name: "name",
                label: t('analytics.cart.name'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.name')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return value;
                    }
                }
            },
            {
                name: "productRevenue",
                label: t('analytics.cart.productRevenue'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value;
                    }
                }
            },
            {
                name: "uniquePurchasesCount",
                label: t('analytics.cart.uniquePurchasesCount'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value;
                    }
                }
            },
            {
                name: "quantity",
                label: t('analytics.cart.quantity'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value;
                    }
                }
            },
            {
                name: "avgQuantity",
                label: t('analytics.cart.avgQuantity'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return parseFloat(value).toFixed(2);
                    }
                }
            },
            {
                name: "avgPrice",
                label: t('analytics.cart.avgPrice'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return parseFloat(value).toFixed(2);
                    }
                }
            },
        ];

        return (
            <CustomGrid role="main" id={"analytics"} sx={mainTag()}>
                {value === "items" &&
                    <Grid item xs={1}>
                        <CustomIconButton
                            keyValue={`btn-search-date`}
                            onClick={() => setOpen(!open)}
                            icon={<CalendarMonthIcon />}
                            iconSx={mainTagSearchItemOrderStatusIcon()}
                            sx={{ ...mainTagSearchItemOrderStatus(), borderRadius: "10px" }}
                        />

                        <CustomDialogBasedOnScreenChildren
                            isOpen={open}
                            handleClose={() => setOpen(false)}
                            handleOpen={() => setOpen(true)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            actionLabel={t('actions.ok')}
                            handleAction={() => setOpen(false)}
                            children={
                                <>
                                    <CustomMuiDateTimeRangePicker
                                        dateRange={search}
                                        setDateRange={setSearch}
                                    />
                                </>
                            }
                        />
                    </Grid>
                }

                <Grid item xs={12}>
                    <TabContext value={value}>
                        <CustomTabs
                            initValue={value}
                            instances={[
                                { name: t('analytics.tabs.revenue'), value: "revenue" },
                                { name: t('analytics.tabs.items'), value: "items" },
                                { name: t('analytics.tabs.employees'), value: "employees" },
                                { name: t('analytics.tabs.ratings'), value: "ratings" },
                                { name: t('analytics.tabs.general'), value: "general" },
                                { name: t('analytics.tabs.extra'), value: "extra" },
                            ]}
                            setValue={setValue}
                            sx={{ width: "100%" }}
                        />

                        <TabPanel value={"items"}>
                            <CustomGrid sx={mainAreaTable()} container={false}>
                                {isLoaded &&
                                    <CustomPageableTableWithFilters
                                        instances={cartAnalyticsData}
                                        emptyMessage={t('analytics.cart.emptyMessage')}
                                        table_columns={table_columns}
                                        keyField="id"
                                        defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                                        pagination={data.pagination}
                                        handlePaginationChange={handlePaginationChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        handleColumnSortChange={handleColumnSortChange}
                                    />
                                }
                            </CustomGrid>
                        </TabPanel>

                        <TabPanel value={"revenue"}>
                            <AnalyticsRevenue
                                currency={data.currency}
                            />
                        </TabPanel>

                        <TabPanel value={"employees"}>
                            <AnalyticsEmployees
                                search={search}
                            />
                        </TabPanel>

                        <TabPanel value={"ratings"}>
                            <AnalyticsRatings />
                        </TabPanel>

                        <TabPanel value={"general"}>
                            <AnalyticsGeneral
                                search={search}
                            />
                        </TabPanel>

                        <TabPanel value={"extra"}>
                            <AnalyticsExtra
                                search={search}
                            />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </CustomGrid>
        );
    }
}

export default AnalyticsComponent;