import {
    Grid
} from '@mui/material';
import { NavigateNextIcon } from 'assets/mui/MuiIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { deleteAccountHeaderTextStyle, deleteAccountIconStyle, deleteAccountTextStyle } from 'themes/defaultThemes';

/**
 * The DeleteAccountCard, that display the delete account section in settings
 * @param {fucntion} handleDeleteNavigationIconClick Callback that handles the click on the navigation icon
 *
 * @returns {JSX.Element} A React component that renders delete account section
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function DeleteAccountCard({ handleDeleteNavigationIconClick }) {
    const { t } = useTranslation();

    return (
        <Grid item xs={12} md={12} sx={{ marginTop: "60px" }}>
            <Grid container sx={{ textAlign: "left" }}>
                <Grid item xs={10} md={10}>
                    <CustomTypography variant="span" text={`${t('user.deleletAccount.label')}`} sx={deleteAccountHeaderTextStyle()} />
                </Grid>
                <Grid item xs={2} md={2}>
                    <NavigateNextIcon sx={deleteAccountIconStyle} onClick={handleDeleteNavigationIconClick} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <CustomTypography variant="span" text={`${t('user.deleletAccount.desc')}`} sx={deleteAccountTextStyle()} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DeleteAccountCard;