import { CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { Grid } from '@mui/material';
import analyticsApi from 'api/analytics';
import { ChartLoading } from 'assets/lottie/LottieAnimations';
import CustomIconButton from 'components/Button/CustomIconButton';
import RevenueMixChart from 'components/Charts/analyitics/RevenueMixChart';
import CustomMuiDateTimeRangePicker from 'components/DatePicker/CustomMuiDateTimeRangePicker';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import CustomTypography from 'components/Typography/CustomTypography';
import { lottieChartOptions } from 'constants/animations/lottieOptions';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsGridDiv, lumanosimoTitleStyle, mainTagSearcAnalytics, mainTagSearchItemOrderStatusIcon } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { dateTimeFormatterFilter } from 'utils/functions';

/**
 * The AnalyticsRevenue, that display customized {@link Grid}to provide the list of analytics revenue details
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AnalyticsRevenue({ currency }) {
    const { t } = useTranslation();
    const [revenueAnalyticsData, setRevenueAnalyticsData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    // for search
    const [search, setSearch] = useState({
        organizationID: "",
        outletIDs: [],
        dateFrom: dateTimeFormatterFilter(),
        dateTo: null,
        dateFormat: "%d-%m-%Y"
    });
    const [open, setOpen] = useState(false);

    function getData() {
        analyticsApi.getRevenue(search).then((r) => {
            setRevenueAnalyticsData(r.data?.returnobject?.analytics);//?.map((instance) => instance.id !== null));
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <>
            <Grid container gap={1} sx={{ textAlign: "center", placeContent: "center" }}>
                {/* <Grid item xs={2} sm={1}>
                    <CustomIconButton
                        keyValue={`btn-search-sec`}
                        label={t('analytics.chart.revenue.sec')}
                        onClick={() => formValChangeWithParentElementWithNameAndValue("dateFormat", "%d-%m-%Y %H:%M:%S", search, setSearch)}
                        iconSx={mainTagSearchItemOrderStatusIcon()}
                        sx={mainTagSearcAnalytics()}
                        backgroundColor={"#47a6d2"}
                        isFocused={search.dateFormat === "%d-%m-%Y %H:%M:%S"}
                    />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <CustomIconButton
                        keyValue={`btn-search-min`}
                        label={t('analytics.chart.revenue.min')}
                        onClick={() => formValChangeWithParentElementWithNameAndValue("dateFormat", "%d-%m-%Y %H:%M", search, setSearch)}
                        iconSx={mainTagSearchItemOrderStatusIcon()}
                        sx={mainTagSearcAnalytics()}
                        backgroundColor={"#47a6d2"}
                        isFocused={search.dateFormat === "%d-%m-%Y %H:%M"}
                    />
                </Grid> */}
                <Grid item xs={2} sm={1}>
                    <CustomIconButton
                        keyValue={`btn-search-hour`}
                        label={t('analytics.chart.revenue.hour')}
                        onClick={() => formValChangeWithParentElementWithNameAndValue("dateFormat", "%d-%m-%Y %H", search, setSearch)}
                        iconSx={mainTagSearchItemOrderStatusIcon()}
                        sx={mainTagSearcAnalytics()}
                        backgroundColor={"#47a6d2"}
                        isFocused={search.dateFormat === "%d-%m-%Y %H"}
                    />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <CustomIconButton
                        keyValue={`btn-search-day`}
                        label={t('analytics.chart.revenue.day')}
                        onClick={() => formValChangeWithParentElementWithNameAndValue("dateFormat", "%d-%m-%Y", search, setSearch)}
                        iconSx={mainTagSearchItemOrderStatusIcon()}
                        sx={mainTagSearcAnalytics()}
                        backgroundColor={"#47a6d2"}
                        isFocused={search.dateFormat === "%d-%m-%Y"}
                    />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <CustomIconButton
                        keyValue={`btn-search-month`}
                        label={t('analytics.chart.revenue.month')}
                        onClick={() => formValChangeWithParentElementWithNameAndValue("dateFormat", "%m-%Y ", search, setSearch)}
                        iconSx={mainTagSearchItemOrderStatusIcon()}
                        sx={mainTagSearcAnalytics()}
                        backgroundColor={"#47a6d2"}
                        isFocused={search.dateFormat === "%m-%Y"}
                    />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <CustomIconButton
                        keyValue={`btn-search-year`}
                        label={t('analytics.chart.revenue.year')}
                        onClick={() => formValChangeWithParentElementWithNameAndValue("dateFormat", "%Y ", search, setSearch)}
                        iconSx={mainTagSearchItemOrderStatusIcon()}
                        sx={mainTagSearcAnalytics()}
                        backgroundColor={"#47a6d2"}
                        isFocused={search.dateFormat === "%Y"}
                    />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <CustomIconButton
                        keyValue={`btn-search-date`}
                        onClick={() => setOpen(!open)}
                        icon={<CalendarMonthIcon />}
                        iconSx={mainTagSearchItemOrderStatusIcon()}
                        sx={mainTagSearcAnalytics()}
                    />
                    <CustomDialogBasedOnScreenChildren
                        isOpen={open}
                        handleClose={() => setOpen(false)}
                        handleOpen={() => setOpen(true)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        actionLabel={t('actions.ok')}
                        handleAction={() => setOpen(false)}
                        children={
                            <>
                                <CustomMuiDateTimeRangePicker
                                    dateRange={search}
                                    setDateRange={setSearch}
                                />
                            </>
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <div style={{ ...analyticsGridDiv(), width: "100%" }}>
                        {(isLoaded && revenueAnalyticsData?.length > 0) &&
                            <>
                                <RevenueMixChart
                                    currency={currency}
                                    data={revenueAnalyticsData}
                                    isHour={search.dateFormat === "hour"}
                                />
                            </>
                        }
                        {(!isLoaded || revenueAnalyticsData?.length === 0) &&
                            <>
                                <Lottie
                                    animationData={ChartLoading}
                                    {...lottieChartOptions}
                                />

                                <CustomTypography
                                    variant="body2"
                                    text={t('analytics.chart.noData')}
                                    sx={{ lumanosimoTitleStyle }}
                                />
                            </>
                        }
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default AnalyticsRevenue;