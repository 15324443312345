import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import { formatISOWithTimezone } from 'utils/functions/date';

/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function paymentMethodPieChartDataHistoryBar(search) {
    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "") {
        data = { organizationID: search.organizationID };
    }
    if (search.outletIDs.length > 0) {
        data = { ...data, outletIDs: search.outletIDs };
    }
    if (search.employeeID !== undefined && search.employeeID !== "" && search.employeeID !== null) {
        data = { ...data, employeeID: search.employeeID };
    }
    if (search.dateFrom !== null && search.dateFrom !== "") {
        data = { ...data, dateFrom: formatISOWithTimezone(new Date(search.dateFrom), true) };
    }
    if (search.dateTo !== null && search.dateTo !== "") {
        data = { ...data, dateTo: formatISOWithTimezone(new Date(search.dateTo), true) };
    }
    if (search.forPDA !== null && search.forPDA !== "" && search.forPDA !== undefined) {
        data = { ...data, forPDA: search.forPDA };
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/payment-method/history/bar`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/payment-method/history/bar`));
}

/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function paymentMethodPieChartDataHistoryPie(search) {
    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "") {
        data = { organizationID: search.organizationID };
    }
    if (search.outletIDs.length > 0) {
        data = { ...data, outletIDs: search.outletIDs };
    }
    if (search.employeeID !== undefined && search.employeeID !== "" && search.employeeID !== null) {
        data = { ...data, employeeID: search.employeeID };
    }
    if (search.dateFrom !== null && search.dateFrom !== "") {
        data = { ...data, dateFrom: formatISOWithTimezone(new Date(search.dateFrom), true) };
    }
    if (search.dateTo !== null && search.dateTo !== "") {
        data = { ...data, dateTo: formatISOWithTimezone(new Date(search.dateTo), true) };
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/payment-method/history/pie`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/payment-method/history/pie`));
}


/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function paymentMethodPieChartDataHistoryEmployee(search) {
    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "") {
        data = { organizationID: search.organizationID };
    }
    if (search.outletIDs.length > 0) {
        data = { ...data, outletIDs: search.outletIDs };
    }
    if (search.employeeID !== undefined && search.employeeID !== "" && search.employeeID !== null) {
        data = { ...data, employeeID: search.employeeID };
    }
    if (search.dateFrom !== null && search.dateFrom !== "") {
        data = { ...data, dateFrom: formatISOWithTimezone(new Date(search.dateFrom), true) };
    }
    if (search.dateTo !== null && search.dateTo !== "") {
        data = { ...data, dateTo: formatISOWithTimezone(new Date(search.dateTo), true) };
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/payment-method/employee`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/payment-method/employee`));
}

/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function paymentMethodPieChartData(search) {

    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "") {
        data = { organizationID: search.organizationID };
    }
    if (search.outletIDs.length > 0) {
        data = { ...data, outletIDs: search.outletIDs };
    }
    if (search.dateFrom !== null && search.dateFrom !== "") {
        data = { ...data, dateFrom: formatISOWithTimezone(new Date(search.dateFrom), true) };
    }
    if (search.dateTo !== null && search.dateTo !== "") {
        data = { ...data, dateTo: formatISOWithTimezone(new Date(search.dateTo), true) };
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/payment-method/pie`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/payment-method/pie`));
}


/**
 * The rest request which attempts to fetch analytic details for cart (table). 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details for cart
 */
export async function getCartAnalytics(search) {
    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "") {
        data = { organizationID: search.organizationID };
    }
    if (search.outletIDs.length > 0) {
        data = { ...data, outletIDs: search.outletIDs };
    }
    if (search.dateFrom !== null && search.dateFrom !== "") {
        data = { ...data, dateFrom: formatISOWithTimezone(new Date(search.dateFrom), true) };
    }
    if (search.dateTo !== null && search.dateTo !== "") {
        data = { ...data, dateTo: formatISOWithTimezone(new Date(search.dateTo), true) };
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/cart`, data));
    else return await request.get(`${API}analytics/cart`);
}

/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function getRevenue(search) {
    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "" && search.organizationID !== null) {
        data = { organizationID: search.organizationID };
    }
    if (search.outletIDs.length > 0) {
        data = { ...data, outletIDs: search.outletIDs };
    }
    if (search.dateFrom !== null && search.dateFrom !== "") {
        data = { ...data, dateFrom: formatISOWithTimezone(new Date(search.dateFrom), true) };
    }
    if (search.dateTo !== null && search.dateTo !== "") {
        data = { ...data, dateTo: formatISOWithTimezone(new Date(search.dateTo), true) };
    }
    if (search.dateFormat !== null && search.dateFormat !== "") {
        data = { ...data, dateFormat: search.dateFormat };
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/revenue`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/revenue`));
}

/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function fetchGeneral(search) {
    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "" && search.organizationID !== null) {
        data = { organizationID: search.organizationID };
    }
    if (search.outletIDs.length > 0) {
        data = { ...data, outletIDs: search.outletIDs };
    }
    if (search.dateFrom !== null && search.dateFrom !== "") {
        data = { ...data, dateFrom: formatISOWithTimezone(new Date(search.dateFrom), true) };
    }
    if (search.dateTo !== null && search.dateTo !== "") {
        data = { ...data, dateTo: formatISOWithTimezone(new Date(search.dateTo), true) };
    }
    if (search.employeeID !== null && search.employeeID !== "") {
        data = { ...data, employeeID: search.employeeID };
    }
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/general`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/general`));
}

/**
 * The rest request which attempts to fetch analytic details for order who method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details for order who method chart
 */
export async function fetchOrderAnalytics(search) {
    let data = {};
    if (search.organizationID !== undefined && search.organizationID !== "" && search.organizationID !== null) {
        data = { organizationID: search.organizationID };
    }
    if (search.outletIDs.length > 0) {
        data = { ...data, outletIDs: search.outletIDs };
    }
    if (search.dateFrom !== null && search.dateFrom !== "") {
        data = { ...data, dateFrom: formatISOWithTimezone(new Date(search.dateFrom), true) };
    }
    if (search.dateTo !== null && search.dateTo !== "") {
        data = { ...data, dateTo: formatISOWithTimezone(new Date(search.dateTo), true) };
    }
    if (search.employeeID !== null && search.employeeID !== undefined && search.employeeID !== "") {
        data = { ...data, employeeID: search.employeeID };
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/order`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/order`));
}

const analyticsApi = {
    paymentMethodPieChartDataHistoryEmployee,
    paymentMethodPieChartDataHistoryPie,
    paymentMethodPieChartDataHistoryBar,
    paymentMethodPieChartData,
    getCartAnalytics,
    getRevenue,
    fetchGeneral,
    fetchOrderAnalytics
}

export default analyticsApi;