import CustomItemModifierDraggableTableItem from "components/Table/CustomItemModifierDraggableTableItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { mainAreaBodyDraggableTable } from 'themes/defaultThemes';

/**
 * A Custom Draggable List.
 * @property {array} item the array of the modifier-lists
 * @property {boolean} showEmptyMessage to specify if the empty list message should be shown
 * @property {string} emptyMessage The message to presented if the `label` is empty
 * @property {*} removeItemOrModifier function that removes the item/modifier instance
 * @property {string} organizationID The id of the organization
 * @property {string} field HAs value `modifiers` for the attachedModifierIds field or `items` for the attachedItemIds
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomItemModifierDraggableTable({ item, showEmptyMessage = true, emptyMessage, removeItemOrModifier, field, organizationID, updateModifierOrder, enableDraggable = false, categoryID }) {

    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = async (result) => {
        const { source, destination } = result;

        if (!destination) return; // Item was dropped outside of the list
        const instances = reorder(item, result.source.index, result.destination.index);
        updateModifierOrder(instances, categoryID)
    }

    return (
        <>
            {(showEmptyMessage && (item === null || item.length === 0)) &&
                <div className="body small" style={mainAreaBodyDraggableTable()}>
                    {emptyMessage}
                </div>
            }

            {(item !== null && item.length > 0) &&
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`droppable-${field}-list`}>
                        {(provided, snapshot) => (
                            <ul className={`droppable-${field}-list`} ref={provided.innerRef} {...provided.droppableProps} style={{
                                background: snapshot.isDraggingOver ? "lightblue" : '',
                                borderRadius: "4px",
                                width: "85%",
                                marginLeft: "85px"
                            }} >
                                {item.map((item, index) => (
                                    <CustomItemModifierDraggableTableItem
                                        item={item}
                                        index={index}
                                        key={item.id}
                                        remove={removeItemOrModifier}
                                        field={field}
                                        organizationID={organizationID}
                                        enableDraggable={enableDraggable}
                                        isMenu={true}
                                    />
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </>
    );
}

export default CustomItemModifierDraggableTable;