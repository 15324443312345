function readFileAsBase64(file) {
  return new Promise((resolve, reject) => {
    // Create a new FileReader instance
    const reader = new FileReader();

    // Set up the onload callback to resolve the promise with the base64 string
    reader.onload = () => {
      resolve(reader.result);
    };

    // Set up the onerror callback to reject the promise with the error message
    reader.onerror = () => {
      reject(new Error("Failed to read file as base64"));
    };

    // Read the file as a Data URL
    reader.readAsDataURL(file);
  });
}

export default readFileAsBase64;
