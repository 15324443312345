import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

/**
 * The Custom Button, that display an button.
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSearchButton({ label, icon, onClick, sx, iconSx }) {
    return (
        <ThemeProvider theme={createTheme({
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#fff",
                            margin: "0px",
                            fontSize: "8px",
                            fontWeight: "900",
                            color: "#2596be",
                            ":hover": {
                                backgroundColor: "#000"
                            }
                        },
                        startIcon: {
                            marginRight: "0px!important"
                        }
                    }
                }
            }
        })}>
            <Button startIcon={<SearchIcon style={{ marginRight: "0px" }} />} onClick={onClick} style={{ backgroundColor: "#54b1d2", borderRadius: "50px", color: "white", padding: "8px 0px", minWidth: "40px", marginRight: "10px" }} className=" tr link">{label}</Button>
        </ThemeProvider>
    );
}

export default CustomSearchButton;