import Visibility from '@mui/icons-material/Visibility';
import { Badge, Card, Grid } from '@mui/material';
import { FontAwesomeIcon, faMoneyBill } from "assets/fontAwesome/FontAwesomeIcons";
import { AddIcon, ShoppingCartIcon, WavingHandIcon } from 'assets/mui/MuiIcons';
import "assets/scss/swipeable-list.scss";
import CustomIconButton from 'components/Button/CustomIconButton';
import { useTranslation } from 'react-i18next';
import { getPDAEquipmentActionButton, getPDAEquipmentActionButtonIcon, getPDAEquipmentButtonFontAwesomeIcon } from 'themes/defaultThemes';

/**
 * The CustomPDAFirstPageCard, that display a custom {@link Card} for PDA first page
 * 
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPDAFirstPageCard({ handlePay, handleCreateOrder, handleViewOrders, handleViewRequests, unpaidOrderCount, pendingRequestsCount = 0 }) {
    const { t } = useTranslation();

    return (
        <>
            {unpaidOrderCount > 0 &&
                <Grid item xs={6}>
                    <CustomIconButton
                        {...getPDAEquipmentActionButton("pay", t('actions.pay'))}
                        icon={
                            <Badge badgeContent={unpaidOrderCount} color={"error"}>
                                <FontAwesomeIcon icon={faMoneyBill} style={getPDAEquipmentButtonFontAwesomeIcon()} />
                            </Badge>
                        }
                        onClick={handlePay}
                    />
                </Grid>
            }

            <Grid item xs={6}>
                <CustomIconButton
                    {...getPDAEquipmentActionButton("order", `${t('actions.add')} ${t('order.plain')}`)}
                    icon={
                        <Badge badgeContent={<AddIcon sx={{ fontSize: "10px" }} />} color="primary">
                            <ShoppingCartIcon {...getPDAEquipmentActionButton()} sx={getPDAEquipmentActionButtonIcon()} />
                        </Badge>
                    }
                    onClick={handleCreateOrder}
                />
            </Grid>

            <Grid item xs={6}>
                <CustomIconButton
                    {...getPDAEquipmentActionButton("request", `${t('actions.view')} ${t('request.pageTitle')}`)}
                    icon={
                        <Badge badgeContent={pendingRequestsCount} color={"error"} >
                            <WavingHandIcon {...getPDAEquipmentActionButton()} sx={getPDAEquipmentActionButtonIcon()} />
                        </Badge>
                    }
                    onClick={handleViewRequests}
                />
            </Grid>

            <Grid item xs={6}>
                <CustomIconButton
                    {...getPDAEquipmentActionButton("order", `${t('actions.view')} ${t('order.title')}`)}
                    icon={
                        <Badge badgeContent={<Visibility sx={{ fontSize: "10px" }} />} color={"primary"} >
                            <ShoppingCartIcon {...getPDAEquipmentActionButton()} sx={getPDAEquipmentActionButtonIcon()} />
                        </Badge>
                    }
                    onClick={handleViewOrders}
                />

            </Grid>
        </>
    );
}

export default CustomPDAFirstPageCard;