import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Save as SaveIcon,
    SignLanguage as SignLanguageIcon,
    WorkOff as WorkOffIcon
} from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Button, Divider, Fab, FormControlLabel, Grid, InputLabel, Switch, TextField } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import itemApi from 'api/item';
import itemOfferApi from "api/itemOffer";
import en from "assets/images/flags/en.png";
import CustomAddEditBreadcrumbMultiParent from "components/Breadcrumb/CustomAddEditBreadcrumbMultiParent";
import CustomButton from 'components/Button/CustomButton';
import CustomIconButton from 'components/Button/CustomIconButton';
import AttachItemOrModifierDialog from 'components/Dialogs/AttachItemOrModifierDialog';
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";
import CustomCollapsableFieldset from 'components/Fieldset/CustomCollapsableFieldset';
import CustomGrid from 'components/Grid/CustomGrid';
import PhotoComponent from 'components/Grid/photo/PhotoComponent';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSelectMultilingual from 'components/Select/CustomSelectMultilingual';
import CustomSelectObjectWithChipAndIcon from 'components/Select/CustomSelectObjectWithChipAndIcon';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTabs from 'components/Tab/CustomTabs';
import CustomItemCategoriesDraggableTable from 'components/Table/draggable/item/category/CustomItemCategoriesDraggableTable';
import CustomFlagTextArea from 'components/Text/CustomFlagTextArea';
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import CustomTextField from 'components/Text/CustomTextField';
import CustomAddEditTitle from "components/Title/CustomAddEditTitle";
import { getAssetDefaultObject, getDefaultItemCategory, getDefaultItemOffer } from 'constants/defaultObjects';
import { getItemLoyaltyPoints, getItemOfferPercentage, getItemPrice, getItemPvcRecyclingTax } from 'constants/fieldProperties';
import { getThemeColor } from 'constants/theme';
import { getWeekDays } from 'constants/weekdays';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { addCardActionAreaButtonStyle, addWorkingHoursButtonIconStyle, cardItemButtonGroupStackButtonIcon, fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, textFieldError } from 'themes/defaultThemes';
import { hasRoleAdminMenu } from 'utils/auth';
import { formIsValid, formValChangeWithParentElementSelect, formValChangeWithParentElementWithNameAndValue, formValChangeWithParents, onControlChange, onSelectChange } from "utils/form-validation";
import { validateInputNumber } from 'utils/functions';
import { generateObjectId } from 'utils/functions/mongo';
import { getLanguageFromURL } from "utils/language";
import { getTheme } from 'utils/theme';
import useDocumentTitle from "utils/useDocumentTitle";


/**
 * The ItemOfferAddEditComponent that allow the ADMIN to create a 
 * new item or edit an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ItemOfferAddEditComponent() {
    const { t } = useTranslation();

    const { organizationID } = useParams();
    const { offerID } = useParams();
    const navigate = useNavigate();
    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    // if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/offer`;

    /**
     * @type {object}
     * @property {boolean} redirect if a redirect should be performed
     * @property {boolean} isLoaded to render DOM based on rest api call status, if true the rest api call completed
     * @property {object} userAuth the authenticated user infromation
     * @property {object} organization the organization basic details (id and name) 
     * @property {object} outlet tha outlet basic details (id and name)
     * @property {string} offerId the offer id to get the current information for edit offer page
     * @property {object} offer the offer details
     * @property {*} icon the legend fa icon
     * @property {object} alertBox holds snackbar infromation and style
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        userAuth: null,
        isAdd: offerID === undefined,
        organization: {
            id: organizationID,
            name: ""
        },
        offer: null,
        icon: AddIcon,
        itemCategories: [],
        items: [],
        outlets: [],
        loyaltyCategories: [],
        types: [],
        currency: "€",
        // error messages per field
        isError: {
            name: "",
            description: "",
            categoryName: "",
            price: ""
        }
    });

    const [value, setValue] = React.useState("details");

    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu offer item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);

    const weekDays = getWeekDays();

    // popup dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        message: t("label.configureTranslations"),
        id: "",
        field: "",
        instance: {},
        translations: {}
    });
    // popup dialog for delete
    const [attachDialog, setAttachDialog] = useState({
        isOpen: false,
        field: "",
        category: null
    });

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        action: undefined,
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getItem();
    }, []);

    //change document title
    useDocumentTitle(`Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t('org.pageTitle') : ""} | ${t('offer.title')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`);

    /**
     * The rest endpoint to get the offer item.
     */
    function getItem() {
        if (data.isAdd) {
            itemOfferApi.fetchNew(organizationID).then((r) => {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    offer: getDefaultItemOffer(),
                    organization: r.data.returnobject.organization,
                    itemCategories: r.data.returnobject.itemCategories,
                    items: r.data.returnobject.items,
                    outlets: r.data.returnobject.outlets,
                    loyaltyCategories: r.data.returnobject.loyaltyCategories,
                    types: r.data.returnobject.types
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            itemOfferApi.fetchOne(organizationID, offerID).then((r) => {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    offer: r.data.returnobject.offer,
                    organization: r.data.returnobject.organization,
                    itemCategories: r.data.returnobject.itemCategories,
                    items: r.data.returnobject.items,
                    outlets: r.data.returnobject.outlets,
                    loyaltyCategories: r.data.returnobject.loyaltyCategories,
                    types: r.data.returnobject.types
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            });
        }
    }

    /**
     * Gets called when the user clicks on the save button, and triggers 
     * the creating of the offer item.
     */
    function addItemOffer(redirectAfter = false) {
        if (formValidation()) {
            let offer = data.offer;
            offer.organizationID = data.organization.id;
            itemOfferApi.create(offer).then((r) => {
                let message = "message" in r.data ?
                    (r.data.code === "SUCCESS") ?
                        `${t('offer.pageTitle')} "${r.data.returnobject.name.en}" ${t('actions.createSuccessfully')}`
                        :
                        r.data.message
                    : t("errorPages.somethingWentWrong")
                Promise.resolve()
                    .then(() => {
                        setAlertBox({
                            isOpen: true,
                            message: message,
                            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313",
                            action: (r.data.code === "SUCCESS") ? <Button color="secondary" size="small" onClick={() => window.location.href = `${path}/${r.data.returnobject.id}`} sx={{ background: "white" }}>{t('actions.view')}</Button> : undefined
                        });
                    })
                    .then(() => {
                        if (!redirectAfter)
                            window.location.reload();
                    })
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the modification of the offer item
     */
    function updateItemOffer() {
        if (formValidation()) {
            itemOfferApi.update(data.offer).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313",
                    action: undefined
                });
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }


    /**
     * Function that triggers form validation.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns the object with the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.name = data.offer.name.en.length < 1 ? t('form.requiredField') : "";
        isError.price = (data.offer.price?.length < 0 && data.offer.percentage?.length < 0) ?
            t('offer.errorPricePercentageEmpty')
            : (data.offer.price?.length > 0 && data.offer.percentage?.length > 0) ?
                t('offer.errorPricePercentageEmpty')
                : "";
        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleDialogState(isOpen, field = "", instance) {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            field: field,
            instance: instance,
            translations: data.offer[field]
        });
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleAttachDialogState(isOpen, field, dialogName, category) {
        if (isOpen) {
            itemApi.fetchListDialog(data.organization.id, false)
                .then((r) => {
                    formValChangeWithParentElementWithNameAndValue("items", r.data.returnobject, data, setData);
                })
                .catch((e) => {
                    // console.log(e);
                    setAlertBox({
                        isOpen: true,
                        message: t("errorPages.somethingWentWrong"),
                        backgroundColor: "#ef5350",
                    });
                });
        }

        setAttachDialog({
            ...attachDialog,
            isOpen: isOpen,
            field: field,
            category: category

        });
    }


    // ===================================== PHOTOS
    /**
     * Callback that handles file upload change.
     * @param {string} fileBase64 the new base64 image 
     */
    function handleFileChange(fileBase64) {
        let newPhotos = data.offer.photos;
        newPhotos.push(getAssetDefaultObject(fileBase64, data.offer.photos.length));
        formValChangeWithParentElementWithNameAndValue("offer.photos", newPhotos, data, setData, t('table.valueReuired'));
    };

    function updatePhotoHiddenState(event, index) {
        const updatedPhotos = data.offer.photos.map((photo, i) => {
            if (i === index) {
                return {
                    ...photo,
                    hidden: !photo.hidden,
                };
            }
            return photo;
        });
        formValChangeWithParentElementWithNameAndValue("offer.photos", updatedPhotos, data, setData);
    }

    function updatePhotoCoverState(event, index) {
        let updatedPhotos = data.offer.photos.map((photo, i) => {
            return {
                ...photo,
                cover: (i === index) ? true : false
            };
        });
        formValChangeWithParentElementWithNameAndValue("offer.photos", updatedPhotos, data, setData);
    }

    function deletePhoto(event, index) {
        const updatedPhotos = data.offer.photos;
        if (index > -1) updatedPhotos.splice(index, 1);
        formValChangeWithParentElementWithNameAndValue("offer.photos", updatedPhotos, data, setData);
    }

    /**
    * The rest endpoint to update offer item photos order
    * 
    * @param {array} photos  the photos after reorder
    */
    function updatePhotoOrder(photos) {
        formValChangeWithParentElementWithNameAndValue("offer.photos", photos, data, setData);
    }

    // ================================== TRANSLATIONS

    /**
     * This function will trigger the offer item field translations configuration
     */
    function configureItemTranslations(translations, field) {
        if (field === "categories") {
            updateCategoryNameTranslations(dialog.instance, translations)
        } else {
            document.querySelectorAll(`[name="offer.en"]`)[0].value = translations.en;
            setData({
                ...data,
                offer: {
                    ...data.offer,
                    [field]: translations
                }
            });
            setDialog({
                ...dialog,
                isOpen: false,
                field: "",
                instance: null,
                translations: null
            });
        }
    }

    /**
     * Function to trigger the remove of the offer item.
     * @property {*} event
     * @property {string} field The field of the selected offer item field.
     */
    function configureTranslations(event, field, instance) {
        handleDialogState(true, field, instance);
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     * @property {object} instance The instance details to have name field translated
     */
    function handleTranslationsDialogState(isOpen, field = "", instance) {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            field: field,
            instance: instance,
            translations: instance.name
        });
    }

    // ========================= WORKING HOURS
    function handleStartEndChange(day, index, field, value) {
        const newWorkingHours = { ...data.offer.hours };
        newWorkingHours[day][index][field] = value;
        formValChangeWithParentElementWithNameAndValue("offer.hours", newWorkingHours, data, setData);
    }

    const handleOpenChange = (day, index, value) => {
        const newWorkingHours = { ...data.offer.hours };
        newWorkingHours[day][index].open = value;
        formValChangeWithParentElementWithNameAndValue("offer.hours", newWorkingHours, data, setData);
    };

    const handleAddStartEnd = (day) => {
        const newWorkingHours = { ...data.offer.hours };
        newWorkingHours[day].push({ start: null, end: null, open: false });
        formValChangeWithParentElementWithNameAndValue("offer.hours", newWorkingHours, data, setData);
    };

    function removeWorkingHour(day, index) {
        let newwWorkingHours = { ...data.offer.hours };
        newwWorkingHours[day].splice(index, 1);
        formValChangeWithParentElementWithNameAndValue("offer.hours", newwWorkingHours, data, setData);
    }

    // ================================== Categories

    /**
     * Include a category to offer
     *
     */
    function addCategoryToOffer() {
        let categoryName = document.getElementById(`categoryName`).value;

        if (categoryName !== "") {
            let category = getDefaultItemCategory(categoryName);
            document.getElementById(`categoryName`).error = false;
            let newCategories = data.offer?.categories;

            let newCategory = {
                id: generateObjectId(),
                ...category
            }
            newCategories.push(newCategory);

            document.getElementById(`categoryName`).value = "";
            formValChangeWithParentElementWithNameAndValue("offer.categories", newCategories, data, setData);
        } else {
            document.getElementById(`categoryName`).error = true;
        }
    }

    /**
     * The rest endpoint to update an offer categories order
     * 
     * @param {array} categories  the offer categories list after reorder
     */
    function updateItemCategoriesOrder(categories) {
        formValChangeWithParentElementWithNameAndValue("offer.categories", categories, data, setData);
    }

    /**
     * The callback to update a category's name
     * 
     * @param {object} category the category instance
     * @param {string} name the new name
     */
    function updateCategoryName(category, name) {
        if (name !== "") {
            category.name.en = name;
            let newCategories = data.offer?.categories?.map(obj => (category.id === obj.id) ? category : obj);
            formValChangeWithParentElementWithNameAndValue("offer.categories", newCategories, data, setData);
        }
    }

    /**
    * The callback to update a menu category name translations
    * 
    * @param {object} category the category instance
    * @param {object} translations the new name translations 
    */
    function updateCategoryNameTranslations(category, translations) {
        if (translations.en !== "") {
            category.name = translations;
            let newCategories = data.offer?.categories?.map(obj => (category.id === obj.id) ? category : obj);

            setDialog({
                ...dialog,
                translations: {},
                field: "",
                instance: {},
                isOpen: false
            });

            formValChangeWithParentElementWithNameAndValue("offer.categories", newCategories, data, setData);
        }
    }

    /**
     * The rest endpoint to activate/disable an offer category
     * @param {object} category the category instance
     * @param {boolean} isActive the new isActive state
     */
    function updateCategoryEnabled(category, isActive) {
        category.active = isActive;
        let newCategories = data.offer?.categories?.map(obj => (category.id === obj.id) ? category : obj);

        formValChangeWithParentElementWithNameAndValue("offer.categories", newCategories, data, setData);
    }

    /**
     * Function that triggers the removal of an offer category.
     * 
     * @param {object} categoryToDelete the category instance to be deleted
     */
    function removeCategory(categoryToDelete) {
        let newCategories = data.offer?.categories;
        const index = newCategories.indexOf(categoryToDelete);
        // only splice array when item is found
        if (index > -1) newCategories.splice(index, 1); // 2nd parameter means remove one item only

        formValChangeWithParentElementWithNameAndValue("offer.categories", newCategories, data, setData);
    }

    // ===================================================== ITEM

    /**
     * This function will trigger the inclusion of the selected items to the offer category
     */
    function addItemOfferCategoryItems(items, field, category) {
        let newItemIds = items?.map(item => item.id);
        let newCategories = data.offer?.categories?.map((obj) => {
            if (category.id === obj.id) {
                obj.itemIds = [...obj.itemIds, ...newItemIds];
            }
            return obj;
        });

        formValChangeWithParentElementWithNameAndValue("offer.categories", newCategories, data, setData);

        setAttachDialog({
            ...attachDialog,
            isOpen: false,
            field: "",
            category: {}
        });
    }

    /**
     * Function that triggers the removal of a item.
     * 
     * @param {string} categoryId the category id that the item is attached
     * @param {object} itemToDelete the item instance to be deleted
     */
    function removeItemFromCategory(event, itemToDelete, field, category) {
        let newItems = category.itemIds;
        const index = newItems.indexOf(itemToDelete.id);
        // only splice array when item is found
        if (index > -1) newItems.splice(index, 1); // 2nd parameter means remove one item only

        category.itemIds = newItems;
        let newCategories = data.offer?.categories?.map((obj) => {
            if (category.id === obj.id) {
                const itemIndex = obj.itemIds.indexOf(itemToDelete.id);
                if (itemIndex > -1) obj.itemIds.splice(index, 1)

            }
            return obj;
        });
        formValChangeWithParentElementWithNameAndValue("offer.categories", newCategories, data, setData);
    }

    /**
    * The rest endpoint to update an offer category items order
    * 
    * @param {object} category the category details
    * @param {array} items  the new items list after reorder
    */
    function updateItemOrder(items, category) {
        category.itemIds = items?.map(obj => obj.id);
        let newCategories = data.offer?.categories.map(obj => (category.id === obj.id) ? category : obj);
        formValChangeWithParentElementWithNameAndValue("offer.categories", newCategories, data, setData);
    }

    /**
     * Function that triggers the removal of an Id.
     * @param {*} idToDelete 
     */
    function removeId(event, idToDelete, field) {
        let ids = data.offer[field];
        const index = ids.indexOf(idToDelete);
        // only splice array when item is found
        if (index > -1) ids.splice(index, 1); // 2nd parameter means remove one item only
        setData({
            ...data,
            offer: {
                ...data.offer,
                [field]: ids
            }
        });
    }

    // ===============================================================================RENDER

    if (redirect) {
        navigate(path);
    } else if (!isLoaded) {
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        const { isError } = data;
        return (
            // ITEM ADD - EDIT
            <CustomGrid role="main" id={"offer-" + data.isAdd ? "add" : "edit"} sx={mainTag()}>
                {(organizationID !== undefined && !hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('offer.title'), url: `/${getLanguageFromURL()}/organization/${organizationID}/item-or-modifier` },
                            ]}
                            instanceName={(data.offer !== null) ? data.offer.name.en : ""}
                        />
                    </CustomGrid>
                }

                {(organizationID !== undefined && hasRoleAdminMenu(data.userAuth.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('offer.title'), url: `/${getLanguageFromURL()}/item-or-modifier` },
                            ]}
                            instanceName={(data.offer !== null) ? data.offer.name.en : ""}
                        />
                    </CustomGrid>
                }

                {organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('offer.title'), url: `/${getLanguageFromURL()}/offer` },
                            ]}
                            instanceName={(data.offer !== null) ? data.offer.name.en : ""}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    <CustomAddEditTitle
                        isAdd={data.isAdd}
                        title={t('offer.pageTitle')}
                        subtitle={t('offer.subtitle')}
                        instanceName={(data.offer !== null) ? data.offer.name.en : ""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="offer" onSubmit={(e) => { return false; }}>
                        {data.isAdd &&
                            <input type="hidden" />
                        }

                        {!data.isAdd && organizationID !== undefined &&
                            <input defaultValue={organizationID} name="organizationID" type="hidden" id="organizationID" />
                        }

                        {!data.isAdd && offerID !== undefined &&
                            <input defaultValue={offerID} name="id" type="hidden" id="id" />
                        }.

                        <TabContext value={value}>
                            <CustomTabs
                                initValue={value}
                                instances={[
                                    { name: t('offer.tabs.details'), value: "details" },
                                    { name: t('offer.tabs.photos'), value: "photos" },
                                    { name: t('offer.tabs.hours'), value: "hours" },
                                ]}
                                setValue={setValue}
                            />

                            <TabPanel value={"details"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="name">{t('label.name')}</InputLabel>
                                        <CustomFlagTextField
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="offer.name.en"
                                            id="name"
                                            onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                            defaultChecked={data.offer?.name?.en}
                                            onTranslationSelect={configureTranslations}
                                            field="name"
                                            error={isError.name.length > 0 ? true : false}
                                            helperText={isError.name}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="description">{t('label.description')}</InputLabel>
                                        <CustomFlagTextArea
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="offer.description.en"
                                            id="description"
                                            onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                            defaultValue={data.offer?.description?.en}
                                            onTranslationSelect={configureTranslations}
                                            field="description"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <InputLabel required={false} htmlFor="price">{t('offer.price')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            name="price"
                                            id="price"
                                            step={getItemPrice().step}
                                            min={getItemPrice().min}
                                            max={getItemPrice().max}
                                            value={data.offer.price}
                                            inputAdornmentAfterLabel={`offer.${data.currency}`}
                                            required={true}
                                            error={isError.price.length > 0 ? true : false}
                                            helperText={isError.price}
                                            sx={textFieldError()}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, getItemPrice().length, getItemPrice().min, getItemPrice().max, getItemPrice().isFloat);
                                                if (value === undefined) value = data.offer.price;
                                                formValChangeWithParentElementWithNameAndValue("offer.price", parseFloat(value), data, setData)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <InputLabel required={false} htmlFor="percentage">{t('offer.percentage')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            name="percentage"
                                            id="percentage"
                                            step={getItemOfferPercentage().step}
                                            min={getItemOfferPercentage().min}
                                            max={getItemOfferPercentage().max}
                                            value={data.offer.percentage}
                                            inputAdornmentAfterLabel={'offer.%'}
                                            required={true}
                                            error={isError.price.length > 0 ? true : false}
                                            helperText={isError.price}
                                            sx={textFieldError()}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, getItemOfferPercentage().length, getItemOfferPercentage().min, getItemOfferPercentage().max, getItemOfferPercentage().isFloat);
                                                if (value === undefined) value = data.offer.percentage;
                                                formValChangeWithParentElementWithNameAndValue("offer.percentage", parseFloat(value), data, setData)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <InputLabel required={false} htmlFor="points">{t('offer.loyaltyPoints')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            step={getItemLoyaltyPoints().step}
                                            min={getItemLoyaltyPoints().min}
                                            max={getItemLoyaltyPoints().max}
                                            name="offer.loyaltyPoints"
                                            value={data.offer.loyaltyPoints}
                                            inputAdornmentAfterLabel={'offer.points'}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, getItemLoyaltyPoints().length, getItemLoyaltyPoints().min, getItemLoyaltyPoints().max, getItemLoyaltyPoints().isFloat);
                                                if (value === undefined) value = data.offer.loyaltyPoints;
                                                formValChangeWithParentElementWithNameAndValue("offer.loyaltyPoints", parseInt(value), data, setData)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <InputLabel required={false} htmlFor="pvcRecyclingTax">{t('offer.pvcRecyclingTax')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            step={getItemPvcRecyclingTax().step}
                                            min={getItemPvcRecyclingTax().min}
                                            max={getItemPvcRecyclingTax().max}
                                            name="offer.pvcRecyclingTax"
                                            value={data.offer.pvcRecyclingTax}
                                            required={true}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, getItemPvcRecyclingTax().length, getItemPvcRecyclingTax().min, getItemPvcRecyclingTax().max, getItemPvcRecyclingTax().isFloat);
                                                if (value === undefined) value = data.offer.pvcRecyclingTax;
                                                formValChangeWithParentElementWithNameAndValue("offer.pvcRecyclingTax", parseInt(value), data, setData)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <InputLabel required={false} htmlFor="itemCategories">{t('itemCategories.label')}</InputLabel>
                                        <CustomSelectMultilingual
                                            id="itemCategories"
                                            isMultiple={true}
                                            name="offer.itemCategories"
                                            translationPath="itemCategories"
                                            required={true}
                                            value={data.offer.itemCategories}
                                            options={data.itemCategories}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("offer.itemCategories", event.target.value, data, setData)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputLabel required={false} htmlFor="type">{t('offer.type.label')}</InputLabel>
                                        <CustomSelectMultilingual
                                            id="type"
                                            isMultiple={false}
                                            name="offer.type"
                                            translationPath="offer.type"
                                            required={true}
                                            value={data.offer.type}
                                            options={data.types}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("offer.type", event.target.value, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <InputLabel required={false} htmlFor="outletIds">{t('outlet.title')}</InputLabel>
                                        <CustomSelectObjectWithChipAndIcon
                                            id="outletIds"
                                            name="offer.outletIds"
                                            isMultiple={true}
                                            required={false}
                                            defaultValue={data.offer.outletIds}
                                            options={data.outlets}
                                            onChange={(event) => onSelectChange(event, data, setData)}
                                            onDelete={(event, idToDelete) => removeId(event, idToDelete, "outletIds")}
                                        />
                                    </Grid>

                                    {data.loyaltyCategories?.length > 0 &&
                                        <Grid item xs={12} md={3}>
                                            <InputLabel required={false} htmlFor="loyaltyCategoryIds">{t('loyalty.category.label')}</InputLabel>
                                            <CustomObjectSelect
                                                id="loyaltyCategoryIds"
                                                name="offer.loyaltyCategoryIds"
                                                isMultiple={true}
                                                required={false}
                                                defaultValue={data.offer.loyaltyCategoryIds}
                                                options={data.loyaltyCategories}
                                                onChange={(event) => onSelectChange(event, data, setData)}
                                                onDelete={(event, idToDelete) => removeId(event, idToDelete, "loyaltyCategoryIds")}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={12} md={12}>
                                        <FormControlLabel control={<Switch defaultChecked={data.offer.enabled} onChange={(event) => onControlChange(event, data, setData, "offer", "enabled")} />} label={t('offer.enabled')} />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <CustomCollapsableFieldset
                                            sx={{ width: "100%", padding: "20px" }}
                                            label={t('offer.categories')}
                                            isOpen={true}
                                            children={<>
                                                <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "10px" }}>
                                                    <Grid item xs={12} md={12}>
                                                        <div style={{ width: "100%", display: "inline-grid", paddingTop: "0px" }}>
                                                            <CustomItemCategoriesDraggableTable
                                                                organizationID={organizationID}
                                                                // categories
                                                                categories={data.offer?.categories}
                                                                addCategoryToOffer={addCategoryToOffer}
                                                                updateCategoryName={updateCategoryName}
                                                                updateCategoryEnabled={updateCategoryEnabled}
                                                                updateItemCategoriesOrder={updateItemCategoriesOrder}
                                                                removeCategory={removeCategory}
                                                                //items
                                                                itemOptions={data.items}
                                                                handleDialogState={handleAttachDialogState}
                                                                removeItemFromCategory={removeItemFromCategory}
                                                                updateItemOrder={updateItemOrder}
                                                                // other
                                                                emptyMessage={t('menuCategories.emptyItemList')}
                                                                handleTranslationsDialogState={handleTranslationsDialogState}
                                                            />
                                                        </div>

                                                        <div style={{ width: "100%", display: "flex", marginTop: "30px" }}>
                                                            <CustomTextField
                                                                type="text" name="categoryName" id="categoryName" required={true}
                                                                label={t('menuCategories.newCategory')}
                                                                error={isError.categoryName.length > 0 ? true : false}
                                                                onChange={(event) => formValChangeWithParentElementSelect(event, data, setData, t('table.valueReuired'))}
                                                                onKeyUp={(e) => { if (e.key === 'Enter') { e.preventDefault(); addCategoryToOffer() } }}
                                                            />
                                                            <CustomButton
                                                                type="button"
                                                                id="btn-create-menu-category"
                                                                label={t('actions.create')}
                                                                sx={{ backgroundColor: "#54b1d2", color: "white", textTransform: "initial", fontSize: "12px" }}
                                                                sxHover={{ backgroundColor: "#34809b", color: "white", textTransform: "initial", fontSize: "12px" }}
                                                                onClick={addCategoryToOffer}
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            }
                                        />

                                        <Fab color="primary"
                                            aria-label="add"
                                            sx={fabStyle()}
                                            onClick={updateItemOffer}
                                        >
                                            <SaveIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"photos"} sx={{ paddingTop: "0px" }}>
                                <PhotoComponent
                                    photos={data.offer.photos}
                                    showEmptyMessage={true}
                                    emptyMessage={t('assets.emptyPhotos')}
                                    imagePath={`https://storage.usee.gr/usee/organization/${data.organization.id}/offer/${data.offer.id}/`}
                                    updatePhotoHiddenState={updatePhotoHiddenState}
                                    updatePhotoCoverState={updatePhotoCoverState}
                                    deletePhoto={deletePhoto}
                                    handleFileChange={handleFileChange}
                                    updatePhotoOrder={updatePhotoOrder}
                                />
                            </TabPanel>

                            <TabPanel value={"hours"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                    {weekDays.map((day, dayIndex) => (
                                        <Grid item xs={12} key={day}>
                                            <Grid container spacing={2} key={dayIndex}>
                                                <Grid item xs={12} md={12}>
                                                    <InputLabel required={false} htmlFor={day}>{t(`workingHours.${day}`)}</InputLabel>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    {data.offer?.hours[day]?.map((startEndDate, index) =>
                                                        <>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <Grid container spacing={2} key={index} sx={{ marginTop: 0, alignItems: "center" }}>
                                                                    <Grid item xs={12} md={4}>
                                                                        <DateTimePicker
                                                                            label={t('workingHours.start')}
                                                                            renderInput={(props) => <TextField {...props} />}
                                                                            value={startEndDate.start}
                                                                            onChange={(newValue) => handleStartEndChange(day, index, 'start', newValue)}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} md={4}>
                                                                        <DateTimePicker
                                                                            label={t('workingHours.end')}
                                                                            renderInput={(props) => <TextField {...props} />}
                                                                            value={startEndDate.end}
                                                                            onChange={(newValue) => handleStartEndChange(day, index, 'end', newValue)}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} md={2}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={6} md={6}>
                                                                                <CustomIconButton
                                                                                    keyValue="bold"
                                                                                    variant="outlined"
                                                                                    icon={<SignLanguageIcon />}
                                                                                    tooltipTitle={t('workingHours.working')}
                                                                                    isFocused={startEndDate.open}
                                                                                    onClick={() => handleOpenChange(day, index, true)}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={6} md={6}>
                                                                                <CustomIconButton
                                                                                    keyValue="bold"
                                                                                    variant="outlined"
                                                                                    icon={<WorkOffIcon />}
                                                                                    tooltipTitle={t('workingHours.break')}
                                                                                    isFocused={!startEndDate.open}
                                                                                    onClick={() => handleOpenChange(day, index, false)}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={6} md={2}>
                                                                        <CustomIconButton
                                                                            keyValue={`btn-delete-${index}`}
                                                                            onClick={() => removeWorkingHour(day, index)}
                                                                            icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                            className="icon-wrapper tr link"
                                                                            iconSx={{ marginRight: "0px!important" }}
                                                                            sx={{
                                                                                backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                                ":hover": {
                                                                                    backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                                },
                                                                                ...cardItemButtonGroupStackButtonIcon()
                                                                            }}
                                                                        >
                                                                            {t('actions.delete')}
                                                                        </CustomIconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </LocalizationProvider>
                                                        </>)}
                                                </Grid>

                                                <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                    <CustomIconButton
                                                        sx={addCardActionAreaButtonStyle()}
                                                        backgroundColor="transparent"
                                                        icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                                        onClick={() => handleAddStartEnd(day)}>
                                                        {t('actions.add')}
                                                    </CustomIconButton>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </Grid>
                                    ))}
                                </Grid>
                            </TabPanel>
                        </TabContext>

                        <Grid container spacing={2}>
                            <Grid item>
                                <CustomIconButton
                                    variant="outlined"
                                    keyValue="save"
                                    onClick={() => data.isAdd ? addItemOffer(true) : updateItemOffer()}
                                    label={t('actions.save')}
                                    sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                />
                            </Grid>

                            {data.isAdd &&
                                <>
                                    <Grid item>
                                        <CustomIconButton
                                            variant="outlined"
                                            keyValue="save"
                                            onClick={() => addItemOffer(false)}
                                            label={t('actions.saveAndCreateNew')}
                                            sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <CustomIconButton
                                            variant="outlined"
                                            keyValue="cancel"
                                            link={path}
                                            label={t('actions.back')}
                                            sx={formSaveButton()}
                                        />
                                    </Grid>
                                </>
                            }

                            {!data.isAdd &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        onClick={getItem}
                                        label={t('actions.cancel')}
                                        sx={formSaveButton()}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={() => data.isAdd ? addItemOffer(true) : updateItemOffer()}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>


                {attachDialog.isOpen &&
                    <AttachItemOrModifierDialog
                        isOpen={attachDialog.isOpen}
                        id="add-items"
                        title={`${t('actions.add')} ${t('modifierList.items')} ${t('modifierList.to')}`}
                        boldTitle={attachDialog.category?.name?.en}
                        searchLabel={`${t('actions.searchFor')} ${t('modifierList.items')}`}
                        noOptionsText={t('modifierList.emptyItemsSearch')}
                        actionLabel={t("actions.add")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.add')} ${t('actions.deleteModal.confirm')}`}
                        action={addItemOfferCategoryItems}
                        handleOpen={handleAttachDialogState}
                        instances={data.items.filter(item => !data.offer.categories.find((category) => attachDialog.category.id === category.id)?.itemIds.includes(item.id))}
                        dialogName="attachDialog"
                        field={"items"}
                        category={attachDialog.category}
                    />
                }

                {dialog.isOpen &&
                    <CustomTranslationsDialog
                        isOpen={dialog.isOpen}
                        id="translations"
                        title={t('actions.translationModal.title')}
                        action={configureItemTranslations}
                        handleOpen={handleDialogState}
                        translations={dialog.translations}
                        field={dialog.field} />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={6000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        action={alertBox.action}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default ItemOfferAddEditComponent;