import { Box, Chip, Grid, Typography } from "@mui/material";
import CustomTypography from "components/Typography/CustomTypography";
import orderApi from "api/order";

export default function OrderItems({
  order,
  setting,
  currency,
  updateOrderItemStatus,
  setAlertBox,
}) {
  function updateOrderItemStatusHandler(orderId, itemId, done, index) {
    orderApi
      .updateOrderItemStatus(orderId, itemId, done, index)
      .then((r) => {
        updateOrderItemStatus(orderId, itemId, done, index);

        if (r.data.message) {
          setAlertBox({
            isOpen: true,
            message: r.data.message,
            backgroundColor: "#4caf50",
          });
        }
      })
      .catch((e) => {
        // console.log(e);
        setAlertBox({
          isOpen: true,
          message: "Fail to Update order item",
          backgroundColor: "#ef5350",
        });
      });
  }

  return (
    <Grid container direction='column' gap='15px'>
      {order.cart.map((item, index) => (
        <Grid
          item
          key={item.id}
          sx={{
            width: "100%",

            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            paddingBottom: "10px",
          }}
        >
          <Grid container spacing={2}>
            {/* quantity */}
            <Grid item sx={{ minWidth: "72px" }}>
              <Box
                sx={{
                  background: "#DDDFE2",
                  //boxShadow: `0px 0px 15px 0px #dcdcdc`,
                  borderRadius: "5px",
                  padding: "5px 15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CustomTypography
                  text={`${item.quantity - item.unavailableQuantity} x`}
                  sx={{ fontWeight: "600", color: "#000" }}
                  variant='subtitle2'
                />
              </Box>
            </Grid>
            {/* items */}
            <Grid item sx={{ mt: "5px", flex: 1 }}>
              <Typography
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  textDecoration: item.done ? "line-through" : "unset",
                  cursor:
                    order.status === "WORKING" &&
                    item.unavailableQuantity !== item.quantity
                      ? "pointer"
                      : "default",
                }}
                variant='body1'
                onClick={() => {
                  if (
                    order.status === "WORKING" &&
                    item.unavailableQuantity !== item.quantity
                  ) {
                    updateOrderItemStatusHandler(order.id, item.id, !item.done, index);
                  }
                }}
              >
                {item.name}
              </Typography>
              {item.modifiers && item.modifiers.length > 0 && (
                <Grid container spacing={1} sx={{ mt: "0.3px" }}>
                  {item.modifiers.map((mod, modInd) => (
                    <Grid item key={item.id}>
                      <Chip
                        size='small'
                        label={mod.name}
                        color='primary'
                        sx={{
                          background: setting
                            ? setting.modifierColor
                            : "#66A15F",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              {item.notes && (
                <CustomTypography
                  text={`${item.notes}`}
                  variant='body2'
                  sx={{
                    textAlign: "left",
                    color: setting
                      ? setting.itemNoteColor
                      : "rgba(0, 0, 0, 0.87)",
                    mt: "5px",
                  }}
                />
              )}

              {(!setting || setting?.showPrice) && (
                <CustomTypography
                  text={`${parseFloat(item.price).toFixed(2)} ${currency}`}
                  variant='body2'
                  sx={{ mt: "20px", textAlign: "left", fontWeight: 500 }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
      {/* totalAmmount, comments, allergies */}
      <Grid
        item
        sx={{
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          {/* hidden space */}
          <Grid item>
            <Box
              sx={{
                background: "#C7C7C7",
                //boxShadow: `0px 0px 15px 0px #dcdcdc`,
                borderRadius: "5px",
                padding: "5px 15px",
                visibility: "hidden",
              }}
            >
              <CustomTypography
                text={`11 x`}
                sx={{ fontWeight: "600", color: "#000" }}
                variant='subtitle2'
              />
            </Box>
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            {/* totalAmmount */}
            {(!setting || setting?.showPrice) && (
              <CustomTypography
                text={`${parseFloat(order.totalAmmount).toFixed(
                  2
                )} ${currency}`}
                variant='body2'
                sx={{ mt: "10px", textAlign: "left", fontWeight: 600 }}
              />
            )}
            {/* allergies */}
            {order.allergies && order.allergies.length > 0 && (
              <Grid container spacing={1} sx={{ mt: "3px" }}>
                {order.allergies.map((all, allInd) => (
                  <Grid item key={allInd}>
                    <Chip
                      size='small'
                      label={all}
                      color='primary'
                      sx={{
                        background: setting
                          ? setting.allergiesColor
                          : "#66A15F",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {order.comments && (
              <CustomTypography
                text={`${order.comments}`}
                variant='body2'
                sx={{
                  textAlign: "left",
                  color: setting
                    ? setting.orderCommentColor
                    : "rgba(0, 0, 0, 0.87)",
                  mt: "5px",
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
