import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import { Fab, FormControlLabel, Grid, Stack, Switch, Tooltip } from "@mui/material";
import loyaltyCategoryApi from 'api/loyaltyCategory';
import loyaltyConfigurationApi from 'api/loyaltyConfiguration';
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomAddLoyaltyCard from 'components/Card/loyalty/CustomAddLoyaltyCard';
import CustomLoyaltyCard from 'components/Card/loyalty/CustomLoyaltyCard';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import AddEditLoyaltyCategory from 'components/Dialogs/loyalty/AddEditLoyaltyCategory';
import CustomDialog from 'components/Dialogs/CustomDialog';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import { getDefaultLoyaltyCategory, getLoyaltyCardProperties } from 'constants/defaultObjects';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagSearchItem } from 'themes/defaultThemes';
import { hasRoleAdmin } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue, onControlChangeWithParent } from 'utils/form-validation';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";
import { getLoyaltyCardCouponValue, getLoyaltyCardTargetPoints } from 'constants/fieldProperties';
import CustomTextField from 'components/Text/CustomTextField';
import { findCurrency, validateInputNumber } from 'utils/functions';

/**
 * The LoyaltySettingsComponent that allow the ADMIN to configure
 * loyalty settings.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function LoyaltySettingsComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('loyalty.settings.label')}`);

    /**
     * @type {object}
     * @property {*} icon the legend fa icon
     * @property {object} userAuth the authenticated user infromation
     * @property {boolean} isAdd to define if requested an edit or an add of the knowledge base category
     * @property {object} organization the organization basic details (id and name)
     * @property {object} outlet tha outlet basic details (id and name)
     * @property {object} alertBox holds snackbar infromation and style
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        icon: AddIcon,
        userAuth: null,
        isAdd: outletID === undefined,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        loyaltyConfiguration: {},
        loyaltyCategories: [],
        logoImgSrc: "",
        formatOptions: [],
        fontOptions: [],
        textAlignOptions: [],
        textPositionOptions: [],
        organizations: [],
        newCategory: {
            organizationID: organizationID,
            outletID: outletID,
            ...getDefaultLoyaltyCategory()
        },
        currency: "EUR",
        // error messages per field
        isError: {
            name: "",
            url: ""
        }
    });

    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        categoryID: "",
        isAdd: 0//add or 1 for edit
    });

    // pop dialog for delete
    const [deleteDialog, setDeletedDialog] = useState({
        isOpen: false,
        categoryID: "",
        nameEn: ""
    });

    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    // for search
    const [search, setSearch] = useState({
        organizationID: organizationID,
        outletID: outletID
    });

    useEffect(() => {
        getLoyaltySettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the loyalty configuration.
     */
    function getLoyaltySettings() {
        loyaltyConfigurationApi.fetchOne(search).then((r) => {
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        organization: r.data.returnobject.organization,
                        outlet: r.data.returnobject.outlet,
                        loyaltyConfiguration: r.data.returnobject.loyaltyConfiguration,
                        loyaltyCategories: r.data.returnobject.loyaltyCategories,
                        logoImgSrc: r.data.returnobject.logoImgSrc,
                        fontOptions: r.data.returnobject.fontOptions,
                        formatOptions: r.data.returnobject.formatOptions,
                        textAlignOptions: r.data.returnobject.textAlignOptions,
                        textPositionOptions: r.data.returnobject.textPositionOptions,
                        organizations: r.data.returnobject.organizations,
                        currency: r.data.returnobject.currency
                    });
                })
                .then(() => {
                    let organizationID = r.data.returnobject.organization !== null ? r.data.returnobject.organization.id : null;
                    let outletID = r.data.returnobject.outlet !== null ? r.data.returnobject.outlet.id : null;
                    if ((search.organizationID !== null && hasRoleAdmin(r.data.returnobject.userAuth.roles)) || !hasRoleAdmin(r.data.returnobject.userAuth.roles))
                        if (organizationID !== search.organizationID)
                            setSearch({ ...search, organizationID: organizationID });
                    // if ((search.organizationID !== null && hasRoleAdmin(r.data.returnobject.userAuth.roles)) || !hasRoleAdmin(r.data.returnobject.userAuth.roles))
                    //     if (organizationID !== search.organizationID || (outletID !== search.outletID))
                    //         setSearch({ ...search, organizationID: organizationID, outletID: outletID !== null ? outletID : (r.data.returnobject.outlets.size === 1) ? r.data.returnobject.outlets.outlets[0].id : null });
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        });
    }

    /**
     * Function that triggers the modification of the loyalty settings
     */
    function editLoyaltySettings() {
        loyaltyConfigurationApi.update(data.loyaltyConfiguration).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            getLoyaltySettings();
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that triggers the creation of a loyalty category
     */
    function addLoyaltyCategory(category) {
        category.organizationID = search.organizationID;
        loyaltyCategoryApi.create(category).then((r) => {
            let newCategories = data.loyaltyCategories;
            newCategories.push(r.data.returnobject);//to get the id
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        loyaltyCategories: newCategories
                    });
                })
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: r.data.message,
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    setDialog({
                        isOpen: false,
                        categoryID: "",
                        nameEn: "",
                        isAdd: 0//add or 1 for edit
                    });
                })
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that triggers the modification of a loyalty category
     */
    function updateLoyaltyCategory(category) {
        loyaltyCategoryApi.update(category).then((r) => {
            let updatedCategories = data.loyaltyCategories.map(obj => (category.id === obj.id) ? category : obj);
            Promise.resolve()
                .then(() => {
                    if (r.data.code === "SUCCESS")
                        setData({
                            ...data,
                            loyaltyCategories: updatedCategories
                        });
                })
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: r.data.message,
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    setDialog({
                        isOpen: false,
                        categoryID: "",
                        isAdd: 0//add or 1 for edit
                    });
                })
        }).catch((e) => {
            // console.log(e)
        });
    }

    function deleteLoyaltyCategory() {
        loyaltyCategoryApi.deleteById(deleteDialog.categoryID).then((r) => {
            let categories = data.loyaltyCategories;
            const index = categories.indexOf(data.loyaltyCategories.find((category) => category.id === deleteDialog.categoryID));
            handleDeleteDialogState(false, "");
            // only splice array when item is found
            if (index > -1) categories.splice(index, 1); // 2nd parameter means remove one item only
            Promise.resolve()
                .then(() => {
                    if (r.data.code === "SUCCESS")
                        setData({
                            ...data,
                            loyaltyCategories: categories
                        });
                })
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: r.data.message,
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    setDeletedDialog({
                        isOpen: false,
                        categoryID: "",
                        nameEn: "",
                    });
                })
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} categoryID The id of the selected category to be editted.
     */
    function handleDialogState(isOpen, categoryID = "", isAdd = true) {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            categoryID: categoryID,
            isAdd: isAdd
        });
    }

    /**
     * Function that handles the delete modal open or close data.
     * 
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} categoryID The id of the selected loyalty category to be removed.
     * @property {string} nameEn The loyalty category name to be removed.
     */
    function handleDeleteDialogState(isOpen, categoryID = "", nameEn = "") {
        setDeletedDialog({
            ...deleteDialog,
            isOpen: isOpen,
            categoryID: categoryID,
            nameEn: nameEn
        });
    }

    /**
     * Function that triggers the removal of the selected categoryID.
     * 
     * @property {*} event
     * @property {string} categoryID The id of the selected loyalty category to be removed.
     * @property {string} nameEn The loyalty category name to be removed.
     */
    function deleteCategory(event, categoryID, nameEn) {
        handleDeleteDialogState(true, categoryID, nameEn);
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <CustomGrid role="main" id="loyalty-categories" sx={mainTag()}>
            <LoadingSkeleton lines={9} />
        </CustomGrid>
    } else {
        return (
            // Loyalty Settings
            <CustomGrid role="main" id="loyalty-categories" sx={mainTag()}>
                {organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('loyalty.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('loyalty.category.label'), url: `/${getLanguageFromURL()}/organization/${organizationID}/loyalty/category` },
                            ]}
                            instanceName={t('loyalty.settings.label')}
                        />
                    </CustomGrid>
                }

                {organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={false}
                            parents={[
                                { name: t('loyalty.category.label'), url: `/${getLanguageFromURL()}/loyalty/category` },
                            ]}
                            instanceName={t('loyalty.settings.label')}
                        />
                    </CustomGrid>
                }

                <Grid container spacing={2}>
                    <Stack direction="row" sx={{ ...mainTagSearch(), textAlign: "right", width: "100%" }} >
                        <CustomGrid sx={{ ...mainTagSearch(), textAlign: "right", width: "100%" }} direction="column" container={false} spacing={2} >
                            <Grid container spacing={2}>
                                <>
                                    {data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) &&
                                        <Grid item xs={12} sm={3}>
                                            <CustomObjectSelect
                                                name="organizationID"
                                                required={true}
                                                defaultValue={search.organizationID}
                                                labelID="organizationID" label={t('org.pageTitle')}
                                                options={data.organizations}
                                                onChange={(event) => { setIsLoaded(false); setSearch({ ...search, organizationID: event.target.value }) }}
                                                sx={mainTagSearchItem()}
                                                field="name"
                                            />
                                        </Grid>
                                    }
                                </>
                            </Grid>
                        </CustomGrid>
                    </Stack>
                </Grid>

                {search.organizationID !== undefined &&
                    <CustomGrid sx={mainArea()} container={false}>
                        <form id="loyalty-settings" onSubmit={(e) => { return false; }}>
                            {data.isAdd &&
                                <input type="hidden" />
                            }

                            <input defaultValue={organizationID} name="organizationID" type="hidden" id="organizationID" />

                            {/* {!data.isAdd && data.outlet !== null &&
                                <input defaultValue={outletID} name="id" type="hidden" id="id" />
                            } */}

                            {/* <fieldset id="working-hours-wrapper" className="fieldset-wrapper collapsable collapsed">
                                <legend onClick={collapsable}>&nbsp;<SvgIcon component={data.icon} aria-hidden="true" style={{ fontSize: "medium", verticalAlign: "sub" }} />{t('outlet.settings.hours')}</legend>
                                </fieldset> 
                            */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <label htmlFor="categories">{t('loyalty.category.label')}</label>
                                        </Grid>
                                        <Grid item >
                                            <Stack direction="row" sx={{ padding: "10px", width: "calc(100% - 40px)",  alignItems: "center" }}>
                                                <Grid container gap={2}>
                                                    {data.loyaltyCategories.map((category) => (
                                                        <CustomLoyaltyCard
                                                            cardProperties={data.loyaltyConfiguration.cardProperties}
                                                            category={category}
                                                            handleDialogState={handleDialogState}
                                                            deleteCategory={deleteCategory}
                                                            logoImgSrc={data.logoImgSrc}
                                                            organizationID={search.organizationID}
                                                        />
                                                    ))}
                                                    <CustomAddLoyaltyCard onClick={() => handleDialogState(true)} />
                                                </Grid>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={6} sx={{ marginTop: "50px" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <label>{t('loyalty.settings.cardProperties')}</label>
                                        </Grid>
                                        <Grid item >
                                            <CustomCheckbox
                                                name="loyaltyConfiguration.cardProperties.configureCard"
                                                selected={data.loyaltyConfiguration.cardProperties.configureCard}
                                                onChange={(event) => onControlChangeWithParent(event, data, setData, "loyaltyConfiguration.cardProperties.configureCard")}
                                                label={t('loyalty.settings.configureLoyaltyCard')}
                                            />
                                        </Grid>
                                        {data.loyaltyConfiguration.cardProperties.configureCard &&
                                            <Grid item xs={12} md={12}>
                                                <Stack sx={{ marginLeft: "20px" }}>
                                                    <Tooltip title={data.logoImgSrc !== null && data.logoImgSrc !== "" ? t('loyalty.settings.showLogoTooltip') : t('loyalty.settings.showLogoTooltipDisabled')}>
                                                        <FormControlLabel control={
                                                            <Switch checked={data.loyaltyConfiguration.cardProperties.showLogo}
                                                                onChange={(event) => onControlChangeWithParent(event, data, setData, "loyaltyConfiguration.cardProperties.showLogo")}
                                                                disabled={data.logoImgSrc === null || data.logoImgSrc === ""}
                                                            />
                                                        }
                                                            label={t('loyalty.settings.showLogo')}
                                                        />
                                                    </Tooltip>
                                                    <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showName}
                                                        onChange={(event) => onControlChangeWithParent(event, data, setData, "loyaltyConfiguration.cardProperties.showName")} />}
                                                        label={t('loyalty.settings.showName')}
                                                    />
                                                    <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showLevel}
                                                        onChange={(event) => onControlChangeWithParent(event, data, setData, "loyaltyConfiguration.cardProperties.showLevel")} />}
                                                        label={t('loyalty.settings.showLevel')}
                                                    />
                                                    <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showPoints}
                                                        onChange={(event) => onControlChangeWithParent(event, data, setData, "loyaltyConfiguration.cardProperties.showPoints")} />}
                                                        label={t('loyalty.settings.showPoints')}
                                                    />
                                                    {/* <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showSubsidy}
                                                    onChange={(event) => onControlChangeWithParent(event, data, setData,  "loyaltyConfiguration.cardProperties.showSubsidy")} />}
                                                    label={t('loyalty.settings.showSubsidy')}
                                                    />
                                                    <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showWallet}
                                                    onChange={(event) => onControlChangeWithParent(event, data, setData,  "loyaltyConfiguration.cardProperties.showWallet")} />}
                                                    label={t('loyalty.settings.showWallet')}
                                                    /> */}
                                                    <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showLoyaltyMemberNumber}
                                                        onChange={(event) => onControlChangeWithParent(event, data, setData, "loyaltyConfiguration.cardProperties.showLoyaltyMemberNumber")} />}
                                                        label={t('loyalty.settings.showLoyaltyMemberNumber')}
                                                    />
                                                    {/* <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showCredit}
                                                    onChange={(event) => onControlChangeWithParent(event, data, setData,  "loyaltyConfiguration.cardProperties.showCredit")} />} 
                                                    label={t('loyalty.settings.showCredit')}
                                            /> */}
                                                    {/* <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showCoupons}
                                                onChange={(event) => onControlChangeWithParent(event, data, setData,  "loyaltyConfiguration.cardProperties.showCoupons")} />}
                                                label={t('loyalty.settings.showCoupons')}
                                                />
                                                <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showCouponRemainder}
                                                onChange={(event) => onControlChangeWithParent(event, data, setData,  "loyaltyConfiguration.cardProperties.showCouponRemainder")} />}
                                                label={t('loyalty.settings.showCouponRemainder')}
                                                /> */}
                                                    <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.cardProperties.showBarcode}
                                                        onChange={(event) => onControlChangeWithParent(event, data, setData, "loyaltyConfiguration.cardProperties.showBarcode")} />}
                                                        label={t('loyalty.settings.showBarcode')}
                                                    />
                                                </Stack>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={6} sx={{ marginTop: "50px" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <FormControlLabel control={<Switch checked={data.loyaltyConfiguration.enableWelcomeBonus}
                                                onChange={(event) => onControlChangeWithParent(event, data, setData, "loyaltyConfiguration.enableWelcomeBonus")} />}
                                                label={t('loyalty.settings.enableWelcomeBonus')}
                                            />
                                        </Grid>
                                        {data.loyaltyConfiguration.enableWelcomeBonus &&
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <CustomTextField
                                                        type="number"
                                                        step={getLoyaltyCardTargetPoints().step}
                                                        min={getLoyaltyCardTargetPoints().min}
                                                        max={getLoyaltyCardTargetPoints().max}
                                                        name="min"
                                                        value={data.loyaltyConfiguration.welcomeBonusPoints}
                                                        inputAdornmentLabel={'loyalty.settings.welcomeBonusPoints'}
                                                        inputAdornmentAfterLabel={'loyalty.settings.points'}
                                                        required={true}
                                                        onChange={(event) => {
                                                            let value = validateInputNumber(event.target.value, getLoyaltyCardTargetPoints().length, getLoyaltyCardTargetPoints().min, getLoyaltyCardTargetPoints().max, getLoyaltyCardTargetPoints().isFloat);
                                                            if (value === undefined) value = data.loyaltyConfiguration.welcomeBonusPoints;
                                                            formValChangeWithParentElementWithNameAndValue("loyaltyConfiguration.welcomeBonusPoints", parseInt(value), data, setData)
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={12}>
                                                    <CustomTextField
                                                        type="number"
                                                        step={getLoyaltyCardCouponValue().step}
                                                        min={getLoyaltyCardCouponValue().min}
                                                        max={getLoyaltyCardCouponValue().max}
                                                        name="min"
                                                        defaultValue={data.loyaltyConfiguration.welcomeBonusCredit}
                                                        inputAdornmentLabel={'loyalty.settings.welcomeBonusCredit'}
                                                        inputAdornmentAfterLabel={findCurrency(data.currency)}
                                                        required={true}
                                                        onChange={(event) => {
                                                            let value = validateInputNumber(event.target.value, getLoyaltyCardCouponValue().length, getLoyaltyCardCouponValue().min, getLoyaltyCardCouponValue().max, getLoyaltyCardCouponValue().isFloat);
                                                            if (value === undefined) value = data.loyaltyConfiguration.welcomeBonusCredit;
                                                            formValChangeWithParentElementWithNameAndValue("loyaltyConfiguration.welcomeBonusCredit", parseFloat(value), data, setData)
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid container spacing={2}>
                                {/* <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="save"
                                        onClick={editLoyaltySettings}
                                        label={t('actions.save')}
                                        sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                    />
                                </Grid> */}

                                {data.isAdd &&
                                    <Grid item>
                                        <CustomIconButton
                                            variant="outlined"
                                            keyValue="cancel"
                                            link={`/${getLanguageFromURL()}/loyalty`}
                                            label={t('actions.back')}
                                            sx={formSaveButton()}
                                            color="error"
                                        />
                                    </Grid>
                                }

                                {!data.isAdd &&
                                    <Grid item>
                                        <CustomIconButton
                                            variant="outlined"
                                            keyValue="cancel"
                                            onClick={getLoyaltySettings}
                                            label={t('actions.cancel')}
                                            sx={formSaveButton()}
                                            color="error"
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </form>

                        <Fab color="primary"
                            aria-label="add"
                            sx={fabStyle()}
                            onClick={editLoyaltySettings}
                        >
                            <SaveIcon />
                        </Fab>
                    </CustomGrid>
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />

                }

                {dialog.isOpen &&
                    <AddEditLoyaltyCategory
                        isOpen={dialog.isOpen}
                        handleOpen={handleDialogState}
                        category={dialog.isAdd ? data.newCategory : data.loyaltyCategories.find((category) => dialog.categoryID === category.id)}
                        backgroundTypeOptions={["COLOR", "IMAGE"]}
                        isAdd={dialog.isAdd}
                        addLoyaltyCategory={addLoyaltyCategory}
                        updateLoyaltyCategory={updateLoyaltyCategory}
                        formatOptions={data.formatOptions}
                        fontOptions={data.fontOptions}
                        textAlignOptions={data.textAlignOptions}
                        textPositionOptions={data.textPositionOptions}
                        cardProperties={data.loyaltyConfiguration.cardProperties}
                        sxCard={getLoyaltyCardProperties()}
                        organizationID={search.organizationID}
                        logoImgSrc={data.logoImgSrc}
                    />
                }

                {deleteDialog.isOpen &&
                    <CustomDialog
                        isOpen={deleteDialog.isOpen}
                        id="delete"
                        title={t('actions.deleteModal.title')}
                        actionLabel={t("actions.deleteModal.message")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('loyalty.category.labelSingle')}: `} a
                        boldMessage={<span style={{ fontWeight: "bold" }}>{deleteDialog.nameEn}</span>}
                        action={deleteLoyaltyCategory}
                        handleOpen={handleDeleteDialogState} />
                }
            </CustomGrid>
        );
    }
}

export default LoyaltySettingsComponent;