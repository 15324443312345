import { Tab, Tabs } from "@mui/material";

function CustomTabs({ initValue = "front", instances, navigationMenuSelectedOptionFontColor, setValue, sx, tabSx, scrollButtons = false, icon, centered = true }) {
    return (
        <Tabs
            variant={scrollButtons ? "scrollable" : "standard"}
            value={initValue}
            scrollButtons={scrollButtons}
            allowScrollButtonsMobile
            selectionFollowsFocus={true}
            orientation="horizontal"
            onChange={(event, newValue) => setValue(newValue)}
            centered={scrollButtons ? false : centered}
            sx={{ paddingBottom: "10px", ...sx }}
        >
            {instances.map((instance, index) =>
                <Tab
                    key={instance.value}
                    value={instance.value}
                    classes={{ indicator: navigationMenuSelectedOptionFontColor }}
                    label={instance.name}
                    icon={instance.icon}
                    iconPosition="start"
                    sx={tabSx}
                />
            )}
        </Tabs>
    )
}
export default CustomTabs;
