import { Box, Skeleton } from '@mui/material';
import React from 'react';

/**
 * The NotificationLoadingSkeleton, that display a box with animated skeletons for notifications box.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function NotificationLoadingSkeleton() {
    return (
        [
            <Skeleton animation="wave" variant="rectangular" width="20px" height="20px" key="s1" style={{ alignSelf: "center", marginRight: "10px", marginLeft: "10px" }} />
            ,
            <Box style={{ marginRight: "10px", marginTop: "10px" }} key="b1">
                <Skeleton animation="wave" variant="rectangular" width={355} height="12px" style={{ marginBottom: "10px" }} key="s2" />
                <Skeleton animation="wave" variant="rectangular" width={110} height="12px" key="s3" />
            </Box>
            ,
            <Box style={{ display: "flex", marginTop: "10px" }} key="b2">
                <Skeleton animation="wave" variant="rectangular" width="12px" height="12px" style={{ marginRight: "5px" }} key="s4" />
                <Skeleton animation="wave" variant="circular" width="12px" height="12px" key="s5" />
            </Box>
        ]
    );
}

export default NotificationLoadingSkeleton;