import { CloseOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CustomFlagTextField from "components/Text/CustomFlagTextField";
import { getMultiLangOptions } from "constants/multilangOptions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import formatDateTime from "utils/formatDateTime";
import readFileAsBase64 from "utils/readFileAsBase64";
import { v4 as uuidv4 } from "uuid";

import en from "assets/images/flags/en.png";
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";

export default function AddItemPopup({
  open,
  setOpen,
  title,
  onSubmit,
  editData,
  organizationID,
  showParent,
  Items,
}) {
  const { t } = useTranslation();
  const intitialData = {
    name: getMultiLangOptions(),
    active: true,
    image: null,
  };
  const [data, setData] = useState(intitialData);
  const [edit, setEdit] = useState(false);
  const [showAllTranslations, setShowAllTranslations] = useState(false);
  const [error, setError] = useState();
  const [fileSizeError, setFileSizeError] = useState();
  const [dialog, setDialog] = useState({
    isOpen: false,
    message: t("label.configureTranslations"),
    id: "",
    field: "",
    translations: {},
  });

  // const onEnterPress = (e) => {
  //   if (e.key === "Enter") {
  //     console.log("Enter");
  //     if (edit) editHandler();
  //     else submitHandler();
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("keydown", onEnterPress);
  //   return () => {
  //     document.removeEventListener("keydown", onEnterPress);
  //   };
  // }, []);

  useEffect(() => {
    setFileSizeError();
    if (editData) {
      setData(editData);
      setEdit(true);
    }
  }, [editData]);

  function handleDialogState(isOpen, field = "") {
    setDialog({
      ...dialog,
      isOpen: isOpen,
      field: field,
      translations: data[field],
    });
  }

  function configureTranslations(event, field) {
    handleDialogState(true, field);
  }
  function configureItemTranslations(translations, field) {
    setData({
      ...data,
      [field]: translations,
    });
    setDialog({
      ...dialog,
      isOpen: false,
    });
  }

  const validate = () => {
    if (data.name?.en === "" || data.name?.en?.length < 4) {
      return {
        success: false,
        message: "Name length cannot be less than 3 characters",
      };
    }
    if (data.name?.en === "default") {
      return {
        success: false,
        message: "Name 'default' is not allowed",
      };
    }

    const checkExist = Items.some((x) => x.name.en === data.name?.en);
    if (edit) {
      if (data.name?.en !== editData.name?.en) {
        if (checkExist) {
          return {
            success: false,
            message: "Name already exist",
          };
        }
      }
    } else {
      if (checkExist) {
        return {
          success: false,
          message: "Name already exist",
        };
      }
    }
    return {
      success: true,
      message: "",
    };
  };
  const submitHandler = () => {
    ///call api and do error handling
    let vali = validate();
    if (vali.success === false) {
      setError(vali.message);
      return;
    } else {
      setError();
    }

    let apiResponse = {
      id: uuidv4(),
      name: data.name,
      photo: data.photo,
      image: data.image,
      //organizationID: organizationID, // Organization think it as Starbucks
      itemIds: [],
      active: data.active,
      activeUI: data.active,
      lastActivity: formatDateTime(new Date()),
      dateCreated: formatDateTime(new Date()),
    };
    onSubmit(apiResponse);
    setData(intitialData);
  };

  const editHandler = () => {
    let vali = validate();
    if (vali.success === false) {
      setError(vali.message);
      return;
    } else {
      setError();
    }
    onSubmit({
      ...data,
      activeUI: data.active,
    });
  };
  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (edit) editHandler();
            else submitHandler();
          }}
        >
          <div style={{ position: "absolute", top: 5, right: 5 }}>
            <IconButton sx={{ p: 0 }} onClick={() => setOpen(false)}>
              <CloseOutlined fontSize='small' sx={{ fill: "#000" }} />
            </IconButton>
          </div>
          <Typography
            variant='h5'
            align='center'
            sx={{ color: "#34353A", fontWeight: 700 }}
          >
            {edit ? "Edit" : "Add new"} {title}
          </Typography>
          <Grid container direction='column'>
            <Grid item sx={{ width: "100%" }}>    {/* name */}
              <Grid item sx={{ width: "100%", mt: "20px" }}>
                <Typography variant='body2'>
                  {t("feedback.addUpdateCategory.name")}
                </Typography>

                <CustomFlagTextField
                  country='en'
                  flag={en}
                  placeholder=''
                  name='data.name.en'
                  id='name'
                  defaultValue={
                    data !== null && data.name !== null ? data.name.en : ""
                  }
                  onTranslationSelect={configureTranslations}
                  field='name'
                  value={data.name.en}
                  onChange={(e) =>
                    setData((d) => {
                      return {
                        ...d,
                        name: {
                          ...d.name,
                          en: e.target.value,
                        },
                      };
                    })
                  }
                  sx={{ mt: "4px" }}
                  otherProps={{
                    inputProps: {
                      maxLength: 20,
                    },
                  }}
                //helperText={isError.name}
                //error={isError.name.length > 0}
                />
              </Grid>
              {error && (
                <Typography
                  variant='body2'
                  sx={{ mt: "5px", color: "red", fontWeight: 500 }}
                >
                  {error}
                </Typography>
              )}


              {/* upload */}
              {data.photo || data.image ? (
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={1}
                  sx={{ m: "24px 0" }}
                >
                  <Grid item>
                    <Grid container alignItems='center' gap={"10px"}>
                      <Grid item>
                        <img
                          src={
                            data.image
                              ? `${data.photo.imageSrc}`
                              : `${process.env.REACT_APP_IMAGE_HOST
                              }/${organizationID}/${showParent ? "parentCategory" : "category"
                              }/${data.id}/${data.photo.imageSrc}`
                          }
                          style={{
                            width: "56px",
                            height: "56px",
                            padding: "1px",
                            border: "3px solid rgba(223,239,248)",
                            borderRadius: "4px",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant='small'
                          sx={{
                            color: "#4a4c50",
                            fontSize: "12px",
                            display: "block",
                          }}
                        >
                          {typeof data.image === "object"
                            ? data.image.name
                            : data.photo
                              ? data.photo.imageSrc
                              : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems='center' gap={"5px"}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={data?.photo?.hidden}
                            onChange={(e) => {
                              setData((d) => {
                                return {
                                  ...d,
                                  photo: {
                                    ...d.photo,
                                    hidden: e.target.checked,
                                  },
                                };
                              });
                            }}
                          />
                        }
                        label='Hide'
                      />

                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => {
                          setData((r) => {
                            return {
                              ...r,
                              photo: null,

                              image: null,
                              imageSize: "",
                            };
                          });
                        }}
                      >
                        <DeleteIcon sx={{ fill: "red" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container sx={{ m: "24px 0" }}>
                  <input
                    accept='image/*'
                    style={{ display: "none" }}
                    id='background'
                    type='file'
                    onChange={async (e) => {
                      if (!e.target.files && !e.target.files[0]) {
                        return;
                      }
                      try {
                        let fileObj = e.target.files[0];

                        if (fileObj.size > 2000000) {
                          setFileSizeError("Maximum upload file size: 2MB");
                          return;
                          // let compressObjBlob = await compressAccurately(
                          //   fileObj,
                          //   2000
                          // );
                          // fileObj = new File([compressObjBlob], fileObj.name, {
                          //   type: fileObj.type,
                          // });
                        }
                        setFileSizeError();
                        const base64 = await readFileAsBase64(fileObj);
                        setData((r) => {
                          return {
                            ...r,
                            photo: {
                              imageSrc: base64,
                              hidden: false,
                            },
                            image: e.target.files[0],
                          };
                        });
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                  <label htmlFor='background' style={{ width: "100%" }}>
                    <Button
                      component='span'
                      variant='contained'
                      fullWidth
                      sx={{
                        fontSize: "14px",
                        background: "#47a6d2",
                        color: "#fff",
                        borderRadius: "0.35rem",
                        fontWeight: 600,
                        p: "10px 16px",
                      }}
                    >
                      {t('assets.uplooadImage')}
                    </Button>
                  </label>
                </Grid>
              )}
              {fileSizeError && (
                <Typography
                  variant='body2'
                  align='center'
                  sx={{ color: "red", mt: "-15px" }}
                >
                  {fileSizeError}
                </Typography>
              )}
            </Grid>
            <Grid item sx={{ mt: "5px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={data?.active}
                    onChange={(e) => {
                      setData((d) => {
                        return {
                          ...d,
                          active: e.target.checked,
                        };
                      });
                    }}
                  />
                }
                label={data?.active ? t('label.isActive') : t('label.isNotActive')}
              />
            </Grid>
            <Grid item sx={{ width: "100%", mt: "20px" }}>
              {/* buttons */}
              <Grid
                container
                wrap='nowrap'
                justifyContent='flex-end'
                spacing={2}
              >
                <Grid item>
                  <Button
                    fullWidth
                    size='large'
                    variant='outlined'
                    sx={{
                      border: "1px solid #D0D5DD",
                      background: "transparent",
                      boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                      color: "#344054",
                    }}
                    onClick={() => {
                      setOpen(false);
                      setError();
                      setData(intitialData);
                    }}
                  >
                    {t('actions.cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    variant='contained'
                    size='large'
                    // onClick={edit ? editHandler : submitHandler}
                    type='submit'
                    sx={{ background: "#47a6d2" }}
                  >
                    {/* {loading?.active && loading?.action === "submit" && (
                      <CircularProgress
                        size='1rem'
                        color='inherit'
                        sx={{ marginRight: "10px" }}
                      />
                    )} */}
                    {t('actions.submit')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>

        {edit && (
          <Typography
            variant='body2'
            sx={{ mt: "20px", color: "#34353A", fontWeight: 700 }}
          >
            {`Date Created: ${new Date(data.dateCreated).toLocaleString()}`}
            <br />
            {`Last Updated: ${new Date(data.lastActivity).toLocaleString()}`}
          </Typography>
        )}
      </DialogContent>

      {dialog.isOpen && (
        <CustomTranslationsDialog
          isOpen={dialog.isOpen}
          id='translations'
          title={t("actions.translationModal.title")}
          action={configureItemTranslations}
          handleOpen={handleDialogState}
          translations={data[dialog.field]}
          field={dialog.field}
        />
      )}
    </Dialog>
  );
}
