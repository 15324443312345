import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PeopleAltRounded, Tag } from '@mui/icons-material';
import {
    Divider,
    Grid,
    InputLabel,
    Tooltip
} from '@mui/material';
import { faCreditCard, faLeaf, faMoneyBill } from 'assets/fontAwesome/FontAwesomeIcons';
import { EditIcon, MessageIcon, PercentIcon, PlaceIcon, ReceiptIcon, ShoppingCartIcon } from 'assets/mui/MuiIcons';
import CheckoutEquipmentPopover from 'components/Popover/CheckoutEquipmentPopover';
import CheckoutOrderStatusPopover from 'components/Popover/CheckoutOrderStatusPopover';
import CheckoutPersonsPopover from 'components/Popover/CheckoutPersonsPopover';
import OrderTypePopover from 'components/Popover/OrderTypePopover';
import CustomTypography from 'components/Typography/CustomTypography';
import { useTranslation } from 'react-i18next';
import { orderCartTitleTextStyle, orderDiscountTextStyle, orderDiscountTextsStyle, orderDiscountValueTextStyle, orderFontAwesomeIconStyle, orderHeaderTextStyle, orderIconStyle, orderItemFontAwesomeIconStyle, orderItemMuiIconStyle, orderItemPriceTextStyle, orderItemTaxPriceTextStyle, orderItemTaxTextStyle, orderItemTotalPriceTextStyle, orderItemTotalTextStyle, orderReviewGridStyle, orderTextStyle, pastOrderDateTextStyle } from 'themes/defaultThemes';
import { dateTimeFormatter } from 'utils/functions';
import { getAllergies } from 'utils/functions/allergy';
import { findCurrency, priceTextWithCurrency } from 'utils/functions/order';

/**
 * The CustomSinglePastOrderCardInfoEdit, that display each customer past order (info section).
 * 
 * @param {object} order the past order details
 * @param {fucntion} handlePastOrderAddFeedbackClick Callback that handles redirects past order add feedback page.
 *
 * @returns {JSX.Element} A React component that renders a past order info section.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSinglePastOrderCardInfoEdit({ order, equipment, equipments, statuses, outlet,
    equipmentAnchorEl,
    updateEquipmentOpenCallback, updateEquipmentCloseCallback, updateEquipmentCallback, updateEquipmentValue,
    openEquipment,
    orderStatusAnchorEl, orderStatus,
    updateOrderStatusOpenCallback, updateOrderStatusCloseCallback,
    updateOrderStatusValue, updateOrderStatusCallback,
    openOrderStatus,
    personsAnchorEl, openPersons, updatePersonsCloseCallback, updatePersonsCallback, updatePersonsOpenCallback, updateOrderPersonsCallback,
    orderTypeAnchorEl, openOrderType, orderType, updateOrderTypeCloseCallback, updateOrderTypeOpenCallback, types, updateOrderTypeCallback
}) {
    const { t } = useTranslation();

    // ==================================== RENDER
    return (
        <>
            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container >
                    <Grid item xs={4} md={3} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={`${t('label.id')}:`} sx={orderHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={8} md={9} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={order.id} sx={pastOrderDateTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container >
                    <Grid item xs={4} md={3} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={`${t('label.dateCreated')}:`} sx={orderHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={8} md={9} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={dateTimeFormatter(order.dateCreated, "DD/MM/yyyy - HH:mm")} sx={pastOrderDateTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container >
                    <Grid item xs={4} md={3} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={`${t('order.status')}:`} sx={orderHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={8} md={9} sx={{ alignSelf: "center" }}>

                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="body2" text={t(`order.actions.statusCaps.${order.status}`)} sx={orderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={8} md={8} sx={{ display: "flex" }}>
                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateOrderStatusOpenCallback} /></Tooltip>
                                        <CheckoutOrderStatusPopover
                                            anchorEl={orderStatusAnchorEl}
                                            open={openOrderStatus}
                                            orderStatus={orderStatus}
                                            handleClose={updateOrderStatusCloseCallback}
                                            inputValue={order?.status}
                                            handleInputChange={updateOrderStatusValue}
                                            handleUpdate={updateOrderStatusCallback}
                                            statuses={statuses}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container >
                    <Grid item xs={4} md={3} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={`${t('cart.orderType.label')}:`} sx={orderHeaderTextStyle()} />
                    </Grid>

                    <Grid item xs={8} md={9} sx={{ alignSelf: "center" }}>

                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="body2" text={`${t(`cart.orderType.${order.type}`)}`} sx={orderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={8} md={8} sx={{ display: "flex" }}>
                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateOrderTypeOpenCallback} /></Tooltip>
                                        <OrderTypePopover
                                            anchorEl={orderTypeAnchorEl}
                                            open={openOrderType}
                                            handleClose={updateOrderTypeCloseCallback}
                                            inputValue={orderType}
                                            handleUpdate={updateOrderTypeCallback}
                                            types={types}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            <Grid item xs={6} md={6} sx={{ marginTop: "20px" }}>
                <Grid container >
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <PlaceIcon sx={orderIconStyle} />
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.equipmentLabel')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="body2" text={equipment?.label} sx={orderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={8} md={8} sx={{ display: "flex" }}>
                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateEquipmentOpenCallback} /></Tooltip>
                                        <CheckoutEquipmentPopover
                                            anchorEl={equipmentAnchorEl}
                                            open={openEquipment}
                                            handleClose={updateEquipmentCloseCallback}
                                            inputValue={equipment?.label}
                                            handleInputChange={updateEquipmentValue}
                                            handleUpdate={updateEquipmentCallback}
                                            equipments={equipments}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={5.5} md={5.5} sx={{ marginTop: "20px" }}>
                <Grid container >
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <PeopleAltRounded sx={orderIconStyle} />
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={`${t('cart.persons')}:`} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={"auto"}>
                                        <CustomTypography variant="body2" text={order.persons} sx={orderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={8} md={8} sx={{ display: "flex" }}>
                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updatePersonsOpenCallback} /></Tooltip>
                                        <CheckoutPersonsPopover
                                            anchorEl={personsAnchorEl}
                                            open={openPersons}
                                            handleClose={updatePersonsCloseCallback}
                                            inputValue={order.persons}
                                            handleUpdate={updateOrderPersonsCallback}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6} md={6} sx={{ marginTop: "20px" }}>
                <Grid container>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <ShoppingCartIcon sx={orderIconStyle} />
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.orderTotal')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={`${findCurrency(order?.outlet?.currency)} ${order?.totalAmmount}`} sx={orderTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            {order?.tipAmount > 0 &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container sx={{ width: "90%", paddingLeft: "10%" }}>
                        <Grid item xs={12} md={12} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" te xt={t('order.orderTotal')} sx={orderHeaderTextStyle()} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <CustomTypography variant="body2" text={`${findCurrency(order?.outlet?.currency)} ${order.tipAmount}`} sx={orderTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Grid item xs={5.5} md={5.5} sx={{ marginTop: "20px" }}>
                <Grid container>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <Tag sx={orderIconStyle} />
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.orderNumber')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={`${order?.orderNumber}`} sx={orderTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                <Grid container>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        {order?.paymentMethod === "cash" &&
                            <FontAwesomeIcon key={"icon-cash"} icon={faMoneyBill} style={orderFontAwesomeIconStyle()} />
                        }
                        {order?.paymentMethod === "card" &&
                            <FontAwesomeIcon key={"icon-card"} icon={faCreditCard} style={orderFontAwesomeIconStyle()} />
                        }
                    </Grid>

                    <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                        <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.paymentMethod')} sx={orderHeaderTextStyle()} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomTypography variant="body2" text={order?.paymentMethod} sx={orderTextStyle()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* {!order?.hasGivenFeedback && order?.outlet.allowReviews &&
                <Grid item xs={12} md={12} sx={orderReviewGridStyle()}>
                    <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                        <Grid item xs={2} md={2}>
                            <ReviewsIcon sx={orderIconStyle} />
                        </Grid>

                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }} onClick={handlePastOrderAddFeedbackClick}>
                            <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.rating')} sx={orderHeaderTextStyle()} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.ratingDescription')} sx={orderTextStyle()} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={2} md={2}>
                            <NavigateNextIcon sx={orderIconStyle} />
                        </Grid>
                    </Grid>
                </Grid>
            } */}

            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />


            {order?.pvcRecyclingTax > 0 &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <FontAwesomeIcon key={"icon-env-tax"} icon={faLeaf} style={orderItemFontAwesomeIconStyle()} />
                        </Grid>

                        <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.pvcRecyclingTax')} sx={orderItemTaxTextStyle()} />
                        </Grid>

                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={priceTextWithCurrency(order?.outlet?.currency, order?.pvcRecyclingTax)} sx={orderItemTaxPriceTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }


            {order?.discount?.loyalty || order?.discount?.coupon &&
                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                    <Grid container>
                        <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                            <PercentIcon sx={orderItemMuiIconStyle} />
                        </Grid>

                        <Grid item xs={10} md={10}>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.discount')} sx={orderDiscountTextStyle()} />
                                </Grid>

                                {order?.discount?.loyalty?.discountPercentage > 0 &&
                                    <Grid item xs={12} md={12}>
                                        <Grid container>
                                            <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                                <CustomTypography variant="body2" text={`${t('customer.view.tabs.orders.order.loyalty')} (${order?.discount?.loyalty?.name}):`} sx={orderDiscountTextsStyle()} />
                                            </Grid>

                                            <Grid item xs={2} md={2}>
                                                <CustomTypography variant="body2" text={`${order?.discount?.loyalty?.discountPercentage} %`} sx={orderDiscountValueTextStyle()} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {order?.discount?.coupon?.value > 0 &&
                                    <Grid item xs={12} md={12}>
                                        <Grid container>
                                            <Grid item xs={10} md={10} sx={{ textAlign: "left", alignSelf: "center" }}>
                                                <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.coupon')} sx={orderDiscountTextsStyle()} />
                                            </Grid>
                                            <Grid item xs={2} md={2}>
                                                <CustomTypography variant="body2" text={priceTextWithCurrency(order?.outlet?.currency, order?.discount?.coupon?.value)} sx={orderItemTotalPriceTextStyle()} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                <Grid container>
                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <ReceiptIcon sx={orderItemMuiIconStyle} />
                    </Grid>

                    <Grid item xs={8} md={8} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={t('customer.view.tabs.orders.order.paymentTotal')} sx={orderItemTotalTextStyle()} />
                    </Grid>

                    <Grid item xs={2} md={2} sx={{ alignSelf: "center" }}>
                        <CustomTypography variant="body2" text={priceTextWithCurrency(order?.outlet?.currency, order?.totalAmmount)} sx={orderItemTotalPriceTextStyle()} />
                    </Grid>
                </Grid>
            </Grid>

            {order?.comments?.length > 0 &&
                <Grid item xs={12} md={12} sx={orderReviewGridStyle()}>
                    <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                        <Grid item xs={2} md={2}>
                            <MessageIcon sx={orderIconStyle} />
                        </Grid>

                        <Grid item xs={10} md={10} sx={{ alignSelf: "center" }}>
                            <CustomTypography variant="body2" text={order?.comments} sx={orderItemPriceTextStyle()} />
                        </Grid>
                    </Grid>
                </Grid>
            }


            {order?.allergies?.length > 0 &&
                <>
                    <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />

                    <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                        <Grid container gap={1}>
                            <Grid item xs={12} md={12}>
                                <InputLabel sx={orderCartTitleTextStyle()}>{t('customer.view.tabs.orders.order.allergies')}</InputLabel>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                {getAllergies(order?.allergies)}
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
}

export default CustomSinglePastOrderCardInfoEdit;