import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';

/**
 * The rest request which attempts to get the customers list from the database.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * @returns an object with an attribute containing the customers list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    if (search.organizationID !== null && search.organizationID !== undefined && search.organizationID !== "") {
        data = { organizationID: search.organizationID }
    }
    if (search.username !== null && search.username !== undefined && search.username !== "") {
        data = { ...data, username: search.username }
    }
    if (search.phone !== null && search.phone !== undefined && search.phone !== "") {
        data = { ...data, phone: search.phone }
    }
    if (search.email !== null && search.email !== undefined && search.email !== "") {
        data = { ...data, email: search.email }
    }
    data = { ...data, showOrderRecentlty: search.showOrderRecentlty }
    data = { ...data, showReturning: search.showReturning }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}customer`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}customer`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to get an existing customer information.
 * @param {*} customerID The id of the customer to be fetched
 * @returns an object with an attribute containing the current infromation of the customer
 */
export async function fetchOne(organizationID, outletID, customerID) {
    let data = {};
    if (organizationID !== undefined && organizationID !== "") {
        data = { organizationID: organizationID }
    }
    if (outletID !== undefined && outletID !== "") {
        data = { ...data, outletID: outletID }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}customer/${customerID}`, data));
    else return await request.get(includeParamsToUrl(`${API}customer/${customerID}`));
}

/**
 * The rest request which attempts to get an existing customer details in conection with an organization.
 * @param {*} organizationID The id of the organization to get details referring to the customer
 * @param {*} outletID The id of the outlet to get details referring to the customer
 * @param {*} customerID The id of the customer to be fetched
 * @returns an object with an attribute containing the current infromation of the customer
 */
export async function fetchOneView(organizationID, outletID, customerID) {
    let data = {};
    if (organizationID !== undefined && organizationID !== "") {
        data = { organizationID: organizationID }
    }
    if (outletID !== undefined && outletID !== "") {
        data = { ...data, outletID: outletID }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}customer/${customerID}/view`, data));
    else return await request.get(includeParamsToUrl(`${API}customer/${customerID}/view`));
}


/**
 * The rest request which attempts to create a new customer.
 * @param {*} data A JSON object which contains new customer infromation
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}customer`, data);
}

/**
 * The rest request which attempts to update an existing customer.
 * @param {*} data A JSON object which contains the updated infromation of the customer
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}customer`, data);
}

/**
 * The rest request which attempts to change the password of a specific customer.
 * @param {*} customerID The id of the customer to change the password
 * @param {*} data A JSON object which contains the updated infromation of the customer password
 * @returns an object refering to the success or failure of the request
 */
export async function changePassword(customerID, data) {
    return await request.put(`${API}customer/${customerID}/password`, data);
}

/**
 * The rest request which attempts to request otp code for reset password
 *
 * @param {*} data A JSON object which contains the updated infromation of the customer password
 * @returns an object refering to the success or failure of the request
 */
export async function forgotPassword(data) {
    return await request.post(`${API}customer/forgotPassword?emailOrUsername=` + data);
}


/**
 * The rest request which attempts to check the otp code
 *
 * @param {*} data A JSON object which contains the updated infromation of the customer password
 * @returns an object refering to the success or failure of the request
 */
export async function checkPasswordResetCode(data, otpCode) {
    return await request.post(`${API}customer/checkPasswordResetCode?emailOrUsername=${data.customername}&resetPasswordCode=${otpCode}`);
}

/**
 * The rest request which attempts to reset customer's password
 *
 * @param {*} data A JSON object which contains the updated infromation of the customer password
 * @returns an object refering to the success or failure of the request
 */
export async function resetPassword(data, otpCode) {
    return await request.post(`${API}customer/resetPassword?emailOrUsername=${data.customername}&resetPasswordCode=${otpCode}&newPassword=${data.newPassword}`);
}

/**
 * The rest request which attempts to enable an existing customer.
 * @param {*} customerId The id of the customer to be enabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function enable(customerId) {
    return await request.post(`${API}customer/${customerId}/enable`);
}

/**
 * The rest request which attempts to disable an existing customer.
 * @param {*} customerId The id of the customer to be disabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function disable(customerId) {
    return await request.post(`${API}customer/${customerId}/disable`);
}

/**
 * The rest request to delete a specific customer.
 * @param {*} id The id of the customer to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}customer/${id}`);
}

/**
 * The rest request which attempts to add new customer note from employee.
 * @param {*} note A JSON object which contains new customer note from employee
 * @returns an object refering to the success or failure of the request
 */
export async function submitNewNote(id, data) {
    return await request.post(`${API}customer/${id}/note`, data);
}

/**
 * The rest request which attempts to add customer points.
 * @param {*} note A JSON object which contains the points
 * @returns an object refering to the success or failure of the request
 */
export async function addPoints(id, data) {
    return await request.post(`${API}customer/${id}/points`, data);
}

const customerApi = {
    fetchAll,
    fetchOne,
    fetchOneView,
    create,
    update,
    enable,
    disable,
    changePassword,
    forgotPassword,
    checkPasswordResetCode,
    resetPassword,
    deleteById,
    submitNewNote,
    addPoints
}

export default customerApi;