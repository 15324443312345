import { Fab, Grid, Tooltip } from "@mui/material";
import modifierListApi from "api/modifierList";
import { AddIcon } from "assets/svg/SvgIcons";
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from 'components/Dialogs/CustomDialog';
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from "components/Select/CustomObjectSelect";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from "components/Title/CustomTitle";
import { getDateTimeFormatter } from "constants/calendar";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagTitle } from "themes/defaultThemes";
import { hasRoleAdmin, hasRoleAdminMenu, hasRoleAdminOrDirectorOrManager } from "utils/auth";
import { formValChangeWithParentElementWithNameAndValue } from "utils/form-validation";
import { prepareTablePaginationSortParams } from "utils/functions";
import { getLanguageFromURL } from "utils/language";
import { actionFormatter, dateFormatter, fieldFormatter, iconFormatter, idFormatterLinkWithParent, nameFormatterLinkWithParent } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The ModifierList List component to view the equipments of the outlet.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ModifierListListComponent() {
    const { organizationID } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    //change document title
    useDocumentTitle(`Usee ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t('org.pageTitle') : ""} | ${t('modifierList.title')}`)


    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    path += `/modifier-list`;

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {object} modifierLists the modifier-lists (pageable)
     * @property {object} organization the organization basic details (id and name, isActive)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        modifierLists: null,
        organization: {
            id: organizationID,
            name: ""
        },
        organizations: null,
        typesList: [t("label.single"), t("label.multiple")],
        pagination: {
            count: -1,
            size: 10,
            page: 0
        }
    });

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // for search
    const [search, setSearch] = useState({
        organizationID: organizationID,
        name: "",
        allowMultipleSelection: null
    });
    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        modifierListID: "",
        name: ""
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getModifierLists();
        setIsFirstTime(false);
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getModifierLists();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
    }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])


    /**
     * The rest endpoint to get the equipments list.
     * @property {number} page the list page number
     */
    function getModifierLists(page) {
        let sear = search;
        if (hasRoleAdminMenu(data.userAuth?.roles)) {
            sear.organizationID = localStorage.getItem("oid")
        }
        modifierListApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
            Promise.resolve()
                .then(() => {
                    if (r.data?.code === "SUCCESS")
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            modifierLists: r.data.returnobject.page,
                            organization: r.data.returnobject.organization,
                            organizations: r.data.returnobject.organizations,
                            pagination: {
                                ...(r.data.returnobject.page != null ? {
                                    count: r.data.returnobject.page?.totalPages,
                                    size: r.data.returnobject.page?.size,
                                    page: r.data.returnobject.page?.number,
                                } : {})
                            }
                        });

                    if (hasRoleAdminMenu(r.data.returnobject.userAuth?.roles) && isFirstTime) {
                        setSearch({ ...search, organizationID: localStorage.getItem("oid") });
                    }
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * 
     * Function that triggers the removal of the selected user.
     * @property {*} event
     * @property {string} userID The id of the selected user to be removed.
     */
    function deleteModifierList(event, userID, name = "") {
        handleDialogState(true, userID, name);
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} modifierListID The id of the selected modifier list to be removed.
     */
    function handleDialogState(isOpen, modifierListID = "", name = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            modifierListID: modifierListID,
            name: name
        });
    }

    /**
     * Gets called to remove the selected modifier list
     */
    function deleteML() {
        modifierListApi.deleteById(dialog.modifierListID).then((r) => {
            handleDialogState(false);
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getModifierLists();
        }).catch((e) => {
            // console.log(e);
        })
    }
    /**
     * Gets called to activate the selected modifier list
     * @param {*} event
     * @param {string} id the id of the modifier list
     */
    function enableModifierList(event, id) {
        modifierListApi.enable(id).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getModifierLists();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Gets called to activate the selected modifier list
     * @param {*} event
     * @param {string} id the id of the modifier list
     */
    function disableModifierList(event, id) {
        modifierListApi.disable(id).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getModifierLists();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * datatable columns default sorted.
     */
    const defaultSorted = {
        name: 'dateCreated',
        direction: 'desc'
    };

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }


    /**
     * Function that handles the pagination information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        let pagination = data.pagination;
        pagination.size = size;
        pagination.page = 0;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }
    /**
     * Function that handles the sort information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }


    /**
     * Function that handles the pagination information.
     * @property {*} event
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(page) {
        formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
    }

    function onFilterChange(event) {
        if (event !== null) {
            let field = event.field;
            let value = event.value;
            if (value === t('label.single')) {
                field = "allowMultipleSelection";
                value = false;
            } else if (value === t('label.multiple')) {
                field = "allowMultipleSelection";
                value = true;
            }

            formValChangeWithParentElementWithNameAndValue(field, value, search, setSearch)
        } else {
            setSearch({
                ...search,
                name: "",
                allowMultipleSelection: null
            })
        }
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return (
            <CustomGrid role="main" id="modifier-list-list" sx={mainTag()}>
                <LoadingSkeleton lines={9} />
            </CustomGrid>
        );
    } else {
        /**
         * datatable columns.
         */
        const table_columns = [
            {
                name: "enabled",
                label: t('label.isEnabled'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return iconFormatter(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            {
                                enable: (event, id) => enableModifierList(event, id),
                                disable: (event, id) => disableModifierList(event, id),
                                positionOfId: 1
                            }
                        );
                    }
                }
            },
            {
                name: "id",
                label: t('label.id'),
                options: {
                    viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles),
                    filter: false,
                    sort: false,
                    empty: false,
                    display: false,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
                    }
                }
            },
            {
                name: "name",
                label: t('label.name'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.name')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return nameFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path, "", 1);
                    }
                }
            },
            {
                name: "displayName",
                label: t('label.displayName'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value?.en;
                    }
                }
            },
            {
                name: "allowMultipleSelection",
                label: t('label.type'),
                options: {
                    filter: true,
                    filterType: 'select',
                    customFilterListRender: v => `${t('label.type')}: ${v}`,
                    filterOptions: {
                        names: [t("label.single"), t("label.multiple")]
                    },
                    sort: true,
                    empty: false,
                    customBodyRender: (value) => {
                        return fieldFormatter(value ? t("label.multiple") : t("label.single"));
                    }
                }
            },
            {
                name: "attachedModifierIds",
                label: t('modifierList.modifiersAttached'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return fieldFormatter(value?.length);
                    }
                }
            },
            {
                name: "minModifiers",
                label: t('modifierList.minSelection'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return fieldFormatter(tableMeta.tableData[tableMeta.rowIndex][4]/*allowMultipleSelection*/ ? value : 1);
                    }
                }
            },
            {
                name: "maxModifiers",
                label: t('modifierList.maxSelection'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return fieldFormatter(tableMeta.tableData[tableMeta.rowIndex][4]/*allowMultipleSelection*/ ? value : 1);
                    }
                }
            },
            {
                name: "lastActivity",
                label: t('label.lastActivity'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateTimeFormatter());
                    }
                }
            },
            {
                name: "dateCreated",
                label: t('label.dateCreated'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    display: false,
                    customBodyRender: (value, tableMeta) => {
                        return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateTimeFormatter());
                    }
                }
            },
            {
                name: "",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return actionFormatter(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            1,
                            null,
                            null,
                            null,
                            2,
                            path,
                            data.showLabel ? t('actions.edit') : undefined,
                            (event, rowIndex, name) => deleteModifierList(event, rowIndex, name),
                            data.showLabel ? t('actions.delete') : undefined,
                            false,
                            false,
                            true
                        )
                    },
                    setCellProps: () => ({ className: "click action" })
                }
            }
        ];

        return (
            <CustomGrid role="main" id="modifier-list-list" sx={mainTag()}>
                {organizationID !== undefined && !hasRoleAdminMenu(data.userAuth.roles) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` }
                            ]}
                            instanceName={t('modifierList.title')}
                        />
                    </CustomGrid>
                }

                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={"auto"}>
                        <CustomRefreshButton onClick={getModifierLists} marginTop="0" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <CustomGrid sx={mainTagTitle()}>
                            <CustomTitle
                                title={t('modifierList.title')}
                                subtitle={""}
                            />
                        </CustomGrid>
                    </Grid>

                    <Grid item xs={12} sm={8} sx={{ justifyContent: "right" }}>
                        <Grid sx={mainTagSearch()} container>
                            {/* <CustomSelectWithLabels
                                        name="allowMultipleSelection"
                                        defaultValue={defaultTypeSelected}
                                        labelID="allowMultipleSelection"
                                        label={t('label.type')}
                                        options={[{ "label": t("label.all"), "value": "All" }, { "label": t("label.single"), "value": false }, { "label": t("label.multiple"), "value": true }]}
                                        onChange={(event) => onSelectChange(event, search, setSearch)}
                                    />
                                    <CustomTextField
                                        type="search"
                                        name="name"
                                        id="name"
                                        variant="filled"
                                        label={t('actions.search') + " by " + t('label.name')}
                                        defaultValue={search.name !== null ? search.name : ""}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <CustomSearchButton onClick={getModifierLists} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        required={false}
                                        sx={{ minWidth: "300px" }}
                                        onChange={(event) => formValChangeWithParentElementSelect(event, search, setSearch, t('table.valueReuired'))}
                                    /> */}
                            {hasRoleAdminMenu(data.userAuth?.roles) &&
                                <Grid item xs={"auto"}>
                                    <label htmlFor="organizationID">{t('org.pageTitle')}</label>
                                    <CustomObjectSelect
                                        name="organizationID"
                                        required={true}
                                        value={localStorage.getItem("oid")}
                                        // labelID="organizationID" label={t('org.pageTitle')}
                                        options={data.organizations}
                                        onChange={(event) => { localStorage.setItem("oid", event.target?.value); setSearch({ ...search, organizationID: event.target?.value }) }}
                                        field={"name"}
                                        sx={{ borderRadius: "30px" }}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <CustomGrid sx={mainAreaTable()} container={false}>
                    <CustomPageableTable
                        instances={data.modifierLists}
                        emptyMessage={t('modifierList.empty')}
                        table_columns={table_columns}
                        keyField="id"
                        defaultSorted={defaultSorted}
                        pagination={data.pagination}
                        handlePaginationChange={handlePaginationChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        handleColumnSortChange={handleColumnSortChange}
                        filter={true}
                        onFilterChange={onFilterChange}
                    />

                    {((search.organizationID && hasRoleAdminMenu(data.userAuth.roles)) || hasRoleAdminOrDirectorOrManager(data.userAuth.roles)) &&
                        <Fab color="primary"
                            aria-label="add"
                            sx={fabStyle()}
                            onClick={() => navigate(`${path}/add`)}
                        >
                            <Tooltip arrow title={t('actions.add') + " " + t('modifierList.title')} sx={fabTooltipStyle()}>
                                <AddIcon {...fabStyleIcon()} />
                            </Tooltip>
                        </Fab>
                    }
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }

                {dialog.isOpen &&
                    <CustomDialog
                        isOpen={dialog.isOpen}
                        id="delete"
                        title={t('actions.deleteModal.title')}
                        actionLabel={t("actions.deleteModal.message")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('itemOrModifier.title')}: `}
                        boldMessage={<span style={{ fontWeight: "bold" }}>{dialog.name}</span>}
                        action={deleteML}
                        handleOpen={handleDialogState}
                        actionColor="#e91e1e"
                    />
                }
            </CustomGrid>
        );
    }
}

export default ModifierListListComponent;