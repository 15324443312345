import { request } from 'constants/alias';
import { API } from "constants/config";

/**
 * The rest request which attempts to create a new menu parent category.
 * @param {*} data A JSON object which contains new menu parent category infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}parentcategory`, data);
}

/**
 * The rest request which attempts to update an existing menu parent category.
 * @param {*} data A JSON object which contains the updated infromation of the menu parent category
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}parentcategory`, data);
}

/**
 * The rest request to delete a specific menu parent category.
 * @param {*} id The id of the menu parent category to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}parentcategory/${id}`);
}

/**
 * The rest request which attempts to activate an existing menu parent category.
 * @param {*} menuCategoryId The id of the menu parent category to be enabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function activate(menuCategoryId) {
    return await request.post(`${API}parentcategory/${menuCategoryId}/activate`);
}

/**
 * The rest request which attempts to disable an existing menu parent category.
 * @param {*} menuCategoryId The id of the menu parent category to be disabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function disable(menuCategoryId) {
    return await request.post(`${API}parentcategory/${menuCategoryId}/disable`);
}

const menuParentCategoryApi = {
    create,
    update,
    activate,
    disable,
    deleteById
}

export default menuParentCategoryApi;