export const lottieDefaultOptions = {
    loop: false,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "500px",
    },
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        progressiveLoad: true,
        hideOnTransparent: false
    }
};

export const lottieLoopOptions = {
    loop: true,
    autoplay: true,
    style: {
        height: "400px",
        marginTop: "-20px"
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
export const lottieDialogLoopOptions = {
    loop: false,
    autoplay: true,
    style: {
        height: "200px",
        marginTop: "-120px"
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieOrderOptions = {
    loop: true,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "400px",
        marginTop: "0px"
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieChartOptions = {
    loop: true,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "400px",
        textAlign: "center"
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieOrderFeedbacksOptions = {
    loop: true,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "360px",
    },
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const lottieDeleteAccountOptions = {
    loop: false,
    autoplay: true,
    style: {
        width: "fit-content",
        height: "360px",
    },
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
