import CloseRounded from '@mui/icons-material/CloseRounded';
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   IconButton
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import 'react-advanced-cropper/dist/style.css'
import 'react-advanced-cropper/dist/themes/classic.scss';

/**
 * The CustomDialogChildren, that display a customized dialog
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {string} title the dialog title
 * @property {*} children the body of the dialog
 * @property {function} handleOpen the function that handles the dialog open state
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomDialogChildren({ isOpen = false, id = "", title, children, handleOpen, handleAction, cancelLabel, actionLabel, sx }) {
   // @property {boolean} if the values is `true`, the modal should be open and visible.
   const [open, setOpen] = useState(isOpen);
   
   /**
    * Function that handles the visualize modal open or close state.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleDialogState(isOpen) {
      setOpen(isOpen);
      handleOpen(isOpen);
   }
   
   useEffect(() => {
      setOpen(isOpen);
  }, [isOpen]);

   return (
      <Dialog
         id={id}
         open={open}
         onClose={() => { handleDialogState(false) }}
         aria-describedby="alert-dialog-slide-description"
         className="dialog-title"
         sx={{ zIndex: 2100, ...sx }}
      >
         <DialogTitle className="dialog-headers">
            {title}
            <IconButton onClick={() => { handleDialogState(false) }} sx={{ float: "right" }}>
               <CloseRounded />
            </IconButton>
         </DialogTitle>
         <DialogContent>
            {children}
         </DialogContent>
         {cancelLabel && handleAction &&
            <DialogActions className="dialog-actions">
               {cancelLabel && <Button variant="outlined" color="error" className="dialog-cancel-btn" onClick={() => { handleDialogState(false) }}>{cancelLabel}</Button>}
               {handleAction && <Button variant="outlined" className="dialog-action-btn" onClick={handleAction}>{actionLabel.toUpperCase()}</Button>}
            </DialogActions>
         }
      </Dialog>
   );
}

export default CustomDialogChildren;