import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab';
import { Divider, Grid } from '@mui/material';
import CustomChip from 'components/Chip/CustomChip';
import SubscriptionHistoryInstanceOutletCharge from 'components/Grid/subscription/SubscriptionHistoryInstanceOutletCharge';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDateTimeFormatter } from 'constants/calendar';
import { useTranslation } from 'react-i18next';
import { orderActionHistoryDetailsLabelStyle, orderActionsDetailsHistoryDateTextStyle, orderActionsHistoryCardStyle, orderActionsHistoryDetailsLabelStyle, orderHistoryStatusLabelStyleChip, orderHistoryStatusLabelStyleDot, subscriptionDetailsHistoryDateTextStyle, subscriptionHistoryCardStyle, subscriptionHistoryDetailsLabelStyle, subscriptionHistoryDetailsTextPriceStyle, subscriptionHistoryDetailsTextStyle, subscriptionHistoryStatusLabelStyle, subscriptionHistoryStatusLabelStyleDot } from 'themes/defaultThemes';
import { clearBeforePresent, dateTimeFormatter, findCurrency } from 'utils/functions';

/**
 * `CustomOrderActionsHistoryTimeline` Component
 * 
 * Renders a visual timeline representing the history of a subscription.
 * 
 * This component uses Material-UI's `Timeline` components to display each significant
 * event in the lifecycle of a subscription, such as changes in subscription plans,
 * charges, and overall subscription status.
 * 
 * Props:
 * - `timelineDetails`: Array of objects representing the timeline events. Each object
 *    should include details like status, planID, dateTime, and other relevant information.
 * - `subscriptionPlans`: Array of available subscription plans. Used to match plan IDs
 *    from `timelineDetails` to their corresponding names.
 * - `outletBranches`: Array or object containing information about outlet branches, 
 *    used for rendering specific details about outlet charges.
 * - `sx`: Custom styles to apply to the timeline component.
 * 
 * Each event in the timeline is represented by a `TimelineItem` which includes the
 * status of the event, details about subscription plan changes, outlet charges, and
 * other relevant data. Internationalization is handled via `useTranslation` for text display.
 * 
 * Example of `timelineDetails` object structure:
 * ```
 * [
 *   {
 *     status: "Active",
 *     planID: "plan_1",
 *     details: {
 *       outletCharges: [{...}],
 *       organizationCharges: 100,
 *       totalAmount: 200,
 *       currency: "USD",
 *       dateTime: "2021-01-01T00:00:00Z"
 *     },
 *     ...
 *   },
 *   ...
 * ]
 * ```
 * 
 * @param {Object} props Component props
 * @returns {React.Component} CustomSubscriptionTimeline component
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomOrderActionsHistoryTimeline({ timelineDetails }) {
    const { t } = useTranslation();

    return (
        <Timeline
            sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                    marginLeft: "20px"
                },
            }}>
            {timelineDetails.reverse()?.map((timelineDetail) =>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot sx={orderHistoryStatusLabelStyleDot(timelineDetail?.status)}>
                            {/* <CustomTypography variant="body2" text={t(`order.actions.statusCaps.${timelineDetail?.status}`)} sx={orderHistoryStatusLabelStyleDot(timelineDetail?.status)} /> */}
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={orderActionsHistoryCardStyle()}>
                        <Grid container spacing={2} >
                            <Grid item xs={4} md={5}>
                                <CustomTypography variant="body2" text={t('label.status')} sx={orderActionsHistoryDetailsLabelStyle()} />
                            </Grid>
                            <Grid item xs={8} md={7}>
                                <CustomChip label={t(`order.actions.statusCaps.${timelineDetail.status}`)} sx={orderHistoryStatusLabelStyleChip(timelineDetail?.status)} />
                            </Grid>
                        </Grid>

                        <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", margin: "5px 0px" }} />
                        <CustomTypography variant="body2" text={dateTimeFormatter(timelineDetail?.dateTime, getDateTimeFormatter())} sx={orderActionsDetailsHistoryDateTextStyle()} />
                    </TimelineContent>
                </TimelineItem>
            )
            }
        </Timeline>
    );
}

export default CustomOrderActionsHistoryTimeline;