import CustomModifierListDraggableTableItem from "components/Table/CustomModifierListDraggableTableItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { mainAreaBodyDraggableTable } from 'themes/defaultThemes';

/**
 * A Custom Draggable List.
 * 
 * @property {array} item the array of the modifier-lists
 * @property {boolean} showEmptyMessage to specify if the empty list message should be shown
 * @property {string} emptyMessage The message to presented if the `label` is empty
 * @property {*} removeModifierList function that removes the modifier-list instance
 * @property {*} save function that updates a modifier-list details
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomModifierListDraggableTable({ item, showEmptyMessage = true, emptyMessage, removeModifierList, save, updateModifierListOrder, organizationID }) {

    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = async (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }
        const orederedModifierLists = reorder(item, result.source.index, result.destination.index)
        updateModifierListOrder(orederedModifierLists)
    }

    return (
        <>
            {(showEmptyMessage && (item === null || item.length === 0)) &&
                <div className="body small" style={mainAreaBodyDraggableTable()}>
                    {emptyMessage}
                </div>
            }

            {(item !== null && item.length > 0) &&
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-modifiers-list">
                        {(provided, snapshot) => (
                            <ul className="droppable-modifiers-list" ref={provided.innerRef} {...provided.droppableProps} style={{
                                background: snapshot.isDraggingOver ? "lightblue" : '',
                                borderRadius: "4px",
                                width: "100%",
                                paddingLeft: "5px"
                            }} >
                                {item.map((item, index) => (
                                    <CustomModifierListDraggableTableItem
                                        item={item}
                                        index={index}
                                        key={item.id}
                                        removeModifierList={removeModifierList}
                                        save={save}
                                        organizationID={organizationID}
                                        enableDraggable={true}
                                    />
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </>
    );
}

export default CustomModifierListDraggableTable;